/* eslint-disable react/prop-types */
import React from "react";
import styled from "styled-components";
import Skeleton from "react-loading-skeleton";
import { FadeLine } from "components/common";
import {
  EconomyClassIcon,
  FlightCancelStrict,
  NoBaggageIcon,
} from "components/common/SvgIcons";
import flyGray from "assets/icons/flyGray.svg";

const Wrapper = styled.div`
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #fff;
  margin-bottom: 12px;
  .css-19bon8n {
    border-radius: 10em;
  }
  .header {
    height: 32px;
    display: flex;
    align-items: center;
    background: linear-gradient(
        0deg,
        rgba(208, 247, 252, 0.2),
        rgba(208, 247, 252, 0.2)
      ),
      #ffffff;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08),
      inset 0px -1px 0px rgba(0, 0, 0, 0.08);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    img {
      margin-left: 20px;
    }
  }
  .airlineSection {
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    .name {
      display: flex;
      flex-flow: column;
    }
  }
  .details {
    height: 96px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
    .left,
    .right {
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      align-items: flex-start;
      height: 100%;
      padding: 12px 0;
    }
    .right {
      align-items: flex-end;
    }
  }
  .hopSegWrapper {
    padding: 12px 12px 0;
    display: flex;
    justify-content: center;
    .item {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: space-between;
      width: 110px;
      height: 190px;
      margin-right: 62px;
      @media (max-width: 800px) {
        margin-right: 10px;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    .buttonWrapper {
      height: 160px;
      width: 110px;
      border: 1px solid #dddddd;
      border-bottom: 0;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      padding: 15px 12px 10px 12px;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: space-between;
    }
  }
`;

export default () => (
  <Wrapper>
    <div className="airlineSection">
      <div className="name">
        <Skeleton height={16} width={60} />
        <Skeleton height={16} width={40} />
      </div>
      <Skeleton height={30} width={137} />
      <div className="name">
        <Skeleton height={16} width={40} />
        <Skeleton height={16} width={40} />
      </div>
    </div>
    <FadeLine />
    <div className="details">
      <div className="left">
        <Skeleton height={16} width={90} />
        <Skeleton height={16} width={70} />
        <Skeleton height={16} width={140} />
      </div>
      <Skeleton height={20} width={60} />
      <div className="right">
        <Skeleton height={16} width={90} />
        <Skeleton height={16} width={70} />
        <Skeleton height={16} width={140} />
      </div>
    </div>
    <FadeLine />
  </Wrapper>
);
