import React from 'react';
import { ApolloConsumer } from 'react-apollo';
import TripFareRules from './TripFareRule';

export default props => (
  <ApolloConsumer>
    {client => (
      <TripFareRules client={client} {...props} />
    )}
  </ApolloConsumer>
);
