import moment from "moment";
import {
    HANDLE_INCLUDE_HOTEL,
    SET_NIGHT_COUNT,
    HANDLE_HOTELS_UNAVAILABLE,
    HANDLE_UPDATE_HOTEL_FILTERED_RESULTS,
    CLEAR_HOTEL_FILTERED_RESULTS,
    HANDLE_SELECTED_HOTEL,
    HANDLE_DESELECT_HOTEL,
    HANDLE_FINALISE_HOTEL,
    REMOVE_TRIP_DATA,
    HANDLE_SETUPBOOKING_COMPLETE,

} from "redux-store/actions";

const initialProps = {
    includeHotel: true,
    nightCount: 1,
    hotelsUnavailable: false,
    bookings: [],
    hotels: [],
    selectedHotels: [],
    destinationCode: null,
    hotelDestinationCode: null,
    availabilityKey: null,
    occupancies: []
};

export default function (state = initialProps, action) {
    switch (action.type) {
        case HANDLE_INCLUDE_HOTEL:
            return {
                ...state,
                includeHotel: action.payload.includeHotel
            };

        case SET_NIGHT_COUNT:
            return {
                ...state,
                nightCount: action.payload.nightCount
            };

        case HANDLE_HOTELS_UNAVAILABLE:
            return {
                ...state,
                hotelsUnavailable: true
            };
        case HANDLE_UPDATE_HOTEL_FILTERED_RESULTS:
            // console.log('action payload updfil ' + JSON.stringify(action.payload));
            if (action.payload.prevDestinationCode) {
                let newSelHotels = [...state.selectedHotels.map(h => {
                    if (h.destinationCode === action.payload.prevDestinationCode) {
                        h.finalised = action.payload.prevSkipped ? false : true;
                        h.skipped = action.payload.prevSkipped ? true : false;
                        console.log("h is " + JSON.stringify(h));
                        return h;
                    }
                })];
                console.log('newSelHotels ' + JSON.stringify(newSelHotels));
                return {
                    ...state,
                    selectedHotels: newSelHotels,
                    destinationCode: action.payload.destinationCode,
                    hotelDestinationCode: action.payload.hotelDestinationCode,
                    hotels: action.payload.hotels.slice(0, 100),
                    availabilityKey: action.payload.availabilityKey,
                    occupancies: action.payload.occupancies
                };
            } else {
                return {
                    ...state,
                    destinationCode: action.payload.destinationCode,
                    hotelDestinationCode: action.payload.hotelDestinationCode,
                    hotels: action.payload.hotels.slice(0, 100),
                    availabilityKey: action.payload.availabilityKey,
                    occupancies: action.payload.occupancies
                };
            }
        case CLEAR_HOTEL_FILTERED_RESULTS:
            return {
                ...state,
                filteredResults: []
            };
        case HANDLE_SELECTED_HOTEL:
            // console.log('selectedHotelData ' + JSON.stringify(action.payload.selectedHotelData));
            let activeDests = state.selectedHotels.filter(sh => !sh.skipped && !sh.finalised);
            let activeHotel = activeDests.length > 0 ? activeDests[0] : null;

            if (activeHotel && activeHotel.hotelDestinationCode === action.payload.selectedHotelData.hotelDestinationCode) {
                return {
                    ...state,
                    selectedHotels: [...state.selectedHotels.filter(sh => sh.skipped || sh.finalised), action.payload.selectedHotelData]
                };
            } else {
                return {
                    ...state,
                    selectedHotels: [...state.selectedHotels, action.payload.selectedHotelData]
                };
            }


        case HANDLE_DESELECT_HOTEL:
            // console.log('deselectedHotel ' + JSON.stringify(action.payload.hotelCode));
            return {
                ...state,
                selectedHotels: state.selectedHotels.filter(h => (h.finalised == true || h.skipped == true) && h.hotelCode != action.payload.hotelCode)
            };
        case HANDLE_FINALISE_HOTEL:
            // console.log('destinationCode to finalise ' + JSON.stringify(action.payload.destinationCode));
            return {
                ...state,
                selectedHotels: state.selectedHotels.map(h => {
                    if (h.destinationCode === action.payload.destinationCode) {
                        h.finalised = true;
                        h.skipped = false;
                    }
                    return h;
                })
            };
        case REMOVE_TRIP_DATA:
            return {
                ...state,
                // includeHotel: true,
                bookings: [],
                hotels: [],
                selectedHotels: [],
                destinationCode: null,
                hotelDestinationCode: null,
                availabilityKey: null,
                occupancies: []
            };
        case HANDLE_SETUPBOOKING_COMPLETE:
            console.log('booking ' + JSON.stringify(action.payload.booking));
            return {
                ...state,
                bookings: [...state.bookings, action.payload.booking]
            }
        default:
            return state;
    }
}