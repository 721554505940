import React from "react";
import styled, { withTheme } from "styled-components";
import Switch from "@material-ui/core/Switch";
import {
  EconomyClassIcon,
  PremiumClassIcon,
  BusinessClassIcon,
  FirstClassIcon
} from "components/common/SvgIcons";

const Item = styled.div`
  background-color: ${props => (props.disabled ? "transparent" : "#fff")};
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
  height: 64px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 15px;
  position: relative;
  .airline {
    position: absolute;
    left: 10px;
    display: flex;
    align-items: center;
  }
`;

class CabinItem extends React.PureComponent {
  components = {
    ECONOMY: EconomyClassIcon,
    PREMIUM: PremiumClassIcon,
    BUSINESS: BusinessClassIcon,
    FIRST: FirstClassIcon
  };

  constructor(props) {
    super(props);
    const isFilteredOff = props.filterKvs.find(
      filter =>
        filter.value === props.item.value && filter.filterKey === "SEAT_CLASS"
    );
    this.state = {
      checked: isFilteredOff || props.disabled ? false : true
    };
  }

  handleChange = (e, checked) => {
    this.setState({ checked });
    this.props.handleFilterToggle(this.props.item.value, checked);
  };

  render() {
    const { checked } = this.state;
    const { item, loading, theme } = this.props;
    const SeatIcon = this.components[item.seatClass || "ECONOMY"];
    return (
      <Item>
        <div className="airline">
          <SeatIcon
            stroke={loading ? "#C4C4C4" : checked ? theme.primary : "#777777"}
          />{" "}
          <span style={{ marginLeft: 10, color: loading ? "#DDDDDD" : null }}>
            {item.name}
          </span>
        </div>
        {!loading && (
          <Switch
            checked={checked}
            onChange={this.handleChange}
            value={item.value}
            color="primary"
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        )}
      </Item>
    );
  }
}

export default withTheme(CabinItem);
