import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import { getTermsLocale } from 'utils/utils';

const Wrapper = styled.div`
  color: #000;
  font-size: 14px;
  padding: 20px 50px;
  @media (max-width: 800px) {
    padding: 20px;
    p {
      word-break: break-all;
    }
  }
  h1 {
    margin: 20px 0;
  }
`;

const Terms = ({
  selectedLanguage,
}) => (
  <Wrapper>
    <ReactMarkdown source={getTermsLocale(selectedLanguage)} />
  </Wrapper>
);


const mapStateToProps = state => ({
  selectedLanguage: state.translations.selectedLanguage,
});

export default connect(mapStateToProps)(Terms);
