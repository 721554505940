import React from "react";
import styled from "styled-components";
import { Autocomplete } from "components/common";
import getTranslation from "utils/getTranslation";
import { getItem } from "utils/utils";

const Wrapper = styled.div`
  margin-bottom: 50px;
  padding: 10px 0;
  /* border-bottom: 1px solid #dddddd; */
`;

let airportsListTimeout;
export default class DestinationsInput extends React.PureComponent {
  constructor(props) {
    super(props);
    let destination = "";
    if (props.postdestinations && props.postdestinations.length > 0) {
      const allAirports = getItem("allAirports");
      const airport = allAirports.find(
        item => item.airportIc === props.postdestinations[0].destination
      );
      if (airport) {
        destination = airport.city;
      }
    }
    this.state = {
      value: "",
      airportsList: [],
      from: destination,
      searchTextFrom: destination
    };
  }

  handleAirportFilter = (txt, airport, isFromInput) => {
    if (txt.length < 3) {
      this.setState({
        airportsList: []
      });
      return;
    }
    clearTimeout(airportsListTimeout);
    airportsListTimeout = setTimeout(() => {
      const allAirports = getItem("allAirports");
      let matchesArray = [];
      let filtered = allAirports.filter(item =>
        item.metareaIc.toLowerCase().startsWith(txt)
      );
      matchesArray = [...filtered];
      filtered = allAirports.filter(item =>
        item.airportIc.toLowerCase().startsWith(txt)
      );
      matchesArray = [...matchesArray, ...filtered];
      filtered = allAirports.filter(item =>
        item.city.toLowerCase().startsWith(txt)
      );
      matchesArray = [...matchesArray, ...filtered];
      filtered = allAirports.filter(item =>
        item.airportDesc.toLowerCase().startsWith(txt)
      );
      matchesArray = [...matchesArray, ...filtered];
      let distinct = [];
      matchesArray.slice(0, 30).forEach(item => {
        const exists = distinct.find(data => data.airportIc === item.airportIc);
        if (!exists) {
          distinct = [...distinct, item];
        }
      });
      if (airport && !isFromInput) {
        distinct = distinct.filter(
          item => item.airportIc !== airport.airportIc
        );
      }
      const metareaItem = distinct.find(
        item => item.metareaIc.toLowerCase() === txt
      );
      if (metareaItem) {
        distinct = [
          {
            ...metareaItem,
            airportIc: metareaItem.metareaIc,
            airportDesc: "All Airports"
          },
          ...distinct
        ];
        const metaAreaIndex = distinct.findIndex(
          item =>
            item.metareaIc === item.airportIc &&
            item.airportDesc !== "All Airports"
        );
        if (metaAreaIndex !== -1)
          distinct[metaAreaIndex].airportDesc =
            distinct[metaAreaIndex].airportDesc + " (All Airports)";
      }
      if (distinct.length > 0) {
        this.setState({
          airportsList: distinct
        });
      } else {
        const selected = this.state.airportsList.filter(item =>
          txt.toLowerCase().includes(item.airportIc.toLowerCase())
        );
        this.setState({
          airportsList: selected
        });
      }
    }, 300);
  };

  onAirportFrom = (index, dataSource) => {
    // if (index === -1) return;
    const airport = dataSource[index];
    if (airport) {
      this.props.handleChange([airport.airportIc]);
      this.setState({
        airportsList: [],
        from: airport.city,
        showFrom: false,
        doReset: false
      });
    }
  };

  handleFromAirportClose = () => {
    this.setState({ airportsList: [] });
  };

  handleFromNewRequest = (i, dataSource) => {
    this.onAirportFrom(i, dataSource || []);
  };

  handleFromClear = () => {
    this.setState({
      searchTextFrom: "",
      from: null
    });
  };

  handleFromInputChange = txt => {
    this.setState({ searchTextFrom: txt, from: null }, () => {
      this.handleAirportFilter(txt.toLowerCase(), this.state.to, true);
    });
  };
  render() {
    const { airportsList, searchTextFrom, from } = this.state;
    const { style = {}, placeholder } = this.props;
    return (
      <Wrapper style={style}>
        <div className="inputWrapper">
          <Autocomplete
            id={`from-field`}
            refName={c => {
              this._fromInput = c;
            }}
            label={getTranslation(placeholder || "nearestAirportCity")}
            value={from}
            type="airport"
            maxSearchResults={30}
            dataSource={airportsList}
            searchText={searchTextFrom}
            onChange={this.handleFromInputChange}
            handleSelect={this.handleFromNewRequest}
            handleClose={this.handleFromAirportClose}
            onBlur={this.handleFromBlurCheck}
            handleClear={this.handleFromClear}
          />
        </div>
      </Wrapper>
    );
  }
}
