import React, { PureComponent } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import Modal from "components/Influencer/TipModal";
import { updateInfluencer } from "redux-store/actions";
import { deleteTipAccount } from "utils/api";
import getTranslation from "utils/getTranslation";

const Wrapper = styled.div`
  width: 600px;
  background-color: #fff;
  padding: 24px;
  h1 {
    margin-top: 0;
  }
  @media (max-width: 800px) {
    width: 100%;
  }
`;

class DeleteAccountModal extends PureComponent {
  state = {
    doShare: false
  };

  handleLayoutChange = selectedLayout => {
    this.setState({ selectedLayout });
  };

  handleDeleteAccount = async () => {
    this.setState({ loading: true });
    try {
      await deleteTipAccount();
      this.props.updateInfluencer(null);
      this.props.handleSubmit();
    } catch (err) {
      console.log(err);
      this.setState({ loading: false });
    }
  };

  render() {
    const { loading } = this.state;
    const { open, handleCloseModal } = this.props;
    return (
      <Modal
        open={open}
        text1={getTranslation("deleteTipAccount")}
        submitText={getTranslation("deleteTipAccount")}
        handleCloseModal={handleCloseModal}
        loading={loading}
        handleSubmit={this.handleDeleteAccount}
        dialogStyle={{ width: 600, maxWidth: 600 }}
      >
        <Wrapper>
          <h1>Are you sure?</h1>
        </Wrapper>
      </Modal>
    );
  }
}

export default connect(null, { updateInfluencer })(DeleteAccountModal);
