import gql from "graphql-tag";

export const insertInfluencer = gql`
  mutation insert_influencer($objects: [influencer_insert_input!]!) {
    insert_influencer(objects: $objects) {
      returning {
        contrast_colour
        logo
        primary_colour
        sitetheme
        tip_sub_domain
        created_at
        updated_at
        custom_domain_selected
        posts(limit: 1, where: { lastpublished_at: { _is_null: false } }) {
          id
        }
        influencerconnects(limit: 3) {
          id
          connect
          influencer_id
          generic_field1
          generic_field2
          generic_field3
          access_token
        }
        customsitedomains(limit: 10) {
          id
          activated_at
          available_at
          domain
          unavailable_at
          price_usd
        }
        id
        fsuseraccount_id
      }
    }
  }
`;

export const getInfluencer = gql`
  query influencer($limit: Int, $where: influencer_bool_exp) {
    influencer(limit: $limit, where: $where) {
      contrast_colour
      sitetheme
      logo
      primary_colour
      tip_sub_domain
      created_at
      updated_at
      custom_domain_selected
      posts(limit: 1, where: { lastpublished_at: { _is_null: false } }) {
        id
      }
      influencerconnects(limit: 3) {
        id
        connect
        influencer_id
        generic_field1
        generic_field2
        generic_field3
        access_token
      }
      customsitedomains(limit: 10) {
        id
        activated_at
        available_at
        domain
        unavailable_at
        price_usd
      }
      id
      fsuseraccount_id
    }
  }
`;

export const getGuestInfluencer = gql`
  query influencer($limit: Int, $where: influencer_bool_exp) {
    influencer(limit: $limit, where: $where) {
      contrast_colour
      logo
      primary_colour
      sitetheme
      tip_sub_domain
      created_at
      updated_at
      id
    }
  }
`;

export const updateInfluencer = gql`
  mutation update_influencer(
    $_set: influencer_set_input
    $where: influencer_bool_exp!
  ) {
    update_influencer(_set: $_set, where: $where) {
      returning {
        contrast_colour
        logo
        primary_colour
        sitetheme
        tip_sub_domain
        created_at
        updated_at
        custom_domain_selected
        posts(limit: 1, where: { lastpublished_at: { _is_null: false } }) {
          id
        }
        influencerconnects(limit: 3) {
          id
          connect
          influencer_id
          generic_field1
          generic_field2
          generic_field3
          access_token
        }
        customsitedomains(limit: 10) {
          id
          activated_at
          available_at
          domain
          unavailable_at
          price_usd
        }
        id
        fsuseraccount_id
      }
    }
  }
`;

export const insertPost = gql`
  mutation insert_post($objects: [post_insert_input!]!) {
    insert_post(objects: $objects) {
      returning {
        created_at
        id
        influencer_id
        postdestinations {
          destination
        }
        itinerarydays {
          day
          title
          id
          text
          itinerarydaytransports {
            destination
            origin
            duration_minutes
            transport_mode
            transport_notes
          }
        }
        lastpublished_at
        text
        title
        type
        updated_at
        coverimage_layout
        coverimage1
        coverimage2
        coverimage3
        coverimage4
      }
    }
  }
`;

export const updatePost = gql`
  mutation update_post($_set: post_set_input, $where: post_bool_exp!) {
    update_post(_set: $_set, where: $where) {
      returning {
        created_at
        id
        influencer_id
        postdestinations {
          destination
        }
        itinerarydays {
          day
          title
          id
          text
          itinerarydaytransports {
            destination
            origin
            duration_minutes
            transport_mode
            transport_notes
          }
        }
        lastpublished_at
        text
        title
        type
        coverimage_layout
        coverimage
        coverimage1
        coverimage1_meta
        coverimage2
        coverimage2_meta
        coverimage3
        coverimage3_meta
        coverimage4
        coverimage4_meta
      }
    }
  }
`;

export const getPostById = gql`
  query post_by_pk($id: Int!) {
    post_by_pk(id: $id) {
      created_at
      id
      influencer_id
      postdestinations {
        destination
      }
      itinerarydays {
        day
        title
        id
        text
        itinerarydaytransports {
          destination
          origin
          duration_minutes
          transport_mode
          transport_notes
        }
      }
      lastpublished_at
      text
      title
      type
      coverimage_layout
      coverimage
      coverimage1
      coverimage1_meta
      coverimage2
      coverimage2_meta
      coverimage3
      coverimage3_meta
      coverimage4
      coverimage4_meta
    }
  }
`;

export const getPosts = gql`
  query post($limit: Int, $where: post_bool_exp) {
    post(limit: $limit, where: $where) {
      created_at
      id
      influencer_id
      postdestinations {
        destination
      }
      itinerarydays {
        day
        title
        text
      }
      lastpublished_at
      text
      title
      type
      updated_at
      coverimage_layout
      coverimage
      coverimage1
      coverimage1_meta
      coverimage2
      coverimage2_meta
      coverimage3
      coverimage3_meta
      coverimage4
      coverimage4_meta
    }
  }
`;

export const deletePost = gql`
  mutation delete_post($where: post_bool_exp!) {
    delete_post(where: $where) {
      affected_rows
    }
  }
`;

export const insertPostDestination = gql`
  mutation insert_postdestination($objects: [postdestination_insert_input!]!) {
    insert_postdestination(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

export const deletePostDestination = gql`
  mutation delete_postdestination($where: postdestination_bool_exp!) {
    delete_postdestination(where: $where) {
      affected_rows
    }
  }
`;

export const insertItineraryDay = gql`
  mutation insert_itineraryday($objects: [itineraryday_insert_input!]!) {
    insert_itineraryday(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

export const deleteItineraryDay = gql`
  mutation delete_itineraryday($where: itineraryday_bool_exp!) {
    delete_itineraryday(where: $where) {
      affected_rows
    }
  }
`;

export const deleteItineraryDayTransport = gql`
  mutation delete_itinerarydaytransport(
    $where: itinerarydaytransport_bool_exp!
  ) {
    delete_itinerarydaytransport(where: $where) {
      affected_rows
    }
  }
`;

export const insertConnect = gql`
  mutation insert_influencerconnect(
    $objects: [influencerconnect_insert_input!]!
  ) {
    insert_influencerconnect(objects: $objects) {
      returning {
        id
        connect
        influencer_id
        generic_field1
        generic_field2
        generic_field3
        access_token
      }
    }
  }
`;

export const deleteConnect = gql`
  mutation delete_influencerconnect($where: influencerconnect_bool_exp!) {
    delete_influencerconnect(where: $where) {
      returning {
        connect
        influencer_id
        generic_field1
        generic_field2
        id
      }
    }
  }
`;

export const getInfluencerConnect = gql`
  query influencerconnect($limit: Int, $where: influencerconnect_bool_exp) {
    influencerconnect(limit: $limit, where: $where) {
      id
      access_token
      connect
      generic_field1
      generic_field2
      influencer_id
      is_active
      share_frequency
    }
  }
`;

export const insertConnectPost = gql`
  mutation insert_influencerconnectpost(
    $objects: [influencerconnectpost_insert_input!]!
  ) {
    insert_influencerconnectpost(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

export const getInfluencerConnectPosts = gql`
  query influencerconnectpost(
    $limit: Int
    $where: influencerconnectpost_bool_exp
    $order_by: [influencerconnectpost_order_by!]
  ) {
    influencerconnectpost(limit: $limit, where: $where, order_by: $order_by) {
      created_at
      id
      shareschedule_at
      post_id
      post {
        created_at
        id
        influencer_id
        postdestinations {
          destination
        }
        itinerarydays {
          day
          title
          text
        }
        lastpublished_at
        text
        title
        type
        updated_at
        coverimage_layout
        coverimage1
        coverimage2
        coverimage3
        coverimage4
      }
      influencerconnect {
        connect
      }
      recurpattern_id
      recurpattern {
        first_recur_at
        last_recur_at
        recur_count
        recur_frequency
        recur_type
      }
      sharenote
      lastshared_at
    }
  }
`;

export const updateConnectPost = gql`
  mutation update_influencerconnectpost(
    $_set: influencerconnectpost_set_input
    $where: influencerconnectpost_bool_exp!
  ) {
    update_influencerconnectpost(_set: $_set, where: $where) {
      returning {
        id
      }
    }
  }
`;

export const deleteConnectPost = gql`
  mutation delete_influencerconnectpost(
    $where: influencerconnectpost_bool_exp!
  ) {
    delete_influencerconnectpost(where: $where) {
      returning {
        id
      }
    }
  }
`;

export const insertCustomDomain = gql`
  mutation insert_customsitedomain(
    $objects: [customsitedomain_insert_input!]!
  ) {
    insert_customsitedomain(objects: $objects) {
      returning {
        id
        available_at
        domain
        price_usd
        unavailable_at
      }
    }
  }
`;

export const updateCustomDomain = gql`
  mutation update_customsitedomain(
    $_set: customsitedomain_set_input
    $where: customsitedomain_bool_exp!
  ) {
    update_customsitedomain(_set: $_set, where: $where) {
      returning {
        id
        available_at
        domain
        price_usd
        unavailable_at
      }
    }
  }
`;

export const getCustomDomainById = gql`
  query customsitedomain_by_pk($id: Int!) {
    customsitedomain_by_pk(id: $id) {
      id
      available_at
      domain
      price_usd
      unavailable_at
    }
  }
`;

export const checkSubDomain = gql`
  query influencer_subdomain(
    $limit: Int
    $where: influencer_subdomain_bool_exp
  ) {
    influencer_subdomain(limit: $limit, where: $where) {
      influencer_id
      tip_sub_domain
    }
  }
`;

export const insertRecurPattern = gql`
  mutation insert_recurpattern($objects: [recurpattern_insert_input!]!) {
    insert_recurpattern(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

export const updateRecurPattern = gql`
  mutation update_recurpattern(
    $_set: recurpattern_set_input
    $where: recurpattern_bool_exp!
  ) {
    update_recurpattern(_set: $_set, where: $where) {
      returning {
        id
      }
    }
  }
`;
