import React from "react";
import styled from "styled-components";
import ActivateDomain from "containers/Influencer/Setup/Share/ActivateDomain";

const Wrapper = styled.div``;

class DomainComp extends React.PureComponent {
  constructor(props) {
    super(props);
    const { tip_sub_domain, customsitedomains } = props.influencerDetails || {};
    this.state = {
      data: {
        tip_sub_domain
      },
      customsitedomains
    };
  }
  render() {
    const { showShare } = this.state;
    const { influencerDetails } = this.props;
    return (
      <Wrapper>
        <ActivateDomain
          edit
          influencerDetails={influencerDetails}
          showShare={showShare}
          handleShare={this.handleShareView}
        />
      </Wrapper>
    );
  }
}

export default DomainComp;
