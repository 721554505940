import React from "react";
import styled from "styled-components";
import Dropzone from "react-dropzone";
import { Button } from "components/common";
import MediaFile from "./MediaFile";
import { uploadImage } from "utils/api";
import getTranslation from "utils/getTranslation";
import config from "config";
import upload from "assets/tip/Upload.svg";

const Wrapper = styled.div`
  margin-top: 50px;
  .drop-wrapper {
    cursor: pointer;
    width: 100%;
    background: #ffffff;
    border: 3px dashed #d0f3fc;
    .inner {
      outline: none;
      display: flex;
      flex-flow: column;
      align-items: center;
      margin: 0px auto 0px auto;
      max-width: 700px;
      img {
        margin-top: 40px;
      }
      .error {
        font-size: 14px;
        color: #eb5757;
      }
      .submitMediaTOS {
        font-size: 12px;
        color: #222222;
        margin-top: 20px;
      }
    }
  }
  .mediaFiles {
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
  }
`;

const Textarea = styled.textarea`
  box-shadow: 0px 0px 2px rgba(255, 255, 255, 0.16);
  border-radius: 4px;
  border: 1px solid #007ac7;
  padding: 10px;
  font-size: 14px;
  margin-bottom: 30px;
`;

export default class extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      allowUpload: true,
      acceptTypes: ["image/*", "video/*"],
      mediaFiles: !!props.text ? props.text.split("!@#") : []
    };
  }
  
  componentDidUpdate(prevProps) {
    if(prevProps.text != this.props.text) {
      this.setState({
        mediaFiles: !!this.props.text ? this.props.text.split("!@#") : []
      });
    }
  }

  onDrop = async ([file]) => {
    if (file.type.includes("image") && file.size > config.imageSizeLimit) {
      this.setState({
        imageSizeError: true
      });
      return;
    }
    if (!this.state.allowUpload) {
      return;
    }
    this.setState({ uploading: true, imageSizeError: false });
    try {
      const request = await uploadImage(file);
      this.setState({
        acceptTypes: "image/*",
        allowUpload: request.resource_type !== "video"
      });
      this.setState(
        {
          uploading: false,
          mediaFiles: [...this.state.mediaFiles, request.secure_url]
        },
        () => {
          this.props.handleChange("text", this.state.mediaFiles.join("!@#"));
        }
      );
    } catch (err) {
      this.setState({ uploading: false });
    }
  };

  handleRemove = index => {
    const { mediaFiles } = this.state;
    this.setState(
      {
        mediaFiles: mediaFiles.filter((item, i) => i !== index)
      },
      () => {
        if (this.state.mediaFiles.length === 0) {
          this.setState({
            acceptTypes: ["image/*", "video/*"]
          });
        }
        this.props.handleChange("text", this.state.mediaFiles.join("!@#"));
      }
    );
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.props.handleChange(name, value);
  };
  render() {
    const {
      uploading,
      mediaFiles,
      acceptTypes,
      allowUpload,
      imageSizeError
    } = this.state;
    const { title } = this.props;
    return (
      <Wrapper className="section">
        <Textarea
          rows="5"
          value={title}
          name="title"
          placeholder={getTranslation("sayHello")}
          onChange={this.handleChange}
        />
        <div className="mediaFiles">
          {mediaFiles.map((file, index) => (
            <MediaFile
              key={index}
              index={index}
              src={file}
              handleRemove={this.handleRemove}
            />
          ))}
        </div>
        {allowUpload && (
          <div className="drop-wrapper">
            <Dropzone onDrop={this.onDrop} accept={acceptTypes}>
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()} style={{ outline: 0 }}>
                    <input {...getInputProps()} />
                    <div className="inner">
                      <img src={upload} alt="" />
                      <p>{getTranslation("dragDropText")}</p>
                      <div style={{ width: 272 }}>
                        <Button color="#D0F3FC" style={{ color: "#007AC7" }}>
                          {getTranslation(
                            uploading ? "uploading" : "uploadFile"
                          )}
                        </Button>
                      </div>
                      {imageSizeError && (
                        <p className="error">
                          {getTranslation("imageSizeError")}
                        </p>
                      )}
                      <p className="submitMediaTOS">
                        {getTranslation("maxImageSize")}
                      </p>
                      <p className="submitMediaTOS">
                        {getTranslation("submitMediaTOS")}
                      </p>
                    </div>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
        )}
      </Wrapper>
    );
  }
}
