import React from 'react';
import styled from 'styled-components';
import { Loader } from 'components/common';
import TimeItem from './TimeItem';

const Wrapper = styled.div`
  padding: 15px;
`;

export default class extends React.PureComponent {
  render() {
    const {
      trips,
      filterKvs,
      loading,
      handleDirectionChange,
      handleTimeToggle
    } = this.props;
    const timeFiltersKvs = filterKvs.filter(item => item.filterKey === 'TIME_PERIOD');
    return (
      trips.length > 0 ? <Wrapper>
        <React.Fragment>
          {trips[0].hopRequests.map((hopRequest, i) => (
            <TimeItem
              index={i}
              trips={trips}
              hopRequest={hopRequest}
              key={hopRequest.id}
              filterKvs={timeFiltersKvs}
              handleTimeToggle={handleTimeToggle}
              handleDirectionChange={handleDirectionChange}
              loading={loading}
            />
          ))}
        </React.Fragment>
      </Wrapper> : null
    )
  }
}
