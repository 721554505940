import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  &.title {
    color: #777777;
    font-size: 16px;
    line-height: 26px;
    font-family: "Roboto Condensed", sans-serif;
    text-transform: uppercase;
    border-bottom: 1px solid #dddddd;
    margin-bottom: 10px;
  }
`;

export default ({ title, style = {} }) => (
  <Wrapper style={style} className="title">
    {title}
  </Wrapper>
);
