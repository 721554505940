import React from "react";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { addPrevPassengers } from "redux-store/actions";
import { getAllPassengers } from "../HopQuery";

class BaggageButton extends React.PureComponent {
  async componentWillMount() {
    const passengers = await this.props.client.query({
      query: getAllPassengers,
      fetchPolicy: "no-cache",
      variables: {}
    });
    this.props.addPrevPassengers(passengers.data.allPassengers);
  }

  render() {
    return null;
  }
}

const mapDispatchToProps = {
  addPrevPassengers
};

export default withApollo(connect(null, mapDispatchToProps)(BaggageButton));
