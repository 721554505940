import { PureComponent } from "react";
import { connect } from "react-redux";
import { xcountryLocalesAndCurrencies } from "components/common/commonQueries";
import { changeLanguage } from "redux-store/actions";
import {
  getItem,
  setItem,
  removeItem,
  getCookie,
  getLanguage,
  getBrowserLocale,
} from "utils/utils";

class Geolocation extends PureComponent {
  state = {};

  componentDidMount() {
    this.getIpDetails();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.doReset && this.props.doReset !== nextProps.doReset) {
      this.getIpDetails();
    }
  }

  getIpDetails = async () => {
    try {
      const browserLocale = getBrowserLocale();
      let country = getCookie("country") || "GB";
      let language = getCookie("locale");
      let currencyIso = getCookie("currency");
      let currencyDisplay = getCookie("currencydisplay")
        ? decodeURIComponent(getCookie("currencydisplay"))
        : null;
      const countryInfo = await this.props.client.query({
        query: xcountryLocalesAndCurrencies,
        variables: {
          fsSupport: true,
          countryIc: country,
        },
      });
      if (language && currencyIso && currencyDisplay) {
        if (language !== getItem("selectedLanguage")) {
          removeItem("selectedLanguage");
        }
        if (currencyIso !== getItem("selectedCurrency")) {
          removeItem("selectedCurrency");
        }
        if (currencyDisplay !== getItem("selectedCurrencySymbol")) {
          removeItem("selectedCurrencySymbol");
        }
      }
      if (countryInfo.data.xcountryLocalesAndCurrencies.length > 0) {
        // check if browser locale is supported by current country. if not then get all locales and match
        const fetchLang = getLanguage(
          language || browserLocale,
          countryInfo.data.xcountryLocalesAndCurrencies[0].locales
        );
        language = getItem("selectedLanguage")
          ? getItem("selectedLanguage")
          : language || fetchLang.locale;
        currencyIso = getItem("selectedCurrency")
          ? getItem("selectedCurrency")
          : currencyIso ||
            countryInfo.data.xcountryLocalesAndCurrencies[0].currencies[0]
              .currencyIso;
        currencyDisplay = getItem("selectedCurrencySymbol")
          ? getItem("selectedCurrencySymbol")
          : currencyDisplay ||
            countryInfo.data.xcountryLocalesAndCurrencies[0].currencies[0]
              .currencyDisplay;

        const internation = await this.props.client.query({
          query: xcountryLocalesAndCurrencies,
          variables: {
            fsSupport: true,
            countryIc: "XX",
          },
        });
        const checkBrowserLaocale = getLanguage(
          browserLocale,
          internation.data.xcountryLocalesAndCurrencies[0].locales
        );

        setItem("selectedCountry", country);
        setItem("selectedLanguageName", fetchLang.language);
        if (checkBrowserLaocale.fsSupport) {
          setItem("browserLocaleSupported", checkBrowserLaocale.language);
        }
      } else {
        country = "XX";
        const allLocales = await this.props.client.query({
          query: xcountryLocalesAndCurrencies,
          variables: {
            fsSupport: true,
            countryIc: country,
          },
        });
        const lang = getLanguage(
          browserLocale,
          allLocales.data.xcountryLocalesAndCurrencies[0].locales
        );
        language = lang.locale;
        currencyIso = "EUR";
        currencyDisplay = "€";
        setItem("selectedCountry", "XX");
        setItem("selectedLanguageName", lang.language);
        if (lang.fsSupport) {
          setItem("browserLocaleSupported", lang.language);
        }
      }
      setItem("selectedLanguage", language.substring(0, 2));
      setItem("selectedCurrency", currencyIso);
      setItem("selectedCurrencySymbol", currencyDisplay);

      this.props.changeLanguage(language.substring(0, 2));
      const payload = {
        countryCode: getItem("selectedCountry") || country,
        locale: language || "en",
        currencyIso: currencyIso || "EUR",
      };

      this.props.handleLocale(payload);
      if (this.props.sendCoords) {
        const coordsPayload = {
          latitude: getCookie("latitude") || 0,
          longitude: getCookie("longitude") || 0,
        };
        if (this.props.handleSuccess) {
          this.props.handleSuccess(coordsPayload, this.props.client);
        }
      } else {
        this.props.moveFocusToDates();
      }
    } catch (err) {
      console.log(err);
      const payload = {
        countryCode: getItem("selectedCountry") || "GB",
        locale: "en",
        currencyIso: "EUR",
      };
      this.props.handleLocale(payload);
      this.handleSetDefaults();
    }
  };

  handleSetDefaults = () => {
    setItem("selectedCountry", "gb");
    setItem("selectedLanguage", "en");
    setItem("selectedLanguageName", "English");
    setItem("selectedCurrency", "EUR");
    setItem("selectedCurrencySymbol", "€");
  };

  render() {
    return null;
  }
}

export default connect(null, { changeLanguage })(Geolocation);
