import React from "react";
import styled, { withTheme } from "styled-components";
import { isMobile } from "react-device-detect";
import ReactProgressiveList from "react-progressive-list";
import { Loader, FadeLine, Button } from "components/common";
import { FilterModal } from "components/common";
import { Title } from "components/common/CommonStyles";
import FlightListItem from "components/common/FlightListItem";
import Sorter from "components/common/Sorting/Sorter";
import { ArrowDown, FilterIcon } from "components/common/SvgIcons";
import getTranslation from "utils/getTranslation";
import { handleUpdateFilteredResults } from "redux-store/actions";
import { connect } from "react-redux";

const Wrapper = styled.div`
  margin-top: 20px;
  .container {
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .loadMoreWrapper {
  }
`;

const Filter = styled.div`
  color: #007ac7;
  cursor: pointer;
  font-size: 14px;
  text-transform: uppercase;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 600;
  display: flex;
  align-items: center;
  span {
    margin-right: 10px;
  }
`;

const Count = styled.div`
  width: 24px;
  height: 24px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #ec9719;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.16);
  font-size: 14px;
  margin-left: 5px;
`;

const loadCount = 10;
const sortArray = [
  {
    name: "departureTime",
    label: "departure",
  },
  {
    name: "arrtivalTime",
    label: "arrival",
  },
  {
    name: "airlineIc",
    label: "airline",
  },
  {
    name: "totalCost",
    label: "totalPrice",
  },
];

class MoreResults extends React.Component {
  constructor(props) {
    super(props);
    const rowCount =
      props.trips.length > loadCount ? loadCount : props.trips.length;
    this.state = {
      rowCount,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.trips.length !== this.props.trips.length) {
      const rowCount =
        nextProps.trips.length > loadCount ? loadCount : nextProps.trips.length;
      this.setState({
        rowCount,
      });
    }
  }
  renderRow = (index) => {
    const { trips, handleClick, selectedTrip, showSelected } = this.props;
    const currentTripPriceNum = trips[index] ? trips[index].priceNum : null;
    return (
      ((!showSelected && selectedTrip !== currentTripPriceNum) ||
        showSelected) && (
        <FlightListItem
          key={index}
          handleClick={
            selectedTrip !== currentTripPriceNum ? handleClick : null
          }
          item={trips[index]}
          flightTimeType="departure"
          active={selectedTrip === currentTripPriceNum}
        />
      )
    );
  };

  handleLoadMore = () => {
    const { rowCount } = this.state;
    const newCount = rowCount + loadCount;
    const count =
      newCount < this.props.trips.length ? newCount : this.props.trips.length;
    this.setState(
      {
        rowCount: count,
      },
      () => {
        if (this.state.rowCount / loadCount === 2) {
          setTimeout(() => {
            window.scrollBy(0, 20);
          }, 1000);
        }
      }
    );
  };

  handleFilterToggle = (doUpdateFlight) => {
    this.setState({
      filterModalOpen: !this.state.filterModalOpen,
    });
    if (doUpdateFlight === true) {
      this.props.handleUpdTriItenerary("", "", "", "", true);
    }
  };

  render() {
    const { rowCount, filterModalOpen } = this.state;
    const {
      trips,
      loadingFiltered,
      filterKvs,
      enablePaymentGateway,
      extraCheckDone,
      theme,
    } = this.props;
    return (
      <Wrapper>
        <FilterModal
          open={filterModalOpen}
          handleCloseModal={this.handleFilterToggle}
          filterKvs={filterKvs}
        />
        <div className="container">
          <Title className="title">
            {getTranslation("availableItinerary")}
            {extraCheckDone && (
              <Filter onClick={this.handleFilterToggle}>
                <span>{getTranslation("filter")}</span>
                <FilterIcon />
                {filterKvs.length > 0 && !enablePaymentGateway && (
                  <Count>{filterKvs.length}</Count>
                )}
              </Filter>
            )}
          </Title>
          {loadingFiltered && <Loader />}
          {!loadingFiltered && (
            <Sorter
              handleUpdateFilteredResults={
                this.props.handleUpdateFilteredResults
              }
              sortArray={sortArray}
              data={trips}
            />
          )}
          {!loadingFiltered && trips.length > 0 && (
            <ReactProgressiveList
              initialAmount={loadCount}
              progressiveAmount={loadCount}
              renderItem={this.renderRow}
              renderLoader={() => <Loader />}
              rowCount={
                isMobile || rowCount > loadCount ? trips.length : rowCount
              }
              useWindowScroll
            />
          )}
          {!loadingFiltered &&
            !isMobile &&
            rowCount <= loadCount &&
            trips.length > loadCount && (
              <div className="loadMoreWrapper">
                <FadeLine />
                <div style={{ textAlign: "center" }}>
                  <Button
                    onClick={this.handleLoadMore}
                    color="#fff"
                    style={{
                      height: 32,
                      maxWidth: 65,
                      borderRadius: "0 0 16px 16px",
                    }}
                  >
                    <ArrowDown stroke={theme.secondary} />
                  </Button>
                </div>
              </div>
            )}
        </div>
      </Wrapper>
    );
  }
}
const mapDispatchToProps = {
  handleUpdateFilteredResults,
};

export default withTheme(connect(null, mapDispatchToProps)(MoreResults));
