import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import ReactProgressiveList from "react-progressive-list";
import { Loader, FadeLine, Button } from "components/common";
import styled, { withTheme } from "styled-components";
import { Title } from "components/common/CommonStyles";
import Sorter from "components/common/Sorting/Sorter";
import { graphql, compose, withApollo } from "react-apollo";
import HotelResultsHeader from "./HotelResultsHeader";
import HotelItem from "./HotelItem";
import getTranslation from "utils/getTranslation";
import { handleUpdateHotelFilteredResults, handleSelectedHotel, handleDeselectHotel, handleFinaliseHotel } from "redux-store/actions";
import { submitAvailabilityRequestGql } from "./HotelQuery";
import { getItem } from 'utils/utils';
import moment from "moment";

const loadCount = 10;
const sortArray = [
    {
        name: "distance",
        label: "distance",
    },
    {
        name: "price",
        label: "price",
    }
];

/*
decodedData [{"to":{"airportIc":"DEL","metareaIc":"XXX","city":"Delhi","airportDesc":"Indira Gandhi Intl"},"from":{"airportIc":"LHR","metareaIc":"LON","city":"London","airportDesc":"Heathrow"},"date":1600902000,"transit":0,"returnDate":0,"passengers":{"infant":0,"adult":1,"child":0,"childrenAges":["3"]},"tripID":336}]
*/

class HotelResults extends Component {
    constructor(props) {
        super(props);

        this.state = {
            rowCount: 10,
            // noHotels: false,
            loadingFiltered: false,
            isMobile: false,
            // lastSelectedHotelCode: null,
            // lastSelectedDecodedDataIndex: null
        };
    };
    componentDidMount() {
        let lastSelectedDestinationCode = this.props.selectedHotels.length > 0 ? this.props.selectedHotels[this.props.selectedHotels.length - 1].destinationCode : null;
        this.moveToNext(lastSelectedDestinationCode);
    }
    handleSelect = async (selectedHotelData) => {
        // store in local and set redux key
        let { selectedHotels, destinationCode, hotelDestinationCode, hotels, availabilityKey, occupancies } = this.props;
        // console.log('avail and occ' + availabilityKey, JSON.stringify(occupancies));
        let activeDests = selectedHotels.filter(sh => sh.skipped == false && sh.finalised == false);
        let lastSelectedHotelCode = activeDests.length == 1 ? activeDests[0].hotelCode : null;


        if (lastSelectedHotelCode == selectedHotelData.hotelCode) {
            // console.log("lastHotel " + lastSelectedHotelCode + "curr " + selectedHotelData.hotelCode);
            this.props.handleDeselectHotel(selectedHotelData.hotelCode);
        }
        else {
            // console.log("oops lastHotel " + lastSelectedHotelCode + "curr " + selectedHotelData.hotelCode);
            let newobj = { ...selectedHotelData };
            newobj.destinationCode = destinationCode;
            newobj.hotelDestinationCode = hotelDestinationCode
            newobj.skipped = false;
            newobj.finalised = false;
            newobj.hotel = hotels.find(h => h.code == newobj.hotelCode);
            newobj.availabilityKey = availabilityKey;
            newobj.occupancies = occupancies;
            this.props.handleSelectedHotel(newobj);
        }
    };
    moveToNext = async (prevHotelDestinationCode, prevSkipped) => {


        // destinationCode: hopr.to.airportIc,
        //       destinationCodeType: "AIRPORT",
        //       destinationName: hopr.to.city,
        //       checkinDate: hopr.date,
        //       checkoutDate: hopr.returnDate


        let { destinationCode, decodedData, nightCount } = this.props;
        // console.log('hotelReqs ' + JSON.stringify(decodedData.hotelReqs));
        let hotelReqs = decodedData.filter(item => item.hotelReqs.length != 0).map(item => item.hotelReqs).flat();
        // console.log('hotelReqs after flat ' + hotelReqs);
        let currentDecodedDataIndex = hotelReqs.findIndex(item => item.destinationCode == destinationCode) + 1;
        // console.log('lastSelectedDecodedDataIndex ' + lastSelectedDecodedDataIndex);

        // let checkOutDateStr = decodedData[currentDecodedDataIndex].returnDate == 0 && decodedData[currentDecodedDataIndex + 1] != null ? moment(decodedData[currentDecodedDataIndex + 1].date * 1000).format("YYYY-MM-DD") : (decodedData[currentDecodedDataIndex + 1] == null ? moment((decodedData[currentDecodedDataIndex].date + (nightCount * 24 * 60 * 60)) * 1000).format("YYYY-MM-DD") : moment(decodedData[currentDecodedDataIndex].returnDate * 1000).format("YYYY-MM-DD"));

        const subAvailReq = await this.props.submitAvailabilityRequest({
            variables: {
                availabilityInput: {
                    currency: getItem("selectedCurrency"),
                    checkIn: moment(hotelReqs[currentDecodedDataIndex].checkinDate * 1000).format("YYYY-MM-DD"),
                    checkOut: moment(hotelReqs[currentDecodedDataIndex].checkoutDate * 1000).format("YYYY-MM-DD"),
                    destinationCodeType: hotelReqs[currentDecodedDataIndex].destinationCodeType,
                    destinationCode: hotelReqs[currentDecodedDataIndex].destinationCode,
                    adults: decodedData[currentDecodedDataIndex].passengers.adult,
                    childrenAges: decodedData[currentDecodedDataIndex].passengers.childrenAges.map(childAge => parseInt(childAge)),
                    firstDepCountry: getItem("selectedCountry")
                }
            },
        });
        let gqlout = subAvailReq.data.submitAvailabilityRequest ? subAvailReq.data.submitAvailabilityRequest[0] : null;
        // console.log('gqlout ' + JSON.stringify(gqlout));
        if (gqlout != null) {
            let hotels = gqlout.hotels.length > 0 ? gqlout.hotels.filter(hotel => hotel.hotelcontent.images != null && hotel.hotelcontent.images.length > 0) : [];
            // console.log('now length ' + hotels.length);
            this.props.handleUpdateHotelFilteredResults(prevHotelDestinationCode, prevSkipped, decodedData[currentDecodedDataIndex].to.airportIc, gqlout.destinationCode, hotels, gqlout._key, gqlout.occupancies);
            // this.props.handleFinaliseHotel();
            // this.props.handleSelectedHotel({ destinationCode, skipped: true, finalised: false }
        }
    };
    handleFinalise = async (allFinalised) => {
        let { destinationCode } = this.props;

        if (allFinalised) {
            await this.props.handleFinaliseHotel(destinationCode);
            this.props.handleHotelsCompleted();
        }
        else {
            this.moveToNext(destinationCode, false);
        }
    };

    handleSkipAccommodation = () => {
        let { destinationCode } = this.props;
        this.moveToNext(destinationCode, true);
        // this.props.handleSelectedHotel({ destinationCode, skipped: true, finalised: false }, () => {
        //     console.log("----I am calling --------");

        // });
    };

    handleAllFinalised = (lastOneSkipped) => {
        // console.log("enter");
        if (!lastOneSkipped) {
            this.handleFinalise(true);
        }
        else {
            this.props.handleHotelsCompleted();
        }
    };

    renderRow = (index) => {
        const { destinationCode, hotels, selectedHotels, occupancies } = this.props;
        const currentHotelCode = hotels[index] ? hotels[index].code : null;
        let filteredSelectedHotels = selectedHotels.filter(sh => sh.skipped == false && sh.finalised == false && sh.destinationCode == destinationCode);
        let lastSelectedHotelCode = filteredSelectedHotels.length == 1 ? filteredSelectedHotels[0].hotelCode : null;
        return (
            <HotelItem
                key={index}
                handleSelect={this.handleSelect}
                item={hotels[index]}
                numOfRooms={occupancies.length}
                active={lastSelectedHotelCode == currentHotelCode}
            />
        );
    };
    render() {
        let { loadingFiltered, isMobile, rowCount } = this.state;
        let { hotelsUnavailable, selectedHotels, decodedData, destinationCode, hotels, theme, nightCount } = this.props;
        if (!hotelsUnavailable && hotels.length == 0) {
            loadingFiltered = true;
        }
        // let totalDestinations = decodedData.length - 1 + (nightCount > 0 ? 1 : 0);
        // console.log('decodedData ' + JSON.stringify(decodedData));
        let hotelReqs = decodedData.filter(item => item.hotelReqs.length != 0).map(item => item.hotelReqs).flat();
        // console.log('selhotels length ' + selectedHotels.length + 'selhotels ' + JSON.stringify(selectedHotels));
        let destinationReady = selectedHotels.filter(sh => sh.finalised == false && sh.skipped == false).length > 0;
        // console.log('destinatioReady ? ' + destinationReady);

        let lastUnfinalised = destinationReady && (selectedHotels.filter(sh => sh.finalised == true || sh.skipped == true).length == (hotelReqs.length - 1))

        // console.log('lastUnfinalised ? ' + lastUnfinalised);
        return (
            <div>
                <HotelResultsHeader destinationCode={destinationCode} totalDestinations={hotelReqs.length} currentDestinationNum={selectedHotels.length + 1} />
                <Title>{getTranslation("roomQtyType")}</Title>
                <Title>{getTranslation("selectHotel")}</Title>
                <div style={{ padding: "24px", background: "#f8f8f8" }}>
                    <Sorter
                        handleUpdateHotelFilteredResults={
                            this.props.handleUpdateHotelFilteredResults
                        }
                        sortArray={sortArray}
                        data={hotels}
                    />
                    {!loadingFiltered && hotels.length > 0 && (

                        <ReactProgressiveList
                            initialAmount={loadCount}
                            progressiveAmount={loadCount}
                            renderItem={this.renderRow}
                            renderLoader={() => <Loader />}
                            rowCount={
                                isMobile || rowCount > loadCount ? hotels.length : rowCount
                            }
                            useWindowScroll
                        />
                    )}
                    {
                        <Button
                            onClick={() => lastUnfinalised ? this.handleAllFinalised(true) : this.handleSkipAccommodation()}
                            disabled={false}
                            color={theme.primary}
                        >
                            {getTranslation("skip")}
                        </Button>
                    }
                    {destinationReady && (
                        <Button
                            onClick={() => lastUnfinalised ? this.handleAllFinalised(false) : this.handleFinalise(false)}
                            disabled={false}
                            color={theme.primary}
                        >
                            {lastUnfinalised ? getTranslation("addPassengers") : getTranslation("addAccommodation")}
                        </Button>
                    )}
                    {/* {!loadingFiltered &&
                    !isMobile &&
                    rowCount <= loadCount &&
                    trips.length > loadCount && (
                        <div className="loadMoreWrapper">
                            <FadeLine />
                            <div style={{ textAlign: "center" }}>
                                <Button
                                    onClick={this.handleLoadMore}
                                    color="#fff"
                                    style={{
                                        height: 32,
                                        maxWidth: 65,
                                        borderRadius: "0 0 16px 16px",
                                    }}
                                >
                                    <ArrowDown stroke={theme.secondary} />
                                </Button>
                            </div>
                        </div>
                    )} */}
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = {
    handleUpdateHotelFilteredResults,
    handleSelectedHotel,
    handleDeselectHotel,
    handleFinaliseHotel
};

const mapStateToProps = state => ({
    hotelsUnavailable: state.hotelResults.hotelsUnavailable,
    selectedHotels: state.hotelResults.selectedHotels,
    destinationCode: state.hotelResults.destinationCode,
    hotelDestinationCode: state.hotelResults.hotelDestinationCode,
    availabilityKey: state.hotelResults.availabilityKey,
    occupancies: state.hotelResults.occupancies,
    hotels: state.hotelResults.hotels,
    nightCount: state.hotelResults.nightCount,
});


export default withTheme(
    compose(
        graphql(submitAvailabilityRequestGql, { name: "submitAvailabilityRequest" }),
        connect(mapStateToProps, mapDispatchToProps))
        (HotelResults));