import gql from 'graphql-tag';

export const addHOPRequest = gql`mutation addHoprequest(
    $hopSeqNum: Int!,
    $adultCount: Int!,
    $childCount: Int!,
    $infantCount: Int!,
    $stdDate: String!,
    $fromAirportIc: String!,
    $toAirportIc: String!,
    $tripId: Int!
  ) {
    addHoprequest(
      hopSeqNum: $hopSeqNum,
      adultCount: $adultCount,
      childCount: $childCount,
      infantCount: $infantCount,
      stdDate: $stdDate,
      fromAirportIc: $fromAirportIc,
      toAirportIc: $toAirportIc,
      tripId: $tripId
    ) {
      id
    }
  }
  `;
export const addHopSegemntControl = gql`mutation addHopSegmentControlsForHoprequest(
  $hoprequestId: Int!
) {
  addHopsegmentcontrolsForHoprequest(
    hoprequestId: $hoprequestId
  ) {
    id,
    seatClass,
    cancellationPenalty,
    dateChangePenalty,
    seatChoice,
    baggagePieceCount,
    baggagePieceMaxWeight,
    baggagePieceImperial
  }
}`;

export const updTripItineraryQuery = gql`mutation updTripItinerary(
  $tripId: Int!
) {
  updTripItinerary(
    tripId: $tripId
  ) {
    hopRequests {
      hopSeqNum,
      adultCount,
      childCount,
      infantCount,
      stdDate,
      fromAirportIc,
      toAirportIc,
      stopCount,
      hopSegmentControls {
        airlinePnr,
        seatClass,
        cancellationPenalty,
        dateChangePenalty,
        seatChoice,
        baggagePieceCount,
        baggagePieceMaxWeight,
        baggagePieceImperial,
        flightSegments {
          airlineIc,
          flightNum,
          fromAirportIc,
          stdDate,
          stdTime,
          toAirportIc,
          staDate,
          staTime,
          aircraftIcao
        }
      }
    },
    tripCostItems {
        cost,
        costItemLabel,
        costItemCurrencyIso,
        costDecimalPlaces
      }
  }
}`;

export const updTripowner = gql`mutation updTripowner(
      $tripId: Int!
    ) {
      updTripowner(
        tripId: $tripId
      ) {
        id
      }
    }
`;

export const updGuestTripowner = gql`mutation updGuestTripowner(
      $tripId: Int!
      $guestEmail: String!
    ) {
      updGuestTripowner(
        tripId: $tripId
        guestEmail: $guestEmail
      ) {
        id
      }
    }
`;

export const xairportNearestByGeo = gql`query xairportNearestByGeo(
    $latitude: String!,
    $longitude: String!
  ) {
    xairportNearestByGeo(
      latitude: $latitude,
      longitude: $longitude
    ) {
      airportIc
      airportDesc
      city
      country
    }
  }
`;
