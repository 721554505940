import React from "react";
import styled from "styled-components";
import Skeleton from "react-loading-skeleton";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Time from "./Time";
import { getChunks } from "utils/utils";
import flightIcon from "assets/icons/flight.svg";
import {
  MorningIcon,
  AfternoonIcon,
  EveningIcon,
  NightIcon,
  FlightFromError
} from "components/common/SvgIcons";

const Item = styled.div`
  background-color: ${props => (props.disabled ? "transparent" : "#fff")};
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
  display: flex;
  padding: 10px;
  align-items: center;
  margin-bottom: 15px;
  position: relative;
  flex-flow: column;
  .trip {
    width: 100%;
    display: flex;
    justify-content: space-between;
    min-height: 42px;
    .css-19bon8n {
      border-radius: 10em;
    }
    @media (max-width: 373px) {
      flex-flow: column;
    }
    div {
      display: flex;
      align-items: center;
      img {
        margin-right: 10px;
      }
      .MuiFormGroup-root {
        display: flex;
        flex-flow: row;
      }
    }
  }
  .time-section {
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 800px) {
      justify-content: space-around;
    }
  }
  .radioWrapper {
    .MuiRadio-root {
      color: ${props => props.theme.primary};
      &.Mui-checked {
        color: ${props => props.theme.secondary};
      }
    }
    .MuiFormControlLabel-label {
      color: ${props => props.theme.primary};
    }
    .Mui-checked + span {
      color: ${props => props.theme.secondary};
    }
  }
`;

const timeGroups = [
  {
    startTime: "06:00:00",
    endTime: "11:59:59",
    type: "m",
    title: "Morning",
    Icon: MorningIcon
  },
  {
    startTime: "12:00:00",
    endTime: "17:59:59",
    type: "a",
    title: "Afternoon",
    Icon: AfternoonIcon
  },
  {
    startTime: "18:00:00",
    endTime: "23:59:59",
    type: "e",
    title: "Evening",
    Icon: EveningIcon
  },
  {
    startTime: "00:00:00",
    endTime: "05:59:59",
    type: "n",
    title: "Night",
    Icon: NightIcon
  }
];

export default class extends React.PureComponent {
  constructor(props) {
    super(props);
    let direction = "departure";
    const { index } = props;
    props.filterKvs.forEach(filter => {
      const chunks = getChunks(filter.value.split(""), 2);
      direction = chunks[index][1] === "-" ? "departure" : "arrival";
    });
    this.state = {
      selectedDirection: direction
    };
  }

  handleChange = e => {
    this.setState({ selectedDirection: e.target.value });
    this.props.handleDirectionChange(e.target.value, this.props.index);
  };

  handleTimeClick = (time, selected) => {
    this.props.handleTimeToggle(
      time,
      this.props.index,
      selected,
      this.state.selectedDirection
    );
  };

  handleFlightsCheck = (trips, index) => {
    let flightTimings = {
      m: false,
      a: false,
      e: false,
      n: false
    };
    timeGroups.forEach(timeGroup => {
      trips.some(trip => {
        const flightTime =
          this.state.selectedDirection === "departure" ? "stdTime" : "staTime";
        const time =
          trip.hopRequests[index].hopSegmentControls[0].flightSegments[0][
            flightTime
          ];
        if (
          Date.parse(`01/01/2011 ${time}`) >=
            Date.parse(`01/01/2011 ${timeGroup.startTime}`) &&
          Date.parse(`01/01/2011 ${time}`) <=
            Date.parse(`01/01/2011 ${timeGroup.endTime}`)
        ) {
          flightTimings[timeGroup.type] = true;
          return;
        }
      });
    });
    return flightTimings;
  };

  render() {
    const { selectedDirection } = this.state;
    const { hopRequest, filterKvs, index, trips, loading } = this.props;
    const flightTimings = this.handleFlightsCheck(trips, index);
    return (
      <Item>
        <div className="trip">
          <div className="tripItem">
            {loading ? (
              <span style={{ marginRight: 10 }}>
                <FlightFromError stroke="#DDDDDD" />
              </span>
            ) : (
              <img src={flightIcon} alt="" />
            )}
            {loading ? (
              <Skeleton height={16} width={64} />
            ) : (
              <span>
                {hopRequest.fromAirportIc} > {hopRequest.toAirportIc}
              </span>
            )}
          </div>
          {!loading && (
            <div className="radioWrapper">
              <RadioGroup
                aria-label="Direction"
                name="direction"
                value={selectedDirection}
                onChange={this.handleChange}
                color="secondary"
              >
                <FormControlLabel
                  value="departure"
                  control={<Radio color="secondary" />}
                  label="Departure"
                />
                <FormControlLabel
                  value="arrival"
                  control={<Radio color="secondary" />}
                  label="Arrival"
                />
              </RadioGroup>
            </div>
          )}
        </div>
        <div className="time-section">
          {timeGroups.map(time => (
            <Time
              filterKvs={filterKvs}
              disabled={!flightTimings[time.type]}
              index={index}
              time={time}
              handleTimeToggle={this.handleTimeClick}
              loading={loading}
            />
          ))}
        </div>
      </Item>
    );
  }
}
