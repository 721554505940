import React, { useReducer, useEffect } from "react";
import styled from "styled-components";
import moment from "moment";
import { Link } from "react-router-dom";
import gql from "graphql-tag";
import { graphql, compose, withApollo } from "react-apollo";
import { Button, GeoLocation } from "components/common";
import { FlightFromSimple } from "components/common/SvgIcons";
import {
  addHOPRequest,
  updTripItineraryQuery,
} from "containers/FlightResults/queries";
import {
  updTripowner,
  xairportNearestByGeo,
} from "containers/TripBooking/queries";
import {
  getItem,
  setItem,
  removeItem,
  numberToLocaleString,
  addDecimal,
  encode,
  getCityFromAirportIc,
  hex2rgb,
} from "utils/utils";

const Wrapper = styled.div`
  position: relative;
  padding: 20px 0;
  &:before {
    position: absolute;
    content: "";
    background-color: ${(props) => props.color};
    height: 1px;
    width: 240px;
    opacity: 0.32;
    top: 0;
    left: 0;
  }
`;

const addTripQuery = gql`
  mutation addTrip(
    $locale: String!
    $countryIc: String!
    $currencyIso: String!
  ) {
    addTrip(locale: $locale, countryIc: $countryIc, currencyIso: $currencyIso) {
      id
    }
  }
`;

const defaultFromDate = moment()
  .day(6 + 14)
  .unix();
const defaultToDate = moment(defaultFromDate * 1000)
  .add(8, "days")
  .unix();

const BookingSection = ({ color, destinations, client, ...props }) => {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      to: destinations[0].destination,
      from: "BLR",
      date: defaultFromDate,
      returnDate: defaultToDate,
      passengers: {
        infant: 0,
        adult: 1,
        child: 0,
      },
    }
  );

  useEffect(() => {
    console.log("mm");
    removeItem("tripId");
    removeItem("tripSearched");
  }, []);
  useEffect(() => {
    if (state.tripID) {
      handleTripOwnerUpdate();
      handleAddHopReq();
    }
  }, [state.tripID]);

  async function handleAddHopReq() {
    let hasError = false;
    try {
      const hopReq1 = await props.addHOPRequestQuery({
        variables: {
          hopSeqNum: 1,
          adultCount: state.passengers.adult,
          childCount: state.passengers.child,
          infantCount: state.passengers.infant,
          stdDate: moment(state.date * 1000).format("YYYY-MM-DD"),
          fromAirportIc: state.from,
          toAirportIc: state.to,
          tripId: state.tripID,
        },
      });
      if (!hopReq1.data.addHoprequest.id) {
        hasError = true;
      }
      const hopReq = await props.addHOPRequestQuery({
        variables: {
          hopSeqNum: 2,
          adultCount: state.passengers.adult,
          childCount: state.passengers.child,
          infantCount: state.passengers.infant,
          stdDate: moment(state.returnDate * 1000).format("YYYY-MM-DD"),
          fromAirportIc: state.to,
          toAirportIc: state.from,
          tripId: state.tripID,
        },
      });
      if (!hopReq.data.addHoprequest.id) {
        hasError = true;
      }
      if (!hasError) {
        handleUpdTriItenerary();
      }
    } catch (err) {
      hasError = true;
      console.log("addHOPRequestQuery", err);
    }
  }

  async function handleUpdTriItenerary() {
    try {
      const itenaryData = await props.updTripItinerary({
        variables: {
          tripId: state.tripID,
        },
      });
      const costItems = itenaryData.data.updTripItinerary.tripCostItems;
      if (Array.isArray(costItems) && costItems.length > 0) {
        const sum = costItems.reduce(
          (accumulator, currentVal) => accumulator + currentVal.cost,
          0
        );
        if (sum > 0) {
          const totalCostInUnits = addDecimal(
            sum,
            costItems[0].costDecimalPlaces
          );
          const localizedTotalCost = numberToLocaleString(totalCostInUnits, {
            selectedCountry: getItem("selectedLanguage"),
            selectedCurrency: costItems[0].costItemCurrencyIso,
          });
          setState({
            tripCost: localizedTotalCost,
            encodedSearch: encode([state]),
          });
          setItem("tripSearched", true);
        }
      }
    } catch (err) { }
  }

  function handleLocale(payload) {
    if (!getItem("tripId")) {
      props
        .addTripQuery({
          variables: {
            locale: payload.locale,
            countryIc: payload.countryCode.toUpperCase(),
            currencyIso: payload.currencyIso,
          },
        })
        .then(({ data }) => {
          // console.log("Generated new trip id");
          setItem("tripId", data.addTrip.id);
          setState({ tripID: data.addTrip.id });
        });
    } else {
      handleAddHopReq();
    }
  }

  function handleGeoSuccess(coords, client) {
    if (coords.latitude === 0 && coords.longitude === 0) {
      return;
    }
    client
      .query({
        query: xairportNearestByGeo,
        variables: {
          latitude: coords.latitude.toString(),
          longitude: coords.longitude.toString(),
        },
      })
      .then((airport) => {
        if (airport.data) {
          console.log(airport.data.xairportNearestByGeo);
          this.setState({
            from: { ...airport.data.xairportNearestByGeo },
          });
        }
      });
  }

  function handleTripOwnerUpdate() {
    if (getItem("tokenDetails")) {
      props
        .updTripowner({
          variables: {
            tripId: getItem("tripId"),
          },
        })
        .then((data) => {
          console.log(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  const iconColor = hex2rgb(color, 0.8);
  return (
    <>
      <GeoLocation
        handleSuccess={handleGeoSuccess}
        handleLocale={handleLocale}
        client={client}
        sendCoords
      />
      <Wrapper color={color}>
        <div
          style={{ marginBottom: 20, display: "flex", alignItems: "center" }}
        >
          <FlightFromSimple stroke={iconColor} />
          <span style={{ marginLeft: 10, color: "#222222", fontSize: 14 }}>
            Return Flights From {getCityFromAirportIc(state.from)} to{" "}
            {getCityFromAirportIc(state.to)},{" "}
            {moment(state.date * 1000).format("DD MMM")} -{" "}
            {moment(state.returnDate * 1000).format("DD MMM")}, 1 Adult
          </span>
        </div>
        <div style={{ maxWidth: 200 }}>
          <Link
            to={
              state.encodedSearch
                ? `/flights/${state.encodedSearch}`
                : "/new-trip"
            }
          >
            <Button style={{ height: 40, color: "#fff" }} color={color}>
              Book now {state.tripCost ? ` for ${state.tripCost}` : ""}
            </Button>
          </Link>
        </div>
      </Wrapper>
    </>
  );
};

export default withApollo(
  compose(
    graphql(addTripQuery, { name: "addTripQuery" }),
    graphql(addHOPRequest, { name: "addHOPRequestQuery" }),
    graphql(updTripItineraryQuery, { name: "updTripItinerary" }),
    graphql(updTripowner, { name: "updTripowner" })
  )(BookingSection)
);
