import React from "react";
import styled from "styled-components";
import getTranslation from "utils/getTranslation";

const Wrapper = styled.div`
  background-color: #fff;
  width: 100%;
  &.fixed {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 999;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.12);
  }
`;

const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  background: ${props =>
    props.loading ? "#F0F0F0" : props.theme.primaryBackground};
  box-shadow: ${props =>
    props.loading
      ? "none"
      : "inset 0px -2px 4px rgba(255, 255, 255, 0.12), inset 0px 2px 0px " +
        props.theme.primary};
  height: 32px;
  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 32px;
  margin: 0 30px 10px;
  span {
    font-size: 14px;
    font-family: "Roboto Condensed", sans-serif;
    color: ${props => props.theme.secondary};
    text-transform: uppercase;
  }
`;
export default ({ handleClose, fixed, loading }) => (
  <Wrapper className={fixed ? "fixed" : ""}>
    <Head onClick={handleClose} loading={loading}>
      {!loading && (
        <span>
          {getTranslation(fixed ? "fillPassengerDetails" : "passengerDetails")}
        </span>
      )}
    </Head>
  </Wrapper>
);
