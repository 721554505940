export default `
# Introducción
Fly Strait OÜ, registrado en Tallinn, Estonia con número de registro 14373971 ("nosotros", "nos", "nuestro" o simplemente "Fly Strait") es el llamado "controlador de datos" de sus datos personales y, por lo tanto, es responsable de la legalidad en la utilización de sus datos personales.

&nbsp;

Este sitio web es operado por Fly Strait. Manejamos una variedad de datos personales, como nombres, direcciones de correo electrónico y otra información relacionada con los viajes en sus negocios diarios. Por lo tanto, nos tomamos muy en serio la seguridad de los datos y la adhesión a la legislación de protección de datos. Esta política de privacidad explica cómo recopilamos, almacenamos, usamos y divulgamos los datos personales que recopilamos sobre usted cuando utiliza este sitio web, y cómo protegemos la privacidad y confidencialidad de su información personal. Su privacidad nos importa, ya sea usted un miembro nuevo de nuestro servicio o un usuario regular, tómese tiempo para conocer nuestras prácticas y contáctenos si tiene alguna pregunta.

# QUÉ DATOS PERSONALES RECOPILAMOS?
En general, el tipo de datos personales que recopilamos es la información que necesitamos para permitirle hacer sus reservaciones y reservas de viaje. Esto incluye información como su nombre y apellido, fecha de nacimiento, número de teléfono y dirección de correo electrónico. Los datos personales que debemos recibir para proporcionarle el servicio de viaje que reservó a través de nuestro sitio web son los únicos datos que es obligatorio proporcionar. Dependiendo del tipo de servicios de viaje que utilice, también podemos recopilar su número de viajero frecuente, información sobre sus requisitos dietéticos y problemas de salud (si corresponde), y otros detalles que sean relevantes para sus opciones de viaje o que sean requeridos por otro proveedor de servicios de viaje (como aerolíneas y hoteles). Esta no es una lista exhaustiva. Si llama a nuestro servicio de asistencia, recopilaremos los datos que nos proporcione durante la llamada telefónica. Como puede ver a continuación, nuestras cookies también recopilan información.

&nbsp;

Si realiza una reserva para un tercero a través de nuestro sitio web, solicitaremos datos personales sobre esa persona. En esas circunstancias, confiamos en usted para asesorar a esas personas sobre esta política de privacidad.

# QUÉ DATOS PERSONALES SENSIBLES RECOPILAMOS
En algunos casos, podemos manejar las llamadas "categorías especiales de datos personales" sobre usted, que pueden considerarse confidenciales. Este sería el caso, por ejemplo, si (i) ha presentado un certificado médico para el uso de una protección de cancelación o el reembolso de una aerolínea (ii) tiene una condición médica o de salud que afecta su viaje y para la cual solicita asistencia o donde se necesita cierta autorización, o (iii) ha realizado una solicitud que revela algunos otros datos personales confidenciales sobre usted.

&nbsp;

Antes de que manejemos datos personales confidenciales sobre usted, requerimos su consentimiento para hacerlo. Por lo tanto, le pedimos que utilice los formularios de contacto dedicados en nuestros sitios web para enviar datos confidenciales. Los formularios de contacto le permiten darnos el consentimiento requerido según la legislación de protección de datos aplicable. Dicho consentimiento podrá retirarse en cualquier momento. No manejaremos ninguna información personal confidencial que no nos permita manejar usted o que no nos haya proporcionado. Una cantidad limitada de nuestro personal tendrá acceso a sus datos personales confidenciales, y después de manejar sus datos confidenciales de acuerdo con su solicitud, los borraremos tan pronto como sea posible.

# Qué hacemos con sus datos personales
Para poder manejar sus datos personales, las legislaciones de protección de datos aplicables nos obligan a tener una llamada "base legal" para cada uno de nuestros propósitos al procesar sus datos personales. Por este motivo, hemos redactado la tabla siguiente para mostrar nuestra base legal para cada uno de nuestros propósitos.

| Lo que hacemos (nuestros propósitos con el manejo de sus datos personales) | Base jurídica | Tiempo de almacenamiento |
| --- | --- | --- |
| Habilitar los servicios de viaje y las reservas que nos ha solicitado (es decir, la reserva de servicios de viaje mediados por nosotros, así como la prestación de nuestros propios servicios). | Ejecución de un contrato con usted. Cuando nos ha proporcionado datos personales confidenciales, el consentimiento es la base legal. | 3 años desde la fecha de compra. El consentimiento para datos personales confidenciales puede ser retirado en cualquier momento.|
| Si ha elegido crear una cuenta de usuario en nuestro sitio web, la pondremos a su disposición. La cuenta incluye acceso a la información sobre sus servicios de viaje y reservas anteriores. También almacenaremos su nombre de usuario y contraseña. | Ejecución de un contrato con usted. | Los datos de su cuenta de usuario, así como la información personal relacionada con sus servicios de viaje y reservas anteriores, se almacenarán hasta que decida cancelar su cuenta de usuario a través de nuestro sitio web. Sin embargo, si no ha iniciado sesión en su cuenta de usuario durante 18 meses, podemos borrar dicha cuenta y la información que contiene, siempre que no tengamos otra base legal para el almacenamiento. |
| Si ha iniciado un proceso de reserva, pero no ha completado la compra, es posible que le enviemos un correo electrónico con un enlace al resultado de la búsqueda o a la reserva iniciada, según el momento en que se interrumpió su proceso de reserva en el sitio web. | Nuestro interés legítimo para realizar negocios y permitirle completar su compra sin tener que completar toda la información una vez más. Si no desea estos correos electrónicos, puede desabonarse para no recibirlos en cualquier momento dentro del correo electrónico. | 24 horas a partir del momento en que abandone el proceso de reserva. |
| Antes de que comience su servicio de viaje, le proporcionaremos información adicional y ofertas relacionadas con su opción de viaje específico, tales como posibles complementos como equipaje adicional y otra información útil para su viaje. Parte de la información se basa en el perfil realizado a partir de los datos que ha proporcionado durante el proceso de reserva (por ejemplo, la fecha o fechas de viaje, su destino, etc.). | Nuestro interés legítimo es ofrecerle un viaje más conveniente y permitirle encontrar fácilmente más información relevante para usted. Si no desea esta información, puede desabonarse para no recibirla en cualquier momento dentro del correo electrónico. | No más allá del comienzo de su viaje. Otro procesamiento similar puede continuar de acuerdo con los propósitos establecidos a continuación. |
| Después de su servicio de viaje, podemos proporcionarle nuestro boletín que contiene recomendaciones sobre otros servicios de viaje y productos y servicios relacionados con el viaje que puedan ser de su interés. Algunas de las recomendaciones se basan en el perfil de sus elecciones anteriores al reservar un viaje y su reacción a nuestros correos electrónicos. | Nuestro interés legítimo para hacer negocios y permitirle hacer arreglos de viaje de su interés. Si no desea estos correos electrónicos, puede desabonarse para no recibirlos en cualquier momento dentro del correo electrónico. Además, antes de finalizar una compra, puede rechazar la recepción del boletín. | 3 años desde la fecha de compra. |
| Registrar las llamadas telefónicas con fines de garantía de calidad y para cualquier solicitud o consulta futura que realice. | Nuestro interés legítimo para (i) mejorar nuestro servicio a través de la educación interna, y donde corresponda (ii) resolver sus posibles solicitudes o reclamaciones. Si no desea que se grabe la llamada telefónica, puede rechazar dicha grabación antes de que comience la grabación. | 6 meses desde la fecha de la llamada telefónica. Sólo un número limitado de personas tiene acceso a su grabación. |
| El uso de cookies para, por ejemplo, mejorar el uso de este sitio web, proporcionar una experiencia personalizada y recopilar estadísticas de uso. También utilizamos cookies de sesión para mejorar la seguridad de este sitio web. | Nuestro interés legítimo para (i) mejorar nuestro sitio web, (ii) mostrarle ofertas de su interés y (iii) tener una oferta de servicios y un sitio web seguros. Si no desea que almacenemos cookies en su ordenador, puede cambiar la configuración en su navegador en cualquier momento. | Dependiendo del tipo de cookie. [Vea a continuación los tiempos de almacenamiento más detallados.](/privacy#Cookies) |

&nbsp;

Además de lo anterior, adoptamos las medidas diarias que son necesarias para que las empresas presten servicios a los consumidores, como registro, contabilidad, facturación, cumplimiento de las obligaciones contra el blanqueo de dinero y el mantenimiento de la seguridad de nuestro sitio web. En la medida en que esto no sea obligatorio según las leyes aplicables, adoptamos estas medidas en función de nuestro interés legítimo. También podemos analizar el comportamiento de nuestros clientes para mejorar nuestros sitios web y servicios a nivel general. Sin embargo, dicho análisis utilizará datos generalizados o anónimos en un nivel agregado.

# Compartiendo sus datos personales
Solo compartiremos sus datos personales cuando sea necesario para los fines enumerados en esta política de privacidad. Esto puede ser para otras compañías dentro de Fly Strait, las autoridades gubernamentales y nuestros socios comerciales de confianza. Por ejemplo, podemos compartir sus datos personales (incluidos los datos personales confidenciales cuando corresponda) con socios comerciales como aerolíneas, proveedores de hoteles, compañías de seguros y sistemas de distribución global (también llamados GDS) para permitirle realizar sus servicios y reservas de viaje.

&nbsp;

Cada socio es responsable de su propio manejo de sus datos personales después de recibirlos de nosotros, lo que significa que debe comunicarse con el socio en cuestión para cualquier solicitud relacionada con sus derechos según la legislación de protección de datos aplicable. Le recomendamos que lea las políticas de privacidad respectivas de los socios para obtener información sobre el manejo de sus datos personales. También compartiremos sus datos personales con otras empresas (los denominados "procesadores de datos") necesarios para prestar los servicios que solicitó, como proveedores de servicios que operan en nuestros centros de llamadas y nuestros otros proveedores y comerciales que manejarán sus datos personales cuando nos presten sus servicios (por ejemplo almacenamiento externo).

&nbsp;

Debido a la naturaleza global de la industria de viajes, sus datos personales pueden procesarse en diferentes lugares del mundo cuando las partes con las que compartimos sus datos personales residen en un país fuera de la UE / EEE. Nuestro intercambio de datos personales fuera de la UE / EEE requiere cierto fundamento legal en virtud de la legislación de protección de datos aplicable. Cuando la Comisión Europea considere que un país cumple con un nivel adecuado de protección de datos personales, este será nuestro fundamento legal. De lo contrario, existen tres tipos principales de fundamento legal en los que podemos basar dicho intercambio. Estos son:

&nbsp;

i. que la transferencia es necesaria para nuestra ejecución del contrato con usted (por ejemplo, cuando ha reservado un vuelo con una aerolínea que reside fuera de la UE / EEE);

&nbsp;

ii. que la transferencia se basará en las cláusulas estándar de protección de datos para la transferencia de datos personales a países fuera de la UE / EEE adoptada por la Comisión Europea (puede encontrar una copia de estas cláusulas estándar de protección de datos en http://ec.europa.eu/justice/dataprotection/internationaltransfers/transfer/); y

&nbsp;

iii. el Escudo de Privacidad de la UE y los EE. UU., donde se realiza la transferencia a los Estados Unidos y el destinatario está debidamente certificado.

# Otros Proveedores
Tenga en cuenta que nuestro sitio web contiene enlaces a otros sitios web y sirve contenido de proveedores externos. Esta política de privacidad solo se aplica a nuestro sitio web y nuestros servicios. Cuando sigue los enlaces a otros sitios web o utiliza servicios y productos de terceros, debe leer sus políticas de privacidad. Además, si elige comunicarse con nosotros a través de las redes sociales, esta política de privacidad no se aplica a los datos personales que envíe como parte de dicho contacto; en tal caso, le recomendamos que lea la política de privacidad de dicho proveedor de redes sociales.

# Your rights
De acuerdo con la legislación de protección de datos aplicable, usted tiene ciertos derechos como el denominado "sujeto de datos". A continuación, hemos enumerado sus derechos. Sus derechos incluyen lo siguiente:

i. Derecho de acceso: tiene derecho a acceder a los datos personales que manejamos. También tiene derecho a recibir cierta información sobre lo que hacemos con los datos personales. Dicha información se proporciona en este documento.

&nbsp;

ii. Derecho de rectificación: en determinadas circunstancias, tiene derecho a corregir los datos personales inexactos sobre usted y a que se completen los datos personales incompletos. Tenga en cuenta que es posible que no podamos corregir los datos personales imprecisos proporcionados por usted debido, por ejemplo, a las normas de las aerolíneas, y que ese cambio en particular puede incurrir en un costo.

&nbsp;

iii. Derecho de cancelación: en determinadas circunstancias, tiene derecho a que se borren sus datos personales. Es el llamado derecho al olvido.

&nbsp;

iv. Derecho a la restricción de procesamiento: en determinadas circunstancias, usted tiene derecho a limitar la forma en que usamos sus datos personales.

&nbsp;

v. Derecho a la portabilidad de los datos: tiene derecho a recibir sus datos personales (o que sus datos personales se transmitan directamente a otro controlador de datos) en un formato estructurado, de uso común y legible por un aparato.

&nbsp;

vi. Derecho a objetar: tiene derecho a rechazar ciertos tipos de tratamiento de datos personales que llevamos a cabo. Esto se aplica a todas nuestras actividades que se basan en nuestro "interés legítimo". También tiene derecho a presentar una queja ante la autoridad competente de supervisión de protección de datos.

# Cookies
Una cookie es un pequeño archivo de texto que se almacena en su ordenador, algunas solo hasta que cierre su navegador (las llamadas "cookies de sesión") y otras por un período prolongado (las llamadas "cookies permanentes"). Si no desea permitir el almacenamiento de cookies en su ordenador, puede cambiar la configuración en su navegador. Sin embargo, tenga en cuenta que, en algunos casos, es posible que algunas de las funciones de nuestro sitio web no funcionen correctamente y, en consecuencia, que algunos contenidos no se muestren correctamente.

&nbsp;

Este sitio web utiliza cookies por varios motivos, entre ellos, para proporcionar una experiencia personalizada, para mejorar el uso de este sitio web y para recopilar estadísticas de uso. También utilizamos cookies de sesión para mejorar la seguridad de este sitio web.

&nbsp;

En algunos casos, cuando utilizamos cookies, compartimos datos con terceros. Por ejemplo, utilizamos Google Analytics y Google AdWords, servicios que transmiten datos de tráfico del sitio web a los servidores de Google. Google Analytics no identifica a usuarios particulares ni asocia su dirección IP con ningún otro dato del que disponga Google. Usamos los informes proporcionados por Google para ayudarnos a comprender el tráfico del sitio web y el uso de la página web, y optimizar los anuncios comprados a través de la red de publicidad propia y de Google. Google puede procesar los datos de la manera descrita en la [Política de privacidad de Google](https://policies.google.com/privacy) y para los fines establecidos anteriormente en esta sección. Puede inhabilitar Google Analytics si deshabilita o rechaza la cookie, deshabilita JavaScript o utiliza el servicio de inhabilitación proporcionado por Google. [Para inhabilitar las funciones publicitarias de Google utiliza este enlace](https://tools.google.com/dlpage/gaoptout)

&nbsp;

Nuestro sitio web también utiliza el píxel de Facebook, que recopila datos anónimos agregados que nos ayudan a optimizar las compras de anuncios en las diferentes plataformas de Facebook (incluido Instagram). Facebook recopila una identificación de usuario que les permitirá detectar si un usuario ha visitado un sitio con el píxel de Facebook. Nosotros, como anunciantes, sin embargo, nunca podemos identificar el comportamiento de un usuario específico. Facebook y sus plataformas relacionadas se encuentran en un ecosistema de publicidad cerrado donde sus usuarios pueden regular si aceptan que los anunciantes utilicen los datos recopilados de sus sitios web para comprar anuncios en Facebook. [Para ver y cambiar su configuración de publicidad en Facebook use este enlace.](https://facebook.com/ads/preferences)

&nbsp;

Además, nuestro sitio web utiliza scripts de seguimiento de conversión y cookies de [Microsoft Bing](https://privacy.microsoft.com/), [TripAdvisor](https://www.smartertravel.com/privacy-policy) y [TvSquared](https://tvsquared.com/privacy-policy/) (puede ver sus políticas de privacidad siguiendo los enlaces).

&nbsp;

Todos estos servicios recopilan datos estadísticos agregados que nos ayudan a optimizar las compras de anuncios. Nosotros, como anunciantes, no podemos identificar a un solo usuario utilizando estos datos. Puede desactivar el uso de cookies en la configuración de su navegador.

&nbsp;

<!-- [To be transparent, we have summarized the cookies used on this website here. ](https://flystrait.com/cookie-sessions) -->
[Para más información sobre las cookies, visite www.youronlinechoices.com.](https://www.youronlinechoices.com)

# Seguridad de los datos
Para mantener seguros sus datos personales, hemos recurrido a una serie de medidas de seguridad técnicas y organizativas. Por ejemplo, mantenemos altos niveles de seguridad técnica en todos los sistemas (incluida la trazabilidad, recuperación de desastres, limitaciones de acceso, etc.). Además, hemos adoptado políticas para garantizar que nuestros empleados (que por supuesto están sujetos a obligaciones de confidencialidad) no utilicen datos personales cuando no sea necesario. Estas políticas también establecen nuestros estándares para cuando contratamos proveedores o introducimos nuevos sistemas de TI en nuestras operaciones.

# Cómo contactar con nosotros
Si tiene alguna pregunta relacionada con el manejo de sus datos personales o el uso de cookies, o si desea abordar alguno de sus derechos conforme a la legislación de protección de datos aplicable, envíe un correo electrónico a: privacy@flystrait.com

# Cambios a la Póliza de Privacidad
Si cambiamos la forma en que manejamos sus datos personales o cómo usamos las cookies, actualizaremos de inmediato esta política de privacidad y la publicaremos en este sitio web.
`;
