import styled from "styled-components";

export const Dots = styled.div`
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 50px;
  position: relative;
  width: max-content;
  .left {
    position: absolute;
    left: -50px;
    top: -6px;
    width: 26px;
    cursor: pointer;
  }
  .right {
    position: absolute;
    right: -50px;
    top: -6px;
    width: 26px;
    cursor: pointer;
    transform: rotate(-180deg);
  }
  .dot {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin: 0 20px;
    background-color: #007ac7;
    opacity: 0.5;
    cursor: pointer;
    &.active,
    &:hover {
      opacity: 1;
    }
  }
`;
