import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Mask, Steps } from "components/Influencer";
import { Wrapper } from "containers/Influencer/Setup/Styles";
import FormAction from "containers/Influencer/Setup/FormAction";
import { updateInfluencer } from "redux-store/actions";
import ConnectSetup from "components/Influencer/Partials/ConnectSetup";
import getTranslation from "utils/getTranslation";
import { getTipDomain } from "utils/utils";
import {
  getPosts,
  deletePost,
} from "containers/Influencer/queries";
import { withApollo, graphql, compose } from "react-apollo";

const CreatedDomain = styled.div`
  margin-bottom: 50px;
  @media (max-width: 800px) {
    padding: 0 16px;
  }
  a {
    color: #007ac7;
    font-weight: 500;
  }
`;

class ConnectSocial extends React.PureComponent {
  state = { loading: false };
  handleBack = () => {
    this.props.history.push("/influencer/setup/step1");
  };
  handleNext = async () => {
    const { influencerDetails } = this.props;
    try {
      const postsRes = await this.props.client.query({
        query: getPosts,
        fetchPolicy: "no-cache",
        variables: {
          where: {
            influencer_id: { _eq: influencerDetails.id }
          }
        }
      });
      const posts = postsRes.data.post;
      posts.map(async post => {
        await this.props.deletePost({
          variables: {
            where: {
              id: { _eq: post.post_id },
            }
          }
        });
      });
      this.props.history.push("/influencer/setup/step3");
    } catch (err) {
      console.log("error is " + err.message);
    }
  };
  handleToggleLoading = loading => {
    this.setState({ loading });
  };
  render() {
    const { loading } = this.state;
    const { influencerDetails } = this.props;
    return (
      <Wrapper>
        <Mask style={{ height: 52 }} />
        <h5 style={{ textAlign: "center", paddingTop: 10 }}>
          {getTranslation("tipRegistration")}
        </h5>
        <Steps activeStep={2} />
        <div className="container">
          {influencerDetails && !influencerDetails.custom_domain_selected && (
            <CreatedDomain>
              <h2>{getTranslation("createdDomain")}</h2>
              <a>{getTipDomain(influencerDetails)}</a>
            </CreatedDomain>
          )}
          {influencerDetails && (
            <ConnectSetup handleToggleLoading={this.handleToggleLoading} />
          )}
          <FormAction
            activeStep={2}
            loading={loading}
            handleBack={this.handleBack}
            handleNext={this.handleNext}
          />
        </div>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  influencerDetails: state.influencer.influencerDetails
});

export default withApollo(
  compose(
    graphql(deletePost, { name: "deletePost" }),
    connect(mapStateToProps, { updateInfluencer })
  )(ConnectSocial)
);
