import React, { Component } from 'react';
import styled from 'styled-components';
import PassengerType from './PassengerType';
import getTranslation from "utils/getTranslation";
import AddChildrenAge from "components/common/AddChildrenAge"

const Wrapper = styled.div`
  display: flex;
  margin-bottom: ${props => (props.isStatic ? '0px' : '20px')};
  width: 100%;
  .container {
    margin: auto;
    display: flex;
    flex-direction: column;
  }
`;

const Container = styled.div`
  display: inline-flex;
`;

const PassengerWrapper = styled.div`
  position: relative;
  // margin-right: 8px;
  padding: 0px 11px;
  &:last-child {
    margin-right: 0;
  }
  .age {
    color: #777777;
    text-align: center;
    font-size: 12px;
    font-family: 'Roboto Condensed', sans-serif;
    margin-top: 5px;
  }
`;

class AddPassengerCount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passengers: {
        adult: props.passengers ? props.passengers.adultCount || props.passengers.adult : 1,
        child: props.passengers ? props.passengers.childCount || props.passengers.child : 0,
        infant: props.passengers ? props.passengers.infantCount || props.passengers.infant : 0,
        childrenAges: props.passengers ? props.passengers.childrenAges || props.passengers.childrenAges : []
      },
      activeType: 'adult',
      isExpanded: true
    };
  }

  getTotalCount = () => {
    const { passengers } = this.state;
    return passengers.child + passengers.adult + passengers.infant;
  }

  handleTypeChange = (activeType) => {
    this.setState({
      activeType,
    });
  }

  handleDecrease = () => {
    const { activeType } = this.state;
    this.setState({
      passengers: {
        ...this.state.passengers,
        [activeType]: this.state.passengers[activeType] - 1,
      },
    }, () => {
      this.props.onChange(this.state.passengers);
    });
  }

  handleIncrease = () => {
    const totalCount = this.getTotalCount();
    if (totalCount >= 10) {
      return;
    }
    const { activeType } = this.state;
    this.setState({
      passengers: {
        ...this.state.passengers,
        [activeType]: this.state.passengers[activeType] + 1,
      }
    }, () => {
      this.props.onChange(this.state.passengers);
    });
  }

  handleReset = (type) => {
    this.setState({
      passengers: {
        ...this.state.passengers,
        [type]: type === 'adult' ? 1 : 0,
        childrenAges: []
      },
    }, () => {
      this.props.onChange(this.state.passengers);
    });
  }

  handleUpdateChildrenCount = (childrenAges) => {
    let { passengers } = this.state;
    let infant = 0;
    let child = 0;

    childrenAges.map(childAge => childAge < 2 ? (infant += 1) : (child += 1));
    console.log('passengers ' + JSON.stringify(passengers));
    this.setState({
      passengers: {
        ...passengers,
        child,
        infant,
        childrenAges: childrenAges
      },
    }, () => {
      this.props.onChange(this.state.passengers);
    });
  }

  render() {
    const { activeType, passengers, isExpanded } = this.state;
    const { isStatic, childText } = this.props;
    return (
      < Wrapper isStatic={isStatic} >
        <div className="container">
          <Container isStatic={isStatic}>
            <PassengerWrapper>
              <PassengerType
                isStatic={isStatic}
                type="adult"
                isActive={activeType === 'adult'}
                count={passengers.adult}
                handleTypeChange={this.handleTypeChange}
                handleIncrease={this.handleIncrease}
                handleReset={this.handleReset}
                isExpanded={isExpanded}
              />
              {isExpanded && <div className="age">{getTranslation("adult")}</div>}
            </PassengerWrapper>
            <PassengerWrapper>
              <PassengerType
                isStatic={isStatic}
                type="child"
                isActive={activeType === 'child'}
                count={passengers.child + passengers.infant}
                handleTypeChange={(type) => this.handleTypeChange(activeType === 'child' ? null : type)}
                handleIncrease={() => null}
                handleReset={this.handleReset}
                isExpanded={isExpanded}
              />
              {isExpanded && <div className="age">{childText}</div>}
            </PassengerWrapper>
            <div>{activeType === 'child' && <AddChildrenAge
              updateChildrenCount={this.handleUpdateChildrenCount}
              childrenAges={passengers.childrenAges}
            />}</div>
          </Container>
        </div>
      </Wrapper >
    );
  }
}

export default AddPassengerCount;
