import React from "react";
import styled from "styled-components";
import { graphql, compose } from "react-apollo";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Wrapper } from "containers/Influencer/Setup/Styles";
import {
  insertPost,
  updatePost,
  insertPostDestination,
  deletePostDestination,
  insertItineraryDay,
  deleteItineraryDay,
  deleteItineraryDayTransport
} from "containers/Influencer/queries";
import PostSelection from "components/Influencer/PostTypes/PostSelection";
import { PublishHeader } from "components/Influencer/Partials";
import getTranslation from "utils/getTranslation";
import CoverSelectionModal from "./Partials/CoverSelectionModal";
import ShareModal from "components/Influencer/Partials/ShareModal";

const MainWrapper = styled.div``;

const Container = styled.div`
  margin-top: 100px !important;
  width: 100% !important;
`;

class CreatePost extends React.PureComponent {
  constructor(props) {
    super(props);
    const step3 = props.blogData || { title: "" };
    let coverImages = [];
    if (step3.created_at) {
      coverImages = this.getCoverImages(step3);
    }
    const destinations =
      props.blogData && props.blogData.postdestinations.length > 0
        ? props.blogData.postdestinations.map(item => item.destination)
        : [];
    this.state = {
      destinations,
      isEdit: !!props.blogData,
      step3: { ...step3, __typename: undefined, created_at: undefined },
      coverImages,
      coverModalOpen: false,
      shareModalOpen: false,
      isFormCompleted: false
    };
  }
  componentDidMount() {
    let isFormCompleted = false;
    const { step3 } = this.state;
    if (step3.type == "QUICK") {
      isFormCompleted = !!step3.text && !!step3.title;
    }
    else if (step3.type == "BLOG") {
      isFormCompleted = !!step3.text && step3.title;
    } else if (step3.type == "ITINERARY") {
      let data = null;
      if (step3.itinerarydays) {
        data = step3.itinerarydays.data;
      };
      isFormCompleted = step3.title && data && data.length > 0 && !!data.every((ele) => ele.text && ele.title);
    }
    if (isFormCompleted) {
      this.setState({
        isFormCompleted,
      });
    }
  }

  componentDidUpdate() {
    let isFormCompleted = false;
    const { step3 } = this.state;
    if (step3.type == "QUICK") {
      isFormCompleted = !!step3.text && !!step3.title;
    }
    else if (step3.type == "BLOG") {
      isFormCompleted = !!step3.text && step3.title;
    } else if (step3.type == "ITINERARY") {
      let data = null;
      if (step3.itinerarydays) {
        data = step3.itinerarydays.data;
      };
      isFormCompleted = step3.title && data && data.length > 0 && !!data.every((ele) => ele.text && ele.title);
    }
    if (isFormCompleted !== this.state.isFormCompleted) {
      this.setState({
        isFormCompleted,
      });
    }
  }

  getCoverImages = blogData => {
    let coverImages = [];
    if (blogData.coverimage1) {
      const payload = {
        image: blogData.coverimage1,
        selected: true
      };
      coverImages = [...coverImages, payload];
    }
    if (blogData.coverimage2) {
      const payload = {
        image: blogData.coverimage2,
        selected: true
      };
      coverImages = [...coverImages, payload];
    }
    if (blogData.coverimage3) {
      const payload = {
        image: blogData.coverimage3,
        selected: true
      };
      coverImages = [...coverImages, payload];
    }
    if (blogData.coverimage4) {
      const payload = {
        image: blogData.coverimage4,
        selected: true
      };
      coverImages = [...coverImages, payload];
    }
    return coverImages;
  };

  handleChange = (name, value) => {
    if (name == "type" && value != this.state.step3.type) {
      this.setState({
        step3: {
          ...this.state.step3,
          [name]: value,
          itinerarydays: undefined,
          text: undefined,
          title: "",
        }
      });
      return;
    }
    if (name === "text" && this.state.step3.type === "QUICK") {
      const isSelected = this.state.coverImages.find(image => image.selected);
      if (!isSelected) {
        const images = value.split("!@#");
        const coverImages = images.map(image => ({
          image,
          selected: false
        }));
        this.handleCoverImagesChange(coverImages);
      }
    }
    this.setState({
      step3: {
        ...this.state.step3,
        [name]: value
      }
    });
  };

  handleCoverImagesChange = coverImages => {
    this.setState({
      coverImages
    });
  };

  handleDestinationChange = destinations => {
    this.setState({
      destinations
    });
  };

  handleItineraryChange = days => {
    const data = days.map((day, index) => ({
      ...day,
      day: index + 1
    }));
    this.setState({
      step3: {
        ...this.state.step3,
        itinerarydays: {
          data
        }
      }
    });
  };

  handlePublish = async () => {
    const coverImages = this.state.coverImages.filter(image => image.selected);
    const { isEdit } = this.state;
    this.setState({ loading: true });
    try {
      let response;
      const queryType = isEdit ? "update_post" : "insert_post";
      const payload = {
        ...this.state.step3,
        influencer_id: this.props.influencerDetails.id,
        postdestinations: !isEdit
          ? {
            data: this.state.destinations.map(destination => ({
              destination
            }))
          }
          : undefined,
        coverimage1: coverImages[0] ? coverImages[0].image : null,
        coverimage2: coverImages[1] ? coverImages[1].image : null,
        coverimage3: coverImages[2] ? coverImages[2].image : null,
        coverimage4: coverImages[3] ? coverImages[3].image : null,
        id: undefined
      };
      if (!isEdit) {
        response = await this.props.insertPost({
          variables: {
            objects: [payload]
          }
        });
      } else {
        payload.itinerarydays = undefined;
        const { itinerarydays } = this.state.step3;
        const postId = this.state.step3.id;
        if (this.state.destinations.length > 0) {
          await this.props.deletePostDestination({
            variables: {
              where: {
                post_id: { _eq: postId }
              }
            }
          });
          const postdestinations = this.state.destinations.map(item => ({
            destination: item,
            post_id: postId
          }));
          await this.props.insertPostDestination({
            variables: {
              objects: postdestinations
            }
          });
        }
        if (itinerarydays.data && itinerarydays.data.length > 0) {
          let dayIds = [];
          itinerarydays.data.forEach(item => {
            if (item.id) {
              dayIds = [...dayIds, item.id];
            }
          });
          await this.props.deleteItineraryDayTransport({
            variables: {
              where: {
                itineraryday_id: { _in: dayIds }
              }
            }
          });
          await this.props.deleteItineraryDay({
            variables: {
              where: {
                post_id: { _eq: postId }
              }
            }
          });
          const itineraryDays = itinerarydays.data.map(item => ({
            ...item,
            post_id: postId,
            id: undefined
          }));
          await this.props.insertItineraryDay({
            variables: {
              objects: itineraryDays
            }
          });
        }
        response = await this.props.updatePost({
          variables: {
            _set: payload,
            where: {
              influencer_id: { _eq: this.props.influencerDetails.id },
              id: { _eq: postId }
            }
          }
        });
      }
      this.setState({
        blogData: response.data[queryType].returning[0],
        coverModalOpen: true,
        loading: false
      });
    } catch (err) {
      console.log(err);
      this.setState({ loading: false });
    }
  };

  handleToggleCoverModal = () => {
    this.setState({
      coverModalOpen: !this.state.coverModalOpen
    });
  };

  handleSubmitCoverModal = blogData => {
    if (this.props.influencerDetails.influencerconnects.length > 0) {
      this.setState({
        coverModalOpen: false,
        shareModalOpen: true,
        blogData
      });
    } else {
      this.setState({
        coverModalOpen: false
      });
      this.props.history.push("/influencer/dashboard/posts");
    }
  };

  handleShareClose = () => {
    this.setState({
      shareModalOpen: false
    });
    this.props.history.push("/influencer/dashboard/posts");
  };

  render() {
    const {
      step3,
      coverModalOpen,
      blogData,
      loading,
      isEdit,
      coverImages,
      shareModalOpen,
      isFormCompleted
    } = this.state;
    const { influencerDetails } = this.props;
    return (
      <MainWrapper>
        {coverModalOpen && (
          <CoverSelectionModal
            open={coverModalOpen}
            blogData={blogData}
            influencerDetails={influencerDetails}
            handleCloseModal={this.handleToggleCoverModal}
            handleSubmit={this.handleSubmitCoverModal}
            title="Cover image"
          />
        )}
        {shareModalOpen && (
          <ShareModal
            open={shareModalOpen}
            blogData={blogData}
            handleCloseModal={this.handleShareClose}
            handleSubmit={this.handleShareClose}
            title="Share"
          />
        )}
        <PublishHeader
          text={getTranslation(isEdit ? "editPost" : "createNewPost")}
          route="/influencer/dashboard/posts"
          disabled={
            loading || !isFormCompleted
          }
          handleSubmit={this.handlePublish}
        />
        <Wrapper>
          <Container className="container">
            <PostSelection
              data={step3}
              handleChange={this.handleChange}
              handleItineraryChange={this.handleItineraryChange}
              handleDestinationChange={this.handleDestinationChange}
            />
          </Container>
        </Wrapper>
      </MainWrapper>
    );
  }
}

const mapStateToProps = state => ({
  influencerDetails: state.influencer.influencerDetails
});

export default withRouter(
  compose(
    graphql(insertPost, { name: "insertPost" }),
    graphql(updatePost, { name: "updatePost" }),
    graphql(insertItineraryDay, { name: "insertItineraryDay" }),
    graphql(deleteItineraryDay, { name: "deleteItineraryDay" }),
    graphql(deleteItineraryDayTransport, {
      name: "deleteItineraryDayTransport"
    }),
    graphql(insertPostDestination, { name: "insertPostDestination" }),
    graphql(deletePostDestination, {
      name: "deletePostDestination"
    }),
    connect(mapStateToProps)
  )(CreatePost)
);
