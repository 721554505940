import React from "react";
import styled from "styled-components";
import { ApolloConsumer } from "react-apollo";
import Polling from "components/Payment/childs/Polling";
import { Loader } from "components/common";
import { getItem } from "utils/utils";
import getTranslation from "utils/getTranslation";

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #fff;
`;

class PaymentSuccess extends React.PureComponent {
  constructor(props) {
    super(props);
    if (getItem("paymentType") === "domain") {
      props.history.replace("/domain-payment-process");
    }
    this.state = {};
  }
  handlePaymentComplete = () => {
    this.props.history.push(`/booking-confirmed/${getItem("tripId")}`);
  };

  handlePaymentApproved = () => {
    this.setState({
      paymentError: true,
      errorMessage: getTranslation("paymentApproved")
    });
  };

  handleShowBookingStatus = msg => {
    this.setState({
      paymentError: true,
      errorMessage: msg
    });
  };

  handlePaymentError = error => {
    console.log("paymentError");
    this.handleTripFailed();
  };

  handleTripFailed = () => {
    this.props.history.push("/booking-failed");
  };
  render() {
    const { errorMessage } = this.state;
    return (
      <ApolloConsumer>
        {client => (
          <Wrapper>
            <Polling
              client={client}
              handlePaymentComplete={this.handlePaymentComplete}
              handlePaymentApproved={this.handlePaymentApproved}
              handleShowBookingStatus={this.handleShowBookingStatus}
              handlePaymentError={this.handlePaymentError}
              handleTripFailed={this.handleTripFailed}
            />
            <Loader size={50} />
            <p style={{ marginTop: 50 }}>{errorMessage}</p>
          </Wrapper>
        )}
      </ApolloConsumer>
    );
  }
}

export default PaymentSuccess;
