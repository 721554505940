import React from "react";
import styled from "styled-components";
import domtoimage from "dom-to-image";
import { isMobile } from "react-device-detect";
import { ItemTitle } from "components/Influencer";
import { Button } from "components/common";
import { BlogPostCard } from "components/Influencer/BlogCards";
import CoverCrop from "./CoverCrop";
import getTranslation from "utils/getTranslation";
import swap from "assets/tip/swap.svg";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 800px) {
    flex-flow: column;
  }
  .leftSection {
    width: 136px;
    @media (max-width: 800px) {
      width: 100%;
    }
    .images {
      display: flex;
      flex-flow: column;
      .imageWrapper {
        cursor: pointer;
        margin-bottom: 24px;
        display: flex;
        flex-flow: column;
        @media (max-width: 800px) {
          width: 136px;
          align-items: center;
        }
        .image {
          opacity: 0.5;
          width: 100%;
          height: 100%;
        }
        &.active {
          .image {
            opacity: 1;
          }
        }
      }
    }
  }
  .middleSection {
    @media (max-width: 800px) {
      width: 100%;
    }
  }
  .rightSection {
    width: 296px;
    @media (max-width: 800px) {
      width: 100%;
    }
  }
`;

const layoutDetails = {
  "1": {
    aspectImage1: 1 / 1
  },
  "=": {
    aspectImage1: 8 / 3,
    aspectImage2: 8 / 3
  },
  "||": {
    aspectImage1: 2 / 3,
    aspectImage2: 2 / 3
  },
  L: {
    aspectImage1: 8 / 3,
    aspectImage2: 4 / 3,
    aspectImage3: 4 / 3
  },
  "7": {
    aspectImage1: 4 / 3,
    aspectImage2: 4 / 3,
    aspectImage3: 8 / 3
  },
  "+": {
    aspectImage1: 4 / 3,
    aspectImage2: 4 / 3,
    aspectImage3: 4 / 3,
    aspectImage4: 4 / 3
  }
};

export default class extends React.PureComponent {
  constructor(props) {
    super(props);
    const images = this.getImages(props.blogData);
    this.state = {
      images: JSON.parse(JSON.stringify(images)),
      croppedImages: JSON.parse(JSON.stringify(images)),
      activeImageIndex: 0,
      postData: props.blogData,
      showCrop: true,
      aspect: this.getAspectRatio(0)
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.requestData !== this.props.requestData) {
      domtoimage.toBlob(document.getElementById("layout-images")).then(blob => {
        this.props.handleCropSubmit(
          this.state.croppedImages,
          this.state.postData,
          this.state.images,
          blob
        );
      });
    }
  }

  getImages = blogData => {
    let images = [];
    for (let i = 1; i <= 4; i++) {
      if (blogData[`coverimage${i}`]) {
        images = [...images, blogData[`coverimage${i}`]];
      }
    }
    return images;
  };

  getAspectRatio = activeImageIndex => {
    const layout = layoutDetails[this.props.blogData.coverimage_layout];
    return layout[`aspectImage${activeImageIndex + 1}`];
  };

  handleImageChange = activeImageIndex => {
    this.setState(
      {
        showCrop: false,
        aspect: this.getAspectRatio(activeImageIndex)
      },
      () => {
        setTimeout(() => {
          this.setState({
            activeImageIndex,
            showCrop: true
          });
        }, 10);
      }
    );
  };

  handleCroppedImage = image => {
    const { activeImageIndex, croppedImages, postData } = this.state;
    croppedImages[activeImageIndex] = image;
    this.setState({
      croppedImages,
      postData: {
        ...postData,
        [`coverimage${activeImageIndex + 1}`]: image.base64
      }
    });
  };

  handleSwap = index => {
    const { images, croppedImages, postData } = this.state;
    const image1 = images[index];
    const image2 = images[index + 1];
    const croppedImage1 = croppedImages[index];
    const croppedImage2 = croppedImages[index + 1];
    images[index] = image2;
    images[index + 1] = image1;
    croppedImages[index] = croppedImage2;
    croppedImages[index + 1] = croppedImage1;
    this.setState({
      images,
      croppedImages,
      postData: {
        ...postData,
        [`coverimage${index + 1}`]: image2,
        [`coverimage${index + 2}`]: image1
      }
    });
  };
  render() {
    const { images, activeImageIndex, postData, showCrop, aspect } = this.state;
    return (
      <Wrapper>
        <div className="leftSection">
          <ItemTitle title="Images" />
          <div className="images">
            {images.map((image, i) => (
              <div
                key={i}
                className={`imageWrapper ${
                  activeImageIndex === i ? "active" : ""
                }`}
                onClick={() => this.handleImageChange(i)}
              >
                <img src={image} className="image" alt="" />
                {images.length > 1 && images.length > i + 1 && (
                  <Button
                    onClick={() => this.handleSwap(i)}
                    color="#D0F3FC"
                    style={{
                      height: 32,
                      maxWidth: 32,
                      minWidth: 32,
                      margin: "auto",
                      marginTop: 20
                    }}
                  >
                    <img src={swap} alt="" />
                  </Button>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="middleSection">
          {showCrop && (
            <CoverCrop
              image={images[activeImageIndex]}
              aspect={aspect}
              handleCroppedImage={this.handleCroppedImage}
            />
          )}
        </div>
        <div className="rightSection">
          <ItemTitle title={getTranslation("preview")} />
          <BlogPostCard
            preview
            cropPreview
            fullWidth={isMobile}
            blogData={postData}
            layout={postData.coverimage_layout}
            id="layout-images"
          />
        </div>
      </Wrapper>
    );
  }
}
