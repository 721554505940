export default `
# Termos e condições

Estes termos e condições gerais (&quot;T &amp; Cs&quot;) aplicam-se entre você (&quot;Você&quot; ou o &quot;Cliente&quot;) e Fly Strait OÜ (&quot;Nós&quot; ou &quot;Nós&quot;), o operador do portal de reservas flystrait.com (doravante referido como o &quot;Portal&quot;). É solicitado que você leia cuidadosamente estes T &amp; Cs antes de usar o Portal. Ao usar o Portal, você concorda com estes T &amp; Cs. Se você não aceitar estes T &amp; Cs, pedimos que você não use o Portal e saia do site.

Você deve ter pelo menos 18 anos de idade e atuar em seu papel como consumidor particular para fazer uma reserva no Portal. Um consumidor privado é, ao contrário de um negócio, uma pessoa física que realiza uma transação legal para fins predominantemente fora de suas atividades comerciais e / ou autônomas.

O operador do Portal e seu parceiro contratual para usar o Portal são:

Fly Strait OÜ (Fly Strait)

E-mail: support@flystrait.com

Os T &amp; Cs estão definidos como segue:

#
# 1. ÂMBITO DOS SERVIÇOS

### 1.1 Mediação de Serviços de Viagem

1.1.1 Para os serviços de reserva de voo oferecidos no Portal (denominados &quot;Serviços de Viagem&quot;), atuamos exclusivamente como intermediários. Para esse fim, o nosso papel e obrigações estão limitados a mediação de serviços de viagens que serão prestados por terceiros, como companhias aéreas (doravante, em cada caso, &quot;Provedor de Serviços&quot;).

1.1.2 Consequentemente, o contrato para a prestação efetiva dos Serviços de Viagem entra em vigor diretamente entre Você e o Provedor de Serviços relevante. Nós não somos co-fornecedores dos Serviços de Viagem e não somos parte da relação contratual entre Você e o Provedor de Serviços.

1.1.3 Ao usar o Portal para comprar os Serviços de Viagem, você nos autoriza a mediar com o(s) Provedor(es) de Serviços correspondente(s) em seu nome, incluindo a mediação do pagamento desses Serviços de Viagem, para cuidar que a transação entre Você e o(s) prestador(es) de serviço é executada. O preço emitido para os Serviços de Viagem reais pode incluir uma comissão pelos serviços que prestamos para mediar o contrato entre você e o Provedor de Serviços relevante.

1.1.4 A responsabilidade pelo desempenho real dos Serviços de Viagem mediado através do Portal é exclusivamente da responsabilidade do Prestador de Serviços relevante. Em nossa função de intermediários, não assumimos nenhuma responsabilidade pelos Serviços de Viagem prestados pelos Prestadores de Serviços e não fazemos representações ou garantias (nem expressas nem implícitas) em relação à adequação ou qualidade dos Serviços de Viagem mediados no Portal. Para qualquer reivindicação que você possa ter em relação com o desempenho ou o não desempenho do Serviço de Viagem, o Provedor de Serviços é o destinatário responsável.

1.1.5 Caso Você tenha a oportunidade de solicitar pedidos especiais (como refeições especiais, instalações para deficientes ou assentos para crianças) ao reservar os Serviços de Viagem, nós enviaremos sua solicitação ao Provedor de Serviços relevante. No entanto, não podemos assumir qualquer responsabilidade sobre se o Provedor de Serviços pode realmente cumprir tais desejos.

### 1.2 Condições contratuais aplicáveis

1.2.1 Estes T &amp; Cs aplicam-se à mediação de Serviços de Viagem de acordo com a Seção 1.1, bem como para nossos próprios serviços, de acordo com a Seção 1.2.

1.2.2 Para o acordo entre Você e o Prestador de Serviços relevante (Seção 1.1.2), os termos e condições gerais do Prestador de Serviços relevante (tais como condições de transporte, condições de transferência, condições de seguro ou similares), conforme o provedor de serviços se aplica. Tais termos e condições gerais do Provedor de Serviços relevante serão notificados a você durante o processo de reserva. Como os termos e condições do Provedor de Serviços podem incluir disposições relacionadas à responsabilidade, cancelamento, alterações de reservas e reembolsos (se disponíveis) e outras restrições, é recomendável ler esses termos com atenção.

#
# 2. PROCESSO DE RESERVA E INFORMAÇÕES DE CONTATO

2.1 Durante o processo de reserva, você recebe os meios técnicos necessários para detectar erros nos formulários de entrada e corrigi-los antes de enviar sua solicitação de reserva. Você é solicitado a verificar todos os dados quanto à precisão antes de concluir sua solicitação de reserva. Solicitações de mudança subseqüentes podem levar a custos adicionais significativos.

2.2 Talvez seja necessário entrar em contato com você, por exemplo, no caso de alterações subsequentes nos serviços de viagem adquiridos. Você deve enviar informações precisas de contato, como seu número de telefone e endereço de e-mail. Você também deve verificar continuamente se recebeu uma mensagem da nossa parte.

Também é sua responsabilidade garantir que Você possa receber nossas mensagens. Não nos responsabilizamos se Você não receber uma mensagem nossa devido a circunstâncias razoavelmente fora do nosso controle, incluindo, mas não limitado ao seguinte: (i) Você nos forneceu um endereço de e-mail incorreto; (ii) suas configurações de e-mail não permitirão que nosso e-mail alcance você; ou (iii) suas configurações de e-mail tratam nosso e-mail como spam.

#
# 3. MEDIAÇÃO DE VOOS

### 3.1 Conclusão de contrato e mudanças de preço

3.1.1 Após o envio do seu pedido de reserva, confirmaremos o recebimento do seu pedido por e-mail. Este é o momento em que o contrato de mediação entre Você e Nós passa a existir. Se Você não receber tal confirmação dentro de uma hora após completar sua solicitação de reserva, e nenhum de nossos operadores tiver entrado em contato com você por e-mail ou telefone para indicar qualquer problema, entre em contato conosco por telefone para verificação.

3.1.2 Assim que seus tickets solicitados forem emitidos, você receberá um email de confirmação com um número de ticket. Este é o momento em que um contrato vinculativo entre Você e as companhias aéreas relevantes foi concluído.

3.1.3 Os preços do voo, bem como a disponibilidade de assentos, são inseridos diretamente pela companhia aérea aplicável. Para alterações de preços de companhias aéreas fora do nosso controle que ocorram após o envio do pedido de reserva, mas antes que o contrato com a companhia aérea se torne vinculativo (conforme estabelecido na Seção 3.1.2 acima), o contrato não entrará em vigor e seu pagamento será reembolsado na íntegra. Podemos entrar em contato com você e oferecer a você a opção de aceitar o preço alterado durante o horário normal de funcionamento, no máximo 24 horas depois que tivermos conhecimento da alteração de preço ou no primeiro dia útil após esse período de 24 horas.

### 3.2 Informações sobre reservas e condições de transporte

3.2.1 Com relação aos voos oferecidos no Portal, atuamos exclusivamente como intermediários. O contrato para o desempenho do voo entra em vigor diretamente entre você e a companhia aérea relevante e não aceitamos nenhuma responsabilidade contratual relacionada ao desempenho ou não desempenho do seu voo. A companhia aérea operadora é a única responsável pelo desempenho / não desempenho do seu voo.

3.2.2 Antes de concluir a reserva, os termos e condições da (s) companhia (s) aérea (s) relevante (s) serão disponibilizados para você.

3.2.3 A seguir, por meio de uma visão geral, fornecemos informações sobre as condições relacionadas à reserva e ao transporte normalmente aplicadas pelas companhias aéreas de forma mais ou menos semelhante. No entanto, quaisquer disposições divergentes da companhia aérea relevante prevalecem sobre as informações gerais fornecidas nesta Seção 3.2.3. Portanto, em cada caso, verifique os termos e condições aplicáveis ​​da companhia aérea relevante antes da sua reserva.

#### a. Horários de voo / check-in

Todas as horas especificadas são locais. As chegadas no dia seguinte são indicadas com um &quot;+1&quot; no horário. Os horários de voo indicados são preliminares e estão sujeitos a alterações a curto prazo após a emissão do bilhete; por exemplo, devido a restrições de controle de voo, previsão do tempo ou restrições funcionais da companhia aérea. Por favor, mantenha-se informado sobre os horários atualizados bem antes do seu voo.

Por favor, cumpra os horários de check-in indicados pela companhia aérea. As companhias aéreas têm o direito de recusar o embarque se você estiver atrasado para o check-in. Por favor, observe que algumas companhias aéreas incentivam o check-in através de seu próprio site, pois podem cobrar uma taxa de check-in no check-in manual no aeroporto.

#### b. Combinação de bilhetes individuais

Uma combinação de dois bilhetes únicos de ida, em vez de um bilhete de ida e volta, é claramente marcada como tal durante o processo de reserva. Os bilhetes são tratados independentemente um do outro em caso de cancelamento, alteração, interrupção do tráfego aéreo, como greves e mudanças nos horários dos voos. As regulamentações próprias de cada companhia aérea serão aplicadas.

Bilhetes de avião com diferentes números de reserva são sempre considerados como viagens independentes entre si.

#### c. Bilhetes de avião com vários segmentos / ordem de uso

Seu bilhete de ida e volta ou bilhete só de ida pode consistir em vários segmentos. De acordo com as condições da maioria das companhias aéreas, esses segmentos de voo devem ser usados ​​em seqüência. Caso contrário, muitas companhias aéreas recusarão o transporte em segmentos de voo subsequentes (por exemplo, a não utilização de um segmento de uma viagem poderá invalidar o restante do bilhete). Para bilhetes de ida e volta, um não comparecimento no seu voo de ida pode resultar no cancelamento do seu voo de entrada pela companhia aérea

#### d. Gravidez

Algumas companhias aéreas se recusam a transportar mulheres que passaram da 28ª semana de gravidez no momento do vôo de ida ou volta. Se estiver grávida, deve esclarecer junto da companhia aérea e do seu médico se pode ou não iniciar a viagem.

#### e. Bebês e ingressos infantis

Por favor, entre em contato com a companhia aérea para obter as condições para viajar com uma criança que não tenha um assento separado. Normalmente, as crianças com idade superior a dois anos necessitam de um lugar separado, enquanto as crianças entre os 0 e os 2 anos viajam quando crianças e não lhes será atribuído um lugar próprio. Se a criança atingir a idade de 2 anos antes do final da viagem, um bilhete de criança deve ser reservado para toda a viagem. Os ingressos para bebês não podem ser reservados antes do nascimento, pois o nome correto e a data de nascimento devem coincidir com os indicados no passaporte. Não reembolsaremos quaisquer despesas que surjam se o tipo errado de bilhete for reservado desde o início.

#### f. Menores não acompanhados

Não medeemos a reserva de ingressos para menores não acompanhados. Crianças menores de 18 anos devem ser reservadas para uma viagem na companhia de um adulto. Alguns países e companhias aéreas recusam a entrada de menores de 18 anos, a menos que acompanhados por um responsável legal. Por favor, note que algumas companhias aéreas exigem que menores de 18 anos tragam uma certidão de nascimento para viajar.

#### g. Bagagem perdida / danificada

Dentro da nossa capacidade como intermediário, não nos responsabilizamos por bagagem perdida ou danificada. Qualquer problema deve ser comunicado imediatamente ao representante da companhia aérea no aeroporto.

#### h. Trânsito e pernoite

Geralmente, o transporte terrestre e / ou o alojamento noturno durante a sua viagem não estão incluídos no preço do bilhete de voo. Você é pessoalmente responsável por verificar os horários e preços do transporte terrestre.

#### i. Tempos de conexão entre os voos

Os bilhetes standard reservados no Portal têm tempos de conexão aprovados. Os tempos necessários para fazer conexões entre os voos são calculados pelas companhias aéreas. Se um segmento de voo estiver atrasado e levar a uma conexão perdida, as companhias aéreas são obrigadas a ajudá-lo a alcançar seu destino final (consulte a Seção 9.1).

Quando bilhetes separados são reservados, as companhias aéreas não se responsabilizam por conexões perdidas resultantes de atrasos. Portanto, é sua responsabilidade garantir que o tempo de conexão seja suficiente de acordo com as companhias aéreas e os aeroportos. Quaisquer custos adicionais incorridos devido a ligações perdidas não serão reembolsados.

#### j. Reserva dupla

Uma reserva dupla significa que duas ou mais reservas com o mesmo nome de passageiro foram feitas com a mesma companhia aérea. Se você tiver uma reserva dupla, a companhia aérea poderá cancelar a (s) viagem (s). Isso também pode ocorrer se as reservas tiverem sido feitas com agências de viagens diferentes. Nós não somos responsáveis ​​por cancelamentos feitos pelas companhias aéreas, nem por reembolsos negados pelas companhias aéreas, nos casos em que eles suspeitam de uma reserva dupla.

### 3.3 Linhas aéreas proibidas de operar na UE (lista negra)

Por favor note que certas companhias aéreas estão proibidas de operar dentro da UE, de acordo com uma decisão tomada pela Comissão Europeia em estreita consulta com as autoridades nacionais de transporte aéreo. Tais companhias aéreas são proibidas porque são consideradas inseguras ou não estão sujeitas a controle adequado pelas autoridades de seu país.

Você pode verificar quais companhias aéreas são afetadas por uma proibição operacional através do seguinte link: [Lista Negra (Lista de companhias aéreas não autorizadas a operar na UE)](https://ec.europa.eu/transport/language-selector/site-language_en?destination=node/2840)

### 3.4 Alterações de horários e cancelamento pelas companhias aéreas

3.4.1 O seu acordo com a companhia aérea aplicável pode permitir-lhes cancelar ou alterar as suas reservas. Vamos notificá-lo de quaisquer alterações, uma vez que somos informados deles pela companhia aérea.

3.4.2 Os tempos de voo mostrados na sua confirmação de reserva podem variar entre a data da sua reserva e a data em que você realmente viaja. Em caso de alterações na programação de voos, nós o notificaremos assim que formos informados pela companhia aérea. No entanto, recomendamos que você entre em contato com sua companhia aérea pelo menos 72 horas antes da partida programada de cada voo para garantir que o (s) voo (s) e quaisquer voos de conexão estejam operando conforme o cronograma. Não temos controle sobre as mudanças de horários das companhias aéreas e não assumimos responsabilidade por custos que possam surgir como resultado de tais mudanças.

### 3.5 Alterações e cancelamentos solicitados por você

Para companhias aéreas de baixo custo, consulte a Seção 3.7.

3.5.1 As condições para alterar as reservas de voo (incluindo a alteração do nome do passageiro, destino e data da viagem) e para fornecer reembolsos de cancelamento são definidas pela companhia aérea relevante, que é seu parceiro contratual para fornecer o voo. Nós, como intermediários, não temos influência sobre tais condições.

3.5.2 Se você deseja fazer uma alteração na sua reserva ou solicitar um reembolso de cancelamento, como um serviço próprio adicional, oferecemos para atender a solicitação em seu nome, desde que as condições da companhia aérea permitam tal alteração ou reembolso de cancelamento. Durante o processo de reserva desses serviços adicionais, iremos informá-lo sobre quaisquer outras condições para tais serviços. Alternativamente, você pode, claro, abordar diretamente a companhia aérea relevante.

3.5.3 Para que possamos lidar com as alterações solicitadas por Você, é necessário que recebamos suas solicitações de alteração no máximo 24 horas antes do início da viagem (somente por telefone). Se você adquiriu nosso serviço de bilhete flexível, consulte a seção 10.

Para pedidos de alteração em um prazo menor, recomendamos que você entre em contato diretamente com a companhia aérea relevante.

### 3.6 Não Aparição ou Não Comparecimento do Voo

Por este meio autoriza-nos a cancelar o voo não utilizado em seu nome em caso de não comparência ou não comparecimento do voo e para solicitar possíveis reembolsos da companhia aérea em seu nome. Temos o direito mas não somos obrigados a fazê-lo, e o seu direito de solicitar reembolsos diretamente da companhia aérea não é afetado.

### 3.7 Companhias aéreas de baixo custo

3.7.1 Durante o procedimento de reserva, deixar-se-á claro a Você se o voo aplicável for operado por uma companhia aérea de baixo custo. As seguintes disposições especiais aplicam-se a voos efectuados por companhias aéreas de baixo custo.

3.7.2 Ao intermediar os Serviços de Viagem prestados por companhias aéreas de baixo custo, podemos agir em seu nome ao celebrar o contrato entre Você e a companhia aérea de baixo custo.

3.7.3 Não temos acesso aos sistemas de reservas de companhias aéreas de baixo custo. Portanto, entre em contato diretamente com a companhia aérea de baixo custo para perguntas sobre reservas, alterações ou cancelamento de sua reserva. Se tiver adquirido o nosso serviço de Bilheteira Flexível (consulte a Seção 10), todas as solicitações de nova reserva deverão ser feitas através de nosso serviço ao cliente, conforme estabelecido na Seção 10.1.4.

3.7.4 Observe também que algumas companhias aéreas de baixo custo cobram taxas pelo check-in manual no aeroporto. Para evitar tais taxas, você deve usar a possibilidade de check-in online através do site da companhia aérea relevante de baixo custo. Por favor, informe-se com antecedência antes da sua partida sobre as possibilidades de check-in e as condições da sua companhia aérea.

3.7.5 As confirmações de reserva para voos de baixo custo são enviadas assim que a reserva for confirmada pela respectiva companhia aérea de baixo custo. Você pode receber duas confirmações de reserva - uma de nós e outra da companhia aérea de baixo custo relevante. Se você recebeu duas confirmações de reserva, use a confirmação de reserva da companhia aérea de baixo custo para fazer o check-in.

#
# 4. DISPOSIÇÕES ESPECIAIS PARA A MEDIAÇÃO DE RESERVAS DE MÚLTIPLOS SERVIÇOS

O Portal oferece a possibilidade de misturar e combinar vários serviços individuais como você deseja (por exemplo, voo + hotel). Neste caso, você nos instrui a agir como intermediário para a reserva de vários serviços de viagem de vários fornecedores. Os fornecedores relevantes serão claramente apresentados a você durante o processo de reserva antes da conclusão da reserva. No caso de você combinar serviços individuais, nenhum contrato de viagem é celebrado entre você e nós; em vez disso, você conclui vários contratos para a prestação de serviços individuais com cada fornecedor separado. Neste caso, nós somente operamos dentro de nossa capacidade como intermediário em relação a cada serviço de viagem individual.

#
# 5. INFORMAÇÕES SOBRE PASSAPORTE, VISTOS E SAÚDE

5.1 Passaporte, visto e / ou requisitos de saúde podem mudar e você deve, portanto, fazer as verificações necessárias com a autoridade competente (embaixada, consulado, etc.) bem antes da viagem. É de sua responsabilidade possuir um passaporte válido e, se apropriado, um visto. É importante lembrar-se de incluir todos os pontos de trânsito em sua viagem, que também podem exigir que você obtenha um visto. Muitas vezes, pode levar algum tempo para obter um visto, por isso é aconselhável aplicar com antecedência. Não aceitamos responsabilidade por clientes que não possuam os documentos corretos.

5.2 Cada destino tem os seus próprios requisitos no que diz respeito às formalidades de entrada, vacinas, etc., que também podem variar dependendo da nacionalidade do passageiro. É da sua responsabilidade coletar essas informações. Nenhum incidente decorrente do descumprimento de tais regulamentos oficiais será considerado de nossa responsabilidade. Portanto, pedimos que você sempre verifique as diferentes formalidades dos países de destino ou de trânsito escolhidos, bem como o tempo necessário para executar todas as etapas relacionadas.

#
# 6. ENCARGOS E PAGAMENTO

### 6.1 Pagamento

6.1.1 O pagamento por Serviços de Viagem é processado por nós (em cooperação com nossos prestadores de serviços de pagamento que fornecem a funcionalidade de processamento de pagamentos), ou o Provedor de Serviços. Observe que podemos precisar compartilhar suas informações de pagamento, como informações de cartão de crédito ou débito, com nossos prestadores de serviços de pagamento para podermos processar o pagamento. Todas as informações de pagamento pertencentes aos nossos clientes são criptografadas em um servidor seguro quando compartilhadas com nosso(s) provedor(es) de serviços de pagamento.

6.1.2 Para que possamos processar seu pagamento, você precisa garantir que haja fundos suficientes disponíveis. No caso de surgir algum problema relacionado com o processamento do seu pagamento, iremos repetir o processamento do seu pagamento em cooperação com o(s) fornecedor(es) do serviço de pagamento. Caso isso não resulte em uma retirada de fundos realizada por Você, entraremos em contato com você o mais breve possível para obter instruções sobre outros meios de pagamento. Caso não recebamos seu pagamento após tais instruções adicionais, o pagamento pendente será encaminhado a uma empresa de cobrança de dívidas.

### 6.2 Fraude de pagamento

Se houver motivos para suspeitarmos de fraude, reservamo-nos o direito de recusar o processamento desse pagamento. A legitimação do pagamento pode ser exigida se houver suspeita de crime. Todos os tipos de fraude de pagamento serão denunciados à polícia e encaminhados a uma empresa de cobrança de dívidas.

#
# 7. RECLAMAÇÕES

### 7.1 Reivindicações relacionadas ao desempenho de serviços de viagem

Qualquer problema, observação ou reclamação relacionada ao desempenho real dos Serviços de Viagem deve ser endereçada diretamente ao Provedor de Serviços relevante com quem você detém o contrato para o Serviço de Viagem relevante. Consulte também a seção 9 para seus direitos de acordo com o regulamento da UE.

### 7.2 Reclamações relativas aos nossos próprios serviços

Reclamações relativas aos nossos próprios serviços são tratadas exclusivamente por escrito e devem ser enviadas dentro de dois meses após a data final de sua viagem via e-mail.

Endereço de e-mail: [complaints@flystrait.com](mailto:complaints@flystrait.com)

#
# 8. DIREITOS DO CLIENTE DE ACORDO COM A REGULAMENTAÇÃO DA UE

### 8.1 Voos cancelados ou atrasados

Se você estiver viajando para dentro ou fora da UE, ou em uma transportadora da UE, você pode ter o direito de exigir o reembolso de custos que você pode declarar diretamente contra a companhia aérea relevante caso seu voo seja cancelado, atrasado ou que lhe seja negado o embarque. [Para mais informações sobre o Regulamento EC 261/2004, por favor clique aqui.](http://eur-lex.europa.eu/legal-content/EN/ALL/?uri=CELEX%3A32004R0261)

### 8.2 Responsabilidade do Transportador

O Regulamento CE (889/2002) relativo à responsabilidade das transportadoras aéreas em caso de acidente. [Regulamento pode ser encontrado aqui.](http://eur-lex.europa.eu/legal-content/EN/TXT/?uri=celex%3A32002R0889)

#
# 9. RESPONSABILIDADE

9.1 Você aceita que Nós ajamos como um intermediário entre o Você e o Provedor de Serviços. Em nenhuma circunstância seremos responsabilizados com relação aos Serviços de Viagem que você reservou com um ou mais Prestador(es) de Serviços e não aceitamos qualquer responsabilidade por quaisquer imprecisões nas informações exibidas no Portal que tenham sido fornecidas pelo(s) respectivo(s) Prestador(es) de Serviços.

9.2 Caso um Provedor de Serviços não consiga fornecer o Serviço de Viagens por qualquer motivo, inclusive quando um Provedor de Serviços declarar falência, só poderemos agir como intermediários e reembolsar pagamentos, onde já o recebemos do Fornecedor de Serviços aplicável.

9.3 No que diz respeito aos nossos próprios serviços, somos responsáveis ​​por danos sujeitos às limitações estabelecidas nestes T &amp; C e na medida permitida por lei. Somente seremos responsáveis ​​por danos diretos realmente sofridos, pagos ou incorridos por você devido a uma falha atribuível de nossas obrigações em relação aos nossos próprios serviços, até um valor total do custo de sua reserva (seja para um evento ou uma série de eventos conectados).

9.4 A limitação de responsabilidade estabelecida na Seção 10.3 também se aplica ao descumprimento de obrigações por pessoas responsáveis ​​por culpa da qual somos responsáveis ​​de acordo com as disposições legais.

#
# 10. LEI GOVERNANTE E RESOLUÇÃO DE DISPUTAS

### 10.1 Lei aplicável

A lei da Estônia, com a exclusão da lei uniforme, em particular a Lei de Vendas da ONU sobre a Venda Internacional de Mercadorias, se aplica exclusivamente a estes T &amp; Cs e ao contrato entre Você e nós.

Como consumidor, você se beneficiará de quaisquer disposições obrigatórias da lei do país em que você reside. Nada nestes T &amp; Cs, incluindo esta Seção 11.1, afeta seus direitos como consumidor de confiar em tais provisões obrigatórias da lei local.

### 10.2 Resolução de Disputas Online

A Comissão Europeia fornece uma plataforma para a resolução de litígios em linha (ODS) no seguinte endereço: (http://ec.europa.eu/consumers/odr/)

#
# 11. PROTEÇÃO DE DADOS

Nós consideramos a proteção de seus dados pessoais com total seriedade. Você pode encontrar informações detalhadas sobre a coleta, processamento e uso de seus dados pessoais em nossa [Política de Privacidade.](https://flystrait.com/privacy)
`;
