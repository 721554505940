import React from "react";
import { withApollo } from "react-apollo";
import { Loader } from "components/common";
import CreatePost from "containers/Influencer/Dashboard/Posts/Create";
import { getPostById } from "containers/Influencer/queries";

class EditPost extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      postId: props.match.params.id,
      loading: true,
      blogData: null
    };
  }
  async componentWillMount() {
    try {
      const response = await this.props.client.query({
        query: getPostById,
        variables: {
          id: parseInt(this.state.postId, 10)
        }
      });
      this.setState({
        loading: false,
        blogData: response.data.post_by_pk
      });
    } catch (err) {
      console.log(err);
      this.setState({
        loading: false,
        error: true
      });
    }
  }
  render() {
    const { loading, blogData } = this.state;
    return loading ? <Loader /> : <CreatePost blogData={blogData} />;
  }
}

export default withApollo(EditPost);
