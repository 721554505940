import styled from "styled-components";

export const Title = styled.div`
  text-transform: uppercase;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 16px;
  color: #555555;
  line-height: 19px;
  border-bottom: 1px solid #dddddd;
  margin: 40px 10px 20px 10px;
`;

export const ModalSelectedTitle = styled.div`
  display: flex;
  flex-flow: column;
  span {
    &:first-child {
      color: #444444;
      font-size: 12px;
      line-height: 14px;
      font-family: "Roboto Condensed", sans-serif;
    }
    &.price {
      font-size: 22px;
      color: ${props => props.theme.secondary};
      line-height: 26px;
      margin-top: 5px;
    }
  }
`;
