import React, { Component } from 'react';
import styled from 'styled-components';
import { isMobile } from "react-device-detect";
import { Button } from 'components/common';
import rectangle from 'assets/icons/rectangle.svg';

const Count = styled.div`
  margin-left: 24px;
  margin-top: 15px;
  display: flex;
  flex-flow: column;
  span {
    font-size: 18px;
    color: #222222;
    &.price {
      margin-top: 10px;
      color: #007AC7;
      font-size: 22px;
    }
  }
  .btnWrapper {
    width: 72px;
    margin-top: 20px;
  }
`;

export default class extends Component {
  state = {};

  render() {
    const {
      activeType,
      type,
      count,
      isStatic,
      weight,
      cost,
      handleRemove
    } = this.props;
    return (
      <Count isActive={activeType === type} isStatic={isStatic}>
        {count > 0 ? <span>{count} x {weight} Kg</span> : <span>0 Kg</span>}
        {count > 0 && <span className="price">{count} x {cost}</span>}
        {count > 0 && <div className="btnWrapper">
          <Button
            style={{
              height: 32,
            }}
            color="#ffffff"
            onClick={handleRemove}
          >
            <img src={rectangle} alt="" />
          </Button>
        </div>}
      </Count>
    );
  }
}
