import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import moment from "moment";
import { graphql, compose } from "react-apollo";
import Sticky from "react-sticky-el";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import { isMobile } from "react-device-detect";
import { Link, NavLink, withRouter } from "react-router-dom";
import { updateUserDate, updateInfluencer } from "redux-store/actions";
import { getRefreshAuth, getAccessToken } from "utils/api";
import { getItem, removeItem, setItem } from "utils/utils";
import {
  postIcon,
  mediaIcon,
  connectIcon,
  earningIcon,
  siteActivityIcon,
  settingsIcon,
} from "components/common/SvgIcons";
import { updTripFareruleQuery } from "components/common/TripFareRules/queries";
import CountryAndLocaleModal from "components/common/CountryAndLocaleModal";
import getTranslation from "utils/getTranslation";
import countryList from "utils/countryList";
import logo from "assets/icons/FlyStrait.svg";
import logoTest from "assets/icons/FlyStrait-Test.svg";
import menuIcon from "assets/icons/menu.svg";
import languageIcon from "assets/icons/language.svg";

const Wrapper = styled.div`
  padding: 0 15px;
  display: flex;
  align-items: center;
  background-color: #fff;
  .container {
    height: 56px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .logoContainer {
      display: flex;
      align-items: center;
      .menuIcon {
        margin-right: 15px;
      }
    }
  }
`;

const MenuLinks = styled.div`
  height: 56px;
  display: flex;
  a {
    display: flex;
    align-items: center;
    font-family: "Roboto Condensed", sans-serif;
    padding: 0 10px;
    color: ${(props) => props.theme.primary};
    text-transform: uppercase;
    text-decoration: none;
    &.active {
      color: ${(props) => props.theme.secondary};
      box-shadow: inset 0px 2px 0px ${(props) => props.theme.primary};
    }
  }
`;

const StyledDrawer = styled(Drawer)`
  .MuiBackdrop-root {
    background: rgba(208, 243, 252, 0.5);
  }
  .MuiPaper-elevation16 {
    box-shadow: none;
  }
  .item {
    display: flex;
    align-items: center;
    span {
      margin-left: 16px;
    }
  }
`;

const DrawerWrapper = styled.div`
  width: 300px;
  padding: 15px 32px;
  hr {
    border: 1px solid ${(props) => props.theme.primaryBackground};
  }
  .menuItems {
    .MuiListItem-gutters {
      padding-left: 0;
    }
    a {
      text-decoration: none;
      color: ${(props) => props.theme.secondary};
    }
    .MuiListItemText-primary {
      font-size: 18px;
      text-decoration: none;
      color: ${(props) => props.theme.secondary};
      font-family: "Roboto Condensed", sans-serif;
      text-transform: uppercase;
    }
    &.links {
      .MuiListItemText-primary {
        font-size: 12px;
        text-decoration: underline;
        text-transform: capitalize !important;
      }
    }
  }
  .language {
    img,
    .currency {
      margin-right: 15px;
    }
    .currency {
      width: 16px;
    }
    .MuiListItem-gutters {
      padding-left: 0;
    }
    .MuiListItemText-primary {
      font-size: 15px;
      text-decoration: none;
      color: #777777;
      font-family: "Roboto Condensed", sans-serif;
    }
    .country .MuiListItemText-primary {
      color: ${(props) => props.theme.secondary};
      text-decoration: underline;
    }
  }
`;

const StyledListItem = styled(ListItem)`
  flex-flow: column;
  align-items: flex-start !important;
  .username {
    font-size: 12px;
    color: #777777;
  }
`;

const tipMenuItems = [
  {
    name: getTranslation("posts"),
    route: "posts",
    icon: postIcon,
  },
  {
    name: getTranslation("media"),
    route: "media",
    icon: mediaIcon,
  },
  {
    name: getTranslation("connect"),
    route: "connect",
    icon: connectIcon,
  },
  {
    name: getTranslation("earning"),
    route: "earning",
    icon: earningIcon,
  },
  {
    name: getTranslation("siteActivity"),
    route: "site-activity",
    icon: siteActivityIcon,
  },
  {
    name: getTranslation("settings"),
    route: "settings",
    icon: settingsIcon,
  },
];

let refreshTokenTimer;
class Header extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      openDrawer: false,
    };
  }
  componentDidMount() {
    if (this.props.userData) {
      this.checkForReauth();
      refreshTokenTimer = setInterval(() => {
        this.checkForReauth();
      }, 1000 * 60);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.userData && this.props.userData !== nextProps.userData) {
      this.handleStartInterval();
    }
    if (this.props.userData !== nextProps.userData && !nextProps.userData) {
      if (refreshTokenTimer) {
        clearInterval(refreshTokenTimer);
        refreshTokenTimer = null;
      }
    }

    if (
      !getItem("doAsyncFareRule") &&
      nextProps.doAsyncFareRule !== this.props.doAsyncFareRule
    ) {
      this.doUpdtTripFareRule();
    }
  }

  doUpdtTripFareRule = async () => {
    try {
      await this.props.updTripFarerule({
        variables: {
          tripId: getItem("tripId"),
        },
      });
      setItem("doAsyncFareRule", true);
    } catch (err) {
      console.log(err);
    }
  };

  checkForReauth = async () => {
    const currentTime = moment();
    const expiryTime = moment(this.props.userData.expiryTime * 1000);
    const difference = expiryTime.diff(currentTime, "minutes");
    if (difference < 5) {
      const reauth = await getRefreshAuth(this.props.userData.refresh_token);
      if (!reauth.code) {
        this.handleLogout(true);
        this.props.history.replace("/login");
      }
      const tokenDetails = await getAccessToken(reauth.code);
      tokenDetails.expiryTime = moment()
        .add(tokenDetails.expires_in, "seconds")
        .unix();
      setItem("tokenDetails", tokenDetails);
      this.props.updateUserDate(tokenDetails);
    }
  };

  handleStartInterval = () => {
    if (!refreshTokenTimer) {
      refreshTokenTimer = setInterval(() => {
        this.checkForReauth();
      }, 1000 * 60);
    }
  };

  handleLogout = (ignoreDrawer) => {
    this.props.updateUserDate(null);
    this.props.updateInfluencer(null);
    removeItem("tokenDetails");
    removeItem("tripId");
    removeItem("loggedInUsername");
    removeItem("useraccount_id");
    removeItem("paymentType");
    if (!ignoreDrawer) {
      this.setState({ openDrawer: false });
    }
  };
  handleDrawerToggle = () => {
    this.setState({
      openDrawer: !this.state.openDrawer,
    });
  };
  handleShowCountries = () => {
    this.setState({ openModal: true });
  };
  handleCloseModal = () => {
    this.setState({ openModal: false });
  };
  render() {
    const { openDrawer, openModal } = this.state;
    const { userData } = this.props;
    const page = this.props.location.pathname.split("/");
    const country = getItem("selectedCountry") || "gb";
    const currency = getItem("selectedCurrency") || "EUR";
    const currencyDisplay = getItem("selectedCurrencySymbol") || "€";
    const selectedCountry = countryList.find(
      (item) => item.Code === country.toUpperCase()
    );
    const currentLanguage = getItem("selectedLanguageName") || "English";
    const isDev = process.env.REACT_APP_ENV !== "live";
    return (
      <Sticky className="stickyHeader" stickyStyle={{ zIndex: 1000 }}>
        <Wrapper id="app-header">
          {isMobile && (
            <CountryAndLocaleModal
              open={openModal}
              handleCloseModal={this.handleCloseModal}
            />
          )}
          <div className="container">
            <div className="logoContainer">
              {isMobile && (
                <div className="menuIcon" onClick={this.handleDrawerToggle}>
                  <img src={menuIcon} alt="" />
                </div>
              )}
              <Link to="/">
                <img src={isDev ? logoTest : logo} alt="" />
              </Link>
            </div>
            {!isMobile && (
              <MenuLinks>
                <NavLink to="/contact" activeClassName="active">
                  {getTranslation("contact")}
                </NavLink>
                <NavLink to="/influencer" activeClassName="active">
                  {getTranslation("influencer")}
                </NavLink>
                <NavLink
                  to="/new-trip"
                  className={
                    page[1] === "trip" ||
                      page[1] === "new-trip" ||
                      page[1] === ""
                      ? "active"
                      : ""
                  }
                >
                  {getTranslation("bookNewTrip")}
                </NavLink>
                <NavLink to="/my-trips" activeClassName="active">
                  {getTranslation("myTrips")}
                </NavLink>
                {!userData && (
                  <NavLink to="/login" activeClassName="active">
                    {getTranslation("login")}
                  </NavLink>
                )}
                {userData && (
                  <NavLink to="/login" onClick={this.handleLogout}>
                    {getTranslation("logout")}
                  </NavLink>
                )}
              </MenuLinks>
            )}
            {isMobile && (
              <StyledDrawer open={openDrawer} onClose={this.handleDrawerToggle}>
                <DrawerWrapper>
                  <div>
                    <img src={isDev ? logoTest : logo} alt="" />
                  </div>
                  <div className="menuItems">
                    <List component="nav">
                      <Link to="/new-trip" onClick={this.handleDrawerToggle}>
                        <ListItem button>
                          <ListItemText
                            primary={getTranslation("bookNewTrip")}
                          />
                        </ListItem>
                      </Link>
                      <Link to="/influencer" onClick={this.handleDrawerToggle}>
                        <ListItem button>
                          <ListItemText
                            primary={getTranslation("influencer")}
                          />
                        </ListItem>
                      </Link>
                      <Collapse in timeout="auto" unmountOnExit>
                        <List component="div" disablePadding></List>
                        {tipMenuItems.map((item) => (
                          <Link
                            to={`/influencer/dashboard/${item.route}`}
                            onClick={this.handleDrawerToggle}
                          >
                            <ListItem button>
                              <div
                                className={`item ${
                                  item.route === page[1] ? "active" : ""
                                  }`}
                                key={item.route}
                              >
                                <item.icon
                                  stroke={
                                    item.route === page[1]
                                      ? "#007AC7"
                                      : "#56CCF2"
                                  }
                                />
                                <span>{item.name}</span>
                              </div>
                            </ListItem>
                          </Link>
                        ))}
                      </Collapse>
                      <Link to="/my-trips" onClick={this.handleDrawerToggle}>
                        <ListItem button>
                          <ListItemText primary={getTranslation("myTrips")} />
                        </ListItem>
                      </Link>
                      {!userData && (
                        <Link to="/login" onClick={this.handleDrawerToggle}>
                          <ListItem button>
                            <ListItemText primary={getTranslation("login")} />
                          </ListItem>
                        </Link>
                      )}
                      {userData && (
                        <Link to="/login" onClick={() => this.handleLogout()}>
                          <StyledListItem button>
                            <span className="username">
                              {getItem("loggedInUsername")}
                            </span>
                            <ListItemText primary={getTranslation("logout")} />
                          </StyledListItem>
                        </Link>
                      )}
                      <Link to="/contact" onClick={this.handleDrawerToggle}>
                        <ListItem button>
                          <ListItemText primary={getTranslation("contact")} />
                        </ListItem>
                      </Link>
                    </List>
                  </div>
                  <hr />
                  <div className="language">
                    <ListItem button onClick={this.handleShowCountries}>
                      <img src={require(`assets/icons/globe.svg`)} alt="" />
                      <ListItemText
                        className="country"
                        primary={selectedCountry.en}
                      />
                    </ListItem>
                    <ListItem button>
                      <img src={languageIcon} alt="" />
                      <ListItemText primary={currentLanguage} />
                    </ListItem>
                    <ListItem button>
                      <span className="currency" style={{ color: "#56CCF2" }}>
                        {currencyDisplay}
                      </span>
                      {/* <img src={currencyIcon} alt="" /> */}
                      <ListItemText primary={currency} />
                    </ListItem>
                  </div>
                  <hr />
                  <div className="menuItems links">
                    <List component="nav">
                      <Link to="/privacy" onClick={this.handleDrawerToggle}>
                        <ListItem button>
                          <ListItemText primary={getTranslation("privacy")} />
                        </ListItem>
                      </Link>
                      <Link to="/terms" onClick={this.handleDrawerToggle}>
                        <ListItem button>
                          <ListItemText primary={getTranslation("terms")} />
                        </ListItem>
                      </Link>
                    </List>
                  </div>
                </DrawerWrapper>
              </StyledDrawer>
            )}
          </div>
        </Wrapper>
      </Sticky>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.user.userData,
  doAsyncFareRule: state.flightResults.doAsyncFareRule,
  selectedLanguage: state.translations.selectedLanguage,
});

const mapDispatchToProps = {
  updateUserDate,
  updateInfluencer,
};

export default withRouter(
  compose(
    graphql(updTripFareruleQuery, { name: "updTripFarerule" }),
    connect(mapStateToProps, mapDispatchToProps)
  )(Header)
);
