import React from "react";
import styled from "styled-components";
import { getTipDomain } from "utils/utils";
import link from "assets/tip/Link.svg";

const Wrapper = styled.div`
  &.activatedDomain {
    display: flex;
    flex-flow: column;
    div {
      display: flex;
      align-items: center;
      img {
        margin-right: 15px;
      }
      span {
        color: #007ac7;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.2px;
      }
    }
    span {
      color: #444444;
      font-size: 14px;
    }
  }
`;

export default ({ domainLink, children }) => (
  <Wrapper className="activatedDomain">
    <div>
      <img src={link} alt="" />
      <span>{domainLink}</span>
    </div>
    {children}
  </Wrapper>
);
