export default `
Bu genel hüküm ve koşullar sizinle (“Siz” veya “Müşteri”) ile rezervasyon portalı flystrait.com işletmecisi olan Fly Strait OÜ (“Biz” veya “Bizim”) (metnin devamında “Portal” olarak anılacaktır), arasında geçerlidir. Portalı kullanmaya başlamadan önce bu Hüküm ve Koşulları dikkatli bir şekilde okumanız istenmektedir. Portalı kullanarak, bu Hüküm ve Koşulları kabul edersiniz. Bu Hüküm ve Koşulları kabul etmiyorsanız, Portalı kullanmamanızı ve web sitesinden çıkmanızı rica ediyoruz.

&nbsp;

Portal üzerinden rezervasyon yapabilmek için en az 18 yaşında olmalı ve bireysel bir müşteri olarak hareket ediyor olmalısınız. Şirketin aksine bireysel müşteri, ticari ve/veya serbest meslek faaliyetleri dışındaki amaçlarla hukuki bir sözleşmeye giren gerçek bir kişidir.

Portalın işletmecisi ve Portalı kullanırken sözleşmenin diğer tarafı olan kuruluş:

&nbsp;

Fly Strait OÜ (Fly Strait)

E-posta: support@flystrait.com

&nbsp;

Hüküm ve koşullar aşağıdaki gibi belirlenmiştir:
# 1. HİZMET KAPSAMI
### 1.1 Seyahat Hizmetlerine Aracılık
1.1.1 Portal üzerinden sunulan uçuş rezervasyonu hizmetleri için (“Seyahat Hizmetleri” olarak anılacaktır), sadece aracı sıfatıyla hareket etmekteyiz. Bu amaçla, rol ve yükümlülüklerimiz, havayolu şirketleri gibi üçüncü taraflarca (bu metnin devamında her bir durumda “Hizmet Sağlayıcı” olarak anılacaktır) verilecek olan Seyahat Hizmetlerine aracılık edilmesiyle sınırlıdır.

&nbsp;

1.1.2 Sonuç olarak, Seyahat Hizmetlerinin sağlanmasıyla ilgili sözleşme, doğrudan Siz ve ilgili Hizmet Sağlayıcı arasında geçerlidir. Biz Seyahat Hizmetlerinin satışına ortak değiliz ve Siz ve Hizmet Sağlayıcı arasındaki sözleşme ilişkisinin bir tarafı değiliz.

&nbsp;

1.1.3 Seyahat Hizmetlerini satın almak için Portalı kullanarak, Sizinle Hizmet Sağlayıcı arasındaki işlemin gerçekleştirilmesi amacıyla bu Seyahat Hizmetlerinin ödemesine aracılık da dâhil olmak üzere Sizin adınıza ilgili Hizmet Sağlayıcı(lar)la aracılık etmeye Bizi yetkilendirirsiniz. Seyahat Hizmetleri için belirlenen ücret, Sizin ve ilgili Hizmet Sağlayıcı arasındaki sözleşmeye aracılık etmek amacıyla sunduğumuz hizmetlerle ilgili bir komisyonu da içerebilir.

&nbsp;

1.1.4 Portal üzerinden sağlanan Seyahat Hizmetlerinin gerçekleştirilmesiyle ilgili sorumluluk tamamen ilgili Hizmet Sağlayıcıya aittir. Aracı rolümüzle Hizmet Sağlayıcılar tarafından sunulan Seyahat Hizmetleri için herhangi bir sorumluluk üstlenmiyoruz ve Portalda aracı olarak sunduğumuz Seyahat Hizmetlerinin uygunluğu veya kalitesine ilişkin herhangi bir taahhüt veya garantide bulunmuyoruz. Seyahat Hizmetinin gerçekleştirilmesi veya gerçekleştirilmemesiyle ilgili herhangi bir talep durumunda sorumlu muhatap Hizmet Sağlayıcıdır.

&nbsp;

1.1.5 Seyahat Hizmetlerinin rezervasyonu sırasında özel isteklerde (özel yemek, engelli hizmetleri veya çocuk koltuğu) bulunma imkânı olursa bu isteği ilgili Hizmet Sağlayıcıya aktaracağız. Ancak Hizmet Sağlayıcının bu istekleri yerine getirip getiremeyeceği konusunda hiçbir sorumluluk kabul edemeyiz.

### 1.2 Kendi Hizmetlerimiz
Yukarıdaki Madde 1.1'de belirtildiği gibi Seyahat Hizmetlerine aracılık etmenin dışında Bizim kendimizin sağlamaktan sorumlu olduğumuz ek hizmetler (Seyahat Hizmetleri dışında) de bulunmaktadır. Bu ek hizmetler için Bizimle doğrudan sözleşme ilişkisine girersiniz. Her bir durumda sadece üçüncü taraf hizmetlere aracılık etmek yerine kendi hizmetlerimizi sunup sunmadığımızı ve ne ölçüde sunduğumuzu açıkça bildiririz.

&nbsp;

Kendi hizmet portföyümüz zamanla değişebilir. Bazı hizmetlerimizin 8. maddede açıklanmıştır. Bu Hüküm ve Koşullarda belirtilmeyen ek hizmetler için bu ek hizmetlerin açıklamasının yanı sıra ücretlerimiz hakkında bilgi ve rezervasyon ve kullanımla ilgili destekleyici hüküm ve koşullar Size rezervasyon işlemi sırasında sağlanmaktadır.

### 1.3 Geçerli sözleşme koşulları
1.3.1 Bu Hüküm ve Koşullar, Madde 1.1'de belirtilen Seyahat Hizmetlerine aracılığın yanı sıra Madde 1.2'de belirtilen kendi sağladığımız hizmetlerimiz için de geçerlidir.

&nbsp;

1.3.2 Siz ve ilgili Hizmet Sağlayıcı (Madde 1.1.2) arasındaki sözleşme gereğince ilgili Hizmet Sağlayıcı tarafından belirlenen genel hüküm ve koşullar (taşıma, transfer ve sigorta koşulları vb.) geçerlidir. İlgili Hizmet Sağlayıcının bu genel hüküm ve koşulları Size rezervasyon işlemi sırasında bildirilecektir. Hizmet Sağlayıcının hüküm ve koşulları yükümlülük, iptal, rezervasyon değişiklikleri ve geri ödeme (varsa) ve diğer kısıtlamalarla ilgili hükümler içerebileceğinden bu hükümleri dikkatlice okumanız tavsiye edilir.

# 2. Rezervasyon işlemi ve iletişim bilgileri
2.1 Rezervasyon işlemi sırasında giriş formlarındaki hataları tespit etmek ve rezervasyon isteğinizi göndermeden önce bunları düzeltmek için gerekli teknik imkânlar sağlanacaktır. Rezervasyon isteğinizi tamamlamadan önce tüm verilerin doğruluğunu kontrol etmeniz istenir. Sonradan yapılacak değişiklik talepleri ciddi ek maliyetlere neden olabilir.

&nbsp;

2.2 Satın alınan Seyahat Hizmetlerinde daha sonradan değişiklik yapılması gibi durumlarda Sizinle iletişim kurmamız gerekebilir. Bize telefon numarası ve e-posta adresi gibi doğru iletişim bilgileri sağlamalısınız. Ayrıca Bizden bir mesaj alıp almadığınızı da düzenli olarak kontrol etmelisiniz.

&nbsp;

Mesajları alabiliyor olduğunuzdan emin olmak Sizin sorumluluğunuzdadır. Bizden gelen mesajları: (i) Bize yanlış e-posta adresi vermiş olmanızdan; (ii) e-posta ayarlarınızın e-postanın Size ulaşmasına izin vermemesinden veya (iii) e-posta ayarlarınızın e-postamızı spam olarak işlemesi dâhil ancak bunlarla sınırlı olmayan makul şekilde kontrolümüz dışındaki durumlardan dolayı Bizden bir mesaj almıyorsanız sorumlu değiliz.

# 3. Uçuşlara aracılık

### 3.1 Sözleşmenin sonuçlandırılması ve fiyat değişiklikleri
3.1.1 Rezervasyon isteğinizi gönderdikten sonra e-posta ile isteğinizi aldığımızı teyit ederiz. Bu noktada Sizinle Bizim aramızdaki aracılık sözleşmesi yürürlüğe girer. Rezervasyon isteğinizi tamamladıktan sonra bir saat içinde Bizden bir teyit almazsanız veya operatörlerimizden herhangi birisi Sizinle e-posta veya telefon yoluyla iletişim kurarak herhangi bir sorun bildirmezse lütfen kontrol amacıyla Bizimle telefonla iletişim kurun.

&nbsp;

3.1.2 İstediğiniz biletler düzenlenir düzenlenmez bilet numarasıyla birlikte bir onay e-postası alırsınız. Bu noktada Siz ve ilgili havayolu şirketi arasında bağlayıcı bir sözleşme yürürlüğe girer.

&nbsp;

3.1.3 Uçak bileti fiyatları ve koltuk durumu doğrudan ilgili havayolu şirketi tarafından belirlenir. Rezervasyon isteğinin gönderilmesinin ardından ancak havayolu şirketi ile bağlayıcı bir sözleşmenin oluşmasından önce (yukarıdaki Madde 3.1.2'de belirtilen şekilde) Bizim kontrolümüz dışında gerçekleşen uçak bileti fiyat değişikleri durumunda sözleşme yürürlüğe girmez ve ödemeniz tamamen iade edilir. Sizinle irtibat kurarak mesai saatleri içerisinde değiştirilen fiyatı kabul etme seçeneği sunarız, ancak fiyat değişikliğinin bilgisini almamızın ardından 24 saat geçtikten sonra veya bu 24 saatlik süreyi izleyen ilk iş günü bunu yapamayız.

### 3.2 Rezervasyon ve taşımacılık koşullarıyla ilgili bilgiler
3.2.1Portalda sunulan uçuşlarla ilgili olarak sadece aracı sıfatıyla hareket ediyoruz. Uçuşun gerçekleştirilmesiyle ilgili sözleşme Sizin ve ilgili havayolu şirketi arasında doğrudan yürürlüğe girer ve uçuşunuzun gerçekleştirilmesi veya gerçekleştirilmemesiyle ilgili olarak herhangi bir sözleşmesel sorumluluk kabul etmiyoruz. Uçuşun gerçekleştirilmesi / gerçekleştirilmemesiyle ilgili tek sorumlu işletmeci havayolu şirketidir.

&nbsp;

3.2.2 Rezervasyonu tamamlamadan önce ilgili havayolu şirketinin hüküm ve koşulları Size sağlanacaktır.

&nbsp;

3.2.3 Aşağıda genel bilgilendirme yoluyla bu tür veya en azından benzer şekilde havayolu şirketleri tarafından rezervasyon ve ulaşımla için uygulanan koşullarla ilgili bilgi vermekteyiz. Ancak ilgili havayolu şirketinin hükümlerinden herhangi bir sapma olduğunda Madde 3.2.3'te verilen genel bilgiler geçerli olacaktır. Bu nedenle her bir durumda rezervasyondan önce lütfen ilgili havayolu şirketinin geçerli hüküm ve koşullarını kontrol edin.

#### a. Uçuş saatleri/check-in
Belirtilen bütün uçuş saatleri yerel saattedir. Bir sonraki gün varışlar, zaman çizelgesinde "+1" ile belirtilir. Belirtilen uçuş saatleri kesin değildir ve uçuş kontrolü kısıtlamaları, hava durumu veya havayolu şirketinin işlevsel kısıtlamaları gibi nedenlerle bilet düzenlendikten sonra kısa süre önceden bildirimde bulunarak değiştirilebilir. Lütfen uçuşunuzun geçerli saatinden haberdar olun.

&nbsp;

Lütfen havayolu şirketi tarafından belirtilen check-in saatlerine uyun. Check in'e geç kalmanız durumunda havayolu şirketi uçağa binmenize izin vermeyebilir. Bazı havayolu şirketlerinin havaalanında manuel check-in’den bir check-in ücreti talep edebileceğinden, check-in işlemini kendi web sitelerinden check-in yaptırmayı teşvik ettiklerini lütfen göz önünde bulundurun.

#### b. Combination of individual tickets
Gidiş dönüş bileti yerine iki ayrı tek yön biletlerinin birleştirilmesi rezervasyon işlemi sırasında açıkça belirtilmiştir. İptal, değişiklik, grev veya uçuş tarifelerindeki değişiklikler gibi hava trafiğindeki aksamalar durumunda biletler birbirinden bağımsız olarak değerlendirilir. Her bir havayolu şirketinin kendi kuralları geçerli olacaktır.

&nbsp;

#### b. Farklı rezervasyon numaralarına sahip uçak biletleri her zaman birbirinden bağımsız seyahatler olarak değerlendirilir.

#### c. Farklı segment/kullanım sırasına sahip uçak biletleri
Gidiş dönüş biletiniz veya tek yöne biletiniz birden fazla segmentten oluşabilir. Çoğu havayolu şirketinin koşullarına göre bu uçuş segmentleri sırayla kullanılmalıdır. Aksi halde çoğu havayolu şirketi takip eden uçuş segmentlerinde ulaştırmayı kabul etmeyecektir (yani bir yolculuğun bir segmentinin kullanılmaması biletin kalan kısmını geçersiz kılabilir). Gidiş dönüş biletlerde gidiş uçağına binmemeniz dönüş uçuşunuzun havayolu şirketi tarafından iptal edilmesine neden olabilir.

#### d. Hamilelik
Bazı havayolu şirketleri, gidiş veya dönüş uçuşu zamanında hamileliğin 28. haftasını geçmiş olan kadınları taşımayı kabul etmeyebilir. Hamileyseniz havayolu şirketine ve doktorunuza yolculuğa çıkıp çıkamayacağınızı danışmalısınız.

#### e. Bebekler ve çocuk biletleri
Ayrı koltuğu olmayan bir çocukla seyahat etme koşullarını öğrenmek için lütfen havayolu şirketiyle iletişime geçin. Genellikle 2 yaş üzerindeki çocuklar ayrı bir koltuğa ihtiyaç duyarken 0 ile 2 yaş arasındaki çocuklar bebek olarak seyahat eder ve kendine ait bir koltuk verilmez. Bebeğin seyahat bitmeden 2 yaşına girmesi halinde tüm seyahat için bir çocuk bileti alınmalıdır. Bebek biletleri, isim ve doğum tarihinin pasaportta belirtilen şekilde yazılması gerektiğinden doğumdan önce alınamaz. Baştan yanlış rezerve edilen bilet türünden kaynaklanabilecek hiçbir masrafı karşılamayız.

#### f. Tek başına seyahat eden çocuklar
Tek başına seyahat eden çocuklar için bilet rezervasyonuna aracılık etmiyoruz. 18 yaşından küçük çocuklar için bir yetişkinin refakatinde seyahat rezervasyonu yapılmalıdır. Bazı ülkeler ve havayolu şirketleri, yasal bir vasi eşlik etmediği sürece 18 yaşından küçük çocukları uçağa kabul etmeyebilir. Bazı havayolu şirketlerinin 18 yaşından küçük çocuklar için doğum belgesini seyahate getirmesini istediğini lütfen unutmayın.

#### g. Kayıp/hasarlı bagaj
Aracı sıfatıyla kayıp veya hasarlı bagaj için herhangi bir sorumluluk kabul etmiyoruz. Herhangi bir sorun hemen havaalanındaki havayolu şirketi temsilcisine bildirilmelidir.

#### h. Transit ve gece konaklama
Genellikle yolculuğunuz sırasındaki karayolu taşımacılığı ve/veya gece konaklaması uçak bileti fiyatınıza dâhil değildir. Karayolu taşımacılığı saat tarifesini ve fiyatları kontrol etmekten Siz şahsen sorumlusunuz.

#### i. Uçuşlar arasındaki bağlantı süreleri
Portal üzerinden rezerve edilen standart biletlerin onaylı bağlantı süreleri vardır. Uçuşlar arasında bağlantıları yapmak için gereken süreler havayolu şirketleri tarafından hesaplanmaktadır. Bir uçuş segmentinin gecikmesi ve bağlantının kaçırılması durumunda havayolu şirketlerinin nihai varış noktanıza ulaşmanıza yardımcı olması zorunludur (Madde 9.1'e bakınız).

&nbsp;

Ayrı biletler rezerve edildiğinde havayolu şirketleri, bağlantılı uçağın kaçırılmasına neden olacak gecikmelerle ilgili herhangi bir sorumluluğa sahip değildir. Bu nedenle bağlantı süresinin havayolu şirketine ve havaalanına göre yeterli olduğunu kontrol etmek Sizin sorumluluğunuzdadır. Kaçırılan bağlantılı uçuşla ilgili ek maliyetler tazmin edilmez.

#### j. Mükerrer rezervasyonlar
Mükerrer rezervasyon, aynı havayolu şirketinde aynı yolcu adı ile iki veya daha fazla rezervasyon yapılması anlamına gelir. Mükerrer rezervasyon yaptıysanız havayolu şirketi seyahat(ler)i iptal edebilir. Bu durum, rezervasyonlar farklı seyahat acenteleriyle yapıldığında da görülebilir. Mükerrer rezervasyondan şüphelenilen durumlarda havayolu şirketlerinin yaptığı iptallerden veya havayolu şirketlerinin geri ödemeyi reddetmesinden sorumlu değiliz

### 3.3 AB'de faaliyet göstermesi yasaklanan havayolu şirketleri (kara liste)
Avrupa Komisyonu'nun ulusal havayolu taşımacılığı yetkilileriyle yaptığı yoğun müzakerelerle verilen bir karara göre bazı havayolu şirketlerinin AB'de faaliyet göstermesinin yasaklandığını lütfen unutmayın. Bu havayolu şirketleri, güvenli olmadıkları düşünüldüğünden veya kendi ülkelerinin yetkilileri tarafından yeterli denetime tabi tutulmadıklarından dolayı yasaklanmıştır.

&nbsp;

Bir işletme yasağından hangi havayolu şirketlerinin etkilendiğini aşağıdaki link üzerinden kontrol edebilirsiniz: Kara Liste (AB'de faaliyet göstermesine izin verilmeyen havayolu şirketleri listesi)
### 3.4 Tarife değişiklikleri ve havayolu şirketleri tarafından iptal
3.4.1 İlgili havayolu şirketiyle olan sözleşmeniz, havayolu şirketinin rezervasyonlarınızı iptal etmesine veya değiştirmesine izin verebilir. Havayolu şirketi tarafından değişiklikler Bize bildirildikten sonra Size bu değişiklikleri bildireceğiz.

&nbsp;

3.4.2 Rezervasyon onayındaki uçuş zamanları rezervasyon tarihi ile seyahat tarihiniz arasında değişebilir. Uçuş takvimindeki değişiklikler durumunda havayolu şirketinden bilgi alır almaz Size bildireceğiz. Ancak uçuşların (ve bağlantılı uçuşların) zamanında gerçekleştirileceğinden emin olmak için her bir uçuşun planlanan kalkış saatinden en az 72 saat öncesinden havayolu şirketinizle iletişim kurmanızı öneririz. Havayolu şirketinin tarife değişiklikleri üzerinde herhangi bir kontrolümüz bulunmuyor ve bu değişiklikler sonucunda oluşabilecek maliyetlerden dolayı herhangi bir sorumluluk kabul etmiyoruz.

### 3.5 Sizin tarafınızdan talep edilen değişiklik ve iptaller
Düşük maliyetli havayolu şirketleri için Madde 3.7'ye bakınız.

&nbsp;

3.5.1 Uçak bileti rezervasyonlarını (yolcu adı, varış noktası ve seyahat tarihi dâhil) değiştirme koşulları ve iptal iadeleri sağlama koşulları, uçuşu sağlamaktan sorumlu sözleşme iş ortağınız olan ilgili havayolu şirketi tarafından belirlenmektedir. Aracı olarak bizim bu koşullar üzerinde herhangi bir etkimiz bulunmamaktadır.

&nbsp;

3.5.2 Rezervasyonunuzda bir değişiklik yapmak veya iptal iadesi talep etmek isterseniz, havayolu şirketinin koşullarının değişiklik veya iptal iadesine izin vermesi koşuluyla, kendimize ait ek bir hizmet olarak talebi Sizin adınıza gerçekleştirmeyi öneriyoruz. Bu ek hizmetlerin rezervasyonu sürecinde bu hizmetlerle ilgili diğer koşulları ve ücretleri size bildireceğiz. Alternatif olarak elbette ilgili havayolu şirketine doğrudan başvurabilirsiniz.

&nbsp;

3.5.3 Sizin tarafınızdan talep edilen değişiklikleri gerçekleştirebilmemiz için değişiklik taleplerinizi seyahat başlamadan en az 24 saat önceden (sadece telefon yoluyla) almamız gerekir. Esnek Bilet hizmetimizi satın aldıysanız 10. maddeye bakınız.

&nbsp;

Daha kısa süreyle bildirimde bulunulan değişiklik talepleri için ilgili havayolu şirketine doğrudan başvurmanızı öneririz.

### 3.6 Uçuşa Gelmeme veya Uçağa Binmeme
Uçuşa gelmemeniz ya da katılmamanız durumunda bize kullanılmamış uçak biletini Sizin adınıza iptal etme ve Sizin adınıza havayolu şirketinden iade talep etme yetkisi verirsiniz. Hak sahibiyiz, ancak bunu yapmakla yükümlü değiliz ve doğrudan havayolu şirketinden iade talep etme hakkınız etkilenmemektedir.

### 3.7 Düşük maliyetli havayolu şirketleri
3.7.1 Uygun uçuş, düşük maliyetli bir havayolu şirketi tarafından sunuluyorsa, rezervasyon işlemi sırasında size açıkça bildirilecektir. Düşük maliyetli havayolu şirketleri tarafından sunulan uçuşlar için aşağıdaki özel hükümler geçerlidir.

&nbsp;

3.7.2 Düşük maliyetli havayolu şirketleri tarafından sağlanan Seyahat Hizmetlerine aracılık ederken Sizinle düşük maliyetli havayolu şirketi arasındaki sözleşmenin gerçekleştirilmesinde Sizin adınıza hareket edebiliriz.

&nbsp;

3.7.3 Düşük maliyetli havayolu şirketlerinin rezervasyon sistemlerine erişimimiz yoktur. Bu nedenle rezervasyonlar, değişiklikler veya rezervasyon iptalleriyle ilgili sorular için düşük maliyetli havayolu şirketiyle doğrudan iletişim kurun. Esnek Bilet hizmetimizi satın aldıysanız (10. Maddeye bakınız) tüm rezervasyon değişikliği istekleri Madde 10.1.4'te belirtilen şekilde müşteri hizmetlerimiz aracılığıyla yapılmalıdır.

&nbsp;

3.7.4 Ayrıca, bazı düşük maliyetli havayolu şirketlerinin havalimanında manuel check-in için ek ücret isteyebildiğini unutmayın. Bu ücretlerden kaçınmak için ilgili düşük maliyetli havayolu şirketinin web sitesinden online check-in imkânını kullanmalısınız. Lütfen check-in olanakları ve havayolu şirketinin koşulları konusunda kalkıştan önce zamanında bilgilenin.

&nbsp;

3.7.5 Düşük maliyetli havayolu şirketlerinin rezervasyon onayları, rezervasyonun ilgili düşük maliyetli havayolu şirketi tarafından onaylanmasının hemen ardından gönderilir. Biri Bizden ve diğeri de düşük maliyetli havayolu şirketinden olmak üzere iki rezervasyon onayı alabilirsiniz. İki rezervasyon onayı aldıysanız check-in işlemi için düşük maliyetli havayolu şirketinin rezervasyon onayını kullanın.

# 4. Çok sayıda hizmet rezervasyonuna aracılıkla ilgili özel hükümler
Portal istediğiniz birden fazla tekli hizmeti (örneğin uçak bileti + otel) birlikte alma olanağı sunmaktadır. Bu durumda Bize çeşitli tedarikçilerin farklı seyahat hizmetlerinin rezervasyonunu aracı olarak gerçekleştirme talimatı verirsiniz. Rezervasyon işlemi tamamlanmadan önce rezervasyon sırasında ilgili tedarikçiler Size net bir şekilde sunulacaktır. Ayrı hizmetleri birleştirmeniz durumunda Sizinle Bizim aramızda herhangi bir seyahat sözleşmesi yapılmaz; bunun yerine her bir ayrı tedarikçi ile ayrı hizmetlerin sağlanması konusunda birkaç sözleşme yaparsınız. Bu durumda her bir seyahat hizmetiyle ilgili olarak sadece aracılık ederiz.

# 5. Pasaport, vize ve sağlık hükümleriyle ilgili bilgiler
5.1 Pasaport, vize ve/veya sağlık gereksinimleri değişebilir ve ilgili kurumla (büyükelçilik, konsolosluk vs.) bunu çok önceden kontrol etmeniz gerekir. Geçerli bir pasaport ve gerekiyorsa vizeye sahip olmanız sizin sorumluluğunuzdadır. Vize almanızı gerektirebilecek seyahatinizdeki tüm transit noktalarını da dâhil etmeyi unutmamanız önemlidir. Bazen vize almak uzun sürebilir bu nedenle yeterince erken başvurmanız tavsiye edilir. Doğru belgelere sahip olmayan müşteriler için herhangi bir sorumluluk kabul etmiyoruz.

&nbsp;

5.2 Her bir varış noktasının giriş formaliteleri, aşılar vs. ile ilgili kendi gereksinimleri bulunmaktadır ve bunlar, yolcunun uyruğuna bağlı olarak da farklılık gösterebilir. Bu bilgileri toplamak Sizin sorumluluğunuzdadır. Bu tür resmi yönetmeliklere uyulmamasından kaynaklanan olaylar Bizim sorumluluğumuz olarak değerlendirilmeyecektir. Bu nedenle seçilen hedef nokta veya transit ülkelerle ilgili farklı formaliteleri ve ilgili adımların tamamını atmak için gereken süreyi her zaman kontrol etmenizi önemle tavsiye ediyoruz.

# 6. Kendi hizmetlerimiz
### 6.1 Esnek Bilet
6.1.1 Rezervasyon işlemi sırasında satın alınan Esnek Bilet, bu maddede belirtilen koşullar çerçevesinde uçak bileti rezervasyonunuzun tarih ve/veya saatini değiştirmenizi sağlar. Esnek Bilet hizmetimizi sağlarken sadece Sizi ve ilgili havayolu şirketi arasındaki taşımacılık sözleşmesine aracılık ederiz. Esnek Bilet kendi hizmetimiz olduğundan dolayı (havayolu şirketiyle doğrudan iletişim kurarak Esnek Bilet hizmetimizden yararlanamazsınız) Esnek Bilet hizmetinden yararlanan tüm rezervasyon değişikliği talepleri müşteri hizmetlerimiz aracılığıyla yapılmalıdır (Madde 6.1.4'e bakınız).

&nbsp;

6.1.2 Esnek Bilet uçuşlarda aşağıdaki koşullarla rezervasyon değişikliği yapılmasına imkân tanır:

&nbsp;

6.1.3 Esnek Bilet rezervasyonu ve ödemesi rezervasyon işlemi sırasında yapılmalıdır, daha sonradan eklenemez

&nbsp;

6.1.4  Biletinizde rezervasyon değişikliği yapmak istiyorsanız müşteri hizmetlerimizle olağan çalışma saatleri içinde iletişime geçmelisiniz. Kalkışınıza 72 saatten daha kısa bir süre varsa, müşteri hizmetlerimizle telefon aracılığıyla iletişime geçmelisiniz.

&nbsp;

Telefon ve e-posta yoluyla İngilizce iletişim kurduğumuzu lütfen unutmayın.

&nbsp;

6.1.5 Rezervasyon değişikliği sadece Bizim e-posta ile onaylamamızın ardından tamamlanır. Onay almazsanız lütfen müşteri hizmetlerimize başvurun.

&nbsp;

6.1.6 Esnek Bilet hizmetinin kullanıldığı uçak bileti rezervasyon değişiklikleri müsaitlik durumuna bağlıdır. İstenen değişikliğin daha pahalı bir bilete neden olması veya değişikliğin yolcunun özel fiyattan (örneğin küçük çocuk) artık yararlanamamasına neden olması halinde ek masrafları kendiniz karşılamanız gerekir. Rezervasyon değişikliğinin havayolu şirketinden doğrudan aldığınız ek hizmetler (örneğin ek bagaj veya koltuk rezervasyonları) üzerindeki etkilerinden sorumlu değiliz.

&nbsp;

6.1.7 Uçak biletini iptal ederseniz Esnek Bilet hizmetinin ücreti iade edilmez.

&nbsp;

6.1.8 Yolculuğun herhangi bir segmentine katılmazsanız Esnek Bilet hizmeti üzerinden rezervasyon değişikliği seçeneği artık geçersiz olur.


# 7. Ücretler ve Ödeme
### 7.1 Ödeme
7.1.1 Seyahat Hizmetlerinin ödemesi ya Bizim tarafımızdan (ödeme işlemlerini yürüten ödeme hizmeti sağlayıcı(ları)mızla işbirliği içinde) ya da Hizmet Sağlayıcı tarafından işleme konulur. Ödeme işlemini gerçekleştirebilmek için, kredi veya banka kartı bilgileri gibi ödeme bilgilerinizi ödeme hizmeti sağlayıcımızla paylaşmamız gerektiğini lütfen unutmayın. Müşterilerimize ait bütün ödeme bilgileri, ödeme hizmeti sağlayıcı(ları)mızla paylaşıldığında güvenli bir sunucuda şifrelenir.

&nbsp;

7.1.2 Rezervasyon kriterlerine ve ilave hizmetlere bağlı olarak ödeme, biri Bize diğeri Hizmet Sağlayıcıya olmak üzere iki ayrı işleme ayrılabilir. Sizden sitemizde görülen toplam fiyattan fazla ücret alınmayacaktır. Aynı güvenlik önlemleri uygulanır.

&nbsp;

7.1.3Ödemenizi işleme koyabilmemiz için yeterli miktarda para olduğundan emin olmalısınız. Ödemenizin işleme konulmasıyla ilgili bir sorun oluşması durumunda, ödeme hizmeti sağlayıcı(ları)mızla işbirliği içinde ödemenizi tekrar işleme koymayı deneriz. Bu sefer de ödeme başarılı bir şekilde yapılamazsa, başka ödeme yöntemleri konusunda Sizden talimat almak için Sizinle en kısa zamanda iletişime geçilecektir. Bu talimatlardan sonra da ödeme gerçekleşmezse, ödenmeyen borcunuz alacak tahsilat şirketine yönlendirilecektir.

&nbsp;


### 7.2 Ödemede sahtecilik
Sahtekârlık yapıldığına ilişkin şüphelenmemizi gerektirecek nedenler varsa ödemeyi işleme koymayı reddetme hakkını saklı tutarız. Eğer bir suçtan şüpheleniliyorsa Ödemenin Meşruluğunu kanıtlamanız talep edilebilir. Her türlü ödeme sahteciliği durumu polise bildirilecektir ve bir alacak tahsilat şirketine başvurulacaktır.

&nbsp;

# 8. Şikâyetler
### Seyahat Hizmetlerinin performansına ilişkin talepler
Seyahat Hizmetlerinin gerçekleştirilmesiyle ilgili bütün sorun, yorum veya talepler, doğrudan ilgili Seyahat Hizmeti için sözleşmenizin olduğu Hizmet Sağlayıcıya iletilmelidir. Ayrıca AB düzenlemeleri uyarınca haklarınız için lütfen bölüm 9'e bakınız.

### Kendi hizmetlerimize ilişkin şikâyetler
Kendi hizmetlerimize ilişkin şikâyetler, yalnızca yazılı olarak işleme alınır ve seyahat tarihinizin bitiminden itibaren iki ay içinde buradaki şikâyet formuyla gönderilmelidir.

&nbsp;

E-posta adresi: complaints@flystrait.com

# 9. AB düzenlemeleri uyarınca müşteri hakları
9.1 İptal edilen veya gecikmeli uçuşlar
AB'ye, AB'den veya AB merkezli bir havayolu şirketiyle seyahat ediyorsanız, uçuşunuzun iptal olması, gecikmesi veya uçağa binmenize izin verilmemesi durumunda masraflarınız için geri ödeme talep etme hakkına sahipsiniz ve bunu doğrudan ilgili havayolu şirketinden talep edebilirsiniz. 261/2004 sayılı AB Yönetmeliği hakkında daha fazla bilgi için lütfen buraya tıklayın.

&nbsp;

9.2 Taşıyıcının sorumluluğu

Kaza durumunda taşıyıcı havayolu şirketin sorumluluğuyla ilgili AB Yönetmeliği (889/2002). Düzenlemeye buradan ulaşabilirsiniz.

&nbsp;

# 10. Sorumluluk
10.1 Sizinle Hizmet Sağlayıcı arasında aracı olarak hareket ettiğimizi kabul ediyorsunuz. Hiçbir durumda bir veya daha fazla Hizmet Sağlayıcı ile rezerve ettiğiniz Seyahat Hizmetleriyle ilgili olarak hiçbir durumda sorumlu tutulamayız ve ilgili Hizmet Sağlayıcı tarafından sağlanan Portalda görüntülenen bilgilerdeki hatalardan dolayı herhangi bir sorumluluk kabul etmiyoruz.

&nbsp;

10.2 Bir Hizmet Sağlayıcının iflas etmesi de dâhil olmak üzere herhangi bir nedenle Seyahat Hizmeti sağlayamaması durumunda sadece aracı rolü görebiliriz ve sadece ilgili Hizmet Sağlayıcıdan aldığımız ödemeleri iade edebiliriz.

&nbsp;

10.3 Kendi hizmetlerimizle ilgili olarak bu Hüküm ve Koşullarda belirtilen ve yasayla izin verilen sınırlar içerisinde tazminat taleplerinden sorumluyuz. Sadece kendi hizmetlerimiz ilgili olarak ve rezervasyonunuzun (bağlı olaylardan ister biri veya bir dizisi için) toplam tutarını karşılayacak kadar yükümlülüklerimizi yerine getirmememizden dolayı maruz kaldığınız, ödediğiniz veya gerçekleşen doğrudan hasarlardan dolayı sorumlu olacağız.

&nbsp;

10.4 Bölüm 10.3'te belirtilen sorumluluk sınırlaması, yasal hükümler çerçevesinde hatasından bizim sorumlu olduğumuz kişilerin görev ihlalleri için de geçerlidir.

# Geçerli Kanun ve Anlaşmazlıkların Çözümü

# 11. Geçerli Kanun
Bu Hüküm ve Koşullar ve Sizinle Bizim aramızdaki sözleşme için münhasıran Estonya yasaları geçerlidir.

&nbsp;

Bir tüketici olarak ikamet ettiğiniz ülkenin zorunlu yasal hükümlerinden yararlanırsınız. Bu Madde 11.1 de dâhil olmak üzere buradaki hiçbir hüküm bir tüketici olarak yerel yasanın zorunlu hükümlerine güvenme hakkınızı etkilememektedir.

&nbsp;

### 11.2 Çevrimiçi Anlaşmazlıkların Çözümü

&nbsp;

Avrupa Komisyonu, online anlaşmazlıkların çözümü için (ODS) için bir platform sağlamaktadır: (http://ec.europa.eu/consumers/odr/)

# Veri Koruma

Kişisel verilerinizin korunmasını ciddiye alıyoruz. Kişisel verilerinizin toplanması, işlenmesi ve kullanılması ile ilgili ayrıntılı bilgiye Gizlilik Politikamızdan ulaşabilirsiniz.

`;
