import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { graphql, compose, withApollo } from "react-apollo";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  insertCustomDomain,
  updateCustomDomain,
  getCustomDomainById
} from "containers/Influencer/queries";
import { searchDomain } from "utils/api";
import { Loader } from "components/common";
import { setItem, currencyCoversion } from "utils/utils";
import { SearchIcon, CloseIcon } from "components/common/SvgIcons";
import getTranslation from "utils/getTranslation";
import close from "assets/tip/Close-circle.svg";

const Wrapper = styled.div`
  .error {
    margin-top: 30px;
    font-size: 16px;
    color: #eb5757;
  }
  .inputWrapper {
    width: 100%;
    position: relative;
    .input {
      width: 90%;
      position: relative;
      svg {
        position: absolute;
        top: 10px;
        &:first-child {
          left: 10px;
        }
        &:last-child {
          right: 10px;
        }
      }
    }
    img {
      position: absolute;
      right: 0;
      top: 8px;
      cursor: pointer;
    }
  }

  .list {
    margin-top: 30px;
    .labels {
      display: flex;
      justify-content: space-between;
      color: #777777;
      font-size: 16px;
      line-height: 26px;
      font-family: "Roboto Condensed", sans-serif;
      text-transform: uppercase;
      border-bottom: 1px solid #dddddd;
      margin-bottom: 10px;
    }
    .items {
      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #007ac7;
        padding: 5px 0;
      }
      .line {
        height: 1px;
        width: 100%;
        background: linear-gradient(
          269.89deg,
          rgba(204, 204, 204, 0) 0%,
          #cccccc 50%,
          rgba(204, 204, 204, 0) 100%
        );
      }
    }
  }
`;

const Input = styled.input`
  border: 0;
  border-radius: 10em;
  background-color: #d0f3fc;
  width: 100%;
  font-size: 18px;
  padding: 10px 40px;
  outline: none;
`;

class CustomDomain extends React.PureComponent {
  constructor(props) {
    super(props);
    const domainData =
      props.customDomains.length > 0 ? props.customDomains[0] : null;
    const selectedDomain =
      props.selected && domainData ? domainData.domain : "";
    this.state = {
      domainData,
      domain: domainData ? domainData.domain : "",
      selectedDomain
    };
    if (domainData) {
      setTimeout(() => {
        this.handleGetDomains();
      }, 500);
    }
  }
  handleChange = e => {
    this.setState({
      domain: e.target.value
    });
  };
  handleClear = () => {
    console.log("clear");
    this.setState(
      {
        domain: ""
      },
      () => {
        console.log(this.state.domain);
      }
    );
  };
  handleRadioChange = e => {
    const { value } = e.target;
    this.setState({
      selectedDomain: e.target.value
    });
    const domain = this.state.domains.find(item => item.domain === value);
    setItem("selectedDomain", domain);
    this.props.handleDomainUpdate(value);
  };
  handleKeyDown = async e => {
    if (e.keyCode === 13) {
      this.handleGetDomains();
    }
  };

  handleGetDomains = async () => {
    try {
      this.setState({ loading: true });
      let domains = [this.state.domain];
      if (this.state.domain.includes(".com")) {
        const domainWithoutTLD = this.state.domain.replace(".com", "");
        domains = [
          this.state.domain,
          `${domainWithoutTLD}.net`
          //`${domainWithoutTLD}.org`
        ];
      }
      //domains = domains.join(",");
      const payload = {
        domains
      };
      const response = await searchDomain(payload);
      console.log(response);
      this.setState({
        loading: false,
        domains: response.available
      });
    } catch (err) {
      this.setState({ loading: false });
    }
  };

  handleDomainData = id => {
    setTimeout(async () => {
      const domainData = await this.props.client.query({
        query: getCustomDomainById,
        variables: {
          id
        },
        fetchPolicy: "no-cache"
      });
      const {
        unavailable_at,
        available_at
      } = domainData.data.customsitedomain_by_pk;
      if (unavailable_at || available_at) {
        this.setState({
          domainData: domainData.data.customsitedomain_by_pk,
          loading: false
        });
      } else {
        this.handleDomainData(id);
      }
    }, 1000);
  };

  render() {
    const { domain, selectedDomain, domains, loading } = this.state;
    const { handleClearDomain } = this.props;
    return (
      <Wrapper>
        <div className="inputWrapper">
          <div className="input">
            <SearchIcon />
            <Input
              value={domain}
              onChange={this.handleChange}
              onKeyDown={this.handleKeyDown}
            />
            {domain.length > 0 && <CloseIcon onClick={this.handleClear} />}
          </div>
          <img src={close} alt="" onClick={handleClearDomain} />
        </div>
        {loading && <Loader />}
        {/* {domainData && domainData.unavailable_at && (
          <div className="error">{getTranslation("domainUnavailable")}</div>
        )} */}
        {domains && (
          <div className="list">
            <div className="labels">
              <span>{getTranslation("domainName")}</span>
              <span>{getTranslation("priceYearly")}</span>
            </div>
            <div className="items">
              <RadioGroup
                aria-label="Domain"
                name="domain"
                value={selectedDomain}
                onChange={this.handleRadioChange}
              >
                {domains.map((item, i) => (
                  <div key={i}>
                    <div className="item">
                      <div>
                        <FormControlLabel
                          value={item.domain}
                          control={<Radio />}
                          label={item.domain}
                        />
                      </div>
                      <span>{currencyCoversion(item.price_usd)}</span>
                    </div>
                    <div className="line" />
                  </div>
                ))}
              </RadioGroup>
            </div>
          </div>
        )}
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  influencerDetails: state.influencer.influencerDetails
});

export default withApollo(
  compose(
    graphql(insertCustomDomain, { name: "insertCustomDomain" }),
    graphql(updateCustomDomain, { name: "updateCustomDomain" }),
    connect(mapStateToProps)
  )(CustomDomain)
);
