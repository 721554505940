import React from "react";
import styled from "styled-components";
import Fade from "react-reveal/Fade";
import getTranslation from "utils/getTranslation";

const Wrapper = styled.div`
  width: 800px;
  margin: auto;
  margin-top: 120px;
  text-align: center;
  @media (max-width: 800px) {
    width: 100%;
    margin-top: 60px;
  }
  h1 {
    margin-bottom: 70px;
    @media (max-width: 800px) {
      margin-bottom: 30px;
    }
  }
`;

export default () => (
  <Wrapper>
    <Fade bottom>
      <h1>{getTranslation("aboutCompany")}</h1>
    </Fade>
    <Fade bottom>
      <p>{getTranslation("aboutCompanyText")}</p>
    </Fade>
  </Wrapper>
);
