import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import moment from "moment";
import parser from "react-html-parser";
import { EditIcon } from "components/common/SvgIcons";
import ShareModal from "components/Influencer/Partials/ShareModal";
import { DateWrapper } from "./CardStyles";
import DestinationLabels from "./DestinationLabels";
import LinkButton from "./LinkButton";
import { resizedImage } from "utils/utils";
import checked from "assets/tip/Checked-circle.svg";
import share from "assets/tip/share.svg";

const Post = styled.div`
  width: ${props => (props.fullWidth ? "100%" : "296px")};
  margin-bottom: 30px;
  background-color: #fff;
  cursor: pointer;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  position: relative;
  .imageWrapper {
    position: relative;
    .selectedCheck {
      z-index: 2;
      display: none;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      width: 48px;
      height: 44px;
      background-color: rgba(255, 255, 255, 0.7);
      border-bottom-left-radius: 10em;
      border-bottom-right-radius: 10em;
    }
    .previewImg {
      height: ${props => (props.fullWidth ? "auto" : "222px")};
      width: ${props => (props.fullWidth ? "auto" : "294px")};
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .images {
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      img {
        width: 100%;
        object-fit: cover;
      }
      &.layout1 {
        img {
          height: 222px;
        }
      }
      &.layout2 {
        flex-flow: column;
        img {
          height: 111px;
          &:last-child {
            margin-top: 1px;
          }
        }
      }
      &.layout21 {
        flex-flow: row;
        img {
          height: 222px;
          width: 148px;
          &:last-child {
            margin-left: 1px;
          }
        }
      }
      &.layout3 {
        justify-content: space-between;
        img {
          &:first-child {
            margin-bottom: 1px;
            width: 100%;
          }
          height: 111px;
          width: calc(50% - 0.5px);
        }
      }
      &.layout31 {
        justify-content: space-between;
        img {
          height: 111px;
          width: calc(50% - 0.5px);
          &:last-child {
            margin-top: 1px;
            width: 100%;
          }
        }
      }
      &.layout4 {
        justify-content: space-between;
        img {
          &:first-child {
            margin-bottom: 1px;
          }
          height: 111px;
          width: calc(50% - 0.5px);
        }
      }
    }
  }
  &.selected {
    border: 2px solid #007ac7;
    .selectedCheck {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .details {
    position: relative;
    padding: 15px;
    h4 {
      font-size: 24px;
      line-height: 28px;
      color: #3f8c52;
      text-transform: capitalize;
      font-weight: 400;
      margin: 0;
    }
    p {
      color: #777777;
      font-size: 16px;
      line-height: 150%;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
  }
`;

const Buttons = styled.div`
  position: absolute;
  right: 0;
  top: 60px;
  background-color: #f0f0f0;
  box-shadow: -1px 0px 1px rgba(0, 0, 0, 0.12);
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  width: 48px;
  z-index: 99;
  div {
    cursor: pointer;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:first-child {
      position: relative;
      &:before {
        content: "";
        position: absolute;
        background-color: #dddddd;
        height: 1px;
        width: 24px;
        bottom: 0;
      }
    }
  }
`;

const CropImages = styled.div`
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  width: 936px;
  img {
    width: 100%;
    object-fit: cover;
  }
  &.layout1 {
    img {
      height: 707px;
    }
  }
  &.layout2 {
    flex-flow: column;
    img {
      height: 351px;
      &:last-child {
        margin-top: 1px;
      }
    }
  }
  &.layout21 {
    flex-flow: row;
    img {
      height: 707px;
      width: 468px;
      &:last-child {
        margin-left: 1px;
      }
    }
  }
  &.layout3 {
    justify-content: space-between;
    img {
      &:first-child {
        margin-bottom: 1px;
        width: 100%;
      }
      height: 351px;
      width: calc(50% - 0.5px);
    }
  }
  &.layout31 {
    justify-content: space-between;
    img {
      height: 351px;
      width: calc(50% - 0.5px);
      &:last-child {
        margin-top: 1px;
        width: 100%;
      }
    }
  }
  &.layout4 {
    justify-content: space-between;
    img {
      &:first-child {
        margin-bottom: 1px;
      }
      height: 351px;
      width: calc(50% - 0.5px);
    }
  }
`;

const layouts = {
  "1": "layout1",
  "=": "layout2",
  "||": "layout21",
  L: "layout3",
  "7": "layout31",
  "+": "layout4"
};
export default class extends React.PureComponent {
  state = {
    shareModalOpen: false
  };
  blogText = null;
  componentWillMount() {
    parser(this.props.blogData.text, {
      transform: this.cleanArticle
    });
  }
  componentWillUnmount() {
    this.blogText = null;
  }
  cleanArticle = node => {
    if (node.type === "text" && !this.blogText) {
      this.blogText = node.data;
    }
  };

  handleLayoutChange = () => {
    const { layout, handleLayoutChange } = this.props;
    if (handleLayoutChange) {
      handleLayoutChange(layout);
    }
  };

  handleShareOpen = e => {
    e.preventDefault();
    this.setState({ shareModalOpen: true });
  };

  handleShareToggle = () => {
    this.setState({ shareModalOpen: !this.state.shareModalOpen });
  };

  render() {
    const { shareModalOpen } = this.state;
    const {
      blogData,
      color,
      layout,
      selectedLayout,
      preview,
      fullWidth,
      canEdit,
      style = {},
      id,
      cropPreview
    } = this.props;
    let dateArray = [];
    if (blogData) {
      const date = moment(blogData.created_at).format("DD,MMM,YYYY");
      dateArray = date.split(",");
    }
    const layoutClass = layouts[layout];
    const coverimage = blogData.coverimage || blogData.coverimage1;
    return (
      <>
        {shareModalOpen && (
          <ShareModal
            open={shareModalOpen}
            blogData={blogData}
            handleCloseModal={this.handleShareToggle}
            handleSubmit={this.handleShareToggle}
            title="Share"
          />
        )}
        <Post
          fullWidth={fullWidth}
          className={`blogPostCard ${
            selectedLayout === layout ? "selected" : ""
          }`}
          onClick={this.handleLayoutChange}
          style={style}
        >
          <div className="imageWrapper">
            <div className="selectedCheck">
              <img src={checked} alt="" />
            </div>
            {!cropPreview && (
              <div className="previewImg">
                <img src={resizedImage(coverimage, 222)} alt="" />
              </div>
            )}
            {cropPreview && (
              <div className={`images ${layoutClass}`}>
                {layout === "1" && <img src={blogData.coverimage1} alt="" />}
                {(layout === "=" || layout === "||") && (
                  <>
                    <img src={blogData.coverimage1} alt="" />
                    <img src={blogData.coverimage2} alt="" />
                  </>
                )}
                {(layout === "L" || layout === "7") && (
                  <>
                    <img src={blogData.coverimage1} alt="" />
                    <img src={blogData.coverimage2} alt="" />
                    <img src={blogData.coverimage3} alt="" />
                  </>
                )}
                {layout === "+" && (
                  <>
                    <img src={blogData.coverimage1} alt="" />
                    <img src={blogData.coverimage2} alt="" />
                    <img src={blogData.coverimage3} alt="" />
                    <img src={blogData.coverimage4} alt="" />
                  </>
                )}
              </div>
            )}
            <DateWrapper>
              <span className="date">{dateArray[0]}</span>
              <span className="month">{dateArray[1]}</span>
            </DateWrapper>
            {canEdit && (
              <Buttons>
                <Link to={`/influencer/dashboard/posts/${blogData.id}`}>
                  <div>
                    <EditIcon
                      stroke="#007AC7"
                      style={{ height: 18, width: 18 }}
                    />
                </div>
                </Link>
                <div onClick={this.handleShareOpen}>
                  <img src={share} style={{ height: 18, width: 18 }} alt="" />
                </div>
              </Buttons>
            )}
            <DestinationLabels blogData={blogData} color={color} />
          </div>
          <div className="details">
            {blogData.type === "BLOG" && (
              <h4 style={color ? { color: color } : null}>{blogData.title}</h4>
            )}
            <p style={{ marginBottom: preview ? 0 : 30 }}>
              {blogData.type === "BLOG" ? this.blogText : blogData.title}
            </p>
          </div>
          {!preview && <LinkButton color={color} />}
        </Post>
        {cropPreview && (
          <CropImages
            className={`${layoutClass}`}
            id={id}
            style={{
              position: "absolute",
              zIndex: -1,
              left: "0",
              top: "0"
            }}
          >
            {layout === "1" && <img src={blogData.coverimage1} alt="" />}
            {(layout === "=" || layout === "||") && (
              <>
                <img src={blogData.coverimage1} alt="" />
                <img src={blogData.coverimage2} alt="" />
              </>
            )}
            {(layout === "L" || layout === "7") && (
              <>
                <img src={blogData.coverimage1} alt="" />
                <img src={blogData.coverimage2} alt="" />
                <img src={blogData.coverimage3} alt="" />
              </>
            )}
            {layout === "+" && (
              <>
                <img src={blogData.coverimage1} alt="" />
                <img src={blogData.coverimage2} alt="" />
                <img src={blogData.coverimage3} alt="" />
                <img src={blogData.coverimage4} alt="" />
              </>
            )}
          </CropImages>
        )}
      </>
    );
  }
}
