import React from "react";
import { graphql, compose } from "react-apollo";
import { connect } from "react-redux";
import { Mask, Steps } from "components/Influencer";
import { Wrapper } from "containers/Influencer/Setup/Styles";
import FormAction from "containers/Influencer/Setup/FormAction";
import { insertPost } from "containers/Influencer/queries";
import PostSelection from "components/Influencer/PostTypes/PostSelection";
import getTranslation from "utils/getTranslation";
import { isMobile } from "react-device-detect";

class Step3 extends React.PureComponent {
  state = {
    destinations: [],
    step3: {
      title: ""
    },
    coverImages: []
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      text,
      title,
      type,
      itinerarydays
    } = this.state.step3;
    let isFormCompleted = false;
    if (type == "QUICK") {
      isFormCompleted = !!text;
    } else if (type == "BLOG") {
      isFormCompleted = !!text && title;
    } else if (type == "ITINERARY") {
      let data = null;
      if (itinerarydays) {
        data = itinerarydays.data;
      };
      isFormCompleted = title && data && data.length > 0 && !!data.every((ele) => ele.text && ele.title);
    }
    if (isFormCompleted !== this.state.isFormCompleted) {
      this.setState({
        isFormCompleted,
      });
    }

  }

  handleChange = (name, value) => {
    if (name == "type" && value != this.state.step3.type) {
      this.setState({
        step3: {
          ...this.state.step3,
          [name]: value,
          itinerarydays: undefined,
          text: undefined,
          title: "",
        }
      });
    } else {
      this.setState({
        step3: {
          ...this.state.step3,
          [name]: value,
          itinerarydays: undefined
        }
      });
    }
  };

  handleDestinationChange = destinations => {
    this.setState({
      destinations
    });
  };

  handleItineraryChange = days => {
    const data = days.map((day, index) => ({
      title: day.title,
      text: day.text,
      day: index + 1
    }));
    this.setState({
      step3: {
        ...this.state.step3,
        itinerarydays: {
          data
        }
      }
    });
  };

  handleBack = () => {
    this.props.history.push("/influencer/setup/step2");
  };
  handleNext = async () => {
    const coverImages = this.state.coverImages.filter(image => image.selected);
    this.setState({ loading: true });
    try {
      const response = await this.props.insertPost({
        variables: {
          objects: [
            {
              ...this.state.step3,
              influencer_id: this.props.influencerDetails.id,
              postdestinations: {
                data: this.state.destinations.map(destination => ({
                  destination
                }))
              },
              coverimage1: coverImages[0] ? coverImages[0].image : null,
              coverimage2: coverImages[1] ? coverImages[1].image : null,
              coverimage3: coverImages[2] ? coverImages[2].image : null,
              coverimage4: coverImages[3] ? coverImages[3].image : null
            }
          ]
        }
      });
      const postId = response.data.insert_post.returning[0].id;
      this.props.history.push("/influencer/setup/step4/" + postId);
    } catch (err) {
      console.log(err);
      this.setState({ loading: false });
    }
    // this.props.history.push("/influencer/setup/step4");
  };
  render() {
    const { step3, loading, coverImages, isFormCompleted } = this.state;
    return (
      <Wrapper>
        <Mask style={{ height: 52 }} />
        <h5 style={{ textAlign: "center", paddingTop: 10 }}>
          {getTranslation("tipRegistration")}
        </h5>
        <Steps activeStep={3} />
        <div className="container">
          <div style={isMobile ? { padding: "0 16px" } : {}}>
            <h2 style={{ marginTop: 50 }}>
              {getTranslation("createFirstPost")}
            </h2>
            <PostSelection
              data={step3}
              coverImages={coverImages}
              handleChange={this.handleChange}
              handleItineraryChange={this.handleItineraryChange}
              handleDestinationChange={this.handleDestinationChange}
            />
          </div>
          <FormAction
            loading={loading}
            activeStep={3}
            handleBack={this.handleBack}
            handleNext={this.handleNext}
            invalid={!isFormCompleted}
          />
        </div>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  influencerDetails: state.influencer.influencerDetails
});

export default compose(
  graphql(insertPost, { name: "insertPost" }),
  connect(mapStateToProps)
)(Step3);
