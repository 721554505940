import React, { PureComponent } from 'react';
import styled from 'styled-components';
import BaggageCount from 'components/common/BaggageCount';
import getTranslation from 'utils/getTranslation';
import Modal from 'components/common/Modal';
import {
  addDecimal,
  numberToLocaleString,
  getItem,
} from 'utils/utils';

const Wrapper = styled.div`
  width: 376px;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

export default class extends PureComponent {
  state = {
    title: getTranslation('addBaggage'),
    showChecked: false
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.open !== this.props.open && nextProps.open) {
      this.handleChangeTitle(this.props.extraBaggages);
    }
  }
  handleClose = () => {
    this.props.handleCloseModal();
  };

  handleChangeTitle = (baggages) => {
    const extraBaggages = baggages.filter(item => item.quantity > 0);
    if (extraBaggages.length > 0) {
      const { costDecimalPlaces, serviceCostCurrencyIso } = extraBaggages[0];
      const total = extraBaggages.reduce((accumulator, currentVal) => (
        accumulator + currentVal.serviceCostAmount * currentVal.quantity), 0);
        const cost = addDecimal(
          total,
          costDecimalPlaces,
        );
        const localizedCost = numberToLocaleString(cost, {
          selectedCountry: getItem('selectedLanguage'),
          selectedCurrency: serviceCostCurrencyIso,
        });
        this.setState({
          title: localizedCost,
          showChecked: true
        });
    } else {
      this.setState({
        title: getTranslation('addBaggage'),
      });
    }
  }

  render() {
    const { title, showChecked } = this.state;
    const {
      open,
      handleCloseModal,
      extraBaggages,
      extraBaggagesInbound,
      hopRequestId
    } = this.props;
    return (
      <Modal
        open={open}
        title={title}
        handleCloseModal={handleCloseModal}
        handleCheckClick={handleCloseModal}
        showChecked={showChecked}
        dialogStyle={{ width: 376, maxWidth: 376 }}
      >
        <Wrapper>
          {extraBaggages.length > 0 ? <BaggageCount
            extraBaggages={extraBaggages}
            extraBaggagesInbound={extraBaggagesInbound}
            hopRequestId={hopRequestId}
            handleChangeTitle={this.handleChangeTitle}
          /> : <span>{getTranslation('loading')}</span>}
        </Wrapper>
      </Modal>
    );
  }
}
