import React, { PureComponent } from "react";
import styled, { withTheme } from "styled-components";
import Modal from "components/common/Modal";
import getTranslation from "utils/getTranslation";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import ClickOutside from "react-click-outside";
import { connect } from "react-redux";
import { isMobile } from "react-device-detect";
import { DateRangePicker, createStaticRanges } from "react-date-range";
import { enGB, enUS, nl, fr, de, it, pt, es } from "date-fns/esm/locale";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import moment from "moment";
import {
    Button,
    Autocomplete,
} from "components/common";
import {
    DateIcon,
    DestinationIcon,
    FlightFrom,
    FlightFromError
} from "components/common/SvgIcons";

import { getItem } from "utils/utils";
import { ModalSelectedTitle } from 'components/common/CommonStyles';


const locales = [
    {
        country: "AT",
        locale: de,
    },
    {
        country: "BE",
        locale: nl,
    },
    {
        country: "DE",
        locale: de,
    },
    {
        country: "ES",
        locale: es,
    },
    {
        country: "FR",
        locale: fr,
    },
    {
        country: "GB",
        locale: enGB,
    },
    {
        country: "IE",
        locale: enUS,
    },
    {
        country: "IN",
        locale: enUS,
    },
    {
        country: "IT",
        locale: it,
    },
    {
        country: "LU",
        locale: fr,
    },
    {
        country: "MT",
        locale: enUS,
    },
    {
        country: "NL",
        locale: nl,
    },
    {
        country: "PT",
        locale: pt,
    },
    {
        country: "US",
        locale: enUS,
    },
    {
        country: "XX",
        locale: enUS,
    },
];


const StyledPopper = styled(Popper)`
  z-index: 999;
  .rdrStaticRangeLabel {
    color: ${(props) => props.theme.secondary};
    font-size: 12px;
    font-family: "Roboto";
  }
  .rdrDayNumber span {
    color: ${(props) => props.theme.secondary};
  }
  .rdrInRange ~ .rdrDayNumber span {
    color: ${(props) => props.theme.secondary} !important;
  }
  .rdrInRange {
    color: ${(props) => props.theme.primaryBackground} !important;
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: ${(props) => (props.isMobile ? "start" : "center")};
  font-size: 18px;
  cursor: pointer;
  @media (max-width: 800px) {
    text-align: left;
  }
`;
const Dates = styled.div`
  width: ${(props) => (props.isMobile ? "100%" : "45%")};
  border-bottom: 1px solid var(--black-color);
  color: ${(props) => (props.hasDate ? "var(--black-color)" : "#ccc")};
  position: relative;
  cursor: pointer;
  @media (min-width: 1000px) {
    width: 100%;
    margin-right: 5px;
  }
`;

const DateWrapper = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.16);
`;

const Overlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  @media (max-width: 800px) {
    cursor: none;
  }
`;

const Wrapper = styled.div`
  display: flex;
  width: 616px;
  min-height: 200px;
  position: relative;
  @media (max-width: 800px) {
    width: 100%;
  }
  .sideMenu {
    display: flex;
    flex-flow: column;
    width: 148px;
    border-right: 1px solid #eeeeee;
    a {
      text-decoration: none;
    }
    .item {
      padding: 18px 0px 15px 24px;
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      height: 72px;
      &.active {
        background: rgba(208, 243, 252, 0.5);
        box-shadow: inset 3px 0px 0px #56ccf2;
        span {
          font-weight: 600;
        }
      }
      img {
        height: 20px;
        margin-bottom: 5px;
      }
      span {
        font-size: 13px;
        color: #007ac7;
        text-transform: uppercase;
        font-family: "Roboto Condensed", sans-serif;
      }
    }
  }
  .content {
    .rdrStaticRangeLabel {
        color: ${(props) => props.theme.secondary};
        font-size: 12px;
        font-family: "Roboto";
      }
      .rdrDayNumber span {
        color: ${(props) => props.theme.secondary};
      }
      .rdrInRange ~ .rdrDayNumber span {
        color: ${(props) => props.theme.secondary} !important;
      }
      .rdrInRange {
        color: ${(props) => props.theme.primaryBackground} !important;
      }
    padding: 0 105px;
    width: 100%;
    position: relative;
    @media (max-width: 800px) {
      padding: 0 16px;
    }
  }
`;

const menuItems = [
    {
        name: getTranslation("dates"),
        option: "dates",
        icon: DateIcon
    },
    {
        name: getTranslation("destination"),
        option: "destination",
        icon: DestinationIcon
    }
];

let hotelDestListTimeout;

class EditHotelRequestModal extends PureComponent {
    constructor(props) {
        super(props);
        const selectionRange = {
            startDate: new Date(this.props.hotelReq.checkinDate * 1000),
            endDate: new Date(this.props.hotelReq.checkoutDate * 1000),
            key: 'selection',
            color: this.props.theme.primary,
            showDateDisplay: false
        };

        this.state = { selectedOption: "dates", selectionRange: [selectionRange], hoteldests: [], hoteldest: { code: null, name: null }, searchText: "", blured: false };
    };

    handleNewRequest = (i, dataSource) => {
        this.onHotelDest(i, dataSource || []);
    };

    onHotelDest = (index, dataSource) => {
        // if (index === -1) return;
        const hoteldest = dataSource[index];
        // console.log("all here " + JSON.stringify(dataSource) + ' index ' + index);
        let isSame = false;
        if (this.state.hoteldest && hoteldest.code === this.state.hoteldest.code) {
            isSame = true;
        }
        if (hoteldest) {
            this.setState(
                {
                    hoteldests: [],
                    hoteldest: { ...hoteldest },
                    searchText: `(${hoteldest.code}) ${hoteldest.name}`,
                    //   doReset: false,
                }
            );
        }
    };

    getLocale = () => {
        const locale = locales.find(
            (item) => item.country === getItem("selectedCountry")
        );
        return locale.locale || enUS;
    };

    handleClose = () => {
        // this.props.handleCloseModal(this.state.filterKvs.length > 0);
        this.props.handleCloseModal(true);
    };

    getPrevDates = () => {

        const { checkinDate, checkoutDate } = this.props.hotelReq;

        const initialDates = {
            startDate: new Date(checkinDate * 1000),
            endDate: new Date(checkoutDate * 1000),
            key: "selection",
            color: this.props.theme.primary,
            showDateDisplay: false,
        };
        return initialDates;
    };

    handleScroll = () => {
        document.querySelector(".rdrInfiniteMonths").scroll({
            top: document.querySelector(".rdrInfiniteMonths").scrollTop + 36,
            behavior: "smooth",
        });
    };

    handleOpen = (e) => {
        if (this.state.open) {
            return;
        }
        this.setState(
            {
                open: true,
                firstSelection: false,
                // title: this.props.departureLabel,
                anchorEl: e.currentTarget,
            },
            () => {
                this.scrollToDate(true);
            }
        );
    };

    scrollToDate = (delay) => {
        if (isMobile) return;
        setTimeout(
            () => {
                const currentMonth = moment().format("M");
                const startDateMonth = moment(
                    this.state.selectionRange[0].startDate
                ).format("M");
                const diff = startDateMonth - currentMonth;
                if (!isMobile && diff === 1) {
                    document.querySelector(".rdrInfiniteMonths").scroll({
                        top: 200,
                    });
                }
                this.handleScroll();
            },
            delay ? 100 : 10
        );
    };

    handleClose = () => {
        const initialDates = this.getPrevDates();
        this.props.handleChangeHotelReq(initialDates, this.props.hotelReq.originalTo);
    };

    handleCancel = () => {
        const initialDates = this.getPrevDates();
        this.setState({
            selectionRange: [initialDates],
            open: false,
            dateSelectedOnce: true,
            anchorEl: false,
        });
    };

    handleSelect = (selectionRange) => {
        // console.log('selectionRange ' + selectionRange.selection.startDate + ' ' + selectionRange.selection.endDate);
        const datesDifferent = moment(selectionRange.selection.startDate).unix() !==
            moment(selectionRange.selection.endDate).unix();

        this.setState(
            {
                dateSelectedOnce: true,
                firstSelection: true,
                selectionRange: [selectionRange.selection],
                saveBtnText: datesDifferent
                    ? this.props.okLabel
                    : this.props.oneWayLabel,
            },
            () => {
                if (!datesDifferent) {
                    this.scrollToDate();
                }
                // if (
                //     moment(this.state.selectionRange[0].startDate).unix() !==
                //     moment(this.state.selectionRange[0].endDate).unix()
                // ) {
                //     this.handleSubmit();
                // }
            }
        );
    };

    handleSubmit = () => {
        let { hotelReq } = this.props;
        let { hoteldest } = this.state;

        if ((hotelReq.destinationCodeType === "AIRPORT" && hoteldest.code) || (hotelReq.destinationCodeType === "DESTINATION" && hotelReq.destinationCode !== hoteldest.code)) {
            this.props.handleChangeHotelReq(this.state.selectionRange[0], this.props.hotelReq.originalTo, hoteldest);
        }
        else {
            this.props.handleChangeHotelReq(this.state.selectionRange[0], this.props.hotelReq.originalTo);
        }
    };

    handleMenuSwitch = (selectedOption) => {
        this.setState({ selectedOption: selectedOption });
    }

    handleHotelDestInputChange = (txt) => {
        this.setState({ searchText: txt }, () => {
            this.handleHotelDestFilter(txt.toLowerCase());
        });
    };

    handleHotelDestClose = () => {
        this.setState({ hoteldests: [] });
    };


    handleHotelDestFilter = (txt) => {
        let { hotelReq } = this.props;
        if (txt.length < 3) {
            this.setState({
                hoteldests: [],
            });
            return;
        }
        clearTimeout(hotelDestListTimeout);
        hotelDestListTimeout = setTimeout(() => {
            const allHotelDests = getItem("allHotelDests");
            let matchesArray = [];

            let filtered = allHotelDests.filter((item) =>
                item.code.toLowerCase().startsWith(txt)
            );
            matchesArray = [...matchesArray, ...filtered];

            filtered = allHotelDests.filter((item) =>
                item.name.toLowerCase().startsWith(txt)
            );
            matchesArray = [...matchesArray, ...filtered];

            // filtered = allAirports.filter((item) =>
            //     item.airportDesc.toLowerCase().startsWith(txt)
            // );
            // matchesArray = [...matchesArray, ...filtered];

            let distinct = [];
            matchesArray.slice(0, 30).forEach((item) => {
                const exists = distinct.find(
                    (data) => data.code === item.code
                );
                if (!exists) {
                    distinct = [...distinct, item];
                }
            });

            // if (airport && !isFromInput) {
            //     distinct = distinct.filter(
            //         (item) => item.airportIc !== airport.airportIc
            //     );
            // }

            // const metareaItem = distinct.find(
            //     (item) => item.metareaIc.toLowerCase() === txt
            // );
            // if (metareaItem) {
            //     distinct = [
            //         {
            //             ...metareaItem,
            //             airportIc: metareaItem.metareaIc,
            //             airportDesc: "All Airports",
            //         },
            //         ...distinct,
            //     ];
            //     const metaAreaIndex = distinct.findIndex(
            //         (item) =>
            //             item.metareaIc === item.airportIc &&
            //             item.airportDesc !== "All Airports"
            //     );
            //     if (metaAreaIndex !== -1)
            //         distinct[metaAreaIndex].airportDesc =
            //             distinct[metaAreaIndex].airportDesc + " (All Airports)";
            // }

            if (distinct.length > 0) {
                this.setState({
                    hoteldests: distinct,
                });
            } else {
                const selected = this.state.hoteldests.filter((item) =>
                    txt.toLowerCase().includes(item.code.toLowerCase())
                );
                this.setState({
                    hoteldests: selected,
                });
            }
        }, 300);
    };

    handleBlurCheck = (text, dataSource) => {
        console.log('in blur check');
        const txt = text.toLowerCase();
        const hoteldestIndex = dataSource.findIndex(
            (item) =>
                item.code.toLowerCase() === txt || item.name.toLowerCase() === txt
        );
        if (hoteldestIndex > -1) {
            this.onHotelDest(hoteldestIndex, dataSource);
            const { code, name } = dataSource[hoteldestIndex];
            this.setState({
                searchText: `(${code}) ${name}`,
                hoteldests: [],
                blured: true,
            });
        } else {
            this.setState({ hoteldests: [], blured: true });
        }
    };

    handleClear = () => {
        console.log("-----clear---from");
        this.setState({
            searchText: "",
            hoteldest: { code: null, name: null },
        });
        console.log("---tst--10--");
        // document.getElementById(`to-field${this.props.id}`).focus();
        // this._fromInput.focus();
    };

    render() {
        const { selectedOption, anchorEl, selectionRange, dateSelectedOnce, hoteldests, searchText, hoteldest } = this.state;
        const { open, hotelReq } = this.props;
        const width = Math.max(window.screen.width, window.innerWidth);

        const minDate = new Date();
        const maxDate = moment(minDate).add(365, 'day').toDate();
        const fromError = null;

        console.log("hoteldest " + JSON.stringify(hoteldest));
        return (


            <Modal
                open={open}
                title={dateSelectedOnce && selectionRange[0].startDate.getTime() !== selectionRange[0].endDate.getTime()
                    ? <ModalSelectedTitle>
                        <span>{hotelReq.destinationName}</span>
                        <span className="price">{moment(selectionRange[0].endDate).startOf('day').diff(moment(selectionRange[0].startDate).startOf('day'), 'days') + ' ' + getTranslation("nights")}</span>
                    </ModalSelectedTitle>
                    : <>
                        <span>{hotelReq.destinationName}</span>
                        <span>{moment(selectionRange[0].endDate).startOf('day').diff(moment(selectionRange[0].startDate).startOf('day'), 'days') + ' ' + getTranslation("nights")}</span>
                    </>
                }
                handleCloseModal={this.handleClose}
                handleCheckClick={this.handleSubmit}
                showChecked={selectedOption === "dates" ? (dateSelectedOnce && selectionRange[0].startDate.getTime() !== selectionRange[0].endDate.getTime()) : ((hotelReq.destinationCodeType === "AIRPORT" && hoteldest.code) || (hotelReq.destinationCodeType === "DESTINATION" && hotelReq.destinationCode !== hoteldest.code) ? true : false)}
                dialogStyle={{ width: 616, maxWidth: 616 }}
                titleHtml
                showReset={width > 320}
                handleReset={this.handleCancel}
                fallbackResetStyle={{ marginRight: 8 }}
            >
                <Wrapper>
                    {!isMobile && (
                        <div className="sideMenu">
                            {menuItems.map(item => (

                                <div
                                    className={`item ${item.option === selectedOption ? "active" : ""}`}
                                    key={item.option}
                                    onClick={() => { item.option !== selectedOption && this.handleMenuSwitch(item.option) }}
                                >
                                    <item.icon
                                        stroke={item.option === selectedOption ? "#007AC7" : "#56CCF2"}
                                    />
                                    <span>{item.name}</span>
                                </div>

                            ))}
                        </div>
                    )}
                    <div className="content">
                        {selectedOption === "dates" ? (
                            <Paper>
                                <DateRangePicker
                                    locale={this.getLocale()}
                                    onChange={this.handleSelect}
                                    staticRanges={[]}
                                    inputRanges={[]}

                                    minDate={minDate}
                                    maxDate={maxDate}
                                    direction="vertical"
                                    scroll={{
                                        enabled: true,
                                    }}
                                    ranges={selectionRange}
                                    showPreview={true}
                                    showMonthAndYearPickers={false}
                                    showMonthArrow={false}
                                    months={1}
                                    rangeColors={["red"]}
                                    className={
                                        "hideHolidays"
                                    }
                                />


                            </Paper>

                        ) : (
                                <Autocomplete
                                    id="destination"
                                    refName={(c) => {
                                        this._fromInput = c;
                                    }}
                                    label={getTranslation("altHotelDest")}
                                    value={hoteldest}
                                    type="hoteldest"
                                    maxSearchResults={30}
                                    dataSource={hoteldests}
                                    searchText={searchText}
                                    onChange={this.handleHotelDestInputChange}
                                    handleSelect={this.handleNewRequest}
                                    handleClose={this.handleHotelDestClose}
                                    onBlur={this.handleBlurCheck}
                                    handleClear={this.handleClear}
                                    icon={
                                        fromError ? (
                                            <FlightFromError />
                                        ) : (
                                                <FlightFrom error={fromError} />
                                            )
                                    }
                                    error={fromError}
                                />
                            )}
                    </div>
                </Wrapper>
            </Modal>

        );
    }
}


export default withTheme(
    EditHotelRequestModal
);
