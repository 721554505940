import React from 'react';
import styled from 'styled-components';
import { Loader } from 'components/common';
import CabinItem from './CabinItem';

const Wrapper = styled.div`
  padding: 15px;
`;

const cabins = [{
  value: '1',
  name: 'Economy',
  seatClass: 'ECONOMY'
}, {
  value: '2',
  name: 'Premium Economy',
  seatClass: 'PREMIUM'
}, {
  value: '3',
  name: 'Business Class',
  seatClass: 'BUSINESS'
}, {
  value: '4',
  name: 'First Class',
  seatClass: 'FIRST'
}];

export default class extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      filterKvs: props.filterKvs
    };
  }

  handleFilterToggle = (seatClass, isInputChecked) => {
    this.props.handleFiltersUpdate('SEAT_CLASS', seatClass, isInputChecked);
  }

  render() {
    const { filterKvs } = this.state;
    const { loading } = this.props;
    return (
      <Wrapper>
        <React.Fragment>
          {cabins.map(cabin => (
            <CabinItem
              key={cabin.value}
              item={cabin}
              handleFilterToggle={this.handleFilterToggle}
              filterKvs={filterKvs}
              loading={loading}
            />
          ))}
        </React.Fragment>
      </Wrapper>
    )
  }
}
