import React from "react";
import { getItem } from "utils/utils";
import config from "config";
import getTranslation from "utils/getTranslation";

let event;
export default class extends React.Component {
  constructor(props) {
    super(props);
    const tripId = props.tripId || getItem("tripId");
    const emp_transactionid = getItem("emp_transactionid");
    event = new EventSource(
      config.paymentHost + "/checkout_status_sse?tripId=" + tripId + "&empTransactionid=" + emp_transactionid
    );
    this.state = {
      tripId
    };
  }
  componentDidMount() {
    event.addEventListener("message", result => {
      if (result.data) {
        const data = JSON.parse(result.data);
        this.handleEventResponse(data);
      }
    });

    event.addEventListener("error", e => {
      console.log(e.data);
      this.props.handleShowBookingStatus(getTranslation("bookingFailed"));
      this.props.handleTripFailed();
    });
  }
  componentWillUnmount() {
    event.close();
  }
  handleEventResponse = async data => {
    const { lastStatus } = this.state;
    const {
      handleShowBookingStatus,
      handlePaymentApproved,
      handleTripBooked,
      handlePaymentError,
      handleTripFailed
    } = this.props;
    try {
      const currentStatus = data.checkoutStatus;
      if (currentStatus !== lastStatus) {
        this.setState({ lastStatus: currentStatus });
        if (currentStatus === "user_approved") {
          handleShowBookingStatus(getTranslation("paymentInitiated"));
        }
        if (currentStatus === "emp_approved") {
          handlePaymentApproved();
        }
        // if (currentStatus === "reconciled") {
        //   handleShowBookingStatus(getTranslation("bookingTrip"));
        // }
        if (
          currentStatus === "reconcile_failed" ||
          currentStatus === "emp_declined" ||
          currentStatus === "emp_error" ||
          currentStatus === "emp_timeout" ||
          currentStatus === "emp_voided"
        ) {
          handleShowBookingStatus(getTranslation("paymentAuthIssue"));
          handlePaymentError(currentStatus, true);
        }
        if (currentStatus === "trip_booked") {
          handleShowBookingStatus(getTranslation("tripBooked"));
          event.close();
          handleTripBooked();
        }
        if (currentStatus === "reconciled") {
          handleShowBookingStatus(getTranslation("bookingTrip"));
        }
        if (currentStatus === "trip_booking_failure" || currentStatus === "flights_booked_hotels_failed") {
          handleShowBookingStatus(getTranslation("bookingFailed"));
          event.close();
          handleTripFailed();
        }
      }
    } catch (err) {
      console.log(err);
      this.props.handlePaymentError("failed", true);
    }
  };

  render() {
    return null;
  }
}
