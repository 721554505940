import React, { Component } from "react";
import { connect } from "react-redux";
import { graphql, compose } from "react-apollo";
import { isMobile } from "react-device-detect";
import styled from "styled-components";
import moment from "moment";
import {
  removePassengerFromAllHopRequest,
  enablePassengerSticky,
} from "redux-store/actions";
import "./FlightSegment.css";
import { Classification, Baggage, DateChangeAndCancellation } from "./buttons";
import FlightInfoSegment from "./FlightInfoSegment";
import FlightNumAndDate from "./Partials/FlightNumAndDate";
import { FadeLine } from "components/common";
import { updHopsegmentcontrol } from "./queries";
import { delTripPassenger } from "components/Payment/PaymentQuery";
import {
  addDecimal,
  getItem,
  numberToLocaleString,
  setItem,
} from "utils/utils";
import getTranslation from "utils/getTranslation";

const Wrapper = styled.div`
  margin-bottom: 12px;
  .hopSegBtns {
    margin-top: 10px;
    display: flex;
    align-items: center;
    width: 100%;
    @media (max-width: 800px) {
      flex-wrap: wrap;
    }
    .hopSegButton {
      margin-right: 10px;
      @media (max-width: 800px) {
        margin-right: 10px;
      }
      &:last-child {
        margin-right: 0;
      }
      @media (max-width: 373px) {
        &:nth-child(2n) {
          margin-right: 0;
        }
        &:nth-child(3),
        &:nth-child(4) {
          .hopSegBtn {
            border-bottom: 0;
          }
        }
      }
    }
    @media (max-width: 373px) {
      .hopSegButton:first-child:nth-last-child(2),
      .hopSegButton:first-child:nth-last-child(2) ~ .hopSegButton {
        .hopSegBtn {
          border-bottom: 0;
        }
      }
    }
    @media (max-width: 800px) {
      width: 100%;
    }
  }
  .detailsWrapper {
    padding: 0 12px;
    background-color: #fff;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    border-top: none;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  }
`;

const Bordered = styled.div`
  border: 2px solid #dddddd;
  width: 100%;
`;

export class FlightSegmentComponent extends Component {
  constructor(props) {
    super(props);
    const { flightLocked } = this.props.hopRequest.hopSegmentControls[0];
    this.state = {
      // showAddHOP: false,
      firstValues: {
        dateChangePenalty: "FULL",
        cancellationPenalty: "FULL",
        seatChoice: "FULL",
      },
      disabledButtons: {},
      flightLocked,
      expand: true,
    };
  }

  componentDidMount() {
    if (
      this.props.hopRequest.hopSegmentControls[0].flightSegments.length === 0
    ) {
      this.props.handleNoFlightSegments();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.hopSegButtonError.type !== this.props.hopSegButtonError.type
    ) {
      setItem("lastFlightModified", moment().subtract(10, "minutes").unix());
      this.setState({
        disabledButtons: {
          ...this.state.disabledButtons,
          [nextProps.hopSegButtonError.type]: true,
        },
      });
      const { seatClass } = this.props.hopRequest.hopSegmentControls[0];
      this.handleUpdateHopSegment(
        {
          type: [nextProps.hopSegButtonError.type],
          value: seatClass,
        },
        true
      );
    }
    if (
      nextProps.allPassengersAdded &&
      nextProps.enablePaymentGateway &&
      isMobile
    ) {
      this.setState({
        expand: false,
      });
    }
  }

  removePassenger = (payload) => {
    this.props.removePassengerFromAllHopRequest(payload);
    this.props.enablePassengerSticky();
    if (getItem("passengers")) {
      const removedPassenger = getItem("passengers").filter((item) =>
        item.firstName === payload.firstName &&
        item.lastName === payload.lastName &&
        item.gender === payload.gender &&
        item.birthdate === payload.birthdate
          ? null
          : item
      );
      setItem("passengers", removedPassenger);
    }
  };

  handleRemovePassenger = (passenger) => {
    const payload = {
      ...passenger,
    };
    if (passenger.isTemprary) {
      this.removePassenger(payload);
    } else {
      this.props
        .delTripPassenger({
          variables: {
            firstName: passenger.firstName,
            lastName: passenger.lastName,
            gender: passenger.gender,
            birthdate: passenger.birthdate,
            tripId: getItem("tripId"),
          },
        })
        .then(() => {
          this.removePassenger(payload);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  handleUpdateFirstChoice = (type, value) => {
    this.setState({
      firstValues: {
        ...this.state.firstValues,
        [type]: value,
      },
    });
  };

  handleUpdateHopSegment = (payload, ignoreUpdateTrip) => {
    const resetValues = {};
    const extraValues = {};
    if (payload.withCancellation) {
      extraValues.cancellationPenalty = payload.value;
    }
    this.props
      .updHopsegmentcontrol({
        variables: {
          id: this.props.hopRequest.hopSegmentControls[0].id,
          [payload.type]: payload.value,
          ...resetValues,
          ...extraValues,
        },
      })
      .then(() => {
        if (!ignoreUpdateTrip) {
          this.props.handleUpdTriItenerary(
            false,
            "hopSegButton",
            payload.type,
            payload.value,
            true,
            payload.priceNum
          );
        }
      })
      .catch((err) => {
        console.log("handleUpdateHopSegment Error", err);
      });
  };

  handleEticket = () => {
    if (this.props.eticket) {
      const iframe = `<iframe width="100%" height="100%" src="data:application/pdf;base64,${encodeURI(
        this.props.eticket
      )}"></iframe>`;
      const x = window.open();
      x.document.open();
      x.document.write(iframe);
      x.document.close();
    }
  };

  handleTotalCost = (cost1, cost2, decimalPlaces) =>
    numberToLocaleString(addDecimal(Number(cost1 + cost2), decimalPlaces), {
      selectedCountry: getItem("selectedLanguage"),
      selectedCurrency: getItem("selectedCurrency"),
    });

  handleFlightLock = () => {
    const flightLocked = !this.state.flightLocked;
    this.setState({ flightLocked });
    this.handleUpdateHopSegment(
      { type: "flightLocked", value: flightLocked },
      true
    );
  };

  handleOpenModal = () => {
    if (this.props.fareRulesFound) {
      this.props.handleOpenModal();
    }
  };

  handleToggle = () => {
    this.setState({
      expand: !this.state.expand,
    });
  };

  render() {
    const { disabledButtons, expand } = this.state;
    const {
      hopRequest,
      loading,
      userTrips,
      tripCostItems = [{}],
      extraCheckDone,
      handleFilterModal,
      handleUpdTriItenerary,
      enablePaymentGateway,
      priceNum,
    } = this.props;
    const { hopSeqNum } = hopRequest;
    const {
      id,
      flightSegments,
      seatClass,
      dateChangePenalty,
      baggagePieceCount,
      baggagePieceMaxWeight,
      bcUndefined,
      webfare,
      extraBaggages,
      transferValue,
      refundValue,
      costDecimalPlaces,
      costCurrencyIso,
    } = hopRequest.hopSegmentControls[0];
    return (
      flightSegments.length > 0 && (
        <Wrapper
          className="__flight-segment-container"
          style={{ opacity: loading ? "0.3" : 1 }}
        >
          {/* <FlightNumAndDate
            flightInfo={flightSegments[0]}
            collapsable
            handleClick={this.handleToggle}
            expanded={expand}
          /> */}
          {expand && (
            <div className="detailsWrapper">
              {hopRequest.hopSegmentControls.map((hopReq) => (
                <div style={{ position: "relative", width: "100%" }}>
                  <FlightInfoSegment
                    key={hopReq.id}
                    hopRequest={hopRequest}
                    flightInfo={hopReq.flightSegments[0]}
                    handleUpdateRank={this.handleUpdateRank}
                    userTrips={userTrips}
                    handleFilterModal={handleFilterModal}
                    handleOpenModal={this.handleOpenModal}
                  />
                </div>
              ))}
              <div className="hopSegBtns">
                <Classification
                  flight={flightSegments}
                  onClick={this.handleUpdateHopSegment}
                  hopRequestId={hopRequest.id}
                  seatClass={seatClass}
                  userTrips={userTrips}
                  disabled={
                    userTrips ||
                    enablePaymentGateway ||
                    !extraCheckDone ||
                    disabledButtons.seatClass
                  }
                  loadingText={getTranslation("loading")}
                  baggagePieceMaxWeight={baggagePieceMaxWeight}
                  baggagePieceCount={baggagePieceCount}
                  hopsegmentcontrolId={id}
                  hopSeqNum={hopSeqNum}
                  handleUpdTriItenerary={handleUpdTriItenerary}
                  priceNum={priceNum}
                />
                {bcUndefined && (
                  <DateChangeAndCancellation
                    flight={flightSegments}
                    onClick={this.handleUpdateHopSegment}
                    hopRequestId={hopRequest.id}
                    dateChangePenalty={dateChangePenalty}
                    updateFirstChoice={this.handleUpdateFirstChoice}
                    userTrips={userTrips}
                    disabled={
                      userTrips || enablePaymentGateway || !extraCheckDone
                    }
                    loadingText={getTranslation("loading")}
                    transferValue={transferValue}
                    refundValue={refundValue}
                    costDecimalPlaces={costDecimalPlaces}
                    costCurrencyIso={costCurrencyIso}
                    costItemCurrencyIso={tripCostItems[0].costItemCurrencyIso}
                    costItemCurrencyDisplay={
                      tripCostItems[0].costItemCurrencyDisplay
                    }
                    hopsegmentcontrolId={id}
                    hopSeqNum={hopSeqNum}
                    priceNum={priceNum}
                  />
                )}
                {webfare && (
                  <Baggage
                    flight={flightSegments}
                    userTrips={userTrips}
                    loading={loading}
                    hopRequestId={hopRequest.id}
                    extraCheckDone={extraCheckDone}
                    disabled={
                      !webfare ||
                      enablePaymentGateway ||
                      (webfare && extraBaggages.length === 0)
                    }
                    baggagePieceMaxWeight={baggagePieceMaxWeight}
                    baggagePieceCount={baggagePieceCount}
                    loadingText={getTranslation("loading")}
                    extraBaggages={extraBaggages}
                    extraBaggagesInbound={
                      hopRequest.hopSegmentControls.length > 1
                        ? hopRequest.hopSegmentControls[1].extraBaggages
                        : []
                    }
                  />
                )}
              </div>
              <Bordered />
            </div>
          )}
        </Wrapper>
      )
    );
  }
}

const mapStateToProps = (state) => ({
  hopSegButtonError: state.reducers.hopSegButtonError,
  hopRequests: state.flightResults.hopRequests,
  tripCostItems: state.flightResults.tripCostItems,
  enablePaymentGateway: state.flightResults.enablePaymentGateway,
  allPassengersAdded: state.flightResults.allPassengersAdded,
});

const mapDispatchToProps = {
  removePassengerFromAllHopRequest,
  enablePassengerSticky,
};

export default compose(
  graphql(delTripPassenger, { name: "delTripPassenger" }),
  graphql(updHopsegmentcontrol, { name: "updHopsegmentcontrol" }),
  connect(mapStateToProps, mapDispatchToProps)
)(FlightSegmentComponent);
