import React, { Component } from 'react';
import moment from 'moment';
import { isMobile } from "react-device-detect";
import { Input } from 'components/common';

export default class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hintText: this.props.label || 'Exp',
      value: this.props.defaultValue || '',
    };
  }

  handleChange = (e, value) => {
    const formatted = this.formatExpiry(value) || '';
    const checkIfDone = formatted.split(' / ').join('');
    if (checkIfDone.length > 4) {
      return;
    }
    this.setState({
      value: formatted,
    }, () => {
      const check = formatted.split(' / ').join('');
      if (check.length >= 4) {
        this.handleDateConversion();
      }
    });
  }

  handleDateConversion = () => {
    const dateArray = this.state.value.split(' / ');
    const date = `20${dateArray[1]}-${dateArray[0]}-01`;
    const fullDate = moment(date).toDate();
    console.log(fullDate);
    this.props.onChange(fullDate);
  }

  formatExpiry = (prevExpiry) => {
    if (!prevExpiry) return null;
    let expiry = prevExpiry;
    if (/^[2-9]$/.test(expiry)) {
      expiry = `0${expiry}`;
    }
    expiry = expiry.match(/(\d{1,2})/g);
    if (expiry.length === 1) {
      if (prevExpiry.includes('/')) {
        return expiry[0];
      }
      if (/\d{2}/.test(expiry)) {
        return `${expiry[0]} / `;
      }
    }
    return expiry.join(' / ');
  };

  handleFocus = () => {
    this.setState({ hintText: 'MM/YY' });
  }
  handleBlur = () => {
    this.setState({ hintText: this.props.label || 'Exp' });
  }

  render() {
    const { value, hintText } = this.state;
    const { width, noMargin } = this.props;
    return (
      <Input
        disabled={this.props.disabled}
        hintText={hintText}
        style={{
          width: width || '50%',
          marginLeft: (isMobile && !noMargin) ? 20 : 0,
          height: 48,
        }}
        value={value}
        onChange={this.handleChange}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        image="/assets/input-icons/expiry.svg"
      />
    );
  }
}
