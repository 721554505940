import React from 'react';
import styled from 'styled-components';
import { Button } from 'components/common';
import FlightDetails from './FlightDetails';
import getTranslation from 'utils/getTranslation';
import { addDecimal, numberToLocaleString, getItem } from 'utils/utils';

const Wrapper = styled.div`
  width: 776px;
  margin: auto;
  @media (max-width: 800px) {
    width: 100%;
  }
  .costAndEticket {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .passengers {
      width: 376px;
    }
    .eTicket {
      width: 376px;
      .MuiButtonBase-root:first-child {
        margin-bottom: 20px;
      }
    }
    @media (max-width: 800px) {
      flex-flow: column;
      .passengers, .eTicket {
        width: 100%;
      }
      .passengers {
        margin-bottom: 20px;
      }
    }
  }
  .hotelCostAndConf {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .guests {
      width: 376px;
    }
    .bookingconf {
      width: 376px;
      .MuiButtonBase-root:first-child {
        margin-bottom: 20px;
      }
    }
    @media (max-width: 800px) {
      flex-flow: column;
      .guests, .bookingconf {
        width: 100%;
      }
      .guests {
        margin-bottom: 20px;
      }
    }
  }
  .container {
    display: flex;
    margin-top: 15px;
  }
`;

const Passengers = styled.div`
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  padding: 10px 15px;
  display: flex;
  .left {
    display: flex;
    align-items: flex-start;
    flex-flow: column;
    justify-content: center;
  }
  .right {
    padding: 0 10px;
  }
  .left, .right {
    width: 50%;
    span {
      color: #777777;
      font-size: 12px;
      font-weight: 300;
    }
    p {
      font-size: 18px;
      color: #222222;
      margin: 10px 0;
      text-transform: capitalize;
    }
  }
  .line {
    height: 1px;
    width: 100%;
    background-color: #D0F3FC;
    &.vertical {
      width: 1px;
      height: auto;
    }
  }
`;

const Guests = styled.div`
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  padding: 10px 15px;
  display: flex;
  .left {
    display: flex;
    align-items: flex-start;
    flex-flow: column;
    justify-content: center;
  }
  .right {
    padding: 0 10px;
  }
  .left, .right {
    width: 50%;
    span {
      color: #777777;
      font-size: 12px;
      font-weight: 300;
    }
    p {
      font-size: 18px;
      color: #222222;
      margin: 10px 0;
      text-transform: capitalize;
    }
  }
  .line {
    height: 1px;
    width: 100%;
    background-color: #D0F3FC;
    &.vertical {
      width: 1px;
      height: auto;
    }
  }
`;

export default ({
  passengers = [],
  tripCostItems,
  hopRequests,
  eticket,
  handleEticket,
  handleOpenModal,

  guests = [],

}) => {
  return (
    <>
      <Wrapper>
        <div className="costAndEticket">
          <Passengers className="passengers">
            <div className="left">
              <span>{getTranslation('passengers')}</span>
              {passengers.map(passenger => (
                <p>
                  {passenger.title.charAt(0) +
                    passenger.title.slice(1).toLowerCase()}{' '}
                  {passenger.firstName} {passenger.lastName}
                </p>
              ))}
            </div>
            <div className="line vertical" />
            <div className="right">
              <span>{getTranslation('totalCost')}</span>
              <p>{numberToLocaleString(
                addDecimal(
                  tripCostItems[0].cost + tripCostItems[1].cost,
                  tripCostItems[0].costDecimalPlaces,
                ),
                {
                  selectedCountry: getItem('selectedLanguage'),
                  selectedCurrency: tripCostItems[0].costItemCurrencyIso,
                },
              )}
              </p>
              <div className="line" />
              <span>{getTranslation('totalTax')}</span>
              <p>{numberToLocaleString(
                addDecimal(
                  tripCostItems[1].cost,
                  tripCostItems[0].costDecimalPlaces,
                ),
                {
                  selectedCountry: getItem('selectedLanguage'),
                  selectedCurrency: tripCostItems[0].costItemCurrencyIso,
                },
              )}
              </p>
            </div>
          </Passengers>
          <div className="eTicket">
            <Button disabled={!eticket} onClick={handleEticket}>{!eticket ? getTranslation('awaitingEticket') : getTranslation('downloadEticket')}</Button>
            <Button onClick={handleOpenModal} color="#fff" style={{ color: '#007AC7' }}>{getTranslation('viewFareRule')}</Button>
          </div>
        </div>
        {hopRequests.map(hopRequest => (
          <FlightDetails hopRequest={hopRequest} />
        ))}
      </Wrapper>

      <Wrapper>
        <div className="hotelCostAndConf">
          <Guests className="guests">
            <div className="left">
              <span>{getTranslation('guests')}</span>
              {guests.map(guest => (
                <p>
                  {guest.title.charAt(0) +
                    guest.title.slice(1).toLowerCase()}{' '}
                  {guest.firstName} {guest.lastName}
                </p>
              ))}
            </div>
            <div className="line vertical" />
            <div className="right">
              <span>{getTranslation('totalCost')}</span>
              <p>{numberToLocaleString(
                addDecimal(
                  tripCostItems[0].cost + tripCostItems[1].cost,
                  tripCostItems[0].costDecimalPlaces,
                ),
                {
                  selectedCountry: getItem('selectedLanguage'),
                  selectedCurrency: tripCostItems[0].costItemCurrencyIso,
                },
              )}
              </p>
              <div className="line" />
              <span>{getTranslation('totalTax')}</span>
              <p>{numberToLocaleString(
                addDecimal(
                  tripCostItems[1].cost,
                  tripCostItems[0].costDecimalPlaces,
                ),
                {
                  selectedCountry: getItem('selectedLanguage'),
                  selectedCurrency: tripCostItems[0].costItemCurrencyIso,
                },
              )}
              </p>
            </div>
          </Guests>
          <div className="eTicket">
            <Button disabled={!eticket} onClick={handleEticket}>{!eticket ? getTranslation('awaitingEticket') : getTranslation('downloadEticket')}</Button>
            <Button onClick={handleOpenModal} color="#fff" style={{ color: '#007AC7' }}>{getTranslation('viewFareRule')}</Button>
          </div>
        </div>
        {hopRequests.map(hopRequest => (
          <FlightDetails hopRequest={hopRequest} />
        ))}
      </Wrapper>
    </>
  );
};
