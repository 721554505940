import React, { Component } from "react";
import { Button } from "components/common";
import IconDelete from 'assets/icons/Icon-delete-childage.svg';
import styled, { withTheme } from "styled-components";
import { AddIcon } from "components/common/SvgIcons";

import { Select } from "components/common";
import MenuItem from "@material-ui/core/MenuItem";
import getTranslation from "utils/getTranslation";
import FilledInput from "@material-ui/core/FilledInput";

class ChildAge extends Component {
    constructor(props) {
        super(props);
        this.state = { openSelect: false, age: 2 };
    }

    handleChange = (e) => {
        let { value } = e.target;
        let errors = {};
        console.log("------hi---value-----", typeof value);
        if (value < 0 || value > 17) {
            errors.age = getTranslation("childAgeError");
        }
        this.setState({
            openSelect: false,
            errors
        }, () => {
            this.props.handleAddNewChildAge(value);
        });

    };

    handleOpenSelect = () => {
        let { openSelect } = this.state;
        this.setState({ openSelect: !openSelect });
    }

    render() {
        const { type, childAge, index, isPlaceholder } = this.props;
        const { openSelect, age } = this.state;
        let count = 1;
        return (
            <>
                {isPlaceholder ?

                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ display: "flex", flexDirection: "column", padding: "7px 10px", width: 44, minHeight: 80, background: "#D0F3FC", borderRadius: 22, marginLeft: 12 }} onClick={this.handleOpenSelect}>
                            <AddIcon style={{ height: 24, width: 24 }} />
                        </div>
                        {
                            (openSelect &&

                                <div>
                                    <Select
                                        // label={getTranslation("selectBaggage")}

                                        open={openSelect}
                                        value={age}
                                        handleChange={this.handleChange}
                                        // input={<FilledInput name="age" id="filled-age-simple" />}
                                        style={{ marginTop: 400, marginLeft: -15 }}
                                        menuItems={[0, 1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map((item) => (
                                            <MenuItem
                                                key={item}
                                                value={item}
                                            >
                                                {item == 0 ? 'Under 1' : (item == 1 ? '1 Year' : item + ' Years')}
                                            </MenuItem>

                                        ))}
                                    />
                                </div>

                            )
                        }
                    </div>
                    :
                    <div style={{ width: 44, height: 80, marginLeft: 12, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-around", background: "linear-gradient(0deg, rgba(208, 243, 252, 0.5), rgba(208, 243, 252, 0.5)), #FFFFFF", border: "1px solid #007AC7", boxSizing: "border-box", borderRadius: 22 }}>
                        <img
                            src={require(`assets/icons/${type}${
                                count > 0 && type !== "adult" ? "-black" : ""
                                }.svg`)}
                            // height={type === "infant" ? 36 : 38}
                            height={24}
                            alt=""
                        />
                        <p style={{ fontSize: 12, margin: 0 }}>{childAge}y</p>
                        <div style={{
                            width: 18,
                            minWidth: 18,

                        }}>
                            <Button
                                style={{
                                    height: 18,
                                    width: 18,
                                    minWidth: 18,
                                    padding: '0px'
                                }}
                                color="transparent"
                                onClick={() => this.props.handleDelete(index)}
                            >
                                <img src={IconDelete} alt="" />
                            </Button>
                        </div>
                    </div>
                }
            </>
        )
    }

}

export default withTheme(ChildAge);