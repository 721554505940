import React, { Component } from 'react';
import { ApolloConsumer } from 'react-apollo';
import MyTrips from './MyTrips';

export default class extends Component {
  componentDidMount() {
    //
  }
  render() {
    return (
      <ApolloConsumer>
        {client => (
          <MyTrips client={client} />
        )}
      </ApolloConsumer>
    );
  }
}
