export default `
# Warunki korzystania

Niniejsze ogólne warunki (&quot;Warunki&quot;) obowiązują między użytkownikiem (&quot;Użytkownik&quot; lub &quot;Klientem&quot;) a Fly Strait OÜ (&quot;My&quot; lub &quot;Nas&quot;), operatorem portalu rezerwacji flystrait.com (zwanego dalej jako &quot;Portal&quot;). Wymagane jest uważne przeczytanie niniejszych Warunków przed korzystaniem z Portalu. Korzystając z Portalu, zgadzają się Państwo na te Warunki. Jeśli nie akceptują Państwo tych Warunków, prosimy, o nie korzystnie z Portalu i opuszczenie strony internetowej.

Musicie mieć Państwo ukończone 18 lat i działać jako osoba fizyczna, aby dokonać rezerwacji na portalu. Osoba fizyczna jest przeciwieństwem przedsiębiorstwa, osoba fizyczna, zawiera transakcję prawną do celów nie związanych z jej działalnością handlową / lub działalnością na własny rachunek.

Operatorem Portalu i partnerem w trakcie korzystania z Portalu jest:

Fly Strait OÜ (Fly Strait)

E-mail: support@flystrait.com

Warunki są następujące:

#
# 1. ZAKRES USŁUG

### 1.1 Pośrednictwo usług turystycznych

1.1.1 W przypadku usług rezerwacji lotów oferowanych na Portalu (zwianych &quot;usługami turystycznymi&quot;), działamy wyłącznie w ramach naszej funkcji pośrednika. W tym celu nasza rola i obowiązki ograniczają się do pośrednictwa w usługach turystycznych, które będą świadczone przez strony trzecie, takie jak linie lotnicze (dalej w każdym przypadku &quot;Usługodawca&quot;).

1.1.2 W związku z tym umowa o faktyczne świadczenie usług turystycznych zostaje zawarta bezpośrednio między Państwem a odpowiednim usługodawcą. Nie jesteśmy dostawcą usług turystycznych i nie jesteśmy stronami umowy między Państwem a Usługodawcą.

1.1.3 Korzystając z Portalu w celu zakupu Usług Transportowych, upoważniają Państwo nas do pośredniczenia w kontaktach z odpowiednim usługodawcą (usługami) w Państwa imieniu, w tym pośrednictwa w płatnościach za te Usługi Transportowe, w celu zapewnienia, że ​​transakcja między Państwem a Usługodawcą (s) jest wykonywana. Cena wystawiona na faktyczne usługi turystyczne może obejmować prowizję za usługi, które świadczymy w celu pośredniczenia w umowie między Państwem a odpowiednim usługodawcą.

1.1.4 Odpowiedzialność za faktyczne wykonywanie Usług Turystycznych za pośrednictwem Portalu ponosi wyłącznie właściwy Usługodawca. Jako pośrednik nie ponosimy żadnej odpowiedzialności za usługi turystyczne świadczone przez dostawców usług i nie składamy żadnych oświadczeń ani zapewnień (ani wyraźnych, ani dorozumianych) dotyczących przydatności lub jakości usług turystycznych oferowanych w Portalu. W przypadku wszelkich roszczeń związanych z wykonaniem lub niewykonaniem Usługi Podróżnej właściwym odbiorcą jest Usługodawca.

1.1.5 W przypadku, gdy podczas rezerwacji usług turystycznych macie Państwo możliwość złożenia zamówienia na specjalne życzenia (takie jak specjalne posiłki, udogodnienia dla niepełnosprawnych lub foteliki dziecięce), przekażemy Państwa prośbę właściwemu usługodawcy. Jednakże nie możemy ponosić odpowiedzialności za to, czy Usługodawca rzeczywiście spełni takie życzenia.

### 1.2 Obowiązujące warunki umowy

1.2.1 Niniejsze Warunki mają zastosowanie do pośrednictwa Usług Turystycznych zgodnie z punktem 1.1 oraz do naszych własnych usług zgodnie z punktem 1.2.

1.2.2 W przypadku umowy między Użytkownikiem a odpowiednim usługodawcą (punkt 1.1.2), ogólne warunki i postanowienia odpowiedniego usługodawcy (takie jak warunki przewozu, warunki transferu, warunki ubezpieczenia itp.), Wydane przez Usługodawcę mają zastosowanie. Takie ogólne warunki i postanowienia odpowiedniego usługodawcy zostaną Państwu przekazane w trakcie procesu rezerwacji. Ponieważ warunki i postanowienia Usługodawcy mogą zawierać postanowienia dotyczące odpowiedzialności, anulowania, zmiany rezerwacji i zwrotów (jeśli są dostępne) oraz innych ograniczeń, zalecamy uważne przeczytanie tych warunków.

#
# 2. Proces rezerwacji i dane kontaktowe

2.1 Podczas procesu rezerwacji Użytkownik otrzymuje środki techniczne niezbędne do wykrycia błędów w formularzach zgłoszeniowych i do ich poprawienia przed przesłaniem prośby o rezerwację. Przed złożeniem wniosku o rezerwację prosimy o sprawdzenie wszystkich danych pod kątem dokładności. Kolejne wnioski o zmianę danych mogą wymagać  znacznych dodatkowych kosztów.

2.2 Być może będziemy musieli się z Państwem skontaktować, np. W przypadku późniejszych zmian w zakupionych usługach turystycznych. Musicie Państwo podać dokładne dane kontaktowe, takie jak numer telefonu i adres e-mail. Musicie Państwo także stale sprawdzać, czy otrzymali Państwo od nas wiadomość.

Państwa obowiązkiem jest również upewnić się, że jesteście w stanie odbierać nasze wiadomości. Nie ponosimy odpowiedzialności za nie otrzymanie od nas wiadomości z przyczyn poza naszą kontrolą, w tym między innymi: (i)Podali nam Państwo nieprawidłowy adres e-mail; (ii) ustawienia poczty e-mail uniemożliwiają naszemu e-mailowi ​​dotarcie do Państwa lub (iii) ustawienia poczty e-mail traktują nasz e-mail jako spam.

#
# 3. Mediacja lotów

### 3.1 Zawarcie umowy i zmiany cen

3.1.1 Po przesłaniu prośby o rezerwację, potwierdzimy otrzymanie wniosku pocztą elektroniczną. Jest to moment, w którym powstaje umowa pośrednictwa między Państwem a nami.  Jeśli nie otrzymacie Państwo takiego potwierdzenia w ciągu godziny od wypełnienia żądania rezerwacji, a żaden z naszych operatorów nie skontaktował się z państwem przez e-mail lub telefon, aby wskazać jakiekolwiek problemy, skontaktuje się Państwo z nami telefonicznie w celu weryfikacji.

3.1.2 Po otrzymaniu zamówionych biletów otrzymają Państwo wiadomość e-mail z potwierdzeniem z numerem biletu. W tym momencie została zawarta wiążąca umowa między Państwem a odpowiednimi liniami lotniczymi.

3.1.3 Ceny za lot oraz dostępność miejsc są wprowadzane bezpośrednio przez odpowiednią linię lotniczą. W przypadku zmian cen linii lotniczych, które nie są kontrolowane po złożeniu wniosku o rezerwację, ale zanim umowa z linią lotniczą stała się wiążąca (jak określono w punkcie 3.1.2 powyżej), umowa nie wejdzie w życie, a Państwa płatność zostanie w pełni zwrócona. Możemy skontaktować się z Państwem i zaoferować Wam możliwość zaakceptowania zmienionej ceny w zwykłych godzinach pracy, jednak nie później niż 24 godziny po uzyskaniu informacji o zmianie ceny lub w pierwszym dniu powszednim po upływie takiego 24-godzinnego okresu.

### 3.2 Informacje o rezerwacji i warunkach transportu

3.2.1 W odniesieniu do lotów oferowanych na Portalu, działamy wyłącznie jako pośrednik. Umowa dotycząca wykonania lotu wchodzi w życie bezpośrednio między Państwem a odpowiednią linią lotniczą i nie przyjmujemy żadnej odpowiedzialności związanej z wykonaniem lub niewykonaniem Waszego lotu. Obsługująca linia lotnicza ponosi wyłączną odpowiedzialność za wykonanie / niewykonanie lotu.

3.2.2 Przed dokonaniem rezerwacji, warunki odpowiedniej linii lotniczej zostaną udostępnione Użytkownikowi.

3.2.3 Poniżej, w ramach ogólnego zarysu, udzielamy informacji na temat warunków związanych z rezerwacją i transportem, konwencjonalnie stosowanych przez linie lotnicze w taki lub podobny sposób. Jednak wszelkie odbiegające postanowienia odpowiednich linii lotniczych mają pierwszeństwo przed ogólnymi informacjami zawartymi w niniejszym punkcie 3.2.3. Dlatego w każdym przypadku przed dokonaniem rezerwacji prosimy sprawdzić obowiązujące warunki odpowiedniego przewoźnika.

#### a. Czas lotu / odprawa

Wszystkie podane czasy odlotów są czasami lokalnymi. Przyloty następnego dnia są oznaczone jako &quot;+1&quot; w rozkładzie. Podane czasy odlotów są wstępne i mogą ulec zmianie w krótkim czasie po wystawieniu biletu; na przykład z powodu ograniczeń związanych z kontrolą lotów, warunkami pogodowymi lub ograniczeniami związanymi funkcjonowaniem linii lotniczej. Proszę być na bieżąco z aktualnym czasem na długo przed lotem.

Prosimy przestrzegać czasów odprawy podanych przez linię lotniczą. Linie lotnicze mają prawo do odmowy przyjęcia na pokład, jeśli spóźnicie się Państwo odprawę. Należy pamiętać, że niektóre linie lotnicze zachęcają do odprawy za pośrednictwem własnej strony internetowej, ponieważ mogą pobierać opłatę za odprawę podczas odprawy na lotnisku.

#### b. Łączenie poszczególnych biletów

Łączenie dwóch oddzielnych biletów w jedną stronę zamiast biletu w obie strony jest wyraźnie oznaczone jako takie podczas procedury rezerwacji. Bilety są traktowane niezależnie od siebie w przypadku odwołania, zmiany, zakłóceń w ruchu lotniczym, takich jak strajki i zmiany w rozkładzie lotów. Obowiązują przepisy własne każdej linii lotniczej.

Bilety lotnicze z różnymi numerami rezerwacji są zawsze traktowane jako podróże niezależne od siebie.

#### do. Bilety lotnicze z kilkoma odcinkami / kolejność użytkowania

Bilet w obie strony lub bilet w jedną stronę może składać się z kilku odcinków. Zgodnie z warunkami większości linii lotniczych, takie odcinki lotów należy wykorzystać po kolei. Jeśli nie, wiele linii lotniczych odmówi transportu na kolejnych odcinkach lotu (np. Niewykorzystanie jednego odcinka podróży może unieważnić resztę biletu). W przypadku biletów w obie strony, nieobecność na locie docelowym może spowodować anulowanie lotu powrotnego przez linię lotniczą

#### d. Ciąża

Niektóre linie lotnicze odmawiają przewozu kobiet, które przekroczyły 28 tydzień ciąży w czasie lotu docelowego lub powrotnego. Jeśli jest Pani w ciąży powinna Pani ustalić z liniami lotniczym i ze swoim lekarzem, czy możesz Pani rozpocząć podróż.

#### e. Niemowlęta i bilety dla dzieci

Proszę skontaktować się z linią lotniczą, aby uzyskać warunki podróżowania z dzieckiem, które nie ma oddzielnego siedzenia. Zwykle dzieci w wieku powyżej 2 lat wymagają osobnego siedzenia, podczas gdy dzieci w wieku od 0 do 2 lat podróżują jako niemowlęta i nie będą miały przydzielonego miejsca. Jeżeli niemowlę osiągnie wiek 2 lat przed końcem podróży, należy zarezerwować bilet dla dziecka na całą podróż. Bilety niemowlęce nie mogą być rezerwowane przed porodem, ponieważ prawidłowe imię i data urodzenia muszą być zgodne z danymi podanymi w paszporcie. Nie będziemy zwracać żadnych kosztów, które powstaną, jeśli od samego początku zarezerwowano niewłaściwy typ biletu.

#### f. OSOBY MAŁOLETNIE BEZ OPIEKI

Nie pośredniczymy w rezerwacji biletów dla nieletnich bez opieki. Dzieci w wieku poniżej 18 lat muszą zostać zarezerwowane na podróż w towarzystwie osoby dorosłej. Niektóre kraje i linie lotnicze odmawiają wstępu dzieciom w wieku poniżej 18 lat, chyba że towarzyszy im opiekun prawny. Należy pamiętać, że niektóre linie lotnicze wymagają, aby dzieci poniżej 18 roku życia podróżowały ze aktem urodzenia.

#### g. Utracony / uszkodzony bagaż

Jako pośrednik nie ponosimy odpowiedzialności za zagubiony lub uszkodzony bagaż. Wszelkie problemy należy niezwłocznie zgłosić przedstawicielowi linii lotniczej na lotnisku.

#### h. Tranzyt i nocleg

Ogólnie rzecz biorąc, transport naziemny i / lub nocleg w trakcie podróży nie jest wliczony w cenę biletu lotniczego. Jesteście Państwo osobiście odpowiedzialni za sprawdzenie rozkładów i cen transportu naziemnego.

#### I. Czas połączenia między lotami

Standardowe bilety zarezerwowane na portalu mają zatwierdzone czasy przesiadek. Czasy wymagane do wykonania przesiadek między lotami są obliczane przez linie lotnicze. Jeśli odcinek lotu jest opóźniony i prowadzi do utraty połączenia, linie lotnicze są zobowiązane do udzielenia pomocy w dotarciu do miejsca docelowego (patrz punkt 9.1).

Po zarezerwowaniu oddzielnych biletów linie lotnicze nie ponoszą odpowiedzialności za niezrealizowane połączenia wynikające z opóźnień. W związku z tym, Państwa obowiązkiem jest upewnienie się, że czas na przesiadkę jest wystarczający, zgodnie z wymogami liniamii lotniczych i lotniska. Wszelkie dodatkowe koszty poniesione w związku z utraconymi połączeniami nie będą zwracane.

#### j. Podwójna rezerwacja

Podwójna rezerwacja oznacza, że ​​dwie lub więcej rezerwacje dla tego samego pasażera zostało złożonych w tej samej linii lotniczej. Jeśli macie Państwo podwójną rezerwację, linia lotnicza może anulować podróż (e). Może się to również zdarzyć, jeśli rezerwacje zostały dokonane w różnych biurach podróży. Nie ponosimy odpowiedzialności za odwołania dokonane przez linie lotnicze, ani za odmowę zwrotu od linii lotniczych, w przypadku gdy podejrzewają podwójną rezerwację.

### 3.3 Linie lotnicze, które nie mogą prowadzić działalności w UE (czarna lista)

Należy zauważyć, że niektóre linie lotnicze nie mogą prowadzić działalności w UE, zgodnie z decyzją podjętą przez Komisję Europejską w ścisłej konsultacji z krajowymi organami transportu lotniczego. Działalność takich linii lotniczych jest zabroniona, ponieważ są uważane za niebezpieczne lub nie podlegają odpowiedniej kontroli władz danego kraju.

Można sprawdzić, których linii lotniczych dotyczy zakaz, klikając poniższy link: [Czarna lista (lista linii lotniczych, które nie mogą prowadzić działalności w UE)](https://ec.europa.eu/transport/language-selector/site-language_en?destination=node/2840)

### 3.4 Zmiany w rozkładzie i odwołanie przez linie lotnicze

3.4.1 Państwa umowa z odpowiednią linią lotniczą może pozwalać na anulowanie lub zmianę rezerwacji. Poinformujemy Państwa o wszelkich zmianach, gdy zostaniemy o nich poinformowani przez linię lotniczą.

3.4.2 Czas odlotu podany w potwierdzeniu rezerwacji może ulec zmianie między datą rezerwacji a datą rzeczywistej podróży. W przypadku zmiany rozkładu lotów, powiadomimy Państwa, gdy tylko zostaniemy o tym poinformowani przez linię lotniczą. Zalecamy jednak skontaktowanie się z linią lotniczą co najmniej 72 godziny przed planowanym odlotem każdego lotu, aby upewnić się, że lot (y) i loty łączone odbędą się zgodnie z planem. Nie mamy wpływu na zmiany rozkładu linii lotniczych i nie ponosimy odpowiedzialności za koszty, które mogą powstać w wyniku takich zmian.

### 3.5 Zmiany i rezygnacja z Państwa winy

Linie lotnicze oferujące tanie linie lotnicze, patrz punkt 3.7.

3.5.1 Warunki zmiany rezerwacji lotu (w tym zmiana nazwiska pasażera, miejsca docelowego i daty podróży) oraz dotyczące zwrotu kosztów są określane przez odpowiednią linię lotniczą, która jest partnerem związanym umową w zakresie zapewnienia lotu. My, jako pośrednik, nie mamy wpływu na te warunki.

3.5.2 Jeśli chcecie dokonać zmiany rezerwacji lub poprosić o zwrot kosztów, jako dodatkową usługę, oferujemy obsługę wniosku w Waszym imieniu, pod warunkiem, że warunki linii lotniczej zezwalają na taką zmianę lub zwrot kosztów. W trakcie rezerwacji takich dodatkowych usług poinformujemy Państwa o wszelkich dalszych warunkach takich usług. Możecie oczywiście skontaktować się bezpośrednio z odpowiednią linią lotniczą.

3.5.3 Abyśmy byli w stanie zrealizować zmiany, których oczekujecie konieczne jest, abyśmy otrzymali Państwa żądania najpóźniej na 24 godziny przed rozpoczęciem podróży (tylko telefonicznie). Jeśli kupiliście naszą usługę Flexible Ticket, patrz punkt 10.

W przypadku żądań zmian z krótszym wyprzedzeniem zalecamy skontaktowanie się bezpośrednio z odpowiednią linią lotniczą.

### 3.6 Niestawienie się lub nieobecność podczas lotu

Niniejszym upoważniacie nas do anulowania niewykorzystanego lotu w Waszym imieniu w przypadku nieobecności lub nie stawienia się podczas lotu oraz do zażądania zwrotu kosztów od linii lotniczych w Państwa imieniu. Jesteśmy uprawnieni, ale nie jesteśmy do tego zobowiązani, a Państwa prawo do żądania zwrotu pieniędzy bezpośrednio od linii lotniczych pozostaje nienaruszone.

### 3.7 Tanie linie lotnicze

3.7.1 Będzie to wyraźnie widoczne podczas procedury rezerwacji, jeśli dany lot jest obsługiwany przez tanie linie lotnicze. Poniższe postanowienia szczególne dotyczą lotów wykonywanych przez tanie linie lotnicze

3.7.2 Podczas pośredniczenia w usługach turystycznych świadczonych przez tanie linie lotnicze, możemy działać w Państwa imieniu w zawarciu umowy między Wami a tanią linią lotniczą.

3.7.3 Nie mamy dostępu do systemów rezerwacji tanich linii lotniczych. W związku z tym prosimy o bezpośredni kontakt z odpowiednią linią lotniczą w przypadku pytań dotyczących rezerwacji, zmian lub anulowania rezerwacji. Jeśli zakupili Państwo usługę Flexible Ticket (patrz rozdział 10), wszystkie prośby o zmianę rezerwacji muszą być składane za pośrednictwem naszego biura obsługi klienta, zgodnie z punktem 10.1.4.

3.7.4 Należy również pamiętać, że niektóre tanie linie lotnicze pobierają opłaty za odprawę ręczną na lotnisku. Aby uniknąć takich opłat, należy skorzystać z możliwości odprawy online za pośrednictwem strony internetowej odpowiedniej taniej linii lotniczej. Przed wyjazdem należy z wyprzedzeniem zasięgnąć informacji o możliwościach i warunkach odprawy linii lotniczych.

3.7.5 Potwierdzenia rezerwacji dla lotów niskokosztowych są wysyłane, gdy rezerwacja zostanie potwierdzona przez odpowiednią linię lotniczą. Można otrzymać dwa potwierdzenia rezerwacji - jeden od nas i jeden od odpowiedniej taniej linii lotniczej. Jeśli otrzymaliście dwa potwierdzenia rezerwacji, skorzystaj z potwierdzenia rezerwacji taniej linii lotniczej do odprawy.

#
# 4. Szczegółowe przepisy dotyczące pośrednictwa rezerwacji wielu usług

Portal oferuje możliwość łączenia i dopasowywania wielu pojedynczych usług według własnego uznania (np. lot + hotel). W takim przypadku Użytkownik upoważnia nas do pośredniczenia w rezerwacji różnych usług turystycznych od różnych dostawców. Odpowiedni dostawcy zostaną wyraźnie przedstawieni Użytkownikowi podczas procedury rezerwacji przed zakończeniem rezerwacji. W przypadku połączenia poszczególnych usług, nie zawierana jest żadna umowa podróży między Państwem a nami; zamiast tego zawieracie kilka umów dotyczących świadczenia poszczególnych usług z każym dostawcą oddzielnie. W tym przypadku działamy wyłącznie jako pośrednik w odniesieniu do poszczególnych usług turystycznych.

#
# 5. Informacje na temat paszportu, wizy i przepisów zdrowotnych

5.1 Wymagania paszportowe, wizowe i / lub zdrowotne mogą ulec zmianie, dlatego powinieneś skontaktować się z odpowiednim organem (ambasadą, konsulatem itp.) Na długo przed podróżą. Państwa obowiązkiem jest posiadanie ważnego paszportu i, w razie potrzeby, wizy. Ważne jest, aby pamiętać o uwzględnieniu wszystkich punktów tranzytowych podczas podróży, które mogą również wymagać uzyskania wizy. Uzyskanie wizy często zabiera trochę czasu, dlatego zaleca się wcześniejsze ubieganie się o nią. Nie ponosimy odpowiedzialności za klientów, którzy nie posiadają odpowiednich dokumentów.

5.2 Każde miejsce przeznaczenia ma własne wymagania dotyczące formalności przy wjeździe, szczepień itp., Które mogą się również różnić w zależności od narodowości pasażera. Państwa obowiązkiem jest zebranie tych informacji. W żadnym przypadku nie ponosimy odpowiedzialności za problemy wynikające z nieprzestrzegania tych oficjalnych przepisów. Dlatego nalegamy, aby zawsze sprawdzać różne formalności w wybranym kraju docelowym lub tranzytowym, a także czas potrzebny na podjęcie wszystkich związanych z tym czynności.

#
# 6.Opłaty i płatności

### 6.1 Płatność

6.1.1 Płatność za usługi turystyczne jest przetwarzana przez nas (we współpracy z naszymi dostawcami usług płatniczych, którzy dostarczają taką usługę) lub z Usługodawcą. Należy pamiętać, że aby móc zrealizować płatność, możemy potrzebować udostępnić informacje dotyczące płatności, takie jak dane karty kredytowej lub debetowej, naszym dostawcom usług płatniczych. Wszystkie informacje o płatnościach należące do naszych klientów są szyfrowane na bezpiecznym serwerze, gdy są udostępniane naszym dostawcom usług płatniczych.

6.1.2 Abyśmy mogli przetworzyć Państwa płatność, musicie zapewnić Państwo wystarczające fundusze. W przypadku jakichkolwiek problemów związanych z przetwarzaniem płatności, ponowimy przetwarzanie płatności we współpracy z naszymi dostawcami usług płatniczych. Jeśli nie dojdzie do wpłaty Państwa środków, skontaktujemy się z Wami tak szybko, jak to możliwe, w celu uzyskania instrukcji dotyczących innych sposobów płatności. Jeżeli nie otrzymamy płatności mimo podanych instrukcji, zaległa płatność zostanie przekazana firmie windykacyjnej.

### 6.2 Oszustwa dotyczące płatności

Jeśli istnieją powody, aby podejrzewać, że oszustwo zostało popełnione, Zastrzegamy sobie prawo do odmowy przetworzenia takiej płatności. Potwierdzenie płatności może być wymagana w przypadku podejrzenia popełnienia przestępstwa. Wszystkie rodzaje oszustw płatniczych będą zgłaszane policji i przekazywane do firmy windykacyjnej.

#
# 7. REKLAMACJE

### 7.1 Roszczenia związane z wykonywaniem usług turystycznych

Wszelkie problemy, uwagi lub roszczenia związane z faktycznym wykonywaniem usług turystycznych należy kierować bezpośrednio do odpowiedniego usługodawcy, z którym jest się związanym umową na dane usługi turystyczne. Proszę również zapoznać się z rozdziałem 9, aby uzyskać informacje o swoich prawach zgodnie z unijnymi przepisami.

### 7.2 Skargi dotyczące naszych własnych usług

Reklamacje dotyczące naszych usług są rozpatrywane wyłącznie na piśmie i należy je składać w ciągu dwóch miesięcy po dacie zakończenia podróży za pośrednictwem poczty elektronicznej.

Adres e-mail: [complaints@flystrait.com](mailto:complaints@flystrait.com)

#
# 8. Prawa klienta zgodnie z przepisami UE

### 8.1 Anulowane lub opóźnione loty

Jeśli podróżujescie do UE lub z UE lub korzystając z usług przewoźnika z UE, możecie mieć prawo do żądania zwrotu kosztów, które można zgłosić bezpośrednio odpowiedniej linii lotniczej, jeśli lot zostanie odwołany, opóźniony lub jeśli nastąpi odmowa przyjęcia na pokład. [Aby uzyskać więcej informacji na temat rozporządzenia WE 261/2004, proszę kliknąć tutaj.](http://eur-lex.europa.eu/legal-content/EN/ALL/?uri=CELEX%3A32004R0261)

### 8.2 Odpowiedzialność przewoźnika

Rozporządzenie WE (889/2002) w sprawie odpowiedzialności przewoźnika lotniczego w razie wypadku. [Rozporządzenie można znaleźć tutaj.](http://eur-lex.europa.eu/legal-content/EN/TXT/?uri=celex%3A32002R0889)

#
# 9. ODPOWIEDZIALNOŚĆ

9.1 Akceptujecie Państwo, że działamy jako pośrednik między Wami a Usługodawcą. W żadnym wypadku nie ponosimy odpowiedzialności za usługi turystyczne, które zarezerwowałeś u jednego lub więcej dostawców usług i nie ponosimy żadnej odpowiedzialności za jakiekolwiek nieścisłości w informacjach wyświetlanych na portalu, które zostały dostarczone przez odpowiedniego usługodawcę.

9.2 W przypadku, gdy Usługodawca nie może dostarczyć Usługi Podróżnej z jakiegokolwiek powodu, w tym również w przypadku bankructwa Usługodawcy, możemy działać jedynie jako pośrednik i zwrócić płatności, jeżeli już ją otrzymamy od właściwego Usługodawcy.

9.3 W odniesieniu do naszych własnych usług ponosimy odpowiedzialność za szkody z zastrzeżeniem ograniczeń określonych w niniejszych Warunkach oraz w zakresie dozwolonym przez prawo. Będziemy ponosić odpowiedzialność wyłącznie za bezpośrednie szkody, faktycznie poniesione, zapłacone przez państwo, w odniesieniu do naszych własnych usług, do łącznej kwoty kosztów rezerwacji (na jedno zdarzenie lub serię połączonych zdarzeń).

9.4 Ograniczenie odpowiedzialności, o którym mowa w punkcie 10.3, dotyczy również naruszenia obowiązków przez osoby, za które ponosimy odpowiedzialność zgodnie z przepisami ustawowymi.

#
# 10. OBOWIĄZUJĄCE PRAWO I ROZSTRZYGANIE SPORÓW

### 10.1 Obowiązujące prawo

Prawo estońskie, z wyłączeniem jednolitego prawa materialnego, w szczególności Prawa Sprzedaży Narodów Zjednoczonych w sprawie międzynarodowej sprzedaży towarów, ma zastosowanie wyłącznie do niniejszych warunków umowy między Państwem a nami.

Jako konsument będziecie Państwo korzystać z wszelkich obowiązkowych przepisów prawa kraju, w którym jesteście Państwo rezydentem. Żadne z postanowień niniejszych Warunków, w tym paragrafu 11.1, nie wpływa na Państwa prawa konsumenta, nie zwalnia z obowiązku podlegania przepisom lokalnego prawa.

### 10.2 Rozstrzyganie sporów online

Komisja Europejska zapewnia platformę internetowego rozstrzygania sporów (ODS) w ramach: (http://ec.europa.eu/consumers/odr/)

#
# 11. OCHRONA DANYCH OSOBOWYCH

Bardzo poważnie traktujemy ochronę Państwa danych osobowych. Szczegółowe informacje na temat gromadzenia, przetwarzania i wykorzystywania danych osobowych można znaleźć w zakładce [Polityka prywatności.](https://flystrait.com/privacy)
`;
