import React from "react";
import styled from "styled-components";
import { Button } from "components/common";

const Wrapper = styled.div`
  height: 460px;
  width: 296px;
  border: 1px solid #dddddd;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  margin-right: 24px;
`;

export default ({ text, className }) => (
  <Wrapper className={className}>
    <div style={{ width: 216 }}>
      <Button>{text}</Button>
    </div>
  </Wrapper>
);
