import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { removeTripData } from "redux-store/actions";
import HopRequest from "components/HopRequest";
import { encode, removeItem } from "utils/utils";
import getTranslation from "utils/getTranslation";

const Wrapper = styled.div`
  @media (min-width: 1000px) {
    max-width: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    margin: auto;
    .leftSection {
      width: 70%;
      margin-top: 50px;
    }
    .rightSection {
      position: relative;
      width: 30%;
      background-color: #e9e9e9;
    }
    .__hop-container {
      margin: auto;
      .inputsContainer {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
      }
    }
    .__payment-container {
      width: 320px;
      margin: auto;
      margin-top: 50px;
      .__payment-button {
        position: relative !important;
      }
    }
  }
`;

class TripBooking extends Component {
  constructor(props) {
    super(props);
    getTranslation("go");
    this.props.removeTripData();
    if (props.isNewTrip) {
      removeItem("latestSearch");
    }
    this.state = {
      hopRequestDone: false,
      hopRequestId: 0,
      loadingScreen: false,
      loadingMessage: "Connecting...",
      paymentLoading: false,
      costDetails: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.updateTime !== this.props.updateTime) {
      this.setState({
        languageChange: true,
      });
    }
    if (nextProps.isNewTrip && this.props.isNewTrip !== nextProps.isNewTrip) {
      removeItem("latestSearch");
      this.setState({
        loadingScreen: false,
      });
    }
  }

  onHopRequestDone = (data) => {
    const encodedData = encode(data);
    removeItem("mobile_number_obf");
    removeItem("tripowner_email");
    this.props.history.push(`/flights/${encodedData}`);
  };

  render() {
    return (
      <Wrapper>
        <HopRequest
          onDone={this.onHopRequestDone}
          canRedirect={this.props.match.path.indexOf("trip") === -1}
          isNewTrip={this.props.isNewTrip}
        />
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  updateTime: state.translations.updateTime,
});

const mapDispatchToProp = {
  removeTripData,
};

export default connect(mapStateToProps, mapDispatchToProp)(TripBooking);
