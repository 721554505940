import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { graphql, compose } from "react-apollo";
import { Calendar, momentLocalizer, Navigate } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import moment from "moment";
import {
  deleteConnectPost,
  insertConnectPost
} from "containers/Influencer/queries";
import CustomToolbar from "./CustomToolbar";
import CustomEvent from "./CustomEvent";
import CustomDayView from "./CustomDayView";
import ShareModal from "components/Influencer/Partials/ShareModal";
import { updateConnectPostRefetch } from "redux-store/actions";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";

const localizer = momentLocalizer(moment);

const DragAndDropCalendar = withDragAndDrop(Calendar);

const Wrapper = styled.div`
  margin-bottom: 100px;
  .rbc-month-view {
    border-top: 0;
  }
  .rbc-header {
    padding: 10px 0;
    position: relative;
    overflow: visible;
    &:before {
      content: "";
      position: absolute;
      left: -4px;
      top: -13px;
      overflow: visible;
      width: 7px;
      background-color: #fff;
      height: 50px;
      z-index: 1;
      @media (max-width: 800px) {
        width: 4px;
      }
    }
    &:after {
      content: "";
      position: absolute;
      right: -4px;
      top: -13px;
      overflow: visible;
      width: 7px;
      background-color: #fff;
      height: 50px;
      z-index: 1;
      @media (max-width: 800px) {
        width: 4px;
      }
    }
    span {
      font-weight: 400;
      font-family: "Roboto Condensed", sans-serif;
      text-transform: uppercase;
      color: #777777;
      @media (max-width: 800px) {
        font-size: 10px;
      }
    }
  }
  .rbc-row {
    /* margin-top: 10px; */
  }
  .rbc-month-header {
    margin-top: 0;
  }
  .rbc-date-cell {
    font-size: 16px;
    font-weight: 300;
    color: #777777;
  }
  .rbc-event {
    background-color: #f0f0f0;
    color: #444444;
    font-size: 12px;
    border: 0 !important;
    height: 20px !important;
    &.rbc-selected {
      background-color: #e6e6e6 !important;
    }
    &:focus {
      outline: none !important;
    }
    .rbc-event-label {
      display: none;
    }
  }
  .rbc-row-segment {
    position: relative;
  }
  .rbc-time-header-content {
    .rbc-allday-cell {
      display: none;
    }
    .rbc-header {
      border-bottom: 0 !important;
    }
  }
  .rbc-time-view {
    border-top: 0;
    border-left: 0;
    .rbc-time-content {
      border-top: 1px solid #ddd;
    }
    .rbc-timeslot-group {
      border: 0 !important;
      min-height: 60px !important;
    }
  }
  .rbc-time-slot {
    .rbc-label {
      color: #777777;
      font-size: 16px;
    }
  }
  .rbc-header.rbc-today {
    background-color: #fff !important;
  }
  .rbc-show-more {
    color: #007ac7;
  }
  .rbc-addons-dnd-resize-ew-anchor {
    display: none;
  }
`;

CustomDayView.navigate = (date, action) => {
  switch (action) {
    case Navigate.PREVIOUS:
      return moment(date)
        .subtract(1, "day")
        .toDate();

    case Navigate.NEXT:
      return moment(date)
        .add(1, "day")
        .toDate();

    default:
      return date;
  }
};

CustomDayView.title = date => {
  return `${date.toLocaleDateString()}`;
};

class CalendarView extends React.PureComponent {
  constructor(props) {
    super(props);
    const events = this.getEvents(props.connectPosts);
    this.state = { events, view: "month", modalOpen: false, connectPost: {} };
  }

  componentDidMount() {
    document.getElementById("customEventMoveLoader").style.display = "none";
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.connectPosts.length !== this.props.connectPosts) {
      const events = this.getEvents(nextProps.connectPosts);
      this.setState({
        events
      });
    }
  }

  getEvents = connectPosts => {
    return connectPosts.map(post => ({
      title: post.post.title,
      start: moment(post.shareschedule_at).toDate(),
      end: moment(post.shareschedule_at)
        .add(1, "minute")
        .toDate(),
      id: post.id
    }));
  };
  handleViewChange = view => {
    this.setState({ view });
  };
  handleEventClick = event => {
    const connectPost = this.props.connectPosts.find(
      item => item.id === event.id
    );
    this.setState({
      connectPost,
      modalOpen: true
    });
  };

  handleMoveEvent = ({ event, start, end }) => {
    const { events } = this.state;
    const idx = events.indexOf(event);
    const updatedEvent = { ...event, start, end };
    const nextEvents = [...events];
    nextEvents.splice(idx, 1, updatedEvent);
    this.setState({
      events: nextEvents
    });
    document.getElementById("customEventMoveLoader").style.display = "block";
    this.handleEventMoveUpdate(updatedEvent);
  };

  handleEventMoveUpdate = async updatedEvent => {
    const { influencerconnects } = this.props.influencerDetails;
    const connectPost = this.props.connectPosts.find(
      item => item.id === updatedEvent.id
    );
    for (var i = 0, j = influencerconnects.length; i < j; i++) {
      try {
        const payload = {
          sharenote: connectPost.sharenote,
          post_id: connectPost.post_id,
          influencerconnect_id: influencerconnects[i].id,
          shareschedule_at: moment(updatedEvent.start).format()
        };
        await this.props.insertConnectPost({
          variables: {
            objects: [payload]
          }
        });
        if (i === influencerconnects.length - 1) {
          this.handleRemove(connectPost);
        }
      } catch (err) {
        //this.setState({ sharing: false });
      }
    }
  };

  handleRemove = async connectPost => {
    await this.props.deleteConnectPost({
      variables: {
        where: {
          post_id: { _eq: connectPost.post_id },
          shareschedule_at: { _eq: connectPost.shareschedule_at }
        }
      }
    });
    document.getElementById("customEventMoveLoader").style.display = "none";
    this.props.updateConnectPostRefetch(moment().unix());
  };

  handleClose = () => {
    this.setState({ modalOpen: false });
  };
  render() {
    const { events, view, modalOpen, connectPost } = this.state;
    return (
      <Wrapper>
        {modalOpen && (
          <ShareModal
            open={modalOpen}
            blogData={connectPost.post}
            connectPost={connectPost}
            handleCloseModal={this.handleClose}
            handleSubmit={this.handleClose}
            isEdit
            deleteItem
          />
        )}
        <DragAndDropCalendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: view === "month" ? 580 : "auto" }}
          components={{
            toolbar: CustomToolbar,
            event: CustomEvent
          }}
          views={{ month: true, week: true, day: CustomDayView }}
          onView={this.handleViewChange}
          onSelectEvent={this.handleEventClick}
          onEventDrop={this.handleMoveEvent}
          resizable={false}
          selectable="ignoreEvents"
        />
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  connectPosts: state.influencer.connectPosts,
  influencerDetails: state.influencer.influencerDetails
});

export default compose(
  graphql(deleteConnectPost, { name: "deleteConnectPost" }),
  graphql(insertConnectPost, { name: "insertConnectPost" }),
  connect(mapStateToProps, { updateConnectPostRefetch })
)(CalendarView);
