import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";
import { graphql, compose, withApollo } from "react-apollo";
import { updTripowner } from "containers/TripBooking/queries";
import { xcountryLocalesAndCurrencies } from "components/common/commonQueries";
import { xcurrencyPerUsd } from "containers/DomainPayment/queries";
import { updateUserDate } from "redux-store/actions";
import { authenticate, getAccessToken, validateEmail } from "utils/api";
import { Input, Button, Toast } from "components/common";
import { setItem, getItem, removeItem, getParameterByName } from "utils/utils";
import getTranslation from "utils/getTranslation";

const Wrapper = styled.div`
  width: 327px;
  margin: auto;
  .forgotPass {
    text-align: center;
    margin-top: 20px;
    a {
      color: #2d9cdb;
      font-family: "Roboto";
    }
  }
`;

class Login extends React.PureComponent {
  constructor(props) {
    super(props);
    removeItem("tripId");
    const activated = getParameterByName("activated");
    const reset = getParameterByName("reset");
    this.state = {
      username: props.email,
      password: "",
      loading: false,
      error: activated == "true" || reset == "true",
      errorMsg: activated
        ? "Account Activated."
        : reset
          ? "Password Reset Successful."
          : "",
    };
    this.passwordInput = null;
  }

  handleChange = (e) => {
    console.log("handleChange", e.target.name);
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleTripOwnerUpdate = () => {
    this.props
      .updTripowner({
        variables: {
          tripId: getItem("tripId"),
        },
      })
      .then((data) => {
        this.handleRedirect();
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
        });
      });
  };
  handleRedirect = async () => {
    let currencyDetails = {};
    const selectedCurrency = getItem("selectedCurrency") || "GBP";
    const response = await this.props.client.query({
      query: xcurrencyPerUsd,
      variables: {
        currencyIso: selectedCurrency,
      },
    });
    currencyDetails.xcurrencyPerUsd = response.data.xcurrencyPerUsd;
    const countries = await this.props.client.query({
      query: xcountryLocalesAndCurrencies,
      variables: {
        fsSupport: true,
        countryIc: getItem("selectedCountry") || "XX",
      },
    });
    const currency = countries.data.xcountryLocalesAndCurrencies[0].currencies.find(
      (item) => item.currencyIso === selectedCurrency
    );
    currencyDetails = {
      ...currencyDetails,
      ...currency,
    };
    setItem("currencyDetails", currencyDetails);
    if (getItem("redirectTo")) {
      this.props.history.replace(getItem("redirectTo"));
      removeItem("redirectTo");
    } else {
      if (this.props.location.state) {
        this.props.history.replace(this.props.location.state.from.pathname);
      } else {
        this.props.history.replace("/my-trips");
      }
    }
  };
  handleLogin = async () => {
    const payload = {
      login: this.state.username,
      password: this.state.password,
    };
    this.setState({ loading: true });
    try {
      const auth = await authenticate(payload);
      if (auth.code) {
        const tokenDetails = await getAccessToken(auth.code);
        tokenDetails.expiryTime = moment()
          .add(tokenDetails.expires_in, "seconds")
          .unix();
        this.props.updateUserDate(tokenDetails);
        setItem("tokenDetails", tokenDetails);
        setItem("loggedInUsername", this.state.username);
        removeItem("guestLogin");
        const validate = await validateEmail(
          getItem("selectedCountry" || "XX"), this.state.username
        );
        if (validate) {
          setItem("useraccount_id", validate.useraccount_id);
        }
        if (getItem("tripId")) {
          this.handleTripOwnerUpdate();
        } else {
          this.handleRedirect();
        }
      } else {
        this.setState({
          loading: false,
          error: true,
          errorMsg: getTranslation("loginError"),
        });
      }
    } catch (err) {
      console.log(err);
      this.setState({
        loading: false,
        error: true,
        errorMsg: getTranslation("genericErrorMsg"),
      });
    }
  };

  handleClose = () => {
    this.setState({ error: false });
  };

  handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      if (this.props.id) {
        document.getElementById(this.props.id).blur();
      }
      e.preventDefault();
      this.handleLogin();
    }
  };

  render() {
    const { username, password, loading, error, errorMsg } = this.state;
    return (
      <Wrapper>
        <Input
          name="username"
          label={getTranslation("username")}
          value={username}
          onChange={this.handleChange}
          autoFocus={true}
        />
        <Input
          type="password"
          name="password"
          label={getTranslation("password")}
          value={password}
          onChange={this.handleChange}
          autoFocus={this.props.email.length > 0}
          onKeyDown={this.handleKeyDown}
        />
        <div style={{ marginTop: 25 }}>
          <Button
            onClick={this.handleLogin}
            disabled={loading || !username || !password}
          >
            {getTranslation("login")}
          </Button>
        </div>
        <div className="forgotPass">
          <Link to="/forgot">{getTranslation("forgotpassword")}</Link>
        </div>
        <Toast open={error} handleClose={this.handleClose} message={errorMsg} />
      </Wrapper>
    );
  }
}

export default withApollo(
  withRouter(
    compose(
      graphql(updTripowner, { name: "updTripowner" }),
      connect(null, { updateUserDate })
    )(Login)
  )
);
