import React from "react";
import styled from "styled-components";
import getTranslation from "utils/getTranslation";
import { AirlineImg, AirlineName } from "components/common";
import PriceRange from "components/common/PriceRange";

const Wrapper = styled.div`
  margin-top: 10px;
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    .name {
      color: #555555;
      font-size: 12px;
      font-weight: 600;
      display: flex;
      flex-flow: column;
      .link {
        color: #0081cb;
        cursor: pointer;
        text-decoration: underline;
        font-weight: 400;
      }
    }
    .fareRule {
      font-size: 12px;
      display: flex;
      flex-flow: column;
      align-items: flex-end;
      .flightNum {
        font-weight: 600;
      }
    }
  }
  .dashed {
    border-bottom: 1px dashed #dddddd;
    margin-top: 10px;
  }
`;

export default ({ flightInfo, hopRequest, handleOpenModal }) => (
  <Wrapper>
    <div className="container">
      <div className="name">
        <AirlineName airlineIc={flightInfo.airlineIc} />
        <span className="link" onClick={handleOpenModal}>
          {getTranslation("fareRule")}
        </span>
      </div>
      <AirlineImg
        airlineIc={flightInfo.airlineIc}
        style={{ height: 30 }}
        // handleFilterModal={handleFilterModal}
      />
      <div className="fareRule">
        <span className="flightNum">
          {flightInfo.airlineIc} {flightInfo.flightNum}
        </span>
        <PriceRange hopRequest={hopRequest} />
      </div>
    </div>
    <div className="dashed" />
  </Wrapper>
);
