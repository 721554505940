import gql from 'graphql-tag';

export const xairportByIataLike = gql`query xairportByIataLike(
    $iata: String!,
  ) {
    xairportByIataLike(
      iata: $iata,
    ) {
      airportIc
      airportDesc
      city
      country
    }
  }
`;

export const xairlineByIc = gql`query xairlineByIc(
    $airlineIc: String!,
  ) {
    xairlineByIc(
      airlineIc: $airlineIc,
    ) {
      airlineDesc
    }
  }
`;
