import React, { Component } from "react";
import { graphql, compose } from "react-apollo";
import styled, { withTheme } from "styled-components";
import { connect } from "react-redux";
import gql from "graphql-tag";
import moment from "moment";
import { isMobile } from "react-device-detect";
import {
  updTripowner,
  xairportNearestByGeo,
} from "containers/TripBooking/queries";
import { removeTripData } from "redux-store/actions";
import {
  Button,
  CustomDate,
  GeoLocation,
  Autocomplete,
} from "components/common";
import {
  AddIcon,
  FlightFrom,
  FlightFromError,
  FlightTo,
  FlightToError,
} from "components/common/SvgIcons";
import { getItem } from "utils/utils";
import getTranslation from "utils/getTranslation";
import IconDelete from "assets/icons/Icon-delete.svg";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .inputsContainer {
    position: relative;
    @media (max-width: 800px) {
      width: 100%;
    }
  }
  .inputWrapper {
    width: 296px;
    margin-right: 25px;
    @media (max-width: 800px) {
      width: 100%;
      margin-right: 0;
    }
  }
  .addMore {
    width: 96px;
    position: absolute;
    right: -105px;
    top: 22px;
    display: flex;
    @media (max-width: 800px) {
      margin: 20px auto;
      position: static;
    }
  }
`;

const addTripQuery = gql`
  mutation addTrip(
    $locale: String!
    $countryIc: String!
    $currencyIso: String!
  ) {
    addTrip(locale: $locale, countryIc: $countryIc, currencyIso: $currencyIso) {
      id
    }
  }
`;
const defaultFromDate = moment()
  .day(6 + 14)
  .toDate();
const defaultToDate = moment(defaultFromDate).add(8, "days").toDate();
let airportsListTimeout;
class HopRequestFields extends Component {
  constructor(props) {
    super(props);
    const lastState = props.lastState || {};
    lastState.date = lastState.date
      ? moment(lastState.date).toDate()
      : props.tripIndex === 0
        ? defaultFromDate
        : null;
    lastState.returnDate = lastState.returnDate
      ? moment(lastState.returnDate).toDate()
      : lastState.returnDate !== null && props.tripIndex === 0
        ? defaultToDate
        : null;
    this.state = {
      id: props.id,
      to: lastState.to || "",
      from: lastState.from || props.prevToAirport || "",
      date: lastState.date || null,
      returnDate: props.tripIndex === 0 ? lastState.returnDate || null : null,
      transit: 0,
      searchText: lastState.searchText || "",
      searchTextFrom:
        lastState.searchTextFrom ||
        (props.prevToAirport
          ? `(${props.prevToAirport.airportIc}) ${props.prevToAirport.city}`
          : ""),
      openDate: false,
      airportsList: [],
    };
    this.currentDate = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    if (
      (nextProps.isNewTrip && this.props.isNewTrip !== nextProps.isNewTrip) ||
      (nextProps.doReset && nextProps.doReset !== this.props.doReset)
    ) {
      this.setState({
        to: "",
        from: "",
        date: defaultFromDate,
        returnDate: defaultToDate,
        searchText: "",
        searchTextFrom: "",
        doReset: true,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state !== prevState) {
      this.props.handleTripUpdate(this.state, this.props.id);
    }
    const { from, to, date, loading } = this.props;
    if (this.props.loading != prevProps.loading || this.props.from != prevProps.from || this.props.to != prevProps.to || this.props.date != prevProps.date) {
      this.props.handleFormStatus(loading || !from || !to || !date);
    }
  }

  componentWillUnmout() {
    airportsListTimeout = null;
  }

  onAirportDest = (index, dataSource) => {
    // if (index === -1) return;
    const airport = dataSource[index];
    if (airport) {
      this.setState({ airportsList: [], to: { ...airport } }, () => {
        this.handleDateOpen();
      });
    }
  };

  onAirportFrom = (index, dataSource) => {
    // if (index === -1) return;
    const airport = dataSource[index];
    let isSame = false;
    if (this.state.to && airport.airportIc === this.state.to.airportIc) {
      isSame = true;
    }
    if (airport) {
      this.setState(
        {
          airportsList: [],
          from: { ...airport },
          showFrom: false,
          to: isSame ? "" : this.state.to,
          doReset: false,
        },
        () => {
          if (!this.state.to && !isMobile) {
            // this._toInput.focus();
            document.getElementById(`to-field${this.props.id}`).focus();
          }
        }
      );
    }
  };

  getTrimmedText = (e) => {
    let fullText = `(${e.airportIc}) ${e.city}, ${e.airportDesc}`;
    if (fullText.length > 30) {
      fullText = `(${e.airportIc}) ${e.city}`;
    }
    return fullText;
  };

  handleDateOpen = () => {
    if (this.state.from && this.state.to) {
      this.setState({
        openDate: true,
      });
    }
  };

  handleDates = (dates) => {
    const startDate = moment(dates.startDate).unix();
    const endDate = moment(dates.endDate).unix();
    this.setState(
      {
        date: dates.startDate,
        returnDate: startDate !== endDate ? dates.endDate : null,
        resetDates: false,
      },
      () => {
        console.log(this.state.returnDate);
      }
    );
  };

  moveFocusToDates = () => {
    // let focused = false;
    // Array(6).fill(0).map((ele,i) => {
    //   if(document.getElementById(`date${6-i}`) && !focused) {
    //     document.getElementById(`date${6-i}`).focus();
    //     focused = true;
    //   }

    // })
  };

  handleGeoSuccess = (coords, client) => {
    if (coords.latitude == 0 && coords.longitude == 0) {
      document.getElementById(`from-field${this.props.id}`).focus();
      return;
    }
    client
      .query({
        query: xairportNearestByGeo,
        variables: {
          latitude: coords.latitude.toString(),
          longitude: coords.longitude.toString(),
        },
      })
      .then((airport) => {
        if (airport.data && !this.state.from) {
          this.setState(
            {
              from: { ...airport.data.xairportNearestByGeo },
              searchTextFrom: this.getTrimmedText(
                airport.data.xairportNearestByGeo
              ),
            },
            () => {
              // this._toInput.scrollIntoView();
              if (!isMobile) {
                document.getElementById(`to-field${this.props.id}`).focus();
              }
              // this._toInput.focus();
              // this._toInput.blur();
              // this._toInput.focus();
            }
          );
        }
      });
  };

  handleGeoSuccess = (coords, client) => {
    client
      .query({
        query: xairportNearestByGeo,
        variables: {
          latitude: coords.latitude.toString(),
          longitude: coords.longitude.toString(),
        },
      })
      .then((airport) => {
        if (airport.data && !this.state.from) {
          this.setState(
            {
              from: { ...airport.data.xairportNearestByGeo },
              searchTextFrom: this.getTrimmedText(
                airport.data.xairportNearestByGeo
              ),
            },
            () => {
              // this._toInput.scrollIntoView();
              if (!isMobile) {
                document.getElementById(`to-field${this.props.id}`).focus();
              }
              // this._toInput.focus();
              // this._toInput.blur();
              // this._toInput.focus();
            }
          );
        }
      })
      .catch((err) => {
        console.log("refetchFlights error", err);
      });
  };

  handleToClear = () => {
    this.setState({
      searchText: "",
      to: null,
    });
    document.getElementById(`to-field${this.props.id}`).focus();
    // this._toInput.focus();
  };

  handleFromClear = () => {
    this.setState({
      searchTextFrom: "",
      from: null,
    });
    document.getElementById(`to-field${this.props.id}`).focus();
    // this._fromInput.focus();
  };

  query = gql`
    query getAirports {
      xairportByPattern(pattern: "New%") {
        airportIc
        airportDesc
        city
        country
      }
    }
  `;

  handleFromNewRequest = (i, dataSource) => {
    this.onAirportFrom(i, dataSource || []);
  };

  handleToNewRequest = (i, dataSource) => {
    this.onAirportDest(i, dataSource || []);
  };

  handleFromInputChange = (txt) => {
    this.setState({ searchTextFrom: txt, from: null }, () => {
      this.handleAirportFilter(txt.toLowerCase(), this.state.to, true);
    });
  };

  handleToInputChange = (txt) => {
    this.setState({ searchText: txt, to: null }, () => {
      this.handleAirportFilter(txt.toLowerCase(), this.state.from);
    });
  };

  handleFromBlurCheck = (text, dataSource) => {
    const txt = text.toLowerCase();
    const airportIndex = dataSource.findIndex(
      (item) =>
        item.airportIc.toLowerCase() === txt || item.city.toLowerCase() === txt
    );
    if (airportIndex > -1) {
      this.onAirportFrom(airportIndex, dataSource);
      const { airportIc, city } = dataSource[airportIndex];
      this.setState({
        searchTextFrom: `(${airportIc}) ${city}`,
        airportsList: [],
        fromBlured: true,
      });
    } else {
      this.setState({ airportsList: [], fromBlured: true });
    }
  };

  handleToBlurCheck = () => {
    const dataSource = this.state.airportsList;
    const txt = this.state.searchText.toLowerCase();
    const airportIndex = dataSource.findIndex(
      (item) =>
        item.airportIc.toLowerCase() === txt || item.city.toLowerCase() === txt
    );
    if (airportIndex > -1) {
      this.onAirportDest(airportIndex, dataSource);
      const { airportIc, city } = dataSource[airportIndex];
      this.setState({
        searchText: `(${airportIc}) ${city}`,
        airportsList: [],
        toBlured: true,
      });
    } else {
      this.setState({
        airportsList: [],
        toBlured: true,
      });
    }
  };

  handleAirportFilter = (txt, airport, isFromInput) => {
    if (txt.length < 3) {
      this.setState({
        airportsList: [],
      });
      return;
    }
    clearTimeout(airportsListTimeout);
    airportsListTimeout = setTimeout(() => {
      const allAirports = getItem("allAirports");
      let matchesArray = [];
      let filtered = allAirports.filter((item) =>
        item.metareaIc.toLowerCase().startsWith(txt)
      );
      matchesArray = [...filtered];
      filtered = allAirports.filter((item) =>
        item.airportIc.toLowerCase().startsWith(txt)
      );
      matchesArray = [...matchesArray, ...filtered];
      filtered = allAirports.filter((item) =>
        item.city.toLowerCase().startsWith(txt)
      );
      matchesArray = [...matchesArray, ...filtered];
      filtered = allAirports.filter((item) =>
        item.airportDesc.toLowerCase().startsWith(txt)
      );
      matchesArray = [...matchesArray, ...filtered];
      let distinct = [];
      matchesArray.slice(0, 30).forEach((item) => {
        const exists = distinct.find(
          (data) => data.airportIc === item.airportIc
        );
        if (!exists) {
          distinct = [...distinct, item];
        }
      });
      if (airport && !isFromInput) {
        distinct = distinct.filter(
          (item) => item.airportIc !== airport.airportIc
        );
      }
      const metareaItem = distinct.find(
        (item) => item.metareaIc.toLowerCase() === txt
      );
      if (metareaItem) {
        distinct = [
          {
            ...metareaItem,
            airportIc: metareaItem.metareaIc,
            airportDesc: "All Airports",
          },
          ...distinct,
        ];
        const metaAreaIndex = distinct.findIndex(
          (item) =>
            item.metareaIc === item.airportIc &&
            item.airportDesc !== "All Airports"
        );
        if (metaAreaIndex !== -1)
          distinct[metaAreaIndex].airportDesc =
            distinct[metaAreaIndex].airportDesc + " (All Airports)";
      }
      if (distinct.length > 0) {
        this.setState({
          airportsList: distinct,
        });
      } else {
        const selected = this.state.airportsList.filter((item) =>
          txt.toLowerCase().includes(item.airportIc.toLowerCase())
        );
        this.setState({
          airportsList: selected,
        });
      }
    }, 300);
  };

  handleFromAirportClose = () => {
    this.setState({ airportsList: [] });
  };

  handleToAirportClose = () => {
    this.setState({ airportsList: [] });
  };

  handleAddNewTrip = () => {
    this.props.handleAddNewTrip(this.props.tripIndex + 1, this.props.isLast);
  };

  handleRemoveTrip = () => {
    this.props.handleRemoveTrip(this.props.id);
  };

  render() {
    const {
      from,
      to,
      openDate,
      resetDates,
      searchText,
      searchTextFrom,
      date,
      returnDate,
      airportsList,
      doReset,
      toBlured,
      fromBlured,
    } = this.state;
    const {
      flightsPage,
      loading,
      client,
      handleLocale,
      id,
      isLast,
      hideAddExtraTrip,
      fromAirport,
      prevHopReqDate,
      tripIndex,
      getMinDate,
      handleSearchFlight,
      getMaxDate,
      theme,
      tripsLength,
    } = this.props;
    let prevDates = [];
    if (this.state.date) {
      prevDates = [...prevDates, this.state.date];
    }
    if (this.state.returnDate) {
      prevDates = [...prevDates, this.state.returnDate];
    }

    const fromError = fromBlured && !from;
    const toError = toBlured && !to;
    return (
      <Wrapper>
        {!flightsPage && id === 1 && (
          <GeoLocation
            handleSuccess={this.handleGeoSuccess}
            handleLocale={handleLocale}
            client={client}
            sendCoords={from === ""}
            doReset={doReset}
            moveFocusToDates={this.moveFocusToDates}
          />
        )}
        <div className="inputsContainer">
          <div className="inputWrapper">
            <Autocomplete
              id={`from-field${id}`}
              refName={(c) => {
                this._fromInput = c;
              }}
              label={getTranslation("from")}
              value={from}
              type="airport"
              maxSearchResults={30}
              dataSource={airportsList}
              searchText={searchTextFrom}
              onChange={this.handleFromInputChange}
              handleSelect={this.handleFromNewRequest}
              handleClose={this.handleFromAirportClose}
              onBlur={this.handleFromBlurCheck}
              handleClear={this.handleFromClear}
              icon={
                fromError ? (
                  <FlightFromError />
                ) : (
                    <FlightFrom error={fromError} />
                  )
              }
              error={fromError}
            />
          </div>
          <div className="inputWrapper">
            <Autocomplete
              id={`to-field${id}`}
              refName={(c) => {
                this._toInput = c;
              }}
              label={getTranslation("to")}
              value={to}
              type="airport"
              dataSource={airportsList}
              searchText={searchText}
              handleSelect={this.handleToNewRequest}
              handleClose={this.handleToAirportClose}
              onChange={this.handleToInputChange}
              handleClear={this.handleToClear}
              onBlur={this.handleToBlurCheck}
              icon={toError ? <FlightToError /> : <FlightTo />}
              error={toError}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: isMobile ? "100%" : "296px",
            }}
          >
            <CustomDate
              id={`date${id}`}
              placeholder={getTranslation("dateSelection")}
              departureLabel={getTranslation("depart")}
              cancelLabel={getTranslation("cancel")}
              okLabel={getTranslation("ok")}
              oneWayLabel={getTranslation("oneWay")}
              returnLabel={getTranslation("return")}
              openDate={openDate}
              handleDates={this.handleDates}
              prevDate={prevDates}
              resetDates={resetDates}
              fromAirport={fromAirport}
              date={date}
              returnDate={returnDate}
              onlyOneWay={tripIndex > 0 || !isLast}
              tripIndex={tripIndex}
              prevHopReqDate={prevHopReqDate}
              getMinDate={getMinDate}
              getMaxDate={getMaxDate}
              handleSearchFlight={handleSearchFlight}
              ref={this.currentDate}
            />
          </div>
          {!hideAddExtraTrip && (
            <div className="addMore">
              {tripsLength > 1 && (
                <div style={{ width: 44, height: 44, marginRight: 8 }}>
                  <Button
                    style={{
                      height: 42,
                      width: 42,
                      minWidth: 42,
                    }}
                    color="#F0F0F0"
                    disabled={loading}
                    onClick={this.handleRemoveTrip}
                  >
                    <img src={IconDelete} alt="" />
                  </Button>
                </div>
              )}
              <div style={{ width: 44, height: 44 }}>
                <Button
                  style={{
                    height: 44,
                    width: 44,
                    minWidth: 44,
                  }}
                  roundButton
                  color="#F2CC56"
                  disabled={loading || !from || !to || !date}
                  onClick={this.handleAddNewTrip}
                >
                  <AddIcon style={{ height: 24, width: 24 }} />
                </Button>
              </div>
            </div>
          )}
        </div>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  hopRequests: state.flightResults.hopRequests,
});

const mapDispatchToProp = {
  removeTripData,
};

export default withTheme(
  compose(
    graphql(addTripQuery, {
      name: "addTripQuery",
    }),
    graphql(updTripowner, { name: "updTripowner" }),
    connect(mapStateToProps, mapDispatchToProp)
  )(HopRequestFields)
);
