import gql from "graphql-tag";

export const xcountryLocalesAndCurrencies = gql`
  query xcountryLocalesAndCurrencies($fsSupport: Boolean!, $countryIc: String) {
    xcountryLocalesAndCurrencies(fsSupport: $fsSupport, countryIc: $countryIc) {
      countryIc
      country
      locales {
        locale
        language
        redirectUrl
        countrylocaleRank
        fsSupport
      }
      currencies {
        currencyIso
        currency
        currencyDisplay
        countrycurrencyRank
        minorUnit
        fsSupport
      }
    }
  }
`;

export const updTripCountry = gql`
  mutation updTripCountry($countryIc: String!, $tripId: Int!) {
    updTripCountry(countryIc: $countryIc, tripId: $tripId) {
      id
    }
  }
`;

export const updTripLocale = gql`
  mutation updTripLocale($locale: String!, $tripId: Int!) {
    updTripLocale(locale: $locale, tripId: $tripId) {
      id
    }
  }
`;

export const updTripCurrency = gql`
  mutation updTripCurrency($currencyIso: String!, $tripId: Int!) {
    updTripCurrency(currencyIso: $currencyIso, tripId: $tripId) {
      id
    }
  }
`;
