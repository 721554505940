import React from 'react';
import { Query } from 'react-apollo';
import MenuItem from '@material-ui/core/MenuItem';
import { Select } from 'components/common';
import { getItem } from 'utils/utils';
import { xmealByTripId } from '../PaymentQuery';
import getTranslation from 'utils/getTranslation';

export default ({
  handleChange,
  value,
}) => (
  <Query query={xmealByTripId} variables={{ tripId: getItem('tripId') }}>
    {({ data }) => (
      <Select
        label={`${getTranslation('selectMeal')} (${getTranslation('optional')})`}
        value={value}
        handleChange={handleChange}
        menuItems={data.xmealByTripId && data.xmealByTripId.map(meal => (
          <MenuItem value={meal.mealCode} key={meal.mealCode}>{meal.mealName}</MenuItem>
        ))}
      />
    )}
  </Query>
);
