import React, { useEffect } from "react";
import styled from "styled-components";
import { BlogCardImages } from "components/Influencer/BlogCards";

const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  .card {
    margin-right: 20px;
    @media (max-width: 800px) {
      :nth-child(2n) {
        margin-right: 0;
      }
    }
  }
`;

export default ({ blogData, selectedLayout, handleLayoutChange }) => {
  useEffect(() => {
    if (!blogData.coverimage2) {
      handleLayoutChange("1", true);
    }
  }, []);
  return blogData
    ? (blogData.type === "BLOG" ||
        blogData.type === "QUICK" ||
        blogData.type === "ITINERARY") && (
        <Cards>
          {!blogData.coverimage2 && (
            <div className="card">
              <BlogCardImages
                preview
                blogData={blogData}
                layout="1"
                selectedLayout={selectedLayout}
                handleLayoutChange={handleLayoutChange}
              />
            </div>
          )}
          {blogData.coverimage2 && !blogData.coverimage3 && (
            <>
              <div className="card">
                <BlogCardImages
                  preview
                  blogData={blogData}
                  layout="="
                  selectedLayout={selectedLayout}
                  handleLayoutChange={handleLayoutChange}
                />
              </div>
              <div className="card">
                <BlogCardImages
                  preview
                  blogData={blogData}
                  layout="||"
                  selectedLayout={selectedLayout}
                  handleLayoutChange={handleLayoutChange}
                />
              </div>
            </>
          )}
          {blogData.coverimage3 && !blogData.coverimage4 && (
            <>
              <div className="card">
                <BlogCardImages
                  preview
                  blogData={blogData}
                  layout="L"
                  selectedLayout={selectedLayout}
                  handleLayoutChange={handleLayoutChange}
                />
              </div>
              <div className="card">
                <BlogCardImages
                  preview
                  blogData={blogData}
                  layout="7"
                  selectedLayout={selectedLayout}
                  handleLayoutChange={handleLayoutChange}
                />
              </div>
            </>
          )}
          {blogData.coverimage4 && (
            <div className="card">
              <BlogCardImages
                preview
                blogData={blogData}
                layout="+"
                selectedLayout={selectedLayout}
                handleLayoutChange={handleLayoutChange}
              />
            </div>
          )}
        </Cards>
      )
    : null;
};
