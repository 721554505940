import React from "react";
import styled from "styled-components";
import Login from "./Login";
import Signup from "./Signup";
import getTranslation from "utils/getTranslation";
import { Toast } from "components/common";

const Wrapper = styled.div`
  background-color: #fff;
  padding-bottom: 50px;
  min-height: -webkit-fill-available;
  .head {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 20px 0;
    .item {
      font-family: "Roboto Condensed", sans-serif;
      color: ${(props) => props.theme.secondary};
      font-size: 16px;
      cursor: pointer;
      &.active {
        color: #444444;
      }
    }
    .pipe {
      height: 22px;
      background-color: #777777;
      margin: 0 20px;
      width: 1px;
    }
  }
`;

export default class extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeView: props.match.params.register ? "signup" : "login",
      errorMsg: "",
      email: "",
    };
  }
  handleLoginView = (errorMsg, email) => {
    this.setState({
      activeView: "login",
      errorMsg: errorMsg || "",
      email: email || "",
    });
  };
  handleSignupView = () => {
    this.setState({
      activeView: "signup",
    });
  };

  handleRequestClose = () => {
    this.setState({
      errorMsg: "",
    });
  };

  render() {
    const { activeView, errorMsg, email } = this.state;
    return (
      <Wrapper>
        <div className="head">
          <div
            className={`item ${activeView === "login" ? "active" : ""}`}
            onClick={this.handleLoginView}
          >
            {getTranslation("login")}
          </div>
          <div className="pipe" />
          <div
            className={`item ${activeView === "signup" ? "active" : ""}`}
            onClick={this.handleSignupView}
          >
            {getTranslation("signup")}
          </div>
        </div>
        <div>
          {activeView === "login" ? (
            <Login email={email} />
          ) : (
            <Signup
              alreadyRegistered={(errorMsg, email) =>
                this.handleLoginView(errorMsg, email)
              }
            />
          )}
        </div>
        <Toast
          open={errorMsg.length > 0}
          message={errorMsg}
          handleClose={this.handleRequestClose}
        />
      </Wrapper>
    );
  }
}
