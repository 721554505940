import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  background: linear-gradient(269.88deg, rgba(204, 204, 204, 0) 0%, #CCCCCC 50%, rgba(204, 204, 204, 0) 100%);
  height: 1px;
  width: 100%;
`;

export default ({
  style = {}
}) => (
  <Wrapper style={style} />
)
