export default `
These general terms and conditions (“T&Cs”) apply between you (“You” or the “Customer”) and Fly Strait OÜ (“We” or “Us”), the operator of the booking portal flystrait.com (hereinafter referred to as the “Portal”). It is requested that You read these T&Cs carefully prior to using the Portal. By using the Portal, You agree to these T&Cs. If You do not accept these T&Cs, We ask that You refrain from using the Portal and exit the website.

&nbsp;

You must be at least 18 years of age and act in your role as a private consumer to make a booking on the Portal. A private consumer is, as opposed to a business, a natural person who enters into a legal transaction for purposes which are predominantly outside its commercial and/or self-employed activities.

The operator of the Portal and your contractual partner for using the Portal is:

&nbsp;

Fly Strait OÜ (Fly Strait)

E-mail: support@flystrait.com

&nbsp;

The T&Cs are set out as follows:
# 1. Scope of Services
### 1.1 Mediation of Travel Services
1.1.1 For the flight booking services offered on the Portal (referred to as “Travel Services”), We exclusively act within our capacity as an intermediary. To that end, our role and obligations are limited to mediating Travel Services that will be rendered by third parties such as airlines (hereinafter in each case “Service Provider”).

&nbsp;  

1.1.2 Consequently, the agreement for the actual provision of Travel Services comes into effect directly between You and the relevant Service Provider. We are not a co-vendor of the Travel Services and We are not a party to the contractual relationship between You and the Service Provider.

&nbsp;  

1.1.3 By using the Portal to purchase Travel Services, You authorize Us to mediate with the corresponding Service Provider(s) on your behalf, including mediation of the payment for these Travel Services, in order to take care that the transaction between You and the Service Provider(s) is carried out. The price issued for the actual Travel Services may include a commission for the services we render for mediating the agreement between you and the relevant Service Provider.

&nbsp;  

1.1.4 Responsibility for the actual performance of the Travel Services mediated through the Portal is exclusively held by the relevant Service Provider. In our role as an intermediary, We do not assume any responsibility for the Travel Services to be rendered by the Service Providers and We make no representations or warranties (neither expressed nor implied) regarding the suitability or quality of Travel Services mediated on the Portal. For any claim You may have in context with the performance or non-performance of the Travel Service, the Service Provider is the responsible addressee.

&nbsp;  

1.1.5 Should You have the opportunity to request special wishes (such as special meals, disabled facilities or child seats) when booking Travel Services, We will pass on your request to the relevant Service Provider. However, We cannot take any responsibility on whether the Service Provider can actually fulfil such wishes.

&nbsp;  

### 1.2 Applicable contractual conditions
1.2.1 These T&Cs apply to the mediation of Travel Services according to Section 1.1.

&nbsp;  

1.2.2 For the agreement between You and the relevant Service Provider (Section 1.1.2), the general terms and conditions of the relevant Service Provider (such as conditions of carriage, transfer conditions, insurance conditions or the like), as issued by the Service Provider apply. Such general terms and conditions of the relevant Service Provider will be notified to you over the course of the booking process. As the Service Provider’s terms and conditions may include provisions relating to liability, cancellation, changes of bookings and refunds (if available) and other restrictions, You are advised to read those terms carefully.

# 2. Booking process and contact information
2.1 During the booking process, You are provided with the technical means needed to detect errors in entry forms and to correct them prior to submitting your booking request. You are requested to check all data for accuracy before concluding your booking request. Subsequent change requests may lead to significant additional costs.

&nbsp;

2.2 We might need to contact You, e.g. in case of subsequent changes to the purchased Travel Services. You must submit accurate contact information such as your telephone number and email address. You must also continuously check if You have received a message from Us.

&nbsp;

It is also your responsibility to ensure that You are able to receive our messages. We are not responsible if You do not receive a message from Us due to circumstances reasonably outside of our control including, but not limited to the following: (i) You gave us an incorrect email address; (ii) your email settings won’t allow our email to reach You; or (iii) your email settings treat our email as spam.

# 3. Mediation of flights

### 3.1 Conclusion of contract and price changes
3.1.1 After You submitted your booking request, We will confirm receipt of your request via email. This is the moment that the mediation contract between You and Us comes into existence. If You do not receive such confirmation within one hour of completing your booking request, and none of our operators has contacted you by email or telephone to indicate any problems, please contact us by telephone for verification.

&nbsp;

3.1.2 As soon as your requested tickets have been issued, You will receive a confirmation email with a ticket number. This is the moment a binding contract between You and the relevant airline(s) has been concluded.

&nbsp;

3.1.3 Prices for the flight, as well as seat availability, are directly inserted by the applicable airline. For airline price changes outside our control occurring after submission of the booking request, but before the contract with the airline has become binding (as set out in Section 3.1.2 above), the contract will not enter into force and your payment will be refunded in full. We may contact You and offer You the option to accept the changed price during ordinary opening hours, however no later than 24 hours after We have gained knowledge of the price change or on the first weekday following such 24 hours-period.

&nbsp;

### 3.2 Information on booking and transport conditions
3.2.1 Regarding the flights offered on the Portal, We exclusively act within our capacity as an intermediary. The agreement for the performance of the flight comes into effect directly between You and the relevant airline and We do not accept any contractual responsibility related to the performance or non-performance of your flight. The operating airline is solely responsible for performance / non-performance of your flight.

&nbsp;

3.2.2 Before You conclude the booking, the terms and conditions of the relevant airline(s) will be made available to You.

&nbsp;

3.2.3 Below, by way of a general overview, We provide information on conditions related to booking and transport typically applied by airlines in such or at least a similar way. However, any deviating provisions of the relevant airline prevail over the general information provided in this Section 3.2.3. Therefore, in each case, please check the applicable terms and conditions of the relevant airline prior to your booking.

&nbsp;

#### a. Flight times/check in
All flight times specified are local. Next-day arrivals are indicated with a "+1" on the timetable. The stated flight times are preliminary and subject to change on short notice after the ticket has been issued; for example, due to restrictions by flight control, the weather, or functional restrictions by the airline. Please keep yourself informed of the current times well ahead of your flight.

&nbsp;

Please comply with the check-in times stated by the airline. The airlines are entitled to refuse boarding if You are late for check-in. Kindly observe that some airlines encourage check-in through their own website as they may charge a check-in fee when checking in manually at the airport.

&nbsp;

#### b. Combination of individual tickets
A combination of two separate one-way tickets instead of a roundtrip ticket is clearly marked as such during the booking procedure. The tickets are treated independently from one another in the event of cancellation, change, disruption of air traffic such as strikes and changes to flight schedules. Each airline's own regulations will apply.

&nbsp;

Flight tickets with different booking numbers are always regarded as journeys that are independent of each other.

&nbsp;

#### c. Flight tickets with several segments/order of use
Your roundtrip ticket or one-way ticket may consist of several segments. According to the conditions of most airlines, such flight segments are to be used in sequence. If not, many airlines will refuse transport on subsequent flight segments (e.g. failure to use one segment of a journey could invalidate the rest of the ticket). For roundtrip tickets a no-show on Your outbound flight may result in Your inbound flight being cancelled by the airline

&nbsp;

#### d. Pregnancy
Some airlines refuse to transport women who are past week 28th of pregnancy at the time of the outward or return flight. If you are pregnant You must clarify with the airline and your doctor whether or not You can commence the journey.

&nbsp;

#### e. Infants and child tickets
Please contact the airline to obtain the conditions for traveling with a child who does not have a separate seat. Usually, children above the age of 2 years require a separate seat, while children between the ages of 0 and 2 years travel as infants and will not be allocated a seat of their own. If the infant reaches the age of 2 before the end of the trip, a child ticket must be booked for the entire trip. Infant tickets cannot be booked before birth, as the correct name and date of birth must match those stated in the passport. We will not reimburse any expenses that arise if the wrong type of ticket is booked from the outset.

&nbsp;

#### f. Unaccompanied minors
We do not mediate the booking of any tickets for unaccompanied minors. Children under 18 years must be booked for a journey in the company of an adult. Some countries and airlines refuse entry to children under 18 years unless accompanied by a legal guardian. Please note that some airlines require children under the age of 18 to bring a birth certificate to travel.

&nbsp;

#### g. Lost/damaged luggage
Within our capacity as an intermediary, We accept no liability for lost or damaged luggage. Any problems should be reported immediately to the representative of the airline at the airport.

&nbsp;

#### h. Transit and overnight accommodation
Generally, ground transportation and/or overnight accommodation during your trip is not included in the flight ticket price. You are personally responsible for checking ground transportation timetables and prices.

&nbsp;

#### i. Connection times between flights
Standard tickets booked on the Portal have approved connection times. The times required for making connections between flights are calculated by the airlines. If a flight segment is delayed and leads to a missed connection, the airlines are obliged to assist You in reaching your final destination (see Section 8.1).

&nbsp;

When separate tickets are booked, the airlines bear no responsibility for missed connections resulting from delays. Therefore, it is your responsibility to ensure that the connection time is sufficient according to the airlines and airports. Any additional costs incurred on account of missed connections will not be reimbursed.

&nbsp;

#### j. Double booking
A double booking means that two or more bookings with the same passenger name have been made with the same airline. If You have a double booking, the airline may cancel the trip(s). This may also occur if the bookings have been made with different travel agencies. We are not responsible for cancellations made by the airlines, nor for denied refunds from airlines, in cases where they suspect a double booking.

&nbsp;

### 3.3 Airlines banned from operating in the EU (black list)
Please note that certain airlines are prohibited from operating within the EU, according to a decision made by the European Commission in close consultation with the national air transport authorities. Such airlines are prohibited because they are considered unsafe or not subject to adequate control by the authorities of their country.

&nbsp;

You can check which airlines are affected by an operating prohibition via the following link:
[Black List (List of airlines not permitted to operate in the EU)](https://ec.europa.eu/transport/language-selector/site-language_en?destination=node/2840)

&nbsp;

### 3.4 Timetable changes and cancellation by airlines

3.4.1 Your agreement with the applicable airline may allow them to cancel or amend your bookings. We will notify You of any changes once We are informed of them by the airline.

&nbsp;

3.4.2 The flight times shown in your booking confirmation may change between the date of your booking and the date You actually travel. In case of flight schedule changes, We will notify You as soon as We are informed thereof by the airline. However, We strongly recommend that You contact your airline at least 72 hours before the scheduled departure of each flight to ensure that the flight(s) (and any connecting flights) are operating on schedule. We have no control over airline’s schedule changes and accept no responsibility for costs which may arise as a result of such changes.

&nbsp;

### 3.5 Changes and cancellations requested by You
For low cost airline(s), see Section 3.7.

&nbsp;

3.5.1 The conditions for changing flight bookings (including change of passenger name, destination, and date of travel) and for providing cancellation refunds are set by the relevant airline, which is your contractual partner for providing the flight. We, as the intermediary, have no influence over such conditions.

&nbsp;

3.5.2 If You wish to make a change to your booking or request a cancellation refund, as an additional own service, We offer to handle the request on your behalf, provided that the conditions of the airline permit such a change or cancellation refund. During the process of booking such additional services, we will inform You of any further conditions for such services. Alternatively, you can, of course, approach the relevant airline directly.

&nbsp;

3.5.3 In order for us to be able to handle the changes requested by You, it is necessary that We receive your change requests at the latest 24 hours prior to the commencement of travel (only by telephone).

&nbsp;

For change requests at shorter notice, we recommend that You contact the relevant airline directly.

&nbsp;

### 3.6 Non-Appearance or Non-Attendance of the Flight
You hereby authorize us to cancel the non-used flight on your behalf in case of non-appearance or non-attendance of the flight and to request possible refunds from the airline on your behalf. We are entitled but not obligated to do so and your right to request refunds directly from the airline remains unaffected.

&nbsp;

### 3.7 Low cost airlines
3.7.1 It will be clearly displayed to You during the booking procedure if the applicable flight is operated by a low cost airline. The following special provisions apply for flights conducted by low cost airlines

&nbsp;

3.7.2 When mediating Travel Services rendered by low cost airlines, We may act on your behalf in concluding the contract between You and the low cost airline.

&nbsp;

3.7.3 We do not have access to the booking systems of low cost airlines. Therefore, please contact the relevant low cost airline directly for questions on bookings, changes or cancellation of your booking.

&nbsp;

3.7.4 Please also note that some low cost airlines charge fees for manual check-in at the airport. In order to avoid such fees, You should use the possibility of online check-in through the website of the relevant low cost airline. Please inform yourself in good time prior to your departure about the check-in possibilities and conditions of your airline.

&nbsp;

3.7.5 Booking confirmations for low cost flights are sent as soon as the reservation has been confirmed by the relevant low cost airline. You may receive two booking confirmations – one from us and one from the relevant low cost airline. If You have received two booking confirmations, please use the booking confirmation of the low cost airline for check-in.

&nbsp;

# 4. Special provisions for the mediation of bookings of multiple services
The Portal offers the possibility to mix and match multiple individual airlines as You wish (e.g. Each flight in a Multi-trip or Return trip can be a mix of two or more validating airlines). In this case, You instruct us to act as an intermediary for the booking of various travel services from various airlines. The relevant airlines will be clearly presented to You during the booking procedure before the booking is completed. In the event that You combine individual airlines, no travel contract is concluded between You and us; instead, You conclude several contracts for the provision of individual services with each separate airline. In this case, We solely operate within our capacity as an intermediary in relation to each individual travel service.

&nbsp;

# 5. Information on passport, visa and health provisions
5.1 Passport, visa and/or health requirements can change and You should therefore check with the relevant authority (embassy, consulate etc.) well in advance of travel. It is your responsibility to be in possession of a valid passport and, if appropriate, a visa. It is important to remember to include all transit points in your journey which may also require You to obtain a visa. It can often take some time to obtain a visa, so You are advised to apply well ahead of time. We accept no responsibility for customers who do not possess the correct documents.

&nbsp;

5.2 Each destination has its own requirements as far as entry formalities, vaccinations, etc. which can also vary depending on the passenger's nationality. It is your responsibility to collect that information. No incidents arising from the failure to comply with such official regulations will be considered our responsibility. We therefore urge You to always verify the different formalities of the chosen destination or transit countries, as well as the time needed to take all of the related steps.

# 6. Charges and payment

&nbsp;

### 6.1 Payment
6.1.1 Payment for Travel Services is processed by us (in cooperation with our payment service provider(s) who supply the payment processing functionality). Please note that We might need to share your payment information, such as credit or debit card information, with our payment service provider(s) in order to be able to process the payment. All payment information belonging to our customers is encrypted in a secure server when shared with our payment service provider(s).

&nbsp;

6.1.2 In order for us to be able to process your payment, You need to ensure that there are sufficient funds available. In the event of any problem arising related to the processing of your payment, We will retry the processing of your payment in cooperation with our payment service provider(s). Should this not result in a fulfilled withdrawal of funds from You, We will contact You as soon as possible for instructions on other means of payment. Should We not receive your payment after such further instructions, your outstanding payment will be referred to a debt collection company.

&nbsp;
### 6.2 Payment fraud
If there are reasons for us to suspect that fraud is being committed, We reserve the right to refuse processing of such payment. Legitimization of Payment may be demanded if a crime is suspected. All types of payment fraud will be reported to the police and referred to a debt collection company.

&nbsp;

# 7. Complaints
### 7.1 Claims relating to performance of Travel Services
Any problem, remark or claim relating to actual performance of the Travel Services must be addressed directly to the relevant Service Provider with whom you hold the contract for the relevant Travel Service. Please also see section 8 for your rights pursuant to EU regulation.

&nbsp;

### 7.2 Complaints relating to our own services
Complaints relating to our own services are handled solely in writing and are to be submitted within two months after the end date of your trip via email.

&nbsp;

Email address: [complaints@flystrait.com](mailto:complaints@flystrait.com)

&nbsp;

# 8. Customer rights pursuant to EU regulation

### 8.1 Cancelled or delayed flights
If you are travelling into or out of the EU, or on an EU carrier, you may have the right to demand reimbursement for costs which you can assert directly against the relevant airline in the event that your flight is cancelled, delayed or you are denied boarding. [For more information about EC Regulation 261/2004, please click here.](http://eur-lex.europa.eu/legal-content/EN/ALL/?uri=CELEX%3A32004R0261)

&nbsp;

### 8.2 Carrier liability
The EC-Regulation (889/2002) on air carrier liability in the event of accidents. [Regulation can be found here.](http://eur-lex.europa.eu/legal-content/EN/TXT/?uri=celex%3A32002R0889)

&nbsp;

# 9. Liability

9.1 You accept that We act as an intermediary between the You and the Service Provider. We will under no circumstances be held liable with respect to Travel Services You have booked with one or more Service Providers and We do not accept any liability for any inaccuracies in the information displayed on the Portal which have been provided by the respective Service Provider.

&nbsp;

9.2 Should a Service Provider be unable to provide the Travel Service for any reason, including where a Service Provider declares for bankruptcy, We can only act as an intermediary and refund payments where we have already received such from the applicable Service Provider.

&nbsp;

9.3 With regard to our own services, We are liable for damages subject to the limitations set out in these T&C’s and to the extent permitted by law. We shall only be liable for direct damages actually suffered, paid or incurred by You due to an attributable shortcoming of our obligations in respect to our own services, up to an aggregate amount of the cost of your booking (whether for one event or series of connected events).

&nbsp;

9.4 The limitation of liability set out in Section 9.3 also applies to breaches of duty by persons for the fault of whom We are responsible according to statutory provisions.

&nbsp;

# 10. Governing law and dispute resolution

&nbsp;

### 10.1 Governing law
Estonian law, with the exclusion of substantive uniform law, in particular UN Sales Law on the International Sale of Goods, applies exclusively to these T&Cs and the contract between You and us.

&nbsp;

As a consumer, you will benefit from any mandatory provisions of the law of the country in which you are resident. Nothing in these T&Cs, including this Section 10.1, affects your rights as a consumer to rely on such mandatory provisions of local law.

&nbsp;

### 10.2 Online Dispute Resolution

The European Commission provides a platform for online dispute resolution (ODS) under: (http://ec.europa.eu/consumers/odr/)

&nbsp;

# 11. Data Protection

We take the protection of your personal data seriously. You can find detailed information about the collection, processing and use of your personal data in our [Privacy Policy.](/privacy)
`;
