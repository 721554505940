import React from "react";
import styled from "styled-components";
import FlightNumAndDate from "components/FlightSegment/Partials/FlightNumAndDate";
import { Button } from "components/common";
import {
  FlightCancelStrict,
  FlightCancelModerate,
  FlightCancelFlexible,
} from "components/common/SvgIcons";
import HopSegButtonLoader from "components/common/HopSegButtonLoader";
import { addDecimal, numberToLocaleString, getItem } from "utils/utils";
import getTranslation from "utils/getTranslation";

const Card = styled.div`
  width: 340px;
  background-color: ${(props) =>
    props.selected ? props.theme.primaryBackground : "#fff"};
  margin-bottom: 15px;
  border: ${(props) =>
    props.selected
      ? `2px solid ${props.theme.primary}`
      : `1px solid ${props.theme.primary}`};
  border-radius: 8px;
  display: flex;
  flex-flow: column;
  padding-bottom: 10px;
  align-items: center;
  font-size: 16px;
  color: #444444;
  @media (max-width: 800px) {
    width: 100%;
    margin-bottom: 10px;
  }
  .row {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
    text-transform: capitalize;
    &.notice {
      font-size: 12px;
      color: #444444;
      margin-top: 20px;
      text-transform: none;
      .contact {
        color: ${(props) => props.theme.secondary};
        text-decoration: underline;
      }
    }
    .price {
      color: ${(props) => props.theme.secondary};
      font-size: 18px;
    }
  }
  .btnWrapper {
    width: 165px;
    margin-bottom: 10px;
    &.selected {
      .MuiFab-root {
        color: #eb5757;
      }
    }
  }
`;

export default class extends React.PureComponent {
  components = {
    STRICT: FlightCancelStrict,
    MODERATE: FlightCancelModerate,
    FLEXIBLE: FlightCancelFlexible,
  };
  getCost = (price) =>
    numberToLocaleString(addDecimal(price.priceDiff, price.costDecimalPlaces), {
      selectedCountry: getItem("selectedLanguage"),
      selectedCurrency: price.currencyIso,
    });
  handleSelect = () => {
    const { details } = this.props;
    const cost = this.getCost(details);
    this.props.handleSelect(
      details,
      `${details.priceDiff > 0 ? "+" : ""} ${cost}`
    );
  };
  render() {
    const {
      details,
      hopSeqNum,
      selected,
      dummy,
      otherFlight,
      loading,
    } = this.props;
    let hopRequest;
    let penalty;
    let flightInfo;
    if (!loading) {
      if (!dummy) {
        hopRequest = details.hopRequests.find(
          (item) => item.hopSeqNum === hopSeqNum
        );
      } else {
        hopRequest = details;
        const flight = otherFlight.hopRequests.find(
          (item) => item.hopSeqNum === hopSeqNum
        );
        penalty = flight.hopSegmentControls[0].dateChangePenalty;
      }

      flightInfo = hopRequest.hopSegmentControls[0].flightSegments[0];
    }
    const { dateChangePenalty } = hopRequest
      ? hopRequest.hopSegmentControls[0]
      : {};
    const Icon = this.components[dateChangePenalty || "STRICT"];

    return (
      <Card
        selected={selected}
        style={
          loading
            ? {
                boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.12)",
                border: 0,
              }
            : {}
        }
      >
        {!loading ? (
          <React.Fragment>
            <div className="row">
              <FlightNumAndDate flightInfo={flightInfo} />
            </div>
            <div className="row">
              <Icon stroke="#777777" style={{ height: 24, width: 24 }} />
            </div>
            <div className="row">
              {!dummy ? dateChangePenalty.toLowerCase() : penalty.toLowerCase()}
            </div>
            <div className="row">
              {!dummy ? (
                <span className="price">
                  {details.priceDiff > 0 ? "+" : ""} {this.getCost(details)}
                </span>
              ) : (
                <span className="price">-</span>
              )}
            </div>
            <div className={`btnWrapper ${selected ? "selected" : ""}`}>
              <Button
                style={{
                  height: 36,
                }}
                disabled={dummy}
                color={selected ? "#F0F0F0" : undefined}
                onClick={this.handleSelect}
              >
                {getTranslation(selected ? "discard" : "select")}
              </Button>
            </div>
            <div className="row notice">
              <span>{getTranslation("dateChangeText")}</span>
              <span className="contact">{getTranslation("contact")}</span>
            </div>
          </React.Fragment>
        ) : (
          <HopSegButtonLoader type="cancellation" />
        )}
      </Card>
    );
  }
}
