import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';

const xairlineByIc = gql`query xairlineByIc(
    $airlineIc: String!,
  ) {
    xairlineByIc(
      airlineIc: $airlineIc,
    ) {
      airlineDesc
    }
  }
`;

export default ({
  airlineIc,
}) => (
  <Query query={xairlineByIc} variables={{ airlineIc: `${airlineIc}` }}>
    {({ data }) => (
      data.xairlineByIc ? <span style={{ margin: 0, fontFamily: 'Roboto Condensed, sans-serif' }}> {data.xairlineByIc.airlineDesc} </span> : null
    )}
  </Query>
);
