import React from "react";
import styled, { withTheme } from "styled-components";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Button } from "components/common";
import SearchMeta from "components/HopSegment/Partials/SearchMeta";
import { InfoIcon } from "components/common/SvgIcons";
import { Title } from "components/common/CommonStyles";
import Loader from "./Loader";
import getTranslation from "utils/getTranslation";

const Wrapper = styled.div`
  color: #000;
  min-height: 64px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  font-size: 18px;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  justify-content: space-between;
  .meta {
    cursor: pointer;
    width: 440px;
    display: flex;
    padding: 10px;
    align-items: center;
    background-color: ${(props) => props.theme.primaryBackground};
    border-radius: 10em;
    font-size: 16px;
    color: #222222;
    img {
      margin-right: 10px;
    }
  }
  @media (max-width: 800px) {
    flex-flow: column;
    align-items: flex-end;
    .meta {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  .right {
    display: flex;
    align-items: center;
    @media (max-width: 800px) {
      padding: 1px;
    }
    .finding {
      font-weight: 300;
      color: #777777;
      font-size: 18px;
      text-transform: capitalize;
      margin-right: 15px;
    }
  }
`;

const StyledLoader = styled(CircularProgress)`
  position: absolute;
  left: 0;
`;

const LoaderContainer = styled.div`
  width: 536px;
  margin: auto;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

const LoadingResults = ({
  hopRequests,
  handleCancel,
  handleMultiTrip,
  theme,
}) => {
  // const fromAirportIc = hopRequests[0].from.airportIc;
  // const { from, to } = hopRequests[hopRequests.length - 1];
  // const isMultiTrip = hopRequests.length > 1 && fromAirportIc !== to.airportIc;

  //handleMultiTrip(isMultiTrip);

  return (
    <React.Fragment>
      <Wrapper>
        <SearchMeta hopRequests={hopRequests} handleClose={handleCancel} />
        <div className="right">
          <span className="finding">{getTranslation("findingItinerary")}</span>
          <div>
            <div style={{ width: 40, height: 40, position: "relative" }}>
              <Button
                style={{
                  height: 40,
                  minWidth: 40,
                }}
                roundButton
                color={theme.primaryBackground}
              >
                <InfoIcon stroke={theme.secondary} />
              </Button>
              <StyledLoader size={40} color="#222222" />
            </div>
          </div>
        </div>
      </Wrapper>
      <LoaderContainer>
        <Title>{getTranslation("selectedItinerary")}</Title>
        {hopRequests.map((hopRequest) => (
          <Loader key={hopRequest.date} />
        ))}
        {hopRequests[0].returnDate > 0 && <Loader />}
      </LoaderContainer>
    </React.Fragment>
  );
};

export default withTheme(LoadingResults);
