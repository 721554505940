import React from "react";
import styled, { withTheme } from "styled-components";
import Button from "@material-ui/core/Button";
import { Button as CustomButton } from "components/common";

const Wrapper = styled.div`
  display: flex;
  width: ${props => (props.fullWidth ? "100%" : "60%")};
  padding: 10px;
  margin-left: auto;
`;

const ActionButtons = ({
  handleSubmit,
  handleCancel,
  cancelLabel,
  saveBtnText,
  fullWidth,
  theme
}) => (
  <Wrapper fullWidth={fullWidth}>
    <Button style={{ marginRight: 20 }} onClick={handleCancel} color="primary">
      {cancelLabel}
    </Button>
    <CustomButton onClick={handleSubmit} color={theme.primary}>
      {saveBtnText}
    </CustomButton>
  </Wrapper>
);

export default withTheme(ActionButtons);
