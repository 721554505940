import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Lottie from "react-lottie";
import Fade from "react-reveal/Fade";

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 335px;
  @media (max-width: 800px) {
    width: 100%;
  }
  p {
    margin-top: 0px;
  }
`;

export default ({ data }) => {
  const [isStopped, setStop] = useState(true);
  const [revealed, setRevealed] = useState(false);
  useEffect(() => {
    setStop(!revealed);
  }, [revealed]);
  function handleToggle() {
    setStop(!isStopped);
  }
  function handleReveal() {
    setTimeout(() => {
      setRevealed(true);
    }, 300);
  }
  return (
    <Wrapper onMouseEnter={handleToggle} onMouseLeave={handleToggle}>
      <Fade onReveal={handleReveal}>
        <Lottie
          options={{
            loop: false,
            autoplay: true,
            animationData: data.data,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice"
            }
          }}
          isStopped={isStopped}
          height={335}
          width={335}
        />
      </Fade>
      <Fade>
        <p>{data.text}</p>
      </Fade>
    </Wrapper>
  );
};
