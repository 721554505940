import gql from 'graphql-tag';

export const addHOPRequest = gql`mutation addHoprequest(
    $hopSeqNum: Int!,
    $adultCount: Int!,
    $childCount: Int!,
    $infantCount: Int!,
    $stdDate: String!,
    $fromAirportIc: String!,
    $toAirportIc: String!,
    $stopCount: Int!,
    $tripId: Int!
  ) {
    addHoprequest(
      hopSeqNum: $hopSeqNum,
      adultCount: $adultCount,
      childCount: $childCount,
      infantCount: $infantCount,
      stdDate: $stdDate,
      fromAirportIc: $fromAirportIc,
      toAirportIc: $toAirportIc,
      stopCount: $stopCount,
      tripId: $tripId
    ) {
      id
      hopSegmentControls {
        id
        flightSegments {
          id
        }
      }
    }
  }
  `;

export const hopSegmentControlById = gql`query hopSegmentControlById(
    $id: Int!
  ) {
    hopSegmentControlById(
      id: $id
    ) {
      id
      fscode,
      paxnameCharLimit,
      airlinePnr,
      seatClass,
      cancellationPenalty,
      dateChangePenalty,
      seatChoice,
      baggagePieceCount,
      baggagePieceMaxWeight,
      baggagePieceImperial,
      flightSegments {
        id
        airlineIc,
        flightNum,
        fromAirportIc,
        stdDate,
        stdTime,
        toAirportIc,
        staDate,
        staTime,
        aircraftIcao,
        durationMins
      }
    }
  }
  `;
export const getCostByTripIdQuery = gql`query tripById(
    $id: Int!
  ) {
    tripById(id: $id) {
      tripCostItems {
        cost,
        costItemLabel,
        costItemCurrencyIso,
        costDecimalPlaces
      }
    }
  }`;

export const getAllPassengers = gql`query allPassengers {
    allPassengers {
      title
      firstName
      lastName
      birthdate
      gender
      nationality
      passportCountry
      passportExpdate
      passportNumber
      cardHolder
      cardHoldercountryic
    }
  }`;
