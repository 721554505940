import React from "react";
import styled from "styled-components";
import { Button } from "components/common";

const Wrapper = styled.div`
  .__release-text {
    font-weight: 300;
    font-size: 14px;
    color: #222222;
    line-height: 100%;
    text-align: center;
    height: 40px;
    display: flex;
    align-items: center;
  }
  svg {
    height: 22px;
    width: 22px;
    margin-right: 5px;
  }
  &.selected {
    button {
      border: 2px solid #56ccf2;
    }
  }
`;

export default ({ children, disabled, selected, onClick }) => (
  <Wrapper className={selected ? "selected" : ""}>
    <Button
      color={selected ? "#fff" : "#F2CC56"}
      disabled={disabled}
      onClick={!disabled ? onClick : null}
      style={{
        borderRadius: 4,
        textTransform: "capitalize",
        fontSize: 14,
        fontWeight: "300 !important",
        fontFamily: '"Roboto Condensed", sans-serif',
      }}
    >
      {children}
    </Button>
  </Wrapper>
);
