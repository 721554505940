import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { graphql, compose } from 'react-apollo';
import Baggages from './Baggages';
import { setExtrabaggageCount, resetExtrabaggage } from './queries';
import { setHopRequestsData, setTripCost } from 'redux-store/actions';

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  .title {
    background-color: #DDDDDD;
    text-transform: uppercase;
    color: #444444;
    padding: 10px 24px;
  }
  .baggages {
    display: flex;
    flex-flow: column;
  }
`;

class BaggaeCount extends Component {
  constructor(props) {
    super(props);
    const passengers = this.getPassengers(props.extraBaggages, props.extraBaggagesInbound);
    this.state = {
      extraBaggages: props.extraBaggages || [],
      extraBaggagesInbound: props.extraBaggagesInbound || [],
      activeType: '',
      activePassenger: 1,
      passengers: passengers.passengers,
      inboundPassengers: passengers.inboundPassengers,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.extraBaggages) {
      const passengers = this.getPassengers(nextProps.extraBaggages, nextProps.extraBaggagesInbound);
      this.setState({
        extraBaggages: nextProps.extraBaggages,
        extraBaggagesInbound: nextProps.extraBaggagesInbound,
        passengers: passengers.passengers,
        inboundPassengers: passengers.inboundPassengers,
      });
    }
  }

  getPassengers = (extraBaggages, extraBaggagesInbound) => {
    let passengers = [];
    let inboundPassengers = [];
    extraBaggages.forEach((baggage) => {
      const passenger = passengers.find(item => item.passengerNum === baggage.passengerNum);
      if (!passenger) {
        const payload = {
          passengerNum: baggage.passengerNum,
          quantity: 0,
        };
        passengers = [...passengers, payload];
      }
      if (baggage.quantity > 0) {
        passengers = passengers.map(item => (item.passengerNum === baggage.passengerNum ? {
          passengerNum: baggage.passengerNum,
          quantity: baggage.quantity,
          baggagePieceMaxWeight: baggage.baggagePieceMaxWeight,
        } : item));
      }
    });
    extraBaggagesInbound.forEach((baggage) => {
      const passenger = inboundPassengers.find(item => item.passengerNum === baggage.passengerNum);
      if (!passenger) {
        const payload = {
          passengerNum: baggage.passengerNum,
          quantity: 0,
        };
        inboundPassengers = [...inboundPassengers, payload];
      }
      if (baggage.quantity > 0) {
        inboundPassengers = inboundPassengers.map(item => (item.passengerNum === baggage.passengerNum ? {
          passengerNum: baggage.passengerNum,
          quantity: baggage.quantity,
          baggagePieceMaxWeight: baggage.baggagePieceMaxWeight,
        } : item));
      }
    });
    passengers = this.handleUpdatePassengerType(passengers);
    inboundPassengers = this.handleUpdatePassengerType(inboundPassengers);
    return { passengers, inboundPassengers };
  }

  getTotalCount = () => {
    const { passengers } = this.state;
    return passengers.child + passengers.adult + passengers.infant;
  }

  handleUpdatePassengerType = (passengers) => {
    const { adultCount, childCount, infantCount } = this.props.hopRequests[0];
    passengers = passengers.map((passenger, i) => {
      if (i < (childCount + infantCount + adultCount)) {
        passenger.type = 'child';
      }
      if (i < (infantCount + adultCount)) {
        passenger.type = 'infant';
      }
      if (i < adultCount) {
        passenger.type = 'adult';
      }
      return passenger;
    });
    return passengers;
  }

  handlePassengerChange = (activePassenger) => {
    this.setState({ activePassenger, activeType: '' });
  }

  handleTypeChange = (activeType) => {
    this.setState({
      activeType,
    });
  }

  handleIncrease = async () => {
    this.handleMutation('setExtrabaggageCount');
  }

  handleRemove = async () => {
    this.handleMutation('setExtrabaggageCount', true);
  }

  handleMutation = async (query, reset) => {
    const { activeType } = this.state;
    let baggage = this.props.extraBaggages.find(item => item.id === activeType);
    if (baggage.baggagePieceCount !== 0 && baggage.quantity !== 0 && !reset) {
      this.props.extraBaggages.find(item =>
        item.baggagePieceMaxWeight === baggage.baggagePieceMaxWeight
         && item.baggagePieceCount === (baggage.baggagePieceCount + 1));
         if (!baggage) {
           return;
         }
         console.log(baggage);
    }
    try {
      this.setState({ loading: true });
      const incr = await this.props[query]({
        variables: {
          hopsegmentcontrolId: baggage.hopsegmentcontrolId,
          extrabaggageId: baggage.id,
          count: reset ? 0 : baggage.quantity + 1
        },
      });
      this.props.setHopRequestsData(incr.data[query].hopRequests);
      this.props.setTripCost(incr.data[query].tripCostItems);
      this.handleBaggageCostCheck(incr.data[query].hopRequests);
      this.setState({ loading: false });
    } catch (err) {
      console.log(err);
      this.setState({ loading: false });
    }
  }

  handleBaggageCostCheck = (hopRequests) => {
    const hopRequest = hopRequests.find(item => item.id === this.props.hopRequestId);
    this.props.handleChangeTitle(hopRequest.hopSegmentControls[0].extraBaggages);
  }

  render() {
    const {
      extraBaggages,
      activePassenger,
      loading,
      passengers,
      activeType,
    } = this.state;
    const { tripCostItems } = this.props;
    const outboungBagges = extraBaggages.filter(item => item.inbound === false);
    const activePassengerData = passengers.find(item => item.passengerNum === activePassenger);
    return (
      <Wrapper>
        {passengers.map((passenger, i) => (
          <div key={passenger.id}>
            <div className="title">
              passenger #{i + 1}
            </div>
            <div className="baggages">
              <Baggages
                outboungBagges={outboungBagges}
                loading={loading}
                activePassengerData={activePassengerData}
                passenger={passenger}
                activeType={activeType}
                handleTypeChange={this.handleTypeChange}
                handleIncrease={this.handleIncrease}
                handleRemove={this.handleRemove}
                tripCostItem={tripCostItems[0]}
              />
            </div>
          </div>
        ))}
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  hopRequests: state.flightResults.hopRequests,
  tripCostItems: state.flightResults.tripCostItems,
});

const mapDispatchToProps = {
  setHopRequestsData,
  setTripCost,
};

export default compose(
  graphql(setExtrabaggageCount, { name: 'setExtrabaggageCount' }),
  graphql(resetExtrabaggage, { name: 'resetExtrabaggage' }),
  connect(mapStateToProps, mapDispatchToProps),
)(BaggaeCount);
