import moment from "moment";
import {
  SET_HOP_REQUESTS_DATA,
  UPDATE_HOP_REQUESTS_DATA,
  SET_TRIP_COST,
  ADD_PASSENGER_TO_ALL_HOP_REQUESTS,
  REMOVE_PASSENGER_FROM_HOP_REQUEST,
  REMOVE_PASSENGER_FROM_ALL_HOP_REQUEST,
  REMOVE_TRIP_DATA,
  ADD_PREV_PASSENGER,
  ALL_PASSENGERS_ADDED,
  HANDLE_ASYNC_UPDATE_FARE_RULE,
  HANDLE_UPDATE_FILTERED_RESULTS,
  CLEAR_FILTERED_RESULTS,
  UPDATE_SPLIT_BOOKING,
  ENABLE_PAYMENT,
  TOGGLE_MULTITRIP,
  UPDATE_COST
} from "redux-store/actions";

const initialProps = {
  hopRequests: [],
  tripCostItems: [],
  prevPassengers: [],
  filteredResults: [],
  splitBooking: true,
  allPassengersAdded: false,
  doAsyncFareRule: false,
  enablePaymentGateway: false,
  isMultiTrip: false,
  hopRequestsUpdateTime: 0,
  localizedTotalCost: ""
};
export default function(state = initialProps, action) {
  switch (action.type) {
    case SET_HOP_REQUESTS_DATA:
      return {
        ...state,
        hopRequests: action.payload,
        hopRequestsUpdateTime: moment.unix()
      };
    case UPDATE_HOP_REQUESTS_DATA:
      return {
        ...state,
        hopRequests: {
          ...state.hopRequests,
          ...action.payload
        },
        hopRequestsUpdateTime: moment.unix()
      };
    case SET_TRIP_COST:
      return {
        ...state,
        tripCostItems: action.payload
      };
    case ADD_PASSENGER_TO_ALL_HOP_REQUESTS:
      return {
        ...state,
        hopRequests: state.hopRequests.map(hopRequest => ({
          ...hopRequest,
          passengers: [...hopRequest.passengers, action.payload]
        })),
        hopRequestsUpdateTime: moment.unix()
      };
    case REMOVE_PASSENGER_FROM_HOP_REQUEST:
      return {
        ...state,
        hopRequests: state.hopRequests.map(hopRequest =>
          hopRequest.id === action.payload.hopRequestId
            ? {
                ...hopRequest,
                passengers: hopRequest.passengers.filter(
                  passenger => passenger.id !== action.payload.passengerId
                )
              }
            : hopRequest
        ),
        hopRequestsUpdateTime: moment.unix()
      };
    case REMOVE_PASSENGER_FROM_ALL_HOP_REQUEST:
      return {
        ...state,
        allPassengersAdded: false,
        hopRequests: state.hopRequests.map(hopRequest => ({
          ...hopRequest,
          passengers: hopRequest.passengers.filter(passenger =>
            passenger.firstName === action.payload.firstName &&
            passenger.lastName === action.payload.lastName &&
            passenger.gender === action.payload.gender &&
            passenger.birthdate === action.payload.birthdate
              ? null
              : passenger
          )
        })),
        hopRequestsUpdateTime: moment.unix()
      };
    case ADD_PREV_PASSENGER:
      return {
        ...state,
        prevPassengers: action.payload
      };
    case REMOVE_TRIP_DATA:
      return {
        ...state,
        hopRequests: [],
        tripCostItems: [],
        hopRequestsUpdateTime: 0,
        prevPassengers: []
      };
    case ALL_PASSENGERS_ADDED:
      return {
        ...state,
        allPassengersAdded: action.payload
      };
    case HANDLE_ASYNC_UPDATE_FARE_RULE:
      return {
        ...state,
        doAsyncFareRule: true
      };
    case HANDLE_UPDATE_FILTERED_RESULTS:
      return {
        ...state,
        filteredResults: action.payload.slice(0, 100)
      };
    case CLEAR_FILTERED_RESULTS:
      return {
        ...state,
        filteredResults: []
      };
    case UPDATE_SPLIT_BOOKING:
      return {
        ...state,
        splitBooking: action.payload
      };
    case ENABLE_PAYMENT:
      return {
        ...state,
        enablePaymentGateway: action.payload
      };
    case TOGGLE_MULTITRIP:
      return {
        ...state,
        isMultiTrip: action.payload
      };
    case UPDATE_COST:
      return {
        ...state,
        localizedTotalCost: action.payload
      };
    default:
      return state;
  }
}
