import React from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { Loader, Input, Toast, Button } from 'components/common';
import { submitContactForm } from 'utils/api';
import { validateEmail, getItem } from 'utils/utils';
import getTranslation from 'utils/getTranslation';

const Wrapper = styled.div`
  color: #222222;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  padding-bottom: 50px;
  min-height: -webkit-fill-available;
  @media (max-width: 800px) {
    padding: 20px;
  }
  div {
    text-align: center;
    p {
      font-size: 16px;
    }
    h2 {
      margin-bottom: 10px;
      font-size: 20px;
    }
    .pipe {
      width: 100%;
      height: 1px;
      background-color: #000;
      margin: 20px 0;
    }
  }
`;

const Form = styled.div`
  width: 456px;
  p {
    color: #444444;
    text-transform: uppercase;
    font-size: 18px;
    margin-bottom: 10px;
    font-family: 'Roboto Condensed', sans-serif;
  }
  .row {
    display: flex;
    @media (min-width: 1000px) {
    }
    .input {
      width: 100%;
      @media (min-width: 1000px) {
        &:first-child {
          margin-right: 10px !important;
        }
        &:last-child {
          margin-left: 10px !important;
        }
      }
    }
  }
  .btnWrapper {
    width: 296px;
    margin: auto;
    margin-top: 20px;
  }
  @media (max-width: 800px) {
    width: 100%;
    .row {
      flex-direction: column;
    }
  }
`;

const defaultState = {
  email: '',
  firstname: '',
  lastname: '',
  subject: '',
  message: '',
  loading: false,
  errors: {},
};

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = defaultState;
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleMessageChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleValidations = () => (
    new Promise((resolve, reject) => {
      let hasErrors = false;
      const errors = {};
      const emailValid = validateEmail(getItem("selectedCountry" || "XX"), this.state.email);
      if (!emailValid) {
        hasErrors = true;
        errors.email = 'Please enter a valid email';
      }
      if (this.state.firstname.length < 2) {
        hasErrors = true;
        errors.firstname = 'Please enter a valid firstname';
      }
      if (this.state.lastname.length < 2) {
        hasErrors = true;
        errors.lastname = 'Please enter a valid lastname';
      }
      if (this.state.message.length < 2) {
        hasErrors = true;
        errors.message = 'Please enter a valid message';
      }
      if (this.state.subject.length < 2) {
        hasErrors = true;
        errors.subject = 'Please enter a valid subject';
      }
      if (hasErrors) {
        reject(errors);
      } else {
        resolve(true);
      }
    })
  );
  handleSubmit = () => {
    this.handleValidations().then(async () => {
      this.setState({ loading: true });
      try {
        const register = await submitContactForm(this.state);
        console.log(register)
        this.setState({
          ...defaultState,
          showToast: true,
          toastMessage: 'You request has been submitted',
        });
        setTimeout(() => {
          this.props.history.push(`/`);
        }, 2000);
      } catch (err) {
        console.log(err);
        this.setState({ loading: false });
      }
    }, (errors) => {
      console.log(errors);
      this.setState({
        errors,
      });
    });
  }

  handleRequestClose = () => {
    this.setState({
      error: false,
    });
  }

  render() {
    const {
      firstname,
      lastname,
      email,
      errors,
      loading,
      message,
      subject,
      showToast,
      toastMessage,
    } = this.state;
    return (
      <Wrapper>
        <Form>
          <p>{getTranslation('generalEnquiries')}</p>
          <div className="row">
            <Input
              label={getTranslation('firstname')}
              value={firstname}
              name="firstname"
              errorText={errors.firstname}
              onChange={this.handleChange}
              autoFocus={true}
            />
          </div>
          <div className="row">
            <Input
              label={getTranslation('lastname')}
              value={lastname}
              name="lastname"
              errorText={errors.lastname}
              onChange={this.handleChange}
            />
          </div>
          <div className="row">
            <Input
              label={getTranslation('email')}
              value={email}
              name="email"
              errorText={errors.email}
              onChange={this.handleChange}
            />
          </div>
          <div className="row">
            <Input
              label={getTranslation('subject')}
              value={subject}
              name="subject"
              errorText={errors.subject}
              onChange={this.handleChange}
            />
          </div>
          <div className="row" style={{ marginTop: isMobile ? 20 : 0 }}>
            <Input
              label={getTranslation('message')}
              value={message}
              name="message"
              multiline
              rows="4"
              onChange={this.handleMessageChange}
            />
          </div>
          <div className="btnWrapper">
            {!loading && <Button
              disabled={loading}
              onClick={this.handleSubmit}
            >{getTranslation('submit')}</Button>}
            {loading && <Loader />}
          </div>
        </Form>
        <Toast
          open={showToast}
          message={toastMessage}
          handleClose={this.handleRequestClose}
        />
      </Wrapper>
    );
  }
}

export default Contact;
