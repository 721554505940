import React from "react";
import styled from "styled-components";
import moment from "moment";
import { connect } from "react-redux";
import RecurrenceCard from "./RecurrenceCard";
import { handleFiltering, handleGrouping } from "./helpers";
import getTranslation from "utils/getTranslation";

const Wrapper = styled.div``;

class CustomDayView extends React.PureComponent {
  constructor(props) {
    super(props);
    const posts = this.getPosts(props.connectPosts, props.date);
    this.state = {
      posts
    };
  }
  componentWillReceiveProps(nextProps) {
    const posts = this.getPosts(nextProps.connectPosts, nextProps.date);
    this.setState({ posts });
  }
  getPosts = (connectPosts, date) => {
    const posts = connectPosts.filter(
      item =>
        moment(item.shareschedule_at).format("DD-MM-YYYY") ===
        moment(date).format("DD-MM-YYYY")
    );
    return posts;
  };
  render() {
    const filteredPosts = handleFiltering(this.state.posts);
    const groupedPosts = handleGrouping(filteredPosts);
    return (
      <Wrapper>
        {groupedPosts.length > 0 ? (
          groupedPosts.map(group => (
            <div key={group.shareschedule_at}>
              {group.posts.map(post => (
                <RecurrenceCard key={post.id} connectPost={post} />
              ))}
            </div>
          ))
        ) : (
          <h2>{getTranslation("noItemForDay")}</h2>
        )}
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  connectPosts: state.influencer.connectPosts
});

export default connect(mapStateToProps)(CustomDayView);
