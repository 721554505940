/* eslint-disable no-lonely-if */
/* eslint-disable no-plusplus */
/* eslint-disable no-loop-func */
import React, { Component } from "react";
import styled from "styled-components";
import MenuItem from "@material-ui/core/MenuItem";
import FilledInput from "@material-ui/core/FilledInput";
import { Select } from "components/common";
import { getItem } from "utils/utils";
import getTranslation from "utils/getTranslation";

const Wrapper = styled.div`
  position: relative;
  .MuiSelect-select {
    p {
      margin: 0;
      padding: 10px 0 !important;
    }
  }
`;

export default class extends Component {
  constructor(props) {
    super(props);
    const items = this.getItems(props.hopRequests);
    this.state = {
      items
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.state.items.length === 0 ||
      nextProps.hopRequestsUpdateTime !== this.props.hopRequestsUpdateTime
    ) {
      const items = this.getItems(nextProps.hopRequests);
      this.setState({
        items
      });
    }
    if (nextProps.value === undefined) {
      this.setState({ value: nextProps.value });
    }
  }

  getItems = hopRequests => {
    let items = [];
    hopRequests.forEach(hopRequest => {
      const hopSeg = hopRequest.hopSegmentControls[0];
      const extraBaggageList = hopSeg.extraBaggages;
      for (let i = 1, j = this.props.passengerCount; i <= j; i++) {
        const extraBaggages = extraBaggageList.filter(
          item => item.passengerNum === i
        );
        const outbound = extraBaggages.find(
          item => item.quantity > 0 && item.inbound === false
        );
        let payload = {
          id: i,
          baggages: []
        };
        if (outbound) {
          const outboundPayload = {
            from: hopSeg.flightSegments[0].fromAirportIc,
            to: hopSeg.flightSegments[0].toAirportIc,
            quantity: outbound.quantity,
            baggagePieceMaxWeight: outbound.baggagePieceMaxWeight,
            baggageId: outbound.id,
            passengerNum: outbound.passengerNum
          };
          payload = {
            ...payload,
            baggages: [outboundPayload]
          };
        }
        if (payload.baggages.length > 0) {
          const index = items.findIndex(item => item.id === payload.id);
          if (index > -1) {
            items[index].baggages = [
              ...items[index].baggages,
              ...payload.baggages
            ];
          } else {
            items = [...items, payload];
          }
        }
      }
    });
    return items;
  };

  handleChange = event => {
    this.setState(
      {
        value: event.target.value
      },
      () => {
        const selected = this.state.items.find(
          item => item.id === event.target.value
        );
        const ids = selected.baggages.map(baggage => baggage.baggageId);
        this.props.handleChange(ids);
      }
    );
  };

  filterSelected = baggage => {
    const passengers = getItem("passengers") || [];
    const selected = passengers.find(passenger =>
      passenger.extraBaggages.find(item => item.id === baggage.baggageId)
    );
    return selected;
  };

  render() {
    const { items, value } = this.state;
    return (
      items.length > 0 && (
        <Wrapper style={{ position: "relative" }}>
          <Select
            label={getTranslation("selectBaggage")}
            value={value}
            handleChange={this.handleChange}
            input={<FilledInput name="age" id="filled-age-simple" />}
            style={{ width: "100%" }}
            menuItems={items.map(
              item =>
                !this.filterSelected(item.baggages[0]) && (
                  <MenuItem
                    key={item.id}
                    value={item.id}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap"
                    }}
                  >
                    {item.baggages.map((baggage, baggageIndex) => (
                      <p
                        key={baggage.baggageId}
                        style={{
                          padding: "0 0 0 10px",
                          margin: 0,
                          lineHeight: 0,
                          fontSize: 14
                        }}
                      >
                        {`${baggage.baggagePieceMaxWeight}Kg x ${baggage.quantity}`}{" "}
                        ({baggage.from + ' > ' + baggage.to})
                        {item.baggages.length > baggageIndex + 1 ? "," : ""}
                      </p>
                    ))}
                  </MenuItem>
                )
            )}
          />
        </Wrapper>
      )
    );
  }
}
