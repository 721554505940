import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from "react-router-dom";
import { Input, Button } from 'components/common';
import { validateEmail } from 'utils/api';
import { getItem, setItem } from 'utils/utils';
import getTranslation from 'utils/getTranslation';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px auto;
  @media (max-width: 800px) {
    height: calc(100vh - 122px);
  }
  h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #000;
  }
`;

const Continer = styled.div`
  width: 300px;
`;


class ContinueBooking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isValidEmail: true,
      email: '',
    };
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleEmailChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    }, () => {
      this.handleEmailCheck(this.state.email);
    });
  }

  handleEmailCheck = (text) => {
    const re = /\S+@\S+\.\S+/;
    const validEmail = re.test(text);
    if (validEmail) {
      this.validateEmailId();
    }
  }

  validateEmailId = async () => {
    try {
      this.setState({ loading: true, isValidEmail: true });
      const validate = await validateEmail(getItem("selectedCountry" || "XX"), this.state.email);
      if (validate.registered) {
        this.handleLoginRedirect();
      }
      this.setState({
        loading: false,
        isValidEmail: validate.valid,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        loading: false,
        isValidEmail: false,
      });
    }
  }

  handleLoginRedirect = () => {
    this.props.history.push(`/login?email=${this.state.email}`);
  }

  handleSubmit = () => {
    const { airlinePnr, lastName, email } = this.state;
    const payload = {
      airlinePnr,
      lastName,
      tripownerId: email,
    };
    setItem('guestLogin', { email });
    this.props.handleSubmit(payload);
  }

  render() {
    const {
      loading,
      email,
      isValidEmail,
      airlinePnr,
      lastName,
    } = this.state;
    const { loadingScreen } = this.props;
    return (
      <Wrapper>
        <Continer>
          <Input
            label={getTranslation('email')}
            value={email}
            fullWidth
            errorText={!isValidEmail ? 'Please enter a valid Email' : ''}
            name="email"
            onChange={this.handleEmailChange}
            autoFocus={true}
          />
          <Input
            label={getTranslation('lastname')}
            value={lastName}
            fullWidth
            name="lastName"
            onChange={this.handleChange}
          />
          <Input
            label={getTranslation('airlinePnr')}
            value={airlinePnr}
            fullWidth
            name="airlinePnr"
            onChange={this.handleChange}
          />
          <Button
            disabled={loading || loadingScreen}
            onClick={this.handleSubmit}
          >Submit</Button>
        </Continer>
      </Wrapper>
    );
  }
}

export default withRouter(ContinueBooking);
