import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import { Select } from "components/common";
import getTranslation from "utils/getTranslation";

const titles = [
  {
    label: "Mr",
    gender: "MALE",
    value: "MR"
  },
  {
    label: "Miss",
    gender: "FEMALE",
    value: "MISS"
  },
  {
    label: "Sir",
    gender: "MALE",
    value: "SIR"
  },
  {
    label: "Lord",
    gender: "MALE",
    value: "LORD"
  },
  {
    label: "Mrs",
    gender: "FEMALE",
    value: "MRS"
  },
  {
    label: "Lady",
    gender: "FEMALE",
    value: "LADY"
  },
  {
    label: "Mstr",
    gender: "MALE",
    value: "MSTR"
  },
  {
    label: "Ms",
    gender: "FEMALE",
    value: "MS"
  },
  {
    label: "Inf",
    gender: "ANY",
    value: "INF"
  }
];

export default class extends React.PureComponent {
  handleChange = e => {
    const { value } = e.target;
    const selectedTitle = titles.find(title => title.value === value);
    const gender = selectedTitle.gender !== "ANY" ? selectedTitle.gender : "";
    this.props.handleChange(value, gender);
  };
  render() {
    const { value, selectedGender, error } = this.props;
    // const valueExists = titles.find(
    //   title => title.gender === selectedGender && value === title.value
    // );
    // const selectedValue = valueExists ? value : undefined;
    return (
      <Select
        label={getTranslation("title")}
        value={value}
        handleChange={this.handleChange}
        menuItems={titles.map(
          item =>
            (item.gender === selectedGender ||
              selectedGender === "" ||
              item.gender === "ANY") && (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            )
        )}
        style={{ width: "48%" }}
        errorState={error}
      />
    );
  }
}
