import React from "react";
import styled from "styled-components";
import Toolbar from "react-big-calendar/lib/Toolbar";
import { Button, Loader } from "components/common";
import { DayIcon, WeekIcon, MonthIcon } from "components/common/SvgIcons";
import BackIcon from "assets/tip/BackwardBig.svg";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  border-top: 1px solid #dddddd;
  position: relative;
  @media (max-width: 800px) {
    flex-flow: column-reverse;
  }
  .loadingWrapper {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -12px;
  }
  .navitationGroup {
    display: flex;
    align-items: center;
    .label {
      margin: 0 75px;
      color: #444444;
    }
  }
  .viewGroup {
    display: flex;
    align-items: center;
    @media (max-width: 800px) {
      justify-content: flex-end;
      margin-bottom: 20px;
    }
    .item {
      padding: 14px;
      display: flex;
      align-items: center;
      color: #444444;
      cursor: pointer;
      &.active {
        background-color: #d0f3fc;
        box-shadow: inset 0px 2px 0px #56ccf2;
        color: #007ac7;
        svg path {
          fill: #007ac7;
        }
      }
      span {
        font-size: 14px;
      }
    }
  }
`;

export default class CalendarToolbar extends Toolbar {
  render() {
    return (
      <Wrapper>
        <div className="loadingWrapper" id="customEventMoveLoader">
          <Loader />
        </div>
        <div className="navitationGroup">
          <div style={{ height: 34, width: 34 }}>
            <Button
              color="#D0F3FC"
              style={{ height: 34, width: 34, minWidth: 34 }}
              roundButton
              onClick={() => this.navigate("PREV")}
            >
              <img src={BackIcon} alt="" />
            </Button>
          </div>
          <div className="label">{this.props.label}</div>
          <div style={{ height: 34, width: 34 }}>
            <Button
              color="#D0F3FC"
              style={{ height: 34, width: 34, minWidth: 34 }}
              roundButton
              onClick={() => this.navigate("NEXT")}
            >
              <img
                src={BackIcon}
                alt=""
                style={{ transform: "rotate(180deg)" }}
              />
            </Button>
          </div>
        </div>
        <div className="viewGroup">
          <div
            className={`item ${this.props.view === "day" ? "active" : ""}`}
            onClick={this.view.bind(null, "day")}
          >
            <DayIcon style={{ height: 14 }} stroke="#56CCF2" />
            <span>Day</span>
          </div>
          <div
            className={`item ${this.props.view === "week" ? "active" : ""}`}
            onClick={this.view.bind(null, "week")}
          >
            <WeekIcon style={{ height: 14 }} stroke="#56CCF2" />
            <span>Week</span>
          </div>
          <div
            className={`item ${this.props.view === "month" ? "active" : ""}`}
            onClick={this.view.bind(null, "month")}
          >
            <MonthIcon style={{ height: 14 }} stroke="#56CCF2" />
            <span>Month</span>
          </div>
        </div>
      </Wrapper>
    );
  }
}
