import React from "react";
import styled from "styled-components";
import { SketchPicker } from "react-color";
import Dropzone from "react-dropzone";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Button } from "components/common";
import { TabbedRadio } from "components/Influencer/Partials";
import { Sections } from "containers/Influencer/Setup/Styles";
import { Layout } from "./LayoutSvgs";
import { EditSection } from "components/Influencer/Common";
import { UserIcon, BusinessIcon } from "components/common/SvgIcons";
import { uploadImage } from "utils/api";
import { resizedImage, hex2rgb } from "utils/utils";
import getTranslation from "utils/getTranslation";
import close from "assets/tip/Close-circle.svg";

const Wrapper = styled.div`
  margin-top: 40px;
  .step1Sections {
    display: flex;
    flex-flow: column;
    .colors {
      display: flex;
      width: 496px;
      margin-top: 28px;
      .section {
        margin-bottom: 24px;
      }
    }
    .logoSection {
      width: 496px;
    }
    @media (max-width: 800px) {
      .logoSection,
      .colors {
        width: 100%;
      }
      .colors {
        flex-flow: column;
      }
    }
  }
  @media (max-width: 800px) {
    padding: 16px;
    width: 100%;
    .step1Sections {
      flex-flow: column;
    }
  }
  .section {
    margin-right: 24px;
    @media (max-width: 800px) {
      margin-right: 0;
      width: 100%;
    }
    &:last-child {
      margin-right: 0;
    }
    .logoWrapper {
      display: flex;
      justify-content: space-between;
      .logo {
        height: 56px;
      }
      .close {
        cursor: pointer;
      }
    }
    .sampleImages {
      display: flex;
      margin-top: 16px;
      flex-wrap: wrap;
      .image {
        border: 1px solid #f0f0f0;
        width: 58px;
        min-width: 58px;
        height: 58px;
        min-height: 58px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 24px;
        cursor: pointer;
        margin-bottom: 16px;
        &.active {
          border: 2px solid #56ccf2;
        }
        img {
          height: 50px;
        }
      }
    }
    .colorWrapper {
      background: #d0f3fc;
      box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
      padding: 10px;
      display: flex;
      align-items: center;
      .colorDisplay {
        border-radius: 4px;
        width: 40px;
        height: 40px;
        border: 1px solid #777777;
        background-color: #fff;
        margin-right: 10px;
        cursor: pointer;
      }
      .text {
        .label {
          font-size: 16px;
          color: #777777;
        }
        &.withColor {
          display: flex;
          flex-flow: column;
          .label {
            font-size: 12px;
          }
          .colorCode {
            text-transform: uppercase;
            color: #222222;
            font-size: 16px;
          }
        }
      }
      .colorPopover {
        position: absolute;
        z-index: 2;
        .colorCover {
          position: fixed;
          top: 0px;
          right: 0px;
          bottom: 0px;
          left: 0px;
        }
      }
    }
  }
  .layouts {
    .MuiFormGroup-root {
      flex-direction: row;
      justify-content: flex-start;
      .MuiFormControlLabel-labelPlacementTop {
        margin-left: 0;
      }
    }
  }
`;

const sampleImages = [
  "https://res.cloudinary.com/flystrait/image/upload/v1585129059/tree_zo14zt.svg",
  "https://res.cloudinary.com/flystrait/image/upload/v1585129122/people_k5l1cw.svg",
  "https://res.cloudinary.com/flystrait/image/upload/v1585129185/scene_u15rso.svg",
  "https://res.cloudinary.com/flystrait/image/upload/v1585129234/surf_bro1lm.svg",
  "https://res.cloudinary.com/flystrait/image/upload/v1585129268/hotel_ho5ugj.svg",
  "https://res.cloudinary.com/flystrait/image/upload/v1585129309/rafting_kwffna.svg",
];

const items = [
  {
    name: "individual",
    label: "Individual Pro",
    icon: UserIcon,
  },
  {
    name: "brand",
    label: "Business brand",
    icon: BusinessIcon,
  },
];

export default class Test extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      editDisabled: props.edit,
      active: "individual"
    };
  }
  handleClosePicker = () => {
    this.setState({ showPrimaryPicker: false, showContrastPicker: false });
  };
  handleOpenPrimaryPicker = () => {
    if (!this.state.editDisabled) {
      this.setState({ showPrimaryPicker: true });
    }
  };
  handleOpenContrastPicker = () => {
    if (!this.state.editDisabled) {
      this.setState({ showContrastPicker: true });
    }
  };
  handlePrimaryChangeComplete = (color) => {
    this.props.handleUpdate("primary_colour", color.hex);
  };
  handleContrastChangeComplete = (color) => {
    this.props.handleUpdate("contrast_colour", color.hex);
  };

  handleRemoveLogo = () => {
    this.props.handleUpdate("logo", null);
  };
  onDrop = async ([file]) => {
    this.setState({ uploading: true });
    try {
      const request = await uploadImage(file);
      this.setState({ uploading: false });
      this.props.handleUpdate("logo", request.secure_url);
    } catch (err) {
      this.setState({ uploading: false });
    }
  };

  handleSampleImgClick = (e) => {
    this.props.handleUpdate("logo", e.target.id);
  };

  handleEnableEdit = () => {
    this.setState({
      editDisabled: false,
    });
  };
  handleDiscard = () => {
    this.setState({
      editDisabled: true,
    });
  };

  handleOptionChange = (active) => {
    this.setState({
      active,
    });
  };

  handleLayoutChange = (e) => {
    this.props.handleUpdate("sitetheme", e.target.value);
  };
  render() {
    const {
      showPrimaryPicker,
      showContrastPicker,
      uploading,
      editDisabled,
      active
    } = this.state;
    const { data, edit, loading, handleUpdateData } = this.props;
    const { primary_colour, contrast_colour, logo, sitetheme } = data;
    const backgroundColor = primary_colour ? hex2rgb(primary_colour, 0.08) : "#fff";
    const avatarSelected = sampleImages.find((item) => item === logo);
    return (
      <Wrapper>
        <div className="titleWithEdit">
          <h2>{getTranslation(edit ? "appearance" : "setupSiteFor")}</h2>
          {edit && (
            <EditSection
              loading={loading}
              editEnabled={!editDisabled}
              handleEnableEdit={this.handleEnableEdit}
              handleDiscard={this.handleDiscard}
              handleUpdate={handleUpdateData}
            />
          )}
        </div>
        <Sections className="step1Sections">
          <div className="section logoSection">
            <div style={{ width: 296, marginBottom: 24 }}>
              <TabbedRadio
                active={active}
                items={items}
                hideCheck
                handleOptionChange={this.handleOptionChange}
              />
            </div>
            {(!logo || avatarSelected) && (
              <div style={{ width: 296 }}>
                <Dropzone onDrop={this.onDrop} accept="image/*">
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Button>
                          {getTranslation(
                            uploading ? "uploading" : "uploadFile"
                          )}
                        </Button>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
            )}
            {!edit && active === "individual" && (
              <div className="text" style={{ marginTop: 10 }}>
                <span>or choose from existing avatars:</span>
                <br />
                <div className="sampleImages">
                  {sampleImages.map((img, i) => (
                    <div
                      className={`image ${img === logo ? "active" : ""}`}
                      key={i}
                      id={img}
                      onClick={this.handleSampleImgClick}
                    >
                      <img src={resizedImage(img, 100)} alt="" id={img} />
                    </div>
                  ))}
                </div>
              </div>
            )}
            {!edit && !logo && (
              <div className="text" style={{ marginTop: 10 }}>
                <span>{getTranslation("getLogoExternalText")}
                  <a href="https://www.freelogodesign.org/" target="_blank">
                    https://www.freelogodesign.org/
                </a>
                </span>
              </div>
            )}
          </div>
          <div className="colors">
            <div className="section">
              <div className="colorWrapper">
                <div
                  className="colorDisplay"
                  onClick={this.handleOpenPrimaryPicker}
                  style={{ backgroundColor: primary_colour }}
                />
                <div className={`text ${primary_colour ? "withColor" : ""}`}>
                  <span className="label">
                    {getTranslation("primaryColor")}
                  </span>
                  {primary_colour && (
                    <span className="colorCode">{primary_colour}</span>
                  )}
                </div>
                {showPrimaryPicker && (
                  <div className="colorPopover">
                    <div
                      className="colorCover"
                      onClick={this.handleClosePicker}
                    />
                    <SketchPicker
                      color={primary_colour}
                      onChangeComplete={this.handlePrimaryChangeComplete}
                    />
                  </div>
                )}
              </div>
            </div>
            {logo && !avatarSelected && (
              <div className="section">
                <div className="logoWrapper">
                  <img src={resizedImage(logo, 100)} className="logo" alt="" />
                  {!editDisabled && (
                    <img
                      src={close}
                      className="close"
                      alt=""
                      onClick={this.handleRemoveLogo}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
          {primary_colour && (
            <div className="layouts">
              <h2>{getTranslation("chooseTheme")}</h2>
              <RadioGroup
                aria-label="gender"
                name="gender1"
                value={sitetheme}
                onChange={this.handleLayoutChange}
              >
                <FormControlLabel
                  value="EXPOSE"
                  control={<Radio />}
                  label={
                    <Layout
                      primary={primary_colour}
                      background={backgroundColor}
                    />
                  }
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="FOCUS"
                  control={<Radio />}
                  label={
                    <Layout primary={primary_colour} background="#EFEFEF" />
                  }
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="PLAIN"
                  control={<Radio />}
                  label={<Layout primary={primary_colour} />}
                  labelPlacement="top"
                />
              </RadioGroup>
            </div>
          )}
        </Sections>
      </Wrapper>
    );
  }
}
