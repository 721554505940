import React from "react";
import styled from "styled-components";
import { Wrapper, Sections } from "containers/Influencer/Setup/Styles";
import { Button } from "components/common";
import Posts from "./Posts";
import getTranslation from "utils/getTranslation";

const Container = styled.div`
  h2 {
    text-transform: capitalize;
  }
  .btnWrapper {
    width: 376px;
  }
  .section {
    width: 100%;
    .mainTitle {
      display: flex;
      justify-content: space-between;
      .time {
        margin-left: 5px;
        color: #444444;
        font-weight: 600;
      }
    }
    .rightSection {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 600;
    }
    .share {
      cursor: pointer;
      color: #007ac7;
      margin-right: 40px;
      display: flex;
      align-items: center;
    }
    .delete {
      cursor: pointer;
      display: flex;
      align-items: center;
      color: #eb5757;
      img {
        margin-right: 10px;
      }
    }
  }
`;

export default class extends React.PureComponent {
  handleTabChange = () => {
    this.props.handleTabChange("setup");
  };
  render() {
    const { influencerDetails, loading } = this.props;
    return (
      <Container>
        <Wrapper>
          {influencerDetails.influencerconnects.length === 0 ? (
            <>
              <h2>{getTranslation("getAccountSetup")}</h2>
              <div className="btnWrapper">
                <Button onClick={this.handleTabChange}>
                  {getTranslation("connectSocialNetwork")}
                </Button>
              </div>
            </>
          ) : (
            <Sections>
              <div className="section">
                <Posts
                  loading={loading}
                  influencerDetails={influencerDetails}
                />
              </div>
            </Sections>
          )}
        </Wrapper>
      </Container>
    );
  }
}
