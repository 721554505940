import React from 'react';
import styled from 'styled-components';
import { resetpassword } from 'utils/api';
import { Input, Button, Toast } from 'components/common';
import { getParameterByName } from 'utils/utils';
import getTranslation from 'utils/getTranslation';

const Wrapper = styled.div`
background-color: #fff;
padding-bottom: 50px;
min-height: -webkit-fill-available;
.head {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 20px 0;
  .item {
    font-family: 'Roboto Condensed', sans-serif;
    color: #444444;
    font-size: 16px;
    cursor: pointer;
  }
  .pipe {
    height: 22px;
    background-color: #777777;
    margin: 0 20px;
    width: 1px;
  }
}
`;
const InputWrapper = styled.div`
width: 327px;
  margin: auto;
  .forgotPass {
    text-align: center;
    margin-top: 20px;
    a {
      color: #2D9CDB;
      font-family: 'Roboto';
    }
  }
`;

class Reset extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            code: getParameterByName('code'),
            email: getParameterByName('email'),
            error: false,
            errors: {},
            password: '',
        };
    }

    handleValidations = () => (
        new Promise((resolve, reject) => {
            let hasErrors = false;
            const errors = {};
            if (this.state.password.length < 6) {
                hasErrors = true;
                errors.password = getTranslation('passwordSixChar');
            }
            if (this.state.password !== this.state.confirmPassword) {
                hasErrors = true;
                errors.confirmPassword = getTranslation('passwordDontMatch');
            }
            if (hasErrors) {
                reject(errors);
            } else {
                resolve(true);
            }
        })
    );

    handleChange = (e) => {
        let { name, value } = e.target;

        this.setState({
            [name]: value,
        });
    }

    handleReset = () => {
        this.handleValidations().then(async () => {
            this.setState({ loading: true, errors: {} });
            try {
                const resetpwd = await resetpassword(this.state);
                if (resetpwd.success) {
                    this.props.history.push('/login?reset=true')
                } else {
                    this.setState({ loading: false, error: true, errorMsg: resetpwd.message });
                }
            } catch (err) {
                console.log(err);
                this.setState({ loading: false, error: true, errorMsg: getTranslation('signupError') });
            }
        }, (errors) => {
            this.setState({
                errors,
            });
        });
    }


    handleClose = () => {
        this.setState({ error: false });
    }

    handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            if (this.props.id) {
                document.getElementById(this.props.id).blur();
            }
            e.preventDefault();
            this.handleReset();
        }
    }

    render() {
        const {
            password,
            confirmPassword,
            error,
            errors,
            errorMsg,
        } = this.state;
        return (
            <Wrapper>
                <React.Fragment>
                    <div>

                        <InputWrapper>
                            <div className="head">
                                <div className={`item`}>{getTranslation('resetpassword')}</div>
                            </div>
                            <Input
                                type="password"
                                name="password"
                                label={getTranslation('password')}
                                value={password}
                                errorText={errors.password}
                                onChange={this.handleChange}
                            />
                            <Input
                                type="password"
                                name="confirmPassword"
                                label={getTranslation('confirmPassword')}
                                value={confirmPassword}
                                errorText={errors.confirmPassword}
                                onChange={this.handleChange}
                                onKeyDown={this.handleKeyDown}
                            />
                            <div style={{ marginTop: 25 }}>
                                <Button onClick={this.handleReset}>{getTranslation('resetpassword')}</Button>
                            </div>
                        </InputWrapper>
                    </div>
                </React.Fragment>
                <Toast
                    open={error}
                    handleClose={this.handleClose}
                    message={errorMsg}
                />
            </Wrapper>
        )
    }
}

export default Reset;
