import React, { PureComponent } from 'react';
import styled from 'styled-components';
import Modal from 'components/common/Modal';
import CountryLocaleSelect from 'components/common/CountryLocaleSelect';
import getTranslation from 'utils/getTranslation';

const Wrapper = styled.div`
  text-align: left;
  color: #fff;
  font-size: 12px;
  background-color: #EEEEEE;
  h4 {
    margin-bottom: 10px;
  }
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    span {
      margin-left: 20px;
      font-size: 20px;
    }
  }
`;

export default class extends PureComponent {
  state = {
    countryChanged: false
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && this.props.open !== nextProps.open) {
      this.setState({
        countryChanged: false,
        doSubmit: false
      });
    }
  }

  handleClose = () => {
    this.props.handleCloseModal();
  };

  handleCountryChange = () => {
    this.setState({
      countryChanged: true
    });
  }

  handleCheckClick = () => {
    this.setState({
      doSubmit: true
    });
  }

  render() {
    const { countryChanged, doSubmit } = this.state;
    const {
      open,
    } = this.props;
    return (
      <Modal
        open={open}
        title={getTranslation('switchCountry')}
        handleCloseModal={this.handleClose}
        handleCheckClick={this.handleCheckClick}
        showChecked={countryChanged}
        dialogStyle={{ width: '100%', maxWidth: '100%', maxHeight: '100%', minHeight: '100%', margin: 0 }}
      >
        <Wrapper>
          <CountryLocaleSelect
            doSubmit={doSubmit}
            countryChange={this.handleCountryChange}
            handleCloseModal={this.handleClose}
          />
        </Wrapper>
      </Modal>

    );
  }
}
