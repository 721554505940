import React from "react";
import styled from "styled-components";
import { getItem } from "utils/utils";

const Wrapper = styled.div`
  .bold {
    font-weight: 400;
    letter-spacing: 1px;
    color: ${(props) => props.theme.primary};
  }
  .faded {
    color: #dddddd;
  }
`;

export default ({ hopRequest }) => {
  function addArrayItems(length) {
    let array = [];
    for (let i = 0; i < length; i++) {
      array = [...array, i];
    }
    return array;
  }
  const currencyDisplay = getItem("selectedCurrencySymbol");
  return (
    <Wrapper>
      <span className="bold">
        {addArrayItems(hopRequest.hopSegmentControls[0].dollarRating).map(
          () => currencyDisplay
        )}
      </span>
      {hopRequest.hopSegmentControls[0].dollarRating < 5 && (
        <span className="faded">
          {addArrayItems(5 - hopRequest.hopSegmentControls[0].dollarRating).map(
            () => currencyDisplay
          )}
        </span>
      )}
    </Wrapper>
  );
};
