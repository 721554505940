import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { graphql, compose } from "react-apollo";
import { updateInfluencer } from "containers/Influencer/queries";
import { setInfluencer } from "redux-store/actions";
import Appearance from "containers/Influencer/Setup/Step1/Partials/Appearance";
import { getItem } from "utils/utils";

const Wrapper = styled.div``;

class AppearanceComp extends React.PureComponent {
  constructor(props) {
    super(props);
    const { contrast_colour, primary_colour, logo, sitetheme } =
      props.influencerDetails || {};
    this.state = {
      data: {
        contrast_colour,
        primary_colour,
        logo,
        sitetheme
      }
    };
  }
  handleUpdate = (name, value) => {
    this.setState({
      data: {
        ...this.state.data,
        [name]: value
      }
    });
  };

  handleUpdateData = async () => {
    try {
      this.setState({ loading: true });
      let response = await this.props.updateInfluencer({
        variables: {
          _set: {
            ...this.state.data
          },
          where: {
            fsuseraccount_id: { _eq: getItem("useraccount_id") }
          }
        }
      });
      this.props.setInfluencer(response.data.update_influencer.returning[0]);
      this.setState({ loading: false });
    } catch (err) {
      console.log(err);
      this.setState({ loading: false });
    }
  };

  render() {
    const { data, loading } = this.state;
    return (
      <Wrapper>
        <Appearance
          edit
          loading={loading}
          data={data}
          handleUpdate={this.handleUpdate}
          handleUpdateData={this.handleUpdateData}
        />
      </Wrapper>
    );
  }
}

export default compose(
  graphql(updateInfluencer, { name: "updateInfluencer" }),
  connect(null, { setInfluencer })
)(AppearanceComp);
