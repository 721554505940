import React, { PureComponent } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { graphql, compose } from "react-apollo";
import { isMobile } from "react-device-detect";
import moment from "moment";
import { updateConnectPostRefetch } from "redux-store/actions";
import { Button } from "components/common";
import Modal from "components/Influencer/TipModal";
import { PreviewAndShare } from "components/Influencer/Partials";
import { deleteConnectPost } from "containers/Influencer/queries";
import getTranslation from "utils/getTranslation";
import deleteIcon from "assets/tip/Delete.svg";

const Wrapper = styled.div`
  width: 880px;
  background-color: #fff;
  padding: 24px 24px 0 24px;
  position: relative;
  @media (max-width: 800px) {
    width: 100%;
    height: 100%;
  }
`;

const CustomButton = styled.button`
  border: 0;
  background-color: transparent;
  height: 32px;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  margin-right: 8px;
  min-width: fit-content;
  color: #007ac7;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  text-transform: uppercase;
  outline: none;
  cursor: pointer;
  &.active {
    background-color: #fff;
    border: 2px solid #56ccf2;
    border-radius: 8px;
  }
`;

const Delete = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #eb5757;
  font-family: "Roboto Condensed", sans-serif;
  margin-right: 10px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  min-width: fit-content;
  img {
    margin-right: 5px;
  }
`;

const MenuItems = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(238, 238, 238, 0.8);
  padding: 20px;
  top: 0;
  left: 0;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  .items {
    border-radius: 13px;
    width: 100%;
    background-color: #fff;
    .item {
      text-align: center;
      padding: 18px 0;
      color: #007ac7;
      border-bottom: 0.5px solid rgba(17, 17, 17, 0.5);
      &.remove {
        color: #eb5757;
      }
      &:last-child {
        border-bottom: 0;
      }
    }
  }
`;

class ShareModal extends PureComponent {
  state = {
    doShare: false,
    openMenu: false
  };

  handleLayoutChange = selectedLayout => {
    this.setState({ selectedLayout });
  };

  showShareNow = () => {
    this.setState({
      shareNowView: true,
      shareAtView: false,
      recurrenceView: false
    });
  };

  showRecurrence = () => {
    this.setState({
      shareNowView: false,
      shareAtView: false,
      recurrenceView: true
    });
  };

  showShareAt = () => {
    this.setState({
      shareAtView: true,
      recurrenceView: false,
      shareNowView: false
    });
  };

  handleSubmit = () => {
    this.setState({
      doShare: true,
      loading: true
    });
  };

  handleShareNow = async () => {
    this.setState({ doShareNow: true, loading: true });
  };

  handleMenuToggle = () => {
    this.setState({ openMenu: !this.state.openMenu });
  };

  handleRemoveConnectPost = async () => {
    const { connectPost } = this.props;
    this.setState({ loading: true });
    try {
      await this.props.deleteConnectPost({
        variables: {
          where: {
            post_id: { _eq: connectPost.post_id },
            shareschedule_at: { _eq: connectPost.shareschedule_at }
          }
        }
      });
      this.props.updateConnectPostRefetch(moment().unix());
      this.props.handleCloseModal();
    } catch (err) {
      //
    }
  };

  handleRemoveRecurrence = async () => {
    const { connectPost } = this.props;
    this.setState({ loading: true });
    try {
      await this.props.deleteConnectPost({
        variables: {
          where: {
            recurpattern_id: { _eq: connectPost.recurpattern_id }
          }
        }
      });
      this.props.updateConnectPostRefetch(moment().unix());
      this.props.handleCloseModal();
    } catch (err) {
      //
    }
  };

  render() {
    const {
      doShare,
      loading,
      recurrenceView,
      shareAtView,
      doShareNow,
      openMenu
    } = this.state;
    const {
      open,
      handleCloseModal,
      blogData,
      connectPost,
      handleSubmit,
      isEdit,
      deleteItem
    } = this.props;
    const { recurpattern } = connectPost || {};
    return (
      <Modal
        open={open}
        text1={
          !isEdit
            ? getTranslation("shareSelectedPost")
            : getTranslation(recurpattern ? "editRecurrence" : "editQueueItem")
        }
        submitText={getTranslation(recurpattern ? "saveChanges" : "addToQueue")}
        handleCloseModal={handleCloseModal}
        loading={loading}
        additionalButtons={
          !isMobile ? (
            <>
              {(!isEdit || !recurpattern) && (
                <>
                  <CustomButton
                    disabled={loading}
                    className={shareAtView ? "active" : ""}
                    onClick={this.showShareAt}
                  >
                    {getTranslation("shareAt")}
                  </CustomButton>
                  <CustomButton
                    disabled={loading}
                    className={recurrenceView ? "active" : ""}
                    onClick={this.showRecurrence}
                  >
                    {getTranslation("recurrence")}
                  </CustomButton>
                  {!isEdit && (
                    <Button
                      disabled={loading}
                      onClick={this.handleShareNow}
                      color="#fff"
                      style={{
                        height: 36,
                        color: "#007AC7",
                        marginRight: 8,
                        minWidth: "fit-content"
                      }}
                    >
                      {getTranslation("shareNow")}
                    </Button>
                  )}
                </>
              )}
              {((isEdit && !recurpattern) || deleteItem) && (
                <Delete onClick={this.handleRemoveConnectPost}>
                  <img src={deleteIcon} alt="" />
                  <span>{getTranslation("remove")}</span>
                </Delete>
              )}
              {isEdit && recurpattern && (
                <Delete onClick={this.handleRemoveRecurrence}>
                  <img src={deleteIcon} alt="" />
                  <span>{getTranslation("removeRecur")}</span>
                </Delete>
              )}
            </>
          ) : (
            <Button
              color="#fff"
              style={{
                height: 36,
                color: "#007AC7",
                marginRight: 8,
                minWidth: "fit-content"
              }}
              onClick={this.handleMenuToggle}
            >
              More
            </Button>
          )
        }
        handleSubmit={this.handleSubmit}
        btnWidth={"fit-content"}
        dialogStyle={{ width: 880, maxWidth: 880 }}
      >
        <Wrapper>
          {openMenu && (
            <MenuItems onClick={this.handleMenuToggle}>
              <div className="items">
                {(!isEdit || !recurpattern) && (
                  <>
                    <div className="item" onClick={this.showShareAt}>
                      {getTranslation("shareAt")}
                    </div>
                    <div className="item" onClick={this.showRecurrence}>
                      {getTranslation("recurrence")}
                    </div>
                    {!isEdit && (
                      <div className="item" onClick={this.handleShareNow}>
                        {getTranslation("shareNow")}
                      </div>
                    )}
                  </>
                )}
                {((isEdit && !recurpattern) || deleteItem) && (
                  <div
                    className="item remove"
                    onClick={this.handleRemoveConnectPost}
                  >
                    {getTranslation("remove")}
                  </div>
                )}
                {isEdit && recurpattern && (
                  <div
                    className="item remove"
                    onClick={this.handleRemoveRecurrence}
                  >
                    {getTranslation("removeRecur")}
                  </div>
                )}
              </div>
            </MenuItems>
          )}
          <PreviewAndShare
            isModal
            doShareNow={doShareNow}
            doShare={doShare}
            blogData={blogData}
            connectPost={connectPost}
            handleSubmit={handleSubmit}
            isEdit={isEdit}
            showRecurrence={recurrenceView}
            showSharAt={shareAtView}
            handleCloseModal={handleCloseModal}
          />
        </Wrapper>
      </Modal>
    );
  }
}

export default compose(
  graphql(deleteConnectPost, { name: "deleteConnectPost" }),
  connect(null, { updateConnectPostRefetch })
)(ShareModal);
