import React from "react";
import styled from 'styled-components';
import getTranslation from 'utils/getTranslation';
import { removeItem } from 'utils/utils';
import errorIcon from 'assets/icons/Error.svg';

const Error = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 30px;
  max-width: 492px;
  margin: auto;
  h5 {
    color: #444444;
    font-size: 18px;
    font-weight: 400;
    line-height: 150%;
  }
`;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    // send a tracking call to Mixpanel with a Page Error event
    console.log('Error caught' + error);
    // removeItem('tripId');
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Error>
          <img src={errorIcon} alt="" />
          <h5 style={{
            color: '#222222',
            padding: 20,
            fontSize: 16,
            textAlign: 'center',
          }}
          >
            {getTranslation('errorContactSupport')}
          </h5>
      </Error>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
