import React from 'react';
import { Query } from 'react-apollo';
import { xairportByIataLike } from '../queries';

export default ({
  airportIc,
}) => (
  <Query query={xairportByIataLike} variables={{ iata: `${airportIc}%` }}>
    {({ data }) => (
      data.xairportByIataLike ?
        <React.Fragment>
          <h2>{data.xairportByIataLike[0].city}</h2>
          <span>
            {data.xairportByIataLike[0].airportDesc} ({airportIc})
          </span>
        </React.Fragment> : null
    )}
  </Query>
);
