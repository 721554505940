import React from "react";
import styled from "styled-components";
import { ConnectIcon } from "components/Influencer/Common";
import facebook from "assets/icons/facebook.svg";
import twitter from "assets/icons/twitter.svg";

const Wrapper = styled.div`
  display: flex;
  margin-top: 10px;
  .item {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    margin-right: 10px;
  }
`;

export default ({ connects, style = {} }) => (
  <Wrapper className="socialNetworks">
    {connects.map(connect => (
      <ConnectIcon connect={{ ...connect, selected: true }} key={connect.id} />
    ))}
    {connects.length < 2 && (
      <>
        <ConnectIcon
          connect={{
            connect:
              connects[0].connect === "FACEBOOK" ? "twitter" : "facebook",
            selected: false
          }}
        />
      </>
    )}
  </Wrapper>
);
