import React from "react";
import styled from "styled-components";
import moment from "moment";
import Slider from "@material-ui/core/Slider";
import { graphql, compose } from "react-apollo";
import DateTimePicker from "components/common/DateTimePicker";
import { DayIcon, WeekIcon, MonthIcon } from "components/common/SvgIcons";
import { BtnCounter } from "components/common";
import {
  insertRecurPattern,
  updateRecurPattern
} from "containers/Influencer/queries";
import { getRecurrences } from "containers/Influencer/Dashboard/Connect/Partials/helpers";
import TabbedRadio from "./TabbedRadio";
import getTranslation from "utils/getTranslation";

const Wrapper = styled.div`
  width: 100%;
  .datesWrapper {
    display: flex;
    justify-content: space-between;
    .inputWrapper {
      width: 216px;
    }
  }
  .counter {
    width: 200px;
    margin: auto;
    margin-top: 30px;
  }
  .repeatWrapper {
    margin-top: 30px;
  }
  .recurrencesWrapper {
    margin-top: 30px;
    text-align: center;
    .MuiSlider-thumb {
      background-color: #ec9719;
      .MuiSlider-valueLabel .PrivateValueLabel-circle-211 {
        background-color: #ec9719 !important;
        span {
          color: #fff;
        }
      }
    }
    .MuiSlider-thumb.Mui-focusVisible,
    .MuiSlider-thumb:hover {
      box-shadow: 0px 0px 0px 8px rgba(236, 151, 25, 0.16);
    }
    .MuiSlider-markActive,
    .PrivateValueLabel-circle-211 {
      background-color: #007ac7;
    }
  }
`;

const items = [
  {
    name: "day",
    label: "Day",
    short: "d",
    icon: DayIcon
  },
  {
    name: "week",
    label: "Week",
    short: "w",
    icon: WeekIcon
  },
  {
    name: "month",
    label: "Month",
    short: "M",
    icon: MonthIcon
  }
];

const marks = [
  { value: 1 },
  { value: 5 },
  { value: 10 },
  { value: 15 },
  { value: 20 },
  { value: 25 },
  { value: 30 },
  { value: 35 },
  { value: 40 },
  { value: 45 },
  { value: 50 }
];

let timeout;

class PatternRecurrence extends React.PureComponent {
  constructor(props) {
    super(props);
    const { recurpattern } = props.connectPost ? props.connectPost : {};
    const recurrences = props.isEdit
      ? getRecurrences(props.connectPost, props.connectPosts)
      : [];
    this.state = {
      startDate: props.isEdit ? moment(recurrences[0]) : moment(),
      endDate: props.isEdit
        ? moment(recurrences[recurrences.length - 1])
        : null,
      recurrenceFrequency: props.isEdit ? recurpattern.recur_frequency : 1,
      repeatType: props.isEdit ? recurpattern.recur_type.toLowerCase() : "day",
      recurrenceCount: props.isEdit ? recurrences.length : 1
    };
  }

  componentWillMount() {
    if (this.props.isEdit) {
      return;
    }
    const payload = {
      recurrenceCount: this.state.recurrenceCount,
      recurrenceFrequency: this.state.recurrenceFrequency,
      repeatType: this.state.repeatType
    };
    const endDate = this.getEndDate(payload);
    this.setState({
      endDate
    });
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.submitPattern &&
      this.props.submitPattern !== nextProps.submitPattern &&
      !this.props.isEdit
    ) {
      this.handleSubmit();
    }
    if (
      nextProps.submitPattern &&
      this.props.submitPattern !== nextProps.submitPattern &&
      this.props.isEdit
    ) {
      this.handleUpdate();
    }
  }

  getEndDate = payload => {
    const { recurrenceCount, recurrenceFrequency, repeatType } = payload;
    if (recurrenceCount === 1) {
      return this.state.startDate;
    } else {
      const repeatItem = items.find(item => item.name === repeatType);
      const endDate = moment().add(
        recurrenceFrequency * (recurrenceCount - 1),
        repeatItem.short
      );
      return endDate;
    }
  };

  handleStartDateChange = startDate => {
    this.setState({
      startDate
    });
  };
  handleIncrease = () => {
    const payload = {
      recurrenceCount: this.state.recurrenceCount,
      recurrenceFrequency: this.state.recurrenceFrequency + 1,
      repeatType: this.state.repeatType
    };
    const endDate = this.getEndDate(payload);
    this.setState({
      recurrenceFrequency: this.state.recurrenceFrequency + 1,
      endDate
    });
  };
  handleDecrease = () => {
    if (this.state.recurrenceFrequency > 1) {
      const payload = {
        recurrenceCount: this.state.recurrenceCount,
        recurrenceFrequency: this.state.recurrenceFrequency - 1,
        repeatType: this.state.repeatType
      };
      const endDate = this.getEndDate(payload);
      this.setState({
        recurrenceFrequency: this.state.recurrenceFrequency - 1,
        endDate
      });
    }
  };
  handleOptionChange = repeatType => {
    const payload = {
      recurrenceCount: this.state.recurrenceCount,
      recurrenceFrequency: this.state.recurrenceFrequency,
      repeatType: repeatType
    };
    const endDate = this.getEndDate(payload);
    this.setState({ repeatType, endDate });
  };

  handleRecurrenceChange = (e, value) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      const payload = {
        recurrenceCount: value,
        recurrenceFrequency: this.state.recurrenceFrequency,
        repeatType: this.state.repeatType
      };
      const endDate = this.getEndDate(payload);
      this.setState({
        recurrenceCount: value,
        endDate
      });
    }, 500);
  };

  getPostRecurrences = () => {
    const {
      recurrenceCount,
      startDate,
      repeatType,
      recurrenceFrequency
    } = this.state;
    let recurrences = [startDate];
    const repeatItem = items.find(item => item.name === repeatType);
    for (var i = 1; i < recurrenceCount; i++) {
      const date = moment(startDate).add(
        recurrenceFrequency * i,
        repeatItem.short
      );
      recurrences = [...recurrences, date];
    }
    return recurrences;
  };

  handleSubmit = async () => {
    const recurrences = this.getPostRecurrences();
    const {
      startDate,
      endDate,
      recurrenceCount,
      recurrenceFrequency,
      repeatType
    } = this.state;
    try {
      const payload = {
        first_recur_at: moment(startDate).format(),
        last_recur_at: moment(endDate).format(),
        recur_count: recurrenceCount,
        recur_frequency: recurrenceFrequency,
        recur_type: repeatType.toUpperCase()
      };
      const response = await this.props.insertRecurPattern({
        variables: {
          objects: [payload]
        }
      });
      this.props.handleInsertConnectPosts(
        recurrences,
        response.data.insert_recurpattern.returning[0].id
      );
    } catch (err) {
      console.log(err);
    }
  };

  handleUpdate = async () => {
    const recurrences = this.getPostRecurrences();
    const {
      startDate,
      endDate,
      recurrenceCount,
      recurrenceFrequency,
      repeatType
    } = this.state;
    try {
      const { recurpattern_id } = this.props.connectPost;
      const payload = {
        first_recur_at: moment(startDate).format(),
        last_recur_at: moment(endDate).format(),
        recur_count: recurrenceCount,
        recur_frequency: recurrenceFrequency,
        recur_type: repeatType.toUpperCase()
      };
      await this.props.updateRecurPattern({
        variables: {
          _set: payload,
          where: {
            id: { _eq: recurpattern_id }
          }
        }
      });
      this.props.handleUpdateConnectPosts(recurrences, recurpattern_id);
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    const {
      startDate,
      endDate,
      recurrenceFrequency,
      repeatType,
      recurrenceCount
    } = this.state;
    const { isEdit } = this.props;
    return (
      <Wrapper>
        <div className="datesWrapper">
          <div className="inputWrapper">
            <div className="label">{getTranslation("firstRecurrence")}</div>
            <DateTimePicker
              date={startDate}
              disabled={isEdit}
              handleDateChange={this.handleStartDateChange}
            />
          </div>
          <div className="inputWrapper">
            <div className="label">{getTranslation("lastRecurrence")}</div>
            <DateTimePicker
              date={endDate}
              disabled
              handleDateChange={this.handleEndDateChange}
            />
          </div>
        </div>
        <div className="counter">
          <div className="label">{getTranslation("repeatEvery")}</div>
          <BtnCounter
            count={recurrenceFrequency}
            handleDecrease={this.handleDecrease}
            handleIncrease={this.handleIncrease}
          />
        </div>
        <div className="repeatWrapper">
          <TabbedRadio
            active={repeatType}
            items={items}
            handleOptionChange={this.handleOptionChange}
          />
        </div>
        <div className="recurrencesWrapper">
          <div className="label">{getTranslation("maxRecurrences")}</div>
          <Slider
            defaultValue={recurrenceCount}
            onChange={this.handleRecurrenceChange}
            aria-labelledby="discrete-slider-custom"
            step={1}
            max={50}
            min={1}
            valueLabelDisplay="auto"
            marks={marks}
          />
        </div>
      </Wrapper>
    );
  }
}

export default compose(
  graphql(insertRecurPattern, { name: "insertRecurPattern" }),
  graphql(updateRecurPattern, { name: "updateRecurPattern" })
)(PatternRecurrence);
