import styled from "styled-components";

export const DestinationLabel = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 10px 5px 5px;
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : "rgba(255, 255, 255, 0.9)"};
  border-top-right-radius: 10em;
  border-bottom-right-radius: 10em;
  margin-right: 10px;
  color: ${props => (props.color ? props.color : "#3f8c52")};
  font-size: 14px;
  text-transform: uppercase;
  img {
    margin-right: 5px;
  }
`;

export const DateWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99;
  background-color: rgba(240, 240, 240, 0.9);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  border-bottom-left-radius: 16px;
  height: 44px;
  width: 48px;
  color: #444444;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  span {
    line-height: 100%;
    font-weight: 300;
  }
  .date {
    font-size: 22px;
    line-height: 100%;
  }
  .month {
    font-size: 12px;
    text-transform: uppercase;
  }
`;
