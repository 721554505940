import gql from "graphql-tag";

export const addHOPRequest = gql`
  mutation addHoprequest(
    $hopSeqNum: Int!
    $adultCount: Int!
    $childCount: Int!
    $infantCount: Int!
    $stdDate: String!
    $fromAirportIc: String!
    $toAirportIc: String!
    $tripId: Int!
  ) {
    addHoprequest(
      hopSeqNum: $hopSeqNum
      adultCount: $adultCount
      childCount: $childCount
      infantCount: $infantCount
      stdDate: $stdDate
      fromAirportIc: $fromAirportIc
      toAirportIc: $toAirportIc
      tripId: $tripId
    ) {
      id
    }
  }
`;
export const addHopSegemntControl = gql`
  mutation addHopSegmentControlsForHoprequest($hoprequestId: Int!) {
    addHopsegmentcontrolsForHoprequest(hoprequestId: $hoprequestId) {
      id
      seatClass
      cancellationPenalty
      dateChangePenalty
      seatChoice
      baggagePieceCount
      baggagePieceMaxWeight
      baggagePieceImperial
    }
  }
`;

export const updTripItineraryQuery = gql`
  mutation updTripItinerary($tripId: Int!, $priceNum: Int) {
    updTripItinerary(tripId: $tripId, priceNum: $priceNum) {
      priceNum
      modifiedDttm
      countryIc
      splitBooking
      passportDetails
      hopRequests {
        id
        hopSeqNum
        adultCount
        childCount
        infantCount
        stdDate
        fromAirportIc
        toAirportIc
        stopCount
        hopSegmentControls {
          id
          fscode
          paxnameCharLimit
          bcUndefined
          airlinePnr
          seatClass
          cancellationPenalty
          dateChangePenalty
          seatChoice
          baggagePieceCount
          baggagePieceMaxWeight
          baggagePieceImperial
          webfare
          costDecimalPlaces
          costCurrencyIso
          transferValue
          refundValue
          extraBaggages {
            id
            createdDttm
            modifiedDttm
            inbound
            baggagePieceCount
            baggagePieceMaxWeight
            baggagePieceImperial
            serviceId
            serviceCostAmount
            serviceCostCurrencyIso
            costDecimalPlaces
            quantity
            hopsegmentcontrolId
            passengerNum
            passengerId
          }
          farerules {
            id
            category
            detail
          }
          priceBreakups {
            id
            cost
            costCurrencyIso
            costDecimalPlaces
            passengerTyp
            isTax
            taxCode
            taxName
          }
          flightSegments {
            airlineIc
            flightNum
            fromAirportIc
            stdDate
            stdTime
            toAirportIc
            staDate
            staTime
            aircraftIcao
            durationMins
          }
          dollarRating
        }
        passengers {
          id
          title
          firstName
          lastName
          birthdate
          gender
          nationality
          passportCountry
          passportExpdate
          passportNumber
          cardHolder
          cardHoldername
          cardHoldercountryic
          extraBaggages {
            id
            baggagePieceCount
            baggagePieceMaxWeight
          }
        }
      }
      filterKvs {
        id
        filterKey
        value
        tripId
      }
      tripCostItems {
        cost
        costItemLabel
        costItemCurrencyIso
        costDecimalPlaces
      }
    }
  }
`;

export const getTripById = gql`
  query tripById($id: Int!) {
    tripById(id: $id) {
      priceNum
      paidlockDttm
      splitBooking
      passportDetails
      eticket
      hopRequests {
        id
        hopSeqNum
        adultCount
        childCount
        infantCount
        stdDate
        fromAirportIc
        toAirportIc
        stopCount
        hopSegmentControls {
          id
          fscode
          bcUndefined
          paxnameCharLimit
          airlinePnr
          seatClass
          cancellationPenalty
          dateChangePenalty
          seatChoice
          baggagePieceCount
          baggagePieceMaxWeight
          baggagePieceImperial
          webfare
          costDecimalPlaces
          costCurrencyIso
          transferValue
          refundValue
          extraBaggages {
            id
            createdDttm
            modifiedDttm
            inbound
            baggagePieceCount
            baggagePieceMaxWeight
            baggagePieceImperial
            serviceId
            serviceCostAmount
            serviceCostCurrencyIso
            costDecimalPlaces
            quantity
            hopsegmentcontrolId
            passengerNum
            passengerId
          }
          flightSegments {
            airlineIc
            flightNum
            fromAirportIc
            stdDate
            stdTime
            toAirportIc
            staDate
            staTime
            aircraftIcao
            durationMins
          }
          dollarRating
          farerules {
            id
            category
            detail
          }
          priceBreakups {
            id
            cost
            costCurrencyIso
            costDecimalPlaces
            passengerTyp
            isTax
            taxCode
            taxName
          }
        }
        passengers {
          id
          title
          firstName
          lastName
          birthdate
          gender
          nationality
          passportCountry
          passportExpdate
          passportNumber
          cardHolder
          cardHoldername
          cardHoldercountryic
          extraBaggages {
            id
            baggagePieceCount
            baggagePieceMaxWeight
          }
        }
      }
      filterKvs {
        id
        filterKey
        value
        tripId
      }
      tripCostItems {
        cost
        costItemLabel
        costItemCurrencyIso
        costDecimalPlaces
      }
    }
  }
`;

export const updTripFareruleQuery = gql`
  mutation updTripFarerule($tripId: Int!) {
    updTripFarerule(tripId: $tripId) {
      hopRequests {
        id
        fromAirportIc
        toAirportIc
        hopSegmentControls {
          id
          farerules {
            id
            category
            detail
          }
          flightSegments {
            airlineIc
          }
        }
      }
    }
  }
`;

export const disableSplitBooking = gql`
  mutation disableSplitBooking($tripId: Int!) {
    disableSplitBooking(tripId: $tripId) {
      id
      splitBooking
    }
  }
`;

export const enableSplitBooking = gql`
  mutation enableSplitBooking($tripId: Int!) {
    enableSplitBooking(tripId: $tripId) {
      id
      splitBooking
    }
  }
`;
