import React from "react";
import styled from "styled-components";
import { Sections } from "containers/Influencer/Setup/Styles";
import { ItemTitle, DestinationsInput } from "components/Influencer";
import getTranslation from "utils/getTranslation";
import BlogPost from "components/Influencer/PostTypes/BlogPost";
import TravelItenerary from "components/Influencer/PostTypes/TravelItenerary";
import QuickPost from "components/Influencer/PostTypes/QuickPost";
import {
  QuickPostIcon,
  BlogPostIcon,
  ItineraryPostIcon
} from "components/common/SvgIcons";
import PostType from "./PostType";

const Wrapper = styled.div`
  .sections {
    flex-flow: column;
  }
  .section {
    width: 100%;
    .postTypes {
      margin: 20px 0;
      display: flex;
      justify-content: space-between;
      background-color: #d0f3fc;
      max-width: 336px;
      padding: 5px;
      border-radius: 6px;
      .postType {
        cursor: pointer;
        display: flex;
        align-items: center;
        font-family: "Roboto Condensed", sans-serif;
        flex-flow: column;
        color: #007ac7;
        font-size: 16px;
        width: 100px;
        padding: 5px;
        img {
          margin-bottom: 20px;
        }
        &.active {
          background-color: #fff;
          border-radius: 6px;
          span {
            color: #222222;
          }
          svg {
            display: block;
          }
        }
      }
    }
  }
`;

const types = [
  {
    id: 1,
    name: getTranslation("quickPost"),
    text: getTranslation("quickPostText"),
    type: "QUICK",
    image: QuickPostIcon
  },
  {
    id: 2,
    name: getTranslation("travelBlog"),
    text: getTranslation("travelBlogText"),
    type: "BLOG",
    image: BlogPostIcon
  },
  {
    id: 3,
    name: getTranslation("itinerary"),
    text: getTranslation("travelItineraryText"),
    type: "ITINERARY",
    image: ItineraryPostIcon
  }
];

export default class extends React.PureComponent {
  state = { selectedType: null };
  handleSelect = selectedType => {
    this.props.handleChange("type", selectedType);
  };

  handleEditorChange = data => {
    this.props.handleChange("text", data);
  };

  handleDestinationChange = destinations => {
    this.props.handleDestinationChange(destinations);
  };
  render() {
    const { data, handleChange, handleItineraryChange } = this.props;
    const { text, type: selectedType, title } = data;
    return (
      <Wrapper>
        <Sections className="sections">
          <div className="section">
            <ItemTitle title={getTranslation("chooseLayout")} />
            <div className="postTypes">
              {types.map(type => (
                <PostType
                  key={type.id}
                  selectedType={selectedType}
                  type={type}
                  handleSelect={this.handleSelect}
                />
              ))}
            </div>
          </div>
          {selectedType === "QUICK" && (
            <QuickPost title={title} text={text} handleChange={handleChange} />
          )}
          {selectedType === "BLOG" && (
            <BlogPost
              html={text}
              title={title}
              handleEditorChange={this.handleEditorChange}
              handleChange={handleChange}
            />
          )}
          {selectedType === "ITINERARY" && (
            <TravelItenerary
              handleChange={handleChange}
              handleItineraryChange={handleItineraryChange}
              handleDestinationChange={this.handleDestinationChange}
              title={title}
              data={data}
            />
          )}
          {selectedType === "BLOG" && (
            <DestinationsInput
              style={{ marginTop: 30 }}
              handleChange={this.handleDestinationChange}
              postdestinations={data.postdestinations}
            />
          )}
        </Sections>
      </Wrapper>
    );
  }
}
