import React from "react";
import { Button } from "components/common";
import { TIPForwardIcon } from "components/common/SvgIcons";
import { hex2rgb } from "utils/utils";

export default ({ color }) => {
  const backgroundColor = hex2rgb(color || "#3F8C52", 0.16);
  return (
    <div
      style={{
        height: 32,
        width: 64,
        position: "absolute",
        bottom: 12,
        right: 12
      }}
    >
      <Button
        style={{
          height: 32
        }}
        color={backgroundColor}
      >
        <TIPForwardIcon stroke={color || "#3F8C52"} />
      </Button>
    </div>
  );
};
