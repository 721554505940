import React from "react";
import styled from "styled-components";
import moment from "moment";
import { isMobile } from "react-device-detect";
import { connect } from "react-redux";
import { graphql, compose, withApollo } from "react-apollo";
import { insertConnectPost } from "containers/Influencer/queries";
import { Wrapper } from "containers/Influencer/Setup/Styles";
import { Button } from "components/common";
import { ItemTitle } from "components/Influencer";
import { BlogPostCard } from "components/Influencer/BlogCards";
import DateTimePicker from "components/common/DateTimePicker";
import { ConnectIcon, DomainLink } from "components/Influencer/Common";
import Recurrence from "./Recurrence";
import getTranslation from "utils/getTranslation";
import { getTipDomain } from "utils/utils";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 800px) {
    flex-flow: column;
  }
  .leftSection {
    width: 456px;
    @media (max-width: 800px) {
      width: 100%;
    }
  }
  .rightSection {
    width: 296px;
    @media (max-width: 800px) {
      width: 100%;
    }
  }
  .separator {
    height: auto;
    width: 1px;
    margin: 0 90px;
    background-color: #dddddd;
  }
  .textareaWrapper {
    position: relative;
    textarea {
      background-color: #f0f0f0;
      height: 174px;
    }
    span {
      position: absolute;
      right: 16px;
      bottom: 16px;
      font-size: 12px;
      color: #777777;
    }
  }
  .connectedChannels {
    margin: 0 0 20px 0;
    display: flex;
    align-items: center;
    .count {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #fff;
      background-color: #ec9719;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin-right: 15px;
    }
    span {
      color: #777777;
      font-size: 16px;
      line-height: 26px;
      font-family: "Roboto Condensed", sans-serif;
      text-transform: uppercase;
    }
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  background: #ffffff;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  resize: none;
  padding: 16px;
  outline: none;
  font-size: 14px;
  &.active {
    border: 2px solid #56ccf2;
    box-shadow: 0px 0px 6px rgba(86, 204, 242, 0.9);
  }
`;

class PreviewAndShare extends React.PureComponent {
  constructor(props) {
    super(props);
    const { sharenote } = props.connectPost || {};
    const influencerconnects = this.props.influencerDetails.influencerconnects.map(
      item => ({
        ...item,
        selected: true
      })
    );
    const postUrl = this.getPostUrl(props.blogData.id);
    this.state = {
      postId: props.blogData.id,
      publishText: sharenote ? sharenote.replace(postUrl, "") : "",
      wordLimit: 140 - postUrl.length,
      shareAt: null,
      influencerconnects
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.doShare && this.props.doShare !== nextProps.doShare) {
      if (this.props.showRecurrence) {
        this.setState({
          submitRecurrence: true
        });
        return;
      }
      if (this.props.showShareAt) {
        this.handleShareAt();
        return;
      }
      this.handleShare();
    }
    if (
      nextProps.doShareNow &&
      this.props.doShareNow !== nextProps.doShareNow
    ) {
      this.handleShareNow();
    }
  }
  handleChange = e => {
    const { name, value } = e.target;
    const wordLimit = 140 - this.getPostUrl().length;
    if (value.length > wordLimit) {
      return;
    }
    this.setState({
      [name]: value,
      wordLimit: wordLimit - value.length
    });
  };

  handleDateChange = date => {
    this.setState({ shareAt: date });
  };

  getPostUrl = postId =>
    `https://${getTipDomain(this.props.influencerDetails)}/post/${postId ||
      this.state.postId}`;

  handleShare = async () => {
    this.setState({ true: false });
    const { influencerconnects } = this.state;
    const { created_at } = this.props.influencerDetails;
    const url = this.getPostUrl();
    const min = parseInt(moment(created_at).format("mm"), 10);
    const currentMin = parseInt(moment().format("mm"), 10);
    const minDiff1 = Math.abs(currentMin - min);
    const minDiff2 = Math.abs(currentMin - (min + 60));
    const minimumMinutes = Math.min(minDiff1, minDiff2);
    const scheduleAt = moment()
      .add(minimumMinutes + 60, "m")
      .format();
    for (var i = 0, j = influencerconnects.length; i < j; i++) {
      if (!influencerconnects[i].selected) {
        continue;
      }
      try {
        const payload = {
          sharenote: this.state.publishText + " " + url,
          post_id: this.state.postId,
          influencerconnect_id: influencerconnects[i].id,
          shareschedule_at: scheduleAt
        };
        await this.props.insertConnectPost({
          variables: {
            objects: [payload]
          }
        });
        this.setState({
          sharing: false
        });
        if (i === influencerconnects.length - 1) {
          if (this.props.isModal) {
            this.props.handleSubmit();
          }
        }
      } catch (err) {
        this.setState({ sharing: false });
      }
    }
  };

  handleShareNow = async () => {
    const { influencerconnects } = this.state;
    const url = this.getPostUrl();
    const shareAt = moment().format();
    try {
      for (var i = 0, j = influencerconnects.length; i < j; i++) {
        if (!influencerconnects[i].selected) {
          continue;
        }
        try {
          const payload = {
            sharenote: this.state.publishText + " " + url,
            post_id: this.state.postId,
            influencerconnect_id: influencerconnects[i].id,
            shareschedule_at: shareAt
          };
          await this.props.insertConnectPost({
            variables: {
              objects: [payload]
            }
          });
          this.setState({
            sharing: false
          });
          if (i === influencerconnects.length - 1) {
            if (this.props.isModal) {
              this.props.handleSubmit();
            }
          }
        } catch (err) {
          this.setState({ sharing: false });
        }
      }
    } catch (err) {}
  };

  handleShareAt = async () => {
    const { influencerconnects } = this.state;
    const url = this.getPostUrl();
    const shareAt = moment(this.state.shareAt).format();
    try {
      for (var i = 0, j = influencerconnects.length; i < j; i++) {
        if (!influencerconnects[i].selected) {
          continue;
        }
        try {
          const payload = {
            sharenote: this.state.publishText + " " + url,
            post_id: this.state.postId,
            influencerconnect_id: influencerconnects[i].id,
            shareschedule_at: shareAt
          };
          await this.props.insertConnectPost({
            variables: {
              objects: [payload]
            }
          });
          this.setState({
            sharing: false
          });
          if (i === influencerconnects.length - 1) {
            if (this.props.isModal) {
              this.props.handleSubmit();
            }
          }
        } catch (err) {
          this.setState({ sharing: false });
        }
      }
    } catch (err) {}
  };

  handleConnectToggle = (connect, selected) => {
    const { influencerconnects } = this.state;
    const selectedConnects = influencerconnects.filter(item => item.selected);
    if (selectedConnects.length === 1 && !selected) {
      return;
    }
    const currentIndex = influencerconnects.findIndex(
      item => item.id === connect.id
    );
    influencerconnects[currentIndex].selected = selected;
    this.setState({
      influencerconnects
    });
  };

  render() {
    const {
      sharing,
      publishText,
      wordLimit,
      submitRecurrence,
      shareAt,
      influencerconnects
    } = this.state;
    const {
      blogData,
      influencerDetails,
      isModal,
      showRecurrence,
      connectPost,
      showSharAt,
      handleCloseModal
    } = this.props;
    const { recurpattern } = connectPost || {};
    const postUrl = this.getPostUrl();
    return (
      <Wrapper>
        {!isModal && (
          <h2 style={{ margin: "30px 0 30px 0" }}>
            {getTranslation("sharePostText")}
          </h2>
        )}
        <Container>
          <div className="leftSection">
            <div className="textareaWrapper">
              <TextArea
                rows="10"
                name="publishText"
                value={publishText}
                onChange={this.handleChange}
                className={publishText ? "active" : ""}
              ></TextArea>
              <span>{wordLimit} left</span>
            </div>
            {(showRecurrence || !!recurpattern) && (
              <Recurrence
                isEdit={!!recurpattern}
                connectPost={connectPost}
                sharenote={publishText}
                postId={blogData.id}
                postUrl={postUrl}
                submitRecurrence={submitRecurrence}
                handleCloseModal={handleCloseModal}
              />
            )}
            {showSharAt && (
              <div style={{ marginTop: 20 }}>
                <DateTimePicker
                  date={shareAt}
                  placeholder={getTranslation("shareAtText")}
                  handleDateChange={this.handleDateChange}
                />
              </div>
            )}
          </div>
          <div className="rightSection">
            <div
              className="connectedChannels"
              style={{ marginTop: isMobile ? 20 : 0 }}
            >
              {influencerconnects.map((connect, i) => (
                <ConnectIcon
                  connect={connect}
                  key={i}
                  handleToggle={this.handleConnectToggle}
                />
              ))}
            </div>
            {blogData && (
              <BlogPostCard
                preview
                fullWidth={isMobile}
                blogData={blogData}
                layout={blogData.coverimage_layout}
              />
            )}
            <DomainLink domainLink={getTipDomain(influencerDetails)} />
            {influencerDetails &&
              !isModal &&
              influencerDetails.influencerconnects.length > 0 && (
                <div className="section">
                  {!isModal && (
                    <ItemTitle title={getTranslation("typeWordsToFollowers")} />
                  )}
                  {!isModal && (
                    <div className="btnWrapper" style={{ marginTop: 10 }}>
                      <Button
                        onClick={this.handleShare}
                        disabled={sharing || !publishText}
                      >
                        {getTranslation(sharing ? "sharing" : "shareNow")}
                      </Button>
                    </div>
                  )}
                </div>
              )}
          </div>
        </Container>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  influencerDetails: state.influencer.influencerDetails
});

export default withApollo(
  compose(
    graphql(insertConnectPost, { name: "insertConnectPost" }),
    connect(mapStateToProps)
  )(PreviewAndShare)
);
