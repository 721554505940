import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Fade from "react-reveal/Fade";
import { isMobile } from "react-device-detect";
import { Dots } from "./Styles";
import { addDecimal, swipedetect } from "utils/utils";
import getTranslation from "utils/getTranslation";
import img from "assets/tip/img.jpg";
import arrowLeft from "assets/tipLanding/arrow-left.svg";

const Wrapper = styled.div`
  padding-top: 100px;
  background-color: #f9f9f9;
  h1 {
    text-align: center;
  }
  p {
    margin-top: 0px;
    text-align: center;
    @media (min-width: 801px) {
      width: 700px;
      margin: auto;
      margin-top: 50px;
    }
    @media (max-width: 800px) {
      margin-bottom: 0;
    }
  }
  .slides {
    margin-top: 50px;
    position: relative;
    height: calc(586px / 2);
    .leftArrow {
      position: absolute;
      cursor: pointer;
      left: 0;
      top: 50%;
      transform: translateX(-50%);
      z-index: 10;
      left: 50px;
    }
    .rightArrow {
      position: absolute;
      cursor: pointer;
      right: 0;
      transform: rotate(-180deg) translateX(-50%);
      top: 50%;
      z-index: 10;
      right: 50px;
    }
    @media (max-width: 800px) {
      height: calc(100vw / 1.5 + 100px);
      overflow: hidden;
      width: 100vw;
      margin-top: 0;
      margin-left: -16px;
      display: flex;
      align-items: center;
    }
  }
  @media (max-width: 800px) {
    .leftArrow,
    .rightArrow {
      position: absolute;
      top: -25px;
    }
    .leftArrow {
      transform: none;
      left: -50px;
    }
    .rightArrow {
      right: -50px;
      transform: rotate(-180deg);
    }
  }
`;

const Slide = styled.div`
  width: calc(892px / 2);
  height: calc(586px / 2);
  object-fit: cover;
  box-shadow: 0px 6px 40px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  position: absolute;
  left: 50%;
  background: ${props => `url(${props.src})`};
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  @media (max-width: 800px) {
    width: calc(100% - 40px);
    height: calc(100vw / 1.5);
  }
  &.inactive {
    background: ${props => `linear-gradient(
      0deg,
      rgba(71, 211, 255, 0.3),
      rgba(71, 211, 255, 0.3)
    ), url(${props.src})`};
  }
`;

export default () => {
  const [activeIndex, setActiveIndex] = useState(0);
  useEffect(() => {
    const el = document.getElementById("swipezone");
    swipedetect(el, swipedir => {
      if (swipedir === "left") {
        handleNext();
      }
      if (swipedir === "right") {
        handlePrev();
      }
    });
  });
  function handleClick(e) {
    setActiveIndex(parseInt(e.target.id));
  }
  function handlePrev() {
    if (activeIndex > 0) setActiveIndex(activeIndex - 1);
  }
  function handleNext() {
    if (activeIndex < 2) setActiveIndex(activeIndex + 1);
  }
  return (
    <Wrapper>
      <div className="main-container">
        <Fade bottom>
          <h1>{getTranslation("newlyFoundDest")} - Brighton</h1>
        </Fade>
        <Fade delay={500}>
          <>
            <div className="slides" id="swipezone">
              {!isMobile && (
                <>
                  <img
                    src={arrowLeft}
                    alt=""
                    className="leftArrow"
                    onClick={handlePrev}
                  />
                  <img
                    src={arrowLeft}
                    alt=""
                    className="rightArrow"
                    onClick={handleNext}
                  />
                </>
              )}
              <Slide
                src={img}
                alt=""
                style={{
                  transform: `translateX(${-50 - activeIndex * 15}%) scale(${
                    activeIndex === 0
                      ? 1
                      : 1 - parseFloat(addDecimal(activeIndex, 1))
                  })`,
                  zIndex: 3 - activeIndex
                }}
                className={activeIndex !== 0 ? "inactive" : ""}
              />
              <Slide
                src={img}
                alt=""
                style={{
                  transform: `translateX(${-35 - activeIndex * 15}%) scale(${
                    activeIndex === 1 ? 1 : 0.9
                  })`,
                  zIndex: activeIndex === 1 ? 10 : 2 + activeIndex
                }}
                className={activeIndex !== 1 ? "inactive" : ""}
              />
              <Slide
                src={img}
                alt=""
                style={{
                  transform: `translateX(${-20 - activeIndex * 15}%) scale(${
                    activeIndex === 2
                      ? 1
                      : 1 - 0.2 + parseFloat(addDecimal(activeIndex, 1))
                  })`,
                  zIndex: activeIndex === 2 ? 10 : 1 + activeIndex
                }}
                className={activeIndex !== 2 ? "inactive" : ""}
              />
            </div>
            <p>
              A beach town, be it for hen parties, fantastic restaurants, pebble
              beaches or festivals throughout the year welcomes all. There are
              popular LGBTQ+ walking tours if you wondered how Brighton became
              the town it is today.
            </p>
            {!isMobile && (
              <Dots>
                <div
                  className={`dot ${activeIndex === 0 ? "active" : ""}`}
                  onClick={handleClick}
                  id="0"
                />
                <div
                  className={`dot ${activeIndex === 1 ? "active" : ""}`}
                  onClick={handleClick}
                  id="1"
                />
                <div
                  className={`dot ${activeIndex === 2 ? "active" : ""}`}
                  onClick={handleClick}
                  id="2"
                />
              </Dots>
            )}
          </>
        </Fade>
      </div>
    </Wrapper>
  );
};
