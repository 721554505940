import React, { PureComponent } from "react";
import styled, { withTheme } from "styled-components";
import { graphql, compose } from "react-apollo";
import { connect } from "react-redux";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Modal from "components/common/Modal";
import { handleUpdateFilteredResults } from "redux-store/actions";
import { Airline, TimeFilter, CabinFilter, TabLabel } from "./Partials";
import {
  filteredResultsQuery,
  addTripFilter,
  delTripFilter,
  clearTripFilters
} from "./queries";
import { getItem, getChunks } from "utils/utils";
import {
  AirlineFilterIcon,
  TimeFilterIcon,
  SeatFilterIcon
} from "components/common/SvgIcons";
import getTranslation from "utils/getTranslation";

const Wrapper = styled.div`
  width: 616px;
  min-height: 200px;
  position: relative;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

const StyledTabs = styled(Tabs)`
  .PrivateTabIndicator-colorSecondary-228 {
    background-color: ${props => props.theme.primary};
  }
`;

const StyledTab = styled(Tab)`
  min-width: 100px !important;
  padding: 0 !important;
  color: ${props => props.theme.primary} !important;
  font-weight: 300 !important;
  font-family: "Roboto Condensed", sans-serif !important;
  @media (max-width: 800px) {
    min-width: 60px !important;
    min-width: 70px !important;
  }
  &.Mui-selected {
    span {
      color: ${props => props.theme.secondary} !important;
    }
  }
`;

class FilterModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      trips: [],
      selectedFilter: "airline",
      filterKvs: this.props.filterKvs || [],
      initialFilterKvs: !!this.props.filterKvs,
      tripId: getItem("tripId"),
      timeFilters: [],
      timeDirection: "",
      filterUpdated: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && this.props.open !== nextProps.open) {
      if (this.props.filteredResults.length === 0) {
        this.getFilteredResults();
      }
      this.setState({
        timeFilters: []
      });
    }
    if (nextProps.filterKvs && this.props.filterKvs !== nextProps.filterKvs) {
      this.setState({
        filterKvs: nextProps.filterKvs || [],
        initialFilterKvs: !!nextProps.filterKvs
      });
    }
  }

  handleClose = () => {
    // this.props.handleCloseModal(this.state.filterKvs.length > 0);
    this.props.handleCloseModal(true);
  };

  handleFilterChange = async (e, value) => {
    if (this.state.filterUpdated) {
      this.setState(
        {
          loading: true
        },
        () => {
          this.getFilteredResults();
        }
      );
    }
    this.setState({
      selectedFilter: value
    });
  };

  handleDirectionChange = (direction, index) => {
    let filters = this.state.timeFilters;
    if (filters[index]) {
      filters[index].timeDirection = direction;
    } else {
      filters = [...filters, { timeDirection: direction }];
    }
    this.setState(
      {
        timeFilters: filters
      },
      () => {
        this.handleCheckTimeFilterDirectionChange(direction, index);
      }
    );
  };

  handleTimeToggle = (time, index, selected, direction) => {
    let filters = this.state.timeFilters;
    if (filters[index] && filters[index].timeFilters) {
      filters[index].timeFilters = [...filters[index].timeFilters, time];
      filters[index].timeDirection = direction;
    } else {
      filters = [...filters, { timeFilters: [time], timeDirection: direction }];
    }
    this.handleTimeFilter(time, direction, index, selected);
    this.setState({
      timeFilters: filters
    });
  };

  handleTimeFilter = (time, flightType, index, selected) => {
    const filter = this.props.filteredResults[0].hopRequests.map(
      (hopRequest, i) =>
        i === index
          ? flightType === "departure"
            ? `${time}-`
            : `-${time}`
          : "--"
    );
    const filterJoin = filter.join("");
    this.handleFiltersUpdate("TIME_PERIOD", filterJoin, selected);
  };

  handleCheckTimeFilterDirectionChange = (direction, index) => {
    const timeFiltersKvs = this.state.filterKvs.filter(
      item => item.filterKey === "TIME_PERIOD"
    );
    for (let i = 0, j = timeFiltersKvs.length; i < j; i++) {
      const filter = timeFiltersKvs[i];
      const chunks = getChunks(filter.value.split(""), 2);
      if (chunks[index][0] !== "-" || chunks[index][1] !== "-") {
        const reverseFilter =
          chunks[index][0] !== "-"
            ? "-" + chunks[index][0]
            : chunks[index][1] + "-";
        chunks[index] = reverseFilter.split("");
        const finalFilter = chunks.map(chunk => chunk.join(""));
        this.handleFiltersUpdate("TIME_PERIOD", filter.value, true);
        this.handleFiltersUpdate("TIME_PERIOD", finalFilter.join(""));
      }
    }
  };

  handleFiltersUpdate = async (filterKey, value, doDelete) => {
    if (doDelete) {
      const filter = this.state.filterKvs.find(
        item => item.filterKey === filterKey && item.value === value
      );
      const removedFilter = await this.props.delTripFilter({
        variables: {
          tripId: this.state.tripId,
          id: filter.id
        }
      });
      this.setState(
        {
          filterKvs: removedFilter.data.delTripFilter.filterKvs,
          filterUpdated: true
        },
        () => {
          // this.getFilteredResults();
        }
      );
    } else {
      const addFilter = await this.props.addTripFilter({
        variables: {
          tripId: this.state.tripId,
          filterKey,
          value
        }
      });
      this.setState(
        {
          filterKvs: addFilter.data.addTripFilter.filterKvs,
          filterUpdated: true
        },
        () => {
          // this.getFilteredResults();
        }
      );
    }
  };

  getFilteredResults = () => {
    this.setState({ loading: true, filterUpdated: false });
    this.props.client
      .query({
        query: filteredResultsQuery,
        fetchPolicy: "no-cache",
        variables: { tripId: this.state.tripId }
      })
      .then(results => {
        const { filteredResults } = results.data;
        this.props.handleUpdateFilteredResults(filteredResults);
        setTimeout(() => {
          this.setState({ loading: false });
        }, 500);
        if (filteredResults.length === 0) {
          this.setState({ loading: false, error: true });
        }
      })
      .catch(err => {
        console.log("getFilteredResults error", err);
        this.setState({ loading: false, error: true });
      });
  };

  resetFilter = async () => {
    try {
      const clearFilter = await this.props.clearTripFilters({
        variables: {
          tripId: this.state.tripId
        }
      });
      this.setState(
        {
          filter: null,
          filterKvs: clearFilter.data.clearTripFilters.filterKvs
        },
        () => {
          this.getFilteredResults();
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    const {
      selectedFilter,
      filterKvs,
      initialFilterKvs,
      loading,
      error
    } = this.state;
    const { open, filteredResults, theme } = this.props;
    const width = Math.max(window.screen.width, window.innerWidth);
    return (
      <Modal
        open={open}
        title={
          <StyledTabs
            onChange={this.handleFilterChange}
            value={selectedFilter}
            variant="fullWidth"
            indicatorColor="primary"
          >
            <StyledTab
              value="airline"
              icon={
                <AirlineFilterIcon
                  stroke={
                    selectedFilter === "airline"
                      ? theme.secondary
                      : theme.primary
                  }
                />
              }
              label={
                <TabLabel
                  label="Airline"
                  filterKvs={filterKvs}
                  filterKey="AIRLINE"
                />
              }
            />
            <StyledTab
              value="time"
              icon={
                <TimeFilterIcon
                  stroke={
                    selectedFilter === "time" ? theme.secondary : theme.primary
                  }
                />
              }
              label={
                <TabLabel
                  label="Time"
                  filterKvs={filterKvs}
                  filterKey="TIME_PERIOD"
                />
              }
            />
            <StyledTab
              value="cabin"
              icon={
                <SeatFilterIcon
                  stroke={
                    selectedFilter === "cabin" ? theme.secondary : theme.primary
                  }
                />
              }
              label={
                <TabLabel
                  label="Cabin"
                  filterKvs={filterKvs}
                  filterKey="SEAT_CLASS"
                />
              }
            />
          </StyledTabs>
        }
        handleCloseModal={this.handleClose}
        handleReset={this.resetFilter}
        dialogStyle={{ width: 616, maxWidth: 616 }}
        titleHtml
        showReset={width > 320}
        fallbackResetStyle={{ marginRight: 8 }}
      >
        <Wrapper>
          {filteredResults.length > 0 || selectedFilter === "airline" ? (
            <React.Fragment>
              {selectedFilter === "airline" && (
                <Airline
                  trips={filteredResults}
                  filterKvs={filterKvs}
                  loading={loading}
                  initialFilterKvs={initialFilterKvs}
                  handleFiltersUpdate={this.handleFiltersUpdate}
                />
              )}
              {selectedFilter === "time" && (
                <TimeFilter
                  trips={filteredResults}
                  filterKvs={filterKvs}
                  loading={loading}
                  initialFilterKvs={initialFilterKvs}
                  handleFiltersUpdate={this.handleFiltersUpdate}
                  handleTimeToggle={this.handleTimeToggle}
                  handleDirectionChange={this.handleDirectionChange}
                />
              )}
              {selectedFilter === "cabin" && (
                <CabinFilter
                  filterKvs={filterKvs}
                  loading={loading}
                  initialFilterKvs={initialFilterKvs}
                  handleFiltersUpdate={this.handleFiltersUpdate}
                />
              )}
            </React.Fragment>
          ) : error && !loading ? (
            <h3>{getTranslation("genericErrorMsg")}</h3>
          ) : null}
        </Wrapper>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  filteredResults: state.flightResults.filteredResults
});

export default withTheme(
  compose(
    graphql(addTripFilter, { name: "addTripFilter" }),
    graphql(delTripFilter, { name: "delTripFilter" }),
    graphql(clearTripFilters, { name: "clearTripFilters" }),
    connect(mapStateToProps, { handleUpdateFilteredResults })
  )(FilterModal)
);
