import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

export default props => (
  <div
    style={{
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    paddingTop: 20,
    ...props.style
  }}>
    <CircularProgress size={props.size || 30} color={props.color || 'primary'}/>
  </div>
);
