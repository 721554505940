import React from "react";
import { connect } from "react-redux";
import styled, { withTheme } from "styled-components";
import CircularProgress from "@material-ui/core/CircularProgress";
import { updateLocalizedCost } from "redux-store/actions";
import { Button, InfoModal } from "components/common";
import SearchMeta from "components/HopSegment/Partials/SearchMeta";
import { InfoIcon } from "components/common/SvgIcons";
import {
  numberToLocaleString,
  getItem,
  addDecimal,
  cancelAllRequests,
} from "utils/utils";
import getTranslation from "utils/getTranslation";

const Wrapper = styled.div`
  color: #222222;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  font-size: 18px;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  justify-content: space-between;
  .priceSection {
    display: flex;
    align-items: center;
    @media (max-width: 800px) {
      align-items: center;
    }
    .price {
      display: flex;
      flex-flow: column;
      align-items: flex-end;
      span {
        font-size: 8px;
        &:first-child {
          cursor: pointer;
          font-weight: 500;
          text-decoration: underline;
        }
      }
      .totalPrice {
        margin-right: 10px;
        font-size: 20px;
        text-decoration: none !important;
        color: ${(props) => props.theme.secondary};
      }
    }
  }
`;

const StyledLoader = styled(CircularProgress)`
  position: absolute;
  left: 0;
`;

class CloseButton extends React.PureComponent {
  constructor(props) {
    super(props);
    const totalCost = this.getTotalCost(props.costItems);
    this.state = {
      totalCost,
      infoModalOpen: false,
      filterModalOpen: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.costItems !== this.props.costItems) {
      const totalCost = this.getTotalCost(nextProps.costItems);
      this.setState({
        totalCost,
      });
    }
  }

  handleInfoToggle = () => {
    this.setState({
      infoModalOpen: !this.state.infoModalOpen,
    });
  };

  getTotalCost = (costItems) => {
    if (Array.isArray(costItems) && costItems.length > 0) {
      const sum = costItems.reduce(
        (accumulator, currentVal) => accumulator + currentVal.cost,
        0
      );

      return sum;
    }

    return 0;
  };

  handleClose = () => {
    cancelAllRequests();
    this.props.handleClose();
  };

  handleOpenModal = () => {
    if (this.props.fareRulesFound) {
      this.props.handleOpenModal();
    }
  };

  render() {
    const {
      loading,
      extraCheckDone,
      hopRequests,
      costItems,
      theme,
      bookings
    } = this.props;

    let { totalCost, infoModalOpen } = this.state;

    // console.log("total " + totalCost);
    if (bookings.length > 0 && bookings[0].customCurrency === costItems[0].costItemCurrencyIso) {
      let hotelBookingTotalCost = bookings.map(b => b.totalCustomCurrencySellingRate).reduce((a, b) => (a + b));
      let hotelBookingTotalCostNoDec = Math.floor(hotelBookingTotalCost) === hotelBookingTotalCost ? hotelBookingTotalCost : hotelBookingTotalCost * Math.pow(10, hotelBookingTotalCost.toString().split(".")[1].length);
      totalCost = (totalCost + hotelBookingTotalCostNoDec).toString();

      // console.log("total now " + totalCost);
    }
    let totalCostInUnits = addDecimal(totalCost);
    const localizedTotalCost = numberToLocaleString(totalCostInUnits, {
      selectedCountry: getItem("selectedLanguage"),
      selectedCurrency: costItems[0].costItemCurrencyIso,
    });
    this.props.updateLocalizedCost(localizedTotalCost);

    const { adultCount, childCount, infantCount } = hopRequests[0];
    const passengerArray = [
      ...Array(adultCount).fill({ passengerTyp: "ADT", type: "adult" }),
      ...Array(childCount).fill({ passengerTyp: "CHD", type: "child" }),
      ...Array(infantCount).fill({ passengerTyp: "INF", type: "infant" }),
    ];
    return (
      <React.Fragment>
        {infoModalOpen && (
          <InfoModal
            open={infoModalOpen}
            handleCloseModal={this.handleInfoToggle}
            title={localizedTotalCost}
            tripCostItems={costItems}
            passengers={passengerArray}
            hopRequests={hopRequests}
          />
        )}
        <Wrapper>
          <SearchMeta
            hopRequests={hopRequests}
            handleClose={this.handleClose}
          />
          {!loading ? (
            <div className="priceSection">
              <div className="price">
                <span className="totalPrice">{localizedTotalCost}</span>
                <span style={{ fontSize: 14, marginRight: 10 }}>
                  {getTranslation("includingAll1")}{" "}
                  {getTranslation("includingAll2")}
                </span>
              </div>
              <div>
                <div
                  style={{
                    width: 40,
                    height: 40,
                    position: "relative",
                    marginRight: 10,
                  }}
                >
                  <Button
                    style={{
                      height: 40,
                      width: 40,
                      minWidth: 40,
                    }}
                    onClick={this.handleInfoToggle}
                    roundButton
                    color={theme.primaryBackground}
                  >
                    <InfoIcon />
                  </Button>
                  {!extraCheckDone && (
                    <StyledLoader size={40} color="#222222" />
                  )}
                </div>
              </div>
            </div>
          ) : (
              <div style={{ width: "20%" }} />
            )}
        </Wrapper>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  costItems: state.flightResults.tripCostItems,
  translations: state.translations,
  enablePaymentGateway: state.flightResults.enablePaymentGateway,
  isMultiTrip: state.flightResults.isMultiTrip,
  bookings: state.hotelResults.bookings
});

export default withTheme(
  connect(mapStateToProps, { updateLocalizedCost })(CloseButton)
);
