import { setItem } from 'utils/utils';

export const SET_HOP_REQUESTS_DATA = 'SET_HOP_REQUESTS_DATA';
export const UPDATE_HOP_REQUESTS_DATA = 'UPDATE_HOP_REQUESTS_DATA';
export const SET_TRIP_COST = 'SET_TRIP_COST';
export const REMOVE_TRIP_DATA = 'REMOVE_TRIP_DATA';
export const ADD_PASSENGER_TO_ALL_HOP_REQUESTS = 'ADD_PASSENGER_TO_ALL_HOP_REQUESTS';
export const REMOVE_PASSENGER_FROM_HOP_REQUEST = 'REMOVE_PASSENGER_FROM_HOP_REQUEST';
export const REMOVE_PASSENGER_FROM_ALL_HOP_REQUEST = 'REMOVE_PASSENGER_FROM_ALL_HOP_REQUEST';
export const ADD_PREV_PASSENGER = 'ADD_PREV_PASSENGER';
export const ALL_PASSENGERS_ADDED = 'ALL_PASSENGERS_ADDED';
export const HANDLE_ASYNC_UPDATE_FARE_RULE = 'HANDLE_ASYNC_UPDATE_FARE_RULE';
export const HANDLE_UPDATE_FILTERED_RESULTS = 'HANDLE_UPDATE_FILTERED_RESULTS';
export const CLEAR_FILTERED_RESULTS = 'CLEAR_FILTERED_RESULTS';
export const UPDATE_SPLIT_BOOKING = 'UPDATE_SPLIT_BOOKING';
export const ENABLE_PAYMENT = 'ENABLE_PAYMENT';
export const TOGGLE_MULTITRIP = 'TOGGLE_MULTITRIP';
export const UPDATE_COST = 'UPDATE_COST';

export function setHopRequestsData(data) {
  return {
    type: SET_HOP_REQUESTS_DATA,
    payload: data,
  };
}

export function updateHopRequestsData(data) {
  return {
    type: UPDATE_HOP_REQUESTS_DATA,
    payload: data,
  };
}

export function setTripCost(data) {
  return {
    type: SET_TRIP_COST,
    payload: data,
  };
}

export function addPassengerToAllHopRequests(data) {
  return {
    type: ADD_PASSENGER_TO_ALL_HOP_REQUESTS,
    payload: data,
  };
}

export function removePassengerFromHopRequest(id) {
  return {
    type: REMOVE_PASSENGER_FROM_HOP_REQUEST,
    payload: id,
  };
}

export function removePassengerFromAllHopRequest(payload) {
  return {
    type: REMOVE_PASSENGER_FROM_ALL_HOP_REQUEST,
    payload,
  };
}

export function addPrevPassengers(payload) {
  return {
    type: ADD_PREV_PASSENGER,
    payload,
  };
}

export function removeTripData() {
  return {
    type: REMOVE_TRIP_DATA,
  };
}

export function allPassengersAdded(payload) {
  return {
    type: ALL_PASSENGERS_ADDED,
    payload,
  };
}

export function handleAsyncUpdateFareRule() {
  return {
    type: HANDLE_ASYNC_UPDATE_FARE_RULE,
  };
}

export function handleUpdateFilteredResults(payload) {
  return {
    type: HANDLE_UPDATE_FILTERED_RESULTS,
    payload,
  };
}

export function clearFilteredResults() {
  return {
    type: CLEAR_FILTERED_RESULTS,
  };
}

export function updateSplitBooking(payload) {
  setItem('splitBooking', payload);
  return {
    type: UPDATE_SPLIT_BOOKING,
    payload,
  };
}

export function togglePayment(payload) {
  return {
    type: ENABLE_PAYMENT,
    payload
  };
}

export function toggleMutlitrip(payload) {
  return {
    type: TOGGLE_MULTITRIP,
    payload
  };
}

export function updateLocalizedCost(payload) {
  return {
    type: UPDATE_COST,
    payload
  };
}
