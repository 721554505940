import React, { useState } from "react";
import styled from "styled-components";
import Fade from "react-reveal/Fade";
import Slide from "react-reveal/Slide";
import Lottie from "react-lottie";

const Wrapper = styled.div`
  background-color: #f9f9f9;
  &.reverse {
    background-color: #fff;
  }
  .container {
    width: 1250px;
    margin: auto;
    display: flex;
    align-items: center;
    &.reverse {
      flex-flow: row-reverse;
    }
    .text {
      padding-left: 100px;
      width: 550px;
    }
    @media (max-width: 800px) {
      width: 100%;
      flex-flow: column;
      &.reverse {
        flex-flow: column;
        background-color: #f9f9f9;
      }
      .text {
        width: 100%;
        padding: 0 16px;
        text-align: center;
      }
    }
  }
`;

export default ({ data }) => {
  const [revealed, setRevealed] = useState(false);
  function handleReveal() {
    setTimeout(() => {
      setRevealed(true);
    }, 300);
  }
  const reverse = data.id % 2 === 0;
  return (
    <Wrapper className={reverse ? "reverse" : ""}>
      <div className={`container ${reverse ? "reverse" : ""}`}>
        <Lottie
          options={{
            loop: false,
            autoplay: true,
            animationData: data.data,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice"
            }
          }}
          isStopped={!revealed}
        />
        <Fade onReveal={handleReveal} duration={1500}>
          <Slide left={!reverse} right={reverse} duration={700}>
            <div className="text">
              <h5>{data.title}</h5>
              <p>{data.text}</p>
            </div>
          </Slide>
        </Fade>
      </div>
    </Wrapper>
  );
};
