import React from "react";
import styled from "styled-components";
import { Button, Input } from "components/common";
import { Sections } from "containers/Influencer/Setup/Styles";
import { ItemTitle } from "components/Influencer";
import { EditSection } from "components/Influencer/Common";
import CustomDomain from "components/Influencer/Partials/CustomDomain";
import getTranslation from "utils/getTranslation";
import close from "assets/tip/Close-circle.svg";
import { isMobile } from "react-device-detect";
import config from "config";
import { rword } from 'rword';

const Wrapper = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  @media (max-width: 800px) {
    padding: 16px;
    width: 100%;
    .domainSection {
      display: flex;
      flex-flow: column;
      @media (max-width: 800px) {
        .section {
          width: 100%;
        }
      }
    }
  }
  .or {
    margin: 0 90px;
    position: relative;
    @media (max-width: 800px) {
      margin: 50px 0;
    }
    .text {
      padding: 15px;
      font-size: 16px;
      color: #777777;
      background-color: #f0f0f0;
      border-radius: 10em;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .flystrait {
    .inputWrapper {
      position: relative;
      @media (max-width: 800px) {
        width: 90%;
      }
      .close {
        cursor: pointer;
        position: absolute;
        right: -40px;
        top: 34px;
      }
    }
  }
`;

export default class extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      randomword: "example",
      domainType:
        props.customsitedomains.length > 0
          ? "custom"
          : props.data.tip_sub_domain
            ? "flystrait"
            : "",
    };
  }

  componentDidMount = () => {
    var { tip_sub_domain } = this.props.data;
    if (!tip_sub_domain) {
      const randomword = rword.generate();
      this.setState({
        randomword: randomword
      })
    }
  }

  handleCustom = () => {
    this.setState({ domainType: "custom" });
  };
  handleFlyStrait = () => {
    this.setState({ domainType: "flystrait" });
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.props.handleUpdate(name, value);
  };
  handleClearDomain = () => {
    this.setState({ domainType: null });
    if (this.props.handleClearDomain) {
      this.props.handleClearDomain();
    }
  };
  render() {
    const { domainType, randomword } = this.state;
    const {
      data,
      customsitedomains,
      subdomainUsed,
      invalidSubdomain,
      edit,
      handleUpdate,
      handleDomainUpdate
    } = this.props;
    const { tip_sub_domain } = data;
    return (
      <Wrapper>
        <div className="titleWithEdit">
          <h2>{getTranslation("setupDomain")}</h2>
          {edit && <EditSection />}
        </div>
        <Sections className="domainSection">
          {(!domainType || domainType === "custom") && (
            <div
              className="section"
              style={
                domainType === "custom"
                  ? { width: isMobile ? "100%" : 424 }
                  : {}
              }
            >
              {!edit && (
                <ItemTitle title={getTranslation("customDomainName")} />
              )}
              {!edit && (
                <div className="text" style={{ marginBottom: 20 }}>
                  <span>{getTranslation("customDomainText")}</span>
                </div>
              )}
              {!domainType && (
                <Button onClick={this.handleCustom}>
                  {getTranslation("customDomain")}
                </Button>
              )}
              {domainType === "custom" && (
                <CustomDomain
                  selected={data.custom_domain_selected}
                  customDomains={customsitedomains}
                  handleClearDomain={this.handleClearDomain}
                  handleUpdate={handleUpdate}
                  handleDomainUpdate={handleDomainUpdate}
                />
              )}
            </div>
          )}
          {!domainType && (
            <div className="or">
              <div className="text">OR</div>
            </div>
          )}
          {(!domainType || domainType === "flystrait") && (
            <div className="section">
              {!edit && <ItemTitle title={getTranslation("flystraitDomain")} />}
              {!edit && (
                <div className="text" style={{ marginBottom: 20 }}>
                  <span>{getTranslation("flystraitDomainText") /*+ " " + tip_sub_domain ? tip_sub_domain : randomword + "." + config.tipFrontUrl*/}</span>
                </div>
              )}
              {!domainType && (
                <Button onClick={this.handleFlyStrait}>
                  {getTranslation("flystraitDomain")}
                </Button>
              )}
              {domainType === "flystrait" && (
                <div className="flystrait">
                  <div className="inputWrapper">
                    <Input
                      label={getTranslation("flystraitDomainName")}
                      fullWidth
                      value={typeof tip_sub_domain !== 'undefined' ? tip_sub_domain : randomword}
                      name="tip_sub_domain"
                      onChange={this.handleChange}
                      hideClear
                      errorText={
                        invalidSubdomain ? getTranslation("invalidSubdomain") : (subdomainUsed ? getTranslation("subDomainUsed") : "")
                      }
                    />
                    <img
                      src={close}
                      className="close"
                      alt=""
                      onClick={this.handleClearDomain}
                    />
                  </div>
                  {!edit && (
                    <div
                      className="text"
                      style={{ marginBottom: 5, marginTop: 20 }}
                    >
                      <span>{getTranslation("flystraitDomainMeta")}</span>
                      <span>{" " + (typeof tip_sub_domain !== 'undefined' ? tip_sub_domain : randomword) + "." + config.tipFrontUrl}</span>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </Sections>
      </Wrapper>
    );
  }
}
