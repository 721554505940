import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { graphql, compose } from 'react-apollo';
import { updGuestTripowner } from 'containers/TripBooking/queries';
import { signup, validateEmail } from 'utils/api';
import { Input, Autocomplete, Button, Toast } from 'components/common';
import countries from 'utils/countries';
import { getItem, removeItem } from 'utils/utils';
import getTranslation from 'utils/getTranslation';

const Wrapper = styled.div`
  width: 327px;
  margin: auto;
`;

let timeout = null;
class Login extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      errors: {},
      firstname: '',
      lastname: '',
      password: '',
      email: '',
      country: '',
      searchText: '',
      registered: false,
      dataSource: [],
      isValidEmail: true,
      isRegistered: false
    };
  }
  onCountrySelect = (index, dataSource) => {
    if (index === -1) return;
    const country = dataSource[index];
    if (country) this.setState({ country, dataSource: [] });
  }
  handleChange = (e) => {
    let { name, value } = e.target;

    this.setState({
      [name]: value,
    }, () => {
      if (name === 'email') {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          this.handleEmailCheck(this.state.email);
        }, 500);
      }
    });
  }

  handleEmailCheck = (text) => {
    const re = /\S+@\S+\.\S+/;
    const validEmail = re.test(text);
    if (validEmail) {
      this.validateEmailId();
    }
  }

  validateEmailId = async () => {
    try {
      const validate = await validateEmail(getItem("selectedCountry" || "XX"), this.state.email);
      if (validate.registered) {

        this.props.alreadyRegistered(getTranslation('signupAlreadyRegistered'), this.state.email);
        return;
      }
      this.setState({
        isValidEmail: validate.valid,
        isRegistered: validate.registered
      });
    } catch (err) {
      console.log(err);
      this.setState({
        isValidEmail: false,
      });
    }
  }

  handleValidations = () => (
    new Promise((resolve, reject) => {
      let hasErrors = false;
      const errors = {};
      const emailValid = validateEmail(getItem("selectedCountry" || "XX"), this.state.email);
      if (!emailValid) {
        hasErrors = true;
        errors.email = getTranslation('enterValidEmail');
      }
      if (this.state.firstname.length < 2) {
        hasErrors = true;
        errors.firstname = getTranslation('enterValidFirstname');
      }
      if (this.state.lastname.length < 2) {
        hasErrors = true;
        errors.lastname = getTranslation('enterValidLastname');
      }
      if (this.state.password.length < 6) {
        hasErrors = true;
        errors.password = getTranslation('passwordSixChar');
      }
      if (this.state.password !== this.state.confirmPassword) {
        hasErrors = true;
        errors.confirmPassword = getTranslation('passwordDontMatch');
      }
      if (this.state.country.length < 2) {
        hasErrors = true;
        errors.country = getTranslation('enterCountry');
      }
      if (hasErrors) {
        reject(errors);
      } else {
        resolve(true);
      }
    })
  );

  handleSignup = () => {
    this.handleValidations().then(async () => {
      this.setState({ loading: true, errors: {} });
      try {
        const register = await signup(this.state);
        if (register.success) {
          console.log(register);
          if (getItem('redirectTo')) {
            this.handleTripOwnerUpdate();
          } else {
            this.setState({ loading: false, registered: true });
          }
        } else {
          this.setState({ loading: false, error: true, errorMsg: register.message });
        }
      } catch (err) {
        console.log(err);
        this.setState({ loading: false, error: true, errorMsg: getTranslation('singupError') });
      }
    }, (errors) => {
      this.setState({
        errors,
      });
    });
  }

  handleTripOwnerUpdate = () => {
    this.props
      .updGuestTripowner({
        variables: {
          guestEmail: this.state.email,
          tripId: getItem('tripId'),
        },
      })
      .then((data) => {
        console.log(data);
        this.props.history.replace(getItem('redirectTo'));
        removeItem('redirectTo');
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
        });
      });
  }

  handleCountryInputChange = (txt) => {
    this.setState({ searchText: txt }, () => {
      this.handleCountryFilter(txt.toLowerCase());
    });
  }

  handleCountryFilter = (txt) => {
    const dataSource = countries.filter(country => country.toLowerCase().startsWith(txt));
    this.setState({
      dataSource,
    });
  }

  handleBlurCheck = (text, dataSource) => {
    const country = dataSource.find(item => item.toLowerCase() === text.toLowerCase());
    if (country) this.setState({ country, searchText: country });
  }

  handleAutocompleteClose = () => {
    this.setState({ dataSource: [] });
  }

  handleClose = () => {
    this.setState({ error: false });
  }
  render() {
    const {
      firstname,
      lastname,
      email,
      password,
      confirmPassword,
      country,
      loading,
      error,
      errors,
      errorMsg,
      searchText,
      dataSource,
      registered,
      isValidEmail
    } = this.state;
    return (
      <Wrapper>
        {!registered && <React.Fragment>
          <Input
            name="firstname"
            label={getTranslation('firstname')}
            value={firstname}
            errorText={errors.firstname}
            onChange={this.handleChange}
            autoFocus={true}
          />
          <Input
            name="lastname"
            label={getTranslation('lastname')}
            value={lastname}
            errorText={errors.lastname}
            onChange={this.handleChange}
          />
          <Input
            name="email"
            label={getTranslation('email')}
            value={email}
            errorText={!isValidEmail ? 'Please enter a valid Email' : ''}
            onChange={this.handleChange}
          />
          <Input
            type="password"
            name="password"
            label={getTranslation('password')}
            value={password}
            errorText={errors.password}
            onChange={this.handleChange}
          />
          <Input
            type="password"
            name="confirmPassword"
            label={getTranslation('confirmPassword')}
            value={confirmPassword}
            errorText={errors.confirmPassword}
            onChange={this.handleChange}
          />
          <Autocomplete
            id="country"
            label={getTranslation('country')}
            value={country}
            searchText={searchText}
            onChange={this.handleCountryInputChange}
            handleSelect={this.onCountrySelect}
            handleClose={this.handleAutocompleteClose}
            onBlur={this.handleBlurCheck}
            dataSource={dataSource}
          />
          <div style={{ marginTop: 25 }}>
            <Button onClick={this.handleSignup} disabled={loading}>{getTranslation('signup')}</Button>
          </div>
        </React.Fragment>}
        {registered && <h3 style={{ color: '#000', padding: 20, textAlign: 'center' }}>{getTranslation('accountActivationMessage')}</h3>}
        <Toast
          open={error}
          handleClose={this.handleClose}
          message={errorMsg}
        />
      </Wrapper>
    )
  }
}

export default withRouter(compose(
  graphql(updGuestTripowner, { name: 'updGuestTripowner' }),
)(Login));
