import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Loader, Button } from 'components/common';
import { getItem, removeItem } from 'utils/utils';
import getTranslation from 'utils/getTranslation';
import GuestForm from './Partials/GuestForm';
import TripItem from './Partials/TripItem';
import { getTripItems } from './queries';

const Wrapper = styled.div`
  display: table;
  padding: 20px 10px;
  width: 100%;
  background-color: #fff;
  min-height: 100vh;
  @media (min-width: 1000px) {
    width: 100%;
    min-height: calc(100vh - 140px);
  }
  @media (max-width: 800px) {
    padding: 0;
    min-height: calc(100vh - 122px);
  }
`;

const Container = styled.div`
  display: flex;
  width: 935px;
  margin: auto;
  margin-top: 30px;
  flex-flow: column;
  @media (max-width: 800px) {
    width: 100%;
    margin-top: 0;
  }
  .item {
    width: 100%;
    padding: 10px;
    font-size: 18px;
    background-color: #F0F0F0;
    color: #007AC7;
    cursor: pointer;
    margin-bottom: 12px;
    &.active {
      background-color: rgba(86,204,242,0.3);
    }
  }
  @media (max-width: 800px) {
    flex-direction: column;
    .nav, .content {
      width: 100%;
    }
  }
`;

const NoFlight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  flex-direction: column;
  text-align: center;
  padding: 30px;
  p{
    color: #444444;
    font-size: 16px;
    width: 296px;
  }
  div {
    margin-top: 20px;
    a{
      text-decoration: none;
    }
  }
`;

class MyTrips extends Component {
  constructor(props) {
    super(props);
    const noFlight = false;
    this.state = {
      tripItems: [],
      hopRequestDone: false,
      hopRequestId: 0,
      hopSegmentData: {},
      loadingScreen: !!getItem('tokenDetails'),
      paymentDone: false,
      noFlight,
      isLoggedIn: getItem('tokenDetails'),
    };
  }

  componentDidMount() {
    if (this.state.isLoggedIn) {
      this.getFlights(undefined);
    }
  }

  getFlights = (variables) => {
    console.log('getFlights', variables);
    this.setState({ loadingScreen: true });
    this.props.client.query({
      query: getTripItems,
      variables,
    }).then((tripItems) => {
      removeItem('guestLogin');
      this.setState({
        loadingScreen: false,
        tripItems: tripItems.data.tripItems,
        noFlight: tripItems.data.tripItems.length === 0,
        tripItem: tripItems.data.tripItems[0],
      });
    }).catch((err) => {
      this.setState({
        loadingScreen: false,
        noFlight: true,
      });
      console.log(err);
    });
  }

  handleAddNewHopComposite = (data) => {
    this.onHopRequestDone(data);
  }

  handleNoFlightSegments = () => {
    this.setState({
      noFlight: true,
      loadingScreen: false,
    });
  }

  handleSubmit = (payload) => {
    console.log('handleSubmit', payload);
    this.getFlights(payload);
  }

  handleClose = () => {
    this.setState({ tripItems: [] });
  }

  handleTripSelect = (e) => {
    const { id } = e.target;
    console.log(id);
    const tripItem = this.state.tripItems.find(trip => trip.hopRequests[0].id === parseInt(id, 10));
    this.setState({ tripItem });
  }

  render() {
    const {
      noFlight,
      loadingScreen,
      tripItems,
      isLoggedIn,
    } = this.state;
    return (
      <Wrapper>
        {(!isLoggedIn && tripItems.length === 0) &&
          <GuestForm handleSubmit={this.handleSubmit} loadingScreen={loadingScreen} />}
        {(!isLoggedIn && tripItems.length > 0) &&
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            iconColor="--red-color"
            icon="&#xE5CD;"
            size={32}
            iconSize={24}
            onClick={this.handleClose}
            style={{ marginBottom: 5 }}
          >close</Button>
        </div>}
        {noFlight &&
          <NoFlight>
            <p>{getTranslation('noPrevTrips')}</p>
            <div style={{ width: 296 }}>
              <Link to="/trip">
                <Button>{getTranslation('bookNewTrip')}</Button>
              </Link>
            </div>
          </NoFlight>}
        {(!loadingScreen && !noFlight) &&
        <Container>
          {tripItems.map((trip, i) => (
            <TripItem
              tripItem={trip}
              firstItem={i === 0}
            />
          ))}
        </Container>}
        {loadingScreen && <Loader />}
      </Wrapper>
    );
  }
}

export default MyTrips;
