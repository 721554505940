import React from 'react';
import styled from 'styled-components';
import { forgot } from 'utils/api';
import { Input, Button, Toast } from 'components/common';
import getTranslation from 'utils/getTranslation';

const Wrapper = styled.div`
background-color: #fff;
padding-bottom: 50px;
min-height: -webkit-fill-available;
.head {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 20px 0;
  .item {
    font-family: 'Roboto Condensed', sans-serif;
    color: #444444;
    font-size: 16px;
    cursor: pointer;
  }
  .pipe {
    height: 22px;
    background-color: #777777;
    margin: 0 20px;
    width: 1px;
  }
}
`;
const InputWrapper = styled.div`
width: 327px;
  margin: auto;
  .forgotPass {
    text-align: center;
    margin-top: 20px;
    a {
      color: #2D9CDB;
      font-family: 'Roboto';
    }
  }
`;

class Forgot extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            username: props.email,
            loading: false,
            reset: false,
        };
    }

    handleChange = (e) => {
        console.log('handleChange', e.target.name);
        this.setState({
            [e.target.name]: e.target.value,
        });
    }


    handleForgot = async () => {
        const payload = {
            login: this.state.username,
            // password: this.state.password,
        };
        this.setState({ loading: true });
        try {
            const forgotsubmitted = await forgot(payload);
            if (forgotsubmitted.success) {
                console.log(forgotsubmitted);
                this.setState({ reset: true });
            }
        } catch (err) {
            console.log(err);
            this.setState({
                loading: false,
                error: true,
                errorMsg: getTranslation('genericErrorMsg')
            });
        }
    }

    handleClose = () => {
        this.setState({ error: false });
    }

    handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            if (this.props.id) {
                document.getElementById(this.props.id).blur();
            }
            e.preventDefault();
            this.handleForgot();
        }
    }

    render() {
        const { username, reset, loading, error, errorMsg } = this.state;
        return (
            <Wrapper>

                <div className="head">
                    <div className={`item`}>{getTranslation('forgotpassword')}</div>

                </div>
                <div>

                    <InputWrapper>
                        {!reset && <React.Fragment>
                            <Input
                                name="username"
                                label={getTranslation('email')}
                                value={username}
                                onChange={this.handleChange}
                                autoFocus={true}
                                onKeyDown={this.handleKeyDown}
                            />
                            <div style={{ marginTop: 25 }}>
                                <Button onClick={this.handleForgot} disabled={loading || (!username)}>{getTranslation('forgotpassword')}</Button>
                            </div>

                            <Toast
                                open={error}
                                handleClose={this.handleClose}
                                message={errorMsg}
                            />
                        </React.Fragment>}
                        {reset && <h3 style={{ color: '#000', padding: 20, textAlign: 'center' }}>{getTranslation('resetRequestMessage')}</h3>}
                    </InputWrapper></div>

            </Wrapper>

        )
    }
}

export default Forgot;
