import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Button } from "components/common";
import getTranslation from "utils/getTranslation";
import { CloseIcon } from "components/common/SvgIcons";

const Wrapper = styled.div`
  background-color: rgba(240, 240, 240, 0.9);
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  position: fixed;
  top: 56px;
  width: calc(100% - 130px);
  right: 0;
  z-index: 99;
  backdrop-filter: blur(12px);
  @media (max-width: 800px) {
    width: 100%;
  }
  .text {
    margin-left: 20px;
    color: #777777;
    font-size: 18px;
    line-height: 21px;
  }
  .leftSection {
    display: flex;
    align-items: center;
  }
  .rightSection {
    width: 150px;
  }
`;

export default ({ text, route, disabled, handleClose, handleSubmit }) => (
  <Wrapper>
    <div className="leftSection">
      <Link to={route}>
        <Button
          onClick={handleClose}
          color="#D0F3FC"
          style={{ height: 32, maxWidth: 32, minWidth: 32 }}
        >
          <CloseIcon />
        </Button>
      </Link>
      <span className="text">{text}</span>
    </div>
    <div className="rightSection">
      <Button disabled={disabled} onClick={handleSubmit} style={{ height: 36 }}>
        {getTranslation("publish")}
      </Button>
    </div>
  </Wrapper>
);
