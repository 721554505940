import React, { PureComponent } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { graphql, compose } from "react-apollo";
import { setInfluencer } from "redux-store/actions";
import Modal from "components/Influencer/TipModal";
import CustomDomain from "components/Influencer/Partials/CustomDomain";
import {
  insertCustomDomain,
  updateCustomDomain
} from "containers/Influencer/queries";
import getTranslation from "utils/getTranslation";
import { getItem, removeItem } from "utils/utils";

const Wrapper = styled.div`
  width: 456px;
  margin: auto;
  background-color: #fff;
  padding: 24px 24px 0 24px;
  margin-bottom: 30px;
  p {
    font-size: 13px;
    color: #777777;
  }
  @media (max-width: 800px) {
    width: 100%;
  }
`;

class DomainSelectionModal extends PureComponent {
  state = {
    doShare: false
  };

  handleLayoutChange = selectedLayout => {
    this.setState({ selectedLayout });
  };

  handleDomainUpdate = domain => {
    this.setState({
      domain
    });
  };

  handleSubmit = async () => {
    try {
      this.setState({ loading: true });
      const { influencerDetails } = this.props;
      let response = await this.props.updateCustomDomain({
        variables: {
          _set: {
            domain: this.state.domain,
            available_at: null,
            unavailable_at: null
          },
          where: {
            influencer_id: { _eq: influencerDetails.id }
          }
        }
      });
      this.setState({ loading: false });
      const domainData = response.data.update_customsitedomain.returning[0];
      domainData.price_usd = getItem("selectedDomain").price_usd;
      this.props.setInfluencer({
        ...this.props.influencerDetails,
        customsitedomains: [domainData]
      });
      removeItem("selectedDomain");
      this.props.handleCloseModal();
    } catch (err) {
      this.setState({ loading: false });
    }
  };

  render() {
    const { loading } = this.state;
    const { open, influencerDetails, handleCloseModal } = this.props;
    return (
      <Modal
        open={open}
        text1={getTranslation("setupCustomDomain")}
        submitText={getTranslation("confirm")}
        handleCloseModal={handleCloseModal}
        handleSubmit={this.handleSubmit}
        loading={loading}
        dialogStyle={{ width: 800, maxWidth: 800 }}
      >
        <Wrapper>
          <p>{getTranslation("searchDomain")}</p>
          <CustomDomain
            selected={influencerDetails.custom_domain_selected}
            customDomains={influencerDetails.customsitedomains}
            handleClearDomain={this.handleClearDomain}
            handleDomainUpdate={this.handleDomainUpdate}
          />
        </Wrapper>
      </Modal>
    );
  }
}

export default compose(
  graphql(insertCustomDomain, { name: "insertCustomDomain" }),
  graphql(updateCustomDomain, { name: "updateCustomDomain" }),
  connect(null, { setInfluencer })
)(DomainSelectionModal);
