import React from 'react';
import { Query } from 'react-apollo';
import { isMobile } from 'react-device-detect';
import { xairportByIataLike } from '../queries';

export default ({
  airportIc,
  noMargin,
}) => (
  <Query query={xairportByIataLike} variables={{ iata: `${airportIc}%` }}>
    {({ data }) => (
      data.xairportByIataLike ? [
        <p style={{ color: '#444444', fontSize: 12 }}>
          {data.xairportByIataLike[0].airportDesc}
        </p>,
        <span style={{ fontSize: 18, fontWeight: 500, color: '#222222', margin: isMobile ? '10px 0' : 0 }}>
          {data.xairportByIataLike[0].city}
        </span>] : null
    )}
  </Query>
);
