export default {
  v: "5.6.5",
  fr: 30,
  ip: 155,
  op: 182,
  w: 390,
  h: 360,
  nm: "Benefit-2",
  ddd: 0,
  assets: [
    {
      id: "image_0",
      w: 44,
      h: 58,
      u: "/static/ae-images/Benefit-2/images/",
      p: "img_0.svg",
      e: 0
    },
    {
      id: "image_1",
      w: 41,
      h: 10,
      u: "/static/ae-images/Benefit-2/images/",
      p: "img_1.svg",
      e: 0
    },
    {
      id: "image_2",
      w: 89,
      h: 99,
      u: "/static/ae-images/Benefit-2/images/",
      p: "img_2.svg",
      e: 0
    },
    {
      id: "image_3",
      w: 411,
      h: 256,
      u: "/static/ae-images/Benefit-2/images/",
      p: "img_3.svg",
      e: 0
    }
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 2,
      nm: "money.png",
      cl: "svg",
      refId: "image_0",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [0.974] },
              o: { x: [0.333], y: [0] },
              t: 157,
              s: [100]
            },
            {
              i: { x: [0.667], y: [24.045] },
              o: { x: [0.333], y: [-15.818] },
              t: 158,
              s: [0]
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [-7.682] },
              t: 164,
              s: [0]
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 166,
              s: [0]
            },
            { t: 169, s: [100] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [192.5, 133.51, 0], ix: 2 },
        a: { a: 0, k: [22, 29, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 3,
      op: 453,
      st: 3,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 2,
      nm: "arrow1.png",
      cl: "svg",
      refId: "image_1",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [147, 126.51, 0], ix: 2 },
        a: { a: 0, k: [20.5, 5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ef: [
        {
          ty: 5,
          nm: "CC Line Sweep",
          np: 7,
          mn: "CS LineSweep",
          ix: 1,
          en: 1,
          ef: [
            {
              ty: 0,
              nm: "Completion",
              mn: "CS LineSweep-0001",
              ix: 1,
              v: {
                a: 1,
                k: [
                  {
                    i: { x: [0.613], y: [0.979] },
                    o: { x: [0.276], y: [0] },
                    t: 156,
                    s: [0]
                  },
                  {
                    i: { x: [0.682], y: [1] },
                    o: { x: [0.223], y: [-17.291] },
                    t: 157,
                    s: [100]
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 171,
                    s: [99]
                  },
                  { t: 174, s: [0] }
                ],
                ix: 1
              }
            },
            {
              ty: 0,
              nm: "Direction",
              mn: "CS LineSweep-0002",
              ix: 2,
              v: { a: 0, k: 180, ix: 2 }
            },
            {
              ty: 0,
              nm: "Thickness",
              mn: "CS LineSweep-0003",
              ix: 3,
              v: { a: 0, k: 50, ix: 3 }
            },
            {
              ty: 0,
              nm: "Slant",
              mn: "CS LineSweep-0004",
              ix: 4,
              v: { a: 0, k: 99, ix: 4 }
            },
            {
              ty: 7,
              nm: "Flip Direction",
              mn: "CS LineSweep-0005",
              ix: 5,
              v: { a: 0, k: 0, ix: 5 }
            }
          ]
        }
      ],
      ip: 0,
      op: 450,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 2,
      nm: "arrow2.png",
      cl: "svg",
      refId: "image_2",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [261, 185.51, 0], ix: 2 },
        a: { a: 0, k: [44.5, 49.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ef: [
        {
          ty: 5,
          nm: "CC Line Sweep",
          np: 7,
          mn: "CS LineSweep",
          ix: 1,
          en: 1,
          ef: [
            {
              ty: 0,
              nm: "Completion",
              mn: "CS LineSweep-0001",
              ix: 1,
              v: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.167], y: [0] },
                    t: 157,
                    s: [0]
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 160,
                    s: [99]
                  },
                  { t: 164, s: [0] }
                ],
                ix: 1
              }
            },
            {
              ty: 0,
              nm: "Direction",
              mn: "CS LineSweep-0002",
              ix: 2,
              v: { a: 0, k: 545, ix: 2 }
            },
            {
              ty: 0,
              nm: "Thickness",
              mn: "CS LineSweep-0003",
              ix: 3,
              v: { a: 0, k: 50, ix: 3 }
            },
            {
              ty: 0,
              nm: "Slant",
              mn: "CS LineSweep-0004",
              ix: 4,
              v: { a: 0, k: 99, ix: 4 }
            },
            {
              ty: 7,
              nm: "Flip Direction",
              mn: "CS LineSweep-0005",
              ix: 5,
              v: { a: 0, k: 0, ix: 5 }
            }
          ]
        }
      ],
      ip: 3,
      op: 453,
      st: 3,
      bm: 0
    },
    {
      ddd: 0,
      ind: 4,
      ty: 2,
      nm: "people.png",
      cl: "svg",
      refId: "image_3",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [206.5, 181.51, 0], ix: 2 },
        a: { a: 0, k: [205.5, 128, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      hasMask: true,
      masksProperties: [
        {
          inv: false,
          mode: "a",
          pt: {
            a: 0,
            k: {
              i: [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0]
              ],
              o: [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0]
              ],
              v: [
                [0, 0],
                [0, 256],
                [411, 256],
                [411, 0]
              ],
              c: true
            },
            ix: 1
          },
          o: { a: 0, k: 100, ix: 3 },
          x: { a: 0, k: 0, ix: 4 },
          nm: "Mask 1"
        }
      ],
      ef: [
        {
          ty: 5,
          nm: "Paint",
          np: 4,
          mn: "ADBE Paint",
          ix: 1,
          en: 1,
          ef: [
            {
              ty: 7,
              nm: "Paint on Transparent",
              mn: "ADBE Paint On Transparent",
              ix: 1,
              v: { a: 0, k: 0, ix: 1 }
            },
            {
              ty: 30,
              nm: "Strokes",
              np: 10,
              mn: "ADBE Paint Group",
              ix: 2,
              en: 1,
              ef: []
            }
          ]
        }
      ],
      ip: 3,
      op: 453,
      st: 3,
      bm: 0
    }
  ],
  markers: []
};
