import React from "react";
import { ApolloConsumer, withApollo } from "react-apollo";
import Booking from "containers/Booking/Booking";
import hotelClient from "hotelClient";


export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tripId: parseInt(this.props.match.params.tripId, 10)
    };
  }
  render() {
    const { tripId } = this.state;
    return (
      <ApolloConsumer>
        {client => <Booking client={client} tripId={tripId} askOtp />}
      </ApolloConsumer>
    );
  }
}
withApollo(Booking);