export default `
Les présentes conditions générales («CG») s'appliquent entre vous («vous» ou le «client») et Fly Strait OÜ («nous»), l'exploitant du portail de réservation flystrait.com (indiqué ci-après par le «portail»). Nous vous prions de lire attentivement ces conditions d'utilisation avant d’utiliser le portail. En utilisant le portail, vous acceptez ces conditions d'utilisation. Si vous ne pouvez accepter ces conditions, nous vous demandons de ne pas utiliser le portail et de quitter le site Web.

&nbsp;

Vous devez avoir au moins 18 ans et agir en tant que consommateur privé pour pouvoir effectuer une réservation sur le portail. Un consommateur privé est, par opposition à une entreprise, une personne physique qui conclut une transaction juridique à des fins qui se situent principalement en dehors de ses activités commerciales et / ou indépendantes.

Le gestionnaire du portail et votre partenaire contractuel pour l’utilisation du portail sont:

&nbsp;

Fly Strait OÜ (Fly Strait)

E-mail: support@flystrait.com

&nbsp;

Les conditions d'utilisation sont les suivantes:
# 1. Services proposés
### 1.1 Intermédiaire de prestations de voyage
1.1.1 Pour les services de réservation de vols proposés sur le portail (ci-après dénommés «prestations de voyage»), nous agissons exclusivement en tant qu'intermédiaire. Dans ce cadre, notre rôle et nos obligations se limitent à la médiation de prestations de voyage qui seront fournis par des tiers tels que des compagnies aériennes (ci-après désigné par «prestataire de service»).

&nbsp;

1.1.2 Par conséquent, le contrat relatif à la fourniture effective de services de voyage intervient directement entre vous et le prestataire de services concerné. Nous ne sommes pas le co-vendeur de prestations de voyage et nous ne prenons pas part à la relation contractuelle entre vous et le prestataire de services.

&nbsp;

1.1.3 En utilisant le portail pour acheter des services de voyage, vous nous autorisez à négocier avec le(s) prestataire(s) de services correspondant(s) en votre nom, y compris la médiation du paiement de ces services de voyage, afin de veiller à ce que la transaction entre vous et le(s) prestataire(s) de services est effectué. Le prix émis pour les services de voyage réels peut inclure une commission sur les services que nous rendons dans notre rôle d'intermédiaire dans la conclusion d'un accord entre vous et le prestataire de services concerné.

&nbsp;

1.1.4 La responsabilité de la performance réelle des prestations de voyage négociées via le portail incombe exclusivement au prestataire de services concerné. En tant qu'intermédiaire, nous n'assumons aucune responsabilité quant aux services de voyage devant être fournis par les prestataires de services et nous ne faisons aucune déclaration ni garantie (ni expressément ni implicitement) concernant la pertinence ou la qualité des services de voyage négociés sur le portail. Pour toute réclamation dans le contexte de l'exécution ou de la non-exécution de la prestation de voyage, veuillez adresser directement au prestataire de service responsable.

&nbsp;

1.1.5 Si vous avez besoin de faire part de souhaits ou de besoins particuliers (tels que des repas spéciaux, des installations pour personnes à mobilité réduite ou des sièges pour enfants) lors de la réservation de votre voyage, nous transmettrons votre demande au prestataire de services concerné. Cependant, nous ne garantissons par que le prestataire de services puisse réellement satisfaire votre demande.

&nbsp;

### 1.2 Conditions contractuelles applicables
1.2.1 Les présentes conditions s'appliquent à la médiation des prestations de voyage telles que présentées à la section 1.1 ainsi qu'aux services propres à Flystrait tels que présentés à la section 1.2.

&nbsp;

1.2.2 En ce qui concerne l'accord entre vous-même et le prestataire de services concerné (section 1.1.2), les conditions générales du prestataire de services concerné (telles que les conditions de transport, de transfert, d'assurance ou autres), telles que publiées par le prestataire de services s'appliquent. Les conditions générales propres au prestataire de services concerné vous seront notifiées au cours du processus de réservation. Étant donné que les conditions générales du fournisseur de services peuvent inclure des dispositions relatives à la responsabilité, à l’annulation, aux modifications de réservation et aux remboursements (le cas échéant) et à d’autres restrictions, il est conseillé de les lire attentivement.

# 2. Processus de réservation et informations de contact
2.1 Pendant le processus de réservation, vous disposerez des moyens techniques nécessaires pour détecter les erreurs dans les formulaires de participation et pour les corriger avant de soumettre votre demande de réservation. Il vous sera demandé de vérifier l'exactitude de toutes les données avant de conclure votre demande de réservation. Les demandes de modification ultérieures peuvent entraîner des coûts supplémentaires importants.

&nbsp;

2.2 En cas de modifications ultérieures des services de voyage achetés par exemple, il se peut que nous devions vous contacter. Vous devrez fournir des informations de contact précises, telles que votre numéro de téléphone et votre adresse électronique. Vous devrez également être en mesure de régulièrement vérifier si vous avez reçu un message de notre part.

&nbsp;

Il est également de votre responsabilité de vous assurer de pouvoir recevoir nos messages. Nous ne sommes pas responsables de la non réception de messages de notre part en raison de circonstances raisonnablement indépendantes de notre volonté, notamment les suivantes: (i) vous nous avez fourni une adresse électronique incorrecte; (ii) vos paramètres de courrier électronique ne permettent pas à notre courrier électronique de vous atteindre; ou (iii) vos paramètres de messagerie traitent notre courrier en tant que spam.

# 3. Intermédiaire de réservation de vols

### 3.1 Conclusion du contrat et modifications du tarif
3.1.1 Après avoir envoyé votre demande de réservation, la réception de votre demande vous sera confirmée par courrier électronique. C'est à ce moment précis que le contrat d'intermédiaire de voyage entre vous et nous prend effet. Si vous ne recevez pas cette confirmation dans l'heure qui suit votre demande de réservation et qu'aucun de nos opérateurs ne vous a contacté par e-mail ou par téléphone pour nous signaler un problème, veuillez nous contacter par téléphone pour vérification.

&nbsp;

3.1.2 Dès que les billets demandés auront été émis, vous recevrez un email de confirmation avec un numéro de billet. C'est à ce moment que le contrat entre vous-même et la ou les compagnies aériennes concernées se conclut.

&nbsp;

3.1.3 Les prix du vol, ainsi que la disponibilité des sièges, sont directement indiqués par la compagnie aérienne concernée. Pour les modifications de prix des compagnies aériennes indépendantes de notre volonté intervenant après le dépôt de la demande de réservation, mais avant que le contrat avec la compagnie aérienne ne devienne contraignant (comme indiqué à la section 3.1.2 ci-dessus), le contrat n'entrera pas en vigueur et votre paiement sera entièrement remboursé. Nous pouvons vous contacter et vous offrir la possibilité d'accepter le prix modifié pendant les heures d'ouverture ordinaires, mais au plus tard 24 heures après avoir pris connaissance du changement de prix ou le premier jour de semaine suivant cette période.

&nbsp;

### 3.2 Informations sur les conditions de réservation et de transport
3.2.1 En ce qui concerne les vols proposés sur le portail, nous agissons exclusivement dans le cadre des limites imposées par notre rôle d'intermédiaire de prestation de services. Le contrat de prestation du vol entre directement en vigueur entre vous et la compagnie aérienne concernée et nous déclinons toute responsabilité contractuelle en ce qui concerne l’exécution ou la non-exécution de votre vol. La compagnie aérienne exploitante est tenue pour seule responsable de la performance / non-performance de votre vol.

&nbsp;

3.2.2 Avant de conclure la réservation, les conditions générales de la ou des compagnies aériennes concernées vous seront communiquées.

&nbsp;

3.2.3 Ci-dessous, à titre d'information générale, nous fournissons un aperçu des conditions relatives à la réservation et au transport telles que généralement appliquées par les compagnies aériennes, de manière plus ou moins similaire. Toutefois, les dispositions de la compagnie aérienne concernée qui divergent des informations générales fournies dans la présente section 3.2.3, prévalent. Par conséquent, dans chaque cas et avant votre réservation, veuillez vérifier les termes et conditions applicables de la compagnie aérienne concernée.

&nbsp;

#### a. Horaires de vol / check in
Tous les horaires de vol indiqués, correspondent à l'horaire local. Les arrivées le lendemain sont marquées d'un «+1» sur l'horaire. Les heures de vol indiquées sont des informations préliminaires, c'est pourquoi après l’émission du billet les horaires sont sujets à changement avec un court préavis, en raison par exemple, de restrictions imposées par les contrôles de vol, les conditions météorologiques ou de restrictions fonctionnelles imposées par la compagnie aérienne. Veuillez rester informés des horaires de vol mis à jour avant votre vol.

&nbsp;

Veuillez respecter les horaires d'enregistrement indiqués par la compagnie aérienne. Les compagnies aériennes sont autorisées à vous refuser l'embarquement si vous êtes en retard à l'enregistrement. Veuillez noter que certaines compagnies aériennes encouragent l’enregistrement sur leur propre site Web, étant donné qu’elles peuvent facturer des frais d’enregistrement lors de l’enregistrement manuel à l’aéroport.

&nbsp;

#### b. Combinaison de billets individuels
Une combinaison de deux billets aller simple séparés au lieu d'un billet aller-retour est clairement indiquée en tant que telle lors de la procédure de réservation. En cas d'annulation, de modification, de perturbation du trafic aérien, telle que des grèves et des modifications d'horaires de vol, les billets sont traités indépendamment les uns des autres. Dans ce cas, les règlements de chaque compagnie aérienne s'appliquent séparément.

&nbsp;

Les billets d'avion portant des numéros de réservation différents sont toujours considérés comme des voyages indépendants les uns des autres.

&nbsp;

#### c. Billets d'avion avec plusieurs segments / ordre d'utilisation
Votre billet aller-retour ou aller simple peut comprendre plusieurs segments. Selon les conditions de la plupart des compagnies aériennes, ces segments de vol doivent être utilisés selon un ordre bien défini. Dans le cas contraire, de nombreuses compagnies aériennes refuseront le transport sur des segments de vol ultérieurs (par exemple, le fait de ne pas utiliser un segment de voyage peut invalider le reste du billet). Pour les billets aller-retour, une non-présentation sur votre vol aller peut entraîner l'annulation de votre vol aller par la compagnie aérienne.

&nbsp;

#### d. En cas de grossesse
Certaines compagnies aériennes refusent de prendre en charge les femmes qui ont passé la 28e semaine de grossesse au moment du vol aller ou du vol retour. Si vous êtes enceinte, vous devez vérifier avec la compagnie aérienne et votre médecin si vous êtes en mesure d'effectuer ou non le voyage.

&nbsp;

#### e. Billets bébés et enfants
Veuillez contacter la compagnie aérienne pour obtenir les conditions pour voyager avec un enfant qui n'a pas de siège séparé. Habituellement, les enfants de plus de 2 ans ont besoin d'un siège séparé, tandis que les enfants de 0 à 2 ans voyagent en bas âge et ne se voient pas attribuer de siège. Si l'enfant atteint l'âge de 2 ans avant la fin du voyage, un billet enfant doit être réservé pour tout le voyage. Les billets pour bébé ne peuvent pas être réservés avant la naissance, car le nom et la date de naissance doivent correspondre à ceux indiqués dans le passeport. Aucun frais ne seront remboursés si le mauvais type de billet est réservé dès le départ.

&nbsp;

#### f. Mineurs non accompagnés
Nous n'intervenons pas dans la réservation de billets pour les mineurs non accompagnés. Les enfants de moins de 18 ans doivent voyager en compagnie d'un adulte. Certains pays et certaines compagnies aériennes refusent l'entrée aux enfants de moins de 18 ans s'ils ne sont pas accompagnés d'un tuteur légal. Veuillez noter que certaines compagnies aériennes exigent que les enfants de moins de 18 ans soient munis d'un certificat de naissance pour pouvoir voyager.

&nbsp;

#### g. Bagages perdus / endommagés
En notre qualité de prestataire de service intermédiaire, nous n'assumons aucune responsabilité pour les bagages perdus ou endommagés. Tout problème doit être immédiatement signalé au représentant de la compagnie aérienne à l'aéroport.

&nbsp;

#### h. Transit et hébergement
En règle générale, les frais de transfert vers le lieu d'hébergement et/ou l'hébergement en soit pendant votre transit ne sont pas inclus dans le prix du billet. Vous êtes personnellement responsable de vérifier les horaires et les frais de transport au sol.

&nbsp;

#### i. Délais de transfert des vols en correspondance
Les tickets standard réservés sur le portail le sont selon des horaires de connexion validés. Les temps nécessaires pour établir les correspondances entre les vols sont calculés par les compagnies aériennes. Si un segment de vol est retardé et entraîne la perte d'une correspondance, les compagnies aériennes sont dans l'obligation de vous aider à atteindre votre destination finale (voir section 9.1).

&nbsp;

Lorsque des billets sont réservés séparément, les compagnies aériennes n'assumeront aucune responsabilité dans le cas de correspondances manquées résultant de retards. Par conséquent, il est de votre responsabilité de vous assurer que le temps de connexion est suffisant selon les compagnies aériennes et les aéroports. Les frais supplémentaires occasionnés par les correspondances manquées ne seront pas remboursés.

&nbsp;

#### j. Double ou multiple(s) réservation(s)
Une réservation à double signifie que deux réservations ou plus portant le même nom de passager ont été effectuées auprès de la même compagnie aérienne. Si vous effectuez une double réservation, il est possible que la compagnie annule le voyage. Cela peut également se produire si les réservations ont été effectuées auprès de différentes agences de voyages. Dans le cas où des compagnies aériennes soupçonneraient une double réservation, nous déclinons toute responsabilité pour les annulations et les refus de remboursement qui pourrait s'en suivre.

&nbsp;

### 3.3 Compagnies aériennes interdites d'opérer dans l'UE (liste noire)
Veuillez noter que certaines compagnies aériennes ne sont pas autorisées à opérer dans l'Union européenne, conformément à une décision prise par la Commission européenne en étroite consultation avec les autorités nationales du transport aérien. Ces compagnies aériennes sont interdites car elles sont considérées comme peu sûres ou ne sont pas soumises à un contrôle adéquat par les autorités de leur pays.

&nbsp;

Vous pouvez vérifier quelles compagnies aériennes sont concernées par une interdiction d'exploitation via le lien suivant:
Liste noire (Liste des compagnies aériennes non autorisées à opérer dans l'UE)(https://ec.europa.eu/transport/language-selector/site-language_en?destination=node/2840)

&nbsp;

### 3.4 Changements d'horaires et annulations effectuées par les compagnies aériennes

3.4.1 L'accord conclu avec la compagnie aérienne choisie peut leur permettre d'annuler ou de modifier vos réservations. Nous vous informerons de tout changement une fois que nous en serons informés par la compagnie aérienne.

&nbsp;

3.4.2 Les heures de vol indiquées dans votre confirmation de réservation peuvent changer entre la date de votre réservation et la date effective du vol. En cas de changement d'horaire du vol, vous en serez averti dès que nous en serons informés par la compagnie aérienne. Cependant, nous vous recommandons vivement de contacter votre compagnie aérienne au moins 72 heures avant l'heure de départ prévue de chaque vol pour vous assurer que le ou les vols (et les vols de correspondance) vont s'effectuer comme prévu. Nous n'avons aucun contrôle sur les modifications d'horaire de la compagnie aérienne et déclinons toute responsabilité pour les frais qui pourraient en résulter.

&nbsp;

### 3.5 Modifications et annulations demandées par le client
Pour les compagnies aériennes low cost (à bas prix), se référer à la section 3.7.

&nbsp;

3.5.1 Les conditions de modification des réservations de vol (y compris le changement du nom du passager, de la destination et de la date du voyage) et du remboursement de l'annulation sont définies par la compagnie aérienne concernée, qui est votre partenaire contractuel pour la prestation du vol. Dans notre rôle d’intermédiaire, nous n’avons aucune influence sur ces conditions.

&nbsp;

3.5.2 Si vous souhaitez modifier votre réservation ou demander un remboursement pour avoir annulé votre vol, et à condition que les conditions de la compagnie aérienne permettent un tel remboursement ou annulation, l'une de nos prestations de service supplémentaire vous permet de nous laisser de traiter la demande en votre nom. Au cours du processus de réservation de ces services supplémentaires, nous vous informerons de toute condition supplémentaire relative à ces services. Bien entendu, vous pouvez également contacter directement la compagnie aérienne concernée.

&nbsp;

3.5.3 Pour que nous puissions traiter les modifications demandées par le client, il est nécessaire que Nous recevions vos demandes de modification au plus tard 24 heures avant le début du voyage (uniquement par téléphone). Si vous avez acheté notre service Ticket flexible, veuillez vous reportez à la section 10.

&nbsp;

Pour les demandes de modification qui devrait intervenir dans un délai plus court, nous vous recommandons de contacter directement la compagnie aérienne concernée.

&nbsp;

### 3.6 Non comparution ou absence du vol
En cas de non-comparution ou d'absence du vol, par la présente, vous nous autorisez à annuler le vol non utilisé en votre nom et à demander un éventuel remboursement à la compagnie aérienne en votre nom. Nous avons l'autorisation de procéder de cette manière, sans en être pour autant tenus. Votre droit de demander des remboursements directement à la compagnie aérienne reste inchangé.

&nbsp;

### 3.7 Compagnies aériennes bas prix (ci-après "low cost")
3.7.1 Pendant la procédure de réservation, il vous sera clairement indiqué si le vol concerné est exploité par une compagnie aérienne à bas prix. Les dispositions spéciales suivantes s'appliquent aux vols effectués par des compagnies aériennes low cost.

&nbsp;

3.7.2 Lors de la médiation de services de voyage rendus par des compagnies aériennes low cost, nous nous sommes autorisés à agir en votre nom afin de conclure le contrat entre vous et la compagnie aérienne low cost.

&nbsp;

3.7.3 Nous n'avons pas accès aux systèmes de réservation des compagnies aériennes low cost. Par conséquent, veuillez contacter directement la compagnie aérienne low cost concernée pour toute question sur les réservations, les modifications ou l'annulation de votre réservation. Si vous avez acheté notre service de billet flexible (voir Section 10), toutes les demandes de modification de réservation doivent être effectuées via notre service clientèle, tel qu'indiqué à la Section 10.1.4.

&nbsp;

3.7.4 Veuillez noter également que certaines compagnies aériennes low cost facturent des frais d’enregistrement manuel à l’aéroport. Afin d'éviter de tels frais, vous devez utiliser la possibilité d'enregistrement en ligne via le site Web de la compagnie aérienne low cost concernée. Veuillez vous informer à temps, bien avant votre départ, des possibilités d’enregistrement et des conditions proposées par la compagnie aérienne choisie.

&nbsp;

3.7.5 Les confirmations de réservation pour les vols low cost sont envoyées dès que la réservation a été confirmée par la compagnie aérienne low cost correspondante. Vous pouvez recevoir deux confirmations de réservation - une de notre part et l’autre de la compagnie aérienne low cost concernée. Si vous avez reçu deux confirmations de réservation, veuillez utiliser la confirmation de réservation de la compagnie aérienne low cost pour votre enregistrement.

&nbsp;

# 4. Dispositions spéciales pour la médiation de réservations de plusieurs services
Le portail offre la possibilité de combiner plusieurs services individuels à votre guise (par exemple, vol + hôtel). Dans ce cas, vous nous demandez d’agir en tant qu’intermédiaire pour la réservation de divers services de voyage auprès de divers fournisseurs. Avant la finalisation de la réservation, les fournisseurs concernés vous seront clairement présentés au cours de la procédure de réservation. Si vous combinez des services individuels, aucun contrat de voyage n'est conclu entre vous et nous. Au lieu de cela, vous concluez plusieurs contrats pour la fourniture de services individuels avec chaque fournisseur. Dans ce cas, nous agissons uniquement dans le cadre de notre capacité d'intermédiaire pour chaque service de voyage individuel.

&nbsp;

# 5. Informations sur les dispositions relatives aux passeport, visa et l'état de santé
5.1 Les exigences en matière de passeport, de visa et / ou de santé peuvent changer et vous devez donc vous renseigner auprès des autorités compétentes (ambassade, consulat, etc.) bien avant le voyage. Il est de votre responsabilité d'être en possession d'un passeport valable et, le cas échéant, d’un visa. N'oubliez pas d'inclure tous les points par lesquels vous aller effectuer un transit lors de votre voyage, ce qui peut également nécessiter l'obtention d'un visa. Les délais d’obtention d’un visa peuvent souvent être assez longs, c'est pourquoi il vous est recommandé de compléter toutes les formalités le plus rapidement possible et suffisamment tôt. Nous n'assumons aucune responsabilité dans le cas où les clients ne possèderaient pas les documents adéquats pour effectuer leur voyage.

&nbsp;

5.2 Pour chaque destination, des exigences particulières s'appliquent ce qui concerne les formalités d'entrée, les vaccinations, etc., qui peuvent également varier en fonction de la nationalité du passager. Il est de votre responsabilité de rassembler toutes ces informations. Nous n'assumons aucune responsabilité dans le cas d'incident résultant du non-respect de ces réglementations officielles. Nous vous invitons donc à toujours vérifier les différentes formalités de la destination choisie ou des pays de transit, ainsi que le temps nécessaire pour effectuer toutes les démarches s'y rapportant.

# 6. Tarifs et paiement

&nbsp;

### 6.1 Paiement
6.1.1 Le paiement des prestations de voyage est effectué par nous-mêmes (en coopération avec nos prestataires de services de paiement offrant la fonctionnalité de traitement des paiements) ou directement par le prestataire de services. Veuillez noter que nous pourrions avoir besoin de partager vos informations de paiement, telles que les informations de carte de crédit ou de débit, avec nos fournisseurs de services de paiement afin de pouvoir effectuer le paiement. Lorsqu'elles sont partagées avec nos fournisseurs de services de paiement, toutes les informations de paiement appartenant à nos clients sont cryptées sur un serveur sécurisé.

&nbsp;

6.1.2 Pour que nous puissions traiter votre paiement, vous devez vous assurer que des fonds suffisants sont disponibles. En cas de problème lié au traitement de votre paiement, nous relancerons le traitement de votre paiement en coopération avec nos prestataires de services de paiement. Si le transfert de fonds de votre part n'a pas pu être effectué correctement, nous vous contacterons dès que possible pour obtenir des instructions sur d'autres moyens de paiement. Si nous ne recevons pas votre paiement suite à ces nouvelles instructions, votre paiement tardif sera renvoyé à une société de recouvrement.

&nbsp;
### 6.2 Fraude au paiement
Si nous avons des raisons de soupçonner qu'une fraude a été commise, nous nous réservons le droit de refuser le traitement de ce paiement. La justification du paiement peut être exigée si un crime est suspecté. Tous les types de fraude au paiement seront signalés à la police et transmis à une société de recouvrement.

&nbsp;

# 7. Plaintes
### 7.1 Réclamations relatives à la prestation de services de voyage
Tout problème, remarque ou réclamation lié à l'exécution effective des services de voyage doit être adressé directement au fournisseur de services concerné avec lequel vous êtes titulaire d'un contrat du service de voyage concerné. Veuillez également consulter la section 9 pour connaître vos droits conformément à la réglementation de l'UE.

&nbsp;

### 7.2 Réclamations relatives à nos propres services
Les plaintes relatives à nos propres services sont traitées uniquement par écrit et doivent être soumises par courrier électronique au plus tard dans les deux mois suivant la date de fin de votre voyage.

&nbsp;

Adresse e-mail: [complaints@flystrait.com](mailto:complaints@flystrait.com)

&nbsp;

# 8. Droits du client conformément à la réglementation de l'UE

### 8.1 Vols annulés ou retardés
Si vous voyagez à l'intérieur ou à l'extérieur de l'UE ou sur un transporteur de l'UE, vous pouvez éventuellement demander le remboursement des frais que vous pouvez imputer directement à la compagnie aérienne concernée un cas où votre vol serait annulé, retardé ou l'embarquement vous aurait été refusé. [Pour plus d'informations sur le règlement CE 261/2004, veuillez cliquer ici.](http://eur-lex.europa.eu/legal-content/EN/ALL/?uri=CELEX%3A32004R0261)

&nbsp;

### 8.2 Responsabilité du transporteur
Le règlement CE (889/2002) sur la responsabilité des transporteurs aériens en cas d'accident. [Vous pouvez accéder au règlement ici.](http://eur-lex.europa.eu/legal-content/EN/TXT/?uri=celex%3A32002R0889)

&nbsp;

# 9. Responsabilité

9.1 Vous acceptez que nous agissions en tant qu'intermédiaire entre vous et le prestataire de services. Nous ne pourrons en aucun cas être tenus responsables des prestations de voyage que vous réservez auprès d'un ou de plusieurs prestataires de services et nous déclinons toute responsabilité pour toute inexactitude dans les informations affichées sur le portail et qui sont fournies par les prestataires de services respectifs.

&nbsp;

9.2 Si un prestataire de services est incapable de fournir la prestation de voyage pour une raison quelconque, y compris lorsque le prestataire de services déclare faillite, nous ne pouvons agir en tant qu'intermédiaire et rembourser les paiements lorsque nous les avons déjà reçus du prestataire de services concerné.

&nbsp;

9.3 En ce qui concerne nos propres services, nous sommes responsables des dommages, sous réserve des limitations énoncées dans les présentes conditions générales et dans la mesure autorisée par la loi. Nous ne serons responsables que des dommages directs réellement subis, payés ou encourus par Vous en raison d'une défaillance imputable de nos obligations vis-à-vis de nos propres services, jusqu'à concurrence d'un montant global du coût de votre réservation (qu'il s'agisse d'un événement ou d'une série d'événements en lien les uns avec les autres).

&nbsp;

9.4 Les limites de notre responsabilité énoncée à la section 10.3 s’applique également aux manquements aux devoirs de la part de personnes dont nous sommes responsables en vertu des dispositions légales.

&nbsp;

# 10. Loi applicable et règlement des litiges

&nbsp;

### 10.1 Loi applicable
Le droit estonien, à l’exclusion du droit matériel uniforme, en particulier la Convention des Nations Unies sur les contrats de vente internationale de marchandises, s’applique exclusivement à ces conditions générales et au contrat entre vous et nous.

&nbsp;

En tant que consommateur, vous bénéficierez des dispositions impératives de la loi du pays dans lequel vous résidez. Rien dans ces CGV, y compris la présente section 11.1, n'affecte votre droit en tant que consommateur de se prévaloir des dispositions impératives de la législation locale.

&nbsp;

### 10.2 Résolution des litiges en ligne

La Commission européenne fournit une plate-forme de résolution des litiges en ligne sous: (http://ec.europa.eu/consumers/odr/)

&nbsp;

# 11. Protection des données

Nous attachons une très grande importance à la protection de vos données personnelles. Vous trouverez des informations détaillées sur la collecte, le traitement et l'utilisation de vos données personnelles dans notre [politique de confidentialité.](/privacy)
`;
