import React from 'react';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import AirlineItem from './AirlineItem';
import { getItem, setItem } from 'utils/utils';

const Wrapper = styled.div`
  padding: 15px;
`;

const Loader = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
  margin-bottom: 15px;
  height: 64px;
  .css-19bon8n {
    border-radius: 10em;
  }
`;

const loaderArray = [1, 2, 3];

class Airline extends React.PureComponent {
  constructor(props) {
    super(props);
    const groupedResults = this.handleGrouping(props.trips);
    this.state = {
      groupedResults,
      filterKvs: props.filterKvs
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.loading && nextProps.loading !== this.props.loading) {
      const groupedResults = this.handleGrouping(nextProps.trips);
      this.setState({
        groupedResults,
        filterKvs: nextProps.filterKvs
      });
    }
  }

  handleGrouping = (trips) => {
    let grouped = [];
    trips.forEach((trip) => {
      trip.hopRequests.forEach((hopRequest) => {
        const { airlineIc } = hopRequest.hopSegmentControls[0].flightSegments[0];
        const airlineIcIndex = grouped.findIndex(item => item.airlineIc === airlineIc);
        if (airlineIcIndex > -1) {
          // const exists = grouped[airlineIcIndex].trips.find(item => item.rank === trip.rank);
        } else {
          grouped = [...grouped, { airlineIc }];
        }
      });
    });
    const initialAirlineIcs = getItem('initialAirlineIcs') || [];
    if (!initialAirlineIcs || grouped.length > initialAirlineIcs.length) {
      const airlineIcs = grouped.map(group => (group.airlineIc));
      setItem('initialAirlineIcs', airlineIcs);
    }
    return grouped;
  }

  handleFilterToggle = (airlineIc, isInputChecked) => {
    this.props.handleFiltersUpdate('AIRLINE', airlineIc, isInputChecked);
  }

  handleAirlineDisplayed = (airlineIc) => {
    let displayed = this.state.filterKvs.find(item => item.value === airlineIc);
    if (!displayed) {
      displayed = this.state.groupedResults.find(group => group.airlineIc === airlineIc);
    }
    return displayed;
  }

  render() {
    const { groupedResults, filterKvs } = this.state;
    const { initialFilterKvs, loading } = this.props;
    return (
      <Wrapper>
        {!loading ? <React.Fragment>
          {groupedResults.map(item => (
            <AirlineItem
              key={item.airlineIc}
              item={item}
              handleFilterToggle={this.handleFilterToggle}
              filterKvs={filterKvs}
            />
          ))}
          {(initialFilterKvs && filterKvs.length > 0) && filterKvs.map(filter => (
            filter.filterKey === 'AIRLINE' && <AirlineItem
              key={filter.id}
              item={{ airlineIc: filter.value }}
              handleFilterToggle={this.handleFilterToggle}
              filterKvs={filterKvs}
              disabled
            />
          ))}
          {getItem('initialAirlineIcs').map(airlineIc => (
            !this.handleAirlineDisplayed(airlineIc) && <AirlineItem
              key={airlineIc}
              filterKvs={[]}
              item={{ airlineIc: airlineIc }}
              disabledState
            />
          ))}
        </React.Fragment> : <React.Fragment>
          {loaderArray.map(loader => (
            <Loader key={loader}>
              <Skeleton height={30} width={95} />
              <Skeleton height={16} width={125} />
            </Loader>
          ))}
        </React.Fragment>}
      </Wrapper>
    )
  }
}

export default Airline;
