export default `
# Terms and conditions page

Ці загальні положення та умови (&quot;Умови&quot;) застосовуються між вами (&quot;Ви&quot; або &quot;Клієнт&quot;) та Fly Strait OÜ (&quot;Ми&quot; або &quot;Нам&quot;), оператором бронювання порталу flystrait.com (далі - як &quot;Портал&quot;). Просимо Вас уважно ознайомитися з Умовами перед використанням Порталу. Використовуючи портал, Ви погоджуєтеся з цими Умовами. Якщо Ви не приймаєте ці Умови, ми просимо Вас утриматися від використання Порталу та вийти з веб-сайту.

Ви повинні бути віком не менше ніж 18 років і виступати у ролі приватного споживача, щоб зробити замовлення на Порталі. На відміну від юридичної особи, споживач - це фізична особа, яка вступає в юридичну угоду для цілей, які переважно не пов&#39;язані з комерційною та / або самостійною діяльністю.

Оператором порталу та Вашим контрагентом для використання порталу є:

Fly Strait OÜ (Fly Strait)

Електронна пошта: support@flystrait.com

Технічні вимоги визначаються таким чином:

#
# 1.Сфера послуг

### 1.1 Посередництво в туристичних послугах

1.1.1. Для послуг бронювання авіаквитків, запропонованих на Порталі (далі - &quot;Туристичні послуги&quot;), ми виступаємо виключно як посередник. З цією метою наша роль і обов&#39;язки обмежуються посередництвом туристичних послуг, які надаватимуться третіми сторонами, такими як авіакомпанії (надалі у кожному випадку &quot;Постачальник послуг&quot;).

1.1.2. Таким чином, угода про фактичне надання туристичних послуг набуває чинності безпосередньо між Вами та відповідним постачальником послуг. Ми не є спільним постачальником туристичних послуг і ми не є учасником договірних відносин між Вами та постачальником послуг.

1.1.3 Використовуючи Портал для придбання Туристичних послуг, Ви дозволяєте нам бути посередником з відповідним Постачальником послуги (послуг) від Вашого імені, включаючи посередництво за плату за ці Туристичні послуги, щоб переконатись у тому, що угода між Вами та Постачальник послуги (послуг) здійсниться. Ціна за фактичнї Туристичнї послуги може включати комісію за послуги, які ми надаємо для угоди посередництва між Вами та відповідним Постачальником послуг.

1.1.4 Відповідальність за фактичне виконання туристичних послуг, що здійснюються через портал,  несе виключно постачальник послуг. У ролі посередника ми не беремо на себе ніяку відповідальність за надання туристичних послуг, які надаються Постачальниками послуг і ми не даємо гарантій (ні висловлених, ні неявних) стосовно придатності або якості туристичних послуг, опосередкованих на порталі. Відповідальним адресатом для отримання будь-яких претензій, які Ви можете мати в контексті роботи або невиконання Послуг, є Постачальник послуг.

1.1.5. Ваші спеціальні побажання (наприклад, спеціальні страви, зручності для інвалідів або дитячі крісла), які Ви вказали під час бронювання туристичних послуг, ми передамо відповідному постачальнику послуг. Проте ми не можемо нести жодної відповідальності за те, чи може Постачальник реально виконати такі побажання.

### 1.2. Застосовні договірні умови

1.2.1 Ці Умови застосовуються до посередництва в туристичних послугах відповідно до розділу 1.1, а також для наших власних послуг відповідно до розділу 1.2.

1.2.2. За угодою між Вами і відповідним Постачальником послуг (розділ 1.1.2) застосовуються загальні умови відповідного Постачальника послуг (такі як умови перевезення, умови передачі, умови страхування тощо), видані Постачальником послуг. Такі загальні положення та умови відповідного Постачальника послуг будуть повідомлені Вам під час оформлення бронювання. У зв&#39;язку з тим, що умови надання послуг можуть містити положення про відповідальність, скасування, зміни бронювання та повернення коштів (якщо такі є) та інші обмеження, рекомендується уважно ознайомитися з цими умовами.

#
# 2. Процес бронювання та контактна інформація

2.1 Під час бронювання вам надаються технічні засоби, необхідні для виявлення помилок у вхідних формах та їх виправлення до подання запиту на бронювання. Ви повинні перевірити точність всіх даних, перш ніж скласти запит на бронювання. Подальші запити на зміну можуть призвести до значних додаткових витрат.

2.2 Ми можемо зв&#39;язатися з Вами, наприклад, у випадку подальших змін до придбаних туристичних послуг. Ви повинні надати точні контактні дані такі як номер телефону та адресу електронної пошти. Ви також повинні постійно перевіряти, чи Ви отримали повідомлення від нас.

Ваша відповідальність також полягає в тому, щоб Ви могли отримати наші повідомлення. Ми не несемо відповідальності, якщо Ви не отримуєте повідомлення від нас через обставини, які лежать поза межами нашого контролю, включаючи (але не обмежуючись) наступне: (i) Ви надали нам неправильну адресу електронної пошти; (ii) Ваші налаштування електронної пошти не дозволять нашим електронним листом зв&#39;язатися з вами; або (iii) Ваші налаштування електронної пошти ставляться до нашої електронної пошти як до спаму.

#
# 3. Посередництво в польотах

### 3.1 Укладення договору та зміна вартості

3.1.1 Після того, як Ви подали Ваш запит на бронювання, ми підтвердимо отримання Вашого запиту електронною поштою. Це є моментом укладання договору про посередництво між Вами та нами. Якщо Ви не отримали таке підтвердження протягом однієї години після заповнення запиту на бронювання, і ніхто з наших операторів не зв&#39;язався з Вами електронною поштою чи телефоном, щоб повідомити про будь-які проблеми, будь ласка, зв&#39;яжіться з нами по телефону для підтвердження.

3.1.2. Як тільки будуть видані запрошені квитки, Ви отримаєте електронний лист із підтвердженням, який матиме номер квитка. Це є моментом укладання обов&#39;язкового договору між Вами та відповідною авіакомпанією (або компаніями).

3.1.3. Ціни на рейс, а також наявність місць, безпосередньо встановляються відповідною авіакомпанією. Поза нашим контролем може статися зміна вартості на авіалінії  після подання запиту на бронювання, але до того, як контракт з авіакомпанією стане обов&#39;язковим (як зазначено в розділі 3.1.2 вище). В такому випадку, договір не набере чинності і ваш платіж буде повернено повністю. Ми можемо зв&#39;язатися з Вами та запропонувати Вам прийняти змінену ціну протягом звичайних робочих годин, але не пізніше ніж через 24 години після того, як ми дізналися про зміну ціни або в перший робочий день, наступний за таким 24-годинним періодом.

### 3.2 Інформація про бронювання та умови перевезення

3.2.1 Щодо рейсів, які пропонуються на Порталі, ми виступаємо в ролі посередника. Договір про виконання польоту набуває чинності безпосередньо між Вами та відповідною авіакомпанією, і ми не несемо ніяку договірну відповідальність, пов&#39;язану з виконанням або невиконанням вашого польоту. Діюча авіакомпанія несе повну відповідальність за виконання / невиконання Вашого рейсу.

3.2.2. Перед тим, як Ви закінчите бронювання, вам будуть надані умови та положення відповідної авіакомпанії (-ій).

3.2.3 Нижче, як загальний огляд, ми надаємо інформацію про умови, пов&#39;язані з бронюванням і транспортом, які зазвичай застосовуються авіакомпаніями, такими або, принаймні, аналогічними способами. Проте, будь-які відхиляючі положення відповідної авіакомпанії мають перевагу над загальною інформацією, наданою в цьому розділі 3.2.3. Тому в кожному випадку, будь ласка, перевірте дійсні умови відповідної авіакомпанії щодо бронювання.

#### а. Час польоту / реєстрація

Всі вкаказі години польоту є місцевим. Наступні дні прибуття позначаються &quot;+1&quot; у розкладі. Визначені терміни польотів є попередніми та можуть бути змінені через короткий термін після видачі квитка; наприклад, через обмеження, пов&#39;язані з контролем польотів, погодними чи функціональними обмеженнями авіакомпанії. Будь ласка, постійно дізнавайтеся про поточний стан задовго до вашого польоту.

Будь ласка, дотримуйтесь термінів реєстрації авіакомпанії. Авіакомпанії мають право відмовити у посадці, якщо Ви запізнюєтесь на реєстрацію. Будь ласка, зверніть увагу, що деякі авіакомпанії заохочують реєстрацію заїзду через свій власний веб-сайт, оскільки вони можуть стягувати плату за реєстрацію під час перевірки в аеропорту вручну.

#### б. Поєднання окремих квитків

Комбінація двох окремих квитків в одну сторону замість квитка в обидва кінці чітко позначена як така під час процедури бронювання. Квитки розглядаються незалежно один від одного в разі скасування, зміни, порушення повітряного руху, такі як страйки та зміни в розкладі польотів. Кожна авіакомпанія буде застосовувати власні правила.

Авіаквитки з різними номерами бронювання завжди розглядаються як подорожі, що не залежать одна від одної.

#### в. Авіаквитки з кількома сегментами / порядок використання

Ваш квиток в обидва кінці або квиток в одну сторону може складатися з декількох сегментів. Відповідно до умов більшості авіакомпаній, такі сегменти польоту повинні використовуватися послідовно. Якщо ні, багато авіакомпанії відмовляються від перевезення на наступних сегментах польоту (наприклад, невикористання одного сегмента подорожі може призвести до втрати решти квитка). Квитки на виїзд не можуть бути заброньованими, якщо авіаперевізник скасовує ваш вхідний рейс

#### г. Вагітність

Деякі авіакомпанії відмовляються перевозити жінок, у яких минув 28-ий тиждень вагітності під час перельоту за кордон або назад. Якщо ви вагітні, ви повинні прояснити у авіакомпанії та лікаря, чи можете ви почати подорож.

#### д. Діти та дитячі квитки

Будь ласка, зв&#39;яжіться з авіакомпанією, щоб дізнатися умови для подорожей з дитиною, яка не має окремого місця. Зазвичай, діти старші 2 років потребують окремого місця, а діти віком від 0 до 2 років подорожують як немовлята, і їм не буде надано власне місце. Якщо немовля до закінчення поїздки досягає двох років, дитячий квиток повинен бути заброньований для всієї поїздки. Квитки для новонароджених не можна забронювати до народження, оскільки правильне ім&#39;я та дата народження повинні відповідати тим, що вказані в паспорті. Ми не будемо відшкодовувати будь-які витрати, які виникнуть, якщо невідповідний тип квитка замовляється з самого початку.

#### е. Неповнолітні без супроводу дорослих

Ми не виступаємо у ролі посередників бронювання будь-яких квитків для неповнолітніх без супроводу. Діти до 18 років повинні подорожувати разом із дорослим. Деякі країни та авіакомпанії відмовляють у виїзді дітям віком до 18 років, якщо їх не супроводжує законний опікун. Будь ласка, зверніть увагу, що деякі авіакомпанії вимагають, щоб діти віком до 18 років мали при собі свідоцтво про народження під час подорожі.

#### є. Загублений / пошкоджений багаж

На правах посередника ми не беремо на себе відповідальність за втрачений або пошкоджений багаж. Про будь-які проблеми необхідно негайно повідомити представнику авіакомпанії в аеропорту.

#### ж. Транзит та ночівля

Як правило, наземне транспортування та / або проживання протягом ночі під час поїздки не входить у вартість авіаквитка. Ви особисто несете відповідальність за перевірку графіків та цін на наземне транспортування.

#### з. Час поєднання між рейсами

Стандартні квитки, заброньовані на порталі, мають підтверджений час поєднання. Час, необхідний для здійснення зв&#39;язків між рейсами, розраховується авіакомпаніями. Якщо сегмент польоту затримується та призводить до пропущеного зв&#39;язку, авіакомпанії зобов&#39;язані допомогти Вам у досягненні Вашого кінцевого пункту призначення (див. Розділ 9.1).

Коли бронюються окремі квитки, авіакомпанії не несуть відповідальності за пропущені поєднання, пов&#39;язані з затримками. Тому Ви несете відповідальність за те, щоб час поєднання відповідав умовам авіакомпаній та аеропортів. Будь-які додаткові витрати, понесені через пропущені з&#39;єднання, не будуть відшкодовані.

#### и. Подвійне бронювання

Подвійне бронювання означає, що з однією авіакомпанією було здійснено два або більше бронювання з одним і тим самим пасажиром. Якщо у Вас є подвійне бронювання, авіакомпанія може скасувати поїздки. Це може також відбутися, якщо замовлення було зроблено з різними туристичними агентствами. Ми не несемо відповідальності за скасування авіакомпаній, а також за відмову у відшкодуванні авіакомпаній у випадках, коли вони підозрюють подвійне бронювання.

### 3.3 Авіалінії, яким заборонено працювати в ЄС (чорний список)

Зверніть увагу, що певним авіалініям заборонено працювати в межах ЄС, згідно з рішенням, прийнятим Європейською Комісією в співпраці з національними органами повітряного транспорту. Такі авіалінії заборонені, оскільки вони вважаються небезпечними або не підлягають належному контролю з боку влади своєї країни.

Ви можете перевірити, на які авіакомпанії поширюється заборона на експлуатацію, за допомогою такої посилання: [Чорний список (список авіакомпаній, які не мають права працювати в ЄС)](https://ec.europa.eu/transport/language-selector/site-language_en?destination=node/2840)

### 3.4. Зміна та скасування розкладу авіакомпаніями

3.4.1 Ваша угода з відповідною авіакомпанією може дозволити їм скасувати або змінити Ваші бронювання. Ми повідомимо Вас про будь-які зміни, коли дізнаємося про них від авіакомпанії.

3.4.2. Час польоту, зазначений у підтвердженні бронювання, може змінюватися між датою Вашого бронювання та датою, коли Ви дійсно подорожуєте. У випадку змін в розкладі літака, ми повідомимо Вас, як тільки ми дізнаємося про це від авіакомпанії. Проте, ми наполегливо рекомендуємо Вам звернутися до авіакомпанії принаймні за 72 години до запланованого відправлення кожного рейсу, щоб впевнетися у тому, що польоти (і будь-які сполучні рейси) працюють за розкладом. Ми не контролюємо зміни в розкладі авіакомпанії та не беремо відповідальності за витрати, які можуть виникнути внаслідок таких змін.

### 3.5 Зміни та скасування за Вашою вимогою

Для бюджетних авіакомпаній див. Розділ 3.7.

3.5.1. Умови для зміни рейсів в літаку (включаючи зміну ім&#39;я пасажира, місця призначення, дати поїздки) та надання відшкодування за скасування встановлюються відповідною авіакомпанією, яка є Вашим контрагентом для забезпечення польоту. Ми як посередники не впливаємо на такі умови.

3.5.2. Якщо Ви хочете внести зміни в Ваше бронювання або вимагаєте відшкодування за скасування, як додаткову власну послугу, ми пропонуємо розглянути запит від Вашого імені, за умови, що правила авіакомпанії дозволяють таку зміну або відшкодування. Під час оформлення подібних додаткових послуг ми повідомимо Вам про будь-які додаткові умови для таких послуг. Крім того, Ви, звичайно, можете звернутися безпосередньо до відповідної авіакомпанії.

3.5.3Для того, щоб ми змогли впоратись із змінами, які Ви потребуєте, необхідно, щоб ми отримали Ваші запити на зміну не пізніше, ніж за 24 години до початку подорожі (тільки по телефону). Якщо Ви придбали нашу послугу «Гнучкий квиток», див. Розділ 10.

Для отримання запитів на зміну, що надається швидше, ми рекомендуємо звернутися безпосередньо до відповідної авіакомпанії.

### 3.6.Неявка або відсутність рейсу

Ви зобов&#39;язуєте нас відмінити невикористану поїздку від Вашого імені у разі неявки чи відсутності рейсу та вимагати відшкодування авіакомпанії від Вашого імені. Ми маємо право, але не зобов&#39;язані це робити, і Ваше право вимагати відшкодування безпосередньо від авіакомпанії залишається незмінним.

### 3.7 Бюджетні авіакомпанії

3.7.1 Якщо відповідний рейс виконується авіакомпанією з низькою вартістю, це буде чітко відображено для Вас під час процедури бронювання. Наступні спеціальні положення застосовуються до рейсів, що здійснюються авіакомпаніями з низькими цінами

3.7.2 При посередництві туристичних послуг, наданих авіакомпаніями з низькою вартістю, ми можемо діяти від Вашого імені при укладанні договору між Вами та бюджетною авіакомпанією.

3.7.3 Ми не маємо доступу до систем бронювання бюджетних авіакомпаній. Тому, будь ласка, зв&#39;яжіться безпосередньо з відповідною бюджетною авіакомпанією з питаннями про бронювання, зміни або скасування бронювання. Якщо Ви придбали нашу послугу «Гнучкий квиток» (див. Розділ 10), всі запити на зміни в реєстраціі повинні бути зроблені через нашу службу підтримки клієнтів, як зазначено в розділі 10.1.4.

3.7.4 Також зауважте, що деякі бюджетні авіакомпанії беруть плату за ручну реєстрацію в аеропорту. Щоб уникнути таких зборів, Ви повинні скористатися можливістю онлайн-реєстрації через веб-сайт відповідної бюджетної авіакомпанії. Будь ласка, дізнайтеся про можливості реєстрації та умови авіакомпанії вчасно та до від&#39;їзду.

3.7.5 Підтвердження бронювання для недорогих рейсів відправляються одразу, як тільки бронювання ухвалено відповідною бюджетною авіакомпанією. Ви можете отримати два підтвердження бронювання: одне від нас та одне - від відповідної бюджетної авіакомпанії. Якщо ви отримали два підтвердження бронювання, будь ласка, використовуйте підтвердження бронювання від бюджетної авіакомпанії для реєстрації заїзду.

#
# 4. Спеціальні положення про посередництво в оформленні бронювання кількох послуг

Портал пропонує можливість поєднувати і підбирати різні індивідуальні послуги, які Вам необхідні (наприклад, переліт + готель). У цьому випадку Ви дозволяєте нам діяти в якості посередника для бронювання різних туристичних послуг від різних постачальників. Відповідні постачальники будуть чітко представлені Вам під час процедури бронювання до завершення бронювання. У випадку, коли Ви об&#39;єднуєте окремі послуги, між Вами та нами не укладається договір про проїзд; замість цього Ви укладаєте кілька контрактів на надання індивідуальних послуг з кожним окремим постачальником. У цьому випадку ми працюємо виключно в якості посередника по відношенню до кожного окремого Туристичного сервісу.

#
# 5. Інформація про паспорт, візу та умови охорони здоров&#39;я

5.1. Паспорт, віза та / або вимоги до здоров&#39;я можуть змінюватися, тому Ви повинні проконсультуватися з відповідним органом (посольством, консульством тощо) задовго до поїздки. Ви зобов&#39;язані мати дійсний паспорт та, у разі необхідності, візу. Важливо пам&#39;ятати про включення всіх пунктів транзиту в подорож, які також можуть вимагати отримання вами візи. Для отримання візи може знадобитися деякий час, тому Вам рекомендується подавати заявку заздалегідь. Ми не несемо відповідальності за клієнтів, які не мають корректних документів.

5.2. Кожне місце призначення має свої власні вимоги щодо в&#39;їздних формальностей, вакцинації тощо, які також можуть відрізнятися залежно від громадянства пасажира. Ви несете відповідальність за збір подібної інформації. Ніякі інциденти, пов&#39;язані із невиконанням таких офіційних правил, не будуть розглядатися як наша відповідальність. Тому ми наполегливо закликаємо Вас завжди перевіряти різні формальності вибраного Вами пункту призначення або країн транзиту, а також враховувати час, необхідний для прийняття всіх відповідних кроків.

#
# 6. Збори та оплата

### 6.1 Оплата

7.1.1 Плата за туристичні послуги обробляється нами (у співпраці з нашим постачальником 6платіжних послуг, який виконує функцію обробки платежу), або Постачальником послуг. Зверніть увагу, що нам, можливо, доведеться поділитися своєю платіжною інформацією, такою як інформація про кредитну або дебетову картку, з нашим постачальником платіжних послуг, щоб мати змогу обробляти платіж. Вся платіжна інформація, що належить нашим клієнтам, зашифрована на захищеному сервері, коли вона використовується спільно з нашими постачальниками платіжних послуг.

6.1.2. Щоб ми змогли обробити Ваш платіж, Вам потрібно забезпечити наявність достатніх коштів. У разі виникнення будь-якої проблеми, пов&#39;язаної з обробкою вашого платежу, ми спробуємо обробити ваш платіж спільно з нашим оператором (-ами) платіжних послуг. Якщо це не призведе до повного списання коштів з Вашого рахунку, ми зв&#39;яжемося з Вами якомога швидше, щоб отримати вказівки щодо інших способів оплати. Якщо Ми не отримаємо Ваш платіж після таких додаткових інструкцій, Ваш непогашений платіж буде переданий до компанії, що стягує борги.

### 6.2 Платіжне шахрайство

Якщо є підстави для підозри у вчиненні шахрайства, ми залишаємо за собою право відмовити в обробці такого платежу. Легітимізація оплати може вимагатись, якщо підозрюється злочин. Про всі випадки шахрайства з оплатою буде повідомлено поліції. Також вони будуть передані компанії з вилучення боргів.

#
# 7. Скарги

### 7.1.Претензії, пов&#39;язані з виконанням туристичних послуг

Будь-яка проблема, зауваження або позов, що стосується фактичної роботи туристичних послуг, повинна бути направлена безпосередньо до відповідного Постачальника послуг, з яким Ви укладали договір на відповідну Туристичну послугу. Будь ласка, перегляньте розділ 9 для ознайомлення з Вашими правами згідно з положеннями ЄС.

### 7.2 Скарги, що стосуються наших послуг

Скарги, пов&#39;язані з нашими власними послугами, обробляються виключно в письмовій формі і повинні бути подані протягом двох місяців після дати закінчення вашої поїздки електронною поштою.

Адреса електронної пошти: [complaints@flystrait.com](mailto:complaints@flystrait.com)

#
# 8. Права клієнта згідно з положеннями ЄС

### 8.1. Скасовані або затримані польоти

Якщо Ви подорожуєте в ЄС, виїжджаєте з ЄС або користуєтесь транспортом ЄС, Ви маєте право вимагати відшкодування витрат, заявлених безпосередньо проти відповідної авіакомпанії у разі скасування вашого авіарейсу, затримки або відмови посадки на борт. [Для отримання додаткової інформації про Регламент ЄС 261/2004, будь ласка, натисніть тут.](http://eur-lex.europa.eu/legal-content/EN/ALL/?uri=CELEX%3A32004R0261)

### 8.2 Відповідальність перевізника

Регламент ЄС (889/2002) про відповідальність авіаперевізників у разі нещасних випадків. [Положення можна знайти тут.](http://eur-lex.europa.eu/legal-content/EN/TXT/?uri=celex%3A32002R0889)

#
# 9. Відповідальність

9.1 Ви погоджуєтесь, що ми є посередником між Вами та Постачальником послуг. Ми ні за яких обставин не будемо нести відповідальність стосовно туристичних послуг, які Ви забронювали у одного або декількох постачальників послуг, і ми не несемо відповідальності за будь-які неточності у інформації, яка відображається на порталі, що була надана відповідним Постачальником послуг.

9.2. Якщо Постачальник послуг з будь-якої причини не може забезпечити Службу подорожі, у тому числі, якщо Постачальник послуг оголошує про банкрутство, ми можемо виконувати функції посередника та повернення платежів лише тоді, коли ми вже отримали це від відповідного Постачальника послуг.

9.3 Що стосується наших власних послуг, ми несемо відповідальність за збитки, що підлягають обмеженню, викладеному в цих Умовах та в межах, дозволених законом. Ми будемо нести відповідальність за прямий збиток, який фактично понесено або сплачено Вами через недолік наших зобов&#39;язань стосовно наших послуг, аж до загальної суми вартості бронювання (чи то для однієї події чи серії пов&#39;язані події).

9.4 Обмеження відповідальності, викладене у Розділі 10.3, також застосовується до порушень службових обов&#39;язків особами, за вину яких ми несемо відповідальність згідно з законодавчими нормами.

#
# 10. Регулююче законодавство та врегулювання суперечок

### 10.1. Нормативно-правове регулювання

Естонське законодавство, за винятком єдиного універсального права, зокрема, закону про торгівлю товарами в рамках міжнародної купівлі-продажу, застосовується виключно до цих Умов та контракту між Вами та нами.

Як споживач, Ви будете користуватися будь-яким з обов&#39;язкових положень законодавства країни, в якій Ви проживаєте. Ніщо в цих Умовах, включаючи цей розділ 11.1, не впливає на ваші права як споживача, щоб покластися на такі обов&#39;язкові положення місцевого законодавства.

### 10.2 Вирішення суперечок в режимі онлайн

Європейська комісія надає платформу для онлайн-розв&#39;язання спорів (ODS) за адресою: (http://ec.europa.eu/consumers/odr/)

#
# 11. Захист даних

Ми серйозно ставимось до захисту ваших особистих даних. Ви можете знайти докладну інформацію про збір, обробку та використання ваших особистих даних у нашому розділі [Політика конфіденційності.](https://flystrait.com/privacy)
`;
