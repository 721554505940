import React from "react";
import styled from "styled-components";
import { Input } from "components/common";
import { EditSection } from "components/Influencer/Common";
import getTranslation from "utils/getTranslation";

const Wrapper = styled.div`
  margin-top: 40px;
  .inputWrapper {
    display: flex;
    justify-content: space-between;
    .inputField {
      margin-right: 24px;
      width: 100%;
      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

class AccountDetails extends React.PureComponent {
  handleChange = () => {};
  render() {
    return (
      <Wrapper>
        <div className="titleWithEdit">
          <h2>{getTranslation("accountDetails")}</h2>
          <EditSection />
        </div>
        <div className="inputWrapper">
          <div className="inputField">
            <Input
              label={getTranslation("flystraitDomainName")}
              fullWidth
              name="name"
              onChange={this.handleChange}
              hideClear
            />
          </div>
          <div className="inputField">
            <Input
              label={getTranslation("flystraitDomainName")}
              fullWidth
              name="name"
              onChange={this.handleChange}
              hideClear
            />
          </div>
          <div className="inputField">
            <Input
              label={getTranslation("flystraitDomainName")}
              fullWidth
              name="name"
              onChange={this.handleChange}
              hideClear
            />
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default AccountDetails;
