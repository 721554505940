import { CHANGE_LANGUAGE, UPDATE_TIME } from 'redux-store/actions';
import moment from 'moment';
import { allTranslations } from 'utils/translations';
import { getItem } from 'utils/utils';

// --------------------------- Reducer function --------------------------

const initialState = {
  selectedLanguage: getItem('selectedLanguage') || 'en',
  translations: allTranslations,
  updateTime: 0,
};

export default function translations(state = initialState, action = {}) {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return {
        ...state,
        selectedLanguage: action.payload,
        updateTime: moment().unix(),
      };
    case UPDATE_TIME:
      return {
        ...state,
        updateTime: moment().unix(),
      };
    default:
      return state;
  }
}
