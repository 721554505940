import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "react-apollo";
import { Title } from "components/common/CommonStyles";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled, { withTheme } from "styled-components";
import { getItem, numberToLocaleString, categoryCodeToStar } from 'utils/utils';
import { StarIcon, SmileyRatingIcon, HotelSelectedIcon, HotelUnselectedIcon } from "components/common/SvgIcons";
import config from "config";
import getTranslation from "utils/getTranslation";


const SliderWrapper = styled.div`
  .slick-slide {
    opacity: 0.8;
  }
  .slick-center {
    opacity: 1;
  }
  .imageWrapper {
    height: 100%;
    align-items: center;
    outline: none;
    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
    /* min-width: 960px; */
  }
`;

const HotelItem = styled.div`
align-self: center;
margin: 0px 0px 20px;
background: #FFFFFF;

border: 1px solid #F0F0F0;
box-sizing: border-box;
box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.06);

flex-direction: column;
width: 744px;
align-self: center;
justify-content: space-between;
cursor: pointer;
position: relative;

font-family: Roboto;
font-style: normal;
font-weight: normal;


.totalPrice {
    font-size: 20px;
    text-decoration: none !important;
    color: ${(props) => props.theme.secondary};
  }
.topRightBox {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-around;    
    display: flex;
    flex-direction: column;
    padding: 8px;

    top: 0;
    right: 0;
    position: absolute;
    
    width: 119px;
    height: 62px;
    background: rgba(242, 204, 86, 0.3);
    border-radius: 0px 0px 0px 16px;
}
.topRightDividerLine {
    border: 1px solid #777777;
    transform: rotate(180deg);
    width: 64px;
}
.topRightText {
    font-size: 12px;
    color: #222222;
  }
.starsInLine {
    display: flex;
    flex-direction: row;
    padding: 0px;
}
.starSingle {
    flex: none;
    align-self: center;
    margin: 2px 0px;
}
.smileyRatingText {
    flex: none;
    align-self: center;
    font-size: 14px;
    line-height: 17px;
    margin: 0px 4px;
}
.hotelTitleText {
    font-size: 22px;
    color: #222222;
}
    `;

class HotelDetail extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let { destinationCode, selectedHotels } = this.props;
        let item = selectedHotels.find(sh => sh.destinationCode == destinationCode).hotel;

        const { occupancies } = this.props;
        let numOfRooms = occupancies.length;


        var settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            center: false,
            adaptiveHeight: true
        };

        const imagedata = {};
        let sortedItemImages = item.hotelcontent.images.sort((a, b) => a.order - b.order);
        sortedItemImages = sortedItemImages.slice(0, 5);
        imagedata.images = sortedItemImages.map(i => config.hotelImagePathPrefix + i.path);
        // console.log('item ' + item);
        let starsFilled = categoryCodeToStar(item.categoryCode) || 0;
        let starsUnfilled = 5 - starsFilled;
        let stars = [];
        for (var i = 0; i < starsFilled; i++) {
            stars.push(<StarIcon fill={true} />)
        }
        for (var i = 0; i < starsUnfilled; i++) {
            stars.push(<StarIcon fill={false} />)
        }

        let nameRatekeys = item.rooms.map(room => room.rates.map(rate => { return { roomName: room.name, roomRatekey: rate.rateKey } })).flat().slice(0, numOfRooms);
        let rooms = [];
        for (var i = 0; i < numOfRooms; i++) {
            rooms.push(<span> {(numOfRooms > 1 ? '| 1 x ' : '') + nameRatekeys[i].roomName} </span>);
        }

        return (item != null) ? (
            <HotelItem
                id={item.code}
            >
                <div className="topRightBox">
                    <div className="topRightText">PAY AT CHECK-IN</div>
                    <div className="topRightDividerLine"></div>
                    <div className="topRightText">Free Cancellation</div>
                </div>
                <div style={{ height: "100%" }}>
                    <div style={{ position: "relative", display: "flex", flexDirection: "column", justifyContent: "space-around", padding: "12px", height: "130px" }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", height: "24px", width: "387px" }}>
                            <div>
                                {stars}
                            </div>
                            <div>
                                <span>City Centre</span>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", width: "54px" }}>
                                <SmileyRatingIcon style={{ margin: "0px 4px" }} />
                                <div className="smileyRatingText">33%</div>
                            </div>
                        </div>
                        <div className="hotelTitleText">
                            {item.name}
                        </div>
                    </div>
                    <div>
                        <SliderWrapper>
                            <div>
                                <Slider
                                    {...settings}
                                    ref={c => (this.slider = c)} {...settings}>
                                    {imagedata.images.map((image, i) => (
                                        <div className="imageWrapper" key={i}>
                                            <img src={image} alt="" />
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </SliderWrapper>
                    </div>
                    <div>
                        {rooms}
                    </div>

                </div>

            </HotelItem >
        ) : null;
    }
}
const mapStateToProps = state => ({
    selectedHotels: state.hotelResults.selectedHotels,
    occupancies: state.hotelResults.occupancies,
    // hotels: state.hotelResults.hotels
});

export default compose(
    connect(mapStateToProps)
)(HotelDetail);
