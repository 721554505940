export default `
#
# Privacidade

#
# Introdução

Fly Strait OÜ, registrado em Tallinn, Estônia com o número de registro 14373971 (&quot;nós&quot;, &quot;nos&quot;, &quot;nosso&quot; ou apenas &quot;Fly Strait&quot;) é o chamado &quot;controlador de dados&quot; de seus dados pessoais e é, portanto, responsável pelo legalidade do que fazemos com seus dados pessoais.

Este site é operado pela Fly Strait. Nós lidamos com uma variedade de dados pessoais, como nomes, endereços de e-mail e outras informações relacionadas a viagens em seus negócios diários. Portanto, levamos muito a sério a segurança dos dados e a adesão à legislação de proteção de dados. Esta política de privacidade explica como coletamos, armazenamos, usamos e divulgamos quaisquer dados pessoais que coletamos sobre você quando você usa este site, bem como protegemos a privacidade e a confidencialidade de suas informações pessoais. Sua privacidade é importante para nós, portanto, se você é novo em nosso serviço ou é um usuário de longa data, reserve um tempo para conhecer nossas práticas e entre em contato conosco se tiver alguma dúvida.

#
# Os dados pessoais que coletamos

Geralmente, o tipo de dados pessoais que coletamos são as informações necessárias para que você possa organizar sua viagem e fazer reservas. Isso inclui informações como seu nome e sobrenome, data de nascimento, número de telefone e endereço de e-mail. Os dados pessoais que precisamos receber para fornecer a organização da viagem que você reservou através de nossos sites são os únicos dados obrigatórios a serem fornecidos. Dependendo do tipo de serviços de viagem que você usa, também podemos coletar seu número de passageiro frequente, informações sobre suas necessidades alimentares e problemas de saúde (se houver) e outros detalhes que sejam relevantes para sua viagem ou que sejam exigidos por outro serviço de viagem. provedor (como companhias aéreas e hotéis). Esta lista não é exaustiva. Se você ligar para o nosso suporte, coletaremos os dados que você fornecer durante a ligação telefônica. Como você pode ver abaixo, nossos cookies também coletam algumas informações.

Se você fizer uma reserva para outra pessoa através de nosso site, solicitaremos dados pessoais sobre essa pessoa. Nessas circunstâncias, confiamos em você para aconselhar essas pessoas sobre esta política de privacidade.

#
# Os dados pessoais que coletamos

Em alguns casos, podemos lidar com as chamadas &quot;categorias especiais de dados pessoais&quot; sobre você, que podem ser consideradas confidenciais. Este seria o caso, por exemplo, se você (i) tiver apresentado um atestado médico para uso de proteção contra cancelamento ou reembolso de uma companhia aérea (ii) tiver uma condição médica ou de saúde que afete sua viagem e para o qual você solicite assistência ou onde certa folga é necessária, ou (iii) tenha feito uma solicitação revelando alguns outros dados pessoais confidenciais sobre você.

Antes de tratarmos de dados pessoais confidenciais sobre você, solicitamos seu consentimento para fazê-lo. Por isso, pedimos que você use os formulários de contato dedicados em nossos sites para enviar dados confidenciais. Os formulários de contato permitem que você nos dê o consentimento exigido pela legislação aplicável de proteção de dados. Esse consentimento é revogável a qualquer momento. Não trataremos nenhum dado pessoal confidencial para o qual você não nos deu autorização, ou que você não nos tenha fornecido. Uma quantidade limitada de nossa equipe terá acesso aos seus dados pessoais confidenciais e, depois de manipular seus dados confidenciais de acordo com sua solicitação, apagaremos os dados assim que possível.

#
# O que fazemos com seus dados pessoais

Para ter permissão para lidar com seus dados pessoais, as legislações de proteção de dados aplicáveis ​​nos obrigam a ter a chamada &quot;base legal&quot; para cada um de nossos propósitos processar seus dados pessoais. Por esta razão, elaboramos a tabela abaixo para mostrar nossa base legal para cada um dos nossos propósitos.

| **O que fazemos (nossos propósitos para lidar com seus dados pessoais)** | **A BASE JURÍDICA** | **Duração do armazenamento** |
| --- | --- | --- |
| Habilitar os planos de viagem e reservas que você solicitou de nós (ou seja, reserva de serviços de viagem mediados por nós, bem como a prestação de nossos próprios serviços). | Execução de um contrato consigo Nos casos em que você nos forneceu dados pessoais sensíveis, o consentimento é a base legal. | 3 anos a partir da data da compra. Consentimento para dados pessoais sensíveis pode ser retirado a qualquer momento. |
| Se você escolheu criar uma conta de usuário em nosso site, disponibilizaremos essa conta para você. A conta inclui acesso a informações sobre suas viagens e reservas anteriores. Também armazenaremos seu nome de usuário e senha. | Execução de um contrato consigo. | Os dados da sua conta de usuário, bem como informações pessoais relacionadas às suas reservas e viagens anteriores, serão armazenados até que você decida cancelar sua conta de usuário através de nosso site. No entanto, se você não tiver acessado em sua conta de usuário por 18 meses, poderemos excluir essa conta e informações, desde que não tenhamos outra base legal para armazenamento. |
| Se você iniciou um processo de reserva, mas não concluiu a compra, podemos enviar um e-mail com um link para o resultado da pesquisa ou para a reserva iniciada, dependendo de em que ponto o processo de reserva no site foi descontinuado. | Nosso interesse legítimo de realizar negócios e permitir que você conclua sua compra sem precisar preencher todas as informações novamente. Se você não quiser receber esses e-mails, poderá cancelar o recebimento a qualquer momento no e-mail. | 24 horas a partir do momento em que você saiu do processo de reserva. |
| Antes do início da sua viagem, forneceremos informações adicionais e ofertas relacionadas ao seu contrato de viagem específico, como possíveis acréscimos como bagagem extra e outras informações de uso para sua viagem. Algumas das informações são baseadas na criação de perfil realizada com base nas informações que você forneceu durante o processo de reserva (por exemplo, a(s) data(s) em que você está viajando, seu destino, etc.). | Nosso interesse legítimo em oferecer uma viagem mais conveniente e permitir que você encontre facilmente mais informações de relevância para você. Se você não quiser essas informações, poderá cancelar o recebimento a qualquer momento no e-mail. | Até sua viagem começar. Outro processamento similar pode continuar de acordo com os propósitos estabelecidos abaixo. |
| Após sua organização de viagem, podemos fornecer-lhe nosso boletim informativo contendo recomendações sobre outros preparativos de viagem e produtos e serviços relacionados a viagens que possam ser de seu interesse. Algumas das recomendações são baseadas em suas escolhas anteriores ao reservar uma viagem e no seu comportamento em relação aos nossos e-mails. | Nosso interesse legítimo de realizar negócios e permitir que você faça planos de viagem de interesse para você. Se você não deseja receber esses e-mails, poderá cancelar o recebimento a qualquer momento no e-mail ou no nosso site. Além disso, antes de finalizar uma compra, você pode se opor a receber a newsletter. | 3 anos a partir da data da compra. |
| Gravação de chamadas telefônicas para fins de garantia de qualidade e para futuras solicitações ou perguntas feitas por você. | Nosso interesse legítimo em (i) melhorar nosso serviço por meio de educação interna e, quando aplicável, (ii) resolver suas solicitações ou reivindicações em potencial. Se você não quiser que o telefonema seja gravado, você poderá se opor a essa gravação antes que a gravação comece. | 6 meses a partir da data do telefonema. Apenas um número limitado de pessoas tem acesso à sua gravação. |
| Uso de cookies para, por exemplo, melhorar a usabilidade deste site, fornecer uma experiência personalizada e coletar estatísticas de uso. Também usamos cookies de sessão para melhorar a segurança deste site | Nosso interesse legítimo em (i) melhorar nosso site, (ii) mostrar ofertas de seu interesse e (iii) ter uma oferta de serviços e um site seguros. Se você não quiser que armazenemos cookies no seu computador, você poderá alterar as configurações no seu navegador a qualquer momento. | Dependendo do tipo de cookie. [Veja abaixo para tempos de armazenamento mais detalhados.](https://flystrait.com/privacy#Cookies) |

Além do acima, realizamos medidas cotidianas que são necessárias para empresas que prestam serviços aos consumidores, tais como escrituração contábil, faturamento, cumprimento das obrigações contra lavagem de dinheiro e manutenção da segurança do nosso site. Na medida em que isso não é obrigatório sob as leis aplicáveis, tomamos essas medidas com base em nosso interesse legítimo. Também podemos analisar o comportamento de nossos clientes para melhorar nossos sites e serviços em um nível geral. No entanto, essa análise usará dados generalizados ou anônimos em um nível agregado.

#
# Partilha dos seus dados pessoais

Nós só compartilharemos seus dados pessoais quando necessário para os fins listados nesta política de privacidade. Isso pode ser para outras empresas dentro do Fly Strait, autoridades governamentais e nossos parceiros comerciais confiáveis. Por exemplo, podemos compartilhar seus dados pessoais (incluindo dados pessoais confidenciais, quando aplicável) com parceiros de negócios, como companhias aéreas, fornecedores de hotéis, companhias de seguros e sistemas de distribuição global (chamados GDSs) para permitir sua organização e reservas de viagens.

Cada parceiro é responsável por seu próprio tratamento de seus dados pessoais após recebê-los, o que significa que você deve entrar em contato com o parceiro em questão para quaisquer solicitações relacionadas aos seus direitos sob a legislação de proteção de dados aplicável. Recomendamos que você leia as respectivas políticas de privacidade dos parceiros para obter informações sobre como eles lidam com seus dados pessoais. Também compartilharemos seus dados pessoais com outras empresas (os chamados &quot;processadores de dados&quot;) necessárias para fornecer os serviços solicitados, como provedores de serviço que operam em nossos centros de atendimentos de chamadas e nossos outros fornecedores e vendedores que manipularão seus dados pessoais ao fornecer seus serviços para nós (por exemplo, armazenamento externo).

Devido à natureza global do setor de viagens, seus dados pessoais podem ser processados ​​em diferentes locais do mundo quando as partes com quem compartilhamos seus dados pessoais residem em um país fora da UE / EEA. A nossa partilha de dados pessoais fora da UE / EEE requer algum fundamento legal ao abrigo da legislação aplicável em matéria de proteção de dados. Quando um país é considerado pela Comissão Europeia como um país com um nível adequado de protecção de dados pessoais, esta será a nossa base legal. Caso contrário, existem três tipos principais de fundamentos jurídicos nos quais podemos basear esse compartilhamento. São elas:

i. que a transferência é necessária para o cumprimento do contrato com você (por exemplo, quando você reservou um voo com uma companhia aérea que reside fora da UE / EEE);

ii. que a transferência será baseada nas cláusulas padrão de proteção de dados para transferência de dados pessoais para países fora da UE / EEE adotados pela Comissão Européia (uma cópia dessas cláusulas de proteção de dados padrão pode ser encontrada em http://ec.europa.eu.eu/justice/dataprotection/internationaltransfers/transfer/);

iii. que o EU-U.S. Privacy Shield, para onde a transferência é feita nos Estados Unidos, e o destinatário são devidamente certificados.

#
# Provedores de terceiros

Por favor, note que o nosso site contém links para outros sites e apresenta conteúdo de provedores terceiros. Esta política de privacidade só se aplica ao nosso site e aos nossos serviços. Quando você segue links para outros sites, ou usa serviços e produtos de terceiros, você deve ler suas políticas de privacidade. Além disso, se você optar por nos contatar via mídia social, esta política de privacidade não se aplica a nenhum dado pessoal enviado por você como parte de tal contato - nesse caso, recomendamos que você leia a política de privacidade desse provedor de mídia social.

#
# Os seus direitos

De acordo com a legislação de proteção de dados aplicável, você tem certos direitos como o chamado &quot;assunto dos dados&quot;. Abaixo, listamos seus direitos. Seus direitos incluem o seguinte:

i. Direito de acesso - Você tem o direito de acessar os dados pessoais que manipulamos. Você também tem direito a receber certas informações sobre o que fazemos com os dados pessoais. Tais informações são fornecidas neste documento.

ii. Direito de retificação - Sob certas circunstâncias, você tem o direito de corrigir dados pessoais imprecisos sobre você e ter dados pessoais incompletos corrigidos. Por favor, note que podemos não ser capazes de corrigir dados pessoais imprecisos fornecidos por você, por exemplo, as regras das companhias aéreas, e que tal alteração em particular pode incorrer em um custo.

iii. Direito de apagar - Sob certas circunstâncias, você tem o direito de ter seus dados pessoais apagados. Este é o chamado &quot;direito ao esquecimento&quot;.

iv. Direito a restrição de processamento - Sob certas circunstâncias, você tem o direito de restringir a forma como usamos seus dados pessoais.

v. Direito à portabilidade de dados - Você tem o direito de receber seus dados pessoais (ou ter seus dados pessoais transmitidos diretamente para outro controlador de dados) em um formato estruturado, comumente usado e legível por máquina.

vi. Direito de contestar - Você tem o direito de se opor a certos tipos de manipulação de dados pessoais que realizamos. Isso se aplica a todas as nossas atividades baseadas em nosso &quot;interesse legítimo&quot;. Finalmente, você também tem o direito de apresentar uma reclamação junto à autoridade supervisora ​​de proteção de dados aplicável.

#
# Cookies

Um cookie é um pequeno arquivo de texto armazenado em seu computador, alguns apenas até você fechar o navegador (os chamados &quot;cookies de sessão&quot;) e outros por um longo período de tempo (os chamados &quot;cookies permanentes&quot;). Se não desejar permitir o armazenamento de cookies no seu computador, você poderá alterar as configurações no seu navegador. Note, no entanto, que em alguns casos alguns dos recursos do nosso site podem não funcionar corretamente e alguns conteúdos podem não ser exibidos corretamente como resultado.

Este site usa cookies por vários motivos, incluindo o fornecimento de uma experiência personalizada, para melhorar a usabilidade deste site e para coletar estatísticas de uso. Também usamos cookies de sessão para melhorar a segurança deste site.

Em alguns casos, ao usar cookies, compartilhamos dados com terceiros. Por exemplo, usamos o Google Analytics e o Google AdWords, serviços que transmitem dados de tráfego do site para os servidores do Google. O Google Analytics não identifica usuários individuais e não associa seu endereço IP a nenhum outro dado mantido pelo Google. Usamos relatórios fornecidos pelo Google para nos ajudar a entender o tráfego do website e o uso de páginas da web e otimizar anúncios comprados da própria rede do Google e de outras redes de publicidade. O Google pode processar os dados da maneira descrita em [Política de privacidade do Google](https://policies.google.com/privacy) e para os propósitos estabelecidos acima nesta seção. Você pode desativar o Google Analytics se desativar ou recusar o cookie, desativar o JavaScript ou usar o serviço de desativação fornecido pelo Google. [Para desativar os recursos de publicidade do Google, use este link.](https://tools.google.com/dlpage/gaoptout)

Nosso site também usa o pixel Facebook, que coleta dados agregados anônimos que nos ajuda a otimizar as compras de anúncios em diferentes plataformas do Facebook (incluindo o Instagram). O Facebook coleta um ID de usuário que permitirá que eles correspondam se um usuário tiver visitado um site com o pixel Facebook. Nós, como anunciantes, no entanto, nunca podemos identificar o comportamento de um usuário específico. O Facebook e suas plataformas relacionadas estão em um ecossistema de publicidade fechado onde seus usuários podem regulamentar se consentirem com os anunciantes que usam dados coletados de seus sites para comprar anúncios no Facebook. [Para visualizar e alterar suas configurações de publicidade no Facebook, use este link.](https://facebook.com/ads/preferences)

Além disso, nosso site usa scripts e cookies de acompanhamento de conversões de [Microsoft Bing](https://privacy.microsoft.com/), [TripAdvisor](https://www.smartertravel.com/privacy-policy) e [TvSquared](https://tvsquared.com/privacy-policy/) (você pode ver suas políticas de privacidade seguindo os links).

Todos esses serviços coletam dados estatísticos agregados que nos ajudam a otimizar a compra de anúncios. Nós, como anunciantes, não podemos identificar um único usuário usando esses dados. Você pode desativar o uso de cookies nas configurações do seu navegador.

[Para mais informações sobre cookies, visite www.youronlinechoices.com.](https://www.youronlinechoices.com/)

#
# Segurança dos dados

Para manter seus dados pessoais seguros, implementamos várias medidas de segurança técnica e organizacional. Por exemplo, mantemos altos níveis de segurança técnica em todos os sistemas (incluindo rastreabilidade, recuperação de desastres, limitações de acesso etc.). Além disso, adotamos políticas para garantir que nossos funcionários (que obviamente estão sujeitos a obrigações de confidencialidade) não usem dados pessoais quando isso não for necessário. Tais políticas também estabelecem nossos padrões para quando contratamos fornecedores ou introduzimos novos sistemas de TI em nossas operações.

#
# Como nos contactar

Se tiver alguma questão relacionada com o processamento dos seus dados pessoais ou a utilização de cookies, ou se pretender invocar qualquer um dos seus direitos ao abrigo da legislação de proteção de dados aplicável, envie um email para: privacy@flystrait.com

#
# Alterações à política de privacidade

Se mudarmos a forma como lidamos com os seus dados pessoais ou como usamos cookies, atualizaremos imediatamente esta política de privacidade e a publicaremos neste site.
`;
