import {
  SET_INFLUENCER,
  UPDATE_INFLUENCER,
  SET_CONNECTPOSTS,
  UPDATE_CONNECTPOST_REFETCH,
  SET_ALL_CONNECTPOSTS
} from "redux-store/actions";

const initialProps = {
  influencerDetails: null,
  connectPosts: [],
  allConnectPosts: [],
  connectPostRefetchTime: 0
};
export default function(state = initialProps, action) {
  switch (action.type) {
    case SET_INFLUENCER:
      return {
        ...state,
        influencerDetails: { ...action.payload }
      };
    case UPDATE_INFLUENCER:
      return {
        ...state,
        influencerDetails: action.payload
      };
    case SET_CONNECTPOSTS:
      return {
        ...state,
        connectPosts: action.payload
      };
    case UPDATE_CONNECTPOST_REFETCH:
      return {
        ...state,
        connectPostRefetchTime: action.payload
      };
    case SET_ALL_CONNECTPOSTS:
      return {
        ...state,
        allConnectPosts: action.payload
      };
    default:
      return state;
  }
}
