/* eslint-disable no-lonely-if */
/* eslint-disable no-plusplus */
/* eslint-disable no-loop-func */
import React, { PureComponent } from "react";
import { graphql, compose } from "react-apollo";
import { connect } from "react-redux";
import { isMobile } from "react-device-detect";
import styled from "styled-components";
import Sticky from "react-sticky-el";
import { TripFareRules } from "components/common";
import { Title } from "components/common/CommonStyles";
import MoreResults from "components/common/MoreResults";
import { MultiBooking } from "components/common/SvgIcons";
import { UniqueFsCode } from "redux-store/actions";

import { getItem, numberToLocaleString } from "utils/utils";
import MetaSection from "./Partials/MetaSection";
import PrevPassengers from "./Partials/PrevPassengers";
import PaymentGateway from "components/Payment/PaymentGateway";
import AddedPassengers from "components/Payment/AddedPassengers";
import FlightSegmentComponent from "../FlightSegment/FlightSegmentComponent";
import { addHOPRequest } from "./HopQuery";
import getTranslation from "utils/getTranslation";
import HotelDetail from "../Hotel/HotelDetail";
import PaypalGateway from "components/Payment/PaypalGateway";

const Wrapper = styled.div`
  #hopSegCmp {
    @media (max-width: 800px) {
      padding: 10px 0 0;
    }
  }
`;

const MultiWarning = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  font-size: 14px;
  cursor: pointer;
  span {
    margin-left: 10px;
  }
`;

class HOPSegmentsControl extends PureComponent {
  state = {
    hopSegmentsControl: [],
    showAddHOP: false,
    passengersByIndex: [],
    filterOpen: false,
    modalOpen: false,
    showSelected: true,
    infoModalOpen: false
  };

  componentWillReceiveProps(newProps) {
    if (
      this.props.payment.received !== newProps.payment.received &&
      newProps.payment.received
    ) {
      console.log("REFETCH FLIGHT");
      this.props.refetchFlights();
    }

    if (
      this.props.hopRequestDone !== newProps.hopRequestDone &&
      newProps.hopRequestDone
    ) {
      if (this.state.showAddHOP) {
        this.setState({
          showAddHOP: false
        });
      }
    }
  }

  getMergedItems = hopReqs => {
    const hopRequests = [...hopReqs];
    let modified = [];
    for (let i = 0, j = hopRequests.length; i < j; i++) {
      if (modified.length === 0) {
        modified = [hopRequests[i]];
      } else {
        if (
          hopRequests[i].hopSegmentControls[0].fscode ===
          hopRequests[i - 1].hopSegmentControls[0].fscode
        ) {
          const index = modified.findIndex(
            item =>
              item.hopSegmentControls[0].fscode ===
              hopRequests[i].hopSegmentControls[0].fscode
          );
          modified[index].hopSegmentControls = [
            ...modified[index].hopSegmentControls,
            hopRequests[i].hopSegmentControls[0]
          ];
        } else {
          this.props.UniqueFsCode();
          modified = [...modified, hopRequests[i]];
        }
      }
    }
    return modified;
  };

  handleNoFlightSegments = () => {
    this.props.handleNoFlightSegments();
  };

  handleFilterModal = (filter = "time") => {
    this.setState({
      filterOpen: true,
      defaultFilter: filter
    });
  };

  handleOpenModal = () => {
    if (this.props.fareRulesFound) {
      this.setState({ modalOpen: true });
    }
  };

  handleCloseModal = () => {
    this.setState({ modalOpen: false });
  };

  handlePriceNumChange = async priceNum => {
    this.props.handleUpdTriItenerary(false, "", "", "", true, priceNum);
  };

  handleToggleSplitBooking = () => {
    if (this.props.splitBooking) {
      this.props.handleMultiBookingDisable();
    } else {
      this.props.handleMultiBookingEnable();
    }
  };




  render() {
    const { modalOpen, showSelected } = this.state;
    const {
      hopRequests,
      loading,
      handleUpdTriItenerary,
      userTrips,
      tripHopRequests,
      tripCostItems,
      eticket,
      bookingPage,
      priceNum,
      onCancel,
      extraCheckDone,
      filterKvs,
      fareRulesLoading,
      fareRulesFound,
      filteredResults,
      loadingFiltered,
      enablePaymentGateway,
      allPassengersAdded,
      client,
      selectedHotels,
      occupancies
    } = this.props;
    const notSkipped = selectedHotels.filter(sh => sh.skipped == false);
    let localizedTotalCost;
    // const currencyDisplay = getItem("selectedCurrencySymbol") || "€";
    if (notSkipped.length > 0) {
      let totalCustomCurrencySellingRate = notSkipped.map(item => item.hotel.rooms.map(room => room.rates.map(rate => rate.customCurrencySellingRate)).flat().slice(0, occupancies.length).reduce((a, b) => (a + b), 0)).reduce((a, b) => (a + b), 0);
      // console.log('totalCustomCurrencySellingRate ' + totalCustomCurrencySellingRate)
      localizedTotalCost = numberToLocaleString(totalCustomCurrencySellingRate, {
        selectedCountry: getItem("selectedLanguage"),
        selectedCurrency: notSkipped[0].hotel.rooms[0].rates[0].customCurrency,
      });
      // console.log('localizedTotalCost ' + localizedTotalCost);
    }

    const hopRequestsItems = userTrips ? tripHopRequests : hopRequests;
    // const modifiedHopRequests = this.getMergedItems(JSON.parse(JSON.stringify(hopRequestsItems)));
    this.getMergedItems(JSON.parse(JSON.stringify(hopRequestsItems)));
    const modifiedHopRequests = hopRequestsItems;
    const selectedTripItem = filteredResults.find(
      item => item.priceNum === priceNum
    );
    const extraStyle = { boxShadow: "rgba(0, 0, 0, 0.12) 0 8px 6px -6px" };
    const passengers =
      modifiedHopRequests.length > 0 ? modifiedHopRequests[0].passengers : [];



    return (
      <Wrapper className="__hop-segments-control-container">
        {modalOpen && (
          <TripFareRules
            open={modalOpen}
            handleCloseModal={this.handleCloseModal}
            handleFareError={this.handleFareError}
            tripId={getItem("tripId")}
            isStatic
          />
        )}
        {!userTrips && !bookingPage && (
          <React.Fragment>
            <Sticky
              topOffset={isMobile ? -56 : -56}
              stickyStyle={{
                marginTop: isMobile ? 56 : 56,
                backgroundColor: "#FFFFFF",
                zIndex: 99,
                ...extraStyle
              }}
              scrollElement={isMobile ? "window" : "window"}
            >
              {modifiedHopRequests.length > 0 && (
                <MetaSection
                  loading={loading}
                  hopRequests={modifiedHopRequests}
                  extraCheckDone={extraCheckDone}
                  filterKvs={filterKvs}
                  handleClose={onCancel}
                  fareRulesLoading={fareRulesLoading}
                  fareRulesFound={fareRulesFound}
                  handleOpenModal={this.handleOpenModal}
                  handleUpdTriItenerary={handleUpdTriItenerary}
                />
              )}
              {/* {(getItem('showMultiBookingIcon') && !loading && modifiedHopRequests.length > 1) &&
            <MultiWarning onClick={this.handleToggleSplitBooking}>
              <MultiBooking stroke={splitBooking ? 'rgb(242, 153, 74)' : '#27AE60'} />
              <span>{getTranslation('multiBooking')} {splitBooking ? 'Enabled' : 'Disabled'}</span>
            </MultiWarning>} */}
            </Sticky>
          </React.Fragment>
        )}
        {getItem("tokenDetails") && <PrevPassengers />}
        <div id="hopSegCmp">
          <Title>{getTranslation("selectedItinerary")}</Title>
          {modifiedHopRequests.map(hopRequest => (
            <FlightSegmentComponent
              key={hopRequest.hopSegmentControls[0].id}
              priceNum={priceNum}
              hopRequest={hopRequest}
              handleNoFlightSegments={this.handleNoFlightSegments}
              handleUpdTriItenerary={handleUpdTriItenerary}
              loading={loading}
              userTrips={userTrips}
              tripCostItems={tripCostItems}
              eticket={eticket}
              bookingPage={bookingPage}
              extraCheckDone={extraCheckDone}
              fareRulesFound={fareRulesFound}
              handleOpenModal={this.handleOpenModal}
              handleFilterModal={this.handleFilterModal}
            />
          ))}
          {selectedHotels.length == 0 &&
            !loading &&
            !bookingPage &&
            !userTrips &&
            modifiedHopRequests.length > 0 &&
            !enablePaymentGateway && (
              <MoreResults
                loadingFiltered={loadingFiltered}
                trips={filteredResults}
                handleClick={this.handlePriceNumChange}
                filterKvs={filterKvs}
                selectedTrip={
                  selectedTripItem ? selectedTripItem.priceNum : null
                }
                showSelected={showSelected}
                extraCheckDone={extraCheckDone}
                enablePaymentGateway={enablePaymentGateway}
                handleUpdTriItenerary={handleUpdTriItenerary}
              />
            )}
          {notSkipped.length != 0 &&
            (
              <>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <Title>{getTranslation("selectedAccommodations")}</Title>
                  <div className="totalPrice">
                    {localizedTotalCost}
                  </div>
                </div>

                {notSkipped.map(ns =>
                  <HotelDetail destinationCode={ns.destinationCode} />
                )}
              </>
            )
          }
          {allPassengersAdded && enablePaymentGateway && isMobile && (
            <React.Fragment>
              <AddedPassengers passengers={passengers} />
              {/* <PaymentGateway
                client={client}
                style={{
                  backgroundColor: "#fff",
                  width: "100vw",
                  paddingTop: 10
                }}
              /> */}
              <PaypalGateway
                client={client}
                style={{
                  backgroundColor: "#fff",
                  width: "100vw",
                  paddingTop: 10
                }}
              />
            </React.Fragment>
          )}
        </div>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  selectedHotels: state.hotelResults.selectedHotels,
  occupancies: state.hotelResults.occupancies,
  payment: state.reducers.payment,
  hopRequests: state.flightResults.hopRequests,
  filteredResults: state.flightResults.filteredResults,
  splitBooking: state.flightResults.splitBooking,
  enablePaymentGateway: state.flightResults.enablePaymentGateway,
  allPassengersAdded: state.flightResults.allPassengersAdded
});

export default compose(
  graphql(addHOPRequest, {
    name: "addHOPRequestQuery"
  }),
  connect(mapStateToProps, { UniqueFsCode })
)(HOPSegmentsControl);
