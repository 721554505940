
export const ADD_PASSENGER = 'ADD_PASSENGER';
export const REMOVE_PASSENGER = 'REMOVE_PASSENGER';
export const EDIT_PASSENGER = 'EDIT_PASSENGER';
export const ADD_PASSENGER_IDS = 'ADD_PASSENGER_IDS';
export const REMOVE_PASSENGER_IDS = 'REMOVE_PASSENGER_IDS';

export function addPassengerData(data) {
  return {
    type: 'ADD_PASSENGER',
    payload: data,
  };
}

export function removePassenger(data) {
  return {
    type: 'REMOVE_PASSENGER',
    payload: data,
  };
}

export function handlePassengerEdit(data) {
  return {
    type: 'EDIT_PASSENGER',
    payload: data,
  };
}

export function handleAddPassengerIds(ids) {
  return {
    type: ADD_PASSENGER_IDS,
    payload: ids,
  };
}

export function handleRemovePassengerIds() {
  return {
    type: REMOVE_PASSENGER_IDS,
  };
}
