import React from "react";
import styled from "styled-components";
import Transport from "./Transport";
import { hex2rgb, fancyTimeFormat } from "utils/utils";

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  position: relative;
  .day {
    margin-bottom: 32px;
    height: 72px;
    width: 72px;
    border-radius: 50%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-evenly;
    color: #fff;
    font-size: 18px;
    .time {
      font-size: 14px;
    }
  }
  .transport {
    border-top-left-radius: 10em;
    border-top-right-radius: 10em;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-flow: column;
    height: 64px;
    width: 64px;
    span {
      font-size: 14px;
      color: #444444;
    }
    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }
  &:not(:last-child) {
    padding-bottom: 32px;
  }
`;

const Line = styled.div`
  width: 2px;
  height: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
`;

export default class extends React.PureComponent {
  render() {
    const { data, color } = this.props;
    const borderColor = hex2rgb(color, 0.32);
    let totalMinutes = 0;
    data.itinerarydaytransports.forEach(item => {
      totalMinutes += item.duration_minutes;
    });
    const timeArray = fancyTimeFormat(totalMinutes * 60).split(":");
    return (
      <Wrapper>
        <Line style={{ backgroundColor: borderColor }} />
        <div className="day" style={{ backgroundColor: color }}>
          <span>Day {data.day}</span>
          <span className="time">
            {timeArray[0]}h {timeArray[1] !== "00" ? timeArray[1] + "m" : ""}
          </span>
        </div>
        {data.itinerarydaytransports.map(transport => (
          <Transport data={transport} color={color} />
        ))}
      </Wrapper>
    );
  }
}
