import React from 'react';
import styled from 'styled-components';
import Switch from '@material-ui/core/Switch';
import { AirlineImg, AirlineName } from 'components/common';

const Item = styled.div`
  background-color: ${props => props.disabled ? 'transparent' : '#fff'};
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
  height: 64px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 15px;
  position: relative;
  .airline {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    display: flex;
    flex-flow: column;
    align-items: center;
    img {
      height: 30px;
    }
  }
  &.disabled {
    background-color: transparent;
    box-shadow: none;
    border: 1px solid #DDDDDD;
    .airline {
      color: #777777;
      img {
        filter: grayscale(100%);
      }
    }
  }
`;

export default class extends React.PureComponent {
  constructor(props) {
    super(props);
    const isFilteredOff = props.filterKvs.find(filter => filter.value === props.item.airlineIc && filter.filterKey === 'AIRLINE');
    this.state = {
      checked: (isFilteredOff || props.disabled) ? false : true
    };
  }

  handleChange = (e, checked) => {
    this.setState({ checked });
    this.props.handleFilterToggle(this.props.item.airlineIc, checked);
  }

  render() {
    const { checked } = this.state;
    const { item, disabledState } = this.props;
    return (
      <Item className={disabledState ? 'disabled' : ''}>
        <div className="airline">
          <AirlineImg airlineIc={item.airlineIc} />
          <AirlineName airlineIc={item.airlineIc} />
        </div>
        {!disabledState && <Switch
          checked={checked}
          onChange={this.handleChange}
          value={item.airlineIc}
          color="primary"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />}
      </Item>
    )
  }
}
