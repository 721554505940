import React from 'react';
import { Query } from 'react-apollo';
import { xairportByIataLike } from 'components/common/BookedTrip/queries';

export default ({
  airportIc,
  date,
}) => (
  <Query query={xairportByIataLike} variables={{ iata: `${airportIc}%` }}>
    {({ data }) => (
      data.xairportByIataLike ?
          `Flight To ${data.xairportByIataLike[0].city}`
         : null
    )}
  </Query>
);
