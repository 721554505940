import React, { Component } from 'react';
import styled from 'styled-components';
import BaggageType from './BaggageType';

const Type = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  @media (min-width: 1000px) {
    width: 100%;
  }
`;

const PassengerWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  margin-bottom: 10px;
`;
export default class extends Component {
  state = {};
  getBaggages = (baggages, passenger) => {
    let weights = [];
    let filteredBaggages = [];
    const modifiedBaggages = baggages.filter(item => item.passengerNum === passenger.passengerNum);
    modifiedBaggages.forEach((baggage) => {
      if (weights.indexOf(baggage.baggagePieceMaxWeight) === -1) {
        filteredBaggages = [...filteredBaggages, baggage];
        weights = [...weights, baggage.baggagePieceMaxWeight];
      } else if (weights.indexOf(baggage.baggagePieceMaxWeight) > -1 && baggage.baggagePieceCount > 0) {
        const index = filteredBaggages.findIndex(item => item.baggagePieceMaxWeight === baggage.baggagePieceMaxWeight);
        filteredBaggages[index].baggagePieceCount = baggage.baggagePieceCount;
      }
    });
    return filteredBaggages;
  }

  handleTypeChange = (type) => {
    this.props.handleTypeChange(type);
  }
  handleDisabledCheck = (baggage, baggages) => {
    let quantity;
    if (baggage.baggagePieceCount === 0) {
      quantity = 3;
    } else {
      const max = this.props.outboungBagges.reduce((prev, current) => (
        (prev.baggagePieceCount > current.baggagePieceCount) ? prev : current
      ));
      quantity = max.baggagePieceCount;
    }
    const baggageAdded = baggages.find(item => item.quantity > 0);
    return (baggageAdded && baggage.quantity === 0) || (baggage.quantity > 0 && baggage.quantity >= quantity);
  }
  render() {
    const {
      outboungBagges,
      activeType,
      loading,
      handleIncrease,
      tripCostItem,
      passenger,
      handleRemove
    } = this.props;
    const bagges = this.getBaggages(JSON.parse(JSON.stringify(outboungBagges)), passenger);
    return (
      <Type style={{ opacity: loading ? '0.4' : 1 }}>
        <Wrapper>
          <Container>
            {bagges.map(baggage => (
              baggage.baggagePieceMaxWeight > 0 &&
              <PassengerWrapper key={baggage.id}>
                <BaggageType
                  baggage={baggage}
                  baggagePieceCount={baggage.baggagePieceCount}
                  weight={baggage.baggagePieceMaxWeight}
                  type={baggage.id}
                  tripCostItem={tripCostItem}
                  disabled={this.handleDisabledCheck(baggage, bagges)}
                  isActive={activeType === baggage.id}
                  activeType={activeType}
                  handleTypeChange={this.handleTypeChange}
                  handleIncrease={handleIncrease}
                  handleRemove={handleRemove}
                />
              </PassengerWrapper>
            ))}
          </Container>
        </Wrapper>
      </Type>
    );
  }
}
