import gql from 'graphql-tag';

export const updHopsegmentcontrol = gql`mutation updHopsegmentcontrol(
  $id: Int!
  $seatClass: SeatClass
  $cancellationPenalty: CancellationPenalty
  $dateChangePenalty: DateChangePenalty
) {
  updHopsegmentcontrol(
    id: $id
    seatClass: $seatClass
    cancellationPenalty: $cancellationPenalty
    dateChangePenalty: $dateChangePenalty
  ) {
      id,
      airlinePnr,
      seatClass,
      cancellationPenalty,
      dateChangePenalty,
      baggagePieceCount,
      flightSegments {
        airlineIc,
        flightNum,
        fromAirportIc,
        stdDate,
        stdTime,
        toAirportIc,
        staDate,
        staTime,
        aircraftIcao
      }
  }
}`;

export const xairportByIataLike = gql`query xairportByIataLike(
    $iata: String!,
  ) {
    xairportByIataLike(
      iata: $iata,
    ) {
      airportIc
      airportDesc
      city
      country
    }
  }
`;

export const xairlineByIc = gql`query xairlineByIc(
    $airlineIc: String!,
  ) {
    xairlineByIc(
      airlineIc: $airlineIc,
    ) {
      airlineDesc
    }
  }
`;

export const smartResultsQuery = gql`
  query smartResults($hopsegmentcontrolId: Int!, $key: SmartKey!) {
    smartResults(hopsegmentcontrolId: $hopsegmentcontrolId, key: $key) {
      priceNum
      flightsegmentMatch
      priceDiff
      currencyIso
      costDecimalPlaces
      hopRequests {
        id
        hopSeqNum
        fromAirportIc
        toAirportIc
        hopSegmentControls {
          id
          fscode
          seatClass
          seatChoice
          costDecimalPlaces
          costCurrencyIso
          dateChangePenalty
          farerules {
            id
            category
            detail
          }
          flightSegments {
            airlineIc
            flightNum
            fromAirportIc
            stdDate
            stdTime
            toAirportIc
            staDate
            staTime
            aircraftIcao
            durationMins
          }
        }
      }
    }
  }
`;
