import React, { Component } from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { graphql, compose } from 'react-apollo';
import Modal from 'components/common/Modal';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Loader } from 'components/common';
import { updTripFareruleQuery, getTripById } from './queries';
import getTranslation from 'utils/getTranslation';
import { DirectionForwardIcon } from 'components/common/SvgIcons';

const TitleWrapper = styled.div`
  display: flex;
  flex-flow: column;
  .fareRule {
    text-transform: capitalize;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 12px;
    color: #444444;
  }
`;

const StyledTabs = styled(Tabs)`
  .PrivateTabIndicator-colorSecondary-228 {
    background-color: #56CCF2;
  }
`;

const StyledTab = styled(Tab)`
  min-width: 100px !important;
  min-height: 60px !important;
  padding: 0 !important;
  color: #56CCF2 !important;
  font-weight: 300 !important;
  font-family: 'Roboto Condensed', sans-serif !important;
  &.Mui-selected {
    span {
      color: #007AC7 !important;
    }
  }
`;

const Container = styled.div`
  padding: 24px;
  h1 {
    font-size: 22px;
    font-weight: 400;
    color: #222222;
    margin: 0;
  }
`;

const Wrapper = styled.div`
  color: var(--black-color);
  text-align: left;
  h4 {
    margin-bottom: 10px;
  }
`;


const FareRule = styled.div`
  h5 {
    font-weight: 400;
    font-size: 18px;
    padding: 5px;
    color: #007AC7;
    text-decoration: underline;
  }
  &.active {
    h5 {
      font-weight: 600;
    }
  }
  p {
    margin-bottom: 10px;
    font-size: 12px;
    white-space: pre-wrap;
  }
  h4 {
    color: #777777;
  }
`;

const FareRulesSection = styled.div`
  display: flex;
  height: 61vh;
  padding-top: 20px;
  .categories {
    width: 30%;
    overflow: scroll;
    h5 {
      font-size: 14px;
      margin-bottom: 10px;
      cursor: pointer;
      @media (max-width: 800px) {
        font-size: 8px;
      }
    }
  }
  .farerules {
    overflow: scroll;
    width: 70%;
    @media (max-width: 800px) {
      width: 100%;
    }
  }
`;

class TripFareRules extends Component {
  state = {
    loading: false,
    updTripFarerulesData: null,
    activeHopRequestIndex: 0,
    activeMenu: '',
  };
  async componentDidMount() {
    this.setState({ loading: true });
    this.props.client.query({
      query: getTripById,
      variables: { id: this.props.tripId },
      fetchPolicy: 'no-cache',
    }).then((tripData) => {
      console.log('tripData', tripData);
      if (tripData.data.tripById[0].hopRequests[0].hopSegmentControls[0].farerules.length > 0) {
        this.setState({
          updTripFarerulesData: tripData.data.tripById[0],
          loading: false,
        }, () => {
          // document.getElementById('tripFareRules').addEventListener('scroll', this.handleScrolling);
        });
      } else {
        this.handleTripFareRule();
      }
    }).catch((err) => {
      console.log('Error during tripByid', err);
      this.handleTripFareRule();
    });
  }

  componentDidUpdate = () => {
    const { updTripFarerulesData } = this.state;
    if (updTripFarerulesData) {
      setTimeout(() => {
        document.getElementById('tripFareRules').addEventListener('scroll', this.handleScrolling);
      }, 500);
    }
  }

  isInViewport = (elem) => {
    const bounding = elem.getBoundingClientRect();
    return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  handleTripFareRule = async () => {
    try {
      const updTripFareruleData = await this.props.updTripFarerule({
        variables: {
          tripId: this.props.tripId,
        },
      });
      this.setState({
        loading: false,
        updTripFarerulesData: updTripFareruleData.data.updTripFarerule,
      });
    } catch (err) {
      console.log(err);
      this.setState({ error: true, loading: false });
      // this.props.handleFareError();
      // this.handleClose();
    }
  }

  handleScroll = (e) => {
    const element = e.target.id;
    const id = element.split('_')[1];
    if (element) {
      document.getElementById('tripFareRules').scroll({
        top: document.getElementById(id).offsetTop - 165,
        behavior: 'smooth',
      });
    }
  }

  handleTabChange = (e, value) => {
    this.setState({ activeHopRequestIndex: value });
  }

  handleScrolling = () => {
    const section = document.getElementsByClassName('section');
    const bScroll = document.getElementById('tripFareRules').scrollTop;
    for (let i = 0; i < section.length; i++) {
      const sHeight = section[i].offsetHeight;
      const offsets = section[i].offsetTop - 170;
      if (bScroll > offsets && bScroll < offsets + sHeight) {
        this.setState({
          activeMenu: section[i].id,
        });
      }
    }
  }

  handleClose = () => {
    this.props.handleCloseModal();
  };

  render() {
    const { loading, updTripFarerulesData, error, activeHopRequestIndex, activeMenu } = this.state;
    const {
      open,
      handleCloseModal
    } = this.props;
    const activeHopRequest = updTripFarerulesData ? updTripFarerulesData.hopRequests[activeHopRequestIndex] : {};
    return (
      <Modal
        open={open}
        title={
          <TitleWrapper>
          <div className="fareRule">{getTranslation('fareRule')}</div>
          {updTripFarerulesData && <StyledTabs value={activeHopRequestIndex} onChange={this.handleTabChange}>
          {updTripFarerulesData.hopRequests.map((hopRequest, i) => (
            <StyledTab
              key={hopRequest.id}
              label={<span style={{ display: 'flex', alignItems: 'center' }}>
                {hopRequest.fromAirportIc} <DirectionForwardIcon stroke={activeHopRequestIndex === i ? '#007AC7' : '#56CCF2'} /> {hopRequest.toAirportIc}
              </span>}
              value={i}
              hopRequestIndex={i}
            />
          ))}
        </StyledTabs>}
        </TitleWrapper>
        }
        handleCloseModal={handleCloseModal}
        dialogStyle={{ width: !isMobile ? 616 : 376, maxWidth: 616 }}
        titleStyle={{ paddingTop: 0, paddingBottom: 0 }}
      >
        <Container>
          {(!loading && error) && <h3>{getTranslation('fareRuleUnable')}</h3>}
          {loading && <Loader />}
          {(!loading && updTripFarerulesData) && <React.Fragment>
            <h1>{getTranslation('fareRuleTitle')}</h1>
          <FareRulesSection>
            {!isMobile &&
            <div className="categories">
              <Wrapper key={activeHopRequest.id} style={{ paddingRight: 10 }}>
                {activeHopRequest.hopSegmentControls[0].farerules.map(fareRule => (
                  <FareRule
                    key={fareRule.id}
                    className={activeMenu === `${activeHopRequest.fromAirportIc}-${activeHopRequest.toAirportIc}${fareRule.category}${fareRule.id}` ? 'active' : ''}
                  >
                    <h5
                      id={`category_${activeHopRequest.fromAirportIc}-${activeHopRequest.toAirportIc}${fareRule.category}${fareRule.id}`}
                      onClick={this.handleScroll}
                    >
                      {fareRule.category}
                    </h5>
                  </FareRule>
                ))}
              </Wrapper>
            </div>}
            <div className="farerules" id="tripFareRules">
              <Wrapper key={activeHopRequest.id} id={`hopRequest${activeHopRequest.id}`}>
                {activeHopRequest.hopSegmentControls[0].farerules.map(fareRule => (
                  <FareRule className="section" key={fareRule.id} id={`${activeHopRequest.fromAirportIc}-${activeHopRequest.toAirportIc}${fareRule.category}${fareRule.id}`}>
                    <h4>{fareRule.category}</h4>
                    <p>{fareRule.detail}</p>
                  </FareRule>
                ))}
              </Wrapper>
            </div>
          </FareRulesSection>
        </React.Fragment>}
        </Container>
      </Modal>
    );
  }
}

export default compose(graphql(updTripFareruleQuery, { name: 'updTripFarerule' }))(TripFareRules);
