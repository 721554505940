import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { graphql, compose, withApollo } from "react-apollo";
import { Link } from "react-router-dom";
import moment from "moment";
import { BlogPostCard } from "components/Influencer/BlogCards";
import { ConnectIcon } from "components/Influencer/Common";
import { insertConnectPost, getPosts } from "containers/Influencer/queries";
import { Mask, Steps } from "components/Influencer";
import { Wrapper } from "containers/Influencer/Setup/Styles";
import { Button, Toast } from "components/common";
import ActivateDomain from "./ActivateDomain";
import getTranslation from "utils/getTranslation";
import { getTipDomain } from "utils/utils";
import config from "config";

const Container = styled.div`
  width: 936px !important;
  @media (max-width: 800px) {
    width: 100% !important;
    padding: 0 16px;
  }
  .section {
    width: 376px;
    @media (max-width: 800px) {
      width: 100%;
    }
  }
  .separator {
    height: auto;
    width: 1px;
    margin: 0 90px;
    background-color: #dddddd;
  }
  .textareaWrapper {
    position: relative;
    span {
      position: absolute;
      right: 16px;
      bottom: 16px;
      font-size: 12px;
      color: #777777;
    }
  }
  .connectedChannels {
    margin: 20px 0;
    display: flex;
    align-items: center;
    .count {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #fff;
      background-color: #ec9719;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin-right: 15px;
    }
    span {
      color: #777777;
      font-size: 16px;
      line-height: 26px;
      font-family: "Roboto Condensed", sans-serif;
      text-transform: uppercase;
    }
  }
  .btnWrapper {
    width: 216px;
    &.bottom {
      display: flex;
      width: 100%;
      align-items: center;
      a {
        width: 216px;
        &:first-child {
          margin-right: 20px;
        }
      }
    }
    a {
      text-decoration: none;
    }
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  background: #f0f0f0;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  resize: none;
  padding: 16px;
  outline: none;
  font-size: 14px;
  height: 174px;
  &.active {
    border: 2px solid #56ccf2;
    box-shadow: 0px 0px 6px rgba(86, 204, 242, 0.9);
  }
`;

const BlogContainer = styled.div`
  display: flex;
  flex-flow: column;
  max-width: 456px;
  .section {
    width: 456px;
    @media (max-width: 800px) {
      width: 100%;
    }
  }
`;

class Share extends React.PureComponent {
  constructor(props) {
    super(props);
    const { influencerDetails } = this.props;
    const influencerconnects = influencerDetails
      ? influencerDetails.influencerconnects.map(item => ({
        ...item,
        selected: true
      }))
      : [];
    this.state = {
      postId: props.match.params.postId,
      publishText: "",
      wordLimit: 120,
      influencerconnects
    };
  }

  async componentWillMount() {
    try {
      const posts = await this.props.client.query({
        query: getPosts,
        variables: {
          limit: 10,
          where: {
            id: { _eq: this.state.postId }
          }
        }
      });
      this.setState({
        loading: false,
        blogData: posts.data.post[0]
      });
    } catch (err) {
      this.setState({
        loading: false,
        error: true
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.influencerDetails &&
      this.props.influencerDetails !== nextProps.influencerDetails
    ) {
      const influencerconnects = nextProps.influencerDetails.influencerconnects.map(
        item => ({
          ...item,
          selected: true
        })
      );
      this.setState({ influencerconnects });
    }
  }

  handleChange = e => {
    const { name, value } = e.target;
    const wordLimit = 140 - this.getPostUrl().length;
    if (value.length > wordLimit) {
      return;
    }
    this.setState({
      [name]: value,
      wordLimit: wordLimit - value.length
    });
  };

  getPostUrl = () =>
    `https://${getTipDomain(this.props.influencerDetails)}/post/${this.state.postId
    }`;

  handleShare = async () => {
    this.setState({ sharing: true });
    const { influencerconnects } = this.state;
    const { created_at } = this.props.influencerDetails;
    const url = this.getPostUrl();
    for (var i = 0, j = influencerconnects.length; i < j; i++) {
      if (!influencerconnects[i].selected) {
        continue;
      }
      try {
        const min = parseInt(moment(created_at).format("mm"), 10);
        const currentMin = parseInt(moment().format("mm"), 10);
        const minDiff1 = Math.abs(currentMin - min);
        const minDiff2 = Math.abs(currentMin - (min + 60));
        const minimumMinutes = Math.min(minDiff1, minDiff2);
        const scheduleAt = moment()
          .add(minimumMinutes + 60, "m")
          .format();
        const payload = {
          sharenote: this.state.publishText + " " + url,
          post_id: this.state.postId,
          influencerconnect_id: influencerconnects[i].id,
          shareschedule_at: scheduleAt
        };
        await this.props.insertConnectPost({
          variables: {
            objects: [payload]
          }
        });
        this.setState({
          sharing: false,
          message: getTranslation("postShared")
        });
      } catch (err) {
        this.setState({ sharing: false });
      }
    }
  };

  handleHideToast = () => {
    this.setState({
      message: null
    });
  };

  handleShareView = () => {
    this.setState({
      showShare: true
    });
  };

  handleBack = () => {
    this.props.history.push("/influencer/setup/step4");
  };

  handleConnectToggle = (connect, selected) => {
    const { influencerconnects } = this.state;
    const selectedConnects = influencerconnects.filter(item => item.selected);
    if (selectedConnects.length === 1 && !selected) {
      return;
    }
    const currentIndex = influencerconnects.findIndex(
      item => item.id === connect.id
    );
    influencerconnects[currentIndex].selected = selected;
    this.setState({
      influencerconnects
    });
  };

  render() {
    const {
      postId,
      publishText,
      wordLimit,
      sharing,
      blogData,
      showShare,
      influencerconnects
    } = this.state;
    const { influencerDetails } = this.props;
    const { customsitedomains } = influencerDetails || {
      customsitedomains: []
    };
    const domainData =
      customsitedomains.length > 0 ? customsitedomains[0] : null;
    const activated = domainData ? domainData.activated_at : false;
    return (
      <Wrapper>
        <Mask style={{ height: 52 }} />
        <h5 style={{ textAlign: "center", paddingTop: 10 }}>
          {getTranslation("tipRegistration")}
        </h5>
        <Steps activeStep={5} />
        <Container className="container">
          {influencerDetails && (
            <ActivateDomain
              influencerDetails={influencerDetails}
              showShare={showShare}
              handleShare={this.handleShareView}
            />
          )}
          {(activated ||
            showShare ||
            (influencerDetails &&
              !influencerDetails.custom_domain_selected)) && (
              <>
                <h2 style={{ margin: "30px 0 30px 0" }}>
                  {getTranslation("sharePost")}
                </h2>
                <BlogContainer>
                  <div className="section">
                    {blogData && (
                      <BlogPostCard
                        preview
                        fullWidth
                        blogData={blogData}
                        layout={blogData.coverimage_layout}
                      />
                    )}
                  </div>
                  {influencerDetails && influencerconnects.length > 0 && (
                    <div className="section">
                      <div className="connectedChannels">
                        <span style={{ marginRight: 10 }}>
                          {getTranslation("channels")}:
                      </span>
                        <div className="count">
                          {influencerDetails.influencerconnects.length}
                        </div>
                        {influencerconnects.map((connect, i) => (
                          <ConnectIcon
                            connect={connect}
                            key={i}
                            handleToggle={this.handleConnectToggle}
                          />
                        ))}
                      </div>
                      <div className="textareaWrapper">
                        <TextArea
                          rows="10"
                          name="publishText"
                          value={publishText}
                          onChange={this.handleChange}
                          className={publishText ? "active" : ""}
                        ></TextArea>
                        <span>{wordLimit} left</span>
                      </div>
                      <div className="btnWrapper" style={{ marginTop: 10 }}>
                        <Button
                          onClick={this.handleShare}
                          disabled={sharing || !publishText}
                        >
                          {getTranslation(sharing ? "sharing" : "shareNow")}
                        </Button>
                      </div>
                    </div>
                  )}
                </BlogContainer>
                <div className="btnWrapper bottom">
                  <a
                    href={`https://${influencerDetails.tip_sub_domain}.${config.tipFrontUrl}/post/${postId}`}
                    target="_blank"
                  >
                    <Button color="#D0F3FC" style={{ color: "#007AC7" }}>
                      {getTranslation("viewPost")}
                    </Button>
                  </a>
                  <Link to="/influencer/dashboard/posts">
                    <Button
                      color="#D0F3FC"
                      style={{ color: "#007AC7", margin: "20px 0" }}
                    >
                      {getTranslation("goToDashboard")}
                    </Button>
                  </Link>
                </div>
              </>
            )}
        </Container>
        <Toast
          open={this.state.message}
          message={this.state.message}
          handleClose={this.handleHideToast}
        />
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  influencerDetails: state.influencer.influencerDetails
});

export default withApollo(
  compose(
    graphql(insertConnectPost, { name: "insertConnectPost" }),
    connect(mapStateToProps)
  )(Share)
);
