import React from "react";
import { PayPalButton } from "react-paypal-button-v2";
import styled, { withTheme } from "styled-components";
import { withRouter } from "react-router-dom";
import { graphql, compose } from "react-apollo";
import moment from "moment";
import config from "config";
import { connect } from "react-redux";
import { addPassengerToAllHopRequests } from "redux-store/actions";
import { Toast } from "components/common";
import PaymentIssueModal from "components/common/PaymentIssueModal";
import countryList from "utils/countryList";
import { updPassengerQuery } from "./PaymentQuery";

import { getItem, setItem } from "utils/utils";
import getTranslation from "utils/getTranslation";
import { paypalSetupAuthorize } from "../../utils/api";
import flyIcon from "assets/icons/flyIcon.svg";
import CircularProgress from "@material-ui/core/CircularProgress";


const Wrapper = styled.div`
position: relative;
  min-height: 100vh;
  .bookingWrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(204, 204, 204, 0.84);
    backdrop-filter: blur(4px);
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .booking {
    font-size: 18px;
    color: #444444;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding: 80px;
    flex-flow: column;
    background-color: #fff;
    border-radius: 10px;
    @media (max-width: 800px) {
      padding: 20px;
      width: 98%;
    }
    .inputStyle {
      width: 3rem !important;
      height: 3rem;
      margin: 0 1rem;
      font-size: 2rem;
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.3);
      @media (max-width: 800px) {
        width: 2rem !important;
        height: 2rem;
      }
    }
    .btns {
      display: flex;
      button {
        min-width: 100px !important;
      }
    }
  }
.loaderWrapper {
    position: relative;
    height: 48px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }`;

const StyledLoader = styled(CircularProgress)`
  position: absolute;
  left: 0;
  circle {
    stroke: #d0f3fc;
  }
`;

let defaultCountry = "";
const selectedCountry = getItem("selectedCountry") || "gb";
if (selectedCountry.toLowerCase() !== "xx") {
    defaultCountry =
        countryList.find((item) => item.Code === selectedCountry.toUpperCase())
            .en || "";
}


class PaypalGateway extends React.PureComponent {
    constructor(props) {
        super(props);
        let passengers = [];
        props.hopRequests[0].passengers.forEach((pass) => {
            const dob = moment(pass.birthdate);
            const currentDate = moment();
            const diff = currentDate.diff(dob, "years");
            if (diff > 12) {
                passengers = [...passengers, pass];
            }
        });
        const passenger = passengers[0];
        passenger.cardHoldercountry = defaultCountry;
        this.state = {
            authorizing: false,
            showIframe: false,
            startPolling: false,
            formData: passenger,
            passengers
        };
    }

    handleUpdatePassenger = async () => {
        this.setState({ loading: true });
        const { hopRequests } = this.props;
        const { formData } = this.state;
        const passengerIndex = hopRequests[0].passengers.findIndex(
            (item) => item.id === formData.id
        );
        let passengerIds = [];
        hopRequests.forEach((hopReq) => {
            const passengerId = hopReq.passengers[passengerIndex].id;
            passengerIds = [...passengerIds, passengerId];
        });
        const passengerPayload = {
            ...formData,
        };
        passengerIds.forEach((passengerId, index) => {
            this.props
                .updPassengerQuery({
                    variables: {
                        ...passengerPayload,
                        passengerId,
                        tripId: getItem("tripId"),
                    },
                })
                .then((data) => {
                    if (passengerIds.length === index + 1) {
                        // this.handleGetUrl();
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.setState({
                        isLoading: false,
                        loading: false,
                        error: true,
                        errorMsg: getTranslation("addPassengerError"),
                    });
                });
        });
    };

    handleWarningAccept = () => {
        this.props.history.push("/new-trip");
    };

    handleHidePaymentError = (msg) => {
        this.setState({
            paymentError: false,
        });
    };


    handlePassengerChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value,
            },
        });
    };


    render() {
        const {
            openWarning,
            warningMessage,
            authorizing
        } = this.state;
        const { style = {}, localizedTotalCost } = this.props;

        return (
            <Wrapper style={style}>
                <PaymentIssueModal
                    open={openWarning}
                    handleAccept={this.handleWarningAccept}
                    errorMsg={warningMessage}
                />
                {authorizing && <div className="bookingWrapper"><div className="loaderWrapper">
                    <img src={flyIcon} alt="" />
                    <StyledLoader size={48} />
                </div></div>}
                {!authorizing && <PayPalButton
                    createOrder={async () => {
                        this.handleUpdatePassenger();
                        const data = await paypalSetupAuthorize({
                            searchparams: {
                                tripId: getItem("tripId"),
                                flightsOnly: true,
                                domain: window.location.origin
                            }
                        });
                        setItem("emp_transactionid", data.emp_transactionid);
                        setItem("tripowner_email", data.tripowner_email);

                        return data.orderID;
                    }}
                    onApprove={async (data) => {
                        setItem("approved_orderid", data.orderID);
                        this.setState({ startIframeLoading: true, loading: true, authorizing: true });

                        try {
                            if (getItem("paymentType") !== "domain") {
                                this.props.history.push(`/booking/${getItem("tripId")}`);
                            }
                        } catch (err) {
                            console.log(err);
                            this.setState({
                                paymentError: true,
                                paymentSetupFailed: true,
                                errorMessage: getTranslation("paymentInitFailed"),
                                startIframeLoading: false,
                                loading: false,
                                authorizing: false
                            });
                        }
                    }}
                />}
                <Toast
                    open={this.state.paymentError}
                    message={this.state.errorMessage}
                    handleClose={this.handleHidePaymentError}
                />
            </Wrapper>
        );
    }
}

const mapStateToProps = (state) => ({
    hopRequests: state.flightResults.hopRequests,
    localizedTotalCost: state.flightResults.localizedTotalCost,
});

export default withTheme(
    withRouter(
        compose(
            graphql(updPassengerQuery, { name: "updPassengerQuery" }),
            connect(mapStateToProps, { addPassengerToAllHopRequests })
        )(PaypalGateway)
    )
);
