import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import Modal from 'components/common/Modal';
import { Button, Input } from 'components/common';
import { addDecimal, getItem, numberToLocaleString } from 'utils/utils';
import { submitUserPriceCheck } from 'utils/api';
import getTranslation from 'utils/getTranslation';

const Wrapper = styled.div`
  text-align: left;
  color: #fff;
  font-size: 12px;
  background-color: #EC9719;
  padding: 50px 30px;
  @media (max-width: 800px) {
    height: 100vh;
  }
  h4 {
    margin-bottom: 10px;
  }
  .header {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    margin-bottom: 20px;
    span {
      font-size: 24px;
      margin-top: 20px;
    }
  }
  .text {
    color: #222222;
    font-size: 16px;
    line-height: 19px;
  }
`;

export default class ExtraCheckErrorModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      accepted: false,
      isValidEmail: false
    };
  }
  getTotalCost = (costItems) => {
    if (Array.isArray(costItems) && costItems.length > 0) {
      const sum = costItems.reduce((accumulator, currentVal) => (accumulator + currentVal.cost), 0);
      return numberToLocaleString(
        addDecimal(sum, costItems[0].costDecimalPlaces),
        {
          selectedCountry: getItem('selectedLanguage'),
          selectedCurrency: getItem('selectedCurrency'),
        },
      );
    }

    return '';
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    }, () => {
      if (name === 'email') {
        this.handleEmailCheck(value);
      }
    });
  }

  handleEmailCheck = (text) => {
    const re = /\S+@\S+\.\S+/;
    const isValidEmail = re.test(text);
    this.setState({ isValidEmail });
  }

  handleClose = () => {
    this.setState({ accepted: false });
    this.props.handleCloseModal();
  };

  handleAccept = () => {
    this.setState({ accepted: true });
  }

  handleSubmit = async () => {
    let message = '';
    this.setState({ submitting: true });
    this.props.hopRequests.forEach((hopRequest, index) => {
      const {
        fromAirportIc,
        toAirportIc,
        stdDate,
        adultCount,
        childCount,
        infantCount,
      } = hopRequest;
      const { airlineIc, flightNum } = hopRequest.hopSegmentControls[0].flightSegments[0];
      message = `${message}
      Hop Request ${index + 1}
      From: ${fromAirportIc}
      To: ${toAirportIc}
      Date: ${stdDate}
      Passengers: ${adultCount + childCount + infantCount}
      Airline IC: ${airlineIc}
      Flight Num: ${flightNum}
      Seat Class: ${hopRequest.hopSegmentControls[0].seatClass}
      Total Cost: ${this.getTotalCost(this.props.costItems)}`;
    });

    try {
      const payload = {
        issue: message,
        subject: 'Contact user If Price Stays Same',
      };
      await submitUserPriceCheck(payload);
      this.handleClose();
    } catch (err) {
      console.log(err);
      this.handleClose();
    }
  }

  render() {
    const { accepted, email, submitting, isValidEmail } = this.state;
    const {
      open,
    } = this.props;
    return (
      <Modal
        open={open}
        disableFullScreen={!isMobile}
        handleCloseModal={this.handleClose}
        dialogStyle={{ width: isMobile ? '100%' : 375, maxWidth: isMobile ? '100%' : 375 }}
      >
      <Wrapper>
        <div className="header">
          <img src={require("assets/icons/warning.svg")} alt="" />
          <span>{getTranslation('fareValidationFail')}</span>
        </div>
        <div className="text" style={{ letterSpacing: '0.05em', marginBottom: 20 }}>
          <p>{getTranslation('fareValidationMsg')}</p>
        </div>
          {!accepted ?
          <React.Fragment>
            <Button
              onClick={this.handleAccept}
              color="#ffffff"
              style={{ color: '#007AC7' }}
            >{getTranslation('onlyIfSame')}</Button>
            <Button
              onClick={this.handleClose}
              style={{ marginTop: 20 }}
            >{getTranslation('noCheckLater')}</Button>
          </React.Fragment> :
          <div>
            <Input
              label={getTranslation('email')}
              fullWidth
              value={email}
              name="email"
              onChange={this.handleChange}
            />
            <Button
              color="#ffffff"
              style={{ color: '#007AC7' }}
              disabled={!isValidEmail}
              onClick={this.handleSubmit}
            >{submitting ? getTranslation('submitting') : getTranslation('submit')}</Button>
            <Button
              onClick={this.handleClose}
              style={{ marginTop: 20 }}
            >{getTranslation('noCheckLater')}</Button>
          </div>}
        </Wrapper>
      </Modal>
    );
  }
}
