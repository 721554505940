import React from "react";
import styled from "styled-components";
import moment from "moment";
import { DateWrapper } from "./CardStyles";
import DestinationLabels from "./DestinationLabels";
import LinkButton from "./LinkButton";

const Post = styled.div`
  width: ${props => (!props.preview ? "296px" : "456px")};
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  margin-bottom: 30px;
  background-color: #fff;
  .imageWrapper {
    position: relative;
    .images {
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      img {
        width: 100%;
        object-fit: cover;
        border: 1px solid #fff;
      }
      &.items1 {
        img {
          height: 167px;
        }
      }
      &.items2 {
        img {
          height: 167px;
          width: 148px;
        }
      }
      &.items3 {
        img {
          height: 148px;
          width: 148px;
        }
      }
      &.items4 {
        img {
          height: 148px;
          width: 148px;
        }
      }
    }
  }

  .details {
    position: relative;
    padding: 15px;
    p {
      color: #777777;
      font-size: 16px;
      line-height: 150%;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
    }
  }
`;

export default class extends React.PureComponent {
  render() {
    const { blogData, preview, color } = this.props;
    let dateArray = [];
    if (blogData) {
      const date = moment(blogData.created_at).format("DD,MMM,YYYY");
      dateArray = date.split(",");
    }
    const images = blogData.text.split("!@#");
    const imagesLength = images.length > 4 ? 4 : images.length;
    return (
      <Post preview={preview}>
        <div className="imageWrapper">
          <div className={`images items${imagesLength}`}>
            {images.map((image, index) => (
              <img src={image} key={index} alt="" />
            ))}
          </div>
          <DateWrapper>
            <span className="date">{dateArray[0]}</span>
            <span className="month">{dateArray[1]}</span>
          </DateWrapper>
          <DestinationLabels blogData={blogData} color={color} />
          <div className="details">
            <p style={{ marginBottom: 30 }}>{blogData.title}</p>
          </div>
          <LinkButton color={color} />
        </div>
      </Post>
    );
  }
}
