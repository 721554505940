import React from "react";
import SeatClassModal from "components/common/SeatClassModal";
import {
  EconomyClassIcon,
  PremiumClassIcon,
  BusinessClassIcon,
  FirstClassIcon,
  AddIcon,
  EditIcon,
} from "components/common/SvgIcons";
import HopSegButton from "./HopSegButton";
import getTranslation from "utils/getTranslation";
import { underscoreToSpace, getItem } from "utils/utils";

class SeatClassChangeButton extends React.PureComponent {
  state = {
    airportData: [],
    selectedIndex: 0,
    openModal: false,
  };

  components = {
    ECONOMY: EconomyClassIcon,
    PREMIUM: PremiumClassIcon,
    BUSINESS: BusinessClassIcon,
    FIRST: FirstClassIcon,
  };

  componentWillReceiveProps(newProps) {
    if (newProps.seatClass !== this.props.seatClass) {
      const selectedIndex = this.state.airportData.findIndex(
        (item) => item === newProps.seatClass
      );
      this.setState({
        selectedIndex,
      });
    }
  }

  handleRequestClose = (priceNum, seatClass) => {
    this.setState({ openModal: false });
    if (priceNum) {
      this.props.onClick({
        type: "seatClass",
        value: seatClass,
        priceNum,
      });
    } else {
      this.props.handleUpdTriItenerary("", "", "", "", true);
    }
  };

  handleClick = (e) => {
    e.preventDefault();
    this.setState({
      openModal: true,
    });
  };

  render() {
    const {
      seatClass,
      baggagePieceCount,
      baggagePieceMaxWeight,
      hopsegmentcontrolId,
      hopSeqNum,
      priceNum,
    } = this.props;
    const initalValues = getItem("hopSegInitialValues") || [];
    const isActive = initalValues.find(
      (item) => item.hopSeqNum === hopSeqNum && seatClass !== item.seatClass
    );
    const SeatIcon = this.components[seatClass || "ECONOMY"];
    const disabledSeatClass = getItem("disabledSeatClass") || [];
    const noResults = disabledSeatClass.indexOf(priceNum) > -1;
    return (
      <div className="hopSegButton">
        <SeatClassModal
          open={this.state.openModal}
          handleCloseModal={this.handleRequestClose}
          hopsegmentcontrolId={hopsegmentcontrolId}
          smartValue="SEAT_CLASS"
          hopSeqNum={hopSeqNum}
          priceNum={priceNum}
        />
        <HopSegButton
          onClick={this.handleClick}
          disabled={
            this.props.disabled ||
            this.state.airportData.length === 1 ||
            noResults
          }
          label="Cabin"
          active={isActive}
          btnContent={
            <>
              <SeatIcon stroke={isActive ? "#222222" : "#222222"} />
              <b className="__release-text">
                {underscoreToSpace(seatClass)}{" "}
                {seatClass === "PREMIUM" ? "Economy" : ""}
              </b>
            </>
          }
          text={
            <div
              style={{ display: "flex", flexFlow: "column", marginBottom: 0 }}
            >
              {baggagePieceCount > 0 || baggagePieceMaxWeight > 0 ? (
                <p>
                  {`${
                    baggagePieceCount > 0 ? `${baggagePieceCount} x` : ""
                  } ${baggagePieceMaxWeight} Kg`}
                </p>
              ) : (
                <p>
                  {getTranslation("noBaggage")} {getTranslation("included")}
                </p>
              )}
            </div>
          }
        ></HopSegButton>
      </div>
    );
  }
}

export default SeatClassChangeButton;
