import React, { Component } from 'react';
import { ApolloConsumer } from 'react-apollo';
import FlightResults from './FlightResults';

export default class extends Component {
  componentDidMount() {
    //
  }
  render() {
    return (
      <ApolloConsumer>
        {client => (
          <FlightResults client={client} encoded={this.props.match.params.encoded} />
        )}
      </ApolloConsumer>
    );
  }
}
