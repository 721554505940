import { store } from 'redux-store';
import { getItem, setItem } from 'utils/utils';
import {
  REMOVE_PASSENGER_FROM_ALL_HOP_REQUEST,
  ENABLE_PASSENGER_STICKY,
} from 'redux-store/actions';

export const removePassenger = (payload) => {
  store.dispatch({
    type: REMOVE_PASSENGER_FROM_ALL_HOP_REQUEST,
    payload,
  });
  store.dispatch({
    type: ENABLE_PASSENGER_STICKY,
  });
  if (getItem('passengers')) {
    const removedPassenger = getItem('passengers').filter(item =>
      ((item.firstName === payload.firstName &&
      item.lastName === payload.lastName &&
      item.gender === payload.gender &&
      item.birthdate === payload.birthdate) ? null : item));
    setItem('passengers', removedPassenger);
  }
};

export const handleRemovePassenger = (passenger) => {
  const payload = {
    ...passenger,
  };
  removePassenger(payload);
};
