import enPrivacy from './en/privacy';
import trPrivacy from './tr/privacy';
import dePrivacy from './de/privacy';
import esPrivacy from './es/privacy';
import frPrivacy from './fr/privacy';
import itPrivacy from './it/privacy';
import nlPrivacy from './nl/privacy';
import plPrivacy from './pl/privacy';
import ruPrivacy from './ru/privacy';
import ukPrivacy from './uk/privacy';
import ptPrivacy from './pt/privacy';
import enTerms from './en/terms';
import trTerms from './tr/terms';
import deTerms from './de/terms';
import esTerms from './es/terms';
import frTerms from './fr/terms';
import itTerms from './it/terms';
import nlTerms from './nl/terms';
import plTerms from './pl/terms';
import ruTerms from './ru/terms';
import ukTerms from './uk/terms';
import ptTerms from './pt/terms';

export default {
  privacyPage: {
    en: enPrivacy,
    tr: trPrivacy,
    de: dePrivacy,
    es: esPrivacy,
    fr: frPrivacy,
    it: itPrivacy,
    nl: nlPrivacy,
    pl: plPrivacy,
    ru: ruPrivacy,
    uk: ukPrivacy,
    pt: ptPrivacy,
  },
  termsPage: {
    en: enTerms,
    tr: trTerms,
    de: deTerms,
    es: esTerms,
    fr: frTerms,
    it: itTerms,
    nl: nlTerms,
    pl: plTerms,
    ru: ruTerms,
    uk: ukTerms,
    pt: ptTerms,
  },
};
