import moment from "moment";
import config from "config";
import Base64 from "./base64";
import locales from "locales";
import {
  AirlineFilterIcon,
  BusIcon,
  TrainIcon,
  BoatIcon,
  TaxiIcon,
} from "components/common/SvgIcons";
import facebook from "assets/icons/facebook.svg";
import twitter from "assets/icons/twitter.svg";
import instagram from "assets/icons/instagram.svg";

const store = require("store");

const userLocale = window.navigator.language;

export const socialNetworks = [
  {
    name: "Facebook",
    icon: facebook,
  },
  {
    name: "Twitter",
    icon: twitter,
  },
  {
    name: "Instagram",
    icon: instagram,
  },
];

export const transportModes = [
  {
    icon: AirlineFilterIcon,
    value: "FLIGHT",
    label: "airline",
  },
  {
    icon: BusIcon,
    value: "BUS",
    label: "bus",
  },
  {
    icon: TrainIcon,
    value: "TRAIN",
    label: "train",
  },
  {
    icon: BoatIcon,
    value: "BOAT",
    label: "boat",
  },
  {
    icon: TaxiIcon,
    value: "TAXI",
    label: "taxi",
  },
];

const a = [
  "",
  "one ",
  "two ",
  "three ",
  "four ",
  "five ",
  "six ",
  "seven ",
  "eight ",
  "nine ",
  "ten ",
  "eleven ",
  "twelve ",
  "thirteen ",
  "fourteen ",
  "fifteen ",
  "sixteen ",
  "seventeen ",
  "eighteen ",
  "nineteen ",
];
const b = [
  "",
  "",
  "twenty",
  "thirty",
  "forty",
  "fifty",
  "sixty",
  "seventy",
  "eighty",
  "ninety",
];
const options = { year: "numeric", month: "short", day: "numeric" };

export const months = [
  {
    name: "January",
    value: "01",
  },
  {
    name: "February",
    value: "02",
  },
  {
    name: "March",
    value: "03",
  },
  {
    name: "April",
    value: "04",
  },
  {
    name: "May",
    value: "05",
  },
  {
    name: "June",
    value: "06",
  },
  {
    name: "July",
    value: "07",
  },
  {
    name: "August",
    value: "08",
  },
  {
    name: "September",
    value: "09",
  },
  {
    name: "October",
    value: "10",
  },
  {
    name: "November",
    value: "11",
  },
  {
    name: "December",
    value: "12",
  },
];

export function encode(obj) {
  const encodedStr = Base64.encode(JSON.stringify(obj));
  return encodedStr;
}

export function decode(string) {
  const decodedStr = JSON.parse(Base64.decode(string));
  return decodedStr;
}

export function getCookie(name) {
  const match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
  if (match) {
    return match[2];
  }
  return null;
}

export function setCookie(name, value) {
  document.cookie = `${name}=${value}`;
}

export function getSessionItem(name) {
  return window.sessionStorage.getItem(name);
}

export function setSessionItem(name, value) {
  window.sessionStorage.setItem(name, value);
}

export function getItem(name) {
  return store.get(name);
}
export function setItem(name, data) {
  store.set(name, data);
}

export function removeItem(name) {
  store.remove(name);
}

export const validateEmail = (email) => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
};

export function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function underscoreToSpace(name) {
  return name
    .toLowerCase()
    .split("_")
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");
}

export function getBrowserLocale() {
  return (
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    navigator.userLanguage
  );
}

export function getLanguage(locale, availableLanguages) {
  let availableLanguage = { locale: "en", language: "English" };
  const findLanguage = availableLanguages.find(
    (item) => item.locale === locale
  );
  if (findLanguage) {
    availableLanguage = findLanguage;
  } else {
    const splitLocale = locale.substring(0, 2);
    const searchLanguage = availableLanguages.find(
      (item) => item.locale.substring(0, 2) === splitLocale
    );
    if (searchLanguage) {
      availableLanguage = searchLanguage;
    }
  }
  return availableLanguage;
}

export function localeDate(date) {
  return moment(date).toDate().toLocaleDateString(userLocale, options);
}

export function differenceInMinutes(unixDate) {
  const currentTime = moment();
  const expiryTime = moment(unixDate * 1000);
  return currentTime.diff(expiryTime, "minutes");
}

export function getChunks(array, size) {
  const results = [];
  while (array.length) {
    results.push(array.splice(0, size));
  }
  return results;
}

export function currencySeperator(n) {
  const parts = n.toString().split(".");
  return (
    parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
    (parts[1] ? "." + parts[1] : "")
  );
}

function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export function addDecimal(sum, decimalPlaces = 2) {
  const total = (sum / 10 ** decimalPlaces).toFixed(decimalPlaces);
  return total;
}

export function numberToLocaleString(
  number,
  { selectedCountry, selectedCurrency }
) {
  const normalizedNumber = Number(number);

  const localeStringOptions = {
    style: "currency",
    currency: selectedCurrency,
  };

  if (selectedCountry === "XX") {
    return normalizedNumber.toLocaleString("en", localeStringOptions);
  }

  return normalizedNumber.toLocaleString(
    getItem("selectedLanguage"),
    localeStringOptions
  );
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function capitalizeWords(string) {
  return string.toLowerCase().replace(/\b./g, (a) => a.toUpperCase());
}

export function getPrivacyLocale(selectedLanguage) {
  return locales.privacyPage[selectedLanguage] || locales.privacyPage.en;
}

export function getTermsLocale(selectedLanguage) {
  return locales.termsPage[selectedLanguage] || locales.termsPage.en;
}

export function cancelAllRequests() {
  window.stop();
  setItem("cancelAllRequests", true);
}

export function clearLocalStore() {
  removeItem("tripSearched");
  removeItem("adpasi");
  removeItem("passengers");
  removeItem("encodedTripSearch");
  removeItem("redirectTo");
  removeItem("lastFlightModified");
  removeItem("doAsyncFareRule");
  removeItem("savedPriceNumTimes");
  removeItem("hopSegInitialValues");
  removeItem("initialAirlineIcs");
  removeItem("disabledSeatClass");
  removeItem("disabledDateAndCancellation");
}

export function getTipDomain(influencerDetails) {
  return influencerDetails
    ? influencerDetails.customsitedomains.length > 0
      ? influencerDetails.customsitedomains[0].activated_at
        ? influencerDetails.customsitedomains[0].domain
        : `${influencerDetails.tip_sub_domain}.${config.tipFrontUrl}`
      : `${influencerDetails.tip_sub_domain}.${config.tipFrontUrl}`
    : "";
}

export function mergeObjs(obj1, obj2) {
  for (var attr in obj2) {
    obj1[attr] = obj2[attr];
  }
  return obj1;
}

export function hex2rgb(hex, opacity) {
  var h = hex.replace("#", "");
  h = h.match(new RegExp("(.{" + h.length / 3 + "})", "g"));

  for (var i = 0; i < h.length; i++)
    h[i] = parseInt(h[i].length == 1 ? h[i] + h[i] : h[i], 16);

  if (typeof opacity != "undefined") h.push(opacity);

  return "rgba(" + h.join(",") + ")";
}

export function currencyCoversion(price) {
  const currencyDetails = getItem("currencyDetails");
  const priceMultiplier = getTruncatedValue(
    price * currencyDetails.xcurrencyPerUsd
  );
  const amountDecimalPlaces = currencyDetails.minorUnit;
  const amount = priceMultiplier * Math.pow(10, amountDecimalPlaces);
  const amountWithDecimal = addDecimal(amount, amountDecimalPlaces);
  const localizedCost = numberToLocaleString(amountWithDecimal, {
    selectedCountry: getItem("selectedLanguage"),
    selectedCurrency: currencyDetails.currencyIso,
  });
  return localizedCost;
}

const getTruncatedValue = (num) => {
  num = num.toString(); //If it's not already a String
  num = num.slice(0, num.indexOf(".") + 3); //With 3 exposing the hundredths place
  return Number(num);
};

export function getTransportDetails(transportMode) {
  const transport = transportModes.find((item) => item.value === transportMode);
  return transport;
}

export function fancyTimeFormat(time) {
  // Hours, minutes and seconds
  var hrs = ~~(time / 3600);
  var mins = ~~((time % 3600) / 60);
  var secs = ~~time % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  var ret = "";

  if (hrs > 0) {
    ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
  }

  ret += "" + mins + ":" + (secs < 10 ? "0" : "");
  ret += "" + secs;
  return ret;
}

export function resizedImage(url, height = 300) {
  const image = url
    ? url.replace(
      "flystrait/image/upload/",
      `flystrait/image/upload/h_${height}/`
    )
    : "";
  return image;
}
export function resizedImageWidth(url, width = 300) {
  const image = url.replace(
    "flystrait/image/upload/",
    `flystrait/image/upload/w_${width}/`
  );
  return image;
}
export function swipedetect(el, callback) {
  var touchsurface = el,
    swipedir,
    startX,
    startY,
    distX,
    distY,
    threshold = 10, //required min distance traveled to be considered swipe
    restraint = 100, // maximum distance allowed at the same time in perpendicular direction
    allowedTime = 1000, // maximum time allowed to travel that distance
    elapsedTime,
    startTime,
    handleswipe = callback || function (swipedir) { };

  touchsurface.addEventListener(
    "touchstart",
    function (e) {
      var touchobj = e.changedTouches[0];
      swipedir = "none";
      startX = touchobj.pageX;
      startY = touchobj.pageY;
      startTime = new Date().getTime(); // record time when finger first makes contact with surface
      // e.preventDefault();
    },
    false
  );

  touchsurface.addEventListener(
    "touchmove",
    function (e) {
      //e.preventDefault(); // prevent scrolling when inside DIV
    },
    false
  );

  touchsurface.addEventListener(
    "touchend",
    function (e) {
      var touchobj = e.changedTouches[0];
      distX = touchobj.pageX - startX; // get horizontal dist traveled by finger while in contact with surface
      distY = touchobj.pageY - startY; // get vertical dist traveled by finger while in contact with surface
      elapsedTime = new Date().getTime() - startTime; // get time elapsed
      if (elapsedTime <= allowedTime) {
        // first condition for awipe met
        if (Math.abs(distX) >= threshold && Math.abs(distY) <= restraint) {
          // 2nd condition for horizontal swipe met
          swipedir = distX < 0 ? "left" : "right"; // if dist traveled is negative, it indicates left swipe
        } else if (
          Math.abs(distY) >= threshold &&
          Math.abs(distX) <= restraint
        ) {
          // 2nd condition for vertical swipe met
          swipedir = distY < 0 ? "up" : "down"; // if dist traveled is negative, it indicates up swipe
        }
      }
      handleswipe(swipedir);
      //e.preventDefault();
    },
    false
  );
}

export function inWords(num) {
  if ((num = num.toString()).length > 9) return "overflow";
  let n = ("000000000" + num)
    .substr(-9)
    .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  if (!n) return;
  var str = "";
  str +=
    n[1] != 0
      ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore "
      : "";
  str +=
    n[2] != 0
      ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lakh "
      : "";
  str +=
    n[3] != 0
      ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand "
      : "";
  str +=
    n[4] != 0
      ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred "
      : "";
  str +=
    n[5] != 0
      ? (str != "" ? "and " : "") +
      (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
      ""
      : "";
  return str;
}

export function getCityFromAirportIc(airportIc) {
  const allAirports = getItem("allAirports");
  const airport = allAirports.find((item) => item.airportIc === airportIc);
  return airport ? airport.city : "";
}
export function getYears() {
  const currentYear = parseInt(moment().format("YYYY"), 10);
  let years = [];
  for (var i = 0; i < 50; i++) {
    years = [...years, currentYear + i];
  }
  return years;
}

export const insertAtIndex = (arr, index, newItem) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted item
  newItem,
  // part of the array after the specified index
  ...arr.slice(index),
];

export const categoryCodeToStar = (code) => {
  if (/.EST/.test(code) || /.LL/.test(code) || /.LUX/.test(code)) {
    return parseInt(code.slice(0, 1));
  }
  if (/APTH./.test(code) || /BB./.test(code) || /HR./.test(code) || /HS./.test(code) || /HSR./.test(code)) {
    return parseInt(code.slice(-1, 1));
  }
  if (/H.S/.test(code)) {
    return parseInt(code.slice(1, 2));
  }
  if (code === "APTH" || code === "HS") {
    return 1;
  }
  if (code === "SUP") {
    return 4;
  }

  return 0;
};