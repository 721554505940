import React, { Component } from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import getTranslation from "utils/getTranslation";

export default class extends Component {
  state = {
    passengers: [],
  };

  getPassengers = () => (
    this.props.passengers.map((item, i) => {
      const doesExist = this.props.currentPassengers.find(passenger =>
      (passenger.firstName === item.firstName &&
        passenger.lastName === item.lastName &&
        passenger.gender === item.gender &&
        passenger.birthdate === item.birthdate));
      return doesExist ? null : <MenuItem value={item} key={i}>{item.firstName}</MenuItem>;
    })
  )

  handleChange = (event) => {
    this.props.handleSelect(event.target.value);
  }

  render() {
    return (
      <FormControl variant="filled" style={{ marginBottom: 15 }}>
        <InputLabel htmlFor="filled-age-simple">{getTranslation("selectPassenger")}</InputLabel>
        <Select
          value={this.state.value}
          onChange={this.handleChange}
          input={<FilledInput name="age" id="filled-age-simple" />}
        >
          {this.getPassengers()}
        </Select>
      </FormControl>
    );
  }
}
