import React from "react";
import styled from "styled-components";
import { Button } from "components/common";
import { AddIcon, MinusIcon } from "components/common/SvgIcons";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .count {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ec9719;
    color: #fff;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.16);
  }
`;

export default ({ style = {}, count, handleDecrease, handleIncrease }) => (
  <Wrapper style={style}>
    <div style={{ width: 76 }}>
      <Button
        style={{
          height: 44
        }}
        color="#D0F3FC"
        onClick={handleDecrease}
      >
        <MinusIcon stroke="#007AC7" style={{ height: 24, width: 24 }} />
      </Button>
    </div>
    <span className="count">{count}</span>
    <div style={{ width: 76 }}>
      <Button
        style={{
          height: 44
        }}
        color="#D0F3FC"
        onClick={handleIncrease}
      >
        <AddIcon stroke="#007AC7" style={{ height: 24, width: 24 }} />
      </Button>
    </div>
  </Wrapper>
);
