import gql from 'graphql-tag';

export const updTripFareruleQuery = gql`mutation updTripFarerule(
  $tripId: Int!
) {
  updTripFarerule(
    tripId: $tripId
  ) {
    hopRequests {
      id
      fromAirportIc,
      toAirportIc,
      hopSegmentControls {
        id,
        fscode,
        farerules {
          id,
          category,
          detail
        }
        flightSegments {
          airlineIc,
        }
      },
    },
  }
}`;

export const getTripById = gql`query tripById(
  $id: Int!
) {
  tripById(
    id: $id
  ) {
    hopRequests {
      id
      fromAirportIc,
      toAirportIc,
      hopSegmentControls {
        id,
        fscode,
        farerules {
          id,
          category,
          detail
        }
        flightSegments {
          airlineIc,
        }
      },
    },
  }
}`;
