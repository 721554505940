import React from 'react';
import { graphql, compose } from 'react-apollo';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { FadeLine, Button } from 'components/common';
import { setExtrabaggageCount } from 'components/common/BaggageCount/queries';
import { setHopRequestsData, setTripCost } from 'redux-store/actions';
import getTranslation from 'utils/getTranslation';
import { addDecimal, numberToLocaleString, getItem } from 'utils/utils';
import IconDelete from 'assets/icons/Icon-delete.svg';

const Confirmation = styled.div`
  display: flex;
  justify-content: flex-end;
`;

class ExtraBaggage extends React.PureComponent {
  constructor(props) {
    super(props);
    const baggage = props.extraBaggages.find(item => item.quantity > 0 && item.passengerNum === props.passengerNum);
    this.state = {
      baggage
    }
  }
  getTotalPrice = () => {
    const { baggage } = this.state;
    const cost = baggage.quantity * baggage.serviceCostAmount;
    return numberToLocaleString(
        addDecimal(
          cost,
          baggage.costDecimalPlaces,
        ),
        {
          selectedCountry: getItem('selectedLanguage'),
          selectedCurrency: baggage.serviceCostCurrencyIso,
        },
      )
  }

  handleCancel = () => {
    this.setState({
      showConfirmation: false
    });
  }

  handleDeleteConfirmation = () => {
    this.setState({
      showConfirmation: true
    }, () => {
      document.querySelector('.MuiDialogContent-root').scrollBy(0, 50);
    });
  }

  handleDelete = async () => {
    const { baggage } = this.state;
    this.setState({ loading: true });
    try {
      const reset = await this.props.setExtrabaggageCount({
        variables: {
          hopsegmentcontrolId: baggage.hopsegmentcontrolId,
          extrabaggageId: baggage.id,
          count: 0
        },
      });
      this.props.setHopRequestsData(reset.data.setExtrabaggageCount.hopRequests);
      this.props.setTripCost(reset.data.setExtrabaggageCount.tripCostItems);
      this.setState({ loading: false, baggage: null, showConfirmation: false });
    } catch (err) {
      console.log(err);
      this.setState({ loading: false, showConfirmation: false });
    }
  }
  render() {
    const { baggage, loading, showConfirmation } = this.state;
    const { fromAirportIc, toAirportIc } = this.props;
    return (
      baggage ? <React.Fragment>
        <div className="item" style={{ alignItems: 'center', color: showConfirmation ? '#EB5757' : null }}>
          <div>
            {getTranslation('extraBaggage')}
            {fromAirportIc && <span style={{ marginLeft: 5 }}>({fromAirportIc} > {toAirportIc})</span>}
          </div>
          {!showConfirmation && <div style={{ minWidth: 42, width: 42 }}>
            <Button
              style={{
                height: 42,
                width: 42,
                minWidth: 42
              }}
              color="#fff"
              disabled={loading}
              onClick={this.handleDeleteConfirmation}
            >
              <img src={IconDelete} alt="" />
            </Button>
          </div>}
          <div className="price">{showConfirmation && <span>- </span>}{this.getTotalPrice()}</div>
        </div>
        {showConfirmation && <Confirmation>
          <div>
            <Button
              style={{
                height: 36,
                color: '#56CCF2'
              }}
              color="#fff"
              disabled={loading}
              onClick={this.handleCancel}
            >
              Cancel
            </Button>
          </div>
          <div style={{ marginLeft: 20 }}>
            <Button
              style={{
                height: 36,
              }}
              color="#56CCF2"
              disabled={loading}
              onClick={this.handleDelete}
            >
              Confirm remove
            </Button>
          </div>
        </Confirmation>}
        <FadeLine />
      </React.Fragment> : null
    );
  }
}

const mapDispatchToProps = {
  setHopRequestsData,
  setTripCost,
};

export default compose(
  graphql(setExtrabaggageCount, { name: 'setExtrabaggageCount' }),
  connect(null, mapDispatchToProps),
)(ExtraBaggage);
