import React from "react";
import { ApolloProvider } from "react-apollo";
import DomainPayment from "./DomainPayment";
import apolloClient from "apolloClient";

export default ({ handleShare, handleRedirect, domainData }) => (
  <ApolloProvider client={apolloClient}>
    <DomainPayment handleShare={handleShare} handleRedirect={handleRedirect} domainData={domainData} />
  </ApolloProvider>
);
