import React from "react";
import styled from "styled-components";
import { Button } from "components/common";
import { CloseIcon } from "components/common/SvgIcons";
import {
  getTwitterRequestToken,
  getTwitterAuthToken,
  handleTwitterPost,
  getTwitterUserDetails,
  getFacebookAccessToken
} from "utils/api";
import getTranslation from "utils/getTranslation";
import { setItem, getItem, removeItem } from "utils/utils";
import dragIcon from "assets/icons/Icon-Drag.svg";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #dddddd;
  &:hover {
    background-color: #f0f0f0;
  }
  .network {
    display: flex;
    align-items: center;
    img {
      margin-right: 10px;
    }
  }
  .details {
    display: flex;
    align-items: center;
    span {
      margin-right: 10px;
      color: #007ac7;
      font-size: 16px;
    }
    svg {
      path {
        fill: #eb5757;
      }
    }
  }
`;

let interval;

export default class extends React.PureComponent {
  state = {
    accessToken:
      "EAAGe5rkXDnMBANLojzLMJZBQ1YvcWTZACFId1mGcFvmfGUqZA6EInrN7wVDL5jZCEmY9ShxsjaC7hNudnRmyeI6w6CK0uZA6ieCBoLGMNKwShndYHP7U9kJZChobBzX1KmvchUcI2ZAOB18NfV75JiSMEp1FlrlCCcyv5vrZALirZBa8zs92nXhcXVF9H9IGZCT6l6gKC3thKZAEQZDZD",
    userSocialId: "10156282941062282",
    pageId: "470846536403877"
  };

  async componentWillMount() {
    if (this.props.network.name === "Twitter") {
      const oauth_token = getItem("twitter_oauth_token");
      const oauth_verifier = getItem("twitter_oauth_verifier");
      if (oauth_token) {
        this.getTwitterAccessToken(oauth_token, oauth_verifier);
      }
    }
  }

  getTwitterAccessToken = async (oauth_token, oauth_verifier) => {
    try {
      const payload = {
        oauthRequestToken: oauth_token,
        oauth_verifier,
        oauthRequestTokenSecret: getItem("oauthTokenSecret")
      };
      const response = await getTwitterAuthToken(payload);
      const userData = await getTwitterUserDetails({
        accessToken: response.oauthAccessToken,
        accessTokenSecret: response.oauthAccessTokenSecret
      });

      const connectPayload = {
        access_token: response.oauthAccessToken,
        generic_field1: response.oauthAccessTokenSecret,
        generic_field2: userData.screen_name,
        connect: this.props.network.name.toUpperCase()
      };
      this.props.handleInsertConnect(connectPayload);

      removeItem("twitter_oauth_token");
      removeItem("twitter_oauth_verifier");
      removeItem("oauthTokenSecret");
      removeItem("dashboard");
    } catch (err) {
      console.log(err);
    }
  };

  handleConnect = () => {
    const { network } = this.props;
    if (network.name === "Facebook") {
      window.FB.login(
        response => {
          if (response.authResponse) {
            this.getFacebookPages(response.authResponse);
          } else {
            console.log("User cancelled login or did not fully authorize.");
          }
        },
        {
          scope: "manage_pages,pages_show_list,publish_pages",
          return_scopes: true
        }
      );
    }
    if (network.name === "Twitter") {
      this.handleTwitterConnect();
    }
  };

  getFacebookPages = async authResponse => {
    clearInterval(interval);
    interval = null;
    const longToken = await getFacebookAccessToken(authResponse.accessToken);
    window.FB.api(
      "/me/accounts",
      { access_token: longToken.access_token },
      response => {
        if (response && !response.error) {
          console.log(response.data[0]);
          const connectPayload = {
            access_token: longToken.access_token,
            generic_field1: response.data[0].access_token,
            generic_field2: response.data[0].name,
            generic_field3: response.data[0].id,
            connect: this.props.network.name.toUpperCase()
          };
          this.props.handleInsertConnect(connectPayload);
        }
      }
    );
  };

  handleTwitterConnect = async () => {
    console.log("twitter connect");
    try {
      const response = await getTwitterRequestToken();
      if (response.oauthToken) {
        setItem("oauthTokenSecret", response.oauthTokenSecret);
        if (this.props.dashboard) {
          setItem("twitterRoute", "dashboard");
        } else {
          setItem("twitterRoute", "step");
        }
        window.location.href = `https://twitter.com/oauth/authorize?oauth_token=${response.oauthToken}`;
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleTwitterPostText = async () => {
    try {
      await handleTwitterPost({
        status: "This is a test post. If u see this. Hurray then!!"
      });
    } catch (err) {
      console.log(err);
    }
  };

  testPost = () => {
    if (this.props.network.name === "Twitter") {
      this.handleTwitterPostText();
    }
  };

  handleDelete = () => {
    this.props.handleDeleteConnect(this.props.connectDetails.id);
  };
  render() {
    const { network, connectDetails } = this.props;
    return (
      <Wrapper>
        <div className="network">
          <img src={dragIcon} alt="" />
          <img src={network.icon} alt="" />
          <span>{network.name}</span>
        </div>
        <div>
          {connectDetails ? (
            <div className="details">
              <span>
                {network.name === "Twitter" ? "@" : ""}
                {connectDetails.generic_field2}
              </span>
              <CloseIcon onClick={this.handleDelete} />
            </div>
          ) : (
            <Button
              style={{ height: 32, width: 100 }}
              color="#F2CC56"
              onClick={this.handleConnect}
            >
              {getTranslation("connect")}
            </Button>
          )}
          {/* <Button style={{ height: 32, width: 100 }} onClick={this.testPost}>
            Test post
          </Button> */}
        </div>
      </Wrapper>
    );
  }
}
