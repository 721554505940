import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { getItem } from 'utils/utils';
import config from 'config';

const httpLink = createHttpLink({
  uri: config.graphqlUrl,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const tokenDetails = getItem('tokenDetails');
  const guestLogin = getItem('guestLogin');
  // return the headers to the context so httpLink can read them
  const authHeader = tokenDetails
    ? { authorization: `Bearer ${tokenDetails.access_token}` }
    : {};
  const guestHeader = guestLogin ? { guest_email: guestLogin.email } : {};
  return {
    headers: {
      ...headers,
      ...authHeader,
      ...guestHeader,
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
