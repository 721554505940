/* eslint-disable */

export const allTranslations = {
  // header
  bookNewTrip: {
    en: "Start New Trip",
    es: "Reservar Un Nuevo Viaje",
    nl: "Boek Een Nieuwe Reis",
    tr: "Yeni Seyahat Planla",
    uk: "Замовте нову поїздку",
    fr: "Nouvelle Réservation",
    de: "Neue Reise Buchen",
    pl: "Zarezerwuj Nową Podróż",
    pt: "Reserve Uma Nova Viagem ",
    ru: "Забронировать новую поездку",
    it: "New Trip",
    dk: "Ny Rejse",
    se: "Ny Resa",
    no: "Ny Tur",
    gr: "Νέο ταξίδι",
    fi: "Uusi Matka",
    lv: "Jauns Ceļojums",
    sk: "Pridať Nový Výlet",
  },
  contact: {
    en: "Contact Us",
    es: "Contact Us",
    nl: "Contact Us",
    tr: "Contact Us",
    uk: "Contact Us",
    fr: "Contact Us",
    de: "Contact Us",
    pl: "Contact Us",
    pt: "Contact Us",
    ru: "Contact Us",
    it: "Contact Us",
  },
  influencer: {
    es: "Influencer",
    nl: "Influencer",
    en: "Influencer",
    tr: "Influencer",
    uk: "Influencer",
    fr: "Influencer",
    de: "Influencer",
    pl: "Influencer",
    pt: "Influencer",
    ru: "Influencer",
    it: "Influencer",
  },
  submit: {
    en: "Submit",
    es: "Submit",
    nl: "Submit",
    tr: "Submit",
    uk: "Submit",
    fr: "Submit",
    de: "Submit",
    pl: "Submit",
    pt: "Submit",
    ru: "Submit",
    it: "Submit",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  myTrips: {
    en: "My Trips",
    es: "Mis viajes",
    nl: "Mijn reizen",
    tr: "Seyahatlerim",
    uk: "Мої поїздки",
    fr: "Mes voyages",
    de: "Meine Reisen",
    it: "I miei viaggi",
    pl: "Moje podróże",
    pt: "Minhas viagens",
    ru: "Мои поездки",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  logout: {
    en: "Log Out",
    es: "Desconexión",
    nl: "Afmelden",
    tr: "Çıkış yap",
    uk: "Вийти",
    fr: "Se déconnecter",
    de: "Logout",
    it: "Logout",
    pl: "Wyloguj się",
    pt: "Sair",
    ru: "Выход",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  terms: {
    en: "Terms & Conditions",
    es: "Términos y Condiciones",
    nl: "Algemene voorwaarden",
    tr: "Hüküm ve Koşullar",
    uk: "Загальні положення та умови",
    fr: "Conditions générales d'utilisation",
    de: "allgemeine Geschäftsbedingungen",
    it: "Termini e Condizioni",
    pl: "Regulamin",
    pt: "Termos e Condições",
    ru: "Условия",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  privacy: {
    en: "Privacy",
    es: "Privacidad",
    nl: "Privacy",
    tr: "Gizlilik",
    uk: "Конфіденційність",
    fr: "Confidentialité",
    de: "Privatsphäre",
    it: "Privacy",
    pl: "Prywatność",
    pt: "Privacidade",
    ru: "Конфиденциальность",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  language: {
    en: "Language",
    es: "Idioma",
    nl: "Taal",
    tr: "Dil",
    uk: "мова",
    fr: "Langue",
    de: "Sprache",
    it: "Lingua",
    pl: "Język",
    pt: "Língua",
    ru: "Language",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  // /login and /sign-up
  login: {
    en: "Log In",
    es: "Conexión",
    nl: "Inloggen",
    tr: "Oturum aç",
    uk: "Особистий кабінет",
    fr: "Connexion",
    de: "Login",
    it: "Login",
    pl: "Zaloguj się",
    pt: "Conecte-se",
    ru: "Вход",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  username: {
    en: "User Name",
    es: "Nombre de usuario",
    nl: "Gebruikersnaam",
    tr: "Kullanıcı adı",
    uk: "Ім'я користувача",
    fr: "Nom d'utilisateur",
    de: "Benutzername",
    it: "Nome utente",
    pl: "Nazwa użytkownika",
    pt: "Nome de usuário",
    ru: "Имя пользователя",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  password: {
    en: "Password",
    es: "Contraseña",
    nl: "Wachtwoord",
    tr: "Şifre",
    uk: "Пароль",
    fr: "Mot de passe",
    de: "Passwort",
    it: "Password",
    pl: "Hasło",
    pt: "Senha",
    ru: "Пароль",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  signup: {
    en: "Sign Up",
    es: "Acceder",
    nl: "Aanmelden",
    tr: "Üye ol",
    uk: "Зареєструватися",
    fr: "Registre",
    de: "Anmeldung",
    it: "Registrati",
    pl: "Zarejestruj się",
    pt: "Inscrever-se",
    ru: "Зарегистрироваться",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  firstname: {
    en: "First Name",
    es: "Nombre",
    nl: "Voornaam",
    tr: "Adı",
    uk: "Ім'я",
    fr: "Prénom",
    de: "Vorname",
    it: "Nome",
    pl: "Imię",
    pt: "Nome",
    ru: "Имя",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  lastname: {
    en: "Last Name",
    es: "Apellidos",
    nl: "Achternaam",
    tr: "Soyadı",
    uk: "Прізвище",
    fr: "Nom de famille",
    de: "Nachname",
    it: "Cognome",
    pl: "Nazwisko",
    pt: "Sobrenome",
    ru: "Фамилия",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  email: {
    en: "Email",
    es: "Correo electrónico",
    nl: "E-mail",
    tr: "E-posta",
    uk: "Електронна пошта",
    fr: "E-Mail",
    de: "eMail",
    it: "E-mail",
    pl: "Email",
    pt: "Correio eletrônico",
    ru: "Адрес электронной почты",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  confirmPassword: {
    en: "Confirm Password",
    es: "Confirme la contraseña",
    nl: "Bevestig wachtwoord",
    tr: "Parolayı doğrulayın",
    uk: "Підтвердити пароль",
    fr: "Confirmer votre mot de passe",
    de: "Passwort bestätigen",
    it: "Conferma password",
    pl: "Proszę potwierdzić hasło",
    pt: "Confirme a senha",
    ru: "Подтверждение пароля",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  loginError: {
    en: "Incorrect User Name Or Password",
    es: "",
    nl: "",
    tr: "",
    uk: "",
    fr: "",
    de: "",
    it: "",
    pl: "",
    pt: "",
    ru: "",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  forgotpassword: {
    en: "Forgot Password",
    es: "",
    nl: "",
    tr: "",
    uk: "",
    fr: "",
    de: "",
    it: "",
    pl: "",
    pt: "",
    ru: "",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  resetpassword: {
    en: "Reset Password",
    es: "",
    nl: "",
    tr: "",
    uk: "",
    fr: "",
    de: "",
    it: "",
    pl: "",
    pt: "",
    ru: "",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  forgotpassword: {
    en: "Forgot Password",
    es: "",
    nl: "",
    tr: "",
    uk: "",
    fr: "",
    de: "",
    it: "",
    pl: "",
    pt: "",
    ru: "",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  signupError: {
    en:
      "This email address is already in use. Please sign in or click on 'Forgot Passwor" +
      "d'.",
    es: "",
    nl: "",
    tr: "",
    uk: "",
    fr: "",
    de: "",
    it: "",
    pl: "",
    pt: "",
    ru: "",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  country: {
    en: "Country",
    es: "País",
    nl: "Land",
    tr: "Ülke",
    uk: "Країна",
    fr: "Pays",
    de: "Land",
    it: "Paese",
    pl: "Kraj",
    pt: "País",
    ru: "Страна",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  billingCountry: {
    en: "Billing Country",
    es: "",
    nl: "",
    tr: "",
    uk: "",
    fr: "",
    de: "",
    it: "",
    pl: "",
    pt: "",
    ru: "",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  cardHolderName: {
    en: "Card Holder Name",
    es: "",
    nl: "",
    tr: "",
    uk: "",
    fr: "",
    de: "",
    it: "",
    pl: "",
    pt: "",
    ru: "",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  resetRequestMessage: {
    en:
      "An email with link to reset your password has been sent to your registered email" +
      " address. Please click on the link to reset your password.",
    es: "",
    nl: "",
    tr: "",
    uk: "",
    fr: "",
    de: "",
    it: "",
    pl: "",
    pt: "",
    ru: "",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  accountActivationMessage: {
    en:
      "An account activation email with a link, has been sent to your registered email " +
      "address. Please click on the link to activate your account.",
    es:
      "Un correo electrónico para activar su cuenta ha sido enviado a la dirección emai" +
      "l que usted ha registrado. Por favor, entre en el enlace para activar su cuenta",
    nl:
      "Er is een accountactiveringsmail verzonden naar uw geregistreerde e-mailadres. K" +
      "lik op onderstaande link om uw nieuwe gebruikersaccount te activeren.",
    tr:
      "Kayıtlı e-posta adresinize bir hesap aktivasyon e-postası gönderildi. Hesabınızı" +
      " aktive etmek için lütfen linke tıklayın",
    uk:
      "Лист активації облікового запису був надісланий на вашу зареєстровану електронну" +
      " адресу.  Будь-ласка, натисніть на посилання, щоб активувати свій обліковий запи" +
      "с",
    fr:
      "Un e-mail d'activation de votre compte a été envoyé à l'adresse e-mail utilisée " +
      "pour vous enregistrer. Veuillez cliquer sur le lien pour activer votre compte.",
    de:
      "Eine Mail zur Kontoaktivierung wurde an die angegebene eMailadresse gesandt. Bit" +
      "te folgen Sie dem Link um ihr Konto zu aktivieren",
    it:
      "Un'e-mail di attivazione dell'account è stata inviata al tuo indirizzo e-mail re" +
      "gistrato. Clicca sul link per attivare il tuo account.",
    pl:
      "Wiadomość e-mail aktywująca konto została wysłana na zarejestrowany adres e-mail" +
      ". Kliknij ten link, aby aktywować konto.",
    pt:
      "Um e-mail de ativação da conta foi enviado para o seu endereço de correio eletrô" +
      "nico registrado. Por favor, clique no link para ativar sua conta",
    ru:
      "Для активации учетной записи на указанный Вами адрес электронной почты было отпр" +
      "авлено письмо. Пожалуйста, нажмите на ссылку, чтобы активировать свой аккаунт.",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  // /trip
  from: {
    en: "From",
    es: "Origen",
    nl: "Van",
    tr: "-den/-dan",
    uk: "Від",
    fr: "De",
    de: "von",
    it: "Da",
    pl: "Od",
    pt: "De",
    ru: "Откуда",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  to: {
    en: "To",
    es: "Destino",
    nl: "Naar",
    tr: "-e/-a",
    uk: "До",
    fr: "À",
    de: "nach",
    it: "A",
    pl: "Do",
    pt: "Para",
    ru: "Куда",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  date: {
    en: "Date",
    es: "Fecha",
    nl: "Datum",
    tr: "Tarih",
    uk: "Дата",
    fr: "Date",
    de: "Datum",
    it: "Data",
    pl: "Data",
    pt: "Data",
    ru: "Дата",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  underTwoYrs: {
    en: "Upto 23 Months",
    es: "Menos de 2 años",
    nl: "Tot 2 jaar",
    tr: "2 yaş altı",
    uk: "До 2 років",
    fr: "Moins de 2 ans",
    de: "unter zwei Jahre",
    it: "Meno di 2 anni",
    pl: "Poniżej 2 lat",
    pt: "Menos de 2 anos",
    ru: "До 2 лет",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  underTwelveYrs: {
    en: "Upto 11 Years",
    es: "Menos de 12 años",
    nl: "Tot 12 jaar",
    tr: "12 yaş altı",
    uk: "До 12 років",
    fr: "Moins de 12 ans",
    de: "unter 12 Jahre",
    it: "Meno di 12 anni",
    pl: "Poniżej 12 lat",
    pt: "Menos de 12 anos",
    ru: "До 12 лет",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  atleastOneAdult: {
    en: "Please enter at least one passenger above 11 years.",
    es: "Por favor añada al menos un adulto",
    nl: "Gelieve ten minste 1 VOLWASSENE in te voeren",
    tr: "Lütfen en az 1 YETİŞKİN seçin",
    uk: "Будь ласка, введіть хоча б один ДОРОСЛИЙ",
    fr: "Veuillez indiquer au moins 1 ADULTE",
    de: "bitte tragen Sie mindestens eine erwachsene Person ein",
    it: "Per favore inserire almeno 1 ADULTO",
    pl: "Wprowadź co najmniej 1 osobę dorosłą",
    pt: "Por favor, indique pelo menos 1 ADULTO",
    ru: "Пожалуйста, укажите хотя бы 1 ВЗРОСЛОГО",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  enterDate: {
    en: "Please Enter Date",
    es: "Por favor indique la FECHA",
    nl: "Voer DATUM in",
    tr: "Lütfen TARİH girin",
    uk: "Будь ласка, введіть дату",
    fr: "Veuillez d'indiquer une date",
    de: "Bitte tragen Sie ein Datum ein",
    it: "Si prega di inserire la data",
    pl: "Wprowadź datę",
    pt: "Por favor, indique a DATA",
    ru: "Пожалуйста, введите ДАТУ",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  enterFrom: {
    en: "Please Enter 'From'",
    es: "Por favor complete la casilla ORIGEN",
    nl: "Vul het veld Van in",
    tr: "Lütfen KALKIŞ noktası seçin",
    uk: "Будь ласка, введіть пункт відправлення",
    fr: "Veuillez indiquer un lieu de départ dans le champs DE",
    de: "Bitte tragen Sie ihren Startpunkt ein",
    it: "Si prega di indicare il luogo di partenza nel campo DA",
    pl: "Proszę podać lokalizację SKĄD",
    pt: "Por favor, indique o campo DE",
    ru: "Пожалуйста, заполните поле ОТКУДА",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  enterTo: {
    en: "Please Enter 'To'",
    es: "Por favor complete la casilla DESTINO",
    nl: "Vul het veld Naar in",
    tr: "Lütfen VARIŞ noktası seçin",
    uk: "Будь ласка, введіть пункт прибуття",
    fr: "Veuillez indiquer votre destination dans le champs À",
    de: "Bitte tragen Sie ihr Ziel ein",
    it: "Si prega di inserire la destinazione nel campo A",
    pl: "Proszę podać lokalizację DOKĄD",
    pt: "Por favor, indique o campo PARA",
    ru: "Пожалуйста, заполните поле КУДА",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  airportFetchError: {
    en: "We are unable to fetch the list of airports, please try again later.",
    es: "Algo va mal, imposible de buscar aeropuertos",
    nl: "Er is iets misgegaan, niet in staat om luchthavens op te halen.",
    tr: "Bir şeyler yanlış gitti, havalimanlarını listeleyemedik",
    uk: "Щось пішло не так. Аеропорти не знайдені",
    fr: "Nous avons rencontré un problème, impossible de lister les aéroports",
    de: "Etwas ist da schiefgelaufen. Flughäfen passen nicht zusammen.",
    it:
      "Qualcosa non ha funzionato, incapace di recuperare le informazioni aeroporti.",
    pl: "Coś poszło nie tak, nie mogę pobrać nazw portów lotniczych.",
    pt: "Algo deu errado, incapaz de buscar aeroportos.",
    ru: "Что-то пошло не так, не удается найти аэропорты",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  passenger: {
    en: "Passenger",
    es: "Pasajero",
    nl: "Passagier",
    tr: "Yolcu",
    uk: "Пасажир",
    fr: "Passager",
    de: "Passagier",
    it: "Passeggero",
    pl: "Pasażer",
    pt: "Passageiro",
    ru: "Пассажир",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  cardHolder: {
    en: "Card Holder",
    es: "Titular de la tarjeta",
    nl: "Kaarthouder",
    tr: "Kart Sahibi",
    uk: "Власник картки",
    fr: "Titulaire de la carte",
    de: "Kreditkartenhalter",
    it: "Titolare della carta",
    pl: "Właściciel karty",
    pt: "Titular do cartão",
    ru: "Владелец карты",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  birthDate: {
    en: "Birth Date",
    es: "Fecha de nacimiento",
    nl: "Geboortedatum",
    tr: "Doğum günü",
    uk: "Дата народження",
    fr: "Date de naissance",
    de: "Geburtsdatum",
    it: "Data di nascita",
    pl: "Data urodzenia",
    pt: "Data de nascimento",
    ru: "Дата рождения",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  frequentFlyerNum: {
    en: "Frequent Flyer Number",
    es: "Número de viajero frecuente",
    nl: "Frequent flyer nummer",
    fr: "Numéro de grand voyageur",
    de: "Vielfliegernummer",
    it: "Numero di viaggiatore regolare",
    pl: "Nr karty stałego pasażera",
    pt: "Número de passageiro frequente",
    ru: "Номер часто летающего пассажира",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  ok: {
    en: "Ok",
    es: "De acuerdo",
    nl: "Ok",
    tr: "Tamam",
    uk: "Ок",
    fr: "Ok",
    de: "Ok",
    it: "Ok",
    pl: "Ok",
    pt: "Ok",
    ru: "Хорошо",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  oneWay: {
    en: "One Way / Multi Trip",
    es: "Sólo ida",
    nl: "Enkele Reis",
    tr: "Tek yön",
    uk: "В один бік",
    fr: "Aller simple",
    de: "einfach",
    it: "Solo andata",
    pl: "Przejazd w jedną stronę",
    pt: "Ida simples",
    ru: "В одну сторону",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  arrivalDate: {
    en: "Choose Arrival Date",
    es: "Escoger fecha de llegada",
    nl: "Kies aankomst datum",
    fr: "Choisir date d'arrivée",
    de: "Ankunftsdatum wählen",
    it: "Scegli data di arrivo",
    pl: "Wybierz datę przyjazdu",
    pt: "Data de chegada",
    ru: "Выберите дату прибытия",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  departureDate: {
    en: "Choose Departure Date",
    es: "Escoger fecha de salida",
    nl: "Kies vertrekdatum",
    fr: "Choisir date de départ",
    de: "Abflugsdatum wählen",
    it: "Scegli data di partenza",
    pl: "Wybierz datę odjazdu",
    pt: "Data de partida",
    ru: "Выберите дату отправления",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  cancel: {
    en: "Cancel",
    es: "Anular",
    nl: "Annuleren",
    tr: "İptal",
    uk: "Скасувати",
    fr: "Annuler",
    de: "Abbruch",
    it: "Annulla",
    pl: "Anuluj",
    pt: "Cancelar",
    ru: "Отменить",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  cardNumber: {
    en: "Card Number",
    es: "Número de tarjeta",
    nl: "Kaartnummer",
    tr: "Kart numarası",
    uk: "Номер картки",
    fr: "Numéro de carte",
    de: "Karten-Nummer",
    it: "Numero di carta",
    pl: "Numer karty",
    pt: "Número do cartão",
    ru: "Номер карты",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  cvv: {
    en: "CVV",
    es: "CVV",
    nl: "CVC",
    tr: "CVV",
    uk: "Код CVV",
    fr: "CVV",
    de: "Kartenprüfnummer",
    it: "CVV",
    pl: "CVV",
    pt: "CVV",
    ru: "CVV",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  exp: {
    en: "Passport Expiry Date",
    es: "Exp",
    nl: "verlopen",
    tr: "Son",
    uk: "Термін дії",
    fr: "Exp",
    de: "Ablaufdatum",
    it: "Exp",
    pl: "Data ważności",
    pt: "Exp",
    ru: "Срок действия",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  confirmBooking: {
    en: "Confirm Booking",
    es: "Confirmar reserva",
    nl: "Bevestig reservering",
    tr: "Rezervasyonu onayla",
    uk: "Підтвердити бронювання",
    fr: "Confirmer la réservation",
    de: "Buchung bestätigen",
    it: "Conferma prenotazione",
    pl: "Potwierdź",
    pt: "Confirme a reserva",
    ru: "Подтвердить бронирование",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  checkingForUser: {
    en: "Checking For User Account",
    es: "",
    nl: "",
    tr: "",
    uk: "",
    fr: "",
    de: "",
    it: "",
    pl: "",
    pt: "",
    ru: "",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  loginConfirmBooking: {
    en: "Sign In And Confirm Booking",
    es: "Confirmar reserva",
    nl: "Bevestig reservering",
    tr: "Rezervasyonu onayla",
    uk: "Підтвердити бронювання",
    fr: "Confirmer la réservation",
    de: "Buchung bestätigen",
    it: "Conferma prenotazione",
    pl: "Potwierdź",
    pt: "Confirme a reserva",
    ru: "Подтвердить бронирование",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  addPassengerError: {
    en: "Unable to add passenger. Please try again later.",
    es: "Impossible añadir el pasajero. Por favor inténtelo más tarde",
    nl: "Kon geen passagier toevoegen. Gelieve later nog eens te proberen.",
    tr: "Yolcu eklenemedi. Lütfen daha sonra tekrar deneyin",
    uk: "Неможливо додати пасажира. Будь ласка спробуйте пізніше",
    fr: "Impossible d'ajouter un passager. Veuillez réessayer plus tard.",
    de:
      "Passagier hinzufügen nicht möglich. Bitte versuchen Sie es später nochmals",
    it: "Impossibile aggiungere il passeggero. Per favore riprovare più tardi.",
    pl: "Nie można dodać pasażera. Proszę spróbować później.",
    pt:
      "Incapaz de adicionar passageiro. Por favor, tente novamente mais tarde.",
    ru: "Невозможно добавить пассажира. Пожалуйста, попробуйте позже.",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  passengerNameLimitError: {
    en:
      "Passenger name is too long for airline systems to handle ({{char}} letters max.)" +
      ", please contact us.",
    es: "",
    nl: "",
    tr: "",
    uk: "",
    fr: "",
    de: "",
    it: "",
    pl: "",
    pt: "",
    ru: "",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  processing: {
    en: "Processing",
    es: "Procesando",
    nl: "Verwerken",
    tr: "İşlem devam ediyor",
    uk: "Обробка",
    fr: "En traitement",
    de: "Wir arbeiten daran",
    it: "In trattamento",
    pl: "Przetwarzanie",
    pt: "Em processamento",
    ru: "Обработка данных",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  connecting: {
    en: "Connecting",
    es: "Conectando",
    nl: "Verbinden",
    tr: "Bağlantı kuruluyor",
    uk: "Підключення  ",
    fr: "Connexion en cours",
    de: "Verbindung wird erstellt",
    it: "Collegamento in corso",
    pl: "Łączenie",
    pt: "Conectando",
    ru: "Соединение",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  unauthPayment: {
    en: "Unable To Authorize Payment",
    es: "Imposible autorizar el pago",
    nl: "Niet in staat om de betaling te autoriseren",
    tr: "Ödeme onaylanmıyor",
    uk: "Неможливо авторизувати платіж",
    fr: "Paiement non autorisé",
    de: "Zahlung wurde nicht veranlasst",
    it: "Pagamento non autorizzato",
    pl: "Nie można autoryzować płatności",
    pt: "Incapaz de autorizar o pagamento",
    ru: "Не удалось авторизовать платёж",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  processingPayment: {
    en: "Processing Payment",
    es: "Procesando el pago",
    nl: "Betaling verwerken",
    tr: "Ödeme alınıyor",
    uk: "Обробка платежу",
    fr: "Paiement en cours de traitement",
    de: "Zahlung erfolgt",
    it: "Trattamento del pagamento",
    pl: "Przetwarzanie płatności",
    pt: "Processando o pagamento",
    ru: "Обработка платежа",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  processingCardErr: {
    en: "Unable To Process Card Information",
    es: "Imposible procesar la información de la tajeta",
    nl: "Kan kaartinformatie niet verwerken",
    tr: "Kart bilgisi alınamıyor",
    uk: "Неможливо обробити інформацію про картку",
    fr: "Traitement des données de la carte impossible",
    de: "Karteninformationen können nicht verarbeitet werden",
    it: "Trattamento delle informazioni della carta impossibile ",
    pl: "Nie można przetworzyć informacji o karcie",
    pt: "Incapaz de processar informações do cartão",
    ru: "Невозможно обработать информацию о карте",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  paymentServeErr: {
    en: "Unable To Connect To Payment Provider",
    es: "Imposible conectar con el servidor de pago",
    nl: "Kan niet met de betalingsserver verbinden",
    tr: "Ödeme sunucusuna bağlanılamıyor",
    uk: "Неможливо підключити платіжний сервер",
    fr: "Connexion au serveur de paiement impossible",
    de: "Verbindung zum Server nicht möglich",
    it: "Collegamento al server di pagamento impossibile ",
    pl: "Nie można połączyć się z serwerem",
    pt: "Não é possível acessar ao servidor de pagamento",
    ru: "Не удается подключиться к серверу платежей",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  finishingPayment: {
    en: "Finishing Payment",
    es: "Imposible conectar con el servidor de pago",
    nl: "Betaling aan het afronden",
    tr: "Son ödeme",
    uk: "Завершення платежу  ",
    fr: "Paiement bientôt terminé",
    de: "Zahlung wird abgeschlossen",
    it: "Validazione del pagamento",
    pl: "Kończenie płatności",
    pt: "Finalizando o pagamento",
    ru: "Завершение платежа",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  // /fligts
  airlinePnr: {
    en: "Airline PNR",
    es: "PNR de la aerolínea",
    nl: "PNR luchtvaartmaatschapij",
    tr: "Havayolu PNR",
    uk: "Код бронювання(PNR) авіакомпанії",
    fr: "PNR de la compagnie aérienne",
    de: "Fluggastdatensatz",
    it: "PNR della compagnia aerea",
    pl: "PNR Linii lotniczych",
    pt: "PNR da companhia aérea",
    ru: "PNR авиакомпании",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  total: {
    en: "Total",
    es: "Total",
    nl: "Totaal",
    tr: "Toplam",
    uk: "Всього",
    fr: "Total",
    de: "Total",
    it: "Totale",
    pl: "Suma łącznie",
    pt: "Total",
    ru: "Всего",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  loading: {
    en: "Loading",
    es: "Cargando",
    nl: "Laden",
    tr: "Yükleniyor",
    uk: "Завантаження",
    fr: "En cours de chargement",
    de: "lädt",
    it: "Caricamento in corso",
    pl: "Ładowanie",
    pt: "Carregando",
    ru: "Загрузка",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  hrs: {
    en: "Hrs",
    es: "Hrs",
    nl: "Uur",
    tr: "Saat",
    uk: "Година",
    fr: "Heures",
    de: "Stunden",
    it: "Ore",
    pl: "Godziny",
    pt: "Horas",
    ru: "Часов",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  mins: {
    en: "Mins",
    es: "Min",
    nl: "Min",
    tr: "dk",
    uk: "Хвилина",
    fr: "Minutes",
    de: "Minuten",
    it: "min",
    pl: "Minuty",
    pt: "Minutos",
    ru: "Минут",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  searchAgain: {
    en: "Search Again",
    es: "Buscar de nuevo",
    nl: "Opnieuw zoeken",
    tr: "Tekrar ara",
    uk: "Шукати знову",
    fr: "Chercher à nouveau",
    de: "Suche nochmals",
    it: "Continua ricerca",
    pl: "Wyszukaj ponownie",
    pt: "Procure de novo",
    ru: "Искать снова",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  noFlights: {
    en:
      "Sorry we could not find matching flights. Please try later or search alternative" +
      " dates or airports.",
    es:
      "Disculpe, no hemos podido encontrar vuelos correspondientes. Intente modificar s" +
      "u búsqueda.",
    nl:
      "Sorry we konden geen overeenkomende vluchten vinden. Probeer uw zoekinstelling t" +
      "e wijzigen.",
    tr:
      "Üzgünüz, seçtiğiniz kriterlerde uçuş bulunamadı. Aramayı değiştirip tekrar deney" +
      "in.",
    uk:
      "Вибачте, ми не змогли отримати будь-які відповідні польоти.  Спробуйте змінити п" +
      "ошук.",
    fr:
      "Désolé, aucun vol ne correspond à votre recherche. Souhaitez-vous modifier la re" +
      "cherche?",
    de:
      "Entschuldigung, wir konnten leider keine passenden Flüge finden. Möchten Sie die" +
      " Suche anpassen?",
    it:
      "Siamo spiacenti, non è possibile ottenere un volo corrispondente. Modificare la " +
      "ricerca?",
    pl:
      "Niestety, nie możemy uzyskać pasujących lotów. Spróbuj zmodyfikować wyszukiwanie" +
      "?",
    pt:
      "Desculpe, não encontramos nenhum voo correspondente. Tente modificar a pesquisa?",
    ru:
      "К сожалению, мы не смогли найти соответствующие рейсы. Попробуйте изменить поиск" +
      ".",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  airline: {
    en: "Airline",
    es: "Compañía aérea",
    nl: "Luchtvaartmaatschappij",
    tr: "Havayolu",
    uk: "Авіакомпанія",
    fr: "Compagnie aérienne",
    de: "Fluggesellschaft",
    it: "Linea aerea",
    pl: "Linia lotnicza",
    pt: "Companhia aéria",
    ru: "Авиакомпания",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  // /booking-confirmed
  viewPrevFlights: {
    en: "View Previous Flights.",
    es: "Ver vuelos anteriores",
    nl: "Eerdere vluchten bekijken",
    tr: "Önceki uçuşlara bakın",
    uk: "Переглянути попередні рейси",
    fr: "Voir les vols précédents",
    de: "vergangene Flüge",
    it: "Visualizza voli precedenti.",
    pl: "Wyświetl poprzednie loty.",
    pt: "Exibir voos anteriores",
    ru: "Просмотреть предыдущие рейсы",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  // /my-trips
  noPrevTrips: {
    en: "Looks like you haven't booked any trips with us previously.",
    es: "Parece que usted no ha efectuado vuelos anteriores",
    nl: "Het lijkt erop dat u geen eerdere reizen heeft gemaakt.",
    tr: "Önceki seyahatleriniz görünmüyor",
    uk: "Схоже, у вас немає попередніх поїздок",
    fr: "Aucun voyage précédent enregistré",
    de: "Sieht aus als ob Sie keine vergangenen Flüge haben.",
    it: "Sembra che tu non abbia nessun viaggio precedente.",
    pl: "Wygląda na to, że nie masz żadnych poprzednich podróży.",
    pt: "Parece que você não tem nenhuma viagem anterior.",
    ru: "Похоже, у вас нет предыдущих поездок",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  // continue-booking
  continue: {
    en: "Continue",
    es: "Continuar",
    nl: "Doorgaan",
    tr: "Devam",
    uk: "Продовжити",
    fr: "Continuer",
    de: "Fortfahren",
    it: "Avanti",
    pl: "Kontynuuj",
    pt: "Prosseguir",
    ru: "Продолжить",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  doNotSignup: {
    en: "Do Not Sign Up",
    es: "No acceda",
    nl: "Niet aanmelden",
    tr: "Üye olma",
    uk: "Не реєструватися",
    fr: "Ne pas s'inscrire",
    de: "Nicht anmelden",
    it: "Non registrarmi",
    pl: "Nie rejestruj się",
    pt: "Não se inscreva",
    ru: "Не регистрироваться",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  mr: {
    en: "MR",
    es: "MR",
    nl: "MR",
    tr: "MR",
    uk: "MR",
    fr: "MR",
    de: "MR",
    it: "MR.",
    pl: "MR.",
    pt: "MR.",
    ru: "MR.",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  miss: {
    en: "MISS",
    es: "MISS",
    nl: "MISS",
    tr: "MISS",
    uk: "MISS",
    fr: "MISS",
    de: "MISS",
    it: "MISS.",
    pl: "MISS.",
    pt: "MISS.",
    ru: "MISS.",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  // /additional-details
  additioanDetailsTitle: {
    en: "Additional Passenger Details",
    es: "Otros detalles del pasajero",
    nl: "Extra passagiersgegevens",
    tr: "Ek Yolcu Ayrıntıları",
    uk: "Додаткова інформація про пасажира",
    fr: "Informations supplémentaires sur le passager",
    de: "Zusätzliche Details zum Passagier",
    it: "Dettagli altri passeggeri",
    pl: "Dodatkowe dane pasażera",
    pt: "Detalhes Adicionais do Passageiro",
    ru: "Дополнительные сведения о пассажире",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  nationality: {
    en: "Nationality",
    es: "Nacionalidad",
    nl: "Nationaliteit",
    tr: "Uyruk",
    uk: "Національність",
    fr: "Nationalité",
    de: "Nationalität",
    it: "Nazionalità",
    pl: "Narodowość",
    pt: "Nacionalidade",
    ru: "Гражданство",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  passportNumber: {
    en: "Passport Number",
    es: "Número de pasaporte",
    nl: "Paspoortnummer",
    tr: "Pasaport Numarası",
    uk: "Номер паспорта",
    fr: "Numéro de passeport",
    de: "Pass-Nummer",
    it: "Numero di passaporto",
    pl: "Numer Paszportu",
    pt: "Número do passaporte",
    ru: "Номер паспорта",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  enterValidPassportExpiry: {
    en: "Please enter a valid passport expiry date.",
    es: "Por favor entre una fecha de caducidad del passporte válida",
    nl: "Vul een geldige vervaldatum voor uw paspoort in",
    tr: "Lütfen pasaportun son geçerlilik tarihini girin",
    uk: "Будь ласка, введіть дійсну дату закінчення терміну дії паспорта",
    fr: "Veuillez entrer une date d'expiration valide du passeport.",
    de: "Bitte tragen Sie ein gültiges Ablaufdatum für ihren Pass ein.",
    it:
      "Si prega di inserire la data di scadenza del passaporto in corso di validità.",
    pl: "Proszę podać datę ważności paszportu.",
    pt: "Por favor insira uma data de caducidade válida do passaporte ",
    ru: "Пожалуйста, введите срок действия паспорта",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  enterValidDob: {
    en: "Please enter a valid date of birth",
    es: "",
    nl: "",
    tr: "",
    uk: "",
    fr: "",
    de: "",
    it: "",
    pl: "",
    pt: "",
    ru: "",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  // Contact
  message: {
    en: "Message",
    es: "Message",
    nl: "Message",
    tr: "Message",
    uk: "Message",
    fr: "Message",
    de: "Message",
    it: "Message",
    pl: "Message",
    pt: "Message",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  subject: {
    en: "Subject",
    es: "Subject",
    nl: "Subject",
    tr: "Subject",
    uk: "Subject",
    fr: "Subject",
    de: "Subject",
    it: "Subject",
    pl: "Subject",
    pt: "Subject",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  contactTitle: {
    en:
      "We love to hear from you. Simply use the form below to send us your message, and" +
      " we'll get back to you soon.",
    es: "",
    nl: "",
    tr: "",
    uk: "",
    fr: "",
    de: "",
    it: "",
    pl: "",
    pt: "",
    ru: "",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  // Modals
  selectLanguage: {
    en: "Select Language",
    es: "",
    nl: "",
    tr: "",
    uk: "",
    fr: "",
    de: "",
    it: "",
    pl: "",
    pt: "",
    ru: "",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  selectCountry: {
    en: "Select Country",
    es: "",
    nl: "",
    tr: "",
    uk: "",
    fr: "",
    de: "",
    it: "",
    pl: "",
    pt: "",
    ru: "",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  // common
  genericErrorMsg: {
    en: "Something went wrong, please try again.",
    es: "Algo ha ido mal, por favor inténtelo de nuevo",
    nl: "Er is iets misgegaan, probeer het opnieuw",
    tr: "Bir şeyler yanlış gitti, lütfen tekrar deneyin",
    uk: "Щось пішло не так. Будь ласка, спробуйте ще раз",
    fr: "Une erreur s'est produite. Veuillez réessayer",
    de: "Etwas ist da schiefgelaufen. Bitte versuchen Sie es später nochmals.",
    it: "Siamo spiacenti, qualcosa non ha funzionato, prova ancora",
    pl: "Coś poszło nie tak – spróbuj ponownie później",
    pt: "Alguma coisa deu errado. Por favor tente outra vez",
    ru: "Что-то пошло не так, попробуйте еще раз",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  apiError: {
    en: "Something went wrong.",
    es: "Algo ha ido mal",
    nl: "Er is iets fout gegaan",
    tr: "Bir şeyler yanlış gitti",
    uk: "Щось пішло не так",
    fr: "Nous avons rencontré un problème.",
    de: "Etwas ist da schiefgelaufen.",
    it: "Qualcosa non ha funzionato",
    pl: "Ups Coś poszlo nie tak",
    pt: "Alguma coisa deu errado.",
    ru: "Что-то пошло не так",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  errorContactSupport: {
    en: "Something went wrong. Please contact us for further information.",
    es:
      "Algo ha ido mal. Por favor contacte el servicio al cliente para más información",
    nl:
      "Er is iets fout gegaan. Neem contact op met de helpdesk voor meer informatie.",
    tr:
      "Bir şeyler yanlış gitti. Lütfen daha fazla bilgi için destek ekibine başvurun.",
    uk:
      "Щось пішло не так.  Будь ласка, зв'яжіться зі службою підтримки для отримання до" +
      "даткової інформації.",
    fr:
      "Une erreur s'est produite.  Pour en savoir plus, veuillez contacter notre centre" +
      " de support.",
    de:
      "Etwas ist da schiefgelaufen. Bitte kontaktieren Sie unseren Support für weitere " +
      "Informationen",
    it:
      "Qualcosa non ha funzionato. Si prega di contattare l'assistenza per ulteriori in" +
      "formazioni.",
    pl:
      "Coś poszło nie tak. Prosimy o kontakt z działem obsługi klienta w celu uzyskania" +
      " dalszych informacji.",
    pt:
      "Algo deu errado. Entre em contato com o suporte para mais informações.",
    ru:
      "Что-то пошло не так. Для получения дополнительной информации обратитесь в службу" +
      " поддержки.",
  },
  resetAll: {
    en: "Reset All",
  },
  reset: {
    en: "Reset",
  },
  passengers: {
    en: "Passengers",
  },
  guests: {
    en: "Guests",
  },
  totalCost: {
    en: "Total Cost",
  },
  departure: {
    en: "Departure",
  },
  terminal: {
    en: "Terminal",
  },
  baggage: {
    en: "Baggage",
  },
  destination: {
    en: "Destination",
  },
  arrival: {
    en: "Arrival",
  },
  duration: {
    en: "Duration",
  },
  hours: {
    en: "Hours",
  },
  minutes: {
    en: "Minutes",
  },
  perKgPc: {
    en: "Kg Per Pc",
  },
  internationSite: {
    en: "Go To Our International Site",
  },
  chooseCountry: {
    en: "Choose Your Country",
  },
  go: {
    en: "Go",
  },
  dep: {
    en: "Dep",
  },
  arr: {
    en: "Arr",
  },
  options: {
    en: "Options",
  },
  fareRuleUnable: {
    en: "Unable to get fare rules from the airline.",
  },
  baggageDiffFound: {
    en: "Baggage Differences Found",
  },
  baggageDiffP1: {
    en:
      "You are about to make a trip booking with flights having different baggage allow" +
      "ances.",
  },
  baggageDiffP2: {
    en:
      "If you are unable to add any extra baggages, please contact the airline directly" +
      ".",
  },
  understandContinue: {
    en: "I Understand, Continue Booking",
  },
  fareValidationFail: {
    en: "Fare Validation Failed",
  },
  fareValidationMsg: {
    en:
      "We are unable to validate the price for your chosen flights. We can reach out to" +
      " you once we are able to validate the same. Shall we?",
  },
  onlyIfSame: {
    en: "Yes, Only If Price Stays Same",
  },
  noCheckLater: {
    en: "No, I Will Check Later",
  },
  submitting: {
    en: "Submitting",
  },
  multiBooking: {
    en: "Multiple Bookings",
  },
  multiBookingP1: {
    en:
      "You are about to make multiple bookings for this trip. We will book them one by " +
      "one.",
  },
  multiBookingP2: {
    en:
      "If we are unable to book any one after the first, you will be shown options to c" +
      "omplete your trip booking - but due to availabilities and pricing, it cannot be " +
      "guaranteed. We have 100% percent success rate previously with these types of boo" +
      "kings",
  },
  multiBookingContinue: {
    en: "I understand the risks, continue multiple bookings.",
  },
  continueBooking: {
    en: "I Understand, Continue Booking.",
  },
  choose: {
    en: "Choose",
  },
  enquireWithAirline: {
    en: "Enquire With Airline",
  },
  added: {
    en: "Added",
  },
  noneAdded: {
    en: "None Added",
  },
  noRefund: {
    en: "No Refund",
  },
  refundUpto: {
    en: "Transfer/Refund Value",
  },
  included: {
    en: "Included",
  },
  fullRefund: {
    en: "Full Refund",
  },
  noBaggage: {
    en: "No Baggage",
  },
  fareRule: {
    en: "Fare Rule",
  },
  combinedFare: {
    en: "Combined Fare - Further Restrictions Applicable",
  },
  includingAll1: {
    en: "Including",
  },
  includingAll2: {
    en: "All Baggages & Passengers",
  },
  paymentInitFailed: {
    en: "We could not initiate payment. Please contact support.",
  },
  paymentInitiated: {
    en: "Payment has been initiated. Please wait.",
  },
  paymentApprovedDomain: {
    en:
      "Payment has been authorized. Please wait while we confirm your domain.",
  },
  paymentComplete: {
    en: "Payment has been completed. Please wait while we confirm your domain.",
  },
  paymentDescriptor: {
    en: "Payment Descriptor - Fly Strait",
  },
  addPayingPassenger: {
    en: "Add Paying Passenger",
  },
  addPassenger: {
    en: "Add Passenger",
  },
  addInfantPassenger: {
    en: "Add Infant passenger",
  },
  addChildPassenger: {
    en: "Add Child Passenger",
  },
  bookingTrip: {
    en: "Payment has been authorized. Please wait while we book the trip.",
  },
  bookingFailed: {
    en:
      "We are unable to confirm your trip booking. We will soon get in touch with you. " +
      "Please do not retry",
  },
  domainPaymentFailed: {
    en:
      "We are unable to confirm your domain booking. We will soon get in touch with you" +
      ". Please do not retry",
  },
  enterValidEmail: {
    en: "Please enter a valid email address.",
  },
  enterValidFirstname: {
    en: "Please enter a valid first name.",
  },
  enterValidLastname: {
    en: "Please enter a valid last name.",
  },
  passwordSixChar: {
    en: "Password must be at least 6 digits.",
  },
  passwordDontMatch: {
    en: "Passwords do not match, please try again.",
  },
  enterCountry: {
    en: "Please enter your country.",
  },
  paymentPending: {
    en: "Please wait while we get payment authorized.",
  },
  paymentAuthIssue: {
    en:
      "We couldn't get payment authorization. We can't proceed to book your trip.",
  },
  tripBooked: {
    en: "Your trip has been booked, please wait.",
  },
  otherOptions: {
    en: "Other Options",
  },
  moreDetails: {
    en: "More Details",
  },
  passengerDetails: {
    en: "Next Step: Passenger Details",
  },
  fillPassengerDetails: {
    en: "Fill In Passenger Details",
  },
  hotelResultsHeader: {
    en: "Accommodation",
  },
  roomQtyType: {
    en: "ROOMS QUANTITY / TYPE",
  },
  selectHotel: {
    en: "SELECT HOTEL",
  },
  cancelAndGoBack: {
    en: "Cancel And Go Back",
  },
  proceedToConfirm: {
    en: "Proceed To Confirm",
  },
  switchCountry: {
    en: "Switch Country Or Language",
  },
  finishPayment: {
    en: "Payment Form",
  },
  totalTax: {
    en: "Total Tax",
  },
  totalPrice: {
    en: "Total Price",
  },
  downloadEticket: {
    en: "Download Eticket PDF",
  },
  viewFareRule: {
    en: "View Fare Rules",
  },
  tryAgain: {
    en: "Try Again",
  },
  dateAndCancelTitle: {
    en: "Change Date And Cancellation",
  },
  addBaggage: {
    en: "Add Baggage",
  },
  generalEnquiries: {
    en: "General Enquiries",
  },
  changeClass: {
    en: "Change Class To",
  },
  sameFlight: {
    en: "Same Flight",
  },
  otherFlights: {
    en: "Other flights (Airline and time may differ)",
  },
  select: {
    en: "Select",
  },
  discard: {
    en: "Discard",
  },
  upgrade: {
    en: "Upgrade",
  },
  noResult: {
    en: "No Results Found",
  },
  dateAndCancel: {
    en: "Cancellation / Date Change",
  },
  fareRuleTitle: {
    en: "Rule Application And Other Conditions",
  },
  extraBaggage: {
    en: "Extra Baggage",
  },
  search: {
    en: "Search",
  },
  title: {
    en: "Title",
  },
  selectedItinerary: {
    en: "Selected Flight Itinerary",
  },
  selectItinerary: {
    en: "Select An Itinerary",
  },
  availableItinerary: {
    en: "Available Flight Itineraries",
  },
  findingItinerary: {
    en: "Finding Best Itinerary",
  },
  selectMeal: {
    en: "Select Meal",
  },
  dateChangeText: {
    en:
      "Date changes and cancellation can be done only by reaching out us via ",
  },
  totalPriceIncludes: {
    en: "Total Price Includes",
  },
  gender: {
    en: "Gender",
  },
  sortBy: {
    en: "Sort by:",
  },
  distance: {
    en: "Distance"
  },
  price: {
    en: "Price"
  },
  dateSelection: {
    en: "Departure Date - Arrival Date:",
  },
  depart: {
    en: "Depart",
  },
  return: {
    en: "Return",
  },
  priceValidationError: {
    en: "Seat & price validity expired. Please try again",
  },
  bookingDisabled: {
    en: "Booking engine is busy, please try later.",
  },
  holidayLabel: {
    en: "Public Holidays",
  },
  baseFare: {
    en: "Base Fare & Other Fee",
  },
  // Do not translate [type] or [limit]. These are place holders
  minorDobInvalid: {
    en:
      "[type] passenger needs to be under [limit] as of the arrival date of the last fl" +
      "ight of this itinerary",
  },
  child: {
    en: "Child",
  },
  infant: {
    en: "Infant",
  },
  infantLimit: {
    en: "23 months",
  },
  childLimit: {
    en: "12 years",
  },
  cardHolderCountry: {
    en: "Card Holder Country",
  },
  passportCountry: {
    en: "Passport Country",
  },
  sameaspasscountry: {
    en: "Same As Passport Country",
  },
  example: {
    en: "Eg",
  },
  paymentError: {
    en: "Flight Booking Error",
  },
  fromAirport: {
    en: "From Airport",
  },
  standardBaggage: {
    en: "Standard Baggage",
  },
  perPassenger: {
    en: "Per Passenger",
  },
  optional: {
    en: "Optional",
  },
  localTime: {
    en: "Local Time",
  },
  selectBaggage: {
    en: "Select Baggage",
  },
  taxUnspecified: {
    en: "Tax (Airline Unspecified)",
  },
  awaitingEticket: {
    en: "Awaiting Eticket",
  },
  awaitingEticketWarning: {
    en:
      "We’re still awaiting eTicket from the Airlines. Please note down the Airline PNR" +
      " for future reference",
  },
  expYear: {
    en: "Exp Year",
  },
  expMonth: {
    en: "Exp Month",
  },
  payNow: {
    en: "Pay",
  },
  pleaseWait: {
    en: "Please Wait",
  },
  setupSite: {
    en: "Setup Site",
  },
  createTIP: {
    en: "Create Traveler Platform",
  },
  tipTestimonials: {
    en: "Here are what our recent joinees say after they begin earning",
  },
  earnWhileTravel: {
    en: "Want to earn more while you travel?",
  },
  earningDetails: {
    en:
      "Id ut ad anim consequat ut nisi consectetur aliquip magna ullamco culpa est dese" +
      "runt laboris.Proident sint ea adipisicing aliqua cupidatat enim nisi.Proident no" +
      "n deserunt veniam velit laborum Id ut ad anim consequat ut nisi consectetur aliq" +
      "uip magna ullamco culpa est deserunt laboris.Proident sint ea adipisicing aliqua" +
      " cupidatat enim nisi.Proident non deserunt veniam velit laborum Id ut ad anim co" +
      "nsequat ut nisi consectetur aliquip magna ullamco culpa est deserunt laboris.Pro" +
      "ident sint ea adipisicing aliqua cupidatat enim nisi.Proident non deserunt venia" +
      "m velit laborum.",
  },
  viewBlog: {
    en: "View Blog",
  },
  tipRegistration: {
    en: "Traveler Platform Registration",
  },
  setupAppearance: {
    en: "Setup Appearance",
  },
  appearance: {
    en: "Appearance",
  },
  logo: {
    en: "Logo",
  },
  primaryColor: {
    en: "Primary Color",
  },
  contrastColor: {
    en: "Secondary Color",
  },
  uploadFile: {
    en: "Upload File",
  },
  uploading: {
    en: "Uploading",
  },
  previous: {
    en: "Previous",
  },
  next: {
    en: "Next",
  },
  setupDomain: {
    en: "Setup Website Domain",
  },
  customDomainName: {
    en: "New Website Domain (Paid)",
  },
  customDomain: {
    en: "Website Domain",
  },
  customDomainText: {
    en: "We will host and manage your new domain.",
  },
  getLogoExternalText: {
    en: "or use free external website to create a logo before uploading"
  },
  flystraitDomain: {
    en: "Fly Strait Sub-domain",
  },
  flystraitDomainText: {
    en: "Free choice of sub-domain, subject to availability.",
  },
  domainName: {
    en: "Website Domain Name",
  },
  priceYearly: {
    en: "Price (Yearly)",
  },
  createdDomain: {
    en: "Created Website Domain Name",
  },
  connectSN: {
    en: "Connect Social Networks",
  },
  addSNaccount: {
    en: "Add your social network accounts",
  },
  addTIPchannels: {
    en:
      "Adding more channels will increase your reach.",
  },
  learnMore: {
    en: "Sign Up",
  },
  connect: {
    en: "Connect",
  },
  facebook: {
    en: "Facebook",
  },
  twitter: {
    en: "Twitter",
  },
  instagram: {
    en: "Instagram",
  },
  accountDetails: {
    en: "Account Details",
  },
  arrangeChannels: {
    en:
      "Arrange channels in desired order. Just move items up or down to change the order.",
  },
  createFirstPost: {
    en: "Create Your First Post",
  },
  chooseLayout: {
    en: "Choose Layout",
  },
  photoAlbum: {
    en: "Photo Album",
  },
  travelBlog: {
    en: "Travel Blog",
  },
  travelBlogText: {
    en: "Rich text editor, blocks, locations",
  },
  travelItinerary: {
    en: "Travel Itinerary",
  },
  travelItineraryText: {
    en: "An itinerary for defined amount of days",
  },
  publishOn: {
    en: "Publish On",
  },
  publish: {
    en: "Publish",
  },
  sharePost: {
    en: "Share Post"
  },

  sharePostText: {
    en: "Share Post On Social Media",
  },
  connectChannels: {
    en: "Connect Channels",
  },
  typeWordsToFollowers: {
    en: "Few Words To Followers",
  },
  shareNow: {
    en: "Share Now",
  },
  sharing: {
    en: "Sharing",
  },
  viewPost: {
    en: "View Post",
  },
  dashboard: {
    en: "Dashboard",
  },
  unpublish: {
    en: "Unpublish",
  },
  addContent: {
    en: "Add Content",
  },
  quickPost: {
    en: "Quick Post",
  },
  quickPostText: {
    en: "Media with some text for the entire post",
  },
  addDestination: {
    en: "Add Destination(s)",
  },
  chooseCoverImage: {
    en: "Choose Cover Image",
  },
  enterCityName: {
    en: "Enter City Name",
  },
  flystraitDomainName: {
    en: "Sub-domain Name",
  },
  flystraitDomainMeta: {
    en:
      "Once registered, website will be available at"
  },
  addBlogTitle: {
    en: "Add title of the blog",
  },
  blogTitle: {
    en: "Blog Title",
  },
  sayHello: {
    en: "Say hello to your users",
  },
  dragDropText: {
    en: "Drag and drop a file you want to upload",
  },
  submitMediaTOS: {
    en:
      "By submitting your media files to Traveler Influencer Platform, you acknowledge " +
      "that you agree to Fly Strait's Terms of Service.",
  },
  daysAmount: {
    en: "Amount Of Travel Days",
  },
  posts: {
    en: "Posts",
  },
  media: {
    en: "Media",
  },
  earning: {
    en: "Earning",
  },
  siteActivity: {
    en: "Site Activity",
  },
  settings: {
    en: "Settings",
  },
  publishedPosts: {
    en: "Published Posts",
  },
  all: {
    en: "All",
  },
  album: {
    en: "Album",
  },
  blog: {
    en: "Blog",
  },
  itinerary: {
    en: "Itinerary",
  },
  datePublished: {
    en: "Date Published",
  },
  createNewPost: {
    en: "Create New Post",
  },
  editPost: {
    en: "Edit Post",
  },
  mediaLibrary: {
    en: "Media Library",
  },
  addNewMedia: {
    en: "Add New Media",
  },
  images: {
    en: "Images",
  },
  video: {
    en: "Video",
  },
  audio: {
    en: "Audio",
  },
  locationAtoZ: {
    en: "Location A-Z",
  },
  queue: {
    en: "Queue",
  },
  recentPosts: {
    en: "Recent Posts",
  },
  recent: {
    en: "Recent",
  },
  channelSetup: {
    en: "Channels setup",
  },
  setup: {
    en: "Setup",
  },
  recurrences: {
    en: "Recurrences",
  },
  recurrence: {
    en: "Recurrence",
  },
  createRecurrence: {
    en: "Create Recurrence",
  },
  firstRecurrence: {
    en: "First Recurrence",
  },
  lastRecurrence: {
    en: "Last Recurrence",
  },
  setRecDateTime: {
    en: "Set Recurrence Date And Time",
  },
  repeatEvery: {
    en: "Repeat Every",
  },
  maxRecurrences: {
    en: "Recurrences (50 max)",
  },
  setRules: {
    en: "Set Rules",
  },
  set: {
    en: "Set",
  },
  noQueue: {
    en: "No automations in the queue. Add some tasks to the queue.",
  },
  getAccountSetup: {
    en: "Let’s get your account set up!",
  },
  connectSocialAcc: {
    en: "Connect Social Accounts",
  },
  monthlyTotal: {
    en: "Monthly Total",
  },
  weeklyTotal: {
    en: "Weekly Total",
  },
  noEarningTitle: {
    en: "You haven’t earned any money yet with this account!",
  },
  noEarningText: {
    en:
      "Once a post has gone live via TIP, you can track its performance here to learn w" +
      "hat works best with your audience!",
  },
  subDomainUsed: {
    en: "Sub-domain Already Taken.",
  },
  invalidSubdomain: {
    en: "Sub-domain Invalid",
  },
  connectedChannels: {
    en: "Connected Channels",
  },
  selectLayoutAndPublish: {
    en: "Select Cover Image Layout And Publish On ",
  },
  goToDashboard: {
    en: "Go To Dashboard",
  },
  addToQueue: {
    en: "Add To Queue",
  },
  shareSelectedPost: {
    en: "Share Selected Post",
  },
  typeWordsForShare: {
    en: "Type few words and switch on destined social network channels",
  },
  selectLayout: {
    en: "Select Cover Image Layout",
  },
  andPublish: {
    en: "And Publish On ",
  },
  signOut: {
    en: "Sign Out",
  },
  update: {
    en: "Update",
  },
  connectSocialNetwork: {
    en: "Connect Social Network Account(s)",
  },
  shareAt: {
    en: "Share At",
  },
  clearQueue: {
    en: "Clear Queue",
  },
  shareAll: {
    en: "Share All Now",
  },
  shareNow: {
    en: "Share Now",
  },
  emptyQueue: {
    en: "Queue Is Empty",
  },
  remove: {
    en: "Remove",
  },
  repeat: {
    en: "Repeat",
  },
  noRecurrence: {
    en: "No Recurrences Found",
  },
  createRecurrenceInfo: {
    en: "You can create a recurrence by editing items in the queue",
  },
  activateCustomDomain: {
    en: "Activate Custom Domain",
  },
  activate: {
    en: "Activate",
  },
  activateMeta: {
    en:
      "Fly Strait initially builds the specified site appearance and creates your first " +
      "post on its own domain (<name>.influencer.flystrait.com). To proceed activation " +
      "of the custom domain that you have chosen on the first step, please, fill in you" +
      "r contact details and click Activate.",
  },
  userDisplayName: {
    en: "User's Display Name",
  },
  registeredEmail: {
    en: "Registered Email",
  },
  phoneNumber: {
    en: "Phone Number",
  },
  domainUnavailable: {
    en: "Domain Not Available",
  },
  payAndActivate: {
    en: "Pay And Activate",
  },
  autoGeneratedSubDomain: {
    en: "Auto-Generated Subdomain",
  },
  edit: {
    en: "Edit",
  },
  setupCustomDomain: {
    en: "Setup Custom Domain",
  },
  confirm: {
    en: "Confirm",
  },
  searchDomain: {
    en: "Type desired domain name and hit enter to start searching:",
  },
  domainActivated: {
    en: "The Custom Domain Was Successfully Activated",
  },
  postShared: {
    en: "Post Shared successfully",
  },
  subDomainActivated: {
    en: "The Fly Strait Subdomain Was Successfully Activated",
  },
  calendar: {
    en: "Calendar",
  },
  addDay: {
    en: "Add Day",
  },
  removeDay: {
    en: "Remove Day",
  },
  day: {
    en: "Day",
  },
  addDayStory: {
    en: "Add a story about the day ",
  },
  addTravel: {
    en: "Add a travel during the day",
  },
  addToItinerary: {
    en: "Add To Itinerary ",
  },
  station: {
    en: "Station",
  },
  bus: {
    en: "bus",
  },
  boat: {
    en: "boat",
  },
  train: {
    en: "train",
  },
  taxi: {
    en: "taxi",
  },
  addNotes: {
    en: "Add notes to this trip",
  },
  coverImageInfo: {
    en:
      "The number of the selected pictures will define the layout of the post cover. Yo" +
      "u will be able to configure the layout on the next step. If you will not select " +
      "an image, the published post will not have a cover image.",
  },
  nextStepLayout: {
    en: "Next Step: Layout",
  },
  selectPictures: {
    en: "Select pictures for the cover image",
  },
  preview: {
    en: "Preview",
  },
  noItemForDay: {
    en: "There are no items for this day.",
  },
  setCoverImage: {
    en: "Set Cover Image:",
  },
  editRecurrence: {
    en: "Edit Recurrence",
  },
  editQueueItem: {
    en: "Edit Queue Item",
  },
  saveChanges: {
    en: "Save Changes",
  },
  shareAtText: {
    en: "Share At: Set Date And Time",
  },
  removeRecur: {
    en: "Remove Recurrence",
  },
  channels: {
    en: "Channels",
  },
  skipActivation: {
    en: "Skip Activation",
  },
  finish: {
    en: "Finish",
  },
  domainSetup: {
    en: "Domain Setup",
  },
  beginJourney: {
    en: "Begin The Journey Of Real Influencing",
  },
  tipLandingText: {
    en:
      "Every place you explore wants more of you and the travelers who follow you. We w" +
      "ill focus on your tools, so you go discover and share the joy.",
  },
  aboutCompany: {
    en: "What This Is",
  },
  aboutCompanyText: {
    en:
      "We put travel at the centre of your mind, taking away all the hassles of managin" +
      "g your new website and social media. We want you to earn sustainably and not be " +
      "bothered with brands you don't really represent. No more obscure hashtags. Simpl" +
      "y share what you do. With passion.",
  },
  BenefitsFlyStrait: {
    en: "What I Get",
  },
  Benefits1: {
    en:
      "You get a free website to showcase your travel content including blogs and itine" +
      "raries. You can also get a custom domain, so you keep it personal and profession" +
      "al.",
  },
  Benefits2: {
    en:
      "You get travel-specific content creation and publishing tools without getting ch" +
      "arged. Not even for the media stored on cloud. You can also plan, schedule and m" +
      "anage all of your social media presence in a single place.",
  },
  Benefits3: {
    en:
      "You get an online flight booking website (white-labelled), so your followers fin" +
      "d your content website deeply integrated. Transactions from your followers will " +
      "be personal to you, along with earnings generated.",
  },
  flyStraitFeatures: {
    en: "How It Works",
  },
  write: {
    en: "Write",
  },
  publish: {
    en: "Publish",
  },
  share: {
    en: "Share",
  },
  earnAndLearn: {
    en: "Earn and Learn",
  },
  writeDesc: {
    en:
      "You can do a quick post, write a blog or compose an itinerary. With an itinerary" +
      " you can highlight travel involved during the trip. This helps your followers wi" +
      "th practical information.",
  },
  publishDesc: {
    en:
      "All the content on your website, shared across social media, will help showcase " +
      "your unique ways of travel. Your post will be the gateway for your followers to " +
      "explore.",
  },
  shareDesc: {
    en:
      "You can share all your post on several social media websites at once. You can sc" +
      "hedule and repeat them as you like and use the calendar view to effectively plan" +
      " your routine.",
  },
  earnAndLearnDesc: {
    en:
      "Your followers will see action button (and not Ads) within your published posts," +
      " that will lead them to book a trip. You will earn most of the profit generated " +
      "by such transaction. You can also view your website activity and learn more abou" +
      "t your audience.",
  },
  newlyFoundDest: {
    en: "Destination In Focus",
  },
  influencerOfWeek: {
    en: "Influencer Of The Week",
  },
  destinationsCovered: {
    en: "Destinations Covered",
  },
  postsCreated: {
    en: "Number Of Posts During Last Week",
  },
  numOfVisitors: {
    en: "Number Of Unique Visitors During Last Week",
  },
  numOfTransactions: {
    en: "Number Of Transactions During Last Week",
  },
  profilePic: {
    en: "Profile Picture",
  },
  deleteTipAccount: {
    en: "Delete Influencer Account",
  },
  nearestAirportCity: {
    en: "Nearest Airport City",
  },
  nearestStartingAirportCity: {
    en: "Nearest Starting Airport City",
  },
  imageSizeError: {
    en: "Image is greater than 10 MB.",
  },
  maxImageSize: {
    en: "Maximum image size allowed is 10 MB.",
  },
  filter: {
    en: "Filter",
    es: "",
    nl: "",
    tr: "",
    uk: "",
    fr: "",
    de: "",
    it: "",
    pl: "",
    pt: "",
    ru: "",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  signupAlreadyRegistered: {
    en: "Already registered. Please log in or click on 'Reset Password'",
    es: "",
    nl: "",
    tr: "",
    uk: "",
    fr: "",
    de: "",
    it: "",
    pl: "",
    pt: "",
    ru: "",
    dk: "",
    se: "",
    no: "",
    gr: "",
    fi: "",
    lv: "",
    sk: "",
  },
  mobileNumber: {
    en: "Mobile Number",
  },
  countryCode: {
    en: "Country Code",
  },
  verifyOtp: {
    en:
      "Please enter the 6-digit verification code we sent to your mobile number ",
  },
  intlCode: {
    en: "Int'l Code",
  },
  anotherPaymentMethod: {
    en: "Another Payment Method",
  },
  useSavedCards: {
    en: "Use Previously Saved Card(s)",
  },
  useSavedCard: {
    en: "Use Previously Saved Card",
  },
  addPaymentMethod: {
    en: "Add Payment Method",
  },
  begin: {
    en: "Begin",
  },
  setupSiteFor: {
    en: "Setup Site",
  },
  chooseTheme: {
    en: "Choose Theme",
  },
  none: {
    en: "None",
  },
  adult: {
    en: "Adult",
  },
  child: {
    en: "Child"
  },
  age: {
    en: "Age"
  },
  childAgeError: {
    en: "Child Age Must Be Between 0 And 17"
  },
  includeHotel: {
    en: "Include Hotel"
  },
  addAccommodation: {
    en: "Add Accommodation"
  },
  addPassengers: {
    en: "Proceed"
  },
  skip: {
    en: "Skip"
  },
  selectedAccommodations: {
    en: "SELECTED ACCOMMODATIONS"
  },
  night: {
    en: "Night"
  },
  nights: {
    en: "Nights"
  },
  at: {
    en: "At",
  },
  dates: {
    en: "Dates"
  },
  altHotelDest: {
    en: "Alternate Hotel Destination"
  },
  selectPassenger: {
    en: "Select Passenger"
  }
};
