import React from "react";
import styled, { withTheme } from "styled-components";
import moment from "moment";
import { ArrowIcon } from "components/common/SvgIcons";
import flightIcon from "assets/icons/flight.svg";

const Wrapper = styled.div`
  cursor: pointer;
  background: ${props =>
    props.expanded
      ? "linear-gradient(0deg, rgba(208, 243, 252, 0.2), rgba(208, 243, 252, 0.2)), #FFFFFF"
      : "#fff"};
  box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.08),
    inset 0px -1px 0px rgba(0, 0, 0, 0.08);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 0 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #444444;
  width: 100%;
  font-size: 18px;
  height: 32px;
  .flight {
    display: flex;
    align-items: center;
    color: #777777;
    span {
      color: #777777;
      font-weight: 600;
      font-family: "Roboto Condensed", sans-serif;
    }
    img {
      margin-right: 15px;
    }
  }
  .time {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    svg {
      margin-left: 5px;
      transform: rotate(90deg);
      transition: transform 0.3s;
      &.expanded {
        transform: rotate(180deg);
      }
    }
  }
`;

const FlightNumAndDate = ({
  flightInfo,
  collapsable,
  expanded,
  withYear,
  withPrice,
  price,
  handleClick,
  style = {},
  disabled,
  theme
}) => {
  const depDate = moment(`${flightInfo.stdDate} ${flightInfo.stdTime}`).format(
    `D MMM${withYear ? " YYYY" : ""}`
  );
  const depTime = flightInfo.stdTime.split(":");
  return (
    <Wrapper style={style} onClick={handleClick} expanded={expanded}>
      <div className="flight">
        <img src={flightIcon} alt="" />
        {collapsable || withPrice ? (
          <span>
            {flightInfo.fromAirportIc} > {flightInfo.toAirportIc}
          </span>
        ) : (
          <span>
            {flightInfo.airlineIc} {flightInfo.flightNum}
          </span>
        )}
      </div>
      <div className="time">
        {withPrice ? (
          <span style={{ color: theme.secondary }}>{price}</span>
        ) : (
          <span>
            {depDate}, {`${depTime[0]}:${depTime[1]}`}
          </span>
        )}
        {collapsable && !disabled && (
          <ArrowIcon
            stroke={theme.secondary}
            className={expanded ? "expanded" : ""}
          />
        )}
      </div>
    </Wrapper>
  );
};

export default withTheme(FlightNumAndDate);
