import React from "react";
import styled from "styled-components";
import ConnectSetup from "components/Influencer/Partials/ConnectSetup";

const Wrapper = styled.div``;

export default class extends React.PureComponent {
  render() {
    return (
      <Wrapper>
        <ConnectSetup dashboard />
      </Wrapper>
    );
  }
}
