import React from "react";
import styled from "styled-components";
import moment from "moment";
import { Button } from "components/common";
import { TIPForwardIcon } from "components/common/SvgIcons";
import { DestinationLabel, DateWrapper } from "./CardStyles";
import location from "assets/icons/Location.svg";
import image from "assets/postImg.jpg";

const Post = styled.div`
  width: ${props => (!props.preview ? "296px" : "456px")};
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  margin-bottom: 30px;
  .imageWrapper {
    position: relative;
    .images {
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      img {
        width: 100%;
        object-fit: cover;
      }
    }
    .destinations {
      position: absolute;
      display: flex;
      bottom: 12px;
      left: 0;
    }
  }

  .details {
    position: relative;
    padding: 15px;
    h4 {
      font-size: 24px;
      line-height: 28px;
      color: #3f8c52;
      text-transform: capitalize;
      font-weight: 400;
      margin: 0;
    }
    p {
      color: #777777;
      font-size: 16px;
      line-height: 150%;
    }
  }
`;

export default class extends React.PureComponent {
  render() {
    const { blogData, preview } = this.props;
    let dateArray = [];
    if (blogData) {
      const date = moment(blogData.created_at).format("DD,MMM,YYYY");
      dateArray = date.split(",");
    }
    const daysLength =
      blogData.itinerarydays.length > 4 ? 4 : blogData.itinerarydays.length;
    return (
      <Post preview={preview}>
        <div className="imageWrapper">
          <div className="images">
            {blogData.itinerarydays.map((day, index) => (
              <img src={image} key={index} alt="" style={{ height: 176 }} />
            ))}
          </div>
          <DateWrapper>
            <span className="date">{dateArray[0]}</span>
            <span className="month">{dateArray[1]}</span>
          </DateWrapper>
          <div className="destinations">
            <DestinationLabel>
              <img src={location} alt="" />
              <span>India</span>
            </DestinationLabel>
            <DestinationLabel>
              <img src={location} alt="" />
              <span>Spain</span>
            </DestinationLabel>
          </div>
          <div
            style={{
              height: 32,
              width: 64,
              position: "absolute",
              bottom: 12,
              right: 12
            }}
          >
            <Button
              style={{
                height: 32
              }}
              color="rgba(255, 255, 255, 0.9)"
            >
              <TIPForwardIcon stroke="#3F8C52" />
            </Button>
          </div>
        </div>
      </Post>
    );
  }
}
