export default `
#
# Сторінка конфіденційності

#
# Вступ

Компанія Fly Strait OÜ, зареєстрована в Таллінні (Естонія) з реєстраційним номером 14373971 (&quot;ми&quot;, &quot;ми&quot;, &quot;наш&quot; або просто &quot;Fly Strait&quot;) є так званим &quot;контролером даних&quot; Ваших особистих даних і тому несе відповідальність за законність того, що ми робимо з Вашими особистими даними.

Цей веб-сайт управляється Fly Strait. Ми обробляємо різноманітніі персональні дані, такі як імена, адреси електронної пошти та іншу інформацію про поїздки в повсякденному бізнесі. Тому ми дуже серйозно ставимось до захисту даних та дотримання законодавства щодо захисту даних. Ця політика конфіденційності пояснює, як ми збираємо, зберігаємо, використовуємо та розкриваємо будь-які особисті дані, які ми збираємо про Вас, коли Ви використовуєте цей веб-сайт, а також як ми захищаємо конфіденційність Вашої особистої інформації. Ваша конфіденційність важлива для нас в любому випадку, чи то Ви - новий користувач нашого сервісу, чи то давній. Будь ласка, виділіть час, щоб ознайомитись з нашими правилами і зв&#39;яжіться з нами, якщо у Вас виникли запитання.

#
# Особисті дані, які ми збираємо

Як правило, характер особистих даних, який ми збираємо, - це інформація, яка нам необхідна, щоб дозволити Вам здійснювати подорожі та бронювання. Це включає таку інформацію, як ваше ім&#39;я та прізвище, дату народження, номер телефону та адресу електронної пошти. Особисті дані, які ми повинні отримати, щоб забезпечити Вам подорож, яку Ви забронювали за допомогою наших веб-сайтів, є єдиними даними, які Вам необхідно надати нам. Залежно від виду туристичних послуг, які Ви використовуєте, ми також можемо отримати ваш номер як пасажира, який часто подорожує, а також інформацію про Ваші дієтичні вимоги та проблеми зі здоров&#39;ям (якщо такі є) та інші деталі, які стосуються Вашої поїздки або які вимагають інші туристичні сервіси-провайдери (наприклад, авіакомпанії та готелі). Це не вичерпний перелік. Якщо Ви телефонуєте в нашу службу підтримки, ми збираємо дані, які Ви надаєте під час телефонного дзвінка. Як Ви можете побачити нижче, наші файли cookie також збирають певну інформацію.

Якщо Ви зробите замовлення для когось іншого через наш веб-сайт, ми будемо запитувати особисті дані про цю особу. В такому випадку, ми покладаємося на те, що Ви повідомите тій особі про нашу політику конфіденційності.

#
# Конфіденційнї особисті дані

В деяких випадках ми можемо обробляти так звані &quot;спеціальні категорії персональних даних&quot; про Вас, які можуть вважатися дуже особистими чи конфіденційними. Це станеться, наприклад, якщо Ви (i) подали медичну довідку про використання захисту від відмови або відшкодування авіакомпанією; (ii) маєте такий стан здоров&#39;я, який впливає на Вашу подорож, і у зв&#39;язку з яким Вам необхідна допомога або де потрібний певний дозвіл, або (iii) подали запит з іншими конфіденційними особистими даними про Вас.

Перш ніж обробляти конфіденційні особисті дані про Вас, ми завжди вимагаємо Вашої згоди на це. Тому ми просимо Вас використовувати спеціальні контактні форми на наших веб-сайтах для надання будь-яких конфіденційних даних. Контактні форми дозволяють надати нам згоду, яка вимагається відповідно до законодавства про захист даних. Звичайно, така згода може бути скасована в будь-який час. Ми не будемо обробляти будь-які конфіденційні особисті дані, які не дозволяється використовувати, або ті, що Ви не надавали нам. Обмежений обсяг наших співробітників матиме доступ до Вашої конфіденційної особистої інформації, і після обробки Вашої конфіденційної інформації відповідно до Вашого запиту ми знищимо дані якомога швидше.

#
# Що ми робимо з Вашими особистими даними

Щоб дозволити обробляти Ваші особисті дані, відповідні закони про захист даних зобов&#39;язують нас мати так звану &quot;правову основу&quot; для кожної з наших цілей для обробки Ваших особистих даних. Тому ми склали наведену нижче таблицю, щоб показати нашу юридичну базу для кожної з наших цілей.

| **Що ми робимо (наші цілі з обробкою ваших особистих даних)** | **Правова основа** | **Час зберігання** |
| --- | --- | --- |
| Включає оплату проїзду та бронювання, які Ви замовили у нас (тобто бронювання туристичних послуг, що опосередковуються нами, а також надання наших власних послуг). | Виконання нашого договору з Вами. Якщо Ви надали нам конфіденційні особисті дані, це є юридичною підставою для згоди. | 3 роки з дати придбання. Згода на отримання конфіденційних особистих даних може бути знята в будь-який час. |
| Якщо Ви вирішили створити обліковий запис користувача на нашому веб-сайті, ми зробимо такий обліковий запис доступним для Вас. Обліковий запис включає доступ до інформації про Вашу попередню поїздку та замовлення. Ми також зберігаємо Ваше ім&#39;я користувача та пароль. | Виконання нашого договору з Вами. | Дані Вашого облікового запису, а також особиста інформація, пов&#39;язана з Вашими попередніми подорожами та замовленнями, зберігатимуться, доки Ви не вирішите скасувати свій обліковий запис за допомогою нашого веб-сайту. Проте, якщо Ви не входили в свій обліковий запис користувача протягом 18 місяців, ми можемо стерти такий обліковий запис і інформацію в ній, за умови, що ми не маємо іншої юридичної основи для зберігання. |
| Якщо Ви почали процес бронювання, але не завершили покупку, ми можемо надіслати Вам електронний лист з посиланням на результат пошуку або на початок бронювання залежно від того, в який момент Ваш процес бронювання на веб-сайті був припинений. | Ми зацікавленні в законномі бізнесі, тому хочемо дозволити Вам завершити покупку без потреби заповнювати всю інформацію ще раз. Якщо Ви не бажаєте отримувати ці електронні листи, Ви можете відмовитися від цього в будь-який час за допомогою електронного листа. | 24 години з моменту, коли Ви залишили процес бронювання. |
| Перш ніж Ви почнете свою подорож, ми надамо Вам додаткову інформацію та пропозиції, що стосуються Вашої конкретної подорожі, наприклад, додаткові можливості, такі як додатковий багаж, і іншу інформацію, яка необхідна для Вашої подорожі. Деяка інформація ґрунтується на профілізації, проведеній на основі інформації, яку Ви надали під час процесу бронювання (наприклад, дня (-ів) Вашої подорожі, Ваш пункт призначення тощо). | Ми зацікавлені в тому, щоб запропонувати Вам більш зручний проїзд і допомогти Вам легко знайти додаткову інформацію, яка стосується Вас. Якщо Ви не бажаєте отримувати цю інформацію, Ви можете відмовитися від неї будь-коли в електронному листі. | Не пізніше, ніж до початку Вашої подорожі. Інша подібна обробка може продовжуватися відповідно до цілей, наведених нижче. |
| Після здійснення поїздки ми можемо забезпечити Вам інформаційну розсилку, що містить рекомендації щодо інших подорожей, продуктів та послуг, пов&#39;язаних з подорожами, які можуть Вас зацікавити. Деякі рекомендації базуються на Ваших попередніх виборах під час бронювання поїздки та на Вашій поведінці стосовно наших листів. | Наша зацікавленість в веденні законного бізнесу гарантує Вам забезпечення кращими турами для Вас. Якщо Ви не бажаєте отримувати ці електронні листи, Ви можете відмовитися від них будь-коли в електронному листі або на нашому веб-сайті. Крім того, перш ніж завершувати покупку, Ви можете відмовитися від отримання інформаційної розсилки. | 3 роки з дати придбання. |
| Телефонні дзвінки записуються з метою забезпечення якості та будь-яких майбутніх звернень або запитів від Вас. | Ми зацікавлені в тому щоб (i) покращити наш сервіс через внутрішню освіту та, де це можливо, (ii) вирішити Ваші потенційні запити чи претензії. Якщо Ви не бажаєте, щоб телефонний дзвінок був записаний, Ви можете відмовитись від такого запису до початку розмови. | 6 місяців з дати телефонного дзвінка. Доступ до Вашого запису доступний лише обмеженій кількості людей. |
| Використовуються файли cookie для покращення зручності користування цим веб-сайтом, для отримання індивідуального досвіду та збирання статистики використання. Ми також використовуємо сеансові файли cookie для підвищення безпеки цього веб-сайту | Ми зацікавлені в тому, щоб: (i) покращити наш веб-сайт, (ii) показати Вам найбільш цікаві для вас пропозиції, (iii) мати безпечне обслуговування та веб-сайт. Якщо Ви не хочете, щоб ми зберігали файли cookie на Вашому комп&#39;ютері, Ви можете будь-коли змінити налаштування свого веб-браузера. | Залежно від типу файлу cookie. [Нижче наведено докладніші терміни зберігання.](https://flystrait.com/privacy#Cookies) |

На додаток до вищевикладеного ми здійснюємо такі щоденні заходи, які необхідні для підприємств, що надають послуги споживачам, такі як бухгалтерський облік, білінг, виконання зобов&#39;язань щодо боротьби з відмиванням грошей та підтримання безпеки нашого веб-сайту. Якщо це не є обов&#39;язковим відповідно до чинного законодавства, ми приймаємо ці заходи на основі наших законних інтересів. Ми також можемо проаналізувати поведінку наших клієнтів, щоб вдосконалити наші веб-сайти та послуги на загальному рівні. Однак такий аналіз використовуватиме узагальнені або анонімні дані на агрегованому рівні.

#
# Обмін Вашими персональними даними

Ми будемо надавати доступ до Ваших особистих даних лише в тих випадках, коли це необхідно для цілей, зазначених у цій політиці конфіденційності. Це можуть бути інші компанії у межах Fly Strait, державні органи влади та наші довірені ділові партнери. Наприклад, ми можемо ділитися Вашими особистими даними (включаючи конфіденційні особисті дані, коли це необхідно) з діловими партнерами, такими як авіакомпанії, постачальники готелів, страхові компанії та глобальні системи дистрибуції (так звані GDS), щоб забезпечити Ваші поїздки та замовлення.

Кожен партнер несе відповідальність за власну обробку Ваших особистих даних після отримання від нас, тобто Ви повинні зв&#39;язатися з відповідним партнером для будь-яких запитів, пов&#39;язаних із Вашими правами відповідно до чинного законодавства про захист даних. Ми рекомендуємо ознайомитись із відповідними політиками конфіденційності партнерів, щоб дізнатися, як саме Ваші особисті дані будуть ними використані. Ми також надаємо Вашу персональну інформацію іншим компаніям (так званим &quot;обробникам даних&quot;), необхідним для виконання ваших запитів, як-от постачальники послуг, які працюють у наших кол-центрах, а також постачальників, які оброблятимуть Ваші особисті дані при наданні своїх послуг нам (наприклад, зовнішній накопичувач).

Через глобальний характер туристичної індустрії, Ваші особисті дані можуть оброблятися в різних місцях у всьому світі, коли сторони, з якими ми розділяємо ваші особисті дані, знаходяться в країні за межами ЄС / ЄЕЗ. Наш обмін персональними даними поза межами ЄС / ЄЕЗ вимагає певного юридичного підґрунтя відповідно до чинного законодавства про захист даних. У тих випадках, коли Європейська комісія вважає, що країна є країною з адекватним рівнем захисту персональних даних, це і буде нашою правовою основою. В іншому випадку існують три основних типи правової основи, на яких ми можемо базувати такий поділ. Це наступні фактори:

і. передача потрібна для виконання нашого контракту з Вами (наприклад, якщо Ви забронювали рейс з авіакомпанією, що знаходиться за межами ЄС / ЄЕЗ);

іі.передача буде ґрунтуватися на стандартних положеннях щодо захисту даних для передачі персональних даних країнам, що не входять до ЄС / ЄЕЗ, затвердженої Європейською Комісією (копію цих стандартних стандартів захисту даних можна знайти на http: //ec.europa .eu / правосуддя / захист даних / міжнародні перекази / передача /);

ііі. EU-US Privacy Shield, де передача проводиться в Сполучені Штати, і одержувач має належну сертифікацію.

#
# Сторонні постачальники

Зверніть увагу, що наш сайт містить посилання на інші веб-сайти і надає контент стороннім провайдерам. Ця політика конфіденційності стосується лише нашого веб-сайту та наших послуг. Коли Ви звертаєте увагу на посилання на інші веб-сайти або користуєтесь сторонніми службами та продуктами, Ви повинні ознайомитися з їх політикою конфіденційності. Крім того, якщо Ви вирішите зв&#39;язатися з нами через соціальні мережі, ця політика конфіденційності не застосовується до будь-яких особистих даних, наданих Вами як частину такого спілкування, - в цьому випадку ми рекомендуємо ознайомитись із політикою конфіденційності постачальника соціальних мереж.

#
# Ваші права

Відповідно до чинного законодавства про захист даних, у Вас є певні права як у так званого &quot;суб&#39;єкта даних&quot;. Нижче ми перерахували Ваші права. Ваші права включають в себе наступне:

і. Право на доступ - Ви маєте право на доступ до персональних даних, які ми обробляємо. Ви також маєте право отримувати певну інформацію про те, що ми робимо з персональними даними. Така інформація наведена в цьому документі.

іі Право на виправлення - За певних обставин ви маєте право виправляти неточні особисті дані щодо Вас та заповнити неповні особисті дані. Зауважте, що ми, можливо, не зможемо виправити неточні персональні дані, надані Вами, наприклад, через правила авіакомпаній, і через те, що такі зміни можуть спричинити витрати.

ііі. Право на стирання - за певних обставин Ви маєте право стерти Ваші особисті дані. Це є так зване &quot;право бути забутим&quot;.

iv. Право на обмеження обробки - За певних обставин Ви маєте право обмежити використання наших особистих даних.

v.Право на передавання даних. Ви маєте право отримувати ваші персональні дані (або передавати Ваші особисті дані безпосередньо на інший контролер даних) в структурованому форматі від нас.

vi.Право на об&#39;єкт. Ви маєте право заперечувати проти певних видів обробки персональних даних, які ми виконуємо. Це стосується всіх наших дій, які засновані на наших «законних інтересах». Нарешті, Ви також маєте право подати скаргу у відповідний орган з нагляду за захистом даних.

#
# Cookies

Файл cookie - це невеликий текстовий файл, який зберігається на Вашому комп&#39;ютері, деякі - лише до того, як Ви закриєте веб-браузер (так звані &quot;куки сеансу&quot;), а деякі - протягом тривалого часу (так звані &quot;постійні файли cookie&quot;). Якщо Ви не хочете дозволяти зберігання файлів cookie на своєму комп&#39;ютері, Ви можете змінити налаштування у своєму веб-браузері. Зверніть увагу, однак, що в деяких випадках декотрі з наших функцій веб-сайту можуть не працювати належним чином, а деякі матеріали можуть відображатися некоректно в результаті.

Цей веб-сайт використовує файли cookie з цілого ряду причин, зокрема для забезпечення індивідуального досвіду, для підвищення зручності використання цього веб-сайту та збирання статистики використання. Ми також використовуємо сеансові файли cookie для підвищення безпеки цього веб-сайту.

У деяких випадках при використанні файлів cookie ми передаємо дані третім особам. Наприклад, ми використовуємо Google Analytics і Google AdWords, послуги, які передають дані трафіку веб-сайту на сервери Google. Google Analytics не ідентифікує окремих користувачів та не пов&#39;язує Вашу IP-адресу з будь-якими іншими даними, якими володіє Google. Ми використовуємо звіти, надані компанією Google, щоб ми могли встановити трафік веб-сайту, використання веб-сторінок та оптимізувати рекламу, придбану власними компаніями Google та іншою рекламною мережею. Google може обробити данні таким чином, як описано в [Політика конфіденційності Google](https://policies.google.com/privacy) і для цілей, викладених вище в цьому розділі. Ви можете відмовитися від Google Analytics, якщо Ви відключите або відмовитесь від файлу cookie, відключите JavaScript або скористаєтесь службою відмови, яку надає Google. [Щоб відмовитися від рекламних функцій у Google, скористайтеся цим посиланням.](https://tools.google.com/dlpage/gaoptout)

Наш веб-сайт також використовує піксель Facebook, який збирає анонімні сукупні дані, що допомагають нам оптимізувати рекламу на різних платформах Facebooks (включаючи Instagram). Facebook збирає ідентифікатор користувача, який дозволить їм відповідати, якщо користувач відвідав сайт за допомогою пікселя Facebook. Ми, як рекламодавці, ніколи не можемо визначити поведінку певного користувача. Facebook та пов&#39;язані з ним платформи знаходяться в закритій рекламній екосистемі, де їх користувачі можуть регулювати, чи вони погоджуються на рекламу, використовуючи дані, зібрані з їхніх веб-сайтів, щоб купувати рекламу на Facebook. [Щоб переглянути та змінити налаштування реклами на Facebook, скористайтеся цим посиланням.](https://facebook.com/ads/preferences)

Крім того, наш веб-сайт використовує сценарії відстеження переходів та куки-файли [Microsoft Bing](https://privacy.microsoft.com/), [TripAdvisor](https://www.smartertravel.com/privacy-policy) і [TvSquared](https://tvsquared.com/privacy-policy/) (ви можете переглянути їх політику конфіденційності, дотримуючись посилань).

Всі ці служби збирають зведені статистичні дані, які допомагають нам оптимізувати покупки реклами. Ми, як рекламодавці, не можемо ідентифікувати одного користувача, який використовує ці дані. Ви можете вимкнути використання файлів cookie у Ваших налаштуваннях браузера.

[Для отримання додаткової інформації про cookies можна відвідати www.youronlinechoices.com.](https://www.youronlinechoices.com/)

#
# Захист даних

Щоб зберегти Ваші особисті дані, ми здійснили низку технічних та організаційних заходів безпеки. Наприклад, ми підтримуємо високий рівень технічної безпеки у всіх системах (включаючи відстеження, аварійне відновлення, обмеження доступу тощо). Крім того, ми прийняли політику, яка гарантує, що наші співробітники (які, звичайно, не підлягають конфіденційності) не використовують особисті дані, коли це не потрібно. Така політика також встановлює наші стандарти, коли ми укладаємо контракти з постачальниками або впроваджуємо нові ІТ-системи в рамках нашої діяльності.

#
# Як з нами зв&#39;язатися:

Якщо у Вас виникли питання щодо нашого поводження з Вашими особистими даними або використанням файлів cookie або якщо Ви хочете використати будь-які з Ваших прав відповідно до чинного законодавства про захист даних, будь ласка, надішліть листа на адресу: privacy@flystrait.com

#
# Зміни в політиці конфіденційності

Якщо ми змінюємо спосіб обробки ваших особистих даних або використання файлів cookie, ми негайно оновимо цю політику конфіденційності та опублікуємо її на цьому веб-сайті.
`;
