import React from "react";
import styled from "styled-components";
import Radio from "@material-ui/core/Radio";
import { Button } from "components/common";
import masterCard from "assets/cards/Mastercard-logo.svg";
import visa from "assets/cards/Visa_Inc._logo.svg";
import deleteIcon from "assets/icons/Delete.svg";

const Wrapper = styled.div`
  .card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
    .cardSelection {
      display: flex;
      align-items: center;
      .details {
        display: flex;
        align-items: center;
        margin-left: 24px;
        .meta {
          margin-left: 24px;
          display: flex;
          flex-flow: column;
          span {
            color: #777777;
            font-size: 13px;
            line-height: 140%;
            &.cardDetails {
              text-transform: capitalize;
              color: #444444;
              font-size: 14px;
              &.active {
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }
`;

export default ({ selectedCard, savedCards, handleDelete, handleChange }) => (
  <Wrapper>
    {savedCards.map(card => (
      <div className="card" key={card.card_id}>
        <div className="cardSelection">
          <Radio
            checked={selectedCard.card_id === card.card_id}
            onChange={handleChange}
            value={card.card_id}
            name="card_id"
          />
          <div className="details">
            <img
              src={card.card_scheme === "visa" ? visa : masterCard}
              width="40"
              alt=""
            />
            <div className="meta">
              <span
                className={`cardDetails ${
                  selectedCard.card_id === card.card_id ? "active" : ""
                }`}
              >
                {card.card_scheme} **** {card.card_last4}
              </span>
              <span>
                Expiry {card.card_expiry_mm_open}/{card.card_expiry_yyyy_open}
              </span>
            </div>
          </div>
        </div>
        <div style={{ width: 48 }}>
          <Button
            roundButton
            color="#F0F0F0"
            style={{ height: 48, minWidth: 48 }}
            onClick={() => handleDelete(card.card_id)}
          >
            <img src={deleteIcon} alt="" />
          </Button>
        </div>
      </div>
    ))}
  </Wrapper>
);
