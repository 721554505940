import React from 'react';
import { getAirports } from 'utils/api';
import { getItem, setItem } from 'utils/utils';

export default class extends React.Component {
  async componentDidMount() {
    if (!getItem('allAirports') || !getItem('fetchedLatestAirports')) {
      const airports = await getAirports();
      setItem('allAirports', airports);
      setItem('fetchedLatestAirports', true);
      this.props.handleComplete();
    } else {
      this.props.handleComplete();
    }
  }
  render() {
    return null;
  }
}
