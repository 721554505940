import React from 'react';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import {
  addDecimal,
  getItem,
  numberToLocaleString,
  underscoreToSpace,
} from 'utils/utils';
import HopSegButton from './HopSegButton';

const cancellationPenaltiesByHoprequest = gql`
  query cancellationPenaltiesByHoprequest($hoprequestId: Int!) {
    cancellationPenaltiesByHoprequest(hoprequestId: $hoprequestId)
  }
`;

export class RefundButton extends React.PureComponent {
  state = {
    airportData: [],
    selectedIndex: 0,
  };

  componentWillReceiveProps(newProps) {
    if (
      newProps.refundPolicy &&
      !newProps.refundPolicy.loading &&
      !this.state.airportData.length &&
      this.state.selectedIndex !== -1
    ) {
      const airportData =
        newProps.refundPolicy.cancellationPenaltiesByHoprequest;
      this.props.updateFirstChoice('cancellationPenalty', airportData[0]);
      const selectedIndex = airportData.findIndex(
        item => item === newProps.cancellationPenalty,
      );
      this.setState({
        airportData,
        selectedIndex,
      });
    }

    if (newProps.cancellationPenalty !== this.props.cancellationPenalty) {
      const selectedIndex = this.state.airportData.findIndex(
        item => item === newProps.cancellationPenalty,
      );
      this.setState({
        selectedIndex,
      });
    }
  }

  handleClick = () => {
    if (this.state.airportData.length) {
      const upcomingIndex = this.state.selectedIndex + 1;
      this.setState(
        {
          selectedIndex:
            this.state.airportData.length > upcomingIndex ? upcomingIndex : 0,
        },
        () =>
          this.props.onClick &&
          this.props.onClick({
            type: 'cancellationPenalty',
            value: this.state.airportData[this.state.selectedIndex],
          }),
      );
    }
  };

  render() {
    const { airportData, selectedIndex } = this.state;
    const {
      cancellationPenalty,
      userTrips,
      refundValue,
      costDecimalPlaces,
    } = this.props;
    const penalty = underscoreToSpace(cancellationPenalty);
    return (
      <HopSegButton
        onClick={this.handleClick}
        disabled={this.props.disabled || airportData.length === 1}
        label="Cancellation"
        icon={
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <b className="__release-text">
              {userTrips
                ? penalty
                : !selectedIndex && !airportData[selectedIndex]
                ? `${this.props.loadingText}...`
                : underscoreToSpace(airportData[selectedIndex])}
            </b>
            <div className="pipe" />
            <p style={{ textAlign: 'center' }}>
              {cancellationPenalty === 'STRICT'
                ? 'No Refund'
                : 'Refund Value upto'}
            </p>
            {cancellationPenalty !== 'STRICT' && (
              <p>
                {numberToLocaleString(
                  addDecimal(refundValue, costDecimalPlaces),
                  {
                    selectedCountry: getItem('selectedLanguage'),
                    selectedCurrency: getItem('selectedCurrency'),
                  },
                )}
              </p>
            )}
          </div>
        }
      />
    );
  }
}

export default compose(
  graphql(cancellationPenaltiesByHoprequest, {
    name: 'refundPolicy',
    options: ownProps => ({
      variables: {
        hoprequestId: ownProps.hopRequestId,
      },
    }),
  }),
)(RefundButton);
