export const SET_INFLUENCER = "SET_INFLUENCER";
export const UPDATE_INFLUENCER = "UPDATE_INFLUENCER";
export const SET_CONNECTPOSTS = "SET_CONNECTPOSTS";
export const SET_ALL_CONNECTPOSTS = "SET_ALL_CONNECTPOSTS";
export const UPDATE_CONNECTPOST_REFETCH = "UPDATE_CONNECTPOST_REFETCH";

export function setInfluencer(data) {
  return {
    type: SET_INFLUENCER,
    payload: data
  };
}

export function updateInfluencer(data) {
  return {
    type: UPDATE_INFLUENCER,
    payload: data
  };
}

export function setConnectPosts(data) {
  return {
    type: SET_CONNECTPOSTS,
    payload: data
  };
}

export function setAllConnectPosts(data) {
  return {
    type: SET_ALL_CONNECTPOSTS,
    payload: data
  };
}
export function updateConnectPostRefetch(data) {
  return {
    type: UPDATE_CONNECTPOST_REFETCH,
    payload: data
  };
}
