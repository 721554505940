import React from "react";
import styled, { withTheme } from "styled-components";
import { withRouter } from "react-router-dom";
import { graphql, compose } from "react-apollo";
import moment from "moment";
import Checkbox from "@material-ui/core/Checkbox";
import { connect } from "react-redux";
import CreditCardInput from "react-credit-card-input";
import MenuItem from "@material-ui/core/MenuItem";
import { addPassengerToAllHopRequests } from "redux-store/actions";
import { Toast, Button, Select, Autocomplete, Loader } from "components/common";
import PaymentIssueModal from "components/common/PaymentIssueModal";
import countries from "utils/countries";
import { getRedirectUrl, getUserCards, removeCard } from "utils/api";
import { updPassengerQuery } from "./PaymentQuery";
import SavedCards from "./SavedCards";
import { getItem, setItem } from "utils/utils";
import getTranslation from "utils/getTranslation";
import countryList from "utils/countryList";
import config from "config";
import paymentLogo from "assets/emerchantpayLogo.svg";
import logo from "assets/icons/FlyStrait.svg";

const Wrapper = styled.div``;

const Meta = styled.div`
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #777777;
    margin-top: 10px;
    font-size: 12px;
    p {
      margin: 0;
    }
  }
`;

const InputWrapper = styled.div`
  padding: 0 20px;
  .cvv {
    display: flex;
    justify-content: space-between;
  }
  .creditCardInput {
    margin: 20px 0;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.08),
      inset 0px -1px 0px ${(props) => props.theme.secondary} !important;
    background-color: rgba(0, 0, 0, 0.09);
    border-radius: 0;
    padding: 27px 12px 10px;
    .floating {
      color: rgba(0, 0, 0, 0.54);
      position: absolute;
      left: 0;
      top: -29px;
      transform: translate(0, 20px) scale(1);
      transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
        transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      &.focus {
        /* top: -27px; */
        transform: translate(-5px, 0px) scale(0.75);
        /* font-size: 12px; */
      }
      &.cardNum {
        transform: translate(-10px, 0px) scale(0.75);
      }
    }
    input {
      background-color: transparent;
    }
    .mask {
      width: 28px;
      height: 20px;
      position: absolute;
      background-color: #fff;
      left: -34px;
      z-index: -1;
    }
    img {
      position: relative;
      z-index: 1;
    }
  }
  .acceptTerms {
    display: flex;
    font-size: 12px;
    align-items: center;
    color: #222222;
    a {
      color: ${(props) => props.theme.secondary};
    }
    input {
      background-color: transparent;
    }
    .mask {
      width: 28px;
      height: 20px;
      position: absolute;
      background-color: #fff;
      left: -34px;
      z-index: -1;
    }
    img {
      position: relative;
      z-index: 1;
    }
  }
  .acceptTerms {
    display: flex;
    font-size: 12px;
    align-items: center;
    color: #222222;
    a {
      color: ${(props) => props.theme.secondary};
    }
  }
`;

let defaultCountry = "";
const selectedCountry = getItem("selectedCountry") || "gb";
if (selectedCountry.toLowerCase() !== "xx") {
  defaultCountry =
    countryList.find((item) => item.Code === selectedCountry.toUpperCase())
      .en || "";
}

class PaymentGateway extends React.PureComponent {
  constructor(props) {
    super(props);
    let passengers = [];
    props.hopRequests[0].passengers.forEach((pass) => {
      const dob = moment(pass.birthdate);
      const currentDate = moment();
      const diff = currentDate.diff(dob, "years");
      if (diff > 12) {
        passengers = [...passengers, pass];
      }
    });
    const passenger = passengers[0];
    passenger.cardHoldercountry = defaultCountry;
    this.state = {
      showIframe: false,
      startPolling: false,
      cardNumber: "",
      cardCvv: "",
      cardExpMm: "",
      cardExpYyyy: "",
      formData: passenger,
      passengers,
      countriesList: [],
      searchTextCountry: defaultCountry,
      isFormCompleted: false,
      cardInputTime: 0,
      errorTime: 0,
      selectedCard: null,
      savedCards: [],
      gettingCards: !!getItem("tokenDetails"),
      currentView: "newCard",
    };
  }

  async componentWillMount() {
    if (getItem("tokenDetails")) {
      try {
        const cards = await getUserCards(getItem("selectedCountry") || "XX");
        console.log(cards);
        if (cards.length > 0) {
          this.setState({
            savedCards: cards,
            selectedCard: cards[0],
            hasSavedCards: true,
            gettingCards: false,
            currentView: "savedCard",
          });
        } else {
          this.setState({ gettingCards: false });
        }
      } catch (err) {
        console.log(err);
        this.setState({ gettingCards: false });
      }
    }
  }

  componentDidUpdate() {
    const {
      cardCvv,
      cardNumber,
      cardExpMm,
      cardExpYyyy,
      cardInputTime,
      errorTime,
      formData,
    } = this.state;
    const isFormCompleted =
      cardCvv.length > 2 &&
      cardNumber.replace(/\s/g, "").length >= 16 &&
      cardExpMm.length === 2 &&
      cardExpYyyy.length === 4 &&
      formData.cardHoldercountry &&
      cardInputTime > errorTime;
    if (isFormCompleted !== this.state.isFormCompleted) {
      this.setState({ isFormCompleted });
    }
  }

  handleUpdatePassenger = async () => {
    this.setState({ loading: true });
    const { formData, currentView } = this.state;
    if (currentView === "savedCard") {
      this.handleGetUrl(true);
      return;
    }
    const { hopRequests } = this.props;
    const passengerIndex = hopRequests[0].passengers.findIndex(
      (item) => item.id === formData.id
    );
    let passengerIds = [];
    hopRequests.forEach((hopReq) => {
      const passengerId = hopReq.passengers[passengerIndex].id;
      passengerIds = [...passengerIds, passengerId];
    });
    const passengerPayload = {
      ...formData,
    };
    passengerIds.forEach((passengerId, index) => {
      this.props
        .updPassengerQuery({
          variables: {
            ...passengerPayload,
            passengerId,
            tripId: getItem("tripId"),
          },
        })
        .then((data) => {
          if (passengerIds.length === index + 1) {
            this.handleGetUrl();
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            isLoading: false,
            loading: false,
            error: true,
            errorMsg: getTranslation("addPassengerError"),
          });
        });
    });
  };

  handleGetUrl = async () => {
    this.setState({ startIframeLoading: true, loading: true });
    try {
      const {
        cardNumber,
        cardCvv,
        cardExpMm,
        cardExpYyyy,
        formData,
      } = this.state;
      var cse = window.Encrypto.createEncryption(config.encryptoPublicKey);
      let data = {
        cvv: cardCvv,
        card_number: cardNumber.replace(/\s/g, ""),
        expiration_month: cardExpMm,
        expiration_year: cardExpYyyy,
        card_holder: formData.cardHoldername,
      };
      var encryptedData = cse.encrypt(data);
      const domain = window.location.origin;
      const payload = {
        tripId: getItem("tripId"),
        cardNumber: encryptedData.card_number,
        cardCvv: encryptedData.cvv,
        cardExpMm: encryptedData.expiration_month,
        cardExpYyyy: encryptedData.expiration_year,
        cardHolder: encryptedData.card_holder,
        domain,
        flightsOnly: !this.props.includeHotel,
      };

      const setup = await getRedirectUrl(payload);
      setItem("tripowner_email", setup.tripowner_email);
      setItem("gateway_redirect_url", setup.redirect_url);
      // console.log(setup);
      window.location = setup.redirect_url;
    } catch (err) {
      console.log(err);
      this.setState({
        paymentError: true,
        paymentSetupFailed: true,
        errorMessage: getTranslation("paymentInitFailed"),
        startIframeLoading: false,
        loading: false,
      });
    }
  };

  handleWarningAccept = () => {
    this.props.history.push("/new-trip");
  };

  handleHidePaymentError = (msg) => {
    this.setState({
      paymentError: false,
    });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    if ((name === "cardCvv" && value.length > 3) || isNaN(value)) {
      return;
    }
    this.setState({
      [name]: value,
    });
  };
  handlePassengerChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      formData: {
        ...this.state.formData,
        [name]: value,
      },
    });
  };
  handleCardHolderCountryInputChange = (txt) => {
    this.setState(
      {
        searchTextCountry: txt,
        formData: { ...this.state.formData, cardHoldercountry: null },
      },
      () => {
        this.handleCountryFilter(txt.toLowerCase());
      }
    );
  };
  handleCountryFilter = (txt) => {
    const countriesList = countries.filter((country) =>
      country.toLowerCase().startsWith(txt)
    );
    const list = countriesList.slice(0, 5);
    this.setState({
      countriesList: list,
    });
  };
  onCardHolderCountrySelect = (index, dataSource) => {
    if (index === -1) return;
    const country = dataSource[index];
    if (country) {
      const countryIc = countryList.find((item) => item.en === country);
      console.log("countryIc", countryIc);
      this.setState({
        formData: {
          ...this.state.formData,
          cardHoldercountry: country,
          cardHoldercountryic: countryIc ? countryIc.Code : "",
        },
        countriesList: [],
      });
    }
  };
  handleCardHolderCountryAutocompleteClose = () => {
    this.setState({ countriesList: [] });
  };
  handleCardHolderCountryBlurCheck = (text, dataSource) => {
    const countryIndex = dataSource.findIndex(
      (item) => item.toLowerCase() === text.toLowerCase()
    );
    if (countryIndex > -1) {
      this.onCardHolderCountrySelect(text, countryIndex, dataSource);
      this.setState({ searchTextCountry: dataSource[countryIndex] });
    }
  };

  handleCardNumberChange = (e) => {
    this.setState({
      cardNumber: e.target.value,
      cardInputTime: moment().valueOf(),
    });
  };
  handleCardExpiryChange = (e) => {
    const monthYear = e.target.value;
    const monthYearArray = monthYear.split(" / ");
    this.setState(
      {
        cardExpMm: monthYearArray[0] ? monthYearArray[0] : "",
        cardExpYyyy: monthYearArray[1]
          ? moment().format("YYYY").slice(0, 2) + monthYearArray[1]
          : "",
        cardInputTime: moment().unix(),
      },
      () => {
        console.log(this.state.cardExpYyyy);
      }
    );
  };
  handleCardCVCChange = (e) => {
    this.setState({
      cardCvv: e.target.value,
      cardInputTime: moment().valueOf(),
    });
  };
  handleCardHolderChange = (e) => {
    this.setState({
      formData: { ...this.state.formData },
      cardHoldername: e.target.name,
    });
  };
  handleAcceptChange = (e, checked) => {
    this.setState({ termsAccepted: checked });
  };
  handleError = (e) => {
    this.setState({
      errorTime: moment().valueOf(),
    });
  };

  handleViewChange = () => {
    this.setState({
      currentView:
        this.state.currentView === "newCard" ? "savedCard" : "newCard",
    });
  };

  handleCardChange = (e) => {
    const cardId = parseInt(e.target.value);
    const selectedCard = this.state.savedCards.find(
      (item) => item.card_id === cardId
    );
    this.setState({
      selectedCard,
    });
  };

  handleDeleteCard = async (cardId) => {
    try {
      await removeCard(cardId);
      const filteredCards = this.state.savedCards.filter(
        (card) => card.card_id !== cardId
      );
      this.setState({
        savedCards: filteredCards,
        selectedCard: filteredCards[0],
        currentView: filteredCards.length === 0 ? "newCard" : "savedCard",
      });
    } catch (err) { }
  };
  render() {
    const {
      loading,
      formData,
      openWarning,
      warningMessage,
      cardNumber,
      countriesList,
      searchTextCountry,
      cardCvv,
      cardExpMm,
      expiry,
      cvc,
      passengers,
      termsAccepted,
      isFormCompleted,
      selectedCard,
      hasSavedCards,
      savedCards,
      currentView,
      gettingCards,
    } = this.state;
    const { style = {}, localizedTotalCost } = this.props;
    console.log("gettingCards", gettingCards);
    return (
      <Wrapper style={style}>
        <PaymentIssueModal
          open={openWarning}
          handleAccept={this.handleWarningAccept}
          errorMsg={warningMessage}
        />
        <div style={{ marginTop: 20 }}>
          {gettingCards && <Loader />}
          <InputWrapper>
            {!gettingCards && (
              <>
                <div
                  style={{
                    fontFamily: "Roboto Condensed, sans-serif",
                    color: "#777777",
                    fontSize: 16,
                    textTransform: "uppercase",
                    borderBottom: "1px solid #DDDDDD",
                  }}
                >
                  {getTranslation(
                    currentView === "newCard"
                      ? "addPaymentMethod"
                      : "useSavedCard"
                  )}
                </div>
                <div style={{ margin: "20px 0" }}>
                  <img
                    src={require("assets/cards/Mastercard-logo.svg")}
                    height="30"
                    alt=""
                    style={{ marginRight: 24 }}
                  />
                  <img
                    src={require("assets/cards/Maestro-new.svg")}
                    height="30"
                    alt=""
                    style={{ marginRight: 24 }}
                  />
                  <img
                    src={require("assets/cards/Visa_Inc._logo.svg")}
                    width="50"
                    height="30"
                    alt=""
                  />
                </div>
              </>
            )}
            {currentView === "savedCard" && hasSavedCards && (
              <SavedCards
                savedCards={savedCards}
                selectedCard={selectedCard}
                handleChange={this.handleCardChange}
                handleDelete={this.handleDeleteCard}
              />
            )}
            {!gettingCards && currentView === "newCard" && (
              <>
                <Select
                  label={getTranslation("cardHolderName")}
                  value={formData.cardHoldername}
                  handleChange={this.handleCardHolderChange}
                  menuItems={passengers.map((item) => (
                    <MenuItem
                      key={item.id}
                      value={item.firstName + " " + item.lastName}
                    >
                      {item.firstName} {item.lastName}
                    </MenuItem>
                  ))}
                  style={{ width: "100%" }}
                />
                <Autocomplete
                  id="cardHoldercountry"
                  label={getTranslation("billingCountry")}
                  value={formData.cardHoldercountry}
                  searchText={searchTextCountry}
                  onChange={this.handleCardHolderCountryInputChange}
                  handleSelect={this.onCardHolderCountrySelect}
                  handleClose={this.handleCardHolderCountryAutocompleteClose}
                  onBlur={this.handleCardHolderCountryBlurCheck}
                  dataSource={countriesList}
                />
                <CreditCardInput
                  onError={this.handleError}
                  cardNumberInputRenderer={({
                    handleCardNumberChange,
                    props,
                  }) => (
                      <>
                        <div className="mask" />
                        <span
                          className={`floating ${cardNumber ? "cardNum" : ""}`}
                        >
                          Card Number
                      </span>
                        <input
                          {...props}
                          placeholder=""
                          value={cardNumber}
                          onChange={handleCardNumberChange(
                            this.handleCardNumberChange
                          )}
                        />
                      </>
                    )}
                  cardExpiryInputRenderer={({
                    handleCardExpiryChange,
                    props,
                  }) => (
                      <>
                        <span className={`floating ${cardExpMm ? "focus" : ""}`}>
                          MM/YY
                      </span>
                        <input
                          {...props}
                          value={expiry}
                          placeholder=""
                          onChange={handleCardExpiryChange(
                            this.handleCardExpiryChange
                          )}
                        />
                      </>
                    )}
                  cardCVCInputRenderer={({ handleCardCVCChange, props }) => (
                    <>
                      <span className={`floating ${cardCvv ? "focus" : ""}`}>
                        CVC
                      </span>
                      <input
                        {...props}
                        value={cvc}
                        placeholder=""
                        onChange={handleCardCVCChange(this.handleCardCVCChange)}
                      />
                    </>
                  )}
                  fieldClassName="creditCardInput"
                />
              </>
            )}
            <div className="acceptTerms">
              <Checkbox color="secondary" onChange={this.handleAcceptChange} />
              <div>
                I acknowledge that I have read the{" "}
                <a href="/terms" target="_blank">
                  Terms & Conditions
                </a>{" "}
                of purchase,{" "}
                <a href="/privacy" target="_blank">
                  Privacy Policy
                </a>{" "}
                and authorize the payment.
              </div>
            </div>
            <Button
              style={{ margin: "20px 0" }}
              disabled={
                loading ||
                !termsAccepted ||
                (!isFormCompleted && currentView === "newCard")
              }
              onClick={this.handleUpdatePassenger}
            >
              {loading
                ? getTranslation("pleaseWait")
                : getTranslation("payNow") + " " + localizedTotalCost}
            </Button>
            {hasSavedCards && (
              <Button
                style={{ margin: "20px 0" }}
                onClick={this.handleViewChange}
              >
                {getTranslation(
                  currentView === "newCard"
                    ? "useSavedCards"
                    : "anotherPaymentMethod"
                )}
              </Button>
            )}
          </InputWrapper>
          <Meta>
            <div>
              <img src={paymentLogo} height="16" alt="" />
            </div>
            <div style={{ marginBottom: 20 }}>
              {getTranslation("paymentDescriptor")}
            </div>
          </Meta>
          <Meta>
            <div style={{ marginTop: 20 }}>
              <img src={logo} height="20" alt="" />
            </div>
            <div style={{ marginBottom: 20 }}>
              <p>
                Harju maakond, Kuusalu vald, Pudisoo küla,
                <br />
                Männimäe, 74626, Estonia
              </p>
            </div>
          </Meta>
        </div>
        <Toast
          open={this.state.paymentError}
          message={this.state.errorMessage}
          handleClose={this.handleHidePaymentError}
        />
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  hopRequests: state.flightResults.hopRequests,
  localizedTotalCost: state.flightResults.localizedTotalCost,
  includeHotel: state.hotelResults.includeHotel
});

export default withTheme(
  withRouter(
    compose(
      graphql(updPassengerQuery, { name: "updPassengerQuery" }),
      connect(mapStateToProps, { addPassengerToAllHopRequests })
    )(PaymentGateway)
  )
);
