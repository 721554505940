import React from "react";
import styled, { withTheme } from "styled-components";
import { getChunks } from "utils/utils";

const Time = styled.div`
  width: 128px;
  height: 72px;
  color: ${props =>
    props.selected ? props.theme.secondary : props.theme.primary};
  background-color: ${props =>
    props.selected ? props.theme.primaryBackground : "#fff"};
  border: ${props =>
    props.selected ? "none" : `1px solid ${props.theme.primary}`};
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  margin-right: 10px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media (max-width: 800px) {
    margin-bottom: 20px;
  }
  &.disabled {
    cursor: not-allowed;
    background-color: #fff;
    border: 1px solid ${props => props.theme.primary};
    span {
      color: #ccc;
    }
  }
  &.loading {
    border: 1px solid #dddddd;
    background-color: #fff;
    span {
      color: #dddddd;
    }
  }
`;

class TimeClass extends React.PureComponent {
  constructor(props) {
    super(props);
    const isFilteredOff = props.filterKvs.find(filter =>
      this.handleFilterCheck(filter.value, props.index, props.time.type)
    );
    this.state = {
      selected: isFilteredOff || props.disabled ? false : true,
      isFilteredOff
    };
  }

  getIcon = (time, selected, isDisabled, loading, theme) => {
    const Icon = time.Icon;
    return (
      <Icon
        stroke={
          loading
            ? "#DDDDDD"
            : isDisabled
            ? "#ccc"
            : selected
            ? "#EC9719"
            : theme.primary
        }
      />
    );
  };

  handleFilterCheck = (value, index, type) => {
    const chunks = getChunks(value.split(""), 2);
    const exist = chunks[index].find(item => item === type);
    return exist;
  };

  handleTimeClick = e => {
    if (this.props.disabled && !this.state.isFilteredOff) {
      return;
    }
    this.setState({ selected: !this.state.selected }, () => {
      this.props.handleTimeToggle(this.props.time.type, this.state.selected);
    });
  };
  render() {
    const { selected, isFilteredOff } = this.state;
    const { time, disabled, loading, theme } = this.props;
    const isDisabled = disabled && !isFilteredOff;
    const disabledStyle = isDisabled ? { borderColor: "#ccc" } : {};
    return (
      <Time
        selected={selected}
        className={`${isDisabled ? "disabled" : ""} ${
          loading ? "loading" : ""
        }`}
        onClick={this.handleTimeClick}
        style={disabledStyle}
      >
        {this.getIcon(time, selected, isDisabled, loading, theme)}
        <span>{time.title}</span>
      </Time>
    );
  }
}

export default withTheme(TimeClass);
