import React, { Component } from "react";
import { graphql, compose, ApolloConsumer } from "react-apollo";
import { connect } from "react-redux";
import styled, { withTheme } from "styled-components";
import gql from "graphql-tag";
import { isMobile } from "react-device-detect";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { updTripowner } from "containers/TripBooking/queries";
import { HotelIcon, HotelGradientIcon, AddIcon, MinusIcon, EditIcon } from "components/common/SvgIcons";
import IconDelete from 'assets/icons/Icon-delete.svg';
import {
  removeTripData,
  RemoveUniqueFsCode,
  clearFilteredResults,
  handleIncludeHotel,
  setNightCount
} from "redux-store/actions";
import { Button, AddPassengersCount, Toast } from "components/common";

import {
  getItem,
  setItem,
  removeItem,
  cancelAllRequests,
  clearLocalStore,
  insertAtIndex,
} from "utils/utils";
import getTranslation from "utils/getTranslation";
import FormFields from "./FormFields";
import Switch from "@material-ui/core/Switch";
import TripBooking from "../../containers/TripBooking";
import EditHotelRequestModal from "../Hotel/EditHotelRequestModal";

const Line = styled.div`
  height: 1px;
  width: ${(props) => props.width};
  margin: 12px auto 0px auto;
  background: #DDDDDD;
  opacity: 0.32;
`;

const Overlay = styled.div`
  position: absolute;
  z-index: 99;
  width: 100%;
  height: calc(100% - 120px);
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.6);
`;

const addTripQuery = gql`
  mutation addTrip(
    $locale: String!
    $countryIc: String!
    $currencyIso: String!
  ) {
    addTrip(locale: $locale, countryIc: $countryIc, currencyIso: $currencyIso) {
      id
    }
  }
`;

export const clearTrip = gql`
  mutation delTripHopRequestsWithTripcostitemsCascade($tripId: Int!) {
    delTripHopRequestsWithTripcostitemsCascade(tripId: $tripId) {
      hopRequests {
        fromAirportIc
      }
    }
  }
`;

class HopRequest extends Component {
  constructor(props) {
    super(props);
    // const latestSearch = (!props.flightsPage && getItem('latestSearch')) ? JSON.parse(getItem('latestSearch')) : {};
    const latestSearch = getItem("latestSearch")
      ? JSON.parse(getItem("latestSearch"))
      : {};
    if (!props.flightsPage) {
      removeItem("cardNumber");
      removeItem("cvvNumber");
      removeItem("expDate");
    }

    this.state = {
      trips: [
        {
          id: 1,
        },
      ],
      errorMsg: "",
      passengers: latestSearch.passengers || {
        infant: 0,
        adult: 1,
        child: 0,
        childrenAges: []
      },
      formInCompleted: true,
      hotelReqs: [],
      inEditHotelReq: null,
      userWantsHotelIncluded: null,
      ...latestSearch,
    };
    if (this.state.userWantsHotelIncluded !== null) {
      console.log('here here ' + this.state.userWantsHotelIncluded);
      this.props.handleIncludeHotel(this.state.userWantsHotelIncluded);
    }
  }

  componentDidMount() {
    this.props.RemoveUniqueFsCode();
    const tripId = getItem("tripId");
    if (getItem("bookingComplete")) {
      // removeItem('guestLogin');
      removeItem("bookingComplete");
    }
    if (!tripId) {
      //
    } else {
      // console.log("Trid ID exists", tripId);
      if (!this.props.flightsPage) {
        if (this.state.date && this.props.canRedirect) {
          this.handleSearchFlight(false);
        }
      }
    }

    if (getItem("encodedTripSearch") && getItem("tripId")) {
      this.props
        .clearTrip()
        .then(({ data }) => {
          this.props.removeTripData();
          this.clearLocalStore(true);
        })
        .catch(() => {
          this.clearLocalStore();
        });
    } else {
      this.props.removeTripData();
      this.clearLocalStore();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.hopRequests.length !== this.props.hopRequests.length &&
      this.props.hopRequests.length === 0
    ) {
      this.setState({
        passengers: {
          infant: nextProps.hopRequests[0].infantCount,
          adult: nextProps.hopRequests[0].adultCount,
          child: nextProps.hopRequests[0].childCount,
        }
      });
    }

    if (nextProps.isNewTrip && this.props.isNewTrip !== nextProps.isNewTrip) {
      this.handleResetSearch();
    }
  }

  clearLocalStore = (generateTripId) => {
    if (generateTripId) {
      removeItem("tripId");
    }
    this.props.clearFilteredResults();
    clearLocalStore();
    if (
      (getItem("guestLogin") || !getItem("tripId")) &&
      getItem("selectedCountry")
    ) {
      removeItem("guestLogin");
      removeItem("tripId");
      setTimeout(() => {
        const language = getItem("selectedLanguage")
          ? getItem("selectedLanguage")
          : navigator.language;
        this.handleLocale({
          locale: language || "en-GB",
          countryCode: getItem("selectedCountry"),
          currencyIso: getItem("selectedCurrency"),
        });
      }, 500);
    }
  };

  handleLocale = (payload) => {
    if (!getItem("tripId")) {
      this.props
        .addTripQuery({
          variables: {
            locale: payload.locale,
            countryIc: payload.countryCode.toUpperCase(),
            currencyIso: payload.currencyIso,
          },
        })
        .then(({ data }) => {
          // console.log("Generated new trip id");
          setItem("tripId", data.addTrip.id);
          if (this.state.date && this.props.canRedirect) {
            this.handleSearchFlight(false);
          }
          this.handleTripOwnerUpdate();
        });
    }
  };

  handleDateOpen = () => {
    if (this.state.from && this.state.to) {
      this.setState({
        openDate: true,
      });
    }
  };

  handleTripOwnerUpdate = () => {
    if (getItem("tokenDetails")) {
      this.props
        .updTripowner({
          variables: {
            tripId: getItem("tripId"),
          },
        })
        .then((data) => {
          console.log(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  handlePassengerChange = (passengers) => {
    this.setState({
      passengers: {
        adult: passengers.adult,
        child: passengers.child,
        infant: passengers.infant,
        childrenAges: passengers.childrenAges
      },
    });
  };

  handleSearchFlight = (checkValidations) => {
    cancelAllRequests();
    removeItem("cancelAllRequests");
    const { flightsPage } = this.props;
    if (flightsPage) {
      setItem("cancelBeforeResult", true);
      this.props.history.push("/trip");
      return;
    }
    let errorMsg = "";
    if (this.state.passengers.adult === 0) {
      errorMsg = getTranslation("atleastOneAdult");
    }

    this.state.trips.forEach((trip) => {
      if (trip.date instanceof Date === false) {
        errorMsg = getTranslation("enterDate");
      } else if (!trip.from) {
        errorMsg = getTranslation("enterFrom");
      } else if (!trip.to) {
        errorMsg = getTranslation("enterTo");
      }
    });
    if (errorMsg) {
      if (checkValidations) {
        this.setState({
          errorMsg,
        });
      }
      return;
    }
    const currentState = JSON.parse(JSON.stringify(this.state));
    setItem("latestSearch", JSON.stringify(currentState));
    const allTrips = this.state.trips.map((trip) => ({
      to: trip.to,
      from: trip.from,
      date: this.props.flightsPage ? trip.date : moment(trip.date).unix(),
      transit: trip.transit,
      returnDate:
        trip.returnDate instanceof Date === false
          ? 0
          : moment(trip.returnDate).unix(),
      passengers: { ...this.state.passengers },
      tripID: getItem("tripId"),
      hotelReqs: this.state.hotelReqs.filter(hr => hr.originalTo === trip.to.airportIc).sort((a, b) => a - b),
    }));
    this.props.onDone(allTrips);
  };

  handleRequestClose = () => {
    this.setState({
      errorMsg: "",
    });
  };


  handleAddNewTrip = (index, isLast) => {
    console.log("inside handleAddNewTrip");
    if (this.state.trips.length < 6) {
      let currIndex = this.state.trips.length - 1;
      const trips = insertAtIndex(this.state.trips, index, {
        id: parseInt(this.state.trips[currIndex].id, 10) + 1,
      });

      this.setState(
        {
          trips: trips,
        },
        () => {
          if (isMobile) {
            window.scrollTo(0, document.body.scrollHeight);
          }
        }
      );
    }
  };

  handleTripUpdate = (trip, id, toggleHotel = false) => {

    let { includeHotel } = this.props;
    let { userWantsHotelIncluded } = this.state;

    // console.log("inside handleTripUpdate");
    const updatedTrips = this.state.trips.map((item) =>
      item.id !== id
        ? item
        : {
          ...trip,
        }
    );
    // console.log('updated Trips ' + JSON.stringify(updatedTrips));
    const hideAddExtraTrip = updatedTrips.find((item) => item.returnDate);

    let hotelReqs = [];
    let localIncomplete = updatedTrips.some(trip => trip.to == null || trip.to == "");
    if (!localIncomplete) {
      updatedTrips.map((hopr, i) => {
        if (hopr.date) {
          if (hopr.returnDate || updatedTrips.length == 1) {
            hotelReqs.push({
              originalTo: hopr.to.airportIc,
              destinationCode: hopr.to.airportIc,
              destinationCodeType: "AIRPORT",
              destinationName: hopr.to.city,
              checkinDate: moment(hopr.date).unix(),
              checkoutDate: hopr.returnDate ? moment(hopr.returnDate).unix() : moment(hopr.date).add(7, 'days').unix()
            });
          }
          else {
            if (updatedTrips.length > 1 && i != 0) {
              hotelReqs.push({
                originalTo: updatedTrips[i - 1].to.airportIc,
                destinationCode: updatedTrips[i - 1].to.airportIc,
                destinationCodeType: "AIRPORT",
                destinationName: updatedTrips[i - 1].to.city,
                checkinDate: moment(updatedTrips[i - 1].date).unix(),
                checkoutDate: moment(hopr.date).unix()
              });
            }
          }
        }
      });
    }
    if (toggleHotel) {
      this.props.handleIncludeHotel(!includeHotel);
    }

    this.setState({
      userWantsHotelIncluded: toggleHotel ? !includeHotel : userWantsHotelIncluded,
      trips: updatedTrips,
      doReset: false,
      hideAddExtraTrip: !!hideAddExtraTrip,
      hotelReqs: hotelReqs
    }, () => {
      if (this.state.trips.length == 1 && this.state.trips[0].startDate == this.state.trips[0].returnDate && this.state.userWantsHotelIncluded === null) {
        this.props.handleIncludeHotel(false);
      }
      else {
        this.props.handleIncludeHotel(this.state.userWantsHotelIncluded === null ? true : this.state.userWantsHotelIncluded);
      }
    });
  };

  handleResetSearch = () => {
    console.log("inside handleResetSearch");
    removeItem("latestSearch");
    this.setState({
      trips: [
        {
          id: 1,
        },
      ],
      errorMsg: "",
      passengers: {
        infant: 0,
        adult: 1,
        child: 0,
        childrenAges: []
      },
      children: false,
      doReset: true,
      hotelReqs: [],
      userWantsHotelIncluded: null
    });
  };

  handleFormStatus = (formInCompleted) => {
    // console.log("inside handleFormStatus. Val " + formInCompleted);
    this.setState({ formInCompleted });
  };

  handleRemoveTrip = (id) => {
    let { userWantsHotelIncluded } = this.state;
    let trips = this.state.trips.filter((trip) => trip.id !== id);
    let hotelReqs = [];
    let localIncomplete = trips.some(trip => trip.to == null || trip.to == "");
    if (!localIncomplete) {
      trips.map((hopr, i) => {
        if (hopr.date) {
          if (hopr.returnDate || trips.length == 1) {
            hotelReqs.push({
              originalTo: hopr.to.airportIc,
              destinationCode: hopr.to.airportIc,
              destinationCodeType: "AIRPORT",
              destinationName: hopr.to.city,
              checkinDate: moment(hopr.date).unix(),
              checkoutDate: hopr.returnDate ? moment(hopr.returnDate).unix() : moment(hopr.date).add(7, 'days').unix()
            });
          }
          else {
            if (trips.length > 1 && i != 0) {
              hotelReqs.push({
                originalTo: trips[i - 1].to.airportIc,
                destinationCode: trips[i - 1].to.airportIc,
                destinationCodeType: "AIRPORT",
                destinationName: trips[i - 1].to.city,
                checkinDate: moment(trips[i - 1].date).unix(),
                checkoutDate: moment(hopr.date).unix()
              });
            }
          }
        }
      });
    }
    if (userWantsHotelIncluded === false) {
      this.props.handleIncludeHotel(false);
    }
    this.setState({ trips, hotelReqs: hotelReqs });
  };

  getMinDate = (index) => {
    const { trips } = this.state;
    return trips.length > 1 && index > 0
      ? moment(trips[trips.length - 2].date).toDate()
      : new Date();
  };

  getMaxDate = (index) => {
    const { trips } = this.state;
    return index < trips.length - 1 && trips[index + 1].date
      ? moment(trips[index + 1].date).toDate()
      : moment().add(16, "months").toDate();
  };

  handleHotelToggle = () => {
    this.handleTripUpdate(null, 0, true);
  };

  handleIncrease = e => {
    let { nightCount } = this.props;
    nightCount += 1;
    this.props.setNightCount(nightCount);
  };

  handleDecrease = (setToZero) => {
    const { trips } = this.state;
    let { nightCount } = this.props;
    if (setToZero || nightCount == 0) {
      if (trips.length == 1) {
        this.props.handleIncludeHotel(false);
      }
      else {
        nightCount = 0;
      }
    }
    else {
      nightCount = nightCount == 1 ? nightCount : nightCount - 1;
    }
    this.props.setNightCount(nightCount);
  };

  handleEditHotelReq = (hr) => {
    this.setState({ inEditHotelReq: hr })
  }

  handleChangeHotelReq = (newDates, originalTo, newHotelDest) => {
    let { hotelReqs } = this.state;
    console.log('type of newDates date ' + typeof newDates.endDate + ' newDates ' + JSON.stringify(newDates) + ' newHotelDest ' + JSON.stringify(newHotelDest));
    hotelReqs.map(hotelReq => {
      if (hotelReq.originalTo == originalTo) {
        hotelReq.checkinDate = moment(newDates.startDate).unix();
        hotelReq.checkoutDate = moment(newDates.endDate).unix();
        if (newHotelDest) {
          hotelReq.destinationCodeType = "DESTINATION";
          hotelReq.destinationCode = newHotelDest.code;
          hotelReq.destinationName = newHotelDest.name
        }
      }
    });

    this.setState({ hotelReqs: hotelReqs, inEditHotelReq: null });
  }

  render() {
    let {
      errorMsg,
      passengers,
      trips,
      hideAddExtraTrip,
      doReset,
      formInCompleted,
      hotelReqs,
      inEditHotelReq,
      userWantsHotelIncluded
    } = this.state;
    const {
      flightsPage,
      loading,
      disabled,
      hopRequests,
      isNewTrip,
      enableCancelBtn,
      theme,
      includeHotel,
      nightCount
    } = this.props;
    const allPassengers =
      hopRequests.length > 0 ? hopRequests[0].passengers || [] : [];
    const tripsLength = trips.length;

    // console.log('formIncompleted ' + formInCompleted + ' trips ' + JSON.stringify(trips, null, 2));
    // console.log('hotelReqs ' + JSON.stringify(hotelReqs));
    // if (hotelReqs.length > 0) {
    // console.log('type of hotelReqs date ' + typeof hotelReqs[0].checkinDate);
    // }
    // if (!trips[tripsLength - 1].to) {
    //   formInCompleted = true;
    // }

    // console.log('formIncompleted ' + formInCompleted + ' trips ' + JSON.stringify(trips, null, 2));

    return (
      <ApolloConsumer>
        {(client) => (
          inEditHotelReq ? <EditHotelRequestModal open hotelReq={inEditHotelReq} handleChangeHotelReq={this.handleChangeHotelReq} />
            :
            <div
              style={{ ...this.props.style, position: "relative", width: "100%" }}
              className="__hop-container"
            >
              {(disabled || allPassengers.length > 0 || flightsPage) && (
                <Overlay />
              )}
              {trips.map((trip, i) => (
                <div
                  key={trip.id}
                  style={{
                    backgroundColor: "#fff",
                    padding: isMobile ? "0 20px" : 0,
                  }}
                >
                  <FormFields
                    id={trip.id}
                    tripIndex={i}
                    lastState={trip.to ? trip : null}
                    isLast={tripsLength - 1 === i}
                    tripsLength={tripsLength}
                    client={client}
                    flightsPage={flightsPage}
                    hideAddExtraTrip={hideAddExtraTrip}
                    handleLocale={this.handleLocale}
                    handleAddNewTrip={this.handleAddNewTrip}
                    handleTripUpdate={this.handleTripUpdate}
                    fromAirport={trips[0].from}
                    prevToAirport={i > 0 ? trips[i - 1].to : null}
                    prevHopReqDate={i > 0 ? trips[i - 1].date : null}
                    isNewTrip={isNewTrip}
                    doReset={doReset}
                    handleFormStatus={this.handleFormStatus}
                    handleRemoveTrip={this.handleRemoveTrip}
                    getMinDate={this.getMinDate}
                    getMaxDate={this.getMaxDate}
                    handleSearchFlight={this.handleSearchFlight}
                  />
                  {tripsLength - 1 == i && (
                    <Line width="100%" />
                  )}
                  {(tripsLength - 1 == i) && (
                    <>
                      <div style={{ display: "flex", flexDirection: "column", padding: "15px 0px", justifyContent: "center" }}>
                        <div style={{ display: "flex", flexDirection: "row", padding: "15px 0px", justifyContent: "center", alignItems: "center" }}>
                          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            {includeHotel ? <HotelIcon /> : <HotelIcon fill="#dddddd" />}
                            <div style={{ color: "#777777", textAlign: "center", fontSize: "12px", marginTop: "4px" }}>
                              {getTranslation("includeHotel")}
                            </div>
                          </div>

                          <Switch
                            checked={includeHotel}
                            onChange={this.handleHotelToggle}
                            // value={item.value}
                            color="primary"
                            inputProps={{ "aria-label": "primary checkbox" }}
                          />
                        </div>

                        {includeHotel && hotelReqs.length > 0 && (
                          <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                            {hotelReqs.map(hr => (
                              <div style={{ display: "flex", background: "#F0F0F0", borderRadius: "24px", margin: "8px 12px", width: "296px", height: "56px", alignItems: "center" }}>
                                <div style={{ marginLeft: "15px" }}> <HotelGradientIcon /> </div>
                                <div style={{ marginLeft: "11px", display: "flex", flexDirection: "column" }}>
                                  <div>{hr.destinationName}</div>
                                  <div style={{ fontSize: "14px", marginTop: "2px" }}>{moment(hr.checkoutDate * 1000).startOf('day').diff(moment(hr.checkinDate * 1000).startOf('day'), 'days') + ' ' + getTranslation("nights")}</div>
                                </div>
                                <div style={{ background: "#FFFFFF", borderRadius: "20px", boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.08)", marginLeft: "auto", width: "56px", height: "32px", display: "flex", alignItems: "center", justifyContent: "center", marginRight: "6px" }} onClick={() => this.handleEditHotelReq(hr)}>
                                  <EditIcon stroke="#007AC7" />
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>

                      {/* {if one way and if open-jaw} */}
                      {/* nightCount != 0 && */ /* !formInCompleted && includeHotel && (tripsLength == 1 || trips.filter(trip => trip.from === trips[tripsLength - 1].to).length == 0) && !trip.returnDate && (
                      <>
                        <div style={{ padding: "20px 5px 0px", display: "flex", justifyContent: "center" }}>
                          <Button style={{ maxWidth: 72, height: 32 }} color="#F2CC56" onClick={this.handleIncrease}>
                            <AddIcon style={{ height: 24, width: 24 }} />
                          </Button>
                          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center", padding: "0px 10px" }}>
                            <div style={{ fontSize: "20px", lineHeight: "32px" }}> {nightCount} </div>
                            <div style={{ color: "#777777", fontSize: "12px" }}>
                              {nightCount == 1 ? getTranslation("night") : getTranslation("nights")}
                            </div>
                          </div>
                          <Button style={{ maxWidth: 72, height: 32 }} color="#F2CC56" onClick={() => this.handleDecrease(false)}>
                            <MinusIcon style={{ height: 24, width: 24 }} />
                          </Button>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", padding: "5px 0px 20px" }}>
                          <div style={{ color: "#777777", fontSize: "12px", paddingBottom: 5 }}>
                            {getTranslation("at") + ' ' + trips[tripsLength - 1].to.city}
                          </div>
                          <div style={{ width: 44, height: 44 }}>
                            <Button
                              style={{
                                height: 42,
                                width: 42,
                                minWidth: 42,
                              }}
                              color="#F0F0F0"
                              // disabled={loading}
                              onClick={() => this.handleDecrease(true)}
                            >
                              <img src={IconDelete} alt="" />
                            </Button>
                          </div>
                        </div>
                      </>
                    )*/}
                    </>
                  )}
                  {tripsLength - 1 !== i && (
                    <Line width="933px" />
                  )}
                </div>
              ))
              }
              <div>
                <AddPassengersCount
                  passengers={passengers}
                  onChange={this.handlePassengerChange}
                  resetSearch={this.handleResetSearch}
                  childText={getTranslation("child")}
                />
              </div>
              <div
                className="__bsc-input-container"
                style={{
                  position: "relative",
                  width: 296,
                  margin: isMobile ? "25px auto" : "25px auto 25px auto",
                }}
              >

                <Button
                  onClick={this.handleSearchFlight}
                  disabled={
                    (loading || formInCompleted) &&
                    (!flightsPage || !enableCancelBtn)
                  }
                  flightsPage={flightsPage}
                  color={theme.primary}
                >
                  {getTranslation("search")}
                </Button>
              </div>
              <Toast
                open={errorMsg.length > 0}
                message={errorMsg}
                handleClose={this.handleRequestClose}
              />
            </div >
        )
        }
      </ApolloConsumer >
    );
  }
}

const mapStateToProps = (state) => ({
  hopRequests: state.flightResults.hopRequests,
  includeHotel: state.hotelResults.includeHotel,
  nightCount: state.hotelResults.nightCount,

});

const mapDispatchToProp = {
  removeTripData,
  RemoveUniqueFsCode,
  clearFilteredResults,
  handleIncludeHotel,
  setNightCount
};
export default withTheme(
  withRouter(
    compose(
      graphql(addTripQuery, {
        name: "addTripQuery",
      }),
      graphql(updTripowner, { name: "updTripowner" }),
      graphql(clearTrip, {
        name: "clearTrip",
        options: () => ({ variables: { tripId: getItem("tripId") } }),
      }),
      connect(mapStateToProps, mapDispatchToProp)
    )(HopRequest)
  )
);
