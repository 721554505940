import React from "react";
import styled from "styled-components";
import { EditIcon } from "components/common/SvgIcons";
import { Button } from "components/common";
import getTranslation from "utils/getTranslation";

const Wrapper = styled.div`
  .btnWrapper {
    display: flex;
    button {
      height: 40px;
      &:first-child {
        margin-right: 24px;
      }
    }
  }
`;

export default ({
  editEnabled,
  loading,
  handleEnableEdit,
  handleDiscard,
  handleUpdate
}) => (
  <Wrapper>
    {!editEnabled && (
      <div style={{ width: 56 }}>
        <Button
          style={{
            height: 32,
            width: 56
          }}
          color="#D0F3FC"
          onClick={handleEnableEdit}
        >
          <EditIcon stroke="#007AC7" style={{ width: 24, height: 24 }} />
        </Button>
      </div>
    )}
    {editEnabled && (
      <div className="btnWrapper">
        <div style={{ width: 136, marginRight: 24 }}>
          <Button
            style={{ color: "#EB5757" }}
            color="#F0F0F0"
            onClick={handleDiscard}
          >
            {getTranslation("discard")}
          </Button>
        </div>
        <div style={{ width: 136 }}>
          <Button disabled={loading} onClick={handleUpdate}>
            {getTranslation("update")}
          </Button>
        </div>
      </div>
    )}
  </Wrapper>
);
