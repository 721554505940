import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Wrapper = styled.div`
  .slick-slide {
    opacity: 0.8;
  }
  .slick-center {
    opacity: 1;
  }
  .imageWrapper {
    height: calc(100vh - 160px);
    margin: 0 24px;
    display: flex !important;
    align-items: center;
    outline: none;
    img {
      object-fit: cover;
      height: 100%;
      width: auto;
    }
    /* min-width: 960px; */
  }
`;

export default class extends React.PureComponent {
  render() {
    const { blogData } = this.props;
    let settings = {
      className: "center",
      arrows: false,
      dots: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      touchThreshold: 50,
      centerMode: true,
      //adaptiveHeight: true,
      variableWidth: true,
      autoplay: false,
      autoplaySpeed: 5000,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            dots: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
            variableWidth: true,
            adaptiveHeight: true,
            autoplay: false
          }
        }
      ]
    };
    const images = blogData.text.split("!@#");
    return (
      <Wrapper>
        <div>
          <Slider ref={c => (this.slider = c)} {...settings}>
            {images.map((image, i) => (
              <div className="imageWrapper" key={i}>
                <img src={image} alt="" />
              </div>
            ))}
          </Slider>
        </div>
      </Wrapper>
    );
  }
}
