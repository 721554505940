export default {
  v: "5.6.5",
  fr: 30,
  ip: 0,
  op: 83,
  w: 1200,
  h: 720,
  nm: "Tour-Eiffel",
  ddd: 0,
  assets: [
    {
      id: "image_0",
      w: 25,
      h: 25,
      u: "/static/ae-images/tower/images/",
      p: "img_0.svg",
      e: 0
    },
    {
      id: "image_1",
      w: 37,
      h: 38,
      u: "/static/ae-images/tower/images/",
      p: "img_1.svg",
      e: 0
    },
    {
      id: "image_2",
      w: 55,
      h: 21,
      u: "/static/ae-images/tower/images/",
      p: "img_2.svg",
      e: 0
    },
    {
      id: "image_3",
      w: 218,
      h: 125,
      u: "/static/ae-images/tower/images/",
      p: "img_3.svg",
      e: 0
    },
    {
      id: "image_4",
      w: 215,
      h: 126,
      u: "/static/ae-images/tower/images/",
      p: "img_4.svg",
      e: 0
    },
    {
      id: "image_5",
      w: 322,
      h: 170,
      u: "/static/ae-images/tower/images/",
      p: "img_5.svg",
      e: 0
    },
    {
      id: "image_6",
      w: 218,
      h: 122,
      u: "/static/ae-images/tower/images/",
      p: "img_6.svg",
      e: 0
    },
    {
      id: "image_7",
      w: 362,
      h: 183,
      u: "/static/ae-images/tower/images/",
      p: "img_7.svg",
      e: 0
    },
    {
      id: "image_8",
      w: 289,
      h: 573,
      u: "/static/ae-images/tower/images/",
      p: "img_8.svg",
      e: 0
    },
    {
      id: "image_9",
      w: 617,
      h: 3,
      u: "/static/ae-images/tower/images/",
      p: "img_9.svg",
      e: 0
    },
    {
      id: "image_10",
      w: 31,
      h: 31,
      u: "/static/ae-images/tower/images/",
      p: "img_10.svg",
      e: 0
    },
    {
      id: "image_11",
      w: 151,
      h: 477,
      u: "/static/ae-images/tower/images/",
      p: "img_11.svg",
      e: 0
    },
    {
      id: "image_12",
      w: 417,
      h: 59,
      u: "/static/ae-images/tower/images/",
      p: "img_12.svg",
      e: 0
    },
    {
      id: "image_13",
      w: 265,
      h: 486,
      u: "/static/ae-images/tower/images/",
      p: "img_13.svg",
      e: 0
    },
    {
      id: "image_14",
      w: 173,
      h: 66,
      u: "/static/ae-images/tower/images/",
      p: "img_14.svg",
      e: 0
    },
    {
      id: "image_15",
      w: 174,
      h: 66,
      u: "/static/ae-images/tower/images/",
      p: "img_15.svg",
      e: 0
    },
    {
      id: "image_16",
      w: 110,
      h: 150,
      u: "/static/ae-images/tower/images/",
      p: "img_16.svg",
      e: 0
    },
    {
      id: "image_17",
      w: 195,
      h: 173,
      u: "/static/ae-images/tower/images/",
      p: "img_17.svg",
      e: 0
    },
    {
      id: "image_18",
      w: 405,
      h: 186,
      u: "/static/ae-images/tower/images/",
      p: "img_18.svg",
      e: 0
    },
    {
      id: "image_19",
      w: 390,
      h: 117,
      u: "/static/ae-images/tower/images/",
      p: "img_19.svg",
      e: 0
    },
    {
      id: "image_20",
      w: 646,
      h: 270,
      u: "/static/ae-images/tower/images/",
      p: "img_20.svg",
      e: 0
    }
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 2,
      nm: "$1.png",
      cl: "svg",
      refId: "image_0",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 30,
              s: [0]
            },
            { t: 38, s: [100] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [626.5, 262, 0], ix: 2 },
        a: { a: 0, k: [12.5, 12.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 8,
      op: 458,
      st: 8,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 2,
      nm: "$.png",
      cl: "svg",
      refId: "image_1",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 30,
              s: [0]
            },
            { t: 38, s: [100] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1053.5, 186, 0], ix: 2 },
        a: { a: 0, k: [18.5, 19, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 8,
      op: 458,
      st: 8,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 2,
      nm: "$.png",
      cl: "svg",
      refId: "image_1",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 30,
              s: [0]
            },
            { t: 38, s: [100] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1022.5, 477, 0], ix: 2 },
        a: { a: 0, k: [18.5, 19, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 8,
      op: 458,
      st: 8,
      bm: 0
    },
    {
      ddd: 0,
      ind: 4,
      ty: 2,
      nm: "logo (1).png",
      cl: "svg",
      refId: "image_2",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [756.375, 94.125, 0], ix: 2 },
        a: { a: 0, k: [27.5, 10.5, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 22,
              s: [0, 0, 100]
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 30,
              s: [100, 100, 100]
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 33,
              s: [70, 70, 100]
            },
            { t: 36, s: [100, 100, 100] }
          ],
          ix: 6
        }
      },
      ao: 0,
      ip: 2,
      op: 452,
      st: 2,
      bm: 0
    },
    {
      ddd: 0,
      ind: 5,
      ty: 2,
      nm: "1.png",
      cl: "svg",
      refId: "image_3",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [832.14, 322, 0], ix: 2 },
        a: { a: 0, k: [109, 62.5, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 22,
              s: [0, 0, 100]
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 30,
              s: [100, 100, 100]
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 33,
              s: [70, 70, 100]
            },
            { t: 36, s: [100, 100, 100] }
          ],
          ix: 6
        }
      },
      ao: 0,
      ip: 2,
      op: 452,
      st: 2,
      bm: 0
    },
    {
      ddd: 0,
      ind: 6,
      ty: 2,
      nm: "6.png",
      cl: "svg",
      refId: "image_4",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [834, 163, 0], ix: 2 },
        a: { a: 0, k: [107.5, 63, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 22,
              s: [0, 0, 100]
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 30,
              s: [100, 100, 100]
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 33,
              s: [70, 70, 100]
            },
            { t: 36, s: [100, 100, 100] }
          ],
          ix: 6
        }
      },
      ao: 0,
      ip: 2,
      op: 452,
      st: 2,
      bm: 0
    },
    {
      ddd: 0,
      ind: 7,
      ty: 2,
      nm: "img2.png",
      cl: "svg",
      refId: "image_5",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 23,
              s: [0]
            },
            { t: 30, s: [100] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0, y: 0 },
              t: 0,
              s: [867, 667, 0],
              to: [0, -13.333, 0],
              ti: [-27.833, 13.333, 0]
            },
            {
              i: { x: 0, y: 1 },
              o: { x: 0, y: 0 },
              t: 23,
              s: [867, 587, 0],
              to: [27.833, -13.333, 0],
              ti: [-27.833, 0, 0]
            },
            { t: 33, s: [1034, 587, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [161, 85, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 8,
      ty: 2,
      nm: "3.png",
      cl: "svg",
      refId: "image_6",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [832, 485, 0], ix: 2 },
        a: { a: 0, k: [109, 61, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 22,
              s: [0, 0, 100]
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 30,
              s: [100, 100, 100]
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 33,
              s: [70, 70, 100]
            },
            { t: 36, s: [100, 100, 100] }
          ],
          ix: 6
        }
      },
      ao: 0,
      ip: 2,
      op: 452,
      st: 2,
      bm: 0
    },
    {
      ddd: 0,
      ind: 9,
      ty: 2,
      nm: "img (1).png",
      cl: "svg",
      refId: "image_7",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 23,
              s: [0]
            },
            { t: 29, s: [100] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0, y: 0 },
              t: 0,
              s: [834, 626, 0],
              to: [0, -13.333, 0],
              ti: [46.333, 13.333, 0]
            },
            {
              i: { x: 0, y: 1 },
              o: { x: 0, y: 0 },
              t: 23,
              s: [834, 546, 0],
              to: [-46.333, -13.333, 0],
              ti: [46.333, 0, 0]
            },
            { t: 33, s: [556, 546, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [181, 91.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 3,
      op: 453,
      st: 3,
      bm: 0
    },
    {
      ddd: 0,
      ind: 10,
      ty: 2,
      nm: "phone.png",
      cl: "svg",
      refId: "image_8",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 17,
              s: [0]
            },
            { t: 28, s: [100] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.109, y: 0 },
              t: 0,
              s: [512, 395, 0],
              to: [0, -13.333, 0],
              ti: [-53.333, 13.333, 0]
            },
            {
              i: { x: 0, y: 1 },
              o: { x: 0.109, y: 0 },
              t: 17,
              s: [512, 315, 0],
              to: [53.333, -13.333, 0],
              ti: [-53.333, 0, 0]
            },
            { t: 32, s: [832, 315, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [144.5, 286.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 8,
      op: 458,
      st: 8,
      bm: 0
    },
    {
      ddd: 0,
      ind: 11,
      ty: 2,
      nm: "line.png",
      cl: "svg",
      refId: "image_9",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 37,
              s: [0]
            },
            { t: 39, s: [100] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [832, 600.5, 0], ix: 2 },
        a: { a: 0, k: [308.5, 1.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 8,
      op: 458,
      st: 8,
      bm: 0
    },
    {
      ddd: 0,
      ind: 12,
      ty: 2,
      nm: "л.б.2.png",
      cl: "2 png",
      refId: "image_10",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [145.077, 539.75, 0], ix: 2 },
        a: { a: 0, k: [15.5, 15.5, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0, 0, 0.667], y: [1.023, 1.023, 1] },
              o: { x: [0.132, 0.132, 0.333], y: [0, 0, 0] },
              t: 26,
              s: [0, 0, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 33,
              s: [120, 120, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 35.5,
              s: [90, 90, 100]
            },
            { t: 38, s: [100, 100, 100] }
          ],
          ix: 6
        }
      },
      ao: 0,
      ip: 2,
      op: 452,
      st: 2,
      bm: 0
    },
    {
      ddd: 0,
      ind: 13,
      ty: 2,
      nm: "Man.png",
      cl: "svg",
      refId: "image_11",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 8,
              s: [0]
            },
            { t: 19, s: [100] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.14, y: 0 },
              t: 0,
              s: [256, 440.5, 0],
              to: [0, -13.333, 0],
              ti: [-46, 13.333, 0]
            },
            {
              i: { x: 0, y: 1 },
              o: { x: 0.14, y: 0 },
              t: 8,
              s: [256, 360.5, 0],
              to: [46, -13.333, 0],
              ti: [-46, 0, 0]
            },
            { t: 23, s: [532, 360.5, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [75.5, 238.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 8,
      op: 458,
      st: 8,
      bm: 0
    },
    {
      ddd: 0,
      ind: 14,
      ty: 2,
      nm: "тень башни.png",
      cl: "svg",
      parent: 15,
      refId: "image_12",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 17,
              s: [0]
            },
            { t: 28, s: [100] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [138.5, 507.5, 0], ix: 2 },
        a: { a: 0, k: [208.5, 29.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 8,
      op: 458,
      st: 8,
      bm: 0
    },
    {
      ddd: 0,
      ind: 15,
      ty: 2,
      nm: "Башня.png",
      cl: "svg",
      refId: "image_13",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 17,
              s: [0]
            },
            { t: 28, s: [100] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.16, y: 0 },
              t: 0,
              s: [529, 388, 0],
              to: [0, -13.333, 0],
              ti: [34, 13.333, 0]
            },
            {
              i: { x: 0, y: 1 },
              o: { x: 0.16, y: 0 },
              t: 17,
              s: [529, 308, 0],
              to: [-34, -13.333, 0],
              ti: [34, 0, 0]
            },
            { t: 32, s: [325, 308, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [132.5, 243, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 2,
      op: 452,
      st: 2,
      bm: 0
    },
    {
      ddd: 0,
      ind: 16,
      ty: 2,
      nm: "Vector (1).png",
      cl: "svg",
      refId: "image_14",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [225.5, 521.5, 0], ix: 2 },
        a: { a: 0, k: [86.5, 33, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0, 0, 0.667], y: [1.023, 1.023, 1] },
              o: { x: [0.132, 0.132, 0.333], y: [0, 0, 0] },
              t: 28,
              s: [0, 0, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 35,
              s: [120, 120, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 37.5,
              s: [90, 90, 100]
            },
            { t: 40, s: [100, 100, 100] }
          ],
          ix: 6
        }
      },
      ao: 0,
      ip: 4,
      op: 454,
      st: 4,
      bm: 0
    },
    {
      ddd: 0,
      ind: 17,
      ty: 2,
      nm: "Vector (2).png",
      cl: "svg",
      refId: "image_15",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [425.5, 519, 0], ix: 2 },
        a: { a: 0, k: [87, 33, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0, 0, 0.667], y: [1.023, 1.023, 1] },
              o: { x: [0.132, 0.132, 0.333], y: [0, 0, 0] },
              t: 28,
              s: [0, 0, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 35,
              s: [120, 120, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 37.5,
              s: [90, 90, 100]
            },
            { t: 40, s: [100, 100, 100] }
          ],
          ix: 6
        }
      },
      ao: 0,
      ip: 2,
      op: 452,
      st: 2,
      bm: 0
    },
    {
      ddd: 0,
      ind: 18,
      ty: 2,
      nm: "л.б.1.png",
      cl: "1 png",
      refId: "image_16",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [215, 478, 0], ix: 2 },
        a: { a: 0, k: [55, 75, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0, 0, 0.667], y: [1.023, 1.023, 1] },
              o: { x: [0.132, 0.132, 0.333], y: [0, 0, 0] },
              t: 26,
              s: [0, 0, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 33,
              s: [120, 120, 100]
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 35.5,
              s: [90, 90, 100]
            },
            { t: 38, s: [100, 100, 100] }
          ],
          ix: 6
        }
      },
      ao: 0,
      ip: 2,
      op: 452,
      st: 2,
      bm: 0
    },
    {
      ddd: 0,
      ind: 19,
      ty: 2,
      nm: "bg4.png",
      cl: "svg",
      refId: "image_17",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1012, 523, 0], ix: 2 },
        a: { a: 0, k: [97.5, 86.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ef: [
        {
          ty: 5,
          nm: "CC Line Sweep",
          np: 7,
          mn: "CS LineSweep",
          ix: 1,
          en: 1,
          ef: [
            {
              ty: 0,
              nm: "Completion",
              mn: "CS LineSweep-0001",
              ix: 1,
              v: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 12,
                    s: [99]
                  },
                  { t: 18, s: [0] }
                ],
                ix: 1
              }
            },
            {
              ty: 0,
              nm: "Direction",
              mn: "CS LineSweep-0002",
              ix: 2,
              v: { a: 0, k: 3, ix: 2 }
            },
            {
              ty: 0,
              nm: "Thickness",
              mn: "CS LineSweep-0003",
              ix: 3,
              v: { a: 0, k: 50, ix: 3 }
            },
            {
              ty: 0,
              nm: "Slant",
              mn: "CS LineSweep-0004",
              ix: 4,
              v: { a: 0, k: 99, ix: 4 }
            },
            {
              ty: 7,
              nm: "Flip Direction",
              mn: "CS LineSweep-0005",
              ix: 5,
              v: { a: 0, k: 0, ix: 5 }
            }
          ]
        }
      ],
      ip: 8,
      op: 458,
      st: 8,
      bm: 0
    },
    {
      ddd: 0,
      ind: 20,
      ty: 2,
      nm: "bg3.png",
      cl: "svg",
      refId: "image_18",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [984.46, 366, 0], ix: 2 },
        a: { a: 0, k: [202.5, 93, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ef: [
        {
          ty: 5,
          nm: "CC Line Sweep",
          np: 7,
          mn: "CS LineSweep",
          ix: 1,
          en: 1,
          ef: [
            {
              ty: 0,
              nm: "Completion",
              mn: "CS LineSweep-0001",
              ix: 1,
              v: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 12,
                    s: [99]
                  },
                  { t: 18, s: [0] }
                ],
                ix: 1
              }
            },
            {
              ty: 0,
              nm: "Direction",
              mn: "CS LineSweep-0002",
              ix: 2,
              v: { a: 0, k: 361, ix: 2 }
            },
            {
              ty: 0,
              nm: "Thickness",
              mn: "CS LineSweep-0003",
              ix: 3,
              v: { a: 0, k: 50, ix: 3 }
            },
            {
              ty: 0,
              nm: "Slant",
              mn: "CS LineSweep-0004",
              ix: 4,
              v: { a: 0, k: 99, ix: 4 }
            },
            {
              ty: 7,
              nm: "Flip Direction",
              mn: "CS LineSweep-0005",
              ix: 5,
              v: { a: 0, k: 0, ix: 5 }
            }
          ]
        }
      ],
      ip: 8,
      op: 458,
      st: 8,
      bm: 0
    },
    {
      ddd: 0,
      ind: 21,
      ty: 2,
      nm: "bg1.png",
      cl: "svg",
      refId: "image_19",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [384.308, 378.5, 0], ix: 2 },
        a: { a: 0, k: [195, 58.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ef: [
        {
          ty: 5,
          nm: "CC Line Sweep",
          np: 7,
          mn: "CS LineSweep",
          ix: 1,
          en: 1,
          ef: [
            {
              ty: 0,
              nm: "Completion",
              mn: "CS LineSweep-0001",
              ix: 1,
              v: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 11,
                    s: [99]
                  },
                  { t: 17, s: [0] }
                ],
                ix: 1
              }
            },
            {
              ty: 0,
              nm: "Direction",
              mn: "CS LineSweep-0002",
              ix: 2,
              v: { a: 0, k: 1, ix: 2 }
            },
            {
              ty: 0,
              nm: "Thickness",
              mn: "CS LineSweep-0003",
              ix: 3,
              v: { a: 0, k: 50, ix: 3 }
            },
            {
              ty: 0,
              nm: "Slant",
              mn: "CS LineSweep-0004",
              ix: 4,
              v: { a: 0, k: 99, ix: 4 }
            },
            {
              ty: 7,
              nm: "Flip Direction",
              mn: "CS LineSweep-0005",
              ix: 5,
              v: { a: 0, k: 0, ix: 5 }
            }
          ]
        }
      ],
      ip: 11,
      op: 461,
      st: 11,
      bm: 0
    },
    {
      ddd: 0,
      ind: 22,
      ty: 2,
      nm: "bg2.png",
      cl: "svg",
      refId: "image_20",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [327.308, 427, 0], ix: 2 },
        a: { a: 0, k: [323, 135, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ef: [
        {
          ty: 5,
          nm: "CC Line Sweep",
          np: 7,
          mn: "CS LineSweep",
          ix: 1,
          en: 1,
          ef: [
            {
              ty: 0,
              nm: "Completion",
              mn: "CS LineSweep-0001",
              ix: 1,
              v: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 13,
                    s: [99]
                  },
                  { t: 19, s: [0] }
                ],
                ix: 1
              }
            },
            {
              ty: 0,
              nm: "Direction",
              mn: "CS LineSweep-0002",
              ix: 2,
              v: { a: 0, k: 358, ix: 2 }
            },
            {
              ty: 0,
              nm: "Thickness",
              mn: "CS LineSweep-0003",
              ix: 3,
              v: { a: 0, k: 50, ix: 3 }
            },
            {
              ty: 0,
              nm: "Slant",
              mn: "CS LineSweep-0004",
              ix: 4,
              v: { a: 0, k: 99, ix: 4 }
            },
            {
              ty: 7,
              nm: "Flip Direction",
              mn: "CS LineSweep-0005",
              ix: 5,
              v: { a: 0, k: 0, ix: 5 }
            }
          ]
        }
      ],
      ip: 11,
      op: 461,
      st: 11,
      bm: 0
    },
    {
      ddd: 0,
      ind: 23,
      ty: 2,
      nm: "bg2.png",
      cl: "svg",
      refId: "image_20",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [867, 200, 0], ix: 2 },
        a: { a: 0, k: [323, 135, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ef: [
        {
          ty: 5,
          nm: "CC Line Sweep",
          np: 7,
          mn: "CS LineSweep",
          ix: 1,
          en: 1,
          ef: [
            {
              ty: 0,
              nm: "Completion",
              mn: "CS LineSweep-0001",
              ix: 1,
              v: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 10,
                    s: [99]
                  },
                  { t: 16, s: [0] }
                ],
                ix: 1
              }
            },
            {
              ty: 0,
              nm: "Direction",
              mn: "CS LineSweep-0002",
              ix: 2,
              v: { a: 0, k: 358, ix: 2 }
            },
            {
              ty: 0,
              nm: "Thickness",
              mn: "CS LineSweep-0003",
              ix: 3,
              v: { a: 0, k: 50, ix: 3 }
            },
            {
              ty: 0,
              nm: "Slant",
              mn: "CS LineSweep-0004",
              ix: 4,
              v: { a: 0, k: 99, ix: 4 }
            },
            {
              ty: 7,
              nm: "Flip Direction",
              mn: "CS LineSweep-0005",
              ix: 5,
              v: { a: 0, k: 0, ix: 5 }
            }
          ]
        }
      ],
      ip: 8,
      op: 458,
      st: 8,
      bm: 0
    },
    {
      ddd: 0,
      ind: 24,
      ty: 2,
      nm: "bg1.png",
      cl: "svg",
      refId: "image_19",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [924, 71.5, 0], ix: 2 },
        a: { a: 0, k: [195, 58.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ef: [
        {
          ty: 5,
          nm: "CC Line Sweep",
          np: 7,
          mn: "CS LineSweep",
          ix: 1,
          en: 1,
          ef: [
            {
              ty: 0,
              nm: "Completion",
              mn: "CS LineSweep-0001",
              ix: 1,
              v: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 8,
                    s: [99]
                  },
                  { t: 14, s: [0] }
                ],
                ix: 1
              }
            },
            {
              ty: 0,
              nm: "Direction",
              mn: "CS LineSweep-0002",
              ix: 2,
              v: { a: 0, k: 1, ix: 2 }
            },
            {
              ty: 0,
              nm: "Thickness",
              mn: "CS LineSweep-0003",
              ix: 3,
              v: { a: 0, k: 50, ix: 3 }
            },
            {
              ty: 0,
              nm: "Slant",
              mn: "CS LineSweep-0004",
              ix: 4,
              v: { a: 0, k: 99, ix: 4 }
            },
            {
              ty: 7,
              nm: "Flip Direction",
              mn: "CS LineSweep-0005",
              ix: 5,
              v: { a: 0, k: 0, ix: 5 }
            }
          ]
        }
      ],
      ip: 8,
      op: 458,
      st: 8,
      bm: 0
    }
  ],
  markers: []
};
