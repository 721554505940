import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  .count {
    width: 20px;
    height: 20px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #EC9719;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.16);
    position: absolute;
    font-size: 14px;
    top: 8px;
    right: 2px;
    @media (max-width: 800px) {
      width: 16px;
      height: 16px;
      font-size: 12px;
    }
  }
`;

export default class extends React.PureComponent {
  constructor(props) {
    super(props);
    const filters = props.filterKvs.filter(item => item.filterKey === props.filterKey);
    this.state = {
      filters
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.filterKvs.length !== this.props.filterKvs.length) {
      const filters = nextProps.filterKvs.filter(item => item.filterKey === nextProps.filterKey);
      this.setState({
        filters
      });
    }
  }
  render () {
    const { filters } = this.state;
    const { label } = this.props;
    return (
      <Wrapper>
        <span>{label}</span>
        {filters.length > 0 && <div className="count">{filters.length}</div>}
      </Wrapper>
    )
  }
}
