import React, { Component } from 'react';
import styled from 'styled-components';
import { Button } from 'components/common';
import { TicketBg, BaggageIcon, AddIcon } from 'components/common/SvgIcons';
import Count from './Count';
import {
  addDecimal,
  numberToLocaleString,
  getItem,
} from 'utils/utils';

const Baggage = styled.div`
  width: 104px;
  height: 142px;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
`;

const Text = styled.span`
  cursor: pointer;
  &.active {
    color: var(--base-color);
  }
  &.price {
    width: 100%;
    text-align: center;
    font-size: 22px;
    color: #007AC7;
    margin-top: 10px;
  }
`;

export default class extends Component {
  state = {};
  handleChange = () => {
    if (this.props.disabled) {
      return;
    }
    if (this.props.isActive !== this.props.type) {
      this.props.handleTypeChange(this.props.type);
    }
    setTimeout(() => {
      this.props.handleIncrease();
    }, 300);
  };

  handleRemove = () => {
    // if (this.props.disabled) {
    //   return;
    // }
    if (this.props.isActive !== this.props.type) {
      this.props.handleTypeChange(this.props.type);
    }
    setTimeout(() => {
      this.props.handleRemove();
    }, 300);
  };
  render() {
    const {
      isActive,
      weight,
      disabled,
      baggage,
      activeType,
    } = this.props;
    const cost = addDecimal(
      baggage.serviceCostAmount,
      baggage.costDecimalPlaces,
    );

    const localizedCost = numberToLocaleString(cost, {
      selectedCountry: getItem('selectedLanguage'),
      selectedCurrency: baggage.serviceCostCurrencyIso,
    });

    return (
      <React.Fragment>
      <div style={{ position: 'relative', display: 'flex', flexFlow: 'column', opacity: disabled ? 0.3 : 1 }}>
        <TicketBg stroke={baggage.quantity > 0 ? '#D0F3FC' : '#fff'} />
        <Baggage>
          <Text
            onClick={this.handleChange}
            className={isActive ? 'active' : ''}
          >
            {weight}Kg
          </Text>
          <BaggageIcon />
          <div style={{ width: 72 }}>
            <Button
              style={{
                height: 32
              }}
              color="#F2CC56"
              disabled={disabled}
              onClick={this.handleChange}
            >
              <AddIcon />
            </Button>
          </div>
        </Baggage>
        <Text className="price">
          {localizedCost}
        </Text>
      </div>
      <Count
        type={baggage.id}
        activeType={activeType}
        cost={localizedCost}
        weight={baggage.baggagePieceMaxWeight}
        count={baggage.quantity}
        handleRemove={this.handleRemove}
      />
    </React.Fragment>
    );
  }
}
