import React from "react";
import styled from "styled-components";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FilledInput from "@material-ui/core/FilledInput";
import InputLabel from "@material-ui/core/InputLabel";

const StyledSelect = styled(FormControl)`
  margin-top: 16px !important;
  margin-bottom: 8px !important;
  .MuiFilledInput-root {
    background-color: ${props => props.theme.primaryBackground};
  }
  .MuiFilledInput-underline:before {
    border-bottom: 1px solid ${props => props.theme.secondary};
  }
  &.filled {
    .MuiFilledInput-root {
      background-color: rgba(0, 0, 0, 0.09);
    }
  }
  &.hasError {
    .MuiInputBase-root {
      background-color: #fbdddd;
      box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.08), inset 0px -1px 0px #eb5757 !important;
    }
    label {
      color: #eb5757;
    }
    .MuiFilledInput-underline:before {
      border-bottom-color: #eb5757;
    }
  }
`;
export default ({
  value,
  label,
  handleChange,
  menuItems,
  open,
  style = {},
  input,
  required,
  errorState,
  onOpen,
  onClose
}) => (
  <StyledSelect
    required={required}
    variant="filled"
    className={`${value ? "filled" : ""} ${errorState ? "hasError" : ""}`}
    style={style}
  >
    <InputLabel htmlFor="filled-age-simple">{label}</InputLabel>
    <Select
      open={open}
      value={value}
      onChange={handleChange}
      onOpen={onOpen}
      onClose={onClose}
      input={input ? input : <FilledInput name="age" id="filled-age-simple" />}
    >
      {menuItems}
    </Select>
  </StyledSelect>
);
