export default `
#
# Strona prywatności

#
# Wprowadzenie

Fly Strait OÜ, zarejestrowana w Tallinie w Estonii pod numerem rejestracyjnym 14373971 (&quot;my&quot;, &quot;nas&quot;, &quot;nasza&quot; lub &quot;Fly Strait&quot;) jest tak zwanym &quot;administratorem danych&quot; Państwa danych osobowych i dlatego jest odpowiedzialna za zgodność z prawem naszych danych osobowych.

Ta strona jest obsługiwana przez Fly Strait. Obsługujemy różne dane osobowe, takie jak imiona, adresy e-mail i inne informacje związane z podróżami i sprawami codziennymi. Dlatego bardzo poważnie podchodzimy do bezpieczeństwa danych i przestrzegania przepisów dotyczących ochrony danych. Niniejsza polityka prywatności wyjaśnia, w jaki sposób zbieramy, przechowujemy, wykorzystujemy i ujawniamy wszelkie dane osobowe, które gromadzimy o Was, kiedy korzystają Państwo z tej strony, a także w jaki sposób chronimy prywatność i poufność Państwa danych osobowych. Państwa prywatność jest dla nas ważna, więc niezależnie od tego, czy od niedawna korzystają Państwo z naszych usług, czy od dawna jesteście naszymi klientami poświęćcie prosimy trochę czasu, aby poznać nasze praktyki - i skontaktujcie się z nami, jeśli macie jakiekolwiek pytania.

#
# Dane osobowe, które gromadzimy

Ogólnie rzecz biorąc, dane osobowe, które zbieramy, są informacjami, których potrzebujemy, aby umożliwić Państwu dokonywanie rezerwacji i organizację podróży. Obejmuje to takie informacje, jak imię i nazwisko, data urodzenia, numer telefonu i adres e-mail. Dane osobowe, które musimy otrzymać, aby zapewnić Państwu rezerwację podróży za pośrednictwem naszych stron internetowych, są jedynymi danymi, które należy podać. W zależności od rodzaju usług turystycznych, z których korzystacie Państwo, możemy również potrzebować numer karty stałego pasażera, informacje o Państwa wymaganiach dietetycznych i kwestiach zdrowotnych (jeśli takie istnieją) oraz inne szczegóły, które są istotne dla ustaleń dotyczących Państwa podróży lub które są wymagane przez innego dostawcę usług turystycznych (np. linie lotnicze i hotele). Wykaz ten nie jest kompletny. Jeśli zadzwonicie Państwo do naszego biura obsługi klienta, zbierzemy dane, które możemy przekazać podczas rozmowy telefonicznej. Jak widać poniżej, nasze pliki cookie zbierają również pewne informacje.

Jeśli dokonacie Państwo rezerwacji dla kogoś innego za pośrednictwem naszej strony internetowej, poprosimy o dane osobowe dotyczące tej osoby. W takich okolicznościach polegamy na Was jeśli chodzi o przekazanie informacji o polityce prywatności tej osobie.

#
# Poufne dane osobowe, które gromadzimy

W niektórych przypadkach możemy obsługiwać tak zwane &quot;specjalne kategorie danych osobowych&quot;, które mogą być uważane za poufne. Dotyczy to na przykład sytuacji, gdy: (i) złożyli Państwo zaświadczenie lekarskie o skorzystaniu z ochrony odstąpienia od umowy lub zwrotu kosztów od linii lotniczej (ii) mają schorzenie lub problemy zdrowotne mające wpływ na podróż i które mogą wiązać się z koniecznością uzyskania pomocy lub uzyskania zgody lub (iii) złożyli Państwo wniosek ujawniający inne poufne dane osobowe o sobie.

Zanim przejmiemy Państwa poufne dane osobowe, wymagamy Państwa zgody, aby to zrobić. Dlatego prosimy o korzystanie z dedykowanych formularzy kontaktowych na naszych stronach internetowych w celu przesyłania poufnych danych. Formularze kontaktowe umożliwiają udzielenie nam zgody wymaganej na mocy obowiązującego prawa w zakresie ochrony danych. Zgodę taką można wycofać w każdej chwili. Nie zajmujemy się poufnymi danymi osobowymi, co do których nie mamy uprawnień do ich przetwarzania lub których nam nie dostarczono. Ograniczona liczba naszych pracowników będzie miała dostęp do Państwa poufnych danych osobowych, a po przetworzeniu wrażliwych danych zgodnie z Państwa żądaniem, usuniemy dane tak szybko, jak to możliwe.

#
# Co robimy z danymi osobowymi?

Aby móc przetwarzać Państwa dane osobowe, obowiązujące przepisy dotyczące ochrony danych obligują nas do posiadania tak zwanej &quot;podstawy prawnej&quot; dla każdego z naszych celów przetwarzania danych osobowych. Z tego powodu przygotowaliśmy poniższą tabelę, aby pokazać naszą podstawę prawną dla każdego z naszych celów.

| **Co robimy (nasze cele w zakresie przetwarzania Państwa danych osobowych)** | **Podstawa prawna** | **Czas przechowywania** |
| --- | --- | --- |
| Umożliwienie rezerwacji podróży i rezerwacji, o którą prosicie Państwo (tj. Rezerwacji usług turystycznych co do których jesteśmy pośrednikiem, a także świadczenie własnych usług). | Wykonanie naszej umowy z Państwem. Jeżeli dostarczyli nam Państwo poufne dane osobowe, zgoda jest podstawą prawną. | 3 lata od daty zakupu. Zgoda na poufne dane osobowe może zostać wycofana w dowolnym czasie. |
| Jeśli zdecydujecie się Państwo utworzyć konto użytkownika na naszej stronie internetowej, udostępnimy Wam to konto. Konto zawiera dostęp do Państwa informacji o Waszych poprzednich planach podróży i rezerwacjach. Przechowujemy również Państwa nazwę użytkownika i hasło. | Wykonanie naszej umowy z Państwem. | Państwa dane konta użytkownika, a także dane osobowe związane z wcześniejszymi ustaleniami podróży i rezerwacjami, będą przechowywane do momentu, aż zdecydujecie się Państwo anulować konto użytkownika za pośrednictwem naszej strony internetowej. Jeśli jednak nie zalogujecie się Państwo na swoje konto użytkownika przez 18 miesięcy, możemy usunąć takie konto i informacje w nim zawarte, o ile nie mamy innej podstawy prawnej do przechowywania. |
| Jeśli rozpoczeli Państwo proces rezerwacji, ale nie zakończyli zakupu, możemy wysłać Wam e-mail z linkiem do wyniku wyszukiwania lub do rozpoczętej rezerwacji, w zależności od tego, w którym momencie proces rezerwacji na stronie został przerwany. | Nasze uzasadnione interesy w prowadzeniu działalności i umożliwiają dokonanie zakupu bez konieczności ponownego wypełniania wszystkich informacji. Jeśli nie chcecie Państwo otrzymywać tych e-maili, możecie zrezygnować w dowolnym momencie z wiadomości e-mail. | 24 godziny od momentu zakończenia procesu rezerwacji. |
| Zanim rozpoczniecie Państwo planowanie podróży, dostarczymy Wam dodatkowe informacje i oferty związane z konkretnym planem podróży, takie jak możliwe dodatki, takie jak dodatkowy bagaż i inne informacje dotyczące podróży. Niektóre informacje oparte są na profilowaniu przeprowadzonym na informacjach podanych przez Was w trakcie procesu rezerwacji (na przykład daty podróży, miejsca docelowego itp.). | Naszym uzasadnionym interesem jest oferowanie wygodniejszych podróży i umożliwianie łatwego znajdowania dodatkowych informacji. Jeśli nie chcecie tych informacji, możecie zrezygnować w każdej chwili z wiadomości e-mail. | Nie dłużej niż do momentu rozpoczęcia podróży. Inne podobne przetwarzanie może być kontynuowane zgodnie z celami przedstawionymi poniżej. |
| Po uzgodnieniu dotyczącym podróży możemy dostarczyć Państwu nasz biuletyn zawierający zalecenia dotyczące innych ustaleń dotyczących podróży oraz produktów i usług związanych z podróżowaniem, które mogą Was zainteresować. Niektóre z zaleceń opierają się na profilowaniu Państwa wcześniejszych wyborów podczas rezerwacji wycieczki i reakcji na nasze e-maile. | Nasze uzasadnione interesy w prowadzeniu działalności gospodarczej i pozwalają na dokonywanie uzgodnień dotyczących podróży dla Waszego dobra. Jeśli nie chcecie otrzymywać tych e-maili, możecie zrezygnować w dowolnym momencie z wiadomości e-mail. Ponadto przed sfinalizowaniem zakupu można zgłosić sprzeciw wobec otrzymywania biuletynu. | 3 lata od daty zakupu. |
| Rozmowy telefoniczne są nagrywane w celu zapewnienia jakości i ewentualnych przyszłych zapytań lub wniosków. | Naszym uzasadnionym interesem jest (i) poprawa jakości naszych usług poprzez wewnętrzne szkolenia oraz, w stosownych przypadkach, (ii) rozwiązywanie wniosków lub skarg. Jeśli nie chcecie, aby rozmowa była nagrywana, można sprzeciwić się takiemu nagrywaniu przed rozpoczęciem. | 6 miesięcy od daty rozmowy telefonicznej. Tylko ograniczona liczba osób ma dostęp do waszego nagrania. |
| Korzystanie z plików cookie na przykład poprawia użyteczność tej strony internetowej, zapewnia spersonalizowaną obsługę i gromadzenie statystyk użytkowania. Używamy również plików cookie sesji w celu poprawy bezpieczeństwa tej witryny | Naszym uzasadnionym interesem jest (i) poprawienie naszej strony internetowej, (ii) przedstawienie interesujących was ofert oraz (iii) bezpieczeństwo oferty usług i strony internetowej. Jeśli nie chcecie, abyśmy zapisywali pliki cookie na waszym komputerze, można w dowolnej chwili zmienić ustawienia przeglądarki. | W zależności od rodzaju pliku cookie. [Poniżej znajdują się bardziej szczegółowe czasy przechowywania.](https://flystrait.com/privacy#Cookies) |

Oprócz tego codziennie podejmujemy działania, które są niezbędne dla przedsiębiorstw świadczących usługi dla konsumentów, takie jak prowadzenie ksiąg rachunkowych, rozliczanie, fakturowanie, wypełnianie zobowiązań w zakresie przeciwdziałania praniu pieniędzy i utrzymywanie bezpieczeństwa na naszej stronie internetowej. W zakresie, w jakim nie jest to obowiązkowe na mocy obowiązujących przepisów, podejmujemy te środki w oparciu o nasz uzasadniony interes. Możemy również analizować zachowanie naszych klientów w celu udoskonalenia naszych stron internetowych i usług na ogólnym poziomie. Jednak taka analiza będzie używać danych uogólnionych lub anonimowych na poziomie zbiorowego zestawiania.

#
#  Udostępnianie Państwa danych osobowych

Udostępniamy Państwa dane osobowe tylko wtedy, gdy będzie to konieczne do celów wymienionych w niniejszej polityce prywatności. Mogą to być inne firmy w obrębie Fly Strait, władze państwowe i zaufani partnerzy biznesowi. Na przykład możemy udostępniać dane osobowe (w tym poufne dane osobowe, o ile są potrzebne) partnerom biznesowym, takim jak linie lotnicze, dostawcy hoteli, firmy ubezpieczeniowe i globalne systemy dystrybucji (tzw. GDS), aby umożliwić organizację podróży i rezerwacje.

Każdy partner jest odpowiedzialny za własną obsługę danych osobowych po otrzymaniu go od nas, co oznacza, że ​​musicie skontaktować się z danym partnerem w sprawie wszelkich wniosków związanych z waszymi prawami zgodnie z obowiązującymi przepisami dotyczącymi ochrony danych. Zalecamy zapoznanie się z odpowiednimi przepisami polityki prywatności partnerów w celu uzyskania informacji na temat przetwarzania ich danych osobowych. Będziemy również udostępniać Państwa dane osobowe innym firmom (tzw. &quot;Procesorom danych&quot;) potrzebnym do świadczenia żądanych usług, takich jak dostawcy usług w naszych centrach obsługi telefonicznej oraz innymi dostawcami, którzy będą obsługiwać Państwa dane osobowe podczas świadczenia usług dla nas (na przykład pamięć zewnętrzna).

Ze względu na globalny charakter branży turystycznej Państwa dane osobowe mogą być przetwarzane w różnych lokalizacjach na całym świecie, gdy strony, którym udostępniamy Państwa dane osobowe, rezydują w kraju spoza UE / EOG. Nasze udostępnianie danych osobowych poza UE / EOG wymaga pewnych podstaw prawnych na podstawie obowiązujących przepisów dotyczących ochrony danych. Jeżeli Komisja Europejska uzna kraj za kraj o odpowiednim poziomie ochrony danych osobowych, będzie to nasza podstawa prawna. W przeciwnym razie istnieją trzy główne rodzaje podstaw prawnych, na podstawie których możemy oprzeć taki podział. Są to:

i. przekazanie jest niezbędne do realizacji umowy z Państwem (na przykład, gdy zarezerwowali Państwo lot z linią lotniczą rezydującą poza UE / EOG);

ii. że transfer będzie oparty na standardowych klauzulach ochrony danych dotyczących przekazywania danych osobowych do krajów spoza UE / EOG przyjętych przez Komisję Europejską (kopię tych standardowych klauzul ochrony danych można znaleźć na stronie http: //ec.europa .eu / justice / dataprotection / internationaltransfers / transfer /);

iii. Tarcza Prywatności UE-USA, w której przeniesienie jest dokonywane do Stanów Zjednoczonych, a odbiorca jest należycie poświadczony.

#
# Dostawcy zewnętrzni

Należy pamiętać, że nasza strona internetowa zawiera linki do innych stron internetowych i obsługuje treści od zewnętrznych dostawców. Niniejsza polityka prywatności dotyczy wyłącznie naszej strony internetowej i naszych usług. Kiedy podążacie Państwo za linkami do innych stron lub korzystasz z usług i produktów innych firm, powinniście się zapoznać się z ich polityką prywatności. Ponadto, jeśli zdecydujecie się skontaktować z nami za pośrednictwem mediów społecznościowych, niniejsza polityka prywatności nie dotyczy żadnych danych osobowych przesłanych przez Was w ramach takiego kontaktu - w takim przypadku zalecamy zapoznanie się z polityką prywatności takiego dostawcy mediów społecznościowych.

#
# Prawa przysługujące użytkownikowi

Zgodnie z obowiązującym prawodawstwem dotyczącym ochrony danych, masz określone prawa jako tzw. &quot;zbiór danych&quot;. Poniżej wymieniliśmy Wasze prawa. Państwa prawa obejmują:

i. Prawo dostępu - Macie Państwo prawo dostępu do danych osobowych, którymi się zajmujemy. Macie Państwo również prawo do otrzymania pewnych informacji o tym, co robimy z danymi osobowymi. Takie informacje znajdują się w tym dokumencie.

ii. Prawo do sprostowania - w pewnych okolicznościach macie prawo do poprawiania niedokładnych danych osobowych dotyczących Was oraz do uzupełnienia niekompletnych danych osobowych. Należy pamiętać, że możemy nie być w stanie skorygować niedokładnych danych osobowych przekazanych przez użytkownika, np. Z powodu przepisów linii lotniczych, oraz że taka konkretna zmiana może spowodować poniesienie kosztów.

iii. Prawo do usunięcia - w pewnych okolicznościach przysługuje Państwu prawo do usunięcia Waszych danych osobowych. Jest to tak zwane &quot;prawo do bycia zapomnianym&quot;.

iv. Prawo do ograniczenia przetwarzania - w pewnych okolicznościach macie Państwo prawo ograniczyć sposób korzystania z waszych danych osobowych.

v. Prawo do przenoszenia danych - Użytkownik jest uprawniony do otrzymywania od nas swoich danych osobowych (lub przekazania danych osobowych bezpośrednio do innego kontrolera danych) w postaci uporządkowanego, powszechnie używanego i możliwego do odczytu maszynowego.

vi. Prawo do sprzeciwu - Użytkownik ma prawo do zgłoszenia sprzeciwu wobec niektórych rodzajów przetwarzania danych osobowych, które realizujemy. Dotyczy to wszystkich naszych działań, które są oparte na naszym &quot;uzasadnionym interesie&quot;. Wreszcie, macie Państwo również prawo do złożenia skargi do właściwego organu nadzorującego ochronę danych.

#
# Pliki cookie

Cookie to mały plik tekstowy, który jest przechowywany na komputerze, niektóre tylko do momentu zamknięcia przeglądarki (tak zwane &quot;pliki cookie sesji&quot;), a niektóre przez dłuższy czas (tak zwane &quot;trwałe pliki cookie&quot;). Jeśli nie chcecie zezwalać na przechowywanie plików cookie na komputerze, można zmienić ustawienia w przeglądarce. Należy jednak pamiętać, że w niektórych przypadkach niektóre funkcje naszej strony internetowej mogą nie działać prawidłowo, a niektóre treści mogą nie być wyświetlane poprawnie.

Ta strona korzysta z plików cookie z wielu powodów, w tym w celu zapewnienia spersonalizowanej obsługi, w celu poprawy użyteczności tej witryny i gromadzenia statystyk użytkowania. Używamy również plików cookie sesji w celu poprawy bezpieczeństwa tej witryny.

W niektórych przypadkach podczas korzystania z plików cookie udostępniamy dane stronom trzecim. Na przykład używamy Google Analytics i Google AdWords, usług, które przesyłają dane o ruchu na stronie do serwerów Google. Google Analytics nie identyfikuje poszczególnych użytkowników i nie łączy Waszego adresu IP z żadnymi innymi danymi przechowywanymi przez Google. Korzystamy z raportów dostarczanych przez Google, które pomagają nam zrozumieć ruch w witrynie i korzystanie ze stron internetowych oraz optymalizować reklamy kupione przez firmę Google i inne sieci reklamowe. Google może przetwarzać dane w sposób opisany w [Polityce prywatności Google](https://policies.google.com/privacy) oraz w celach określonych powyżej w tej sekcji. Można zrezygnować z Google Analytics, jeśli odmówi się lub wyłączy obsługę plików cookie, wyłączy obsługę JavaScript lub skorzysta z usługi rezygnacji oferowanej przez Google. [Aby zrezygnować z funkcji reklamowych od Google, należy skorzystać z tego linku.](https://tools.google.com/dlpage/gaoptout)

Nasza strona korzysta również z Facebooka pixel, który zbiera anonimowe uwspólnione dane, które pomagają nam w optymalizacji zakupów reklam na różnych platformach Facebooka (w tym na Instagramie). Facebook zbiera identyfikator użytkownika, który pozwoli mu dopasować, jeśli użytkownik odwiedził witrynę z piksela Facebooka. My, jako reklamodawcy, możemy jednak nigdy nie zidentyfikować zachowania konkretnego użytkownika. Facebook i powiązane z nim platformy znajdują się w zamkniętym ekosystemie reklamowym, w którym mogą regulować ich użytkownicy, jeśli wyrażą zgodę na wykorzystanie przez reklamodawców danych zebranych z ich stron internetowych do zakupu reklam na Facebooku. [Aby wyświetlić i zmienić ustawienia reklam na Facebooku, proszę użyć tego linku.](https://facebook.com/ads/preferences)

Ponadto nasza strona internetowa wykorzystuje skrypty śledzenia konwersji i pliki cookie z witryny [Microsoft Bing](https://privacy.microsoft.com/), [TripAdvisor](https://www.smartertravel.com/privacy-policy)[i](https://tvsquared.com/privacy-policy/) TvSquared (można przeglądać ich politykę prywatności, korzystając z linków).

Wszystkie te usługi gromadzą uwspólnione dane statystyczne, które pomagają nam optymalizować zakupy reklam. My, jako reklamodawcy, nie możemy zidentyfikować pojedynczego użytkownika korzystającego z tych danych. Można wyłączyć korzystanie z plików cookie w ustawieniach przeglądarki.

[Więcej informacji na temat plików cookie można znaleźć na stronie www.youronlinechoices.com.](https://www.youronlinechoices.com/)

#
# Bezpieczeństwo danych

Aby zapewnić bezpieczeństwo danych osobowych, wdrożyliśmy szereg technicznych i organizacyjnych środków bezpieczeństwa. Na przykład utrzymujemy wysoki poziom bezpieczeństwa technicznego we wszystkich systemach (w tym identyfikowanie, odzyskiwanie po awarii, ograniczenia dostępu itp.). Ponadto przyjęliśmy zasady zapewniające, że nasi pracownicy (którzy oczywiście podlegają obowiązkowi poufności) nie wykorzystują danych osobowych, gdy nie jest to konieczne. Polityka ta określa również nasze standardy w przypadku, gdy zamawiamy dostawców lub wprowadzamy nowe systemy IT w ramach naszej działalności.

#
# Jak skontaktować się z nami

W przypadku jakichkolwiek pytań związanych z przetwarzaniem danych osobowych lub korzystaniem z plików cookie, lub w celu skorzystania z dowolnych praw wynikających z obowiązujących przepisów o ochronie danych, prosimy wysłać wiadomość e-mail na adres: privacy@flystrait.com

#
# Zmiany w polityce prywatności

W przypadku zmiany sposobu przetwarzania danych osobowych lub sposobu korzystania z plików cookie niezwłocznie zaktualizujemy niniejszą politykę prywatności i opublikujemy ją w tej witrynie.
`;
