export default {
  v: "5.6.5",
  fr: 30,
  ip: 339,
  op: 367,
  w: 1000,
  h: 720,
  nm: "Feature-3 in",
  ddd: 0,
  assets: [
    {
      id: "image_0",
      w: 59,
      h: 39,
      u: "/static/ae-images/Feature-3/images/",
      p: "img_0.svg",
      e: 0
    },
    {
      id: "image_1",
      w: 85,
      h: 55,
      u: "/static/ae-images/Feature-3/images/",
      p: "img_1.svg",
      e: 0
    },
    {
      id: "image_2",
      w: 60,
      h: 39,
      u: "/static/ae-images/Feature-3/images/",
      p: "img_2.svg",
      e: 0
    },
    {
      id: "image_3",
      w: 348,
      h: 318,
      u: "/static/ae-images/Feature-3/images/",
      p: "img_3.svg",
      e: 0
    },
    {
      id: "image_4",
      w: 348,
      h: 108,
      u: "/static/ae-images/Feature-3/images/",
      p: "img_4.svg",
      e: 0
    },
    {
      id: "image_5",
      w: 889,
      h: 344,
      u: "/static/ae-images/Feature-3/images/",
      p: "img_5.svg",
      e: 0
    },
    {
      id: "image_6",
      w: 27,
      h: 27,
      u: "/static/ae-images/Feature-3/images/",
      p: "img_6.svg",
      e: 0
    },
    {
      id: "image_7",
      w: 27,
      h: 27,
      u: "/static/ae-images/Feature-3/images/",
      p: "img_7.svg",
      e: 0
    },
    {
      id: "image_8",
      w: 35,
      h: 23,
      u: "/static/ae-images/Feature-3/images/",
      p: "img_8.svg",
      e: 0
    },
    {
      id: "image_9",
      w: 33,
      h: 27,
      u: "/static/ae-images/Feature-3/images/",
      p: "img_9.svg",
      e: 0
    },
    {
      id: "image_10",
      w: 32,
      h: 31,
      u: "/static/ae-images/Feature-3/images/",
      p: "img_10.svg",
      e: 0
    },
    {
      id: "image_11",
      w: 27,
      h: 27,
      u: "/static/ae-images/Feature-3/images/",
      p: "img_11.svg",
      e: 0
    }
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 2,
      nm: "облако3.png",
      cl: "svg",
      refId: "image_0",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 369,
              s: [100]
            },
            { t: 377, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [206, 388, 0], ix: 2 },
        a: { a: 0, k: [29.5, 19.5, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0, 0, 0.667], y: [1, 1, 1] },
              o: { x: [0, 0, 0.333], y: [0, 0, 0] },
              t: 341,
              s: [0, 0, 100]
            },
            { t: 355, s: [100, 100, 100] }
          ],
          ix: 6
        }
      },
      ao: 0,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 2,
      nm: "облако3.png",
      cl: "svg",
      refId: "image_0",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 369,
              s: [100]
            },
            { t: 377, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [666, 301, 0], ix: 2 },
        a: { a: 0, k: [29.5, 19.5, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0, 0, 0.667], y: [1, 1, 1] },
              o: { x: [0, 0, 0.333], y: [0, 0, 0] },
              t: 341,
              s: [0, 0, 100]
            },
            { t: 355, s: [100, 100, 100] }
          ],
          ix: 6
        }
      },
      ao: 0,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 2,
      nm: "облако3.png",
      cl: "svg",
      refId: "image_0",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 369,
              s: [100]
            },
            { t: 377, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [792, 368.5, 0], ix: 2 },
        a: { a: 0, k: [29.5, 19.5, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0, 0, 0.667], y: [1, 1, 1] },
              o: { x: [0, 0, 0.333], y: [0, 0, 0] },
              t: 341,
              s: [0, 0, 100]
            },
            { t: 355, s: [100, 100, 100] }
          ],
          ix: 6
        }
      },
      ao: 0,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 4,
      ty: 2,
      nm: "облако2.png",
      cl: "svg",
      refId: "image_1",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 369,
              s: [100]
            },
            { t: 377, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [351, 180, 0], ix: 2 },
        a: { a: 0, k: [42.5, 27.5, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0, 0, 0.667], y: [1, 1, 1] },
              o: { x: [0, 0, 0.333], y: [0, 0, 0] },
              t: 341,
              s: [0, 0, 100]
            },
            { t: 355, s: [100, 100, 100] }
          ],
          ix: 6
        }
      },
      ao: 0,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 5,
      ty: 2,
      nm: "облако.png",
      cl: "svg",
      refId: "image_2",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 369,
              s: [100]
            },
            { t: 377, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [290, 268.5, 0], ix: 2 },
        a: { a: 0, k: [30, 19.5, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0, 0, 0.667], y: [1, 1, 1] },
              o: { x: [0, 0, 0.333], y: [0, 0, 0] },
              t: 341,
              s: [0, 0, 100]
            },
            { t: 355, s: [100, 100, 100] }
          ],
          ix: 6
        }
      },
      ao: 0,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 6,
      ty: 2,
      nm: "woman.png",
      cl: "svg",
      refId: "image_3",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 341,
              s: [0]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 355,
              s: [100]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 369,
              s: [100]
            },
            { t: 377, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [489, 374, 0], ix: 2 },
        a: { a: 0, k: [174, 159, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 7,
      ty: 2,
      nm: "Vector.png",
      cl: "svg",
      refId: "image_4",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 349,
              s: [0]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 358,
              s: [100]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 369,
              s: [100]
            },
            { t: 377, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0, y: 1 },
              o: { x: 0, y: 0 },
              t: 349,
              s: [489, 509, 0],
              to: [-0.167, 15, 0],
              ti: [0.167, -15, 0]
            },
            { t: 365, s: [488, 599, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [174, 54, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 8,
      ty: 2,
      nm: "Vector.png",
      cl: "svg",
      refId: "image_4",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 341,
              s: [0]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 350,
              s: [100]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 369,
              s: [100]
            },
            { t: 377, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0, y: 1 },
              o: { x: 0, y: 0 },
              t: 341,
              s: [489, 421, 0],
              to: [-0.167, 15, 0],
              ti: [0.167, -15, 0]
            },
            { t: 357, s: [488, 511, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [174, 54, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 9,
      ty: 2,
      nm: "дома.png",
      cl: "svg",
      refId: "image_5",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 369,
              s: [100]
            },
            { t: 377, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [505, 484, 0], ix: 2 },
        a: { a: 0, k: [444.5, 172, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0, 0, 0.667], y: [1, 1, 1] },
              o: { x: [0, 0, 0.333], y: [0, 0, 0] },
              t: 341,
              s: [0, 0, 100]
            },
            { t: 355, s: [100, 100, 100] }
          ],
          ix: 6
        }
      },
      ao: 0,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 10,
      ty: 2,
      nm: "Group 24.png",
      cl: "svg",
      refId: "image_6",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 341,
              s: [0]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 350,
              s: [100]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 369,
              s: [100]
            },
            { t: 377, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0, y: 1 },
              o: { x: 0, y: 0 },
              t: 341,
              s: [344.5, 287.5, 0],
              to: [-9.583, -11.25, 0],
              ti: [9.583, 11.25, 0]
            },
            { t: 357, s: [287, 220, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [13.5, 13.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 11,
      ty: 2,
      nm: "Vector (5).png",
      cl: "svg",
      refId: "image_7",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 341,
              s: [0]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 350,
              s: [100]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 369,
              s: [100]
            },
            { t: 377, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0, y: 1 },
              o: { x: 0, y: 0 },
              t: 341,
              s: [407, 203, 0],
              to: [-0.333, -11.833, 0],
              ti: [0.333, 11.833, 0]
            },
            { t: 357, s: [405, 132, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [13.5, 13.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 12,
      ty: 2,
      nm: "Vector (3).png",
      cl: "svg",
      refId: "image_8",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 341,
              s: [0]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 350,
              s: [100]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 369,
              s: [100]
            },
            { t: 377, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0, y: 1 },
              o: { x: 0, y: 0 },
              t: 341,
              s: [445.5, 262, 0],
              to: [7.083, -12.5, 0],
              ti: [-7.083, 12.5, 0]
            },
            { t: 357, s: [488, 187, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [17.5, 11.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 13,
      ty: 2,
      nm: "Vector (4).png",
      cl: "svg",
      refId: "image_9",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 341,
              s: [0]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 350,
              s: [100]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 369,
              s: [100]
            },
            { t: 377, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0, y: 1 },
              o: { x: 0, y: 0 },
              t: 341,
              s: [579, 220, 0],
              to: [7.083, -12.5, 0],
              ti: [-7.083, 12.5, 0]
            },
            { t: 357, s: [621.5, 145, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [16.5, 13.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 14,
      ty: 2,
      nm: "Vector (2).png",
      cl: "svg",
      refId: "image_10",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 341,
              s: [0]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 350,
              s: [100]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 369,
              s: [100]
            },
            { t: 377, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0, y: 1 },
              o: { x: 0, y: 0 },
              t: 341,
              s: [635.5, 301, 0],
              to: [7.083, -12.5, 0],
              ti: [-7.083, 12.5, 0]
            },
            { t: 357, s: [678, 226, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [16, 15.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 15,
      ty: 2,
      nm: "Vector (1).png",
      cl: "svg",
      refId: "image_11",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 341,
              s: [0]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 350,
              s: [100]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 369,
              s: [100]
            },
            { t: 377, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0, y: 1 },
              o: { x: 0, y: 0 },
              t: 341,
              s: [541.5, 361.5, 0],
              to: [7.083, -12.5, 0],
              ti: [-7.083, 12.5, 0]
            },
            { t: 357, s: [584, 286.5, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [13.5, 13.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0
    }
  ],
  markers: []
};
