export default {
  v: "5.6.5",
  fr: 30,
  ip: 373,
  op: 390,
  w: 1000,
  h: 720,
  nm: "Feature-4 in",
  ddd: 0,
  assets: [
    {
      id: "image_0",
      w: 164,
      h: 179,
      u: "/static/ae-images/Feature-4/images/",
      p: "img_0.svg",
      e: 0
    },
    {
      id: "image_1",
      w: 318,
      h: 176,
      u: "/static/ae-images/Feature-4/images/",
      p: "img_1.svg",
      e: 0
    },
    {
      id: "image_2",
      w: 318,
      h: 176,
      u: "/static/ae-images/Feature-4/images/",
      p: "img_2.svg",
      e: 0
    },
    {
      id: "image_3",
      w: 76,
      h: 54,
      u: "/static/ae-images/Feature-4/images/",
      p: "img_3.svg",
      e: 0
    },
    {
      id: "image_4",
      w: 76,
      h: 60,
      u: "/static/ae-images/Feature-4/images/",
      p: "img_4.svg",
      e: 0
    },
    {
      id: "image_5",
      w: 758,
      h: 569,
      u: "/static/ae-images/Feature-4/images/",
      p: "img_5.svg",
      e: 0
    },
    {
      id: "image_6",
      w: 190,
      h: 391,
      u: "/static/ae-images/Feature-4/images/",
      p: "img_6.svg",
      e: 0
    },
    {
      id: "image_7",
      w: 244,
      h: 233,
      u: "/static/ae-images/Feature-4/images/",
      p: "img_7.svg",
      e: 0
    }
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 2,
      nm: "man.png",
      cl: "svg",
      refId: "image_0",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 374,
              s: [0]
            },
            { t: 383, s: [100] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0, y: 1 },
              o: { x: 0, y: 0 },
              t: 374,
              s: [659.7, 614, 0],
              to: [23.5, -0.167, 0],
              ti: [-23.5, 0.167, 0]
            },
            { t: 390, s: [800.7, 613, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [82, 89.5, 0], ix: 1 },
        s: { a: 0, k: [90, 90, 100], ix: 6 }
      },
      ao: 0,
      ip: 3,
      op: 453,
      st: 3,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 2,
      nm: "графики 1.png",
      cl: "svg",
      refId: "image_1",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [578, 543, 0], ix: 2 },
        a: { a: 0, k: [159, 88, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0, 0, 0.667], y: [1, 1, 1] },
              o: { x: [0, 0, 0.333], y: [0, 0, 0] },
              t: 374,
              s: [0, 0, 100]
            },
            { t: 388, s: [90, 90, 100] }
          ],
          ix: 6
        }
      },
      ao: 0,
      ip: 3,
      op: 453,
      st: 3,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 2,
      nm: "графики 2.png",
      cl: "svg",
      refId: "image_2",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [578, 348, 0], ix: 2 },
        a: { a: 0, k: [159, 88, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0, 0, 0.667], y: [1, 1, 1] },
              o: { x: [0, 0, 0.333], y: [0, 0, 0] },
              t: 374,
              s: [0, 0, 100]
            },
            { t: 388, s: [90, 90, 100] }
          ],
          ix: 6
        }
      },
      ao: 0,
      ip: 3,
      op: 453,
      st: 3,
      bm: 0
    },
    {
      ddd: 0,
      ind: 4,
      ty: 2,
      nm: "карта.png",
      cl: "svg",
      refId: "image_3",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [316, 542, 0], ix: 2 },
        a: { a: 0, k: [38, 27, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0, 0, 0.667], y: [1, 1, 1] },
              o: { x: [0, 0, 0.333], y: [0, 0, 0] },
              t: 374,
              s: [0, 0, 100]
            },
            { t: 388, s: [90, 90, 100] }
          ],
          ix: 6
        }
      },
      ao: 0,
      ip: 3,
      op: 453,
      st: 3,
      bm: 0
    },
    {
      ddd: 0,
      ind: 5,
      ty: 2,
      nm: "деньги.png",
      cl: "svg",
      refId: "image_4",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [315.5, 349, 0], ix: 2 },
        a: { a: 0, k: [38, 30, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0, 0, 0.667], y: [1, 1, 1] },
              o: { x: [0, 0, 0.333], y: [0, 0, 0] },
              t: 374,
              s: [0, 0, 100]
            },
            { t: 388, s: [90, 90, 100] }
          ],
          ix: 6
        }
      },
      ao: 0,
      ip: 3,
      op: 453,
      st: 3,
      bm: 0
    },
    {
      ddd: 0,
      ind: 6,
      ty: 2,
      nm: "Group 29.png",
      cl: "svg",
      refId: "image_5",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 371,
              s: [0]
            },
            { t: 385, s: [100] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [548.4, 419, 0], ix: 2 },
        a: { a: 0, k: [379, 284.5, 0], ix: 1 },
        s: { a: 0, k: [90, 90, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 7,
      ty: 2,
      nm: "лист.png",
      cl: "svg",
      refId: "image_6",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [214, 495, 0], ix: 2 },
        a: { a: 0, k: [95, 195.5, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0, 0, 0.667], y: [1, 1, 1] },
              o: { x: [0, 0, 0.333], y: [0, 0, 0] },
              t: 374,
              s: [0, 0, 100]
            },
            { t: 388, s: [90, 90, 100] }
          ],
          ix: 6
        }
      },
      ao: 0,
      ip: 3,
      op: 453,
      st: 3,
      bm: 0
    },
    {
      ddd: 0,
      ind: 8,
      ty: 2,
      nm: "лист2.png",
      cl: "svg",
      refId: "image_7",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [848.5, 195, 0], ix: 2 },
        a: { a: 0, k: [122, 116.5, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0, 0, 0.667], y: [1, 1, 1] },
              o: { x: [0, 0, 0.333], y: [0, 0, 0] },
              t: 374,
              s: [0, 0, 100]
            },
            { t: 388, s: [90, 90, 100] }
          ],
          ix: 6
        }
      },
      ao: 0,
      ip: 3,
      op: 453,
      st: 3,
      bm: 0
    }
  ],
  markers: []
};
