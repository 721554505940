import React from "react";
import styled from "styled-components";
import { IconCheckCircle } from "components/common/SvgIcons";

const Wrapper = styled.div`
  background-color: #d0f3fc;
  width: 100%;
  border-radius: 10px;
  padding: 4px;
  display: flex;
  .option {
    cursor: pointer;
    width: 50%;
    height: 64px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-around;
    text-transform: uppercase;
    color: #007ac7;
    div {
      display: flex;
      align-items: center;
      svg {
        margin-right: 10px;
      }
      span {
        font-family: "Roboto Condensed", sans-serif;
      }
    }
    &.active {
      background-color: #fff;
      border-radius: 10px;
      font-weight: 600;
      color: #222222;
    }
  }
`;

export default class extends React.PureComponent {
  handleOptionChange = (option) => {
    this.props.handleOptionChange(option);
  };
  render() {
    const { items, active, hideCheck } = this.props;
    return (
      <Wrapper>
        {items.map((item, i) => (
          <div
            key={i}
            className={`option ${item.name === active ? "active" : ""}`}
            onClick={() => this.handleOptionChange(item.name)}
          >
            <item.icon stroke={item.name === active ? "#222222" : "#007AC7"} />
            <div>
              {item.name === active && !hideCheck && (
                <IconCheckCircle stroke="#222222" style={{ width: 14 }} />
              )}
              <span>{item.label}</span>
            </div>
          </div>
        ))}
      </Wrapper>
    );
  }
}
