import gql from 'graphql-tag';

export const getTripItems = gql`query tripItems(
  $airlinePnr: String,
  $lastName: String,
  $tripownerId: String
) {
  tripItems(
    airlinePnr: $airlinePnr,
    lastName: $lastName,
    tripownerId: $tripownerId
  ) {
    eticket,
    id,
    hopRequests {
      id
      hopSeqNum,
      adultCount,
      childCount,
      infantCount,
      stdDate,
      fromAirportIc,
      toAirportIc,
      stopCount,
      hopSegmentControls {
        id,
        airlinePnr,
        bcUndefined,
        seatClass,
        cancellationPenalty,
        dateChangePenalty,
        seatChoice,
        baggagePieceCount,
        baggagePieceMaxWeight,
        baggagePieceImperial,
        flightSegments {
          airlineIc,
          flightNum,
          fromAirportIc,
          stdDate,
          stdTime,
          toAirportIc,
          staDate,
          staTime,
          aircraftIcao,
          durationMins,
          toTerminal,
          fromTerminal
        }
      },
      passengers {
        id,
        title,
        firstName,
        lastName,
        birthdate,
        gender,
        nationality,
        passportCountry,
        passportExpdate,
        passportNumber,
        cardHolder
        extraBaggages {
          id
          baggagePieceCount
          baggagePieceMaxWeight
        }
      }
    },
    tripCostItems {
        cost,
        costItemLabel,
        costItemCurrencyIso,
        costDecimalPlaces
      }
  }
}`;
