const isLive = process.env.REACT_APP_ENV === "live";

export default {
  auth: {
    client_id: "fsWeb",
    grant_type: "authorization_code",
    redirect_uri: isLive
      ? "https://cb.flystrait.com/callback"
      : "https://devcb.flystrait.com/callback",
    url: isLive
      ? "https://auth.flystrait.com"
      : "https://devauth.flystrait.com",
  },
  frontUrl: isLive ? "https://www.flystrait.com" : "http://dev2.flystrait.com",
  // frontUrl: "https://dev.flystrait.com",
  graphqlUrl: isLive
    ? "https://api.flystrait.com/graphql"
    : "https://devapi.flystrait.com/graphql",
  ipGeoKey: "c6f9c9b80f25494db55cf0054de1593e",
  ipGeoUrl: "https://api.ipgeolocation.io",
  holidayUrl: isLive
    ? "https://hol.flystrait.com"
    : "https://devhol.flystrait.com",
  paymentHost: isLive
    ? "https://pay.flystrait.com"
    : "https://devpay.flystrait.com",
  // tipFrontUrl:
  //   process.env.NODE_ENV === "development"
  //     ? "dev2.flystrait.com:8443"
  //     : "dev.flystrait.com",
  tipFrontUrl: "dev.flystrait.com",
  smRelayUrl:
    process.env.NODE_ENV === "development"
      ? "https://devsmrelay.flystrait.com"
      : "https://devsmrelay.flystrait.com",
  mapApiKey: "AIzaSyAd2j3IzW1peGUB_FY__JDRk31usdqtTdg",
  helpdesk: {
    url: "https://helpdesk.flystrait.com/api/v1/tickets/create",
    accesstoken: "f7797faf6cb2d25e9de36475989331d0278bf4a8",
    group: "5d9b56994a181a659d00a4d0",
    type: "5d9b5008477c1965170d7f67",
    priority: "5d9b50124a181a659d00a4c5",
  },
  tipGraphqlUrl: "https://fstip.herokuapp.com/v1/graphql",
  hotelGraphqlUrl: "https://devapi.flystrait.com/hotel/graphql",
  hotelImagePathPrefix: "https://photos.hotelbeds.com/giata/original/",
  cloudinaryUnisgnedUploadPreset: "e397soml",
  cloudinaryApiKey: "648938843957527",
  cloudinarySecret: "_MTtUGHGCWKNX94PTki_VJqn9mA",
  cloudinaryApiUrl: "https://api.cloudinary.com/v1_1/flystrait/upload",
  cloudinaryEnvVar:
    "CLOUDINARY_URL=cloudinary://648938843957527:_MTtUGHGCWKNX94PTki_VJqn9mA@flystrait",
  twitterConsumerKey: "mkXFeQs0RFGXBf68MQR4KgGGg",
  twitterAccessToken: "716316714-Edynwl19O7qBVL0UjwvRbYBRlsmLrVp5FgcGBgCY",
  twitterConsumerSecret: "aXMM9di7HIm1GzZPGyz3jXJeXnNqIsTH6afsyrc8pe1NeVxDWe",
  twitterAccessTokenSecret: "EHXOYs99KMZhAdNHSDin1ggg7LZf5erl8th1MtuEPHObd",
  facebookAppId: "456188761673331",
  gaId: "UA-162704704-1",
  imageSizeLimit: 10000000,
  encryptoPublicKey:
    "LS0tLS1CRUdJTiBQVUJMSUMgS0VZLS0tLS0KTUlJQklqQU5CZ2txaGtpRzl3MEJBUUVGQUFPQ0FROEFNSUlCQ2dLQ0FRRUFtM0c3c3ZrbjdiMVpUVUNBOHNUeQpKQ28wcE9zR21JWCtKWW5QNGkzbmR1MGlLS3h1MVBWVitRSFZnbDVIMmhJN3crMnpPN3dFVnpHc1lCdjFUNEY4CjlGWlI1blNUa3dYZnl1b0ltMlAwckxrVUZYU0o2Tnk0VmxYZ0s3UmoxRVR6Tm42UUlkeXBhM0pRN2QreGF3N1AKdStvakF0UWU1cXdWT3owaG5XUnRFYVRCUVZFUXBJNUxFUHRMM2hPdm5DWTZDYUF1UUlDNjVFaUZ1R0JDSTl5TgpGNVZwL1JpZFBRK2wwZVNNdmJORzZwV2NTaUlMd05MSU9qUk5sWFQvT1NLMHRTekxPZTE2NVVwUkN2MHhWUUZiCkxNd3FQUXhLV0dtSFh6b3g4Y1JVRHUxYXhEVDJ6NzFkY3JNOTJReFEzNDl6VkNmR0tRckNIL09xUlZjYmlJQVkKaFFJREFRQUIKLS0tLS1FTkQgUFVCTElDIEtFWS0tLS0tCg==",
};
