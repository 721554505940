export default `
#
# Privacy

#
# Inleiding

Fly Strait OÜ, geregistreerd in Tallinn, Estland met registratienummer 14373971 (&quot;wij&quot;, &quot;ons&quot;, &quot;onze&quot; of gewoon &quot;Fly Strait&quot;) is de zogenaamde &quot;gegevensbeheerder&quot; van uw persoonlijke gegevens en is daarom verantwoordelijk voor de rechtmatigheid van wat we doen met uw persoonlijke gegevens.

Deze website wordt beheerd door Fly Strait. Wij verwerken een verscheidenheid aan persoonlijke gegevens, zoals namen, e-mailadressen en andere reisgerelateerde informatie in hun dagelijkse activiteiten. Daarom nemen wij gegevensbeveiliging en de naleving van de wetgeving inzake gegevensbescherming zeer serieus. In dit privacybeleid wordt uitgelegd hoe we persoonlijke gegevens verzamelen, opslaan, gebruiken en openbaar maken wanneer u deze website gebruikt, en hoe wij de privacy en vertrouwelijkheid van uw persoonlijke gegevens beschermen. Uw privacy is belangrijk voor ons, dus of u nieuw bent bij onze dienst of deze al langere tijd gebruikt, neem dan de tijd om onze methopden te leren kennen – en neem contact met ons op als u vragen heeft.

#
# De persoonlijke gegevens die we verzamelen

Over het algemeen is het type persoonlijke gegevens dat we verzamelen de informatie die we nodig hebben om u in staat te stellen uw reisarrangementen en boekingen te maken. Dit omvat informatie zoals uw voor- en achternaam, geboortedatum, telefoonnummer en e-mailadres. De persoonlijke gegevens die we moeten ontvangen om u te voorzien van het reisarrangement dat u via onze websites heeft geboekt, zijn de enige gegevens die verplicht zijn om te verstrekken. Afhankelijk van het soort reisdiensten dat u gebruikt, kunnen we ook uw frequent flyer-nummer verzamelen, informatie over uw dieeteisen en gezondheidsproblemen (indien aanwezig) en andere details die relevant zijn voor uw reisarrangementen of die vereist zijn door een andere reisdienstverlener (zoals luchtvaartmaatschappijen en hotels). Deze opsomming is niet limitatief. Als u onze ondersteuning belt, verzamelen wij de gegevens die u tijdens het telefoongesprek verstrekt. Zoals u hieronder ziet, verzamelen onze cookies ook wat gegevens.

Als u voor iemand anders een reservering doet via onze website, dan vragen wij om de persoonlijke gegevens van deze persoon. In die omstandigheden vertrouwen wij erop dat u deze personen adviseert over dit privacybeleid.

#
# De gevoelige persoonlijke gegevens die we verzamelen

In sommige gevallen kunnen we zogenaamde &quot;speciale categorieën persoonlijke gegevens&quot; over u behandelen, die als gevoelig kunnen worden beschouwd. Dit zou bijvoorbeeld het geval kunnen zijn als u (i) een medische verklaring heeft ingediend voor het gebruik van een annuleringsverzekering of een terugbetaling van een luchtvaartmaatschappij (ii) een medische of gezondheidstoestand heeft die invloed heeft op uw reis en waarvoor u assistentie vraagt of indien bepaalde toestemming nodig is, of (iii) een verzoek heeft gedaan waarin enkele andere gevoelige persoonlijke gegevens over u zijn vrijgegeven.

Voordat we gevoelige persoonlijke gegevens over u behandelen, hebben wij uw toestemming daarvoor nodig. Daarom verzoeken wij u de speciale contactformulieren op onze websites te gebruiken voor het indienen van gevoelige gegevens. Met de contactformulieren kunt u ons de toestemming geven die vereist is op grond van de toepasselijke wetgeving inzake gegevensbescherming. Deze toestemming kan uiteraard te allen tijde worden ingetrokken. We behandelen geen gevoelige persoonlijke gegevens die wij niet mogen behandelen, of die u ons niet heeftt gegeven. Een beperkt aantal van onze medewerkers heeft toegang tot uw gevoelige persoonlijke gegevens en na behandeling van uw gevoelige gegevens in overeenstemming met uw verzoek, zullen we de gegevens zo snel mogelijk wissen.

#
# Wat we doen met uw persoonlijke gegevens

Om te kunnen omgaan met uw persoonlijke gegevens, verplichten de toepasselijke wetgevingen inzake gegevensbescherming ons om een ​​zogenaamde &quot;wettelijke basis&quot; te hebben voor elk van onze doeleinden om uw persoonlijke gegevens te verwerken. Om deze reden hebben we de onderstaande tabel opgesteld om onze wettelijke basis voor elk van onze doelen te tonen.

| **Wat we doen (onze doeleinden met betrekking tot de verwerking van uw persoonlijke gegevens)** | **Juridische grondslag** | **Opslagduur** |
| --- | --- | --- |
| Maakt de reisarrangementen en boekingen mogelijk die u bij ons hebt aangevraagd (dwz boeking van door ons bemiddelde reisdiensten, evenals het leveren van onze eigen diensten). | Uitvoering van ons contract met u. Waar u ons gevoelige persoonsgegevens hebt verstrekt, is toestemming de wettelijke basis. | 3 jaar vanaf de datum van aankoop. Toestemming voor gevoelige persoonlijke gegevens kan te allen tijde worden ingetrokken. |
| Als u ervoor heeft gekozen om een ​​gebruikersaccount op onze website aan te maken, zullen wij een dergelijk account voor u beschikbaar maken. Het account bevat toegang tot informatie over uw vorige reisarrangementen en boekingen. We slaan ook uw gebruikersnaam en wachtwoord op. | Uitvoering van ons contract met u. | Uw gebruikersaccountgegevens, evenals persoonlijke informatie met betrekking tot uw vorige reisarrangementen en boekingen, worden opgeslagen totdat u besluit om uw gebruikersaccount via onze website te annuleren. Als u zich echter 18 maanden niet hebt aangemeld bij uw gebruikersaccount, kunnen we een dergelijke account en bijbehorende informatie wissen, omdat we geen andere juridische basis hebben voor opslag. |
| Als u een reserveringsproces bent begonnen maar de aankoop niet hebt voltooid, kunnen we u een e-mail sturen met een link naar het zoekresultaat of naar de begonnen boeking, afhankelijk van het moment waarop uw reserveringsproces op de website werd beëindigd. | Ons legitieme belang is om zaken te doen en u in staat te stellen uw aankoop te voltooien zonder alle informatie opnieuw in te vullen. Als u deze e-mails niet wenst, kunt u zich op elk moment in de e-mail afmelden. | 24 uur vanaf het moment dat u het boekingsproces verliet. |
| Voordat uw reisarrangement van start gaat, zullen wij u aanvullende informatie en aanbiedingen met betrekking tot uw specifieke reisarrangement verstrekken, zoals mogelijke extra&#39;s zoals extra bagage en andere gebruiksinformatie voor uw reis. Een deel van de informatie is gebaseerd op profilering die is uitgevoerd op de informatie die u tijdens het boekingsproces heeft verstrekt (bijvoorbeeld de datum (s) waarop u reist, uw bestemming, enz.). | Ons legitieme belang is om u een betere reiservaring aan te bieden en om u in staat te stellen eenvoudig meer informatie te vinden die voor u relevant is. Als u deze informatie niet wenst, kunt u zich op elk moment in de e-mail afmelden. | Niet langer dan tot uw reisarrangement begint. Andere soortgelijke bewerkingen kunnen worden voortgezet in overeenstemming met de hieronder uiteengezette doeleinden. |
| Na uw reisarrangement kunnen we u onze nieuwsbrief sturen met aanbevelingen over andere reisarrangementen en reisgerelateerde producten en diensten die mogelijk interessant voor u zijn. Sommige van de aanbevelingen zijn gebaseerd op profilering van uw eerdere keuzes bij het boeken van een reis en uw gedrag met betrekking tot onze e-mails. | Ons legitiem belang is om zaken te doen en u in staat te stellen reisarrangementen te maken die voor u interessant zijn. Als u deze e-mails niet wenst, kunt u dit op elk gewenst moment annuleren via de e-mail of op onze website. Voordat u een aankoop voltooit, kunt u bezwaar maken tegen het ontvangen van de nieuwsbrief. | 3 jaar vanaf de datum van aankoop. |
| De opname van telefoongesprekken is voor kwaliteitsborging en voor toekomstige verzoeken of vragen van u. | Ons legitieme belang is om (i) onze service te verbeteren door intern onderwijs, en waar van toepassing (ii) uw potentiële verzoeken of claims op te lossen. Als u niet wilt dat het telefoongesprek wordt opgenomen, kunt u bezwaar maken tegen dergelijke opnames voordat de opname start. | 6 maanden vanaf de datum van het telefoongesprek. Slechts een beperkt aantal personen heeft toegang tot uw opname. |
| Gebruik van cookies om bijvoorbeeld de bruikbaarheid van deze website te verbeteren, een gepersonaliseerde ervaring te bieden en gebruiksstatistieken te verzamelen. We gebruiken ook sessiecookies om de beveiliging van deze website te verbeteren | Ons legitieme belang is om (i) onze website te verbeteren, (ii) u interessante aanbiedingen te tonen, en (iii) een beveiligd serviceaanbod en een website te hebben. Als u niet wilt dat wij cookies op uw computer opslaan, kunt u de instellingen in uw browser op elk gewenst moment wijzigen. | Afhankelijk van het type cookie. [Zie hieronder voor meer gedetailleerde opslagtijden.](https://flystrait.com/privacy#Cookies) |

In aanvulling op het bovenstaande ondernemen wij dagelijkse maatregelen die noodzakelijk zijn voor bedrijven die diensten verlenen aan consumenten, zoals boekhouding, facturering, het nakomen van verplichtingen tegen het witwassen van geld en het handhaven van de beveiliging van onze website. Voor zover dit niet verplicht is onder de toepasselijke wetgeving, ondernemen wij deze maatregelen op basis van ons legitieme belang. We kunnen ook het gedrag van onze klanten analyseren om onze websites en diensten op een algemeen niveau te verbeteren. Bij een dergelijke analyse worden gegeneraliseerde of geanonimiseerde gegevens echter op geaggregeerd niveau gebruikt.

#
# Uw persoonlijke gegevens delen

We zullen uw persoonlijke gegevens alleen delen als dit nodig is voor de doeleinden die worden vermeld in dit privacybeleid. Dit kan zijn voor andere bedrijven binnen Fly Strait, overheidsinstanties en onze vertrouwde zakelijke partners. Wij kunnen bijvoorbeeld uw persoonlijke gegevens (inclusief gevoelige persoonlijke gegevens indien van toepassing) delen met zakenpartners zoals luchtvaartmaatschappijen, hotelproviders, verzekeringsmaatschappijen en Global Distribution Systems (zogenaamde GDS&#39;s) om uw reisarrangementen en boekingen mogelijk te maken.

Elke partner is verantwoordelijk voor de eigen verwerking van uw persoonlijke gegevens nadat deze door ons is ontvangen, wat betekent dat u contact moet opnemen met de betreffende partner voor alle verzoeken in verband met uw rechten volgens de toepasselijke wetgeving inzake gegevensbescherming. We raden u aan het respectieve privacybeleid van de partners te lezen voor informatie over hun omgang met uw persoonlijke gegevens. We zullen uw persoonlijke gegevens ook delen met andere bedrijven (zogenaamde &quot;gegevensverwerkers&quot;) die nodig zijn om de door u gevraagde services te leveren, zoals serviceproviders die onze callcenters runnen en onze andere leveranciers en leveranciers die uw persoonlijke gegevens verwerken bij het aanbieden van hun diensten aan ons (bijvoorbeeld externe opslag).

Vanwege het wereldwijde karakter van de reisindustrie, kunnen uw persoonsgegevens op verschillende locaties in de wereld worden verwerkt wanneer de partijen waarmee wij uw persoonlijke gegevens delen, in landen buiten de EU / EER wonen. Ons delen van persoonlijke gegevens buiten de EU / EER vereist bepaalde wettelijke grond onder de toepasselijke wetgeving inzake gegevensbescherming. Als een land door de Europese Commissie wordt beschouwd als een land met een passend niveau van bescherming van persoonsgegevens, dan is dit onze rechtsgrond. Anders zijn er drie belangrijke soorten juridische gronden waarop we dergelijke delen kunnen baseren. Deze zijn:

i. dat de overdracht noodzakelijk is voor onze uitvoering van het contract met u (bijvoorbeeld wanneer u een vlucht hebt geboekt bij een luchtvaartmaatschappij die buiten de EU / EER woont);

ii. dat de overdracht zal worden gebaseerd op de standaardbepalingen inzake gegevensbescherming voor de overdracht van persoonsgegevens naar landen buiten de EU / EER die door de Europese Commissie zijn goedgekeurd (een kopie van deze standaardbepalingen inzake gegevensbescherming is te vinden op http://ec.europa.eu/justice/dataprotection/internationaltransfers/transfer/), en

iii. het EU-VS privacyschild, waar de overdracht plaatsvindt naar de Verenigde Staten en de ontvanger naar behoren is gecertificeerd.

#
# Externe providers

Houd er rekening mee dat onze website links naar andere websites bevat en inhoud van externe leveranciers aanbiedt. Dit privacybeleid is alleen van toepassing op onze website en onze services. Wanneer u links naar andere websites volgt of diensten en producten van derden gebruikt, dient u hun privacybeleid te lezen. Als u ervoor kiest om via social media contact met ons op te nemen, is dit privacybeleid niet van toepassing op de persoonlijke gegevens die u als onderdeel van een dergelijk contact hebt ingediend - in dat geval raden wij u aan het privacybeleid van een dergelijke aanbieder van sociale media te lezen.

#
# Uw rechten

Volgens de toepasselijke wetgeving inzake gegevensbescherming hebt u bepaalde rechten als de betrokkene. Hieronder hebben we uw rechten vermeld. Uw rechten omvatten het volgende:

i. Recht op toegang - U hebt recht op toegang tot de persoonlijke gegevens die wij behandelen. U hebt ook het recht om bepaalde informatie te ontvangen over wat we doen met de persoonlijke gegevens. Dergelijke informatie wordt verstrekt in dit document.

ii. Recht op rectificatie - Onder bepaalde omstandigheden hebt u het recht onjuiste persoonlijke gegevens over u te corrigeren en om onvolledige persoonlijke gegevens aangevuld krijgen. Houd er rekening mee dat we mogelijk niet in staat zijn om onjuiste persoonlijke gegevens die door u zijn verstrekt te corrigeren als gevolg van bijvoorbeeld de regels van de luchtvaartmaatschappij, en dat dergelijke specifieke wijzigingen kosten met zich mee kunnen brengen.

iii. Recht op verwijdering - Onder bepaalde omstandigheden hebt u recht op het verwijderen van uw persoonlijke gegevens. Dit is het zogenaamde &quot;recht om te worden vergeten&quot;.

iv. Recht op beperking van verwerking - Onder bepaalde omstandigheden hebt u het recht om te beperken hoe wij uw persoonlijke gegevens gebruiken.

v. Recht op gegevensportabiliteit: u hebt recht op uw persoonlijke gegevens (of uw persoonlijke gegevens worden rechtstreeks naar een andere gegevenscontroller verzonden) in een gestructureerd, algemeen gebruikt en machinaal leesbaar formaat van ons.

vi. Recht op bezwaar - U hebt het recht om bezwaar te maken tegen bepaalde soorten verwerking van persoonlijke gegevens die wij uitvoeren. Dit geldt voor al onze activiteiten die gebaseerd zijn op ons &quot;legitieme belang&quot;. Ten slotte hebt u ook het recht om een ​​klacht in te dienen bij de toepasselijke toezichthoudende instantie voor gegevensbescherming.

#
# Cookies

Een cookie is een klein tekstbestand dat op uw computer wordt opgeslagen, sommige alleen totdat u uw browser sluit (de zogenaamde &quot;sessiecookies&quot;) en sommige voor een langere periode (zogenaamde &quot;permanente cookies&quot;). Als u geen opslag van cookies op uw computer wilt toestaan, kunt u de instellingen in uw browser wijzigen. Houd er echter rekening mee dat in een enkel geval sommige van onze websitefuncties mogelijk niet goed werken en dat bepaalde inhoud daardoor mogelijk niet correct wordt weergegeven.

Deze website gebruikt cookies om een ​​aantal redenen, waaronder voor het aanbieden van een gepersonaliseerde ervaring, voor het verbeteren van het gebruiksgemak van deze website en voor het verzamelen van gebruiksstatistieken. We gebruiken ook sessiecookies om de beveiliging van deze website te verbeteren.

In sommige gevallen delen we gegevens met derden wanneer we cookies gebruiken. We maken bijvoorbeeld gebruik van Google Analytics en Google AdWords, services die websiteverkeersgegevens verzenden naar Google-servers. Google Analytics identificeert geen individuele gebruikers en koppelt uw IP-adres niet aan andere gegevens in het bezit van Google. We gebruiken rapporten van Google om ons te helpen het gebruik van websiteverkeer en -webpagina&#39;s te begrijpen en om advertenties te optimaliseren die zijn gekocht van het eigen en andere advertentienetwerk van Google. Google kan de gegevens verwerken op de manier die wordt beschreven in [het Privacybeleid van Google](https://policies.google.com/privacy) en voor de doeleinden die hierboven in deze sectie zijn uiteengezet. U kunt zich afmelden voor Google Analytics als u de cookie uitschakelt of weigert, JavaScript uitschakelt of de afmeldingsservice van Google gebruikt. [Gebruik deze link om u af te melden voor advertentiefuncties van Google.](https://tools.google.com/dlpage/gaoptout)

Onze website maakt ook gebruik van Facebook pixel, die geanonimiseerde geaggregeerde gegevens verzamelt die ons helpt bij het optimaliseren van advertentie-aankopen op verschillende platforms van Facebook (inclusief Instagram). Facebook verzamelt een gebruikers-ID waarmee ze kunnen matchen als een gebruiker een site met de Facebook-pixel heeft bezocht. Wij als adverteerders kunnen echter nooit het gedrag van een specifieke gebruiker identificeren. Facebook en de bijbehorende platforms bevinden zich in een gesloten advertentie-ecosysteem, waar hun gebruikers kunnen bepalen of ze toestemming geven aan adverteerders die gegevens gebruiken die zijn verzameld van hun websites om advertenties op Facebook te kopen. [Gebruik deze link om uw advertentie-instellingen op Facebook te bekijken en te wijzigen.](https://facebook.com/ads/preferences)

Verder gebruikt onze website scripts voor het bijhouden van conversies en cookies van [Microsoft Bing](https://privacy.microsoft.com/), [TripAdvisor](https://www.smartertravel.com/privacy-policy) en [TvSquared](https://tvsquared.com/privacy-policy/) (u kunt hun privacybeleid bekijken door de links te volgen).

Al deze services verzamelen geaggregeerde statistische gegevens die ons helpen bij het optimaliseren van advertenties. Wij als adverteerders kunnen geen enkele gebruiker identificeren die deze gegevens gebruikt. U kunt het gebruik van cookies uitschakelen in uw browserinstellingen.

[Ga voor meer informatie over cookies naar www.youronlinechoices.com.](https://www.youronlinechoices.com/)

#
# Gegevensbeveiliging

Om uw persoonlijke gegevens te beveiligen, hebben we een aantal technische en organisatorische beveiligingsmaatregelen geïmplementeerd. Wij handhaven bijvoorbeeld een hoog niveau van technische beveiliging in alle systemen (inclusief traceerbaarheid, herstel na een ramp, toegangsbeperkingen enz.). Daarnaast hebben we beleid aangenomen om ervoor te zorgen dat onze werknemers (die uiteraard onderworpen zijn aan vertrouwelijkheidsverplichtingen) geen persoonsgegevens gebruiken wanneer dit niet nodig is. Dergelijk beleid bepaalt ook onze normen voor wanneer wij leveranciers contracteren of nieuwe IT-systemen introduceren in onze activiteiten.

#
# Contact met ons opnemen

Als u vragen hebt over onze behandeling van uw persoonlijke gegevens of ons gebruik van cookies, of als u een beroep zou willen doen op uw rechten volgens de toepasselijke wetgeving inzake gegevensbescherming, stuur dan een e-mail naar: privacy@flystrait.com

#
# Wijzigingen in dit privacybeleid

Als we veranderen hoe wij omgaan met uw persoonlijke gegevens of hoe we cookies gebruiken, zullen we dit privacybeleid onmiddellijk bijwerken en op deze website publiceren.
`;
