import React, { Component } from "react";
import styled, { ThemeProvider } from "styled-components";
import { connect } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import { ThemeProvider as MaterialTheme } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import { ApolloConsumer } from "react-apollo";
import { isMobile } from "react-device-detect";
import { ApolloProvider } from "react-apollo";

import MobilePay from "containers/MobilePay";
import AirportProvider from "components/common/AirportProvider";
import HotelDestProvider from "components/Hotel/HotelDestProvider";
import Header from "components/Header";
import Footer from "components/Footer";
import TripBooking from "containers/TripBooking";
import LoginSignup from "containers/LoginSignup";
import FlightResults from "containers/FlightResults";
import BookingFailed from "containers/BookingFailed";
import Forgot from "containers/LoginSignup/Forgot";
import Reset from "containers/LoginSignup/Reset";
import Booking from "containers/Booking";
import ConfirmBooking from "containers/ConfirmBooking";

import MyTrips from "containers/MyTrips";
import Privacy from "containers/Privacy";
import Terms from "containers/Terms";
import Contact from "containers/Contact";
import PaymentSuccess from "containers/PaymentSuccess";
import PaymentProcess from "containers/PaymentProcess";
import TwitterCallback from "containers/SocialCallback/TwitterCallback";
import DomainPaymentProcess from "containers/DomainPaymentProcess";
import DomainPaymentFailed from "containers/DomainPaymentFailed";
import Logout from "containers/Logout";

import InfluencerLanding from "containers/Influencer/Landing";
import InfluencerSetupStep1 from "containers/Influencer/Setup/Step1";
import InfluencerSetupStep2 from "containers/Influencer/Setup/Step2";
import InfluencerSetupStep3 from "containers/Influencer/Setup/Step3";
import InfluencerSetupStep4 from "containers/Influencer/Setup/Step4";
import InfluencerSetupShare from "containers/Influencer/Setup/Share";
import InfluencerDashboard from "containers/Influencer/Dashboard";
import InfluencerBlog from "containers/Influencer/Blog";

import ErrorBoundary from "components/common/ErrorBoundary";

import { getItem, hex2rgb } from "utils/utils";
import tipClient from "tipClient";

const Body = styled.div`
  min-height: calc(100vh - 140px);
  @media (max-width: 800px) {
    min-height: auto;
  }
`;

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      getItem("tokenDetails") ? (
        <Component {...props} />
      ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: {
                from: props.location,
              },
            }}
          />
        )
    }
  />
);

const defaultTheme = {
  primary: "#56CCF2",
  secondary: "#007AC7",
  primaryBackground: "#D0F3FC",
};

let materialTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#56CCF2",
    },
    secondary: {
      main: "#007AC7",
    },
  },
});
const isTip = window.location.hostname.includes(".dev.flystrait");
class App extends Component {
  // primary background 0.3 opacity
  state = {
    theme: defaultTheme,
  };
  componentWillReceiveProps(nextProps) {
    const { influencerDetails } = nextProps;
    if (influencerDetails !== this.props.influencerDetails && isTip) {
      const theme = influencerDetails.primary_colour
        ? {
          primary: influencerDetails.primary_colour,
          secondary: influencerDetails.contrast_colour,
          primaryBackground: hex2rgb(influencerDetails.primary_colour, 0.3),
        }
        : defaultTheme;
      materialTheme = createMuiTheme({
        palette: {
          primary: {
            main: theme.primary,
          },
          secondary: {
            main: theme.secondary,
          },
        },
      });
      this.setState({ theme });
    }
    this.setState({ languageChange: true });
  }
  hideSplash = () => {
    // const splashScreen = document.querySelector('.splash-screen');
    // splashScreen.classList.add('animated');
    // splashScreen.classList.add('fadeOut'); setTimeout(() => {
    // splashScreen.style.display = 'none'; }, 1000);
  };
  render() {
    const mobilePay =
      window.location.href.includes("mobile-pay") ||
      window.location.href.includes("emp-redirect");
    return (
      <ThemeProvider theme={this.state.theme}>
        <MaterialTheme theme={materialTheme}>
          <ApolloConsumer>
            {(client) => (
              <React.Fragment>
                {!mobilePay && (
                  <>
                    <AirportProvider
                      handleComplete={this.hideSplash}
                      client={client}
                    />
                    <HotelDestProvider
                      handleComplete={this.hideSplash}
                      client={client}
                    />
                  </>
                )}
                <ErrorBoundary>
                  {!isTip && <Header />}
                  <Body>
                    {!isTip && (
                      <Switch>
                        <Route path="/" exact component={TripBooking} />
                        <Route path="/trip" exact component={TripBooking} />
                        <Route
                          exact
                          path="/new-trip"
                          render={(props) => {
                            return <TripBooking isNewTrip {...props} />;
                          }}
                        />
                        <Route path="/my-trips" exact component={MyTrips} />
                        <Route
                          path="/login/:register?"
                          exact
                          component={LoginSignup}
                        />
                        <Route path="/forgot" exact component={Forgot} />
                        <Route path="/reset" exact component={Reset} />
                        <Route exact path="/privacy" component={Privacy} />
                        <Route exact path="/terms" component={Terms} />
                        <Route exact path="/contact" component={Contact} />
                        <Route
                          exact
                          path="/tip_twitter_callback"
                          component={TwitterCallback}
                        />
                        <Route
                          path="/booking-failed"
                          exact
                          component={BookingFailed}
                        />
                        <Route
                          exact
                          path="/booking/:tripId"
                          component={Booking}
                        />
                        <Route
                          exact
                          path="/confirm-booking/:tripId"
                          component={ConfirmBooking}
                        />

                        <Route
                          exact
                          path="/flights/:encoded"
                          component={FlightResults}
                        />
                        <Route
                          exact
                          path="/emp-redirect"
                          component={PaymentSuccess}
                        />
                        <Route exact path="/mobile-pay" component={MobilePay} />
                        <Route exact path="/payment" component={MobilePay} />
                        <Route
                          exact
                          path="/domain-payment-process"
                          component={DomainPaymentProcess}
                        />
                        <Route
                          exact
                          path="/domain-payment-failed"
                          component={DomainPaymentFailed}
                        />
                        <Route exact path="/logout" component={Logout} />
                        <Route
                          exact
                          path="/payment-process"
                          component={PaymentProcess}
                        />
                      </Switch>
                    )}
                    <ApolloProvider client={tipClient}>
                      <Switch>
                        {isTip && <Route path="/" component={InfluencerBlog} />}
                        <Route
                          exact
                          path="/influencer"
                          component={InfluencerLanding}
                        />
                        <PrivateRoute
                          exact
                          path="/influencer/setup/step1"
                          component={InfluencerSetupStep1}
                        />
                        <PrivateRoute
                          exact
                          path="/influencer/setup/step2"
                          component={InfluencerSetupStep2}
                        />
                        <PrivateRoute
                          exact
                          path="/influencer/setup/step3"
                          component={InfluencerSetupStep3}
                        />
                        <PrivateRoute
                          exact
                          path="/influencer/setup/step4/:postId"
                          component={InfluencerSetupStep4}
                        />
                        <PrivateRoute
                          exact
                          path="/influencer/setup/share/:postId"
                          component={InfluencerSetupShare}
                        />
                        <PrivateRoute
                          exact
                          path="/influencer/dashboard/:route"
                          component={InfluencerDashboard}
                        />
                        <PrivateRoute
                          exact
                          path="/influencer/dashboard/:route/:id"
                          component={InfluencerDashboard}
                        />
                      </Switch>
                    </ApolloProvider>
                  </Body>
                  {!isMobile && !isTip && <Footer />}
                </ErrorBoundary>
              </React.Fragment>
            )}
          </ApolloConsumer>
        </MaterialTheme>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  updateTime: state.translations.updateTime,
  influencerDetails: state.influencer.influencerDetails,
});
export default connect(mapStateToProps)(App);
