import React from "react";
import DateAndCancellationModal from "components/common/DateAndCancellationModal";
import {
  addDecimal,
  getItem,
  numberToLocaleString,
  underscoreToSpace,
} from "utils/utils";
import HopSegButton from "./HopSegButton";
import getTranslation from "utils/getTranslation";
import {
  AddIcon,
  FlightCancelStrict,
  FlightCancelModerate,
  FlightCancelFlexible,
  EditIcon,
} from "components/common/SvgIcons";

export class DateChangeAndCancellation extends React.PureComponent {
  state = {
    airportData: [],
    selectedIndex: 0,
    openModal: false,
  };

  components = {
    STRICT: FlightCancelStrict,
    MODERATE: FlightCancelModerate,
    FLEXIBLE: FlightCancelFlexible,
  };

  componentWillReceiveProps(newProps) {
    if (newProps.dateChangePenalty !== this.props.dateChangePenalty) {
      const selectedIndex = this.state.airportData.findIndex(
        (item) => item === newProps.dateChangePenalty
      );
      this.setState({
        selectedIndex,
      });
    }
  }

  handleClick = () => {
    if (this.state.airportData.length) {
      const upcomingIndex = this.state.selectedIndex + 1;
      this.setState(
        {
          selectedIndex:
            this.state.airportData.length > upcomingIndex ? upcomingIndex : 0,
        },
        () =>
          this.props.onClick &&
          this.props.onClick({
            type: "dateChangePenalty",
            value: this.state.airportData[this.state.selectedIndex],
            withCancellation: true,
          })
      );
    }
  };

  handleRequestClose = (priceNum, value) => {
    this.setState({ openModal: false });
    if (priceNum) {
      this.props.onClick({
        type: "dateChangePenalty",
        value,
        priceNum,
      });
    }
  };

  handleClick = (e) => {
    e.preventDefault();
    this.setState({
      openModal: true,
    });
  };

  render() {
    const { airportData, openModal } = this.state;
    const {
      dateChangePenalty,
      transferValue,
      costDecimalPlaces,
      hopsegmentcontrolId,
      hopSeqNum,
      priceNum,
    } = this.props;
    const penalty = underscoreToSpace(dateChangePenalty);
    const initalValues = getItem("hopSegInitialValues") || [];
    const isActive = initalValues.find(
      (item) =>
        item.hopSeqNum === hopSeqNum &&
        dateChangePenalty !== item.dateChangePenalty
    );
    const isStrict = dateChangePenalty === "STRICT";
    const isFlexible = dateChangePenalty === "FLEXIBLE";
    const Icon = this.components[dateChangePenalty || "STRICT"];
    let disabledDateAndCancellation =
      getItem("disabledDateAndCancellation") || [];
    const noResults = disabledDateAndCancellation.indexOf(priceNum) > -1;
    return (
      <div className="hopSegButton">
        <DateAndCancellationModal
          open={openModal}
          handleCloseModal={this.handleRequestClose}
          hopsegmentcontrolId={hopsegmentcontrolId}
          smartValue="DATE_CHANGE_CANCELLATION"
          hopSeqNum={hopSeqNum}
          priceNum={priceNum}
        />
        <HopSegButton
          onClick={this.handleClick}
          disabled={
            this.props.disabled || airportData.length === 1 || noResults
          }
          label="Cancel/Date change"
          active={isActive}
          btnContent={
            <>
              <Icon
                stroke={isActive ? "#222222" : "#222222"}
                style={{ height: 22, width: 22 }}
              />
              <b className="__release-text">{penalty}</b>
            </>
          }
          text={
            <p style={{ textAlign: "center", marginTop: 3, width: "100%" }}>
              {isStrict
                ? getTranslation("noRefund")
                : isFlexible
                ? getTranslation("fullRefund")
                : getTranslation("refundUpto")}
              {!isStrict && (
                <span style={{ marginLeft: 5 }}>
                  {numberToLocaleString(
                    addDecimal(transferValue, costDecimalPlaces),
                    {
                      selectedCountry: getItem("selectedLanguage"),
                      selectedCurrency: getItem("selectedCurrency"),
                    }
                  )}
                </span>
              )}
            </p>
          }
        ></HopSegButton>
      </div>
    );
  }
}

export default DateChangeAndCancellation;
