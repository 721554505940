import React from "react";
import { graphql, compose } from "react-apollo";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { Mask, Steps } from "components/Influencer";
import { Wrapper } from "containers/Influencer/Setup/Styles";
import { Appearance, Domain } from "./Partials";
import {
  insertInfluencer,
  updateInfluencer,
  checkSubDomain,
  insertCustomDomain,
  updateCustomDomain,
} from "containers/Influencer/queries";
import { Toast } from "components/common";
import { setInfluencer } from "redux-store/actions";
import FormAction from "containers/Influencer/Setup/FormAction";
import getTranslation from "utils/getTranslation";
import { getItem, removeItem } from "utils/utils";

let timeout;
class Step1 extends React.PureComponent {
  constructor(props) {
    super(props);
    const {
      contrast_colour,
      sitetheme,
      primary_colour,
      logo,
      tip_sub_domain,
      customsitedomains,
      custom_domain_selected,
    } = props.influencerDetails || {};
    this.state = {
      step1: {
        fsuseraccount_id: getItem("useraccount_id"),
        contrast_colour: "#54cf02",
        sitetheme,
        primary_colour,
        logo,
        tip_sub_domain,
        custom_domain_selected,
      },
      isFormCompleted: false,
      isAppearanceCompleted: false,
      customsitedomains: customsitedomains || [],
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.influencerDetails !== this.props.influencerDetails) {
      const {
        contrast_colour,
        sitetheme,
        primary_colour,
        logo,
        tip_sub_domain,
        customsitedomains,
        custom_domain_selected,
      } = nextProps.influencerDetails;
      this.setState({
        step1: {
          ...this.state.steps,
          sitetheme,
          primary_colour,
          logo,
          tip_sub_domain,
          contrast_colour,
          custom_domain_selected,
        },
        customsitedomains,
      });
    }
  }
  componentDidUpdate() {
    const {
      contrast_colour,
      sitetheme,
      primary_colour,
      logo,
      tip_sub_domain,
      custom_domain_selected,
    } = this.state.step1;

    const isFormCompleted =
      contrast_colour &&
      primary_colour &&
      logo &&
      sitetheme &&
      !this.state.tipLoading &&
      !this.state.subdomainUsed &&
      !this.state.invalidSubdomain &&
      (tip_sub_domain || custom_domain_selected);

    const isAppearanceCompleted =
      contrast_colour &&
      primary_colour &&
      logo &&
      sitetheme;

    if (isAppearanceCompleted !== this.state.isAppearanceCompleted) {
      this.setState({
        isAppearanceCompleted
      });
    }

    if (isFormCompleted !== this.state.isFormCompleted) {
      this.setState({
        isFormCompleted,
      });
    }
  }

  handleUpdate = (name, value) => {
    this.setState(
      {
        step1: {
          ...this.state.step1,
          [name]: value,
        },
        tipLoading: name === "tip_sub_domain",
      },
      () => {
        if (name === "tip_sub_domain") {
          const re = /^([a-zA-Z0-9-]+)$/;
          let isValidSubDomainName = !value || re.test(String(value).toLowerCase());
          this.setState({
            invalidSubdomain: !isValidSubDomainName
          })
          if (isValidSubDomainName) {
            clearTimeout(timeout);
            const { influencerDetails } = this.props;
            timeout = setTimeout(async () => {
              const influencer = await this.props.client.query({
                query: checkSubDomain,
                variables: {
                  limit: 1,
                  where: {
                    tip_sub_domain: {
                      _eq: this.state.step1.tip_sub_domain,
                    },
                    influencer_id: influencerDetails
                      ? { _neq: influencerDetails.id }
                      : undefined,
                  },
                },
              });
              this.setState({
                subdomainUsed: influencer.data.influencer_subdomain.length > 0,
                tipLoading: false,
              });
            }, 300);
          }
        }
      }
    );
  };

  handleDomainUpdate = (domain) => {
    this.setState({
      customDomainName: domain,
      step1: {
        ...this.state.step1,
        custom_domain_selected: true,
      },
    });
  };

  handleUpdateDomain = async () => {
    try {
      let response;
      let queryType;
      const { influencerDetails } = this.props;
      if (influencerDetails.customsitedomains.length === 0) {
        queryType = "insert_customsitedomain";
        response = await this.props.insertCustomDomain({
          variables: {
            objects: [
              {
                domain: this.state.customDomainName,
                influencer_id: influencerDetails.id,
              },
            ],
          },
        });
      } else {
        queryType = "update_customsitedomain";
        response = await this.props.updateCustomDomain({
          variables: {
            _set: {
              domain: this.state.customDomainName,
              available_at: null,
              unavailable_at: null,
            },
            where: {
              influencer_id: { _eq: influencerDetails.id },
            },
          },
          fetchPolicy: "no-cache",
        });
      }
      const domainData = response.data[queryType].returning[0];
      domainData.price_usd = getItem("selectedDomain").price_usd;
      this.props.setInfluencer({
        ...this.props.influencerDetails,
        customsitedomains: [domainData],
      });
      removeItem("selectedDomain");
      this.props.history.push("/influencer/setup/step2");
    } catch (err) {
      this.props.history.push("/influencer/setup/step2");
    }
  };
  handleBack = () => {
    this.props.history.push("/influencer");
  };
  handleNext = async () => {
    if (this.state.subdomainUsed) {
      return;
    }
    try {
      this.setState({ loading: true });
      let response;
      const { influencerDetails } = this.props;
      if (!influencerDetails) {
        response = await this.props.insertInfluencer({
          variables: {
            objects: [
              {
                ...this.state.step1,
              },
            ],
          },
        });
      } else {
        response = await this.props.updateInfluencer({
          variables: {
            _set: {
              ...this.state.step1,
            },
            where: {
              fsuseraccount_id: { _eq: getItem("useraccount_id") },
            },
          },
        });
      }
      this.props.setInfluencer(
        response.data[
          influencerDetails ? "update_influencer" : "insert_influencer"
        ].returning[0]
      );
      this.setState({ loading: false });
      if (this.state.customDomainName) {
        this.handleUpdateDomain();
      } else {
        this.props.history.push("/influencer/setup/step2");
      }
    } catch (err) {
      console.log(err);
      this.setState({
        loading: false,
        errorMessage: getTranslation("genericErrorMsg"),
      });
    }
  };

  handleFetchComplete = () => {
    this.setState({
      fetchComplete: true,
    });
  };

  handleClearDomain = () => {
    this.setState({
      step1: {
        ...this.state.step1,
        custom_domain_selected: false,
        tip_sub_domain: null,
      },
    });
  };

  handleHideError = () => {
    this.setState({
      errorMessage: null,
    });
  };
  render() {
    const { influencerDetails } = this.props;
    const {
      step1,
      customsitedomains,
      loading,
      subdomainUsed,
      invalidSubdomain,
      errorMessage,
      isFormCompleted,
      fetchComplete,
      isAppearanceCompleted
    } = this.state;
    return (
      <Wrapper>
        {fetchComplete && (
          <>
            <Mask style={{ height: 52 }} />
            <h5 style={{ textAlign: "center", paddingTop: 10 }}>
              {getTranslation("tipRegistration")}
            </h5>
          </>
        )}
        <Steps
          activeStep={1}
          handleFetchComplete={this.handleFetchComplete}
          handleStep1={this.handleFetchComplete}
          fetchComplete={fetchComplete}
        />
        {fetchComplete && (
          <div className="container">
            <Appearance data={step1} handleUpdate={this.handleUpdate} />
            {isAppearanceCompleted && (fetchComplete || influencerDetails) && (
              <Domain
                data={step1}
                customsitedomains={customsitedomains}
                subdomainUsed={subdomainUsed}
                invalidSubdomain={invalidSubdomain}
                handleUpdate={this.handleUpdate}
                handleDomainUpdate={this.handleDomainUpdate}
                handleClearDomain={this.handleClearDomain}
              />
            )}
            <FormAction
              activeStep={1}
              handleBack={this.handleBack}
              handleNext={this.handleNext}
              loading={loading}
              invalid={!isFormCompleted}
            />
          </div>
        )}
        <Toast
          open={errorMessage}
          message={errorMessage}
          handleClose={this.handleHideError}
        />
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  influencerDetails: state.influencer.influencerDetails,
});

export default withApollo(
  compose(
    graphql(insertInfluencer, { name: "insertInfluencer" }),
    graphql(updateInfluencer, { name: "updateInfluencer" }),
    graphql(insertCustomDomain, { name: "insertCustomDomain" }),
    graphql(updateCustomDomain, { name: "updateCustomDomain" }),
    connect(mapStateToProps, { setInfluencer })
  )(Step1)
);
