import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import styled from "styled-components";
import CountryAndLocaleModal from "components/common/CountryAndLocaleModal";
import getTranslation from "utils/getTranslation";
import countryList from "utils/countryList";
import { getItem } from "utils/utils";
import languageIcon from "assets/icons/language.svg";
import facebook from "assets/icons/facebook-footer.svg";
import twitter from "assets/icons/twitter-footer.svg";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  background: #ffffff;
  box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.16);
  padding: 22px 20px;
  .leftSection {
    display: flex;
    flex-flow: column;
    .copyright {
      font-size: 13px;
      color: #444444;
    }
    .links {
      margin-top: 10px;
      a {
        color: #007ac7;
        font-size: 14px;
        &:first-child {
          margin-right: 30px;
        }
      }
    }
  }
  .centerSection {
    align-items: center;
    display: flex;
    flex-flow: column;
    .contact {
      display: flex;
      margin-bottom: 10px;
      align-items: center;
      div {
        color: #007ac7;
        text-decoration: underline;
        font-size: 14px;
        margin: 0 20px;
        a {
          color: #007ac7;
        }
      }
    }
    .address {
      font-size: 12px;
      color: #444444;
    }
  }
  .rightSection {
    display: flex;
    .item {
      margin: 0 10px;
      justify-content: space-between;
      display: flex;
      flex-flow: column;
      font-size: 14px;
      color: #777777;
      &:first-child {
        margin-left: 0;
        color: #007ac7;
      }
    }
  }
  .social {
    display: flex;
    align-items: center;
    img {
      margin: 0 10px;
    }
  }
`;

const Pipe = styled.div`
  margin: 0 16px;
  width: 1px;
  border-right: 1px dashed #dddddd;
`;

const social = [
  {
    icon: facebook,
    id: 1,
  },
  {
    icon: twitter,
    id: 2,
  },
];

class Footer extends React.PureComponent {
  state = {};
  componentWillReceiveProps(nextProps) {
    this.setState({
      languageChange: true,
    });
  }
  handleShowCountries = () => {
    this.setState({ openModal: true });
  };
  handleCloseModal = () => {
    this.setState({ openModal: false });
  };
  render() {
    const { openModal } = this.state;
    const country = getItem("selectedCountry") || "gb";
    const currency = getItem("selectedCurrency") || "EUR";
    const currencyDisplay = getItem("selectedCurrencySymbol") || "€";
    const selectedCountry = countryList.find(
      (item) => item.Code === country.toUpperCase()
    );
    const currentLanguage = getItem("selectedLanguageName") || "English";
    return (
      <React.Fragment>
        <CountryAndLocaleModal
          open={openModal}
          handleCloseModal={this.handleCloseModal}
        />
        <Wrapper id="app-footer">
          <div className="leftSection">
            <div className="copyright">© 2019 Fly Strait</div>
            <div className="links">
              <Link to="/privacy">{getTranslation("privacy")}</Link>
              <Link to="/terms">{getTranslation("terms")}</Link>
            </div>
          </div>
          <Pipe />
          <div className="rightSection">
            <div
              className="item"
              onClick={this.handleShowCountries}
              style={{ cursor: "pointer" }}
            >
              <div>
                <img src={require(`assets/icons/globe.svg`)} alt="" />
              </div>
              <div>{selectedCountry.en}</div>
            </div>
            <div className="item">
              <div>
                <img src={languageIcon} alt="" />
              </div>
              <div>{currentLanguage}</div>
            </div>
            <div className="item">
              <div
                style={{ color: "#56CCF2", fontSize: 16, lineHeight: "20px" }}
              >
                {currencyDisplay}
                {/* <img src={currencyIcon} alt="" /> */}
              </div>
              <div>{currency}</div>
            </div>
          </div>
          <Pipe />
          <div className="social">
            {social.map((item) => (
              <img src={item.icon} alt="" key={item.id} />
            ))}
          </div>
        </Wrapper>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  updateTime: state.translations.updateTime,
});

export default connect(mapStateToProps)(Footer);
