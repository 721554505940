import React from "react";
import styled from "styled-components";
import moment from "moment";
import { DateWrapper } from "components/Influencer/BlogCards/CardStyles";
import DestinationLabels from "components/Influencer/BlogCards/DestinationLabels";
import DayContent from "./DayContent";
import TransportTimeline from "./TransportTimeline";
import BookingSection from "./BookingSection";

const Container = styled.div`
  width: 1096px;
  margin: auto;
  display: flex;
  .transports {
    width: 160px;
  }
`;

const Wrapper = styled.div`
  background-color: #fff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
  max-width: 936px;
  margin: auto;
`;
const Cover = styled.div`
  position: relative;
  height: 703px;
  width: 100%;
  background-size: cover;
`;

const Content = styled.div`
  padding: 30px 80px;
  .title {
    font-weight: 400;
    font-size: 48px;
    line-height: 56px;
    margin: 50px 0 32px 0;
  }
`;

export default class extends React.PureComponent {
  render() {
    const { blogData, color } = this.props;
    let dateArray = [];
    if (blogData) {
      const date = moment(blogData.created_at).format("DD,MMM,YYYY");
      dateArray = date.split(",");
    }
    const coverimage = blogData.coverimage || blogData.coverimage1;
    return (
      <Container>
        <div className="transports">
          {blogData.itinerarydays.map(item => (
            <TransportTimeline key={item.id} data={item} color={color} />
          ))}
        </div>
        <Wrapper>
          <Cover style={{ backgroundImage: `url(${coverimage})` }}>
            <DateWrapper>
              <span className="date">{dateArray[0]}</span>
              <span className="month">{dateArray[1]}</span>
            </DateWrapper>
            <div className="destinations">
              <DestinationLabels blogData={blogData} color={color} />
            </div>
          </Cover>
          <Content>
            <h1 className="title" style={{ color: color || "#222222" }}>
              {blogData.title}
            </h1>
            {blogData.itinerarydays.map(itineraryday => (
              <DayContent
                data={itineraryday}
                color={color}
                key={itineraryday.id}
              />
            ))}
            {blogData.postdestinations.length > 0 && (
              <BookingSection
                destinations={blogData.postdestinations}
                color={color}
              />
            )}
          </Content>
        </Wrapper>
      </Container>
    );
  }
}
