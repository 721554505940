import React from "react";
import { connect } from "react-redux";
import { Wrapper } from "containers/Influencer/Setup/Styles";
import { Button } from "components/common";
import {
  Domain,
  Appearance,
  AccountDetails,
  DeleteAccountModal
} from "./Partials";
import getTranslation from "utils/getTranslation";

class Settings extends React.PureComponent {
  state = { openConfirm: false };
  handleToggle = () => {
    this.setState({
      openConfirm: !this.state.openConfirm
    });
  };
  handleAccountDeleted = () => {
    this.props.history.push("/influencer");
  };
  render() {
    const { openConfirm } = this.state;
    const { influencerDetails } = this.props;
    return (
      <Wrapper>
        {openConfirm && (
          <DeleteAccountModal
            open={openConfirm}
            handleCloseModal={this.handleToggle}
            handleSubmit={this.handleAccountDeleted}
            title="Share"
          />
        )}
        <Domain influencerDetails={influencerDetails} />
        <Appearance influencerDetails={influencerDetails} />
        {/* <AccountDetails influencerDetails={influencerDetails} /> */}
        <div style={{ margin: "20px 0", width: "fit-content" }}>
          <Button
            color="#F0F0F0"
            style={{ color: "#EB5757" }}
            onClick={this.handleToggle}
          >
            {getTranslation("deleteTipAccount")}
          </Button>
        </div>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  influencerDetails: state.influencer.influencerDetails
});

export default connect(mapStateToProps)(Settings);
