import React from "react";
import { connect } from "react-redux";
import { updateUserDate, updateInfluencer } from "redux-store/actions";
import { removeItem } from "utils/utils";

class Logout extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleLogout();
  }
  handleLogout = () => {
    this.props.updateUserDate(null);
    this.props.updateInfluencer(null);
    removeItem("tokenDetails");
    removeItem("tripId");
    removeItem("loggedInUsername");
    removeItem("useraccount_id");
    removeItem("paymentType");
    // this.props.location.replace()
    this.props.history.replace("/login");
  };
  render() {
    return null;
  }
}

const mapDispatchToProps = {
  updateUserDate,
  updateInfluencer
};

export default connect(null, mapDispatchToProps)(Logout);
