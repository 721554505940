import React from 'react';
import styled from 'styled-components';
import { getItem, getBrowserLocale } from 'utils/utils';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 296px;
  color: #979797;
  background-color: rgba(255, 255, 255, 0.5);
  margin: 0 10px 0 10px;
  margin-bottom: 20px;
  padding: 5px;
  cursor: pointer;
  position: relative;
  ${'' /* &:nth-child(3n+0) {
    margin-right: 0;
  }
  &:nth-child(3n+1) {
    margin-left: 0;
  } */}
  @media (max-width: 800px) {
    width: 100%;
    margin: 0 0px 20px 0px;
  }
  &.active {
    outline: 1px solid #56CCF2;
  }
  .country {
    display: flex;
    margin-bottom: 20px;
    .name {
      color: #222222;
      margin-left: 5px;
      font-size: 18px;
    }
  }
  .locale {
    margin-bottom: 10px;
  }
  .locale, .currency {
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    span {
      border: 1px solid #F0F0F0;
      background-color: #fff;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      cursor: pointer;
      padding: 5px;
      color: #007AC7;
      margin: 0 10px 5px 0 !important;
      &.active, &.highlight:hover {
        color: #fff;
        background-color: #56CCF2;
      }
    }
  }
`;

export default class extends React.PureComponent {
  handleCountryChange = () => {
    this.props.handleCountryChange(this.handleMergeBrowserLocale(this.props.country));
  }

  handleLocaleChange = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const selectedLocale = e.target.id;
    const localeMeta = this.props.country.locales.find(item => item.locale === selectedLocale);
    if (this.props.defaultSelected) {
      const locale = this.handleMergeBrowserLocale(this.props.country).locales.find(item => item.locale === selectedLocale);
      this.props.handleLocaleChange(localeMeta, locale.redirectUrl);
    } else {
      this.props.handleCountryChange(this.handleMergeBrowserLocale(this.props.country, null, localeMeta));
    }
  }

  handleCurrencyChange = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const currency = e.target.id;
    const currencyMeta = this.props.country.currencies.find(item => item.currencyIso === currency);
    if (this.props.defaultSelected) {
      this.props.handleCurrencyChange(currencyMeta);
    } else {
      this.props.handleCountryChange(this.handleMergeBrowserLocale(this.props.country, currencyMeta));
    }
  }

  handleMergeBrowserLocale = (currentCountry, currencyMeta, localeMeta) => {
    const browserLocaleSupported = getItem('browserLocaleSupported');
    const browserLocale = getBrowserLocale().substring(0, 2);
    const country = JSON.parse(JSON.stringify(currentCountry));
    const filteredLocales = country.locales.filter(item => item.language !== browserLocaleSupported);
    if (browserLocaleSupported) {
      country.locales = [{
        locale: browserLocale,
        language: browserLocaleSupported,
        redirectUrl: country.locales[0].redirectUrl,
      }, ...filteredLocales];
    }
    if (currencyMeta) {
      country.currencies = [currencyMeta, ...country.currencies];
    }
    if (localeMeta) {
      country.locales = [localeMeta, ...country.locales];
    }
    return country;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.handleSubmit();
  }

  render() {
    const {
      country: currentCountry,
      defaultSelected,
      selectedLanguage,
      selectedCurrency,
      style = {}
    } = this.props;
    const country = this.handleMergeBrowserLocale(currentCountry);
    return (
      <Wrapper
        className={defaultSelected ? 'active' : ''}
        onClick={this.handleCountryChange}
        style={style}
      >
        <div className="country">
          <img
            src={require(`assets/country-flags/${country.countryIc.toLowerCase()}${(country.countryIc === 'XX' && defaultSelected) ? '-blue' : ''}.svg`)}
            alt=""
            height="30"
            width="40"
          />
          <span className="name">{country.country || 'International'}</span>
        </div>
        <div className="locale">
          {country.locales.map(locale => (
            <span
              className={(defaultSelected && locale.locale === selectedLanguage) ? 'active' : defaultSelected ? 'highlight' : ''}
              onClick={this.handleLocaleChange}
              id={locale.locale}
            >
              {locale.language}
            </span>
          ))}
        </div>
        <div className="currency">
          {country.currencies.map(currency => (
            <span
              className={(defaultSelected && currency.currencyIso === selectedCurrency) ? 'active' : defaultSelected ? 'highlight' : ''}
              onClick={this.handleCurrencyChange}
              id={currency.currencyIso}
            >
              {currency.currencyDisplay} {currency.currencyIso}
            </span>
          ))}
        </div>
      </Wrapper>
    );
  }
}
