import React from 'react';
import styled from 'styled-components';
import BookedTrip from 'components/common/BookedTrip';
import Label from './Label';
import DownArrow from 'assets/icons/Icon-DropDownDown.svg';

const Wrapper = styled.div`
  width: 100%;
  background-color: #F0F0F0;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  .tripItem {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
    font-size: 18px;
    color: #007AC7;
    cursor: pointer;
    &.active {
      background-color: rgba(86,204,242,0.3);
    }
    img {
      transform: scale(1.5) rotate(-90deg);
      margin-right: 10px;
      transition: all 0.3s;
      &.expanded {
        transform: scale(1.5);
      }
    }
  }
`;

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: props.firstItem,
    };
  }

  handleToggle = () => {
    this.setState({
      expanded: !this.state.expanded,
    });
  }

  render() {
    const { expanded } = this.state;
    const { tripItem } = this.props;
    return (
      <Wrapper>
        <div className="tripItem" onClick={this.handleToggle}>
          <img src={DownArrow} alt="" className={expanded ? 'expanded' : ''} />
          <Label tripItem={tripItem} />
        </div>
        {expanded && <div style={{ marginTop: 12 }}><BookedTrip tripItem={tripItem} tripId={tripItem.id} /></div>}
      </Wrapper>
    );
  }
}
