import React from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import ClickOutside from "react-click-outside";
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { Button } from 'components/common';
import { CloseIcon } from 'components/common/SvgIcons';
import Input from './Input';
import Icon from 'assets/icons/Icon-Airport.svg';

const Wrapper = styled.div`
  &.wrapperFocused {
    position: fixed !important;
    z-index: 999999;
    width: 100%;
    height: 100%;
    background-color: #fff;
    left: 0;
    top: 0;
  }
  span {
    font-size: 16px;
    color: #212121;
  }
  .smallText {
    color: #555555;
    font-size: 14px;
    font-weight: 300;
  }
`;

const StyledMenuList = styled(MenuList)`
  padding-bottom: 0 !important;
  padding-top: 0 !important;
`;

const StyledMenuItem = styled(MenuItem)`
  &:not(:last-child) {
    border-bottom: 1px solid #DDDDDD !important;
  }
  @media (max-width: 800px) {
    border-bottom: 1px solid #DDDDDD !important;
  }
  .textEllipsis {
    word-break: break-word;
    white-space: pre-line;
    line-height: 19px;
  }
`;
let blurTimeout;
export default class extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      focused: false,
      value: props.value,
      currentFocus: -1
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.value && nextProps.value !== this.props.value) {
      this.setState({ value: nextProps.value, wrapperFocused: false });
    }
    if (!nextProps.value && nextProps.value !== this.props.value) {
      this.setState({ value: nextProps.value });
    }
  }
  getTrimmedText = (type, e) => {
    switch (type) {
      case "airport":
        if (e && e.airportIc) {
          return `${e.city} (${e.airportIc})`;
        }
        break;
      case "hoteldest":
        if (e && e.code) {
          return `${e.name} (${e.code})`;
        }
        break;
      default:
        return `${e}`;
    }
  }

  handleSelect = (i, e) => {
    if (e) {
      e.preventDefault();
    }
    clearTimeout(blurTimeout);
    this.props.handleSelect(i, this.props.dataSource);
  }
  handleKeyChange = (e) => {
    if (e.keyCode === 13) {
      e.target.click();
    }
  }
  handleChange = (e) => {
    this.setState({
      value: e.target.value,
      currentFocus: -1,
    }, () => {
      this.props.onChange(this.state.value);
    });
  }
  handleBlur = () => {
    const { searchText, dataSource } = this.props;
    if (this.props.onBlur) {
      blurTimeout = setTimeout(() => {
        this.props.onBlur(searchText, dataSource);
      }, 200);
    }
  }
  highlightText = (string) => {
    const { searchText } = this.props;
    return string.replace(new RegExp(searchText, 'gi'), match => (
      `<b>${match}</b>`
    ));
  }
  handleFocusUpdate = (focused) => {
    setTimeout(() => {
      this.setState({
        focused: focused
      });
    }, 300);
  }
  handleFocus = () => {
    setTimeout(() => {
      this.setState({
        focused: true
      });
    }, 300);
  }
  handleWrapperFocus = () => {
    if (isMobile) {
      this.setState({
        wrapperFocused: true
      });
    }
  }
  handleWrapperFocusRemove = () => {
    if (isMobile) {
      this.setState({
        wrapperFocused: false,
      });
      // this.props.onChange('');
    }
  }
  handleClickOutside = () => {
    this.setState({
      focused: false
    });
  }
  handleKeyDown = (e) => {
    if (e.keyCode === 38) {
      // up
      this.setState({
        currentFocus: this.state.currentFocus - 1
      });
      e.preventDefault();

    } else if (e.keyCode === 40) {
      // down
      this.setState({
        currentFocus: this.state.currentFocus + 1
      });
    } else if (e.keyCode === 13 && this.state.currentFocus > -1) {
      if (this.props.id) {
        document.getElementById(this.props.id).blur();
      }
      e.preventDefault();
      this.handleSelect(this.state.currentFocus);
    }
  }
  render() {
    const { value, focused, currentFocus, wrapperFocused } = this.state;
    const {
      label,
      dataSource = [],
      refName,
      type = 'country',
      id,
      icon,
      required,
      error
    } = this.props;
    // console.log('inside autocom data ' + JSON.stringify(dataSource));
    return (
      <ClickOutside onClickOutside={this.handleClickOutside}>
        <Wrapper
          className={wrapperFocused ? 'wrapperFocused' : ''}
          style={{ position: 'relative' }}
          onClick={this.handleFocus}
          onFocus={this.handleWrapperFocus}
        >
          <div style={wrapperFocused ? {
            display: 'flex',
            alignItems: 'center',
            padding: '0 10px',
            boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.16)'
          } : {}}>
            {wrapperFocused &&
              <Button
                onClick={this.handleWrapperFocusRemove}
                color="#D0F3FC"
                style={{ height: 32, maxWidth: 32, minWidth: 32, marginRight: 10, marginTop: 5 }}
              >
                <CloseIcon />
              </Button>}
            <Input
              required={required}
              autoComplete={type === 'country' ? 'nope' : 'off'}
              id={id}
              inputRef={refName}
              label={label}
              value={this.getTrimmedText(type, value)}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              handleFocusUpdate={this.handleFocusUpdate}
              doSelection
              icon={icon}
              onKeyDown={this.handleKeyDown}
              errorState={error}
            />
          </div>
          <Paper
            elevation={isMobile ? 0 : 1}
            style={{
              position: 'absolute',
              top: 68,
              width: '100%',
              maxHeight: isMobile ? 'calc(100vh - 80px)' : 300,
              overflow: 'auto',
              zIndex: 99,
              padding: isMobile ? '0 16px' : 0,
              marginTop: 5
            }}>
            {(focused && dataSource.length > 0) && <StyledMenuList ref={(c) => { this._menulist = c; }}>
              {dataSource.map((data, i) => (
                <StyledMenuItem
                  onKeyUp={this.handleKeyChange}
                  onClick={(e) => this.handleSelect(i, e)}
                  selected={i === currentFocus}
                >
                  {type === 'country' && <span dangerouslySetInnerHTML={{ __html: this.highlightText(data) }} />}
                  {(type === 'airport' || type === 'hoteldest') &&
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      className="textEllipsis"
                    >
                      <div style={{ marginRight: 10 }}>
                        <img src={Icon} alt="" />
                      </div>
                      <div className="textEllipsis" style={{ display: 'flex', flexFlow: 'column' }}>
                        <div className="textEllipsis" style={{ lineHeight: '19px' }}>
                          <span dangerouslySetInnerHTML={type === 'airport' ? { __html: this.highlightText(data.city) } : { __html: this.highlightText(data.name) }} />
                          <span style={{ marginLeft: 5 }}>(<span style={{ lineHeight: '16px' }} dangerouslySetInnerHTML={type === 'airport' ? { __html: this.highlightText(data.airportIc) } : { __html: this.highlightText(data.code) }} />)</span>
                        </div>
                        <span className="smallText textEllipsis" dangerouslySetInnerHTML={type === 'airport' ? { __html: this.highlightText(data.airportDesc) } : { __html: this.highlightText(data.name) }} />
                      </div>
                    </div>}
                </StyledMenuItem>
              ))}
            </StyledMenuList>}
          </Paper>
        </Wrapper>
      </ClickOutside>
    )
  }
}
