import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { getInfluencer } from "containers/Influencer/queries";
import { setInfluencer } from "redux-store/actions";
import { Loader } from "components/common";
import { getItem } from "utils/utils";
import check from "assets/tip/Check-circle.svg";

const Steps = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  .step {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    margin: 0 20px;
    .number {
      background: rgba(208, 243, 252, 0.5);
      box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.08);
      border-radius: 10em;
      height: 32px;
      width: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #56ccf2;
      font-size: 16px;
      font-weight: 900;
    }
    .text {
      font-family: "Roboto Condensed", sans-serif;
      color: #777777;
      font-size: 16px;
      margin-top: 10px;
      &.blue {
        color: #56ccf2;
      }
    }
    &.active {
      .number {
        background-color: #56ccf2;
        color: #fff;
      }
      .text {
        color: #007ac7;
      }
    }
    &.completed {
      .number {
        position: relative;
        background-color: #ffffff;
        border: 2px solid #56ccf2;
        color: #007ac7;
        img {
          position: absolute;
          right: -5px;
          background: #fff;
          top: -2px;
          border: 1px solid #fff;
          border-radius: 50%;
        }
      }
    }
  }
`;

const steps = [
  {
    step: 1,
    text: "Setup Site",
  },
  {
    step: 2,
    text: "Connect",
  },
  {
    step: 3,
    text: "First Post",
  },
  {
    step: 4,
    text: "Publish",
  },
];

class StepsComp extends React.PureComponent {
  state = {};
  async componentWillMount() {
    if (!getItem("useraccount_id")) {
      return;
    }
    if (this.props.influencerDetails) {
      this.handleRedirect(this.props.influencerDetails);
      return;
    }
    try {
      const influencer = await this.props.client.query({
        query: getInfluencer,
        variables: {
          limit: 1,
          where: {
            fsuseraccount_id: { _eq: getItem("useraccount_id") },
          },
        },
        fetchPolicy: "no-cache",
      });
      if (influencer.data.influencer.length > 0) {
        const influencerDetails = influencer.data.influencer[0];
        if (influencerDetails.fsuseraccount_id === getItem("useraccount_id")) {
          await this.props.setInfluencer(influencerDetails);
          if (this.props.handleFetchComplete) {
            this.props.handleFetchComplete();
          }
          this.handleRedirect(influencerDetails);
        }
      } else {
        this.props.handleStep1();
      }
    } catch (err) {
      console.log("err", err);
    }
  }
  handleRedirect = (influencer) => {
    if (
      influencer.posts.length > 0 &&
      influencer.posts.find(post => post.lastpublished_at != null) &&
      !window.location.href.includes("setup/share")
    ) {
      this.props.history.push("/influencer/dashboard/posts");
    }
  };
  render() {
    const { activeStep, fetchComplete } = this.props;
    return activeStep === 1 && !fetchComplete ? (
      <Loader />
    ) : (
        <Steps>
          {steps.map((step, index) => (
            <div
              className={`step ${activeStep === step.step ? "active" : ""} ${index + 1 < activeStep ? "completed" : ""
                }`}
              key={step.step}
            >
              <div className="number">
                {index + 1 < activeStep && <img src={check} alt="" />}
                <span>{step.step}</span>
              </div>
              <div className={`text ${activeStep ? "blue" : ""}`}>
                {step.text}
              </div>
            </div>
          ))}
        </Steps>
      );
  }
}

const mapStateToProps = (state) => ({
  influencerDetails: state.influencer.influencerDetails,
});

export default withRouter(
  withApollo(connect(mapStateToProps, { setInfluencer })(StepsComp))
);
