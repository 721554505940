import gql from "graphql-tag";

export const submitAvailabilityRequestGql = gql`mutation submitAvailabilityRequest(
    $availabilityInput: AvailabilityInput!
  ) {
    submitAvailabilityRequest(
        availabilityInput: $availabilityInput
    ) {
        _key,
        currency,
        destinationCode,
        occupancies {
            rooms,
            adults,
            children,
            paxes {
                type,
                age
            }
        }
        hotels {
            code,
            currency
            name,
            categoryCode,
            categoryName,
            rooms {
                name,
                rates {
                    rooms,
                    adults,
                    children,
                    packaging,
                    boardName,
                    customCurrency,
                    customCurrencySellingRate,
                    customPayAtHotelRate,
                    rateKey,
                    rateType,
                    rateClass,
                }
            },
            hotelcontent {
                code,
                email,
                images {
                    path,
                    order,
                    visualOrder,
                    imageTypeCode
                }
            },
            reviews {
                rate,
                reviewCount,
                type
            }
        }
    }
}
  `;


export const setupBookingRequestGql = gql`mutation setupBookingRequest(
      $bookingInput: BookingInput!
  ){
  setupBookingRequest(
    bookingInput: $bookingInput
  ){
    tripId,
    destinationCode,
    totalCustomCurrencySellingRate,
    customCurrency
  }
}`;

export const bookingByTripIdGql = gql`{
    bookingByTripIdGql(
        tripId: $tripId
    ) {
        tripId,
        destinationCode,
        selectedHotelCode,
        clientReference,
        booking {
            reference,
            clientReference,
            status,
            hotel {
                code,
                currency
                name,
                categoryCode,
                categoryName,
                checkOut,
                checkIn,
                rooms {
                    status,
                    rates {
                        cancellationPolicies {
                            amount,
                            from
                        }
                    }
                },
                supplier {
                    name
                }
            }
        }
    }
}
  `;
