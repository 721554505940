  import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { AirlineImg, AirlineName } from 'components/common';
import { FlightFromError, FlightToError } from 'components/common/SvgIcons';
import FlightNumAndDate from 'components/FlightSegment/Partials/FlightNumAndDate';
import AirportDetails from './Partials/AirportDetails';
import AirportDesc from './Partials/AirportDesc';
import TripLabel from './Partials/TripLabel';
import getTranslation from 'utils/getTranslation';
import { underscoreToSpace } from 'utils/utils';
import DateIcon from 'assets/icons/Date.svg';
import ClockIcon from 'assets/icons/Clock.svg';
import TerminalIcon from 'assets/icons/Terminal.svg';
import BaggageIcon from 'assets/icons/Baggage-On.svg';

const Wrapper = styled.div`
  width: 776px;
  margin: auto;
  margin-top: 20px;
  @media (max-width: 800px) {
    width: 100%;
  }
  .flightLabel {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #DDDDDD;
    color: #555555;
    font-size: 14px;
    font-family: 'Roboto Condensed', sans-serif;
    text-transform: uppercase;
  }
  .detailsWrapper {
    padding-top: 20px;
    background-color: #ffffff;
  }
  .airline {
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 800px) {
      flex-flow: column;
    }
    .details {
      display: flex;
      align-items: center;
      color: #222222;
      @media (max-width: 800px) {
        flex-flow: column;
        img {
          margin-bottom: 20px;
        }
      }
    }
    img {
      height: 30px;
      margin-right: 15px;
    }
    .pnr {
      width: 376px;
      background: #FFFFFF;
      border: 1px solid #DDDDDD;
      border-radius: 4px;
      padding: 5px 0;
      text-align: center;
      @media (max-width: 800px) {
        width: 100%;
        margin-top: 15px;
      }
      span {
        color: #777777;
        font-size: 18px;
      }
    }
  }
  .container {
    background-color: #FFFFFF;
    display: flex;
    margin-top: 15px;
    @media (max-width: 800px) {
      flex-flow: column;
      .leftSection, .rightSection {
        width: 100%;
      }
    }
  }
  .trip-card {
    padding: 16px;
    background: #FFFFFF;
    width: 180px;
    align-items: flex-start;
    display: flex;
    flex-flow: column;
    @media (max-width: 800px) {
      width: 50%;
    }
    .iconRow {
      display: flex;
      align-items: center;
      img, svg {
        margin-right: 5px;
      }
    }
    span {
      color: #777777;
      font-size: 12px;
      font-weight: 300;
      font-family: 'Roboto Condensed', sans-serif;
    }
    h2 {
      font-size: 28px;
      color: #222222;
      letter-spacing: 0.5px;
      font-weight: 400;
    }
  }
  .leftSection {
    width: 376px;
    .trip {
      display: flex;
      justify-content: space-between;
    }
    .timing {
      margin-top: 15px;
      display: flex;
      justify-content: space-between;
      .item {
        background-color: #fff;
        width: 180px;
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        padding: 16px;
        margin-bottom: 0;
        @media (max-width: 800px) {
          width: 50%;
        }
        span {
          color: #777777;
          font-size: 12px;
          font-weight: 300;
          font-family: 'Roboto Condensed', sans-serif;
        }
        .line {
          background: #DDDDDD;
          height: 1px;
          width: 50%;
          margin-top: 10px;
        }
        p {
          font-size: 17px;
          color: #222222;
        }
      }
    }
  }
  .rightSection {
    width: 376px;
    display: flex;
    flex-flow: column;
    @media (max-width: 800px) {
      margin-left: 0;
    }
    .trip {
      display: flex;
      justify-content: space-between;
    }
    span {
      color: #777777;
      font-size: 12px;
      &.large {
        color: #444444;
        letter-spacing: 0.5px;
        font-size: 20px;
      }
    }
    h5 {
      margin: 0;
      font-size: 40px;
      color: #222222;
      font-weight: 300;
    }
  }
`;

export default class extends React.PureComponent {
  state = {
    expand: true
  };
  handleToggle = () => {
    this.setState({
      expand: !this.state.expand
    });
  }
  checkExtraBaggage = (hopRequest) => {
    let hasExtraBaggage = false
    hopRequest.passengers.forEach(passenger => {
      if (passenger.extraBaggages.length > 0) {
        hasExtraBaggage = true;
      }
    });
    return hasExtraBaggage;

  }
  render() {
    const { expand } = this.state;
    const { hopRequest } = this.props;
    const hopSegmentControl = hopRequest.hopSegmentControls[0];
    const flightInfo = hopRequest.hopSegmentControls[0].flightSegments[0];
    const arTime = flightInfo.staTime.split(':');
    const hours = Math.trunc(flightInfo.durationMins / 60);
    const mins = flightInfo.durationMins % 60;
    const arrivalDate = flightInfo.staDate;
    return (
      <Wrapper>
        <div className="flightLabel">
          <TripLabel airportIc={hopRequest.toAirportIc} />
        </div>
        <FlightNumAndDate
          flightInfo={flightInfo}
          collapsable
          expanded={expand}
          handleClick={this.handleToggle}
        />
        {expand && <div className="detailsWrapper">
        <div className="airline">
          <div className="details">
            <AirlineImg airlineIc={flightInfo.airlineIc} />
            <span>
            <AirlineName airlineIc={flightInfo.airlineIc} />
            <span style={{ marginLeft: 3 }}> (<b>{flightInfo.airlineIc} {flightInfo.flightNum}</b>), {underscoreToSpace(hopSegmentControl.seatClass)}</span></span>
         </div>
         <div className="pnr">
           <span>Airline PNR - {hopSegmentControl.airlinePnr}</span>
         </div>
        </div>
        <div className="container">
        <div className="leftSection">
          <div className="trip">
            <div className="trip-card">
              <div className="iconRow">
                <img src={DateIcon} alt="" /> <span className="large">{getTranslation('date')}</span>
              </div>
              <h2>{moment(hopRequest.hopSegmentControls[0].flightSegments[0].stdDate).format('MMMM')} {moment(hopRequest.hopSegmentControls[0].flightSegments[0].stdDate).format('D')}</h2>
              <span className="large">{moment(hopRequest.hopSegmentControls[0].flightSegments[0].stdDate).format('ddd')}</span>
            </div>
            <div className="trip-card">
              <div className="iconRow">
                <img src={ClockIcon} alt="" /> <span className="large">{getTranslation('departure')}</span>
              </div>
              <h2>{flightInfo.stdTime.slice(0, -3)}</h2>
              <span className="large">{getTranslation('localTime')}</span>
            </div>
          </div>
          <div className="timing">
            <div className="item">
              <span>{getTranslation('duration')}</span>
              <div className="line" />
              <p>{hours} {getTranslation('hours')}<br />
              {mins} {getTranslation('minutes')}</p>
            </div>
            <div className="item">
              <span>{getTranslation('arrival')}</span>
              <div className="line" />
              <p>{`${arTime[0]}:${arTime[1]}`} {getTranslation('hrs')},<br />
              {moment(arrivalDate).format('MMMM')} {moment(arrivalDate).format('D')} ({moment(arrivalDate).format('ddd')})</p>
            </div>
          </div>
        </div>
        <div className="rightSection">
          <div className="trip">
          <div className="trip-card">
            <div className="iconRow">
              <FlightFromError stroke="#777777" style={{ height: 16, width: 16 }} /> <span>{getTranslation('fromAirport')}</span>
            </div>
            <AirportDetails airportIc={hopRequest.hopSegmentControls[0].flightSegments[0].fromAirportIc} />
          </div>
          <div className="trip-card">
            <div className="iconRow">
              <FlightToError stroke="#777777" style={{ height: 16, width: 16 }} /> <span>{getTranslation('destination')}</span>
            </div>
            <AirportDetails airportIc={hopRequest.hopSegmentControls[0].flightSegments[0].toAirportIc} />
          </div>
          </div>
          <div className="trip">
          <div className="trip-card">
            <div className="iconRow">
              <img src={TerminalIcon} alt="" /> <span>{getTranslation('terminal')}</span>
            </div>
            <h2>{(flightInfo.fromTerminal && flightInfo.fromTerminal.length > 0) ? flightInfo.fromTerminal : '--'}</h2>
            <span>({hopRequest.hopSegmentControls[0].flightSegments[0].fromAirportIc})</span>
            <AirportDesc airportIc={hopRequest.hopSegmentControls[0].flightSegments[0].fromAirportIc} />
          </div>
          <div className="trip-card">
            <div className="iconRow">
              <img src={BaggageIcon} alt="" /> <span>{getTranslation('standardBaggage')}</span>
            </div>
            <h2>{hopRequest.hopSegmentControls[0].baggagePieceCount}</h2>
            <span>{hopRequest.hopSegmentControls[0].baggagePieceMaxWeight} {getTranslation('perKgPc')}</span>
            <div style={{ height: 1, width: '50%', backgroundColor: '#DDDDDD', margin: '5px 0' }} />
            <span>{getTranslation('perPassenger')}</span>
            {this.checkExtraBaggage(hopRequest) && <React.Fragment>
              <div style={{ marginTop: 20 }} />
              <div className="iconRow">
                <img src={BaggageIcon} alt="" /> <span>{getTranslation('extraBaggage')}</span>
              </div>
              {hopRequest.passengers.map(passenger => (
                <React.Fragment>
                  <h2>{passenger.extraBaggages[0].baggagePieceMaxWeight}</h2>
                  <span>{passenger.extraBaggages[0].baggagePieceMaxWeight} {getTranslation('perKgPc')}</span>
                  <div style={{ height: 1, width: '50%', backgroundColor: '#DDDDDD', margin: '5px 0' }} />
                  <span>{passenger.firstName} {passenger.lastName}</span>
                </React.Fragment>
              ))}
            </React.Fragment>}

          </div>
          </div>
        </div>
        </div>
      </div>}
      </Wrapper>
    );
  }
}
