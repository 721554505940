export default `
# Termini e condizioni

Questi termini e condizioni generali (&quot;Termini e Condizioni&quot;) si applicano tra il &quot;Cliente&quot; (&quot;voi&quot;, &quot;lei&quot; o l&#39;&quot;Utente&quot;) e Fly Strait OÜ (&quot;la Compagnia&quot;, &quot;noi&quot; o &quot;Flystrait&quot;), l&#39;operatore del portale di prenotazione flystrait.com (di seguito indicato come il &quot;Portale web&quot;). Si richiede di leggere attentamente questi Termini e Condizioni di utilizzare il Portale web. Usando il portale, il Cliente accetta questi Termini e Condizioni. Se non si accettano questi Termini e Condizioni, le chiediamo di astenersi dall&#39;utilizzare il Portale e quindi di lasciare il sito.

Il Cliente deve avere almeno 18 anni di età e agire nel suo ruolo di consumatore privato per effettuare una prenotazione sul Portale web. Un consumatore privato è, al contrario di un&#39;azienda, una persona fisica che entra in una transazione legale per scopi che sono prevalentemente al di fuori delle sue attività commerciali e / o autonomi.

Il gestore del portale e il tuo partner contrattuale per l&#39;utilizzo del portale è:

Fly Strait OÜ (Fly Strait)

E-mail: support@flystrait.com

I Termini e Condizioni sono definiti come segue:

#
# 1. Ambito dei servizi

### 1.1 Mediazione di servizi di viaggio

1.1.1 Per i servizi di prenotazione di voli offerti sul Portale (denominati &quot;Servizi di viaggio&quot;), agiamo esclusivamente in qualità di intermediario. A tal fine, il nostro ruolo e nostri obblighi sono limitati alla mediazione dei servizi di viaggio che saranno resi presso terzi come le compagnie aeree (di seguito, in ogni caso, &quot;Fornitore di servizi&quot;).

1.1.2 Di conseguenza, l&#39;accordo per la fornitura effettiva di servizi di viaggio diventa effettivo direttamente tra il Cliente e il fornitore di servizi pertinente. Non siamo un co-fornitore dei servizi di viaggio e non siamo parte del rapporto contrattuale tra il Cliente e il fornitore di servizi.

1.1.3 Utilizzando il portale per acquistare servizi di viaggio, il Cliente ci autorizza a mediare con i corrispondenti fornitori di servizi per il suo conto, inclusa la mediazione del pagamento per questi servizi di viaggio, al fine di garantire che la transazione tra essi e il/i fornitore/i di servizi venga eseguita. Il prezzo emesso per i servizi di viaggio effettivi può includere una commissione per i servizi resi per la mediazione dell&#39;accordo tra il Cliente e il relativo fornitore di servizi.

1.1.4 La responsabilità per l&#39;effettiva prestazione dei servizi di viaggio mediati attraverso il portale è detenuta esclusivamente dal fornitore di servizi competente. In qualità di intermediario, non ci assumiamo alcuna responsabilità per i Servizi di viaggio da parte dei fornitori di servizi e non rilasciamo alcuna dichiarazione o garanzia (né espressa né implicita) in merito all&#39;adeguatezza o alla qualità dei servizi di viaggio mediati sul portale. Per qualsiasi reclamo contestualmente connesso all&#39;esecuzione o alla mancata esecuzione del servizio di viaggio, il fornitore di servizi è il destinatario responsabile.

1.1.5 Se il Cliente gradisce la richiesta di desideri speciali (come pasti speciali, strutture per disabili o seggiolini per bambini) al momento della prenotazione dei servizi di viaggio, passeremo la vostra richiesta al fornitore di servizi competente. Tuttavia, non possiamo assumerci alcuna responsabilità sul fatto che il fornitore di servizi possa effettivamente soddisfare tali desideri.

### 1.2 Condizioni contrattuali applicabili

1.2.1 I presenti Termini e Condizioni si applicano alla mediazione dei servizi di viaggio in base alla Sezione 1.1, nonché ai nostri servizi ai sensi della Sezione 1.2.

1.2.2 Per l&#39;accordo tra il Cliente e il fornitore di servizi attenente (sezione 1.1.2), si applicano i termini e le condizioni generali del fornitore di servizi competente (come le condizioni di trasporto, condizioni di trasferimento, condizioni di assicurazione o simili) tale che indicati da esso. Tali termini e condizioni generali del fornitore di servizi attenente saranno notificati al Cliente nel corso del processo di prenotazione. Poiché i termini e le condizioni del fornitore di servizi possono includere disposizioni relative a responsabilità, cancellazione, modifiche di prenotazioni e rimborsi (se disponibili) e altre restrizioni, si consiglia di leggere attentamente tali termini.

#
# 2. Processo di prenotazione e informazioni di contatto

2.1 Durante il processo di prenotazione, vengono forniti i mezzi tecnici necessari per rilevare gli errori nei moduli di iscrizione e correggerli prima di inviare la richiesta di prenotazione. Si richiede di verificare tutti i dati per verificarne la precisione prima di concludere la richiesta di prenotazione. Successive richieste di modifica possono comportare significativi costi aggiuntivi.

2.2 Potremmo aver bisogno di contattarvi, ad esempio in caso di modifiche successive ai Servizi di viaggio acquistati. È necessario inviare informazioni di contatto accurate come il numero di telefono e l&#39;indirizzo e-mail. Il Cliente si deve anche di continuamente controllare se hai ricevuto un messaggio da Noi.

È anche responsabilità dell&#39;utente assicurarsi di poter ricevere i nostri messaggi. Non siamo responsabili se non ricevete un messaggio da Noi a causa di circostanze ragionevolmente al di fuori del nostro controllo incluso, ma non limitato a quanto segue: (i) ci avete fornito un indirizzo email errato; (ii) le impostazioni e-mail non consentiranno alla nostra posta elettronica di raggiungerti; o (iii) le impostazioni e-mail considerano la nostra e-mail come spam.

#
# 3. Mediazione dei voli

### 3.1 Conclusione del contratto e modifiche dei prezzi

3.1.1 Dopo aver inviato la richiesta di prenotazione, confermeremo la ricezione della richiesta via email. Questo è il momento in cui il contratto di mediazione tra il Cliente e Fly Strait viene ad esistenza. Se non ricevi tale conferma entro un&#39;ora dal completamento della richiesta di prenotazione e nessuno dei nostri operatori ti ha contattato via email o telefono per segnalare eventuali problemi, ti preghiamo di contattarci telefonicamente per la verifica.

3.1.2 Non appena i biglietti richiesti sono stati emessi, riceverai un&#39;email di conferma con un numero di biglietto. Questo è il momento in cui è stato concluso un contratto vincolante tra il Cliente e la/le compagnia/e aerea/e interessata/e.

3.1.3 I prezzi per il volo, così come la disponibilità dei posti, sono direttamente inseriti dalla compagnia aerea competente. Per le variazioni del prezzo delle compagnie aeree fuori dal nostro controllo che si verificano dopo la presentazione della richiesta di prenotazione, ma prima che il contratto con la compagnia aerea sia diventato vincolante (come indicato nella Sezione 3.1.2 sopra), il contratto non entrerà in vigore e il pagamento sarà integralmente rimborsato. Potremmo contattarvi e offrirvi la possibilità di accettare il prezzo modificato durante gli orari di apertura ordinari, comunque non oltre 24 ore dopo che abbiamo acquisito conoscenza del cambio di prezzo o del primo giorno della settimana successivo a tale periodo di 24 ore.

### 3.2 Informazioni sulla prenotazione e condizioni di trasporto

3.2.1 Per quanto riguarda i voli offerti sul Portale, agiamo esclusivamente in qualità di intermediario. L&#39;accordo per l&#39;esecuzione del volo entra in vigore direttamente tra l&#39;utente e la compagnia aerea interessata e non accettiamo alcuna responsabilità contrattuale relativa alla prestazione o alla mancata esecuzione del volo. La compagnia aerea operativa è l&#39;unica responsabile per le prestazione/mancata esecuzione del vostro volo.

3.2.2 Prima di concludere la prenotazione, i termini e le condizioni della/e compagnia/e aerea/e competente saranno messi a vostra disposizione.

3.2.3 Di seguito, mediante una panoramica generale, forniamo informazioni sulle condizioni relative alla prenotazione e al trasporto tipicamente applicate dalle compagnie aeree in tale o almeno in simile modo. Tuttavia, eventuali disposizioni divergenti della compagnia aerea interessata prevalgono sulle informazioni generali fornite in questa sezione 3.2.3. Pertanto, in ogni caso, si prega di verificare i termini e le condizioni applicabili della compagnia aerea interessata prima della prenotazione.

#### a. Orari di volo/check in

Tutti i tempi di volo sono espressi in ora locale. Gli arrivi del giorno successivo sono indicati con un &quot;+1&quot; sull&#39;orario. I tempi di volo indicati sono preliminari e sono soggetti a modifiche a breve termine dopo che il biglietto è stato emesso; ad esempio, a causa di restrizioni del controllo del volo, del tempo o di restrizioni funzionali da parte della compagnia aerea. Il Cliente è pregato di tenersi informato sugli orari attuali con un buon anticipo rispetto alla partenza del volo.

Si prega di rispettare gli orari di check-in indicati dalla compagnia aerea. Le compagnie aeree hanno il diritto di rifiutare l&#39;imbarco se il Cliente è in ritardo per il check-in. Si prega di osservare che alcune compagnie aeree incoraggiano il check-in attraverso il proprio sito web in quanto possono addebitare una tassa di check-in al momento del check-in manualmente in aeroporto.

#### b. Combinazione di singoli biglietti

Una combinazione di due biglietti di sola andata separati anziché di un biglietto di andata e ritorno è chiaramente indicata come tale durante la procedura di prenotazione. In caso di cancellazione, modifica, interruzione del traffico aereo come scioperi e modifiche agli orari dei voli, i biglietti sono trattati indipendentemente l&#39;uno dall&#39;altro. Si applicheranno i regolamenti di ciascuna compagnia aerea.

I biglietti aerei con numeri di prenotazione diversi sono sempre considerati viaggi indipendenti l&#39;uno dall&#39;altro.

#### c. Biglietti aerei con diversi segmenti/ordine di utilizzo

Il tuo biglietto di andata e ritorno o biglietto di sola andata può essere composto da diversi segmenti. Secondo le condizioni della maggior parte delle compagnie aeree, tali segmenti di volo devono essere utilizzati in sequenza. In caso contrario, molte compagnie aeree rifiuteranno il trasporto su segmenti di volo successivi (ad es. Il mancato utilizzo di un segmento di un viaggio potrebbe invalidare il resto del biglietto). Per i biglietti di andata e ritorno, la mancata presentazione del volo in partenza potrebbe comportare la cancellazione del volo di andata da parte della compagnia aerea

#### d. Gravidanza

Alcune compagnie aeree si rifiutano di trasportare donne che hanno trascorso la 28a settimana di gravidanza sia in andata che in ritorno. In caso di gravidanza, il Cliente deve chiarire con la compagnia aerea e il suo medico se è possibile iniziare il viaggio.

#### e. Neonati e biglietti per bambini

Si prega di contattare la compagnia aerea per ottenere le condizioni di viaggio con un bambino che non ha un posto separato. Di solito, i bambini di età superiore a 2 anni richiedono un posto separato, mentre i bambini di età compresa tra 0 e 2 anni viaggiano come neonati e non gli verrà assegnato un posto a parte. Se il bambino raggiunge l&#39;età di 2 anni prima della fine del viaggio, è necessario prenotare un biglietto per bambini per l&#39;intero viaggio. I biglietti per neonati non possono essere prenotati prima della nascita, in quanto il nome e la data di nascita corretti devono corrispondere a quelli indicati sul passaporto. Non rimborseremo alcuna spesa che sorgesse se il tipo di biglietto sbagliato è prenotato fin dall&#39;inizio.

#### f. Minori non accompagnati

Non mediamo la prenotazione di biglietti per minori non accompagnati. I minori di 18 anni devono essere prenotati per un viaggio in compagnia di un adulto. Alcuni paesi e compagnie aeree rifiutano l&#39;ingresso a minori sotto di 18 anni se non accompagnati da un tutore legale. Si prega di notare che alcune compagnie aeree richiedono ai bambini di età inferiore ai 18 anni di portare un certificato di nascita per viaggiare.

#### g. Bagagli persi/danneggiati

Nell&#39;ambito della nostra capacità di intermediario, non ci assumiamo alcuna responsabilità per il bagaglio smarrito o danneggiato. Eventuali problemi devono essere segnalati immediatamente al rappresentante della compagnia aerea in aeroporto.

#### h. Transito e pernottamento

In generale, il trasferimento verso il luogo del pernottamento e/o il propio pernottamento durante il viaggio non è incluso nel prezzo del biglietto aereo. Il Cliente è personalmente responsabile della verifica degli orari e dei prezzi dei trasporti terrestri.

#### i. Tempi di collegamento tra i voli

I biglietti standard prenotati sul Portale contengono acconsentiti tempi di collegamento. I tempi necessari per effettuare collegamenti tra i voli sono calcolati dalle compagnie aeree. Se un segmento di volo è in ritardo e porta a un collegamento perso, le compagnie aeree sono tenute ad assistervi nel raggiungere la destinazione finale (vedi Sezione 9.1).

Quando vengono prenotati biglietti separati, le compagnie aeree non si assumono alcuna responsabilità per i collegamenti persi per causa di ritardi. Pertanto, è responsabilità dell&#39;utente assicurarsi che i tempi di collegamento siano sufficienti in base alle compagnie aeree e agli aeroporti. Eventuali costi aggiuntivi sostenuti a causa di mancati collegamenti non saranno rimborsati.

#### j. Doppia prenotazione

Una doppia prenotazione significa che due o più prenotazioni con lo stesso nome di passeggero sono state effettuate presso la stessa compagnia aerea. Se hai una doppia prenotazione, la compagnia aerea può annullare il/i viaggio/i. Ciò può verificarsi anche se le prenotazioni sono state effettuate con diverse agenzie di viaggio. Nei casi in cui le compagnie aeree sospettano una doppia prenotazione, non siamo responsabili per le cancellazioni fatte né per i rimborsi negati da esse.

### 3.3 Le compagnie aeree vietate di operare nell&#39;UE (lista nera)

Si noti che a determinate compagnie aeree è vietato operare all&#39;interno dell&#39;UE, secondo una decisione presa dalla Commissione europea in stretta consultazione con le autorità nazionali di trasporto aereo. Tali compagnie aeree sono vietate perché sono considerate non sicure o non soggette ad un controllo adeguato da parte delle autorità del loro paese.

Puoi verificare quali compagnie aeree sono interessate da un divieto operativo tramite il seguente link: [Lista nera (Elenco delle compagnie aeree non autorizzate a operare nell&#39;UE)](https://ec.europa.eu/transport/language-selector/site-language_en?destination=node/2840)

### 3.4 Modifica dell&#39;orario e cancellazione da parte delle compagnie aeree

3.4.1 L&#39;accordo del Cliente con la compagnia aerea applicabile potrebbe consentire loro di cancellare o modificare le vostre prenotazioni. Il Cliente sarà informato di eventuali cambiamenti una volta che Fly Strait sarà stato informato dalla compagnia aerea.

3.4.2 Gli orari di volo indicati nella conferma di prenotazione possono variare tra la data della prenotazione e la data in cui il Cliente viaggerà. In caso di modifiche al programma di volo, non appena Fly Strait sarà informato dalla compagnia aerea, il Cliente sarà anche lui informato. Tuttavia, consigliamo vivamente di contattare la compagnia aerea almeno 72 ore prima della partenza programmata di ogni volo per garantire che i voli (e tutti i voli in coincidenza) operino nei tempi previsti. Non abbiamo alcun controllo sulle variazioni di orario della compagnia aerea e non ci assumiamo alcuna responsabilità per i costi che potrebbero derivare da tali cambiamenti.

### 3.5 Modifiche e cancellazioni richieste dal Cliente

Per le compagnie aeree a basso costo (low cost), consultare la sezione 3.7.

3.5.1 Le condizioni per modificare le prenotazioni dei voli (compreso il cambio del nome del passeggero, la destinazione e la data del viaggio) e per fornire i rimborsi di cancellazione sono stabilite dalla compagnia aerea competente, che è il vostro partner contrattuale per fornire il volo. Fly Strait, come intermediario, non ha alcuna influenza su tali condizioni.

3.5.2 Se desiderate apportare una modifica alla vostra prenotazione o richiedere un rimborso per la cancellazione, come servizio aggiuntivo, possiamo offrirle di gestire la richiesta per il vostro conto, a condizione che le condizioni della compagnia aerea consentano tale modifica o il rimborso dell&#39;annullamento. Durante il processo di prenotazione di tali servizi aggiuntivi, il Cliente si verrà informato di eventuali ulteriori condizioni per tali servizi. Alternativamente, puoi, naturalmente, accedere direttamente alla compagnia aerea competente.

3.5.3 Per permetterci di essere in grado di gestire le modifiche richieste dal Cliente, è necessario che riceviamo le richieste di modifica entro 24 ore prima dell&#39;inizio del viaggio (solo per telefono). Se avete acquistato il nostro servizio di biglietto flessibile, le preghiamo di consultare la sezione 10.

Per richieste di modifica con preavviso più breve, ti consigliamo di contattare direttamente la compagnia aerea interessata.

### 3.6 Mancata comparizione o mancata partecipazione al volo

Con la presente il Cliente ci autorizza a cancellare il volo non utilizzato per suo conto in caso di mancata comparizione o mancata presenza del volo e di richiedere eventuali rimborsi dalla compagnia aerea per suo conto. Siamo autorizzati ma non obbligati a farlo e il diritto di richiedere rimborsi direttamente dalla compagnia aerea rimane inalterato.

### 3.7 Compagnie aeree a basso costo (low cost)

3.7.1 Verrà visualizzato chiaramente durante la procedura di prenotazione se il volo applicabile è gestito da una compagnia aerea a basso costo. Le seguenti disposizioni speciali si applicano ai voli effettuati da compagnie aeree a basso costo

3.7.2 Nel mediare i servizi di viaggio resi da compagnie aeree a basso costo, possiamo agire a nome del Cliente nel concludere il contratto tra esso e la compagnia aerea low cost.

3.7.3 Non abbiamo accesso ai sistemi di prenotazione delle compagnie aeree a basso costo. Per domande su prenotazioni, modifiche o cancellazioni della prenotazione, si prega dunque il Cliente di contattare direttamente la compagnia aerea low cost. Se il servizio Flexible Ticket (Biglietto flessibile) è stato acquistato (vedere la Sezione 10), tutte le richieste di riprenotazione devono essere effettuate tramite il nostro servizio clienti come stabilito nella Sezione 10.1.4.

3.7.4 Si noti inoltre che alcune compagnie aeree a basso costo applicano tariffe per il check-in manuale in aeroporto. Al fine di evitare tali commissioni, è necessario utilizzare la possibilità di effettuare il check-in online attraverso il sito Web della compagnia aerea a basso costo competente. Si prega di informarsi in tempo utile prima della partenza circa le possibilità di check-in e le condizioni della propria compagnia aerea.

3.7.5 Le conferme di prenotazione per i voli low cost vengono inviate non appena la prenotazione è stata confermata dalla compagnia aerea low cost interessata. Potrete ricevere due conferme di prenotazione: una da noi e una dalla compagnia aerea a basso costo. Se il Cliente ha ricevuto due conferme di prenotazione, si prega di utilizzare la conferma di prenotazione della compagnia aerea low cost per il check-in.

#
# 4. Disposizioni speciali per la mediazione delle prenotazioni di più servizi

Il portale offre la possibilità di combinare più servizi singoli come desiderato dal Cliente (ad es. Volo + hotel). In questo caso, il Cliente ci indica a fare da intermediario per la prenotazione di vari servizi di viaggio da vari fornitori. I rilevanti fornitori saranno chiaramente presentati all&#39;Utente durante la procedura di prenotazione prima che la prenotazione sia completata. Nel caso in cui il Cliente combini i singoli servizi, nessun contratto di viaggio è concluso tra esso e Fly Strait; invece, il Cliente conclude diversi contratti per la fornitura di servizi individuali con ogni fornitore separatamente. In questo caso, operiamo esclusivamente nell&#39;ambito della nostra capacità di intermediario in relazione a ogni singolo servizio di viaggio.

#
# 5. Informazioni su passaporto, visti e condizioni sanitarie

5.1 Passaporto, visto e/o requisiti di salute possono cambiare e pertanto è necessario verificare con l&#39;autorità competente (ambasciata, consolato ecc.) con largo anticipo. È della responsabilità del Cliente essere in possesso di un passaporto valido e, se necessario, di un visto. È importante ricordare di includere tutti i punti di transito del viaggio che potrebbero anche richiedere che il Cliente ottenga un visto. Spesso è necessario un po &#39;di tempo per ottenere un visto, quindi si è consigliato applicare in anticipo. Non accettiamo alcuna responsabilità per i clienti che non possiedono i documenti corretti.

5.2 Ogni destinazione ha i propri requisiti per quanto riguarda le formalità d&#39;ingresso, le vaccinazioni, ecc. Che possono anche variare a seconda della nazionalità del passeggero. È della responsabilità del Cliente raccogliere queste informazioni. Nessun incidente derivante dall&#39;inosservanza di tali regolamenti ufficiali sarà considerato nostra responsabilità. Si esorta pertanto a verificare sempre le diverse formalità della destinazione scelta o dei paesi di transito, nonché il tempo necessario per prendere tutti i passaggi correlati.

#
# 6. Tariffe e pagamento

### 6.1 Pagamento

6.1.1 Il pagamento per i servizi di viaggio viene elaborato da Fly Strait (in collaborazione con i nostri fornitori di servizi di pagamento che forniscono la funzionalità di elaborazione dei pagamenti) o dal fornitore di servizi. Si prega di notare che potrebbe essere necessario condividere le informazioni di pagamento, come le informazioni sulla carta di credito o di debito, con i nostri fornitori di servizi di pagamento per poter elaborare il pagamento. Tutte le informazioni di pagamento appartenenti ai nostri clienti sono criptate in un server sicuro quando condivise con i nostri fornitori di servizi di pagamento.

6.1.2 Per consentirci di elaborare il pagamento, è necessario assicurarsi che ci siano fondi sufficienti disponibili. In caso di problemi relativi all&#39;elaborazione del pagamento, riproveremo l&#39;elaborazione del pagamento in collaborazione con i nostri fornitori di servizi di pagamento. Qualora ciò non si traduca in un prelievo appagato di fondi appartenendo al Cliente, la contatteremo al più presto per istruzioni su altri mezzi di pagamento. Se non dovessimo ricevere il pagamento dopo tali ulteriori istruzioni, il pagamento in sospeso sarà indirizzato a una società di recupero crediti.

### 6.2 Frode nei pagamento

Se ci sono motivi per sospettare che venga commessa una frode, ci riserviamo il diritto di rifiutare l&#39;elaborazione di tale pagamento. La legittimizzazione del pagamento può essere richiesta se si sospetta un crimine. Tutti i tipi di frode nei pagamenti saranno segnalati alla polizia e indirizzati a una società di recupero crediti.

#
# 7. Reclami

### 7.1 Reclami relativi alla prestazione dei servizi di viaggio

Qualsiasi problema, nota o reclamo relativo alla prestazione effettiva dei servizi di viaggio deve essere indirizzata direttamente al fornitore di servizi competente con il quale si detiene il contratto per il servizio di viaggio attenente. Si prega di consultare anche la sezione 9 per i propri diritti ai sensi della normativa UE.

### 7.2 Reclami relativi ai nostri servizi (Fly Strait)

I reclami relativi ai nostri servizi sono gestiti esclusivamente per iscritto e devono essere inviati entro due mesi dalla data di fine del viaggio via e-mail.

Indirizzo email: [complaints@flystrait.com](mailto:complaints@flystrait.com)

#
# 8. Diritti del cliente ai sensi della normativa UE

### 8.1 Voli cancellati o ritardati

Se il Cliente viaggia sia all&#39;interno o all&#39;esterno dall&#39;UE o da un vettore UE, potreste avere il diritto di chiedere il rimborso delle spese che può sostenere direttamente contro la compagnia aerea interessata nel caso in cui il suo volo venga cancellato, ritardato o che gli sia negato l&#39;imbarco. [Per ulteriori informazioni sul regolamento CE 261/2004, si prega di cliccare qui.](http://eur-lex.europa.eu/legal-content/EN/ALL/?uri=CELEX%3A32004R0261)

### 8.2 Responsabilità del vettore

Il regolamento CE (889/2002) sulla responsabilità del vettore aereo in caso di incidenti. [Il regolamento può essere trovato qui.](http://eur-lex.europa.eu/legal-content/EN/TXT/?uri=celex%3A32002R0889)

#
# 9. Responsabilità

10.1 Il Cliente accetta che operiamo come intermediario tra lui e il fornitore di servizi. Non saremo in alcun modo ritenuti responsabili in relazione ai servizi di viaggio che avete prenotato con uno o più fornitori di servizi e non accettiamo alcuna responsabilità per eventuali inesattezze nelle informazioni visualizzate sul portale fornite dal rispettivo fornitore di servizi.

10.2 Qualora un fornitore di servizi non sia in grado di fornire il servizio di viaggio per qualsiasi motivo, incluso il caso in cui un fornitore di servizi dichiari bancarotta, possiamo agire solo come intermediari e rimborsare i pagamenti se già lo abbiamo ricevuto dal fornitore di servizi applicabile.

10.3 Per quanto riguarda i nostri servizi, siamo responsabili per i danni soggetti alle limitazioni stabilite in questi Termini e Condizioni nella misura consentita dalla legge. Saremo responsabili solo per danni diretti effettivamente subiti, pagati o sostenuti dall&#39;Utente a causa di una penalità attribuibile ai nostri obblighi in relazione ai nostri servizi, fino a un importo complessivo del costo della prenotazione (per un evento o una serie di eventi collegati).

10.4 La limitazione di responsabilità di cui al punto 10.3 si applica anche alle violazioni dei doveri da parte di persone per colpa di cui siamo responsabili secondo le disposizioni di legge.

#
# 10. Legge applicabile e composizione delle controversie

### 10.1 Legge applicabile

La legge estone, con l&#39;esclusione del diritto sostanziale uniforme, in particolare il diritto delle vendite dell&#39;ONU sulla vendita internazionale di merci, si applica esclusivamente a questi Termini e Condizioni e al contratto tra il Cliente e noi.

In qualità di consumatore, il Cliente benefice di eventuali disposizioni obbligatorie della legge del paese in cui risiede. Nulla in questi Termini e Condizioni, inclusa questa Sezione 11.1, influisce sui diritti dei consumatori di fare affidamento su tali disposizioni obbligatorie della legge locale.

### 10.2 Risoluzione delle controversie online

La Commissione europea fornisce una piattaforma per la risoluzione delle controversie online (ODS) sotto: (http://ec.europa.eu/consumers/odr/)

#
# 11. Protezione dei dati

La protezione dei dati personali dell&#39;Utente è molto importante per noi. Il Cliente può trovare informazioni dettagliate sulla raccolta, l&#39;elaborazione e l&#39;uso dei suoi dati personali nella cosiddetta [&quot;Privacy Policy&quot;.](https://flystrait.com/privacy)
`;
