import React, { Component } from "react";
import styled, { withTheme } from "styled-components";
import { Button } from "components/common";
import { MaleIcon, FemaleIcon, EditIcon } from "components/common/SvgIcons";
import getTranslation from "utils/getTranslation";

const Passenger = styled.div`
  width: 332px;
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.08),
    inset 0px -1px 0px ${props => props.theme.secondary};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 60px;
  align-items: center;
  padding: 0 10px;
  @media (max-width: 800px) {
    width: 100%;
  }
  &:not(:last-child) {
    margin-bottom: 15px;
  }
  .passenger {
    display: flex;
    align-items: center;
  }
  .name {
    margin-left: 20px;
    display: flex;
    flex-flow: column;
    text-transform: capitalize;
    span {
      color: #222222;
      font-size: 16px;
    }
    .number {
      font-size: 12px;
      color: #777777;
    }
  }
`;

class PassengerClass extends Component {
  handleRemovePassenger = () => {
    this.props.handleRemovePassenger(this.props.passenger);
  };

  render() {
    const { passenger, number, theme } = this.props;
    const title = passenger.title ? passenger.title.toLowerCase() : "";
    return (
      <Passenger>
        <div class="passenger">
          <MaleIcon />
          <div className="name">
            <span className="number">
              {getTranslation("passenger")} #{number}
            </span>
            <span>
              {title}. {passenger.firstName} {passenger.lastName}
            </span>
          </div>
        </div>
        <div style={{ width: 40 }}>
          <Button
            style={{
              height: 40,
              minWidth: 40
            }}
            roundButton
            color="#F0F0F0"
            onClick={this.handleRemovePassenger}
          >
            <EditIcon
              stroke={theme.secondary}
              style={{ width: 24, height: 24 }}
            />
          </Button>
        </div>
      </Passenger>
    );
  }
}

export default withTheme(PassengerClass);
