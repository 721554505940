import React from "react";
import styled from "styled-components";
import { Button } from "components/common";
import getTranslation from "utils/getTranslation";

const Wrapper = styled.div``;

export default class extends React.PureComponent {
  // componentDidMount() {
  //   window.twttr.widgets.createTweet(
  //     "1220256518303412224",
  //     document.getElementById("tweet-container"),
  //     {
  //       theme: "light"
  //     }
  //   );
  // }
  render() {
    return (
      <Wrapper>
        {/* <div id="tweet-container" /> */}
        <div className="emptyQueue">{getTranslation("noQueue")}</div>
        <div className="connectNew">
          <h1>{getTranslation("getAccountSetup")}</h1>
          <div style={{ width: 280, marginTop: 30 }}>
            <Button>{getTranslation("connectSocialAcc")}</Button>
          </div>
        </div>
      </Wrapper>
    );
  }
}
