import React from "react";
import { connect } from "react-redux";
import { graphql, compose, withApollo } from "react-apollo";
import { updateInfluencer } from "redux-store/actions";
import {
  insertConnect,
  getInfluencerConnect,
  deleteConnect
} from "containers/Influencer/queries";
import { ConnectNetworks } from "containers/Influencer/Setup/Step2/Partials";

class ConnectSetup extends React.PureComponent {
  state = {
    influencerConnects: []
  };
  componentWillMount() {
    if (this.props.influencerDetails) {
      this.getInfluencerConnects(this.props.influencerDetails.id);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.influencerDetails && !this.props.influencerDetails) {
      this.getInfluencerConnects(nextProps.influencerDetails.id);
    }
  }

  getInfluencerConnects = async id => {
    this.handleToggleLoading(true);
    try {
      const response = await this.props.client.query({
        query: getInfluencerConnect,
        variables: {
          limit: 3,
          where: {
            influencer_id: { _eq: id }
          }
        }
      });
      this.setState({
        influencerConnects: response.data.influencerconnect
      });
      this.handleToggleLoading(false);
    } catch (err) {
      console.log(err);
      this.handleToggleLoading(false);
    }
  };
  handleToggleLoading = type => {
    const { handleToggleLoading } = this.props;
    if (handleToggleLoading) {
      handleToggleLoading(type);
    }
  };
  handleInsertConnect = async payload => {
    const { insertConnect, influencerDetails, updateInfluencer } = this.props;
    this.handleToggleLoading(true);
    const response = await insertConnect({
      variables: {
        objects: [
          {
            ...payload,
            is_active: true,
            influencer_id: influencerDetails.id
          }
        ]
      }
    });
    this.setState(
      {
        influencerConnects: [
          ...this.state.influencerConnects,
          response.data.insert_influencerconnect.returning[0]
        ]
      },
      () => {
        updateInfluencer({
          ...influencerDetails,
          influencerconnects: this.state.influencerConnects
        });
        this.handleToggleLoading(false);
      }
    );
  };

  handleDeleteConnect = async id => {
    this.handleToggleLoading(true);
    try {
      const response = await this.props.deleteConnect({
        variables: {
          where: {
            influencer_id: { _eq: this.props.influencerDetails.id },
            id: { _eq: id }
          }
        }
      });
      if (response.data.delete_influencerconnect) {
        this.setState(
          {
            influencerConnects: this.state.influencerConnects.filter(
              connect => connect.id !== id
            )
          },
          () => {
            this.props.updateInfluencer({
              ...this.props.influencerDetails,
              influencerconnects: this.state.influencerConnects
            });
            this.handleToggleLoading(false);
          }
        );
      }
    } catch (err) {
      console.log(err);
      this.handleToggleLoading(false);
    }
  };
  render() {
    const { influencerConnects } = this.state;
    const { dashboard } = this.props;
    return (
      <ConnectNetworks
        dashboard={dashboard}
        influencerConnects={influencerConnects}
        handleInsertConnect={this.handleInsertConnect}
        handleDeleteConnect={this.handleDeleteConnect}
      />
    );
  }
}

const mapStateToProps = state => ({
  influencerDetails: state.influencer.influencerDetails
});

export default withApollo(
  compose(
    graphql(insertConnect, { name: "insertConnect" }),
    graphql(deleteConnect, { name: "deleteConnect" }),
    connect(mapStateToProps, { updateInfluencer })
  )(ConnectSetup)
);
