import React from "react";
import styled, { withTheme } from "styled-components";
import AirlineName from "components/common/AirlineName";
import { CheckIconCircle } from "components/common/SvgIcons";
import PriceRange from "components/common/PriceRange";
import { numberToLocaleString, getItem, addDecimal } from "utils/utils";
//import checkIcon from "assets/icons/Icon-Check-circle.svg";

const ListItem = styled.div`
  background-color: ${(props) => (props.active ? "#fff" : "none")};
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-size: 14px;
  position: relative;
  &:not(:last-child) {
    &:before {
      content: "";
      position: absolute;
      background: linear-gradient(
        269.88deg,
        rgba(204, 204, 204, 0) 0%,
        #cccccc 50%,
        rgba(204, 204, 204, 0) 100%
      );
      height: 1px;
      width: 100%;
      bottom: 0px;
      left: 0;
    }
  }
  @media (max-width: 800px) {
    &:focus {
      background-color: ${(props) => (props.active ? "none" : "#fff")};
    }
  }
  @media (min-width: 1000px) {
    &:hover {
      background-color: ${(props) => (props.active ? "none" : "#fff")};
    }
  }
  .left {
    width: 70%;
    .details {
      padding: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .rating {
        text-align: right;
        width: 30%;
        font-size: 18px;

        @media (max-width: 800px) {
          div {
            text-align: right;
          }
        }
      }
      .flightDetails {
        width: 70%;
        display: flex;
        .flight {
          display: flex;
          flex-direction: column;
          font-size: 16px;
          font-family: "Roboto Condensed", sans-serif;
          &:first-child {
            min-width: 120px;
            @media (max-width: 800px) {
              min-width: 80px;
            }
          }
          span:last-child {
            color: #777777;
          }
        }
      }
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
  .right {
    width: 30%;
    text-align: right;
    font-size: 22px;
    color: ${(props) => props.theme.secondary};
    text-transform: capitalize;
    display: flex;
    justify-content: flex-end;
    svg {
      margin-right: 10px;
    }
  }
`;

class FlightListItem extends React.Component {
  handleClick = () => {
    if (this.props.handleClick) {
      this.props.handleClick(this.props.item.priceNum);
    }
  };

  render() {
    const { item, className, active, id, theme } = this.props;
    const costInMinorCurrencyUnits = item
      ? parseInt(item.baseFare, 10) + parseInt(item.totalTax, 10)
      : 0;
    const costInUnits = addDecimal(costInMinorCurrencyUnits);
    return item ? (
      <ListItem
        id={id}
        onClick={this.handleClick}
        active={active}
        className={className}
      >
        <div className="left">
          {item.hopRequests.map((hopRequest) => (
            <div className="details" key={hopRequest.id}>
              <div className="flightDetails">
                <div className="flight" style={{ alignItem: "flex-start" }}>
                  <span>
                    Dep.{" "}
                    {hopRequest.hopSegmentControls[0].flightSegments[0].stdTime.slice(
                      0,
                      -3
                    )}
                  </span>
                  <span>
                    Arr.{" "}
                    {hopRequest.hopSegmentControls[0].flightSegments[0].staTime.slice(
                      0,
                      -3
                    )}
                  </span>
                </div>
                <div className="flight">
                  <AirlineName
                    airlineIc={
                      hopRequest.hopSegmentControls[0].flightSegments[0]
                        .airlineIc
                    }
                  />
                  <span style={{ fontSize: 12 }}>
                    {
                      hopRequest.hopSegmentControls[0].flightSegments[0]
                        .airlineIc
                    }{" "}
                    {
                      hopRequest.hopSegmentControls[0].flightSegments[0]
                        .flightNum
                    }
                  </span>
                </div>
              </div>
              <div className="rating">
                <PriceRange hopRequest={hopRequest} />
                {hopRequest.hopSegmentControls[0].seatClass !== "ECONOMY" && (
                  <span
                    style={{
                      fontSize: 12,
                      color: "#777777",
                      textTransform: "capitalize",
                    }}
                  >
                    {hopRequest.hopSegmentControls[0].seatClass.toLowerCase()}
                  </span>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="right">
          {active && <CheckIconCircle stroke={theme.primary} />}
          {numberToLocaleString(costInUnits, {
            selectedCountry: getItem("selectedLanguage"),
            selectedCurrency: getItem("selectedCurrency"),
          })}
        </div>
      </ListItem>
    ) : null;
  }
}

export default withTheme(FlightListItem);
