import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import CityName from './CityName';

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  .date {
    color: #444444;
    font-size: 12px;
  }
`;

export default ({
  tripItem,
}) => (
  <Wrapper>
    {tripItem.hopRequests.length > 2 ? moment(tripItem.hopRequests[0].stdDate).format('MMMM YYYY') :
    <CityName
      airportIc={tripItem.hopRequests[0].toAirportIc}
    />}
    <span className="date">{moment(tripItem.hopRequests[0].stdDate).format('MMMM YYYY')}</span>
  </Wrapper>
);
