import React from 'react';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import { FlightFromError, SeatFilterIcon } from 'components/common/SvgIcons';

const Loading = styled.div`
  width: 100%;
  .head {
    display: flex;
    align-items: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background: #FFFFFF;
    height: 32px;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08), inset 0px -1px 0px rgba(0, 0, 0, 0.08);
    padding-left: 15px;
  }
  .loaderBody {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    .css-19bon8n {
      border-radius: 10em;
      margin-bottom: 15px;
    }
    .staticIcon {
      border-radius: 8px;
      border: 2px solid #DDDDDD;
      height: 20px;
      width: 20px;
      margin: 15px 0;
    }
  }
`;

export default ({
  type
}) => (
  <Loading>
    <div className="head">
      <FlightFromError stroke="#DDDDDD" />
    </div>
    <div className="loaderBody">
      {type === 'cancellation' ? <React.Fragment>
        <div className="staticIcon" />
        <Skeleton height={22} width={96} />
        <Skeleton height={16} width={64} />
        <Skeleton height={36} width={165} />
        <Skeleton height={16} width={116} />
      </React.Fragment> : <React.Fragment>
        <div style={{ marginTop: 15 }}>
          <Skeleton height={30} width={165} />
        </div>
        <Skeleton height={16} width={125} />
        <SeatFilterIcon stroke="#DDDDDD" />
        <div style={{ marginTop: 15 }}>
          <Skeleton height={16} width={125} />
        </div>
        <Skeleton height={30} width={165} />
      </React.Fragment>}
    </div>
  </Loading>
);
