import React, { Component } from "react";
import styled, { withTheme } from "styled-components";
import { TicketBg, TicketBgSmall, AddIcon } from "components/common/SvgIcons";
import { Button } from "components/common";
import { CheckIcon } from "../SvgIcons";

const Wrapper = styled.div`
  position: relative;
`;

const PassengerType = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  padding: 5px 20px;
  display: flex;
  flex-flow: ${props => (props.isExpanded ? "column" : "row")};
  align-items: center;
  cursor: pointer;
  .countWrapper {
    display: flex;
    width: 100%;
    img {
      margin: auto;
      margin-bottom: 10px;
    }
  }
  .count {
    position: absolute;
    top: 52px;
    right: 3px;
    background: #ec9719;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.16);
    color: #fff;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  .reset {
    text-decoration: underline;
    cursor: pointer;
    color: #007ac7;
    margin-top: 5px;
  }
`;

class PassType extends Component {
  state = {};
  handleChange = () => {
    if (!this.props.isStatic) {
      this.props.handleTypeChange(this.props.type);
      setTimeout(() => {
        this.props.handleIncrease();
      }, 50);
    }
  };
  handleIncrease = e => {
    e.preventDefault();
    e.stopPropagation();
    if (this.props.type !== this.props.isActive) {
      this.handleChange();
    } else {
      setTimeout(() => {
        this.props.handleIncrease();
      }, 50);
    }
  };
  handleReset = e => {
    e.preventDefault();
    e.stopPropagation();
    this.props.handleReset(this.props.type);
  };
  render() {
    const { isActive, type, isStatic, count, isExpanded, theme } = this.props;
    const style = isExpanded
      ? { height: 138, justifyContent: "space-between", padding: "10px 20px" }
      : {};
    const active = type === "adult" ? count > 1 : count > 0;
    const svgStroke = count > 0 ? theme.primaryBackground : "#fff";
    return (
      <Wrapper>
        {isExpanded ? (
          <TicketBg stroke={svgStroke} />
        ) : (
            <TicketBgSmall stroke={svgStroke} />
          )}
        <PassengerType
          isStatic={isStatic}
          type={type}
          isActive={isActive}
          isExpanded={isExpanded}
          onClick={this.handleChange}
          style={style}
        >
          {active ? (
            <span className="reset" onClick={this.handleReset}>
              Reset
            </span>
          ) : (
              <span />
            )}
          <div>
            <div className="countWrapper">
              <img
                src={require(`assets/icons/${type}${
                  count > 0 && type !== "adult" ? "-black" : ""
                  }.svg`)}
                height={type === "infant" ? 36 : 38}
                alt=""
              />
              {count > 0 && <span className="count">{count}</span>}
            </div>
            {isExpanded && (
              <div style={{ width: 72 }}>
                <Button
                  style={{
                    height: 32
                  }}
                  color="#F2CC56"
                  onClick={this.handleIncrease}
                >
                  {isActive && type === "child" ? <CheckIcon style={{ height: 24, width: 24 }} /> : <AddIcon style={{ height: 24, width: 24 }} />}
                </Button>
              </div>
            )}
          </div>
        </PassengerType>
      </Wrapper>
    );
  }
}

export default withTheme(PassType);
