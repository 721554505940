import React, { Component } from "react";
import moment from "moment";
import { Input } from "components/common";
import getTranslation from "utils/getTranslation";

export default class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value || "",
      label: props.label || "Birth Date",
      country: "IN"
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value === undefined) {
      this.setState({ value: "" });
    } else if (nextProps.value && this.props.value !== nextProps.value) {
      this.setState({
        value: moment(nextProps.value).format("DD-MM-YYYY")
      });
    }
  }

  handleChange = e => {
    const formatted = this.formatDate(e.target.value, this.state.value) || "";
    const checkIfDone = formatted.split("-").join("");
    if (checkIfDone.length > 8) {
      return;
    }
    this.setState(
      {
        value: formatted
      },
      () => {
        const check = formatted.split("-").join("");
        if (check.length >= 8) {
          this.handleDateConversion();
        } else {
          this.props.onChange("");
        }
      }
    );
  };

  handleDateConversion = () => {
    const dateArray = this.state.value.split("-");
    const date =
      this.state.country !== "US"
        ? `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`
        : `${dateArray[2]}-${dateArray[0]}-${dateArray[1]}`;
    const fullDate = moment(date).toDate();
    this.props.onChange(fullDate);
  };

  checkValue = (str, max) => {
    if (str.charAt(0) !== "0" || str === "00") {
      let num = parseInt(str, 10);
      if (isNaN(num) || num <= 0 || num > max) num = 1;
      str =
        num > parseInt(max.toString().charAt(0), 10) &&
          num.toString().length === 1
          ? `0${num}`
          : num.toString();
    }
    return str;
  };

  formatDate = (input, prevInput) => {
    if (/-+$/.test(prevInput)) {
      if (input.length < prevInput.length) {
        input = input.substr(0, input.length - 1);
      }
    }
    const values = input.split("-").map(v => v.replace(/\D/g, ""));
    if (this.state.country === "US") {
      if (values[0]) values[0] = this.checkValue(values[0], 12);
      if (values[1]) values[1] = this.checkValue(values[1], 31);
    } else {
      if (values[0]) values[0] = this.checkValue(values[0], 31);
      if (values[1]) values[1] = this.checkValue(values[1], 12);
    }
    const output = values.map((v, i) =>
      v.length === 2 && i < 2 ? `${v}-` : v
    );
    return output.join("").substr(0, 14);
  };

  render() {
    const { value, label, country } = this.state;
    const {
      image,
      imgHeight,
      usExample,
      example,
      onKeyDown,
      errorState,
      handleFocus
    } = this.props;
    return (
      <Input
        disabled={this.props.disabled}
        label={`${label} (${getTranslation("example")}: ${
          country !== "US" ? usExample : example
          })`}
        value={value}
        type="tel"
        onChange={this.handleChange}
        onFocus={handleFocus}
        image={image}
        imgHeight={imgHeight}
        onKeyDown={onKeyDown}
        errorState={errorState}
      />
    );
  }
}
