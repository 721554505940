import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { graphql, compose } from "react-apollo";
import moment from "moment";
import { setConnectPosts, updateConnectPostRefetch } from "redux-store/actions";
import { ItemTitle } from "components/Influencer";
import {
  deleteConnectPost,
  updateConnectPost
} from "containers/Influencer/queries";
import { Loader } from "components/common";
import RecurrenceCard from "./RecurrenceCard";
import { handleGrouping, handleFiltering } from "./helpers";
import getTranslation from "utils/getTranslation";

const Wrapper = styled.div``;

class Posts extends React.PureComponent {
  state = { posts: [], loading: true, repeatModalOpen: false };

  handleRemoved = id => {
    const filteredPosts = this.props.connectPosts.filter(
      item => item.id !== id
    );
    this.props.setConnectPosts(filteredPosts);
  };

  handleGetConnects = () => {
    const connects = this.props.influencerDetails.influencerconnects.map(
      connect => ({
        influencerconnect_id: { _eq: connect.id }
      })
    );
    return connects;
  };

  handleShareAll = async () => {
    try {
      const connects = this.handleGetConnects();
      await this.props.updateConnectPost({
        variables: {
          _set: {
            shareschedule_at: moment().format()
          },
          where: {
            _or: connects
          }
        }
      });
      this.props.updateConnectPostRefetch(moment().unix());
    } catch (err) {
      console.log(err);
      //
    }
  };

  handleClearQueue = async () => {
    try {
      const connects = this.handleGetConnects();
      await this.props.deleteConnectPost({
        variables: {
          where: {
            _or: connects
          }
        }
      });
      this.props.setConnectPosts([]);
    } catch (err) {
      //
    }
  };

  render() {
    const { connectPosts, loading } = this.props;
    const filteredPosts = handleFiltering(connectPosts);
    const groupedPosts = handleGrouping(filteredPosts);
    return (
      <Wrapper>
        {!loading ? (
          <>
            {groupedPosts.length > 0 ? (
              groupedPosts.map(group => (
                <div key={group.shareschedule_at}>
                  <ItemTitle
                    title={
                      <div className="mainTitle">
                        <div>
                          <span>{getTranslation("shareAt")}</span>
                          <span className="time">
                            {group.shareschedule_at} HRS
                          </span>
                        </div>
                      </div>
                    }
                  />
                  {group.posts.map(post => (
                    <RecurrenceCard key={post.id} connectPost={post} />
                  ))}
                </div>
              ))
            ) : (
              <h2>{getTranslation("emptyQueue")}</h2>
            )}
          </>
        ) : (
          <Loader />
        )}
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  connectPosts: state.influencer.connectPosts
});

export default compose(
  graphql(updateConnectPost, { name: "updateConnectPost" }),
  graphql(deleteConnectPost, { name: "deleteConnectPost" }),
  connect(mapStateToProps, { setConnectPosts, updateConnectPostRefetch })
)(Posts);
