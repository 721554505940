import React from "react";
import styled from "styled-components";
import { ItemTitle } from "components/Influencer";
import getTranslation from "utils/getTranslation";
import { numberToLocaleString, getItem } from "utils/utils";

const Wrapper = styled.div`
  width: 100%;
  padding-top: 30px;
  .total {
    color: #777777;
    text-transform: uppercase;
    font-family: "Roboto Condensed", sans-serif;
    margin-bottom: 25px;
    span {
      font-weight: 500;
      color: #007ac7;
      margin-left: 5px;
    }
  }
  .noEarning {
    margin: 100px auto 0 auto;
    color: #777777;
    text-align: center;
    max-width: 529px;
    h1 {
      font-weight: 400;
      font-size: 22px;
    }
    p {
      font-size: 14px;
      line-height: 140%;
    }
  }
`;

const earning = 0;

export default class extends React.PureComponent {
  render() {
    return (
      <Wrapper>
        <div className="total">
          {getTranslation("monthlyTotal")}
          <span>
            {numberToLocaleString(earning, {
              selectedCountry: getItem("selectedLanguage"),
              selectedCurrency: getItem("selectedCurrency")
            })}
          </span>
        </div>
        <div className="total">
          <ItemTitle
            title={
              <>
                {getTranslation("weeklyTotal")}
                <span>
                  {numberToLocaleString(earning, {
                    selectedCountry: getItem("selectedLanguage"),
                    selectedCurrency: getItem("selectedCurrency")
                  })}
                </span>
              </>
            }
          />
        </div>
        <div className="noEarning">
          <h1>{getTranslation("noEarningTitle")}</h1>
          <p>{getTranslation("noEarningText")}</p>
        </div>
      </Wrapper>
    );
  }
}
