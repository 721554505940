import React from "react";
import styled from "styled-components";
import moment from "moment";
import AirportDetails from "./AirportDetails";

const Wrapper = styled.div`
  margin-top: 10px;
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    position: relative;
    align-items: flex-end;
    .details {
      width: 154px;
      display: flex;
      flex-flow: column;
      font-weight: 300;
      &.right {
        text-align: right;
      }
      p {
        margin: 0;
        font-size: 16px;
        color: #222222;
        line-height: 19px;
      }
      b {
        font-weight: 400;
        font-size: 22px;
        line-height: 26px;
      }
      span {
        font-size: 18px;
        color: #444444;
        text-transform: capitalize;
      }
      .dateTime {
        font-size: 12px;
        line-height: 14px;
        margin-top: 3px;
      }
    }
    .duration {
      display: flex;
      padding: 5px;
      background-color: #f0f0f0;
      position: relative;
      min-width: 60px;
      position: absolute;
      bottom: 20px;
      left: calc(50% - 10px);
      transform: translate(-50%);
      text-align: center;
      justify-content: center;
      @media (max-width: 800px) {
        min-width: 52px;
        left: calc(50% - 4px);
        bottom: 30px;
      }
      p {
        margin: 0;
        font-size: 14px;
        color: #444444;
        &:nth-child(2) {
          margin-left: 5px;
        }
        @media (max-width: 800px) {
          font-size: 12px;
        }
      }
    }
  }
`;

const Arrow = styled.div`
  border-style: solid;
  border-color: transparent #f0f0f0;
  border-width: 20px 0px 22px 28px;
  height: 0px;
  width: 0px !important;
  position: absolute;
  right: -27px;
  bottom: -8px;
  @media (max-width: 800px) {
    border-width: 16px 0px 16px 17px;
    right: -15px;
    bottom: -4px;
    z-index: -1;
  }
`;

export default ({ flightInfo }) => {
  const depDate = moment(`${flightInfo.stdDate} ${flightInfo.stdTime}`).format(
    "DD MMM"
  );
  const arrDate = moment(`${flightInfo.staDate} ${flightInfo.staTime}`).format(
    "DD MMM"
  );
  const depTime = flightInfo.stdTime.split(":");
  const arrTime = flightInfo.staTime.split(":");
  const hours = Math.trunc(flightInfo.durationMins / 60);
  const mins = flightInfo.durationMins % 60;
  return (
    <Wrapper>
      <div className="container">
        <div className="details">
          <AirportDetails airportIc={flightInfo.fromAirportIc} />
          <span className="dateTime">
            {depDate} {`${depTime[0]}:${depTime[1]}`}
          </span>
        </div>
        <div className="duration">
          {hours > 0 && <p>{hours}h</p>}
          {mins > 0 && <p>{mins}m</p>}
          <Arrow />
        </div>
        <div className="details right">
          <AirportDetails airportIc={flightInfo.toAirportIc} />
          <span className="dateTime">
            {arrDate} {`${arrTime[0]}:${arrTime[1]}`}
          </span>
        </div>
      </div>
    </Wrapper>
  );
};
