import React from "react";
import styled from "styled-components";
import { ApolloConsumer } from "react-apollo";
import CreditCardInput from "react-credit-card-input";
import Checkbox from "@material-ui/core/Checkbox";
import moment from "moment";
import { isMobile } from "react-device-detect";
import FlightNumAndDate from "components/FlightSegment/Partials/FlightNumAndDate";
import { Button } from "components/common";
import PaymentIssueModal from "components/common/PaymentIssueModal";
import { getParameterByName, decode } from "utils/utils";
import getTranslation from "utils/getTranslation";
import { getRedirectUrl } from "utils/api";
import config from "config";
import paymentLogo from "assets/emerchantpayLogo.svg";

const Wrapper = styled.div`
  padding: 20px;
  background-color: #fff;
`;

const CardHolder = styled.div`
  font-size: 12px;
  padding: 0;
  .field {
    background: #f0f0f0;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.08), inset 0px -1px 0px #007ac7;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 5px 10px 10px 10px;
  }
  div {
    margin-bottom: 16px;
    font-weight: 300;
    display: flex;
    flex-direction: column;
    &:last-child {
      margin-bottom: 0;
    }
    span {
      color: #777777;
      &:last-child {
        font-size: 16px;
        margin-top: 5px;
        color: #222222;
      }
    }
  }
`;

const InputWrapper = styled.div`
  .cvv {
    display: flex;
    justify-content: space-between;
  }
  .creditCardInput {
    margin: 0 0 20px 0;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.08), inset 0px -1px 0px #007ac7 !important;
    background-color: rgba(0, 0, 0, 0.09);
    border-radius: 0;
    padding: 27px 12px 10px;
    .floating {
      color: rgba(0, 0, 0, 0.54);
      position: absolute;
      left: 0;
      top: -29px;
      transform: translate(0, 20px) scale(1);
      transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
        transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      &.focus {
        /* top: -27px; */
        transform: translate(-5px, 0px) scale(0.75);
        /* font-size: 12px; */
      }
      &.cardNum {
        transform: translate(-10px, 0px) scale(0.75);
      }
    }
    input {
      background-color: transparent;
    }
    .mask {
      width: 28px;
      height: 20px;
      position: absolute;
      background-color: #fff;
      left: -34px;
      z-index: -1;
    }
    img {
      position: relative;
      z-index: 1;
    }
  }
  .acceptTerms {
    display: flex;
    font-size: 12px;
    align-items: center;
    color: #222222;
    a {
      color: #007ac7;
    }
  }
`;

const Meta = styled.div`
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #777777;
    margin-top: 10px;
    font-size: 12px;
  }
`;

export default class extends React.PureComponent {
  constructor(props) {
    super(props);
    let decodedData = {};
    let error = false;
    const query = getParameterByName("query");
    try {
      decodedData = decode(query);
    } catch (err) {
      console.log("Tampered parameter");
      error = true;
    }
    const {
      card_holder,
      billing_country,
      gateway_url,
      total_amount_display,
      front_url,
      trip_id,
      items
    } = decodedData;
    // const name = getParameterByName('card_holder');
    // const country = getParameterByName('billing_country');
    // const url = getParameterByName('gateway_url');
    // const price = getParameterByName('total_amount_display');
    // const frontUrl = getParameterByName('front_url');
    this.state = {
      name: card_holder,
      country: billing_country,
      url: gateway_url,
      price: total_amount_display,
      frontUrl: front_url,
      tripId: trip_id,
      items,
      startIframeLoading: true,
      error
    };
  }

  componentDidMount() {
    document.body.style.backgroundColor = "#FFFFFF";
    document.getElementById("app-header").style.display = "none";
    if (!isMobile) {
      document.getElementById("app-footer").style.display = "none";
    }
  }

  handleGetUrl = async () => {
    this.setState({ startIframeLoading: true, loading: true });
    try {
      const {
        cardNumber,
        cardCvv,
        cardExpMm,
        cardExpYyyy,
        tripId,
        name
      } = this.state;
      var cse = window.Encrypto.createEncryption(config.encryptoPublicKey);
      let data = {
        cvv: cardCvv,
        card_number: cardNumber.replace(/\s/g, ""),
        expiration_month: cardExpMm,
        expiration_year: cardExpYyyy,
        card_holder: name
      };
      var encryptedData = cse.encrypt(data);
      const hostname = window.location.hostname;
      const payload = {
        tripId,
        cardNumber: encryptedData.card_number,
        cardCvv: encryptedData.cvv,
        cardExpMm: encryptedData.expiration_month,
        cardExpYyyy: encryptedData.expiration_year,
        cardHolder: encryptedData.card_holder,
        tld: hostname.split("flystrait.")[1]
      };
      const setup = await getRedirectUrl(payload);
      window.location = setup.redirect_url;
    } catch (err) {
      console.log(err);
      this.setState({
        paymentError: true,
        paymentSetupFailed: true,
        errorMessage: getTranslation("paymentInitFailed"),
        startIframeLoading: false,
        loading: false
      });
    }
  };

  handleChange = e => {
    const { name, value } = e.target;
    if ((name === "cardCvv" && value.length > 3) || isNaN(value)) {
      return;
    }
    this.setState({
      [name]: value
    });
  };
  handleCardNumberChange = e => {
    this.setState({
      cardNumber: e.target.value,
      cardInputTime: moment().valueOf()
    });
  };
  handleCardExpiryChange = e => {
    const monthYear = e.target.value;
    const monthYearArray = monthYear.split(" / ");
    this.setState(
      {
        cardExpMm: monthYearArray[0] ? monthYearArray[0] : "",
        cardExpYyyy: monthYearArray[1]
          ? moment()
            .format("YYYY")
            .slice(0, 2) + monthYearArray[1]
          : "",
        cardInputTime: moment().unix()
      },
      () => {
        console.log(this.state.cardExpYyyy);
      }
    );
  };
  handleCardCVCChange = e => {
    this.setState({
      cardCvv: e.target.value,
      cardInputTime: moment().valueOf()
    });
  };

  handleAcceptChange = (e, checked) => {
    this.setState({ termsAccepted: checked });
  };
  handleError = e => {
    this.setState({
      errorTime: moment().valueOf()
    });
  };
  render() {
    const {
      name,
      country,
      price,
      items,
      loading,
      openWarning,
      warningMessage,
      cardNumber,
      cardCvv,
      cardExpMm,
      expiry,
      cvc,
      termsAccepted,
      isFormCompleted
    } = this.state;
    return (
      <ApolloConsumer>
        {client => (
          <Wrapper>
            <PaymentIssueModal
              open={openWarning}
              handleAccept={this.handleWarningAccept}
              errorMsg={warningMessage}
            />
            <div style={{ marginTop: 20 }}>
              <div style={{ width: 320, margin: "auto" }}>
                {items.map(item => (
                  <FlightNumAndDate
                    flightInfo={item}
                    style={{ marginBottom: 20 }}
                    collapsable
                    withYear
                    disabled
                  />
                ))}
                <div
                  style={{
                    fontFamily: "Roboto Condensed, sans-serif",
                    color: "#777777",
                    fontSize: 16,
                    textTransform: "uppercase",
                    borderBottom: "1px solid #DDDDDD"
                  }}
                >
                  {getTranslation("finishPayment")}
                </div>
                <div style={{ margin: "20px 0" }}>
                  <img
                    src={require("assets/cards/Mastercard-logo.svg")}
                    height="30"
                    alt=""
                    style={{ marginRight: 5 }}
                  />
                  <img
                    src={require("assets/cards/Visa_Inc._logo.svg")}
                    width="50"
                    height="30"
                    alt=""
                    style={{ marginLeft: 5 }}
                  />
                </div>
                <CardHolder style={{ marginBottom: 14, color: "#000" }}>
                  {price && (
                    <div className="field">
                      <span>Total amount:</span>
                      <span>{price}</span>
                    </div>
                  )}
                  <div className="field">
                    <span>{getTranslation("cardHolder")}</span>
                    <span>{name}</span>
                  </div>
                  <div className="field">
                    <span>{getTranslation("billingCountry")}</span>
                    <span>{country}</span>
                  </div>
                </CardHolder>
                <InputWrapper>
                  <CreditCardInput
                    onError={this.handleError}
                    cardNumberInputRenderer={({
                      handleCardNumberChange,
                      props
                    }) => (
                        <>
                          <div className="mask" />
                          <span
                            className={`floating ${cardNumber ? "cardNum" : ""}`}
                          >
                            Card Number
                        </span>
                          <input
                            {...props}
                            placeholder=""
                            value={cardNumber}
                            onChange={handleCardNumberChange(
                              this.handleCardNumberChange
                            )}
                          />
                        </>
                      )}
                    cardExpiryInputRenderer={({
                      handleCardExpiryChange,
                      props
                    }) => (
                        <>
                          <span
                            className={`floating ${cardExpMm ? "focus" : ""}`}
                          >
                            MM/YY
                        </span>
                          <input
                            {...props}
                            value={expiry}
                            placeholder=""
                            onChange={handleCardExpiryChange(
                              this.handleCardExpiryChange
                            )}
                          />
                        </>
                      )}
                    cardCVCInputRenderer={({ handleCardCVCChange, props }) => (
                      <>
                        <span className={`floating ${cardCvv ? "focus" : ""}`}>
                          CVC
                        </span>
                        <input
                          {...props}
                          value={cvc}
                          placeholder=""
                          onChange={handleCardCVCChange(
                            this.handleCardCVCChange
                          )}
                        />
                      </>
                    )}
                    fieldClassName="creditCardInput"
                  />
                  <div className="acceptTerms">
                    <Checkbox
                      color="secondary"
                      onChange={this.handleAcceptChange}
                    />
                    <div>
                      I acknowledge that I have read the{" "}
                      <a href="/terms" target="_blank">
                        Terms & Conditions
                      </a>{" "}
                      of purchase,{" "}
                      <a href="/privacy" target="_blank">
                        Privacy Policy
                      </a>{" "}
                      and authorize the payment.
                    </div>
                  </div>
                  <Button
                    style={{ margin: "20px 0" }}
                    disabled={loading || !termsAccepted || !isFormCompleted}
                    onClick={this.handleUpdatePassenger}
                  >
                    {loading
                      ? getTranslation("pleaseWait")
                      : getTranslation("payNow") + " " + price}
                  </Button>
                </InputWrapper>
              </div>
              <Meta>
                <div>
                  <img src={paymentLogo} height="16" alt="" />
                </div>
                <div>{getTranslation("paymentDescriptor")}</div>
              </Meta>
            </div>
          </Wrapper>
        )}
      </ApolloConsumer>
    );
  }
}
