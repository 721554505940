import React from "react";
import styled from "styled-components";
import { resizedImage } from "utils/utils";

const Wrapper = styled.div`
  &.postDetails {
    display: flex;
    @media (max-width: 800px) {
      margin-top: 20px;
    }
    .cover {
      width: 106px;
      min-width: 106px;
      height: 80px;
      object-fit: cover;
      margin-right: 16px;
    }
    .meta {
      display: flex;
      flex-flow: column;
      h4 {
        color: #3f8c52;
        font-size: 18px;
        margin-bottom: 10px;
        margin-top: 0px;
      }
      p {
        margin-top: 0;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
      }
    }
  }
`;

export default ({ post, blogText }) => {
  const coverimage = post.coverimage || post.coverimage1;
  return (
    <Wrapper className="postDetails">
      <img src={resizedImage(coverimage, 80)} className="cover" alt="" />
      <div className="meta">
        {post.type === "BLOG" && <h4>{post.title}</h4>}
        <p>{post.type === "BLOG" ? blogText : post.title}</p>
      </div>
    </Wrapper>
  );
};
