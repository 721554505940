import React from 'react';
import { Query } from 'react-apollo';
import { xairportByIataLike } from 'components/common/BookedTrip/queries';

export default ({
  airportIc,
}) => (
  <Query query={xairportByIataLike} variables={{ iata: `${airportIc}%` }}>
    {({ data }) => (
      data.xairportByIataLike ?
          <span>{`To ${data.xairportByIataLike[0].city}`}</span>
         : null
    )}
  </Query>
);
