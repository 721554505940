import React from "react";
import styled from "styled-components";
import { DestinationLabel } from "./CardStyles";
import { getItem } from "utils/utils";
import location from "assets/icons/Location.svg";

const Wrapper = styled.div`
  &.destinations {
    position: absolute;
    display: flex;
    bottom: 12px;
    left: 0;
  }
`;

export default ({ blogData, color, backgroundColor }) => {
  function getDestinationCity(airportIc) {
    const allAirports = getItem("allAirports");
    const airport = allAirports.find(item => item.airportIc === airportIc);
    return airport ? airport.city : "";
  }
  return (
    <Wrapper className="destinations">
      {blogData.postdestinations.map((destination, index) => (
        <DestinationLabel
          backgroundColor={backgroundColor}
          color={color}
          key={index}
        >
          <img src={location} alt="" />
          <span>{getDestinationCity(destination.destination)}</span>
        </DestinationLabel>
      ))}
    </Wrapper>
  );
};
