export default {
  v: "5.6.5",
  fr: 30,
  ip: 315,
  op: 332,
  w: 1000,
  h: 720,
  nm: "Feature-2 in",
  ddd: 0,
  assets: [
    {
      id: "image_0",
      w: 100,
      h: 308,
      u: "/static/ae-images/Feature-2/images/",
      p: "img_0.svg",
      e: 0
    },
    {
      id: "image_1",
      w: 254,
      h: 19,
      u: "/static/ae-images/Feature-2/images/",
      p: "img_1.svg",
      e: 0
    },
    {
      id: "image_2",
      w: 369,
      h: 200,
      u: "/static/ae-images/Feature-2/images/",
      p: "img_2.svg",
      e: 0
    },
    {
      id: "image_3",
      w: 212,
      h: 276,
      u: "/static/ae-images/Feature-2/images/",
      p: "img_3.svg",
      e: 0
    },
    {
      id: "image_4",
      w: 585,
      h: 70,
      u: "/static/ae-images/Feature-2/images/",
      p: "img_4.svg",
      e: 0
    },
    {
      id: "image_5",
      w: 92,
      h: 179,
      u: "/static/ae-images/Feature-2/images/",
      p: "img_5.svg",
      e: 0
    },
    {
      id: "image_6",
      w: 735,
      h: 478,
      u: "/static/ae-images/Feature-2/images/",
      p: "img_6.svg",
      e: 0
    }
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 2,
      nm: "Group 20.png",
      cl: "svg",
      refId: "image_0",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 337,
              s: [100]
            },
            { t: 345, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [939.5, 470, 0], ix: 2 },
        a: { a: 0, k: [50, 154, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0, 0, 0.667], y: [1, 1, 1] },
              o: { x: [0, 0, 0.333], y: [0, 0, 0] },
              t: 317,
              s: [0, 0, 100]
            },
            { t: 331, s: [100, 100, 100] }
          ],
          ix: 6
        }
      },
      ao: 0,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 2,
      nm: "Vector (2).png",
      cl: "svg",
      refId: "image_1",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 317,
              s: [0]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 331,
              s: [100]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 337,
              s: [100]
            },
            { t: 345, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [274, 366, 0], ix: 2 },
        a: { a: 0, k: [127, 9.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 2,
      nm: "Vector (2).png",
      cl: "svg",
      refId: "image_1",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 317,
              s: [0]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 331,
              s: [100]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 337,
              s: [100]
            },
            { t: 345, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [274, 324, 0], ix: 2 },
        a: { a: 0, k: [127, 9.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 4,
      ty: 2,
      nm: "Vector (2).png",
      cl: "svg",
      refId: "image_1",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 317,
              s: [0]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 331,
              s: [100]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 337,
              s: [100]
            },
            { t: 345, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [274, 278, 0], ix: 2 },
        a: { a: 0, k: [127, 9.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 5,
      ty: 2,
      nm: "Vector (2).png",
      cl: "svg",
      refId: "image_1",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 317,
              s: [0]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 331,
              s: [100]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 337,
              s: [100]
            },
            { t: 345, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [274, 236, 0], ix: 2 },
        a: { a: 0, k: [127, 9.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 6,
      ty: 2,
      nm: "Group 22.png",
      cl: "svg",
      refId: "image_2",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 317,
              s: [0]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 331,
              s: [100]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 337,
              s: [100]
            },
            { t: 345, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [661, 328, 0], ix: 2 },
        a: { a: 0, k: [184.5, 100, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 7,
      ty: 2,
      nm: "man 2.png",
      cl: "svg",
      refId: "image_3",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 317,
              s: [0]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 325,
              s: [100]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 337,
              s: [100]
            },
            { t: 345, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0, y: 1 },
              o: { x: 0, y: 0 },
              t: 317,
              s: [120, 536, 0],
              to: [24, 0, 0],
              ti: [-24, 0, 0]
            },
            { t: 331, s: [264, 536, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [106, 138, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 8,
      ty: 2,
      nm: "Vector.png",
      cl: "svg",
      refId: "image_4",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 317,
              s: [0]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 331,
              s: [100]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 337,
              s: [100]
            },
            { t: 345, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [479, 523, 0], ix: 2 },
        a: { a: 0, k: [292.5, 35, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 9,
      ty: 2,
      nm: "Group 21.png",
      cl: "svg",
      refId: "image_5",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 337,
              s: [100]
            },
            { t: 345, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [84.5, 526, 0], ix: 2 },
        a: { a: 0, k: [46, 89.5, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0, 0, 0.667], y: [1, 1, 1] },
              o: { x: [0, 0, 0.333], y: [0, 0, 0] },
              t: 317,
              s: [0, 0, 100]
            },
            { t: 331, s: [100, 100, 100] }
          ],
          ix: 6
        }
      },
      ao: 0,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 10,
      ty: 2,
      nm: "Group 23.png",
      cl: "svg",
      refId: "image_6",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 314,
              s: [0]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 328,
              s: [100]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 337,
              s: [100]
            },
            { t: 345, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [502, 378, 0], ix: 2 },
        a: { a: 0, k: [367.5, 239, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0
    }
  ],
  markers: []
};
