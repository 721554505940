import React, { useState } from "react";
import styled from "styled-components";
import Fade from "react-reveal/Fade";
import getTranslation from "utils/getTranslation";
import FeatureSlide from "./FeatureSlide";
import feature1Data from "assets/ae-animations/Feature-1/data";
import feature2Data from "assets/ae-animations/Feature-2/data";
import feature3Data from "assets/ae-animations/Feature-3/data";
import feature4Data from "assets/ae-animations/Feature-4/data";

const Wrapper = styled.div`
  margin-top: 100px;
  h1 {
    text-align: center;
    background-color: #f9f9f9;
    margin: 0;
    padding: 20px 0;
  }
  .features {
    min-height: 455px;
    display: flex;
    align-items: center;
    @media (max-width: 800px) {
      min-height: 562px;
    }
  }
  @media (max-width: 800px) {
    margin-top: 50px;
  }
`;

const features = [
  {
    id: 1,
    data: feature1Data,
    title: getTranslation("write"),
    text: getTranslation("writeDesc")
  },
  {
    id: 2,
    data: feature2Data,
    title: getTranslation("publish"),
    text: getTranslation("publishDesc")
  },
  {
    id: 3,
    data: feature3Data,
    title: getTranslation("share"),
    text: getTranslation("shareDesc")
  },
  {
    id: 4,
    data: feature4Data,
    title: getTranslation("earnAndLearn"),
    text: getTranslation("earnAndLearnDesc")
  }
];

export default () => {
  const [revealed, setRevealed] = useState(false);

  function handleReveal() {
    setRevealed(true);
  }
  return (
    <Wrapper>
      <Fade bottom>
        <h1>{getTranslation("flyStraitFeatures")}</h1>
      </Fade>
      <Fade onReveal={handleReveal}>
        <>
          <div className={`features}`}>
            {features.map(
              (item, i) => handleReveal && <FeatureSlide data={item} key={i} />
            )}
          </div>
        </>
      </Fade>
    </Wrapper>
  );
};
