import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Fade from "react-reveal/Fade";
import { isMobile } from "react-device-detect";
import getTranslation from "utils/getTranslation";
import { swipedetect } from "utils/utils";
import { Dots } from "./Styles";
import userImg from "assets/tipLanding/user-thumb.jpg";
import illustration from "assets/tipLanding/bottom-illustration.svg";
import ellipse from "assets/tipLanding/Ellipse.svg";
import destinations from "assets/tipLanding/destinations.svg";
import post from "assets/tipLanding/post.svg";
import people from "assets/tipLanding/people.svg";
import money from "assets/tipLanding/money.svg";
import img from "assets/tip/img.jpg";
import arrowLeft from "assets/tipLanding/arrow-left.svg";

const Wrapper = styled.div`
  padding-top: 100px;
  background-color: #f9f9f9;
  .main-container {
    position: relative;
  }
  .illustration {
    position: absolute;
  }
  h1 {
    text-align: center;
    margin-bottom: 40px;
  }
  .userDetails {
    display: flex;
    align-items: center;
    img {
      margin-right: 40px;
      height: 200px;
      width: 200px;
      object-fit: cover;
      border-radius: 50px;
      box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.25);
    }
    h5 {
      margin-bottom: 40px;
    }
    @media (max-width: 800px) {
      flex-flow: column;
      text-align: center;
      img {
        margin-right: 0;
      }
    }
  }
  .info {
    display: flex;
    justify-content: space-between;
    padding: 50px 0;
    .leftSection {
      display: flex;
      flex-flow: column;
      margin-right: 50px;
      position: relative;
      z-index: 3;
      .imgSlider {
        width: 440px;
        img {
          width: 100%;
          border-radius: 20px;
          box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.25);
        }
      }
    }
    .rightSection {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      height: 100%;
      position: relative;
      z-index: 1;
    }
    @media (max-width: 800px) {
      flex-flow: column;
      .leftSection {
        margin-right: 0;
        margin-bottom: 50px;
        .imgSlider {
          width: 100%;
        }
      }
    }
    .item {
      display: flex;
      align-items: flex-start;
      margin-bottom: 50px;
      img {
        width: 40px;
        margin-right: 30px;
      }
      h5 {
        text-align: left;
        margin-bottom: 24px;
        margin-top: 0;
      }
      p {
        margin: 0;
        font-size: 12px;
        width: 300px;
        line-height: 16px;
        @media (max-width: 800px) {
          font-size: 14px;
        }
      }
    }
  }
`;

const Item = ({ data }) => (
  <div className="item">
    <img src={data.icon} alt="" />
    <div>
      <h5>{data.title}</h5>
      <p>{data.text}</p>
    </div>
  </div>
);

const items = [
  {
    icon: destinations,
    title: "Last 4",
    text: getTranslation("destinationsCovered")
  },
  {
    icon: post,
    title: "20",
    text: getTranslation("postsCreated")
  },
  {
    icon: people,
    title: "155",
    text: getTranslation("numOfVisitors")
  },
  {
    icon: money,
    title: "244",
    text: getTranslation("numOfTransactions")
  }
];

const images = [1, 2, 3];

export default () => {
  const [activeIndex, setActiveIndex] = useState(0);
  useEffect(() => {
    const el = document.getElementById("swipezone-second");
    swipedetect(el, swipedir => {
      if (swipedir === "left") {
        handleNext();
      }
      if (swipedir === "right") {
        handlePrev();
      }
    });
  });
  function handleClick(e) {
    setActiveIndex(parseInt(e.target.id));
  }
  function handlePrev() {
    if (activeIndex > 0) setActiveIndex(activeIndex - 1);
  }
  function handleNext() {
    if (activeIndex < 2) setActiveIndex(activeIndex + 1);
  }
  return (
    <Wrapper>
      <div className="main-container">
        <Fade>
          <h1>{getTranslation("influencerOfWeek")}</h1>
        </Fade>
        <div className="userDetails">
          <Fade>
            <img src={userImg} alt="" />
          </Fade>
          <Fade>
            <div>
              <h5>N. Adrey</h5>
              <p>
                A beach town, be it for hen parties, fantastic restaurants,
                pebble beaches or festivals throughout the year welcomes all.
                There are popular LGBTQ+ walking tours if you wondered how
                Brighton became the town it is today. There are popular LGBTQ+
                walking tours if you wondered how Brighton became the town it is
                today. There are popular
              </p>
            </div>
          </Fade>
        </div>
        <div className="info">
          <div className="leftSection">
            <Fade>
              <Item data={items[0]} />
            </Fade>
            <Fade>
              <div className="imgSlider" id="swipezone-second">
                <div>
                  {images.map(
                    (item, i) =>
                      activeIndex === i && (
                        <Fade key={i} duration={1400}>
                          <img src={img} alt="" />
                        </Fade>
                      )
                  )}
                </div>
              </div>
              <Dots>
                <img
                  src={arrowLeft}
                  alt=""
                  className="left"
                  onClick={handlePrev}
                />
                <img
                  src={arrowLeft}
                  alt=""
                  className="right"
                  onClick={handleNext}
                />
                <div
                  className={`dot ${activeIndex === 0 ? "active" : ""}`}
                  onClick={handleClick}
                  id="0"
                />
                <div
                  className={`dot ${activeIndex === 1 ? "active" : ""}`}
                  onClick={handleClick}
                  id="1"
                />
                <div
                  className={`dot ${activeIndex === 2 ? "active" : ""}`}
                  onClick={handleClick}
                  id="2"
                />
              </Dots>
            </Fade>
          </div>
          <div className="rightSection">
            <Fade>
              {items.slice(1).map((item, i) => (
                <Item data={item} key={i} />
              ))}
            </Fade>
          </div>
        </div>
        <Fade>
          <>
            <img
              src={illustration}
              className="illustration"
              style={{ width: "100%", bottom: 0, right: 0, zIndex: 2 }}
              alt=""
            />
            <img
              src={ellipse}
              className="illustration"
              style={{ width: "36%", bottom: 0, right: 0, zIndex: -1 }}
              alt=""
            />
          </>
        </Fade>
      </div>
    </Wrapper>
  );
};
