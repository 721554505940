/* eslint-disable */
export default [{
	"Code": "AF",
	'en-GB': "Afghanistan",
	'en': "Afghanistan",
}, {
	"Code": "AX",
	'en-GB': "\u00c5land Islands",
	'en': "\u00c5land Islands",
}, {
	"Code": "AL",
	'en-GB': "Albania",
	'en': "Albania",
}, {
	"Code": "DZ",
	'en-GB': "Algeria",
	'en': "Algeria",
}, {
	"Code": "AS",
	'en-GB': "American Samoa",
	'en': "American Samoa"
}, {
	"Code": "AD",
	'en-GB': "Andorra",
	'en': "Andorra",
}, {
	"Code": "AO",
	'en-GB': "Angola",
	'en': "Angola"
}, {
	"Code": "AI",
	'en-GB': "Anguilla",
	'en': "Anguilla",
}, {
	"Code": "AQ",
	'en-GB': "Antarctica",
	'en': "Antarctica",
}, {
	"Code": "AG",
	'en-GB': "Antigua and Barbuda",
	'en': "Antigua and Barbuda",
}, {
	"Code": "AR",
	'en-GB': "Argentina",
	'en': "Argentina",
}, {
	"Code": "AM",
	'en-GB': "Armenia",
	'en': "Armenia",
}, {
	"Code": "AW",
	'en-GB': "Aruba",
	'en': "Aruba",
}, {
	"Code": "AU",
	'en-GB': "Australia",
	'en': "Australia",
}, {
	"Code": "AT",
	'en-GB': "Austria",
	'en': "Austria",
}, {
	"Code": "AZ",
	'en-GB': "Azerbaijan",
	'en': "Azerbaijan",
}, {
	"Code": "BS",
	'en-GB': "Bahamas",
	'en': "Bahamas",
}, {
	"Code": "BH",
	'en-GB': "Bahrain",
	'en': "Bahrain",
}, {
	"Code": "BD",
	'en-GB': "Bangladesh"
}, {
	"Code": "BB",
	'en-GB': "Barbados",
	'en': "Barbados",
}, {
	"Code": "BY",
	'en-GB': "Belarus",
	'en': "Belarus",
}, {
	"Code": "BE",
	'en-GB': "Belgium",
	'en': "Belgium",
}, {
	"Code": "BZ",
	'en-GB': "Belize",
	'en': "Belize",
}, {
	"Code": "BJ",
	'en-GB': "Benin",
	'en': "Benin",
}, {
	"Code": "BM",
	'en-GB': "Bermuda",
	'en': "Bermuda",
}, {
	"Code": "BT",
	'en-GB': "Bhutan",
	'en': "Bhutan",
}, {
	"Code": "BO",
	'en-GB': "Bolivia",
	'en': "Bolivia",
}, {
	"Code": "BQ",
	'en-GB': "Bonaire, Sint Eustatius and Saba",
	'en': "Bonaire, Sint Eustatius and Saba",
}, {
	"Code": "BA",
	'en-GB': "Bosnia and Herzegovina",
	'en': "Bosnia and Herzegovina",
}, {
	"Code": "BW",
	'en-GB': "Botswana",
	'en': "Botswana",
}, {
	"Code": "BV",
	'en-GB': "Bouvet Island",
	'en': "Bouvet Island",
}, {
	"Code": "BR",
	'en-GB': "Brazil",
	'en': "Brazil",
}, {
	"Code": "IO",
	'en-GB': "British Indian Ocean Territory",
	'en': "British Indian Ocean Territory",
}, {
	"Code": "BN",
	'en-GB': "Brunei",
	'en': "Brunei",
}, {
	"Code": "BG",
	'en-GB': "Bulgaria",
	'en': "Bulgaria",
}, {
	"Code": "BF",
	'en-GB': "Burkina Faso",
	'en': "Burkina Faso",
}, {
	"Code": "BI",
	'en-GB': "Burundi",
	'en': "Burundi",
}, {
	"Code": "KH",
	'en-GB': "Cambodia",
	'en': "Cambodia",
}, {
	"Code": "CM",
	'en-GB': "Cameroon",
	'en': "Cameroon",
}, {
	"Code": "CA",
	'en-GB': "Canada",
	'en': "Canada",
}, {
	"Code": "CV",
	'en-GB': "Cape Verde",
	'en': "Cape Verde",
}, {
	"Code": "KY",
	'en-GB': "Cayman Islands",
	'en': "Cayman Islands",
}, {
	"Code": "CF",
	'en-GB': "Central African Republic",
	'en': "Central African Republic",
}, {
	"Code": "TD",
	'en-GB': "Chad",
	'en': "Chad",
}, {
	"Code": "CL",
	'en-GB': "Chile",
	'en': "Chile",
}, {
	"Code": "CN",
	'en-GB': "China",
	'en': "China",
}, {
	"Code": "CX",
	'en-GB': "Christmas Island",
	'en': "Christmas Island",
}, {
	"Code": "CC",
	'en-GB': "Cocos (Keeling) Islands",
	'en': "Cocos (Keeling) Islands",
}, {
	"Code": "CO",
	'en-GB': "Colombia",
	'en': "Colombia",
}, {
	"Code": "KM",
	'en-GB': "Comoros",
	'en': "Comoros",
}, {
	"Code": "CG",
	'en-GB': "Congo (Brazzaville)",
	'en': "Congo (Brazzaville)",
}, {
	"Code": "CD",
	'en-GB': "Congo (Kinshasa)",
	'en': "Congo (Kinshasa)",
}, {
	"Code": "CK",
	'en-GB': "Cook Islands",
	'en': "Cook Islands",
}, {
	"Code": "CR",
	'en-GB': "Costa Rica",
	'en': "Costa Rica",
}, {
	"Code": "CI",
	'en-GB': "Cote d'Ivoire",
	'en': "Cote d'Ivoire",
}, {
	"Code": "HR",
	'en-GB': "Croatia",
	'en': "Croatia",
}, {
	"Code": "CU",
	'en-GB': "Cuba",
	'en': "Cuba",
}, {
	"Code": "CW",
	'en-GB': "Cura\u00e7ao",
	'en': "Cura\u00e7ao",
}, {
	"Code": "CY",
	'en-GB': "Cyprus",
	'en': "Cyprus",
}, {
	"Code": "CZ",
	'en-GB': "Czech Republic",
	'en': "Czech Republic",
}, {
	"Code": "DK",
	'en-GB': "Denmark",
	'en': "Denmark",
}, {
	"Code": "DJ",
	'en-GB': "Djibouti",
	'en': "Djibouti",
}, {
	"Code": "DM",
	'en-GB': "Dominica",
	'en': "Dominica",
}, {
	"Code": "DO",
	'en-GB': "Dominican Republic",
	'en': "Dominican Republic",
}, {
	"Code": "EC",
	'en-GB': "Ecuador",
	'en': "Ecuador",
}, {
	"Code": "EG",
	'en-GB': "Egypt",
	'en': "Egypt",
}, {
	"Code": "SV",
	'en-GB': "El Salvador",
	'en': "El Salvador",
}, {
	"Code": "GQ",
	'en-GB': "Equatorial Guinea",
	'en': "Equatorial Guinea",
}, {
	"Code": "ER",
	'en-GB': "Eritrea",
	'en': "Eritrea",
}, {
	"Code": "EE",
	'en-GB': "Estonia",
	'en': "Estonia",
}, {
	"Code": "ET",
	'en-GB': "Ethiopia",
	'en': "Ethiopia",
}, {
	"Code": "FK",
	'en-GB': "Falkland Islands",
	'en': "Falkland Islands",
}, {
	"Code": "FO",
	'en-GB': "Faroe Islands",
	'en': "Faroe Islands",
}, {
	"Code": "FJ",
	'en-GB': "Fiji",
	'en': "Fiji",
}, {
	"Code": "FI",
	'en-GB': "Finland",
	'en': "Finland",
}, {
	"Code": "FR",
	'en-GB': "France",
	'en': "France",
}, {
	"Code": "GF",
	'en-GB': "French Guiana",
	'en': "French Guiana",
}, {
	"Code": "PF",
	'en-GB': "French Polynesia",
	'en': "French Polynesia",
}, {
	"Code": "TF",
	'en-GB': "French Southern Territories",
	'en': "French Southern Territories",
}, {
	"Code": "GA",
	'en-GB': "Gabon",
	'en': "Gabon",
}, {
	"Code": "GM",
	'en-GB': "Gambia",
	'en': "Gambia",
}, {
	"Code": "GE",
	'en-GB': "Georgia",
	'en': "Georgia",
}, {
	"Code": "DE",
	'en-GB': "Germany",
	'en': "Germany",
}, {
	"Code": "GH",
	'en-GB': "Ghana",
	'en': "Ghana",
}, {
	"Code": "GI",
	'en-GB': "Gibraltar",
	'en': "Gibraltar",
}, {
	"Code": "GR",
	'en-GB': "Greece",
	'en': "Greece",
}, {
	"Code": "GL",
	'en-GB': "Greenland",
	'en': "Greenland",
}, {
	"Code": "GD",
	'en-GB': "Grenada",
	'en': "Grenada",
}, {
	"Code": "GP",
	'en-GB': "Guadeloupe",
	'en': "Guadeloupe",
}, {
	"Code": "GU",
	'en-GB': "Guam",
	'en': "Guam",
}, {
	"Code": "GT",
	'en-GB': "Guatemala",
	'en': "Guatemala",
}, {
	"Code": "GG",
	'en-GB': "Guernsey",
	'en': "Guernsey",
}, {
	"Code": "GN",
	'en-GB': "Guinea",
	'en': "Guinea",
}, {
	"Code": "GW",
	'en-GB': "Guinea-Bissau",
	'en': "Guinea-Bissau",
}, {
	"Code": "GY",
	'en-GB': "Guyana",
	'en': "Guyana",
}, {
	"Code": "HT",
	'en-GB': "Haiti",
	'en': "Haiti",
}, {
	"Code": "HM",
	'en-GB': "Heard Island and McDonald Islands",
	'en': "Heard Island and McDonald Islands",
}, {
	"Code": "VA",
	'en-GB': "Holy See (Vatican City State)",
	'en': "Holy See (Vatican City State)",
}, {
	"Code": "HN",
	'en-GB': "Honduras",
	'en': "Honduras",
}, {
	"Code": "HK",
	'en-GB': "Hong Kong",
	'en': "Hong Kong",
}, {
	"Code": "HU",
	'en-GB': "Hungary",
	'en': "Hungary",
}, {
	"Code": "IS",
	'en-GB': "Iceland",
	'en': "Iceland",
}, {
	"Code": "IN",
	'en-GB': "India",
	'en': "India",
}, {
	"Code": "ID",
	'en-GB': "Indonesia",
	'en': "Indonesia",
}, {
	"Code": "IR",
	'en-GB': "Iran",
	'en': "Iran",
}, {
	"Code": "IQ",
	'en-GB': "Iraq",
	'en': "Iraq",
}, {
	"Code": "IE",
	'en-GB': "Ireland",
	'en': "Ireland",
}, {
	"Code": "IM",
	'en-GB': "Isle of Man",
	'en': "Isle of Man",
}, {
	"Code": "IL",
	'en-GB': "Israel",
	'en': "Israel",
}, {
	"Code": "IT",
	'en-GB': "Italy",
	'en': "Italy",
}, {
	"Code": "JM",
	'en-GB': "Jamaica",
	'en': "Jamaica",
}, {
	"Code": "JP",
	'en-GB': "Japan",
	'en': "Japan",
}, {
	"Code": "JE",
	'en-GB': "Jersey",
	'en': "Jersey",
}, {
	"Code": "JO",
	'en-GB': "Jordan",
	'en': "Jordan",
}, {
	"Code": "UM",
	'en-GB': "Johnston Atoll",
	'en': "Johnston Atoll",
}, {
	"Code": "KZ",
	'en-GB': "Kazakhstan",
	'en': "Kazakhstan",
}, {
	"Code": "KE",
	'en-GB': "Kenya",
	'en': "Kenya",
}, {
	"Code": "KI",
	'en-GB': "Kiribati",
	'en': "Kiribati",
}, {
	"Code": "KP",
	'en-GB': "Korea",
	'en': "Korea",
}, {
	"Code": "KR",
	'en-GB': "Korea, Republic of",
	'en': "Korea, Republic of",
}, {
	"Code": "KW",
	'en-GB': "Kuwait",
	'en': "Kuwait",
}, {
	"Code": "KG",
	'en-GB': "Kyrgyzstan",
	'en': "Kyrgyzstan",
}, {
	"Code": "LA",
	'en-GB': "Laos",
	'en': "Laos",
}, {
	"Code": "LV",
	'en-GB': "Latvia",
	'en': "Latvia",
}, {
	"Code": "LB",
	'en-GB': "Lebanon",
	'en': "Lebanon",
}, {
	"Code": "LS",
	'en-GB': "Lesotho",
	'en': "Lesotho",
}, {
	"Code": "LR",
	'en-GB': "Liberia",
	'en': "Liberia",
}, {
	"Code": "LY",
	'en-GB': "Libya",
	'en': "Libya",
}, {
	"Code": "LI",
	'en-GB': "Liechtenstein",
	'en': "Liechtenstein",
}, {
	"Code": "LT",
	'en-GB': "Lithuania",
	'en': "Lithuania",
}, {
	"Code": "LU",
	'en-GB': "Luxembourg",
	'en': "Luxembourg",
}, {
	"Code": "MO",
	'en-GB': "Macao",
	'en': "Macao",
}, {
	"Code": "MK",
	'en-GB': "Macedonia",
	'en': "Macedonia",
}, {
	"Code": "MG",
	'en-GB': "Madagascar",
	'en': "Madagascar",
}, {
	"Code": "MW",
	'en-GB': "Malawi",
	'en': "Malawi",
}, {
	"Code": "MY",
	'en-GB': "Malaysia",
	'en': "Malaysia",
}, {
	"Code": "MV",
	'en-GB': "Maldives",
	'en': "Maldives",
}, {
	"Code": "ML",
	'en-GB': "Mali",
	'en': "Mali",
}, {
	"Code": "MT",
	'en-GB': "Malta",
	'en': "Malta",
}, {
	"Code": "MH",
	'en-GB': "Marshall Islands",
	'en': "Marshall Islands",
}, {
	"Code": "MQ",
	'en-GB': "Martinique",
	'en': "Martinique",
}, {
	"Code": "MR",
	'en-GB': "Mauritania",
	'en': "Mauritania",
}, {
	"Code": "MU",
	'en-GB': "Mauritius",
	'en': "Mauritius",
}, {
	"Code": "YT",
	'en-GB': "Mayotte",
	'en': "Mayotte",
}, {
	"Code": "MX",
	'en-GB': "Mexico",
	'en': "Mexico",
}, {
	"Code": "FM",
	'en-GB': "Micronesia",
	'en': "Micronesia",
}, {
	"Code": "MI",
	'en-GB': "Midway Islands",
	'en': "Midway Islands",
}, {
	"Code": "MD",
	'en-GB': "Moldova",
	'en': "Moldova",
}, {
	"Code": "MC",
	'en-GB': "Monaco",
	'en': "Monaco",
}, {
	"Code": "MN",
	'en-GB': "Mongolia",
	'en': "Mongolia",
}, {
	"Code": "ME",
	'en-GB': "Montenegro",
	'en': "Montenegro",
}, {
	"Code": "MS",
	'en-GB': "Montserrat",
	'en': "Montserrat",
}, {
	"Code": "MA",
	'en-GB': "Morocco",
	'en': "Morocco",
}, {
	"Code": "MZ",
	'en-GB': "Mozambique",
	'en': "Mozambique",
}, {
	"Code": "MM",
	'en-GB': "Burma",
	'en': "Burma",
}, {
	"Code": "MM",
	'en-GB': "Myanmar",
	'en': "Myanmar",
}, {
	"Code": "NA",
	'en-GB': "Namibia",
	'en': "Namibia",
}, {
	"Code": "NR",
	'en-GB': "Nauru",
	'en': "Nauru",
}, {
	"Code": "NP",
	'en-GB': "Nepal",
	'en': "Nepal",
}, {
	"Code": "NL",
	'en-GB': "Netherlands",
	'en': "Netherlands",
}, {
	"Code": "AN",
	'en-GB': "Netherlands Antilles",
	'en': "Netherlands Antilles",
}, {
	"Code": "NC",
	'en-GB': "New Caledonia",
	'en': "New Caledonia",
}, {
	"Code": "NZ",
	'en-GB': "New Zealand",
	'en': "New Zealand",
}, {
	"Code": "NI",
	'en-GB': "Nicaragua",
	'en': "Nicaragua",
}, {
	"Code": "NE",
	'en-GB': "Niger",
	'en': "Niger",
}, {
	"Code": "NG",
	'en-GB': "Nigeria",
	'en': "Nigeria",
}, {
	"Code": "NU",
	'en-GB': "Niue",
	'en': "Niue",
}, {
	"Code": "NF",
	'en-GB': "Norfolk Island",
	'en': "Norfolk Island",
}, {
	"Code": "MP",
	'en-GB': "Northern Mariana Islands",
	'en': "Northern Mariana Islands",
}, {
	"Code": "NO",
	'en-GB': "Norway",
	'en': "Norway",
}, {
	"Code": "OM",
	'en-GB': "Oman",
	'en': "Oman",
}, {
	"Code": "PK",
	'en-GB': "Pakistan",
	'en': "Pakistan",
}, {
	"Code": "PW",
	'en-GB': "Palau",
	'en': "Palau",
}, {
	"Code": "PS",
	'en-GB': "Palestine",
	'en': "Palestine",
}, {
	"Code": "PA",
	'en-GB': "Panama",
	'en': "Panama",
}, {
	"Code": "PG",
	'en-GB': "Papua New Guinea",
	'en': "Papua New Guinea",
}, {
	"Code": "PY",
	'en-GB': "Paraguay",
	'en': "Paraguay",
}, {
	"Code": "PE",
	'en-GB': "Peru",
	'en': "Peru",
}, {
	"Code": "PH",
	'en-GB': "Philippines",
	'en': "Philippines",
}, {
	"Code": "PN",
	'en-GB': "Pitcairn",
	'en': "Pitcairn",
}, {
	"Code": "PL",
	'en-GB': "Poland",
	'en': "Poland",
}, {
	"Code": "PT",
	'en-GB': "Portugal",
	'en': "Portugal",
}, {
	"Code": "PR",
	'en-GB': "Puerto Rico",
	'en': "Puerto Rico",
}, {
	"Code": "QA",
	'en-GB': "Qatar",
	'en': "Qatar",
}, {
	"Code": "RE",
	'en-GB': "Reunion",
	'en': "Reunion",
}, {
	"Code": "RO",
	'en-GB': "Romania",
	'en': "Romania",
}, {
	"Code": "RU",
	'en-GB': "Russia",
	'en': "Russia",
}, {
	"Code": "RW",
	'en-GB': "Rwanda",
	'en': "Rwanda",
}, {
	"Code": "BL",
	'en-GB': "Saint Barth\u00e9lemy",
	'en': "Saint Barth\u00e9lemy",
}, {
	"Code": "SH",
	'en-GB': "Saint Helena, Ascension and Tristan da Cunha",
	'en': "Saint Helena, Ascension and Tristan da Cunha",
}, {
	"Code": "KN",
	'en-GB': "Saint Kitts and Nevis",
	'en': "Saint Kitts and Nevis",
}, {
	"Code": "LC",
	'en-GB': "Saint Lucia",
	'en': "Saint Lucia",
}, {
	"Code": "MF",
	'en-GB': "Saint Martin (French part)",
	'en': "Saint Martin (French part)",
}, {
	"Code": "PM",
	'en-GB': "Saint Pierre and Miquelon",
	'en': "Saint Pierre and Miquelon",
}, {
	"Code": "VC",
	'en-GB': "Saint Vincent and the Gre",
	'en': "Saint Vincent and the Gre",
}, {
	"Code": "WS",
	'en-GB': "Samoa",
	'en': "Samoa",
}, {
	"Code": "SM",
	'en-GB': "San Marino",
	'en': "San Marino",
}, {
	"Code": "ST",
	'en-GB': "Sao Tome and Principe",
	'en': "Sao Tome and Principe",
}, {
	"Code": "SA",
	'en-GB': "Saudi Arabia",
	'en': "Saudi Arabia",
}, {
	"Code": "SN",
	'en-GB': "Senegal",
	'en': "Senegal",
}, {
	"Code": "RS",
	'en-GB': "Serbia",
	'en': "Serbia",
}, {
	"Code": "SC",
	'en-GB': "Seychelles",
	'en': "Seychelles",
}, {
	"Code": "SL",
	'en-GB': "Sierra Leone",
	'en': "Sierra Leone",
}, {
	"Code": "SG",
	'en-GB': "Singapore",
	'en': "Singapore",
}, {
	"Code": "SX",
	'en-GB': "Sint Maarten (Dutch part)",
	'en': "Sint Maarten (Dutch part)",
}, {
	"Code": "SK",
	'en-GB': "Slovakia",
	'en': "Slovakia",
}, {
	"Code": "SI",
	'en-GB': "Slovenia",
	'en': "Slovenia",
}, {
	"Code": "SB",
	'en-GB': "Solomon Islands",
	'en': "Solomon Islands",
}, {
	"Code": "SO",
	'en-GB': "Somalia",
	'en': "Somalia",
}, {
	"Code": "ZA",
	'en-GB': "South Africa",
	'en': "South Africa",
}, {
	"Code": "GS",
	'en-GB': "South Georgia and the South Sandwich Islands",
	'en': "South Georgia and the South Sandwich Islands",
}, {
	"Code": "SS",
	'en-GB': "South Sudan",
	'en': "South Sudan",
}, {
	"Code": "ES",
	'en-GB': "Spain",
	'en': "Spain",
}, {
	"Code": "LK",
	'en-GB': "Sri Lanka",
	'en': "Sri Lanka",
}, {
	"Code": "SD",
	'en-GB': "Sudan",
	'en': "Sudan",
}, {
	"Code": "SR",
	'en-GB': "Suriname",
	'en': "Suriname",
}, {
	"Code": "SJ",
	'en-GB': "Svalbard",
	'en': "Svalbard",
}, {
	"Code": "SZ",
	'en-GB': "Swaziland",
	'en': "Swaziland",
}, {
	"Code": "SE",
	'en-GB': "Sweden",
	'en': "Sweden",
}, {
	"Code": "CH",
	'en-GB': "Switzerland",
	'en': "Switzerland",
}, {
	"Code": "SY",
	'en-GB': "Syrian",
	'en': "Syrian",
}, {
	"Code": "TW",
	'en-GB': "Taiwan",
	'en': "Taiwan",
}, {
	"Code": "TJ",
	'en-GB': "Tajikistan",
	'en': "Tajikistan",
}, {
	"Code": "TZ",
	'en-GB': "Tanzania",
	'en': "Tanzania",
}, {
	"Code": "TH",
	'en-GB': "Thailand",
	'en': "Thailand",
}, {
	"Code": "TL",
	'en-GB': "East Timor",
	'en': "East Timor",
}, {
	"Code": "TG",
	'en-GB': "Togo",
	'en': "Togo",
}, {
	"Code": "TK",
	'en-GB': "Tokelau",
	'en': "Tokelau",
}, {
	"Code": "TO",
	'en-GB': "Tonga",
	'en': "Tonga",
}, {
	"Code": "TT",
	'en-GB': "Trinidad and Tobago",
	'en': "Trinidad and Tobago",
}, {
	"Code": "TN",
	'en-GB': "Tunisia",
	'en': "Tunisia",
}, {
	"Code": "TR",
	'en-GB': "Turkey",
	'en': "Turkey",
}, {
	"Code": "TM",
	'en-GB': "Turkmenistan",
	'en': "Turkmenistan",
}, {
	"Code": "TC",
	'en-GB': "Turks and Caicos Islands",
	'en': "Turks and Caicos Islands",
}, {
	"Code": "TV",
	'en-GB': "Tuvalu",
	'en': "Tuvalu",
}, {
	"Code": "UG",
	'en-GB': "Uganda",
	'en': "Uganda",
}, {
	"Code": "UA",
	'en-GB': "Ukraine",
	'en': "Ukraine",
}, {
	"Code": "AE",
	'en-GB': "United Arab Emirates",
	'en': "United Arab Emirates",
}, {
	"Code": "GB",
	'en-GB': "United Kingdom",
	'en': "United Kingdom",
}, {
	"Code": "US",
	'en-GB': "United States",
	'en': "United States",
}, {
	"Code": "UM",
	'en-GB': "United States Minor Outlying Islands",
	'en': "United States Minor Outlying Islands",
}, {
	"Code": "UY",
	'en-GB': "Uruguay",
	'en': "Uruguay",
}, {
	"Code": "UZ",
	'en-GB': "Uzbekistan",
	'en': "Uzbekistan",
}, {
	"Code": "VU",
	'en-GB': "Vanuatu",
	'en': "Vanuatu",
}, {
	"Code": "VE",
	'en-GB': "Venezuela, Bolivarian Republic of",
	'en': "Venezuela, Bolivarian Republic of",
}, {
	"Code": "VN",
	'en-GB': "Vietnam",
	'en': "Vietnam",
}, {
	"Code": "VG",
	'en-GB': "British Virgin Islands",
	'en': "British Virgin Islands",
}, {
	"Code": "VI",
	'en-GB': "Virgin Islands",
	'en': "Virgin Islands",
}, {
	"Code": "WF",
	'en-GB': "Wallis and Futuna",
	'en': "Wallis and Futuna",
}, {
	"Code": "EH",
	'en-GB': "Western Sahara",
	'en': "Western Sahara",
}, {
	"Code": "YE",
	'en-GB': "Yemen",
	'en': "Yemen",
}, {
	"Code": "ZM",
	'en-GB': "Zambia",
	'en': "Zambia",
}, {
	"Code": "ZW",
	'en-GB': "Zimbabwe",
	'en': "Zimbabwe",
}, {
	"Code": "XX",
	'en-GB': "International",
	'en': "International",
}]
