import React from "react";
import { Loader } from "components/common";
import BaggageModal from "components/common/BaggageCount/BaggageModal";
import HopSegButton from "./HopSegButton";
import getTranslation from "utils/getTranslation";
import {
  BaggageIcon,
  NoBaggageIcon,
  AddIcon,
  EditIcon,
} from "components/common/SvgIcons";
import { addDecimal, getItem, numberToLocaleString } from "utils/utils";

class BaggageButton extends React.PureComponent {
  state = {
    airportData: [],
    selectedIndex: 0,
    openModal: false,
  };

  getTotalCount = (extraBaggages) => {
    let sumStr = 0;
    if (Array.isArray(extraBaggages)) {
      const sum = extraBaggages.reduce(
        (accumulator, currentVal) => accumulator + currentVal.quantity,
        0
      );
      sumStr = sum;
      return sumStr;
    }
    return sumStr;
  };

  getTotalWeight = (extraBaggages) => {
    let sumStr = 0;
    if (Array.isArray(extraBaggages)) {
      const sum = extraBaggages.reduce(
        (accumulator, currentVal) =>
          accumulator +
          (currentVal.quantity > 0
            ? currentVal.baggagePieceMaxWeight * currentVal.quantity
            : 0),
        0
      );
      sumStr = sum;
      return sumStr;
    }
    return sumStr;
  };

  getTotalCost = (extraBaggages) => {
    let sumStr = 0;
    if (Array.isArray(extraBaggages)) {
      const sum = extraBaggages.reduce(
        (accumulator, currentVal) =>
          accumulator +
          (currentVal.quantity > 0
            ? currentVal.serviceCostAmount * currentVal.quantity
            : 0),
        0
      );
      sumStr = sum;
    }
    if (extraBaggages.length > 0) {
      const { costDecimalPlaces, serviceCostCurrencyIso } = extraBaggages[0];
      sumStr = numberToLocaleString(addDecimal(sumStr, costDecimalPlaces), {
        selectedCountry: getItem("selectedLanguage"),
        selectedCurrency: serviceCostCurrencyIso,
      });
    }
    return sumStr;
  };

  handleRequestClose = () => {
    this.setState({
      openModal: false,
    });
  };

  handleClick = (e) => {
    e.preventDefault();
    this.setState({
      openModal: true,
    });
  };

  render() {
    const {
      disabled,
      extraBaggages,
      extraBaggagesInbound,
      userTrips,
      extraCheckDone,
      loading,
      hopRequestId,
    } = this.props;
    const totalBaggage = this.getTotalCount([
      ...extraBaggages,
      ...extraBaggagesInbound,
    ]);
    const totalWeight = this.getTotalWeight(extraBaggages);
    const totalCost = this.getTotalCost(extraBaggages);
    return (
      <div className="hopSegButton">
        <HopSegButton
          onClick={this.handleClick}
          disabled={disabled}
          active={totalBaggage > 0}
          label="Extra Baggage"
          btnContent={
            <>
              {!extraCheckDone && !userTrips && !loading && (
                <div style={{ position: "absolute", top: "15%" }}>
                  <Loader />
                </div>
              )}
              {extraBaggages.length > 0 ? (
                <>
                  {totalBaggage > 0 ? (
                    <BaggageIcon stroke="#222222" />
                  ) : (
                    <NoBaggageIcon stroke="#222222" />
                  )}
                  <b key="1" className="__release-text">
                    {totalBaggage > 0 ? `${totalWeight} Kg` : ""}
                  </b>
                </>
              ) : (
                <>
                  <NoBaggageIcon stroke="#222222" />
                  <b className="__release-text">{getTranslation("none")}</b>
                </>
              )}
            </>
          }
          text={
            extraBaggages.length > 0 ? (
              <p
                key="2"
                style={{ textAlign: "center", marginTop: 3, width: "100%" }}
              >
                {totalBaggage > 0
                  ? "+ " + totalCost
                  : getTranslation("noneAdded")}
              </p>
            ) : (
              <p
                key="3"
                style={{ textAlign: "center", marginTop: 3, width: "100%" }}
              >
                {getTranslation("enquireWithAirline")}
              </p>
            )
          }
        ></HopSegButton>
        {!disabled && (
          <BaggageModal
            open={this.state.openModal}
            handleCloseModal={this.handleRequestClose}
            extraBaggages={extraBaggages}
            extraBaggagesInbound={extraBaggagesInbound}
            hopRequestId={hopRequestId}
          ></BaggageModal>
        )}
      </div>
    );
  }
}

export default BaggageButton;
