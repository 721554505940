import React, { Component } from "react";
import styled, { withTheme } from "styled-components";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";
import { graphql, compose } from "react-apollo";
import { updateUserDate } from "redux-store/actions";
import { Button, Input, Toast, Select } from "components/common";
import { updGuestTripowner } from "../PaymentQuery";
import { updTripowner } from "containers/TripBooking/queries";
import {
  validateEmail,
  authenticate,
  getAccessToken,
  registerGuestPhone,
} from "utils/api";
import { getItem, setItem, removeItem } from "utils/utils";
import getTranslation from "utils/getTranslation";
import phoneCountryCodes from "utils/phoneCountryCodes";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #000;
  }
`;

const Continer = styled.div`
  width: 332px;
`;

let timeout = null;
class ContinueBooking extends Component {
  constructor(props) {
    super(props);
    const defaultCode = phoneCountryCodes.find(
      (item) => item.code === getItem("selectedCountry")
    );
    this.state = {
      loading: false,
      isValidEmail: true,
      email: "",
      showPhone: false,
      countryCode: defaultCode ? defaultCode.dial_code : null,
    };
  }

  async componentWillMount() {
    if (getItem("tokenDetails")) {
      if (!getItem("mobile_number_obf")) {
        this.setState({ mobileCheck: true });
        const validate = await validateEmail(
          getItem("selectedCountry" || "XX"), getItem("loggedInUsername")
        );
        if (!validate.mobile) {
          this.setState({
            showPhone: true,
            onlyPhone: true,
            mobileCheck: false,
          });
        } else {
          setItem("mobile_number_obf", validate.mobile_number_obf);
          this.setState({
            mobileCheck: true,
          });
          this.props.handleShowPayment(true);
        }
      } else {
        this.props.handleShowPayment(true);
      }
    }
  }

  setGuesLogin = () => {
    const payload = {
      email: this.state.email,
    };
    setItem("guestLogin", payload);
    this.setState({ done: true });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        if (name === "email") {
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            this.handleEmailCheck(this.state.email);
          }, 500);
        }
      }
    );
  };

  handleEmailCheck = (text) => {
    const re = /\S+@\S+\.\S+/;
    const validEmail = re.test(text);
    if (validEmail) {
      this.validateEmailId();
    }
  };

  validateEmailId = async () => {
    try {
      this.setState({ loading: true, validating: true });
      const validate = await validateEmail(getItem("selectedCountry" || "XX"), this.state.email);
      this.setState({
        loading: false,
        validating: false,
        isValidEmail: validate.valid,
        isRegistered: validate.registered,
        validated: true,
        showPhone: validate.valid ? !validate.mobile : false,
      });
      if (validate.mobile) {
        setItem("mobile_number_obf", validate.mobile_number_obf);
      }
    } catch (err) {
      console.log(err);
      this.setState({
        loading: false,
        validating: false,
        isValidEmail: false,
        showPhone: false,
      });
    }
  };
  handleGuestLogin = async () => {
    const { phoneNumber, countryCode, email, showPhone } = this.state;
    if (showPhone) {
      const payload = {
        email,
        mobile: phoneNumber.replace(/\D/g, ""),
        country_code: countryCode,
      };
      const newMob = await registerGuestPhone(payload);
      if (newMob.success) {
        this.handleSetMobileNum();
      } else {
        this.setState({
          error: true,
          errorMsg: newMob.message,
        });
        return;
      }
    }
    this.props
      .updGuestTripowner({
        variables: {
          guestEmail: email,
          tripId: getItem("tripId"),
        },
      })
      .then(() => {
        this.setGuesLogin();
        this.props.handleShowPayment(true);
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  };

  handleLogin = async () => {
    const { showPhone, phoneNumber, countryCode, email, password } = this.state;
    let phoneDetails = {};
    if (showPhone) {
      phoneDetails = {
        email,
        mobile: phoneNumber.replace(/\D/g, ""),
        country_code: countryCode,
      };
      const newMob = await registerGuestPhone(phoneDetails);
      if (newMob.success) {
        this.handleSetMobileNum();
      } else {
        this.setState({
          error: true,
          errorMsg: newMob.message,
        });
        return;
      }
    }
    const payload = {
      login: email,
      password: password,
    };
    this.setState({ loading: true });
    try {
      const auth = await authenticate(payload);
      if (auth.code) {
        const tokenDetails = await getAccessToken(auth.code);
        tokenDetails.expiryTime = moment()
          .add(tokenDetails.expires_in, "seconds")
          .unix();
        this.props.updateUserDate(tokenDetails);
        setItem("tokenDetails", tokenDetails);
        removeItem("guestLogin");
        this.handleTripOwnerUpdate();
        if (showPhone) {
          this.handleSetMobileNum();
        }
      } else {
        this.setState({
          loading: false,
          error: true,
          errorMsg: getTranslation("genericErrorMsg"),
        });
      }
    } catch (err) {
      console.log(err);
      this.setState({
        loading: false,
        error: true,
        errorMsg: getTranslation("genericErrorMsg"),
      });
    }
  };

  handleTripOwnerUpdate = () => {
    this.props
      .updTripowner({
        variables: {
          tripId: getItem("tripId"),
        },
      })
      .then((data) => {
        console.log(data);
        this.setState({ done: true });
        this.props.handleShowPayment(true);
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
        });
      });
  };

  handleRequestClose = () => {
    this.setState({
      error: false,
    });
  };

  handleCountryCodeChange = (e) => {
    this.setState({ countryCode: e.target.value });
  };

  handleSelectOpen = () => {
    this.setState({ codeSelectOpen: true });
  };

  handleSelectClose = () => {
    this.setState({ codeSelectOpen: false });
  };

  handleSetMobileNum = () => {
    const { countryCode, phoneNumber } = this.state;
    const mobile = `+${countryCode.replace(
      /\D/g,
      ""
    )}${phoneNumber.toString().replace(/(?!^)\d(?=\d{4})/g, "*")}`;
    setItem("mobile_number_obf", mobile);
  };

  handleAddPhone = async () => {
    this.setState({ loading: true });
    const { phoneNumber, countryCode } = this.state;
    const payload = {
      email: getItem("loggedInUsername"),
      mobile: phoneNumber.replace(/\D/g, ""),
      country_code: countryCode,
    };
    const newMob = await registerGuestPhone(payload);
    if (newMob.success) {
      this.handleSetMobileNum();
      this.setState({ done: true });
      this.props.handleShowPayment(true);
    } else {
      this.setState({
        error: true,
        errorMsg: newMob.message,
      });
      return;
    }
  };

  render() {
    const {
      loading,
      email,
      isValidEmail,
      isRegistered,
      password,
      done,
      error,
      errorMsg,
      validating,
      validated,
      phoneNumber,
      countryCode,
      showPhone,
      codeSelectOpen,
      onlyPhone,
      mobileCheck,
    } = this.state;
    return !getItem("mobile_number_obf") ||
      (!getItem("tokenDetails") && !getItem("guestLogin") && !done) ? (
        <Wrapper>
          <Continer>
            {!mobileCheck && !onlyPhone && (
              <Input
                label={getTranslation("email")}
                value={email}
                fullWidth
                errorText={!isValidEmail ? "Please enter a valid Email" : ""}
                name="email"
                onChange={this.handleChange}
              />
            )}

            {isRegistered && (
              <Input
                label={getTranslation("password")}
                value={password}
                fullWidth
                name="password"
                type="password"
                onChange={this.handleChange}
              />
            )}
            {showPhone && (
              <div style={{ display: "flex" }}>
                <Select
                  label={getTranslation("intlCode")}
                  value={countryCode}
                  style={{ width: 150, marginRight: 10 }}
                  handleChange={this.handleCountryCodeChange}
                  onOpen={this.handleSelectOpen}
                  onClose={this.handleSelectClose}
                  menuItems={phoneCountryCodes.map((item) => (
                    <MenuItem value={item.dial_code} key={item.code}>
                      {!codeSelectOpen
                        ? item.dial_code
                        : item.name + " " + item.dial_code}
                    </MenuItem>
                  ))}
                />
                <Input
                  label={getTranslation("mobileNumber")}
                  value={phoneNumber}
                  fullWidth
                  name="phoneNumber"
                  onChange={this.handleChange}
                />
              </div>
            )}
            {isRegistered && !onlyPhone && (
              <Button
                disabled={loading || !password}
                style={{ marginBottom: 20 }}
                onClick={this.handleLogin}
              >
                {getTranslation("loginConfirmBooking")}
              </Button>
            )}
            {!isRegistered && !onlyPhone && (
              <Button
                disabled={loading || !validated}
                style={{ marginBottom: 20 }}
                color={this.props.theme.primary}
                onClick={this.handleGuestLogin}
              >
                {validating
                  ? getTranslation("checkingForUser")
                  : getTranslation("confirmBooking")}
              </Button>
            )}
            {onlyPhone && (
              <Button
                disabled={loading}
                style={{ marginBottom: 20 }}
                onClick={this.handleAddPhone}
              >
                {validating
                  ? getTranslation("checkingForUser")
                  : getTranslation("confirmBooking")}
              </Button>
            )}
          </Continer>
          <Toast
            open={error}
            message={errorMsg}
            handleClose={this.handleRequestClose}
          />
        </Wrapper>
      ) : null;
  }
}

export default withTheme(
  withRouter(
    compose(
      graphql(updGuestTripowner, { name: "updGuestTripowner" }),
      graphql(updTripowner, { name: "updTripowner" }),
      connect(null, { updateUserDate })
    )(ContinueBooking)
  )
);
