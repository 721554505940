export default `
# Politique de confidentialité

# Introduction

Fly Strait OÜ, enregistré à Tallinn, Estonie sous le numéro d&#39;enregistrement 14373971 («nous», «notre», «notre» ou simplement «Fly Strait») est ce que l&#39;on appelle le «responsable du traitement» de vos données personnelles et est donc responsable d&#39;assurer le caractère légal de ce que nous faisons avec vos données personnelles.

Ce site Web est exploité par Fly Strait. Nous traitons une variété de données personnelles, telles que noms, adresses électroniques et autres informations relatives aux voyages dans leurs activités quotidiennes. Par conséquent, nous prenons très au sérieux la sécurité des données et le respect de la législation en matière de protection des données. Cette politique de confidentialité explique comment nous recueillons, stockons, utilisons et divulguons toutes les données personnelles que nous recueillons à votre sujet lorsque vous utilisez ce site, ainsi que comment nous protégeons la confidentialité et la confidentialité de vos informations personnelles. Votre vie privée nous importe donc, que vous soyez un nouvel utilisateur ou un utilisateur de longue date, nous vous prions de prendre le temps de vous familiariser avec nos pratiques - et contactez-nous si vous avez des questions.

# Données personnelles récoltées par notre organisme

En règle générale, les données personnelles que nous collectons sont les informations dont nous avons besoin pour vous permettre d&#39;organiser votre voyage et vos réservations. Cela inclut des informations telles que votre nom et prénom, votre date de naissance, votre numéro de téléphone et votre adresse électronique. Les données personnelles que nous devons recevoir pour vous fournir les prestations de voyage que vous avez réservées via nos sites Web sont les seules données que vous devez obligatoirement fournir. En fonction du type des prestations de voyage que vous utilisez, nous pouvons également recueillir votre numéro d&#39;identification de voyageur régulier, des informations sur vos besoins diététiques et vos problèmes de santé (le cas échéant), ainsi que d&#39;autres informations relatives à vos préparatifs de voyage ou requises par un autre prestataire de service (comme les compagnies aériennes et les hôtels). Cette liste n&#39;est pas exhaustive. Si vous appelez notre centre de support, nous collecterons les données que vous avez fournies lors de l&#39;appel téléphonique. Comme vous pouvez le voir ci-dessous, nos cookies collectent également certaines informations.

Si vous effectuez une réservation pour quelqu&#39;un d&#39;autre via notre site Web, nous vous demanderons des données personnelles le concernant. Dans ces circonstances, nous comptons sur vous pour informer ces personnes de la présente politique de confidentialité.

# Données personnelles sensibles récoltées par notre organisme

Dans certains cas, nous pouvons traiter des «catégories spéciales de données à caractère personnel» vous concernant, qui peuvent être considérées comme sensibles. Ce serait le cas, par exemple, si vous (i) avez présenté un certificat médical attestant de l&#39;utilisation d&#39;une protection d&#39;annulation ou du remboursement d&#39;une compagnie aérienne (ii) souffrez d&#39;un problème de santé ou d&#39;un état de santé qui affecte votre voyage et pour lequel vous demandez de l&#39;aide ou lorsqu&#39;une autorisation est nécessaire, ou (iii) avez fait une demande révélant d&#39;autres données personnelles sensibles vous concernant.

Avant de traiter des données personnelles sensibles vous concernant, nous avons besoin de votre consentement pour le faire. Pour soumettre des données sensibles, nous vous demandons donc d&#39;utiliser les formulaires de contact dédiés sur nos sites Web. Les formulaires de contact vous permettent de nous donner le consentement requis en vertu de la législation applicable en matière de protection des données. Cet accord est révocable à tout moment. Nous ne traiterons aucune donnée personnelle sensible que vous ne nous autorisez pas à traiter ou que vous ne nous avez pas fournie. Une quantité limitée de notre personnel aura accès à vos données personnelles sensibles et, après avoir traité vos données sensibles conformément à votre demande, elles seront effacées dans les plus brefs délais.

# Comment nous utilisons vos données personnelles

Pour pouvoir traiter vos données personnelles, la législation applicable en matière de protection des données nous oblige à disposer d&#39;une &quot;base légale&quot; pour chaque type d&#39;utilisation de vos données personnelles. Pour cette raison, nous avons rédigé le tableau ci-dessous afin d&#39;indiquer la base légale sur laquelle nous nous appuyons pour justifier de chaque utilisation de vos données.

| **Comment nous utilisons (types d&#39;utilisation et de traitement de vos données personnelles)** | **Base légale** | **Durée de stockage** |
| --- | --- | --- |
| Autoriser les préparatifs de voyage et les réservations que vous avez demandés (c&#39;est-à-dire la réservation de services de voyage gérés par nous, ainsi que la fourniture de nos propres services). | Exécution du contrat entre nous et vous. Lorsque vous nous avez fourni des données personnelles sensibles, votre consentement constitue la base légale. | 3 ans à compter de la date d&#39;achat. Votre consentement pour l&#39;utilisation de données personnelles sensibles peut être révoqué à tout moment. |
| Si vous avez choisi de créer un compte utilisateur sur notre site Web, nous mettrons ce compte à votre disposition. Le compte permet d&#39;accéder aux informations relatives à vos précédents voyages et réservations. Nous allons également stocker votre nom d&#39;utilisateur et mot de passe. | Exécution du contrat entre nous et vous. | Les données de votre compte d&#39;utilisateur, ainsi que les informations personnelles relatives à vos préparatifs de voyage et à vos réservations précédentes, seront conservées jusqu&#39;à ce que vous décidiez de supprimer votre compte d&#39;utilisateur via notre site Web. Toutefois, si vous ne vous êtes pas connecté à votre compte d&#39;utilisateur pendant 18 mois, nous pouvons effacer ce compte et les informations qu&#39;il contient, à condition que nous ne basions pas sur une autre base légale pour le stockage. |
| Si vous avez commencé un processus de réservation, mais que vous n&#39;avez pas finalisé l&#39;achat, nous pouvons vous envoyer un email avec un lien vers le résultat de la recherche ou vers la réservation commencée en fonction du moment où votre processus de réservation sur le site Web a été interrompu. | Notre intérêt légitime de faire des affaires et de vous permettre de finaliser votre achat sans avoir à remplir à nouveau toutes les informations. Si vous ne souhaitez pas recevoir ces courriels, vous pouvez vous désinscrire à tout moment. | 24 heures à partir du moment où vous avez quitté le processus de réservation. |
| Avant le début de votre voyage, nous vous fournirons des informations complémentaires et des offres en rapport avec votre voyage, telles que des compléments éventuels tels que des bagages supplémentaires et d&#39;autres informations utiles pour votre voyage. Certaines informations sont basées sur le profilage effectué des informations que vous avez fournies au cours du processus de réservation (par exemple, la date à laquelle vous voyagez, votre destination, etc.). | Notre intérêt légitime de vous offrir un voyage plus pratique et de vous permettre de trouver plus facilement les informations qui vous intéressent. Si vous ne souhaitez pas recevoir ces informations, vous pouvez vous cliquer sur le bouton de désinscription à tout moment dans le courrier électronique. | Jusqu&#39;à la date de début de votre voyage. D&#39;autres traitements similaires peuvent se poursuivre conformément aux utilisations indiquées ci-dessous. |
| Une fois que vous avez planifié votre voyage, nous pouvons vous envoyer notre bulletin d&#39;information contenant des recommandations sur d&#39;autres préparatifs de voyage ainsi que sur des produits et services connexes pouvant vous intéresser. Certaines des recommandations sont basées sur le profilage de vos choix précédents lors de la réservation d&#39;un voyage et sur votre attitude vis-à-vis de nos courriels. | Notre intérêt légitime de faire des affaires et de vous permettre de prendre les dispositions de voyage qui vous intéressent. Si vous ne souhaitez pas recevoir ces courriels, vous pouvez vous désinscrire à tout moment. En outre, avant de finaliser un achat, vous pouvez vous opposer à recevoir le bulletin d&#39;information. | 3 ans à compter de la date d&#39;achat. |
| Enregistrer les appels téléphoniques à des fins d&#39;assurance qualité et pour toute future demande de votre part. | Notre intérêt légitime à (i) améliorer notre service grâce à une formation interne et, le cas échéant (ii) à résoudre vos demandes ou réclamations potentielles. Si vous ne souhaitez pas que l&#39;appel téléphonique soit enregistré, vous pouvez vous opposer à cet enregistrement avant le début de l&#39;enregistrement. | 6 mois à compter de la date à laquelle l&#39;appel téléphonique a été effectué. Seul un nombre limité de personnes a accès à votre enregistrement. |
| L&#39;utilisation de cookies pour, par exemple, améliorer la convivialité de ce site Web, fournir une expérience personnalisée et collecter des statistiques d&#39;utilisation. Nous utilisons également des cookies de session pour améliorer la sécurité de ce site. | Notre intérêt légitime à (i) améliorer notre site Web, (ii) vous présenter des offres qui vous intéressent et (iii) disposer d&#39;un service et d&#39;un site Web sécurisés. Si vous ne souhaitez pas que des cookies soient stockés sur votre ordinateur, vous pouvez modifier les paramètres de votre navigateur à tout moment. | Selon le type de cookie. [Voir ci-dessous pour accéder aux informations détaillées concernant les périodes de stockage.](https://flystrait.com/privacy#Cookies) |

En plus de ce qui précède, nous prenons les mesures quotidiennes nécessaires aux entreprises fournissant des services aux consommateurs, telles que la comptabilité, la tenue des comptes, la facturation, le respect des obligations en matière de lutte contre le blanchiment d&#39;argent et le maintien de la sécurité de notre site Web. Dans la mesure où cela n&#39;est pas obligatoire en vertu des lois applicables, nous prenons ces mesures en fonction de notre intérêt légitime. Nous pouvons également analyser le comportement de nos clients afin d&#39;améliorer nos sites Web et nos services de manière générale. Cependant, cette analyse utilisera des données généralisées ou anonymisées à un niveau global.

# Partage de vos données personnelles

Nous ne partagerons vos données personnelles uniquement dans le cadre de l&#39;exécution des prestations énumérées dans la présente politique de confidentialité. Cela peut concerner d&#39;autres sociétés de Fly Strait, les autorités gouvernementales et nos partenaires de confiance. Par exemple, nous pouvons partager vos données personnelles (y compris, le cas échéant, des données personnelles sensibles) avec des partenaires commerciaux tels que des compagnies aériennes, des fournisseurs d&#39;hôtels, des compagnies d&#39;assurance et des systèmes de distribution mondiaux (GDS) afin de vous permettre d&#39;organiser votre voyage et vos réservations.

Chaque partenaire est responsable de sa propre gestion de vos données personnelles après l&#39;avoir reçue de notre part, ce qui signifie que vous devez contacter le partenaire en question pour toute demande liée à vos droits en vertu de la législation applicable en matière de protection des données. Nous vous recommandons de lire les politiques de confidentialité des partenaires respectifs pour obtenir des informations sur le traitement de vos données personnelles. Nous partagerons également vos données personnelles avec d&#39;autres sociétés (appelées «machine de traitement de données») nécessaires pour fournir les services que vous avez demandés, telles que les prestataires de services exploitant nos centres d&#39;appels et nos autres fournisseurs qui traitent vos données personnelles lorsqu&#39;ils nous fournissent leurs services (par exemple, stockage externe).

En raison de la nature mondiale de l&#39;industrie du voyage, vos données personnelles peuvent être traitées dans différents endroits du monde lorsque les parties avec lesquelles nous partageons vos données personnelles résident dans un pays en dehors de l&#39;UE / EEE. Notre partage de données à caractère personnel en dehors de l&#39;UE / EEE requiert un certain fondement juridique en vertu de la législation applicable en matière de protection des données. Si un pays est considéré par la Commission européenne comme un pays doté d&#39;un niveau de protection adéquat des données personnelles, cela constituera notre base légale. Autrement, il existe trois principaux types de fondement juridique sur lesquels nous pouvons baser un tel partage. En voici quelques uns :

i. que le transfert soit nécessaire à l&#39;exécution du contrat avec vous (par exemple, lorsque vous avez réservé un vol avec une compagnie aérienne domiciliée en dehors de l&#39;UE / EEE);

ii. que le transfert sera basé sur les clauses standard de protection des données pour le transfert de données à caractère personnel vers des pays hors UE / EEE adoptées par la Commission européenne (une copie de ces clauses standard de protection des données est disponible à l&#39;adresse http: //ec.europa .eu / justice / protection des données / transferts internationaux / transfert /);

iii. le bouclier de protection de la vie privée UE-États-Unis, où le transfert est effectué aux États-Unis et le destinataire est dûment certifié.

# Tiers prestataires

Veuillez noter que notre site Web contient des liens vers d&#39;autres sites Web et propose du contenu provenant de tiers prestataires. Cette politique de confidentialité s&#39;applique uniquement à notre site Web et à nos services. Lorsque vous suivez des liens vers d&#39;autres sites Web ou utilisez des services et produits tiers, vous devriez lire leurs politiques de confidentialité. En outre, si vous choisissez de nous contacter via les médias sociaux, la présente politique de confidentialité ne s&#39;applique pas aux données personnelles que vous avez fournies dans le cadre de ce contact. Dans ce cas, nous vous recommandons de lire la politique de confidentialité de ce fournisseur de médias sociaux.

# Vos droits

Selon la législation applicable en matière de protection des données, vous disposez de certains droits en tant que «personne concernée». Ci-dessous, voici une énumération de vos droits. Vos droits incluent ce qui suit:

i. Droit d&#39;accès - Vous avez le droit d&#39;accéder aux données personnelles que nous traitons. Vous avez également le droit de recevoir certaines informations sur ce que nous faisons avec les données personnelles. Ces informations sont fournies dans le présent document.

ii. Droit de rectification - Dans certaines circonstances, vous avez le droit de corriger des données à caractère personnel inexactes qui vous concernent et de compléter des données à caractère personnel. Veuillez noter que nous pourrions ne pas être en mesure de corriger les données personnelles inexactes fournies par vous en raison, par exemple, des règles des compagnies aériennes, et qu&#39;un tel changement particulier pourrait entraîner des coûts.

iii. Droit de suppression - Dans certaines circonstances, vous avez le droit de demander à ce que vos données personnelles soient supprimées. C&#39;est le soi-disant «droit à l&#39;oubli».

iv. Droit de restreindre le traitement - Dans certaines circonstances, vous avez le droit de restreindre la manière dont nous utilisons vos données personnelles.

v. Droit au transfert des données - Vous avez le droit de recevoir vos données personnelles (ou de les transmettre directement à un autre responsable du traitement) sous un format structuré, couramment utilisé et lisible par machine.

vi. Droit d&#39;opposition - Vous avez le droit de vous opposer à certains types de traitement de données à caractère personnel que nous effectuons. Ceci s&#39;applique à toutes nos activités qui reposent sur nos «intérêts légitimes». Vous avez également le droit de déposer une plainte auprès de l&#39;autorité de régulation de la protection des données compétente.

# Cookies

Un cookie est un petit fichier texte stocké sur votre ordinateur, certains jusqu&#39;à la fermeture de votre navigateur (appelés «cookies de session») et d&#39;autres pendant une période prolongée (appelés «cookies permanents»). Si vous ne souhaitez pas autoriser le stockage de cookies sur votre ordinateur, vous pouvez modifier les paramètres de votre navigateur. Notez toutefois que dans certains cas, certaines fonctionnalités de notre site Web risquent de ne pas fonctionner correctement et que certains contenus risquent de ne pas s&#39;afficher correctement.

Ce site Web utilise des cookies pour un certain nombre de raisons, notamment pour fournir une expérience personnalisée, pour améliorer la convivialité de ce site Web et pour collecter des statistiques d&#39;utilisation. Nous utilisons également des cookies de session pour améliorer la sécurité de ce site.

Dans certains cas, lors de l&#39;utilisation de cookies, nous partageons des données avec des tiers. Par exemple, nous utilisons Google Analytics et Google AdWords, des services qui transmettent des données de trafic de sites Web à des serveurs Google. Google Analytics n&#39;identifie pas les utilisateurs individuels et n&#39;associe pas votre adresse IP à d&#39;autres données détenues par Google. Nous utilisons les rapports fournis par Google pour nous aider à comprendre le trafic et l&#39;utilisation de pages Web et à optimiser les publicités achetées auprès de Google et d&#39;autres réseaux publicitaires. Google peut traiter les données de la manière décrite dans la [politique de confidentialité de Google](https://policies.google.com/privacy) et aux fins énoncées ci-dessus dans cette section. Vous pouvez désactiver Google Analytics si vous désactivez ou refusez le cookie, désactiver JavaScript ou utilisez le service de désactivation fourni par Google. [Pour désactiver les fonctionnalités publicitaires de Google, veuillez cliquer sur ce lien.](https://tools.google.com/dlpage/gaoptout)

Notre site Web utilise également le pixel Facebook, qui collecte des données sous forme agrégée et anonyme qui nous aident à optimiser les achats d&#39;annonces sur différentes plateformes Facebook (y compris Instagram). Facebook collecte un identifiant d&#39;utilisateur qui lui permettra de voir si un utilisateur a visité un site avec le pixel Facebook. En tant qu&#39;annonceurs, nous ne pouvons toutefois jamais identifier le comportement d&#39;un utilisateur spécifique. Facebook et ses plateformes associées font partie d&#39;un écosystème publicitaire fermé où leurs utilisateurs peuvent autoriser ou non les annonceurs à acheter des publicités sur Facebook en utilisant les données collectées sur leurs sites Web. [Pour afficher et modifier vos paramètres publicitaires sur Facebook, utilisez ce lien.](https://facebook.com/ads/preferences)

En outre, notre site Web utilise des scripts de suivi des conversions et des cookies de [Microsoft Bing.](https://privacy.microsoft.com/), [TripAdvisor](https://www.smartertravel.com/privacy-policy) et[TvSquared](https://tvsquared.com/privacy-policy/)(vous pouvez consulter leurs politiques de confidentialité en suivant les liens).

Tous ces services collectent des données statistiques agrégées qui nous aident à optimiser les achats d&#39;annonces. En tant qu&#39;annonceurs, nous ne pouvons pas identifier d&#39;utilisateur unique grâce à ces données. Vous pouvez désactiver l&#39;utilisation de cookies dans les paramètres de votre navigateur.

[Pour plus d&#39;informations sur les cookies, visitez le site www.youronlinechoices.com.](https://www.youronlinechoices.com/)

# Sécurité des données

Afin de sécuriser vos données personnelles, nous avons mis en place un certain nombre de mesures de sécurité techniques et organisationnelles. Par exemple, nous maintenons des niveaux de sécurité technique élevés dans tous les systèmes (traçabilité, reprise après sinistre, limitations d&#39;accès, etc.). En outre, nous avons adopté des politiques visant à garantir que nos employés (qui sont bien sûr soumis à des obligations de confidentialité) n&#39;utilisent pas de données à caractère personnel lorsque cela n&#39;est pas nécessaire. Ces politiques définissent également nos normes en matière de sous-traitance ou d&#39;introduction de nouveaux systèmes informatiques au sein de nos opérations.

# Nous contacter

Si vous avez des questions concernant le traitement de vos données personnelles ou l&#39;utilisation de cookies, ou si vous souhaitez faire valoir l&#39;un de vos droits en vertu de la législation applicable sur la protection des données, veuillez envoyer un courrier électronique à: privacy@flystrait.com

# Changements de notre politique de confidentialité

Si nous modifions la manière dont nous traitons vos données personnelles ou dont nous utilisons les cookies, nous mettrons à jour cette politique de confidentialité et la publierons sur ce site.
`;
