import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import getTranslation from 'utils/getTranslation';
import Modal from 'components/common/Modal';
import PenaltyCard from './Partials/PenaltyCard';
import { smartResultsQuery } from 'components/FlightSegment/queries';
import { ModalSelectedTitle } from 'components/common/CommonStyles';
import { getItem, setItem } from 'utils/utils';

const Wrapper = styled.div`
  width: 744px;
  background-color: #EEEEEE;
  padding: 10px 25px;
  @media (max-width: 800px) {
    width: 100%;
    padding: 10px 5px;
  }
`;

const Content = styled.div`
  .title {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 12px;
    text-align: center;
    text-transform: uppercase;
    color: #444444;
    margin-bottom: 20px;
  }
  .sameFlightWrapper {
    display: flex;
    justify-content: center;
  }
  .grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

class DateAndCancellationModal extends PureComponent {
  state = {
    title: getTranslation('dateAndCancel'),
    showChecked: false,
    loading: true,
    otherFlights: []
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.open !== this.props.open && nextProps.open) {
      const { hopsegmentcontrolId, smartValue } = nextProps;
      this.setState({
        selectedPriceNum: null,
        seatClass: null,
        title: getTranslation('dateAndCancel'),
      });
      this.handleResults(hopsegmentcontrolId, smartValue);
    }
  }
  handleClose = () => {
    this.props.handleCloseModal();
  };

  handleResults = async (hopsegmentcontrolId, smartValue) => {
    this.setState({ loading: true });
    try {
      const result = await this.props.client.query({
        query: smartResultsQuery,
        fetchPolicy: 'no-cache',
        variables: { hopsegmentcontrolId: hopsegmentcontrolId, key: smartValue }
      });
      const { smartResults } = result.data;
      const sameFlight = smartResults.find(item => item.flightsegmentMatch);
      const otherFlights = smartResults.filter(item => !item.flightsegmentMatch);
      const noResult = this.handleNoResult(sameFlight, otherFlights);
      this.setState({
        loading: false,
        sameFlight,
        otherFlights,
        noResult
      });
    } catch (err) {
      console.log(err);
      const noResult = this.handleNoResult(null, []);
      this.setState({ loading: false, sameFlight: null, otherFlights: [], noResult });
    }
  }

  handleNoResult = (sameFlight, otherFlights) => {
    const noResult = !sameFlight && otherFlights.length === 0;
    let disabledDateAndCancellation = getItem('disabledDateAndCancellation') || [];
    if (noResult && this.props.open) {
      disabledDateAndCancellation = [...disabledDateAndCancellation, this.props.priceNum];
      setItem('disabledDateAndCancellation', disabledDateAndCancellation);
    }
    return noResult;
  }

  handleSelect = (details, cost) => {
    const sameSelection = details.priceNum === this.state.selectedPriceNum;
    const title = sameSelection ? getTranslation('dateAndCancel') : cost;
    const hopRequest = details.hopRequests.find(item => item.hopSeqNum === this.props.hopSeqNum);
    this.setState({
      selectedPriceNum: sameSelection ? null : details.priceNum,
      dateChangePenalty: hopRequest.hopSegmentControls[0].dateChangePenalty,
      title
    });
  }

  handleCheckClick = () => {
    const { dateChangePenalty, selectedPriceNum } = this.state;
    if (selectedPriceNum) {
      this.props.handleCloseModal(selectedPriceNum, dateChangePenalty);
    } else {
      this.props.handleCloseModal();
    }
  }

  render() {
    const {
      title,
      loading,
      sameFlight,
      otherFlights,
      selectedPriceNum,
      noResult
    } = this.state;
    const {
      open,
      handleCloseModal,
      hopSeqNum,
      hopRequests
    } = this.props;
    const sameFlightDetails = hopRequests.find(item => item.hopSeqNum === hopSeqNum);
    return (
      <Modal
        open={open}
        title={!!selectedPriceNum ? (<ModalSelectedTitle>
          <span>{getTranslation('changeClass')}</span>
          <span className="price">{title}</span>
        </ModalSelectedTitle>) : (<span>{title}</span>)}
        handleCloseModal={handleCloseModal}
        handleCheckClick={this.handleCheckClick}
        showChecked={!!selectedPriceNum}
        titleHtml
        dialogStyle={{ width: !isMobile ? 744 : 376, maxWidth: 744 }}
        titleStyle={{ padding: '8px 8px 8px 24px' }}
      >
        <Wrapper>
          <Content>
            {(!loading && noResult) && <div style={{ textAlign: 'center' }}>
              {getTranslation('noResult')}
            </div>}
            <React.Fragment>
              <div className="title">{getTranslation('sameFlight')}</div>
              <div className="sameFlightWrapper">
                {(!loading && sameFlight) ? <PenaltyCard
                  details={sameFlight}
                  hopSeqNum={hopSeqNum}
                  selected={selectedPriceNum === sameFlight.priceNum}
                  selectedPriceNum={selectedPriceNum}
                  handleSelect={this.handleSelect}
                /> : <PenaltyCard
                  details={sameFlightDetails}
                  hopSeqNum={hopSeqNum}
                  otherFlight={otherFlights.length > 0 ? otherFlights[0] : null}
                  dummy
                  loading={loading}
                />}
              </div>
            {(!loading && otherFlights.length > 0) && <React.Fragment>
              <div className="title">{getTranslation('otherFlights')}</div>
              <div className="grid">
              {otherFlights.map(flight => (
                <PenaltyCard
                  key={flight.priceNum}
                  details={flight}
                  hopSeqNum={hopSeqNum}
                  selected={selectedPriceNum === flight.priceNum}
                  handleSelect={this.handleSelect}
                />
              ))}
              </div>
            </React.Fragment>}
          </React.Fragment>
          </Content>
        </Wrapper>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  hopRequests: state.flightResults.hopRequests,
});

export default connect(mapStateToProps)(DateAndCancellationModal);
