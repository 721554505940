import React, { Component, Fragment } from "react";
import { graphql, compose } from "react-apollo";
import gql from "graphql-tag";
import styled, { withTheme } from "styled-components";
import { connect } from "react-redux";
import { isMobile } from "react-device-detect";
import moment from "moment";
import {
  addPassengerToAllHopRequests,
  allPassengersAdded,
  handleAsyncUpdateFareRule,
  disablePassengerSticky,
  handlePassengerEdit,
  handleRemovePassengerIds,
} from "redux-store/actions";
import {
  DateInput,
  Toast,
  Input,
  Autocomplete,
  Button,
  PassengerHeader,
} from "components/common";
import { getItem, setItem } from "utils/utils";
import getTranslation from "utils/getTranslation";
import countries from "utils/countries";
import countryList from "utils/countryList";
import { addTripPassengerQuery, updPassengerQuery } from "./PaymentQuery";
import PrevPassengers from "./PrevPassengers";
import MealPreference from "./childs/MealPreference";
import BaggageSelect from "./childs/BaggageSelect";
import TitleSelect from "./childs/TitleSelect";
import GenderSelect from "./childs/GenderSelect";
import { Checkbox } from "@material-ui/core";

const Optional = styled.div`
  color: #000;
  font-size: 12px;
  user-select: none;
  .toggle {
    cursor: pointer;
    text-align: center;
    margin-top: 20px;
    span {
      color: ${(props) => props.theme.secondary};
      font-size: 16px;
      text-decoration: underline;
    }
  }
  .first-two-letters {
    display: flex;
    flex-flow: column;
  }
`;
const CheckBoxContainer = styled.div`
  display: flex;
  font-size: 12px;
  align-items: center;
  color: #222222;
`;

let defaultCountry = "";
const selectedCountry = getItem("selectedCountry") || "gb";
if (selectedCountry.toLowerCase() !== "xx") {
  defaultCountry =
    countryList.find((item) => item.Code === selectedCountry.toUpperCase())
      .en || "";
}
const defaultState = {
  formData: {
    firstName: "",
    lastName: "",
    dateOfBirth: undefined,
    gender: "",
    title: "",
    extrabaggageIds: undefined,
    mealCode: "",
    ffNumber: "",
    passportNumber: "",
    passportExpdate: undefined,
    passportCountry: "",
    nationality: "",
    cardHoldername: "",
    cardHoldercountry: defaultCountry,
    cardHoldercountryic: selectedCountry === "XX" ? "" : selectedCountry,
  },
  samePassCountryNationality: true,
  isFormCompleted: false,
  isLoading: false,
  showOptional: false,
  searchText: "",
  searchTextCountry: defaultCountry,
  showErrors: false,
  showDobError: false,
  showExpiryError: false,
};

class PassengerForm extends Component {
  constructor(props) {
    super(props);
    const passengerCount = this.getTotalPassengerCount(props.hopRequests);
    const maxNameLimit = this.getMinCharCount(props.hopRequests);
    this.state = {
      ...defaultState,
      error: false,
      nameError: false,
      passengerCount,
      cardHolderAdded: false,
      maxNameLimit,
      passengersAddedCount:
        props.hopRequests.length > 0
          ? props.hopRequests[0].passengers.length
          : 0,
      countriesList: [],
      minYears: 12,
      maxYears: 200,
    };
  }

  componentWillMount() {
    const buttonText = this.getButtonText(this.props.hopRequests);
    this.setState({
      buttonText,
    });
  }

  componentWillReceiveProps(newProps) {
    if (newProps.hopRequests !== this.props.hopRequests) {
      let cardHolderAdded = false;
      const passengerCount = this.getTotalPassengerCount(newProps.hopRequests);
      const maxNameLimit = this.getMinCharCount(newProps.hopRequests);
      if (newProps.hopRequests.length > 0) {
        cardHolderAdded = newProps.hopRequests[0].passengers.find(
          (passenger) => passenger.cardHolder === true
        );
      }
      this.setState(
        {
          passengerCount,
          maxNameLimit,
          passengersAddedCount:
            newProps.hopRequests.length > 0
              ? newProps.hopRequests[0].passengers.length
              : 0,
          cardHolderAdded: !!cardHolderAdded,
        },
        () => {
          const buttonText = this.getButtonText(newProps.hopRequests);
          this.setState({ buttonText });
          if (newProps.hopRequests[0].passengers.length === this.props.passengerCount) {
            this.props.handlePassengersDone();
          }
        }
      );
    }
    if (
      newProps.editPassenger !== this.props.editPassenger &&
      newProps.editPassenger !== null
    ) {
      const buttonText = this.getButtonText(newProps.hopRequests);
      let cardHoldercountry;
      if (newProps.editPassenger.cardHolder) {
        console.log(newProps.editPassenger.cardHoldercountryic);
        cardHoldercountry = countryList.find(
          (item) => item.Code === newProps.editPassenger.cardHoldercountryic
        );
      }
      this.setState(
        {
          formData: {
            ...newProps.editPassenger,
            dateOfBirth: newProps.editPassenger.birthdate,
            cardHoldercountryic: cardHoldercountry
              ? cardHoldercountry.Code
              : "",
            cardHoldercountry: cardHoldercountry ? cardHoldercountry.en : "",
          },
          cardHolderAdded: !newProps.editPassenger.cardHolder,
          buttonText,
        },
        () => {
          console.log(this.state);
        }
      );
    }
  }

  componentDidUpdate() {
    const form = this.state.formData;
    let adpasiFieldsValid = true;
    let passportFieldsValid = true;
    let cardHolderFieldsValid = true;
    if (this.props.passportDetails) {
      passportFieldsValid =
        form.passportNumber && form.passportExpdate && form.passportCountry;
    }
    if (this.props.adpasi) {
      adpasiFieldsValid = form.nationality;
    }
    if (!this.state.cardHolderAdded) {
      cardHolderFieldsValid = form.cardHoldername && form.cardHoldercountryic;
    }
    const isFormCompleted =
      form.firstName &&
      form.lastName &&
      form.gender &&
      form.title &&
      form.dateOfBirth &&
      adpasiFieldsValid &&
      passportFieldsValid &&
      cardHolderFieldsValid;
    if (this.state.isFormCompleted !== isFormCompleted)
      this.setState({ isFormCompleted });
  }

  componentWillUnmount() {
    this.props.handlePassengerEdit(null);
  }

  onCountrySelect = (index, dataSource) => {
    if (index === -1) return;
    const country = dataSource[index];
    if (country) {
      this.setState({
        formData: {
          ...this.state.formData,
          passportCountry: country,
          // cardHoldercountry: country,
        },
        searchTextCountry: country,
        countriesList: [],
      });
    }
  };

  onNationalitySelect = (index, dataSource) => {
    if (index === -1) return;
    const country = dataSource[index];
    if (country) {
      this.setState({
        formData: {
          ...this.state.formData,
          nationality: country,
          passportCountry: this.state.samePassCountryNationality
            ? country
            : this.state.passportCountry,
          // cardHoldercountry: country,
        },
        searchTextCountry: country,
        countriesList: [],
      });
    }
  };

  getTotalPassengerCount = (hopRequests) => {
    let sum = 0;
    if (Array.isArray(hopRequests) && hopRequests.length > 0) {
      const array = [hopRequests[0]];
      sum = array.reduce(
        (accumulator, currentVal) =>
          accumulator +
          currentVal.adultCount +
          currentVal.childCount +
          currentVal.infantCount,
        0
      );
    }
    return sum;
  };

  getMinCharCount = (hopRequests) => {
    let min = 100000;
    if (Array.isArray(hopRequests) && hopRequests.length > 0) {
      const array = [hopRequests[0].hopSegmentControls[0]];
      array.forEach((item) => {
        if (item.paxnameCharLimit < min) {
          min = item.paxnameCharLimit;
        }
      });
    }
    return min;
  };

  getButtonText = (hopRequests) => {
    if (hopRequests.length > 0) {
      const { adultCount, childCount, infantCount } = hopRequests[0];
      const { cardHolderAdded, passengersAddedCount } = this.state;
      if (passengersAddedCount < adultCount) {
        this.setState({
          minYears: 12,
          maxYears: 200,
        });
        return `${getTranslation("addPassenger")} ${passengersAddedCount + 1}`;
      }
      if (passengersAddedCount < infantCount + adultCount) {
        this.setState({
          minYears: 0,
          maxYears: 2,
        });
        return `${getTranslation("addInfantPassenger")} ${passengersAddedCount - adultCount + 1
          }`;
      }
      if (passengersAddedCount < childCount + infantCount + adultCount) {
        this.setState({
          minYears: 2,
          maxYears: 12,
        });
        return `${getTranslation("addChildPassenger")} ${passengersAddedCount - (adultCount + infantCount) + 1
          }`;
      }
    }
    return getTranslation("addPassenger");
  };

  handleChange = (e) => {
    const { name } = e.target;
    this.setState(
      {
        formData: {
          ...this.state.formData,
          [name]: e.target.value,
        },
      },
      () => {
        const { firstName, lastName } = this.state.formData;
        if (
          firstName &&
          lastName &&
          (name === "firstName" || name === "lastName")
        ) {
          this.setState({
            formData: {
              ...this.state.formData,
              cardHoldername: `${firstName} ${lastName}`,
            },
          });
        }
      }
    );
  };

  handleFfNumChange = (value) => {
    this.setState({
      formData: {
        ...this.state.formData,
        ffNumber: value,
      },
    });
  };

  handleMealchange = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        mealCode: e.target.value,
      },
    });
  };

  handlePassengerSelect = (passenger) => {
    if (!getItem("extraCheckDone")) {
      this.props.handleShowExtraCheckError();
      return;
    }
    this.setState(
      {
        formData: {
          ...this.state.formData,
          firstName: passenger.firstName,
          lastName: passenger.lastName,
          gender: passenger.gender,
          title: passenger.title,
          dateOfBirth: passenger.birthdate,
          nationality: passenger.nationality,
          passportCountry: passenger.passportCountry,
          passportExpdate: passenger.passportExpdate,
          passportNumber: passenger.passportNumber,
          cardHoldername: `${passenger.firstName} ${passenger.lastName}`,
          cardHoldercountryic: passenger.cardHoldercountryic,
        },
      },
      () => {
        this.props.disablePassengerSticky();
        this.addPassenger("", true);
      }
    );
  };

  addPassenger = (e, isPrevPassenger) => {
    if (!this.state.isFormCompleted) {
      this.setState({
        showErrors: true,
      });
      return;
    }
    this.setState({ isLoading: true });
    const form = this.state.formData;
    const formattedDate = moment(form.dateOfBirth).format("YYYY-MM-DD");
    const adpasiFields = {};
    const cardHolderFields = {};
    if (this.props.adpasi) {
      adpasiFields.nationality = form.nationality;
      adpasiFields.passportCountry = form.passportCountry;
      adpasiFields.passportExpdate = moment(form.passportExpdate).format(
        "YYYY-MM-DD"
      );
      adpasiFields.passportNumber = form.passportNumber;
    }
    if (!this.state.cardHolderAdded) {
      cardHolderFields.cardHoldername = form.cardHoldername;
      cardHolderFields.cardHoldercountryic = form.cardHoldercountryic.toUpperCase();
    }
    const { editPassenger } = this.props;
    const payload = {
      passengerId: editPassenger ? editPassenger.id : undefined,
      firstName: form.firstName,
      lastName: form.lastName,
      birthdate: isPrevPassenger ? form.dateOfBirth : formattedDate,
      gender: form.gender,
      cardHolder: !this.state.cardHolderAdded,
      ffNumber: form.ffNumber,
      mealCode: form.mealCode,
      title: form.title,
      tripId: getItem("tripId"),
      extrabaggageIds: form.extrabaggageIds,
      extraBaggages: form.extrabaggageIds
        ? form.extrabaggageIds.map((id) => ({
          id,
        }))
        : undefined,
      ...adpasiFields,
      ...cardHolderFields,
    };
    const passengers = getItem("passengers") ? getItem("passengers") : [];
    this.handleAddPassenger(payload, passengers);
  };

  handleAddPassenger = (variables, passengers) => {
    const { editPassenger } = this.props;
    if (!editPassenger) {
      this.props
        .addTrippassenger({
          variables,
        })
        .then((data) => {
          const passenger = data.data.addTrippassenger[0];
          this.props.addPassengerToAllHopRequests(passenger);
          this.props.handlePassengerEdit(null);
          setItem("passengers", [...passengers, passenger]);
          this.resetState();
        })
        .catch((err) => {
          this.setState({
            isLoading: false,
            error: true,
            errorMsg: getTranslation("addPassengerError"),
          });
        });
    } else {
      this.handleEditPassenger(variables, passengers);
    }
  };

  handleEditPassenger = (variables, passengers) => {
    console.log(variables);
    this.props.passengerIds.forEach((passengerId, index) => {
      this.props
        .updPassenger({
          variables: { ...variables, passengerId },
        })
        .then((data) => {
          if (this.props.passengerIds.length === index + 1) {
            const passenger = data.data.updPassenger;
            this.props.addPassengerToAllHopRequests(passenger);
            this.props.handlePassengerEdit(null);
            this.props.handleRemovePassengerIds();
            setItem("passengers", [...passengers, passenger]);
            this.resetState();
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            isLoading: false,
            error: true,
            errorMsg: getTranslation("addPassengerError"),
          });
        });
    });
  };

  resetState() {
    if (document.getElementById("passengerInput")) {
      document.getElementById("passengerInput").focus();
    }
    const buttonText = this.getButtonText(this.props.hopRequests);
    this.setState({
      ...defaultState,
      buttonText,
      cardHolderAdded: true,
    });
  }

  getDobInvalidText = (maxYears) => {
    let passengerType, ageLimit;
    if (maxYears <= 2) {
      passengerType = getTranslation("infant");
      ageLimit = getTranslation("infantLimit");
    } else {
      passengerType = getTranslation("child");
      ageLimit = getTranslation("childLimit");
    }
    const text = getTranslation("minorDobInvalid")
      .replace("[type]", passengerType)
      .replace("[limit]", ageLimit);
    return text;
  };

  handleDateSelect = (val) => {
    if (val instanceof Date) {
      const { minYears, maxYears } = this.state;
      const { hopRequests } = this.props;
      const { staDate, staTime } = hopRequests[
        hopRequests.length - 1
      ].hopSegmentControls[0].flightSegments[0];
      const lastFlightDate = moment(`${staDate} ${staTime}`);
      const minDate = moment(lastFlightDate).subtract(minYears, "years");
      const maxDate = moment(lastFlightDate).subtract(maxYears, "years");
      const afterValid = moment(val).isBefore(minDate);
      const beforeValid = moment(val).isAfter(maxDate);
      if (afterValid && beforeValid) {
        this.setState({
          formData: { ...this.state.formData, dateOfBirth: val },
          showDobError: false,
        });
      } else {
        this.setState({
          error: true,
          errorMsg:
            minYears < 12
              ? this.getDobInvalidText(maxYears)
              : getTranslation("enterValidDob"),
          formData: {
            ...this.state.formData,
            dateOfBirth: undefined,
          },
          showDobError: true,
        });
      }
    }
  };

  handleExpDateChange = (date) => {
    if (date instanceof Date) {
      const minDate = getItem("minPassportExpiry") || moment().unix();
      if (moment(date).isAfter(moment(minDate * 1000))) {
        this.setState({
          formData: { ...this.state.formData, passportExpdate: date },
          showExpiryError: false,
        });
      } else {
        this.setState({
          error: true,
          errorMsg: getTranslation("enterValidPassportExpiry"),
          formData: { ...this.state.formData, passportExpdate: undefined },
          showExpiryError: true,
        });
      }
    }
  };

  handleBaggageChange = (data) => {
    console.log(data);
    this.setState({
      formData: { ...this.state.formData, extrabaggageIds: data },
    });
  };

  handleToastClose = () => {
    this.setState({
      error: false,
      nameError: false,
    });
  };

  handleGenderChange = (e) => {
    const gender = e.target.value;
    if (gender !== this.state.formData.gender) {
      this.setState({
        formData: {
          ...this.state.formData,
          gender: e.target.value,
          title: null,
        },
      });
    }
  };

  handleTitleChange = (title, gender) => {
    this.setState({ formData: { ...this.state.formData, title, gender } });
  };

  handleCountryInputChange = (txt) => {
    this.setState(
      {
        searchText: txt,
        formData: { ...this.state.formData, passportCountry: null },
      },
      () => {
        this.handleCountryFilter(txt.toLowerCase());
      }
    );
  };

  handleNationalityInputChange = (txt) => {
    this.setState(
      {
        searchText: txt,
        formData: { ...this.state.formData, nationality: null },
      },
      () => {
        this.handleCountryFilter(txt.toLowerCase());
      }
    );
  };

  handleCountryFilter = (txt) => {
    const countriesList = countries.filter((country) =>
      country.toLowerCase().startsWith(txt)
    );
    const list = countriesList.slice(0, 5);
    this.setState({
      countriesList: list,
    });
  };

  handleBlurCheck = (text, dataSource) => {
    const country = dataSource.find(
      (item) => item.toLowerCase() === text.toLowerCase()
    );
    if (country)
      this.setState({
        formData: {
          ...this.state.formData,
          passportCountry: country,
          cardHoldercountry: country,
        },
        searchTextCountry: country,
        searchText: country,
      });
  };

  handleCountryClear = () => {
    this.setState(
      {
        searchText: "",
      },
      () => {
        this._countryInput.focus();
      }
    );
  };

  handleCardHolderCountryClear = () => {
    this.setState(
      {
        searchTextCountry: "",
      },
      () => {
        this._cardHolderCountryInput.focus();
      }
    );
  };

  handleAutocompleteClose = () => {
    this.setState({ countriesList: [] });
  };

  handleBlur = (dataSource) => {
    if (dataSource) {
      const { searchText } = this.state;
      const isSame = searchText.toLowerCase() === dataSource[0].toLowerCase();
      if (isSame) {
        this.setState(
          {
            searchText: isSame ? dataSource[0] : searchText,
            formData: {
              ...this.state.formData,
              passportCountry: isSame
                ? dataSource[0]
                : this.state.formData.passportCountry,
            },
          },
          () => {
            console.log(this.state);
          }
        );
      }
    }
  };

  handleBlurCountry = (dataSource) => {
    console.log("handleBlurCountry");
    if (dataSource) {
      const { searchTextCountry } = this.state;
      const isSame =
        searchTextCountry.toLowerCase() === dataSource[0].toLowerCase();
      if (isSame) {
        const cardHoldercountry = isSame
          ? dataSource[0]
          : this.state.formData.cardHoldercountry;
        const countryIc = countryList.find(
          (item) => item.en === cardHoldercountry
        );
        this.setState(
          {
            searchTextCountry: isSame ? dataSource[0] : searchTextCountry,
            formData: {
              ...this.state.formData,
              cardHoldercountry: cardHoldercountry,
              cardHoldercountryic: countryIc ? countryIc.Code : "",
            },
          },
          () => {
            console.log(this.state);
          }
        );
      }
    }
  };

  handleUpdateFareRule = () => {
    if (!getItem("doAsyncFareRule")) {
      this.props.handleAsyncUpdateFareRule();
    }
    if (!isMobile) {
      window.scrollTo(0, 0);
    }
    setTimeout(() => {
      if (!isMobile) {
        window.scrollTo(window.scrollX, window.scrollY);
      } else {
        document.getElementById("leftSection").scrollTo(0, 0);
      }
    }, 200);
    this.props.disablePassengerSticky();
    if (!getItem("extraCheckDone")) {
      this.props.handleShowExtraCheckError();
    }
  };

  handleToggleOptional = () => {
    this.setState({
      showOptional: !this.state.showOptional,
    });
  };

  handlePassportCountryBlurCheck = (text, dataSource) => {
    const countryIndex = dataSource.findIndex(
      (item) => item.toLowerCase() === text.toLowerCase()
    );
    if (countryIndex > -1) {
      this.onCountrySelect(countryIndex, dataSource);
      this.setState({ searchText: dataSource[countryIndex] });
    }
  };

  handleInputFocus = () => {
    if (isMobile) {
      document.querySelector(".MuiDialogContent-root").scrollBy(0, 80);
    }
  };

  handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      this.addPassenger();
    }
  };

  handleSamePassCountryNationalityChange = (e, checked) => {
    const passportCountry = checked ? this.state.formData.nationality : "";
    this.setState({
      samePassCountryNationality: checked,
      formData: { ...this.state.formData, passportCountry },
    });
  };

  query = gql`
    query getCountries {
      xcountryByPattern(pattern: "New%")
    }
  `;

  render() {
    const {
      error,
      passengerCount,
      passengersAddedCount,
      formData,
      nameError,
      maxNameLimit,
      showOptional,
      searchText,
      errorMsg,
      countriesList,
      isLoading,
      buttonText,
      showErrors,
      showExpiryError,
      showDobError,
    } = this.state;
    const {
      hopRequests,
      prevPassengers,
      adpasiFound,
      adpasi,
      disabled,
      passportDetails,
      hopRequestsUpdateTime,
      handleClosePassenger,
      theme,
    } = this.props;
    // console.log("passAdd passCount" + passengersAddedCount + " " + passengerCount);
    this.props.allPassengersAdded(passengersAddedCount === passengerCount && passengerCount != 0);
    this.props.handleShowPayment(passengersAddedCount === passengerCount && passengerCount != 0);
    return (
      <Fragment>
        {passengersAddedCount === 0 ||
          passengersAddedCount !== passengerCount ? (
            <form autoComplete="off">
              <PassengerHeader handleClose={handleClosePassenger} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                  backgroundColor: !isMobile ? "#fff" : "transparent",
                  padding: !isMobile ? "10px 30px" : 0,
                }}
              >
                {!getItem("guestLogin") && prevPassengers.length > 0 && (
                  <PrevPassengers
                    passengers={prevPassengers}
                    handleSelect={this.handlePassengerSelect}
                    currentPassengers={
                      hopRequests.length > 0 ? hopRequests[0].passengers : []
                    }
                  />
                )}
                <div className="__pbs-input-container">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <TitleSelect
                      value={formData.title}
                      selectedGender={formData.gender}
                      handleChange={this.handleTitleChange}
                      error={showErrors && !formData.title}
                      autoFocus={true}
                    />
                    <GenderSelect
                      value={formData.gender}
                      handleChange={this.handleGenderChange}
                      error={showErrors && !formData.gender}
                    />
                  </div>
                  <Input
                    label={getTranslation("firstname")}
                    fullWidth
                    errorState={showErrors && !formData.firstName}
                    value={formData.firstName}
                    name="firstName"
                    onChange={this.handleChange}
                    marginRight={10}
                    onFocus={this.handleUpdateFareRule}
                  />
                  <Input
                    label={getTranslation("lastname")}
                    fullWidth
                    errorState={showErrors && !formData.lastName}
                    value={formData.lastName}
                    name="lastName"
                    onChange={this.handleChange}
                    hideClear
                  />
                  <DateInput
                    label={getTranslation("birthDate")}
                    onChange={this.handleDateSelect}
                    value={formData.dateOfBirth}
                    image="/assets/input-icons/dob.png"
                    usExample="31-12-2019"
                    example="12-31-2019"
                    onKeyDown={
                      !passportDetails && !adpasi ? this.handleKeyDown : null
                    }
                    errorState={
                      (showErrors && !formData.dateOfBirth) || showDobError
                    }
                    handleFocus={this.handleInputFocus}
                  />
                  {adpasi && (
                    <React.Fragment>
                      <Autocomplete
                        id="nationality"
                        label={getTranslation("nationality")}
                        value={formData.nationality}
                        searchText={searchText}
                        type="country"
                        onChange={this.handleNationalityInputChange}
                        handleSelect={this.onNationalitySelect}
                        handleClose={this.handleAutocompleteClose}
                        onBlur={this.handleBlurCheck}
                        dataSource={countriesList}
                        error={showErrors && !formData.nationality}
                      />
                      {passportDetails && (
                        <CheckBoxContainer>
                          <Checkbox
                            checked={this.state.samePassCountryNationality}
                            color="secondary"
                            onChange={this.handleSamePassCountryNationalityChange}
                          />
                          <div>{getTranslation("sameaspasscountry")}</div>
                        </CheckBoxContainer>
                      )}
                    </React.Fragment>
                    // <Input
                    //   label={getTranslation("nationality")}
                    //   fullWidth
                    //   errorState={showErrors && !formData.nationality}
                    //   value={formData.nationality}
                    //   name="nationality"
                    //   onChange={this.handleChange}
                    //   image="/assets/input-icons/nationality.svg"
                    //   handleFocus={this.handleInputFocus}
                    // />
                  )}
                  {passportDetails && (
                    <React.Fragment>
                      {!this.state.samePassCountryNationality && (
                        <Autocomplete
                          id="passportCountry"
                          label={getTranslation("passportCountry")}
                          value={formData.passportCountry}
                          searchText={searchText}
                          type="country"
                          onChange={this.handleCountryInputChange}
                          handleSelect={this.onCountrySelect}
                          handleClose={this.handleAutocompleteClose}
                          onBlur={this.handleBlurCheck}
                          dataSource={countriesList}
                          error={showErrors && !formData.passportCountry}
                        />
                      )}
                      <DateInput
                        label={getTranslation("exp")}
                        onChange={this.handleExpDateChange}
                        value={formData.passportExpdate}
                        noMargin
                        image="/assets/input-icons/expirydate.svg"
                        usExample="31-12-2018"
                        example="12-31-2018"
                        errorState={
                          (showErrors && !formData.passportExpdate) ||
                          showExpiryError
                        }
                        handleFocus={this.handleInputFocus}
                      />
                      <Input
                        type="text"
                        label={getTranslation("passportNumber")}
                        fullWidth
                        errorState={showErrors && !formData.passportNumber}
                        value={formData.passportNumber}
                        name="passportNumber"
                        onChange={this.handleChange}
                        onKeyDown={this.handleKeyDown}
                        image="/assets/input-icons/passport.svg"
                        imgHeight={14}
                        handleFocus={this.handleInputFocus}
                      />
                    </React.Fragment>
                  )}
                  <BaggageSelect
                    passengerCount={passengerCount}
                    hopRequests={hopRequests}
                    hopRequestsUpdateTime={hopRequestsUpdateTime}
                    handleChange={this.handleBaggageChange}
                    value={formData.extrabaggageIds}
                  />
                </div>
                <Optional>
                  {!showOptional && (
                    <div className="toggle">
                      <span onClick={this.handleToggleOptional}>
                        {getTranslation("moreDetails")}
                      </span>
                    </div>
                  )}
                  {showOptional && (
                    <React.Fragment>
                      <div className="first-two-letters">
                        <Input
                          label={`${getTranslation(
                            "frequentFlyerNum"
                          )} (${getTranslation("optional")})`}
                          fullWidth
                          value={formData.ffNumber}
                          name="ffNumber"
                          onChange={this.handleChange}
                          handleFocus={this.handleInputFocus}
                        />
                        {!disabled && (
                          <MealPreference
                            value={formData.mealCode}
                            handleChange={this.handleMealchange}
                          />
                        )}
                      </div>
                    </React.Fragment>
                  )}
                </Optional>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    disabled={!adpasiFound || isLoading}
                    style={{ margin: "20px 0" }}
                    color={theme.primary}
                    onClick={this.addPassenger}
                  >
                    {buttonText}
                  </Button>
                </div>
              </div>
            </form>
          ) : null}
        <Toast
          open={nameError}
          message={getTranslation("passengerNameLimitError").replace(
            "{{char}}",
            maxNameLimit
          )}
          handleClose={this.handleToastClose}
        />
        <Toast
          open={error}
          message={errorMsg}
          handleClose={this.handleToastClose}
        />
      </Fragment>
    );
  }
}

const mapDispatchToProps = {
  addPassengerToAllHopRequests,
  allPassengersAdded,
  handleAsyncUpdateFareRule,
  disablePassengerSticky,
  handlePassengerEdit,
  handleRemovePassengerIds,
};

const mapStateToProps = (state) => ({
  hopRequests: state.flightResults.hopRequests,
  hopRequestsUpdateTime: state.flightResults.hopRequestsUpdateTime,
  prevPassengers: state.flightResults.prevPassengers,
  editPassenger: state.passengers.editPassenger,
  passengerIds: state.passengers.passengerIds,
});

export default withTheme(
  compose(
    graphql(addTripPassengerQuery, { name: "addTrippassenger" }),
    graphql(updPassengerQuery, { name: "updPassenger" }),
    connect(mapStateToProps, mapDispatchToProps)
  )(PassengerForm)
);
