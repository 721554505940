import React from "react";
import styled from "styled-components";
import { transportModes } from "utils/utils";
import getTranslation from "utils/getTranslation";
import checked from "assets/tip/Checked-circle.svg";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  .item {
    position: relative;
    margin-right: 24px;
    border: 2px solid #56ccf2;
    border-radius: 4px;
    width: 72px;
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    cursor: pointer;
    img {
      display: none;
      position: absolute;
      top: 0px;
      right: 0px;
      height: 13px;
    }
    span {
      font-family: "Roboto Condensed", sans-serif;
      text-transform: uppercase;
      color: #007ac7;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.5;
    }
    &.active {
      background-color: #d0f3fc;
      img {
        display: block;
      }
      span {
        color: #444444;
      }
    }
    &:last-child {
      margin-right: 0;
    }
  }
`;

export default class extends React.PureComponent {
  render() {
    const { transportMode, handleTransportSelect } = this.props;
    return (
      <Wrapper>
        {transportModes.map(item => (
          <div
            key={item.value}
            className={`item ${transportMode === item.value ? "active" : ""}`}
            onClick={() => handleTransportSelect(item.value)}
          >
            <img src={checked} alt="" />
            <item.icon />
            <span>{getTranslation(item.label)}</span>
          </div>
        ))}
      </Wrapper>
    );
  }
}
