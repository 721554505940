import React from 'react';
import { connect } from 'react-redux';
import { Query } from "react-apollo";
import { graphql, compose } from 'react-apollo';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import { changeLanguage } from 'redux-store/actions';
import {
  xcountryLocalesAndCurrencies,
  updTripCountry,
  updTripLocale,
  updTripCurrency,
} from 'components/common/commonQueries';
import { Loader } from 'components/common';
import { getItem, setItem, setCookie } from 'utils/utils';
import CountryCard from './Partials/CountryCard';
import getTranslation from 'utils/getTranslation';

const Wrapper = styled.div`
  width: 100%;
  padding: 10px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  @media (max-width: 800px) {
    overflow: auto;
    padding: 10px;
    margin: 5px;
  }
  h1 {
    color: #777777;
    font-size: 18px;
    margin-bottom: 20px;
    font-weight: 400;
  }
`;
const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Container = styled.div`
  display: flex;
  @media (max-width: 800px) {
    flex-flow: column;
  }
`;
const LeftSection = styled.div`

`;
const RightSection = styled.div`

`;
class CountryLocaleSelect extends React.PureComponent {
  constructor(props) {
    super(props);
    let internationalUrl = window.location.origin.split('flystrait');
    internationalUrl = `${internationalUrl[0]}flystrait.com`;
    this.state = {
      selectedCountry: '',
      selectedLocale: '',
      selectedCurrency: '',
      internationalUrl,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.doSubmit && this.props.doSubmit !== nextProps.doSubmit) {
      this.handleSubmit();
    }
  }

  handleCountryChange = (country) => {
    this.setState({
      selectedCountry: country.countryIc,
      selectedLocale: country.locales[0].locale,
      selectedLanguageName: country.locales[0].language,
      selectedCurrency: country.currencies[0].currencyIso,
      currencyDisplay: country.currencies[0].currencyDisplay,
      redirectUrl: country.locales[0].redirectUrl || this.state.internationalUrl,
    });
    this.props.countryChange();
  }
  handleCurrencyChange = (currency) => {
    this.setState({
      selectedCurrency: currency.currencyIso,
      currencyDisplay: currency.currencyDisplay,
    });
  }
  handleLocaleChange = (locale, redirectUrl) => {
    this.setState({
      selectedLocale: locale.locale,
      selectedLanguageName: locale.language,
      redirectUrl: redirectUrl || this.state.internationalUrl,
    });
  }

  handleSubmit = async () => {
    const {
      selectedCountry,
      redirectUrl,
      selectedLocale,
      selectedCurrency,
      currencyDisplay,
    } = this.state;
      window.location.href = `${redirectUrl}/country-redirect?country=${selectedCountry}&locale=${selectedLocale}&currency=${selectedCurrency}&currencydisplay=${currencyDisplay}`;
  }
  render() {
    const { selectedCountry, selectedLocale, selectedCurrency } = this.state;
    return (
      <Wrapper id="countryLocaleSelect">
        <Query
          query={xcountryLocalesAndCurrencies}
          variables={{ fsSupport: true }}
        >
          {({ loading, data }) => {
            if (loading) return <Loader />;
            const international = data.xcountryLocalesAndCurrencies.find(country => country.countryIc === 'XX');
            if (this.props.handleCountriesFetchDone) {
              this.props.handleCountriesFetchDone();
            }
            return (
              <Container>
                <LeftSection>
                  {!isMobile && <h1>{getTranslation('internationSite')}</h1>}
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <CountryCard
                      country={international}
                      key={international.countryIc}
                      defaultSelected={selectedCountry.toLowerCase() === international.countryIc.toLowerCase()}
                      selectedLanguage={selectedLocale}
                      selectedCurrency={selectedCurrency}
                      handleCountryChange={this.handleCountryChange}
                      handleLocaleChange={this.handleLocaleChange}
                      handleCurrencyChange={this.handleCurrencyChange}
                      handleSubmit={this.handleSubmit}
                      style={{ marginLeft: 0 }}
                    />
                  </div>
                </LeftSection>
                <RightSection>
                  {!isMobile && <h1 style={{ marginLeft: 10 }}>{getTranslation('chooseCountry')}</h1>}
                  <Grid>
                    {data.xcountryLocalesAndCurrencies.filter(country => country.countryIc !== 'XX').map(item => (
                      <CountryCard
                        country={item}
                        key={item.countryIc}
                        defaultSelected={selectedCountry.toLowerCase() === item.countryIc.toLowerCase()}
                        selectedLanguage={selectedLocale}
                        selectedCurrency={selectedCurrency}
                        handleCountryChange={this.handleCountryChange}
                        handleLocaleChange={this.handleLocaleChange}
                        handleCurrencyChange={this.handleCurrencyChange}
                        handleSubmit={this.handleSubmit}
                      />
                    ))}
                  </Grid>
                </RightSection>
              </Container>
            );
          }}
        </Query>
      </Wrapper>
    );
  }
}

export default compose(graphql(updTripLocale, {
  name: 'updateTripLocale',
}), graphql(updTripCountry, {
  name: 'updateTripCountry',
}), graphql(updTripCurrency, {
  name: 'updateTripCurrency',
}),
connect(null, { changeLanguage })
)(CountryLocaleSelect);
