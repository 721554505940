import React from "react";
import styled from "styled-components";
import { Button } from "components/common";
import getTranslation from "utils/getTranslation";

const Wrapper = styled.div`
  border-top: 1px solid #dddddd;
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  @media (max-width: 800px) {
    padding: 16px;
  }
  button {
    width: 216px !important;
    @media (max-width: 800px) {
      width: fit-content !important;
    }
  }
  .left {
    button {
      margin-right: 25px;
    }
  }
  .right {
    button {
      margin-left: 25px;
    }
  }
`;

export default ({ activeStep, loading, invalid, handleBack, handleNext }) => (
  <Wrapper>
    <div className="left">
      {activeStep < 5 && (
        <Button
          onClick={handleBack}
          color="#F0F0F0"
          style={{ color: "#007AC7" }}
        >
          {getTranslation(activeStep === 1 ? "cancel" : "previous")}
        </Button>
      )}
      {activeStep === 5 && (
        <>
          <Button
            onClick={handleBack}
            color="#D0F3FC"
            style={{ color: "#007AC7" }}
          >
            {getTranslation("viewPost")}
          </Button>
          <Button
            onClick={handleBack}
            color="#F0F0F0"
            style={{ color: "#EB5757" }}
          >
            {getTranslation("unpublish")}
          </Button>
        </>
      )}
    </div>
    <div className="right">
      {activeStep < 5 && (
        <Button onClick={handleNext} disabled={loading || invalid}>
          {getTranslation(activeStep === 4 ? "publish" : "next")}
        </Button>
      )}
      {activeStep === 5 && (
        <Button
          onClick={handleNext}
          color="#D0F3FC"
          style={{ color: "#007AC7" }}
        >
          {getTranslation("dashboard")}
        </Button>
      )}
    </div>
  </Wrapper>
);
