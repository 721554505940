import {
  ADD_PASSENGER,
  EDIT_PASSENGER,
  ADD_PASSENGER_IDS,
  REMOVE_PASSENGER_IDS
} from 'redux-store/actions';

const initialProps = {
  passengers: [],
  editPassenger: null,
  passengerIds: []
};
export default function (state = initialProps, action) {
  switch (action.type) {
    case ADD_PASSENGER:
      return {
        ...state,
        passengers: [...state.passengers, action.payload],
      };
    case EDIT_PASSENGER:
     return {
       ...state,
       editPassenger: action.payload
     }
    case ADD_PASSENGER_IDS:
      return {
        ...state,
        passengerIds: action.payload
      }
    case REMOVE_PASSENGER_IDS:
      return {
        ...state,
        passengerIds: []
      }
    default:
      return state;
  }
}
