import React from "react";
import Airline from "./Partials/Airline";
import FlightRoute from "./Partials/FlightRoute";

export default ({
  animationClass,
  flightInfo,
  style,
  hopRequest,
  handleFilterModal,
  handleOpenModal,
}) => (
  <React.Fragment>
    <div style={{ ...style }}>
      <Airline
        flightInfo={flightInfo}
        hopRequest={hopRequest}
        handleOpenModal={handleOpenModal}
      />
      <FlightRoute flightInfo={flightInfo} />
    </div>
  </React.Fragment>
);
