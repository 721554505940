import gql from 'graphql-tag';

export const setExtrabaggageCount = gql`mutation setExtrabaggageCount(
  $hopsegmentcontrolId: Int!,
  $extrabaggageId: Int!,
  $count: Int!
) {
  setExtrabaggageCount(
    hopsegmentcontrolId: $hopsegmentcontrolId,
    extrabaggageId: $extrabaggageId,
    count: $count
  ) {
    priceNum,
    hopRequests {
      id
      hopSeqNum
      adultCount
      childCount
      infantCount
      stdDate
      fromAirportIc
      toAirportIc
      stopCount
      hopSegmentControls {
        id
        fscode
        paxnameCharLimit
        bcUndefined
        airlinePnr
        seatClass
        cancellationPenalty
        dateChangePenalty
        seatChoice
        baggagePieceCount
        baggagePieceMaxWeight
        baggagePieceImperial
        webfare
        costDecimalPlaces
        costCurrencyIso
        transferValue
        refundValue
        extraBaggages {
          id
          createdDttm
          modifiedDttm
          inbound
          baggagePieceCount
          baggagePieceMaxWeight
          baggagePieceImperial
          serviceId
          serviceCostAmount
          serviceCostCurrencyIso
          costDecimalPlaces
          quantity
          hopsegmentcontrolId
          passengerNum
          passengerId
        }
        farerules {
          id
          category
          detail
        }
        priceBreakups {
          id
          cost
          costCurrencyIso
          costDecimalPlaces
          passengerTyp
          isTax
          taxCode
          taxName
        }
        flightSegments {
          airlineIc
          flightNum
          fromAirportIc
          stdDate
          stdTime
          toAirportIc
          staDate
          staTime
          aircraftIcao
          durationMins
        }
      }
      passengers {
        id
        title
        firstName
        lastName
        birthdate
        gender
        nationality
        passportCountry
        passportExpdate
        passportNumber
        cardHolder
        cardHoldername
        cardHoldercountryic
        extraBaggages {
          id
          baggagePieceCount
          baggagePieceMaxWeight
        }
      }
    }
    tripCostItems {
        cost,
        costItemLabel,
        costItemCurrencyIso,
        costDecimalPlaces
      }
  }
}`;

export const resetExtrabaggage = gql`mutation resetExtrabaggage(
  $hopsegmentcontrolId: Int!,
) {
  resetExtrabaggage(
    hopsegmentcontrolId: $hopsegmentcontrolId,
  ) {
    priceNum,
    hopRequests {
      id
      hopSeqNum
      adultCount
      childCount
      infantCount
      stdDate
      fromAirportIc
      toAirportIc
      stopCount
      hopSegmentControls {
        id
        fscode
        paxnameCharLimit
        bcUndefined
        airlinePnr
        seatClass
        cancellationPenalty
        dateChangePenalty
        seatChoice
        baggagePieceCount
        baggagePieceMaxWeight
        baggagePieceImperial
        webfare
        costDecimalPlaces
        costCurrencyIso
        transferValue
        refundValue
        extraBaggages {
          id
          createdDttm
          modifiedDttm
          inbound
          baggagePieceCount
          baggagePieceMaxWeight
          baggagePieceImperial
          serviceId
          serviceCostAmount
          serviceCostCurrencyIso
          costDecimalPlaces
          quantity
          hopsegmentcontrolId
          passengerNum
          passengerId
        }
        farerules {
          id
          category
          detail
        }
        priceBreakups {
          id
          cost
          costCurrencyIso
          costDecimalPlaces
          passengerTyp
          isTax
          taxCode
          taxName
        }
        flightSegments {
          airlineIc
          flightNum
          fromAirportIc
          stdDate
          stdTime
          toAirportIc
          staDate
          staTime
          aircraftIcao
          durationMins
        }
      }
      passengers {
        id
        title
        firstName
        lastName
        birthdate
        gender
        nationality
        passportCountry
        passportExpdate
        passportNumber
        cardHolder
        cardHoldername
        cardHoldercountryic
        extraBaggages {
          id
          baggagePieceCount
          baggagePieceMaxWeight
        }
      }
    }
    tripCostItems {
        cost,
        costItemLabel,
        costItemCurrencyIso,
        costItemCurrencyDisplay,
        costDecimalPlaces
      }
  }
}`;
