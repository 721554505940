import React from "react";
import { Helmet } from "react-helmet";

export default class HeaderTags extends React.Component {
  render() {
    const { articleData } = this.props;
    return (
      <Helmet>
        <meta charSet="utf-8" />
        <title>{articleData.title}</title>
        <link rel="alternate" href={window.location.href} />
        {!!articleData && [
          <meta property="og:title" key="2" content={articleData.title} />,
          <meta
            property="og:url"
            key="3"
            content={encodeURI(articleData.url)}
          />,
          <meta property="og:type" key="4" content="Article" />,
          <meta
            property="og:image"
            key="5"
            content={encodeURI(articleData.imageUrl)}
          />,
          <meta name="twitter:card" key="7" content="summary_large_image" />,
          <meta name="twitter:site" key="8" />,
          <meta name="twitter:title" key="9" content={articleData.title} />,
          <meta
            name="twitter:image"
            key="11"
            content={encodeURI(articleData.imageUrl)}
          />
        ]}
      </Helmet>
    );
  }
}
