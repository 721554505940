import React, { Component } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { TripFareRules } from 'components/common';
import Template from './Template';

const Wrapper = styled.div`
  position: relative;
`;

class FlightResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  handleEticket = () => {
    const { eticket, hopRequests } = this.props.tripItem;
    const flightSegment = this.props.tripItem.hopRequests[0].hopSegmentControls[0].flightSegments[0];

    const linkSource = `data:application/pdf;base64,${eticket}`;
    const downloadLink = document.createElement('a');
    const fileName = `eTicket-${hopRequests[0].passengers[0].lastName}-${moment(flightSegment.stdDate).format('DDMMMYYYY')}.flystrait.pdf`;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  handleOpenModal = () => {
    this.setState({ modalOpen: true });
  }

  handleCloseModal = () => {
    this.setState({ modalOpen: false });
  }

  render() {
    const {
      modalOpen,
    } = this.state;
    const { tripItem } = this.props;
    return (
      <Wrapper>
        <div style={{ padding: 10, position: 'relative', margin: 'auto' }}>
          {modalOpen &&
            <TripFareRules
              open={modalOpen}
              handleFareError={this.handleCloseModal}
              handleCloseModal={this.handleCloseModal}
              isStatic
              tripId={this.props.tripId}
            />}
          <Template
            eticket={tripItem.eticket}
            hopRequests={tripItem.hopRequests}
            passengers={tripItem.hopRequests[0].passengers}
            tripCostItems={tripItem.tripCostItems}
            handleEticket={this.handleEticket}
            handleOpenModal={this.handleOpenModal}
          />
        </div>
      </Wrapper>
    );
  }
}

export default FlightResults;
