import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { graphql, compose } from 'react-apollo';
import {
  handlePassengerEdit,
  removePassengerFromAllHopRequest,
  togglePayment,
  handleAddPassengerIds
} from "redux-store/actions";
import { delTripPassenger } from 'components/Payment/PaymentQuery';
import Passenger from './Passenger';
import { getItem, setItem } from 'utils/utils';

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 20px 0;
  background-color: #EEEEEE;
  @media (max-width: 800px) {
    background-color: transparent;
  }
`;

class AddedPassengers extends React.PureComponent {
  removePassenger = (payload) => {
    this.props.removePassengerFromAllHopRequest(payload);
    if (getItem('passengers')) {
      const removedPassenger = getItem('passengers').filter(item =>
        ((item.firstName === payload.firstName &&
        item.lastName === payload.lastName &&
        item.gender === payload.gender &&
        item.birthdate === payload.birthdate) ? null : item));
      setItem('passengers', removedPassenger);
    }
  }

  handleRemovePassenger = (passenger) => {
    const hopRequests = JSON.parse(JSON.stringify(this.props.hopRequests));
    const passengerIndex = hopRequests[0].passengers.findIndex(
      item => item.id === passenger.id
    );
    let passengerIds = [];
    hopRequests.forEach(hopReq => {
      const passengerId = hopReq.passengers[passengerIndex].id;
      passengerIds = [...passengerIds, passengerId];
    });
    this.props.handleAddPassengerIds(passengerIds);
    this.removePassenger(passenger);
    this.props.togglePayment(false);
    setTimeout(() => {
      this.props.handlePassengerEdit(passenger);
    }, 200);
  }
  render() {
    const { passengers } = this.props;
    return (
      passengers.length > 0 ? <Wrapper>
        {passengers.map((passenger, i) => (
          <Passenger
            passenger={passenger}
            number={i + 1}
            handleRemovePassenger={this.handleRemovePassenger}
          />
        ))}
      </Wrapper> : null
    )
  }
}

const mapStateToProps = state => ({
  hopRequests: state.flightResults.hopRequests,
});

const mapDispatchToProps = {
  handlePassengerEdit,
  removePassengerFromAllHopRequest,
  togglePayment,
  handleAddPassengerIds
};

export default compose(
  graphql(delTripPassenger, { name: "delTripPassenger" }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(AddedPassengers);
