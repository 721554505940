export default `
#
# Informativa privacy

#
# Introduzione

Fly Strait OÜ, registrato a Tallinn, in Estonia con il numero di registrazione 14373971 (&quot;noi&quot;, &quot;nostro&quot; o semplicemente &quot;Fly Strait&quot;) è il cosiddetto &quot;controllore dei dati&quot; dei vostri dati personali ed è quindi responsabile per il liceità di ciò che facciamo con i vostri dati personali.

Questo sito web è gestito da Fly Strait. Gestiamo una varietà di dati personali, come nomi, indirizzi e-mail e altre informazioni relative al viaggio nelle loro attività quotidiane. Pertanto, prendiamo molto seriamente la sicurezza dei dati e il rispetto della legislazione sulla protezione dei dati. Questa informativa sulla privacy spiega in che modo raccogliamo, archiviamo, utilizziamo e divulghiamo tutti i dati personali che raccogliamo su di voi quando utilizzate questo sito web, nonché il modo in cui proteggiamo la privacy e la riservatezza delle vostre informazioni personali. Sia che voi siate nuovo al nostro servizio o un utente regolare, la vostra privacy è importante per noi, quindi vi preghiamo di prendere il tempo per conoscere le nostre pratiche e di contattarci se avete qualche domanda.

#
# I dati personali che raccogliamo includono:

Generalmente, il tipo di dati personali che raccogliamo è l&#39;informazione che ci serve per permettervi di organizzare il vostro viaggio e le connesse prenotazioni. Questo include informazioni come il vostro nome e cognome, data di nascita, numero di telefono e indirizzo email. I dati personali che dobbiamo ricevere per fornirvi l&#39;accordo di viaggio prenotato tramite i nostri siti Web sono gli unici dati obbligatori da fornire. A seconda del tipo di servizi di viaggio che utilizzate, potremmo anche raccogliere il vostro numero di viaggiatore regolare (frequent flyer number), informazioni sui vostri requisiti dietetici e problemi di salute (se presenti), e altri dettagli che sono rilevanti per il vostro viaggio o che sono richiesti da un altro fornitore di servizi di viaggio (come compagnie aeree e alberghi). Questo elenco non è completo. Se chiamate il nostro supporto, raccoglieremo i dati forniti durante la telefonata. Come potete vedere qui di seguito, i nostri cookie raccolgono anche alcune informazioni.

Se effettuate una prenotazione per qualcun altro tramite il nostro sito web, richiederemo dati personali su tale persona. In tali circostanze, ci affidiamo a voi per consigliare queste persone riguardo a questa informative sulla privacy.

#
# I dati personali che raccogliamo includono:

In alcuni casi, potremmo gestire le cosiddette &quot;categorie speciali di dati personali&quot; su di voi, che potrebbero essere considerate sensibili. Questo sarebbe il caso, ad esempio, se voi (i) avete presentato un certificato medico per l&#39;uso di una protezione di annullamento o un rimborso da una compagnia aerea (ii) abbiate una malattia o condizioni mediche che influenzano il vostro viaggio e per il quale richiedete assistenza o dove sono richieste diverse autorizzazioni o (iii) avete presentato una richiesta che rivela alcuni altri dati personali sensibili su di voi.

Prima di trattare dei vostri dati personali sensibili, abbiamo bisogno del vostro consenso per farlo. Vi preghiamo quindi di utilizzare i moduli di contatto dedicati sui nostri siti Web per l&#39;invio di dati sensibili. I moduli di contatto vi danno la possibilità di fornirci il consenso richiesto dalla legislazione applicabile sulla protezione dei dati. Tale consenso può essere revocato in qualsiasi momento; Non gestiremo alcun dato personale sensibile di cui non siamo autorizzati a gestire o che non ci avete fornito. Una quantità limitata del nostro personale avrà accesso ai vostri dati personali sensibili e, dopo aver gestito i vostri dati sensibili in conformità con la vostra richiesta, cancelleremo i dati il ​​prima possibile.

#
# Che cosa facciamo con i vostri dati personali?

Per poter gestire i vostri dati personali, le leggi sulla protezione dei dati applicabili ci impongono di avere una cosiddetta &quot;base giuridica&quot; per ciascuno dei nostri scopi per elaborare i vostri dati personali. Per questo motivo, abbiamo elaborato la tabella sottostante per evidenziare la base giuridica su cui riposa ognuno dei nostri scopi.

| **Cosa facciamo (i nostri scopi nel trattare i vostri dati personali)** | **Base giuridica** | **Durata di conservazione** |
| --- | --- | --- |
| Abilita le modalità di viaggio e le prenotazioni che ci avete richiesto (ad esempio, la prenotazione di servizi di viaggio da noi mediati, nonché la fornitura dei nostri servizi). | Esecuzione di un contratto con voi Nel caso in cui ci abbiate fornito dati personali sensibili, il consenso è la base giuridica. | 3 anni dalla data di acquisto. Il consenso per i dati personali sensibili può essere ritirato in qualsiasi momento. |
| Se si è scelto di creare un account utente sul nostro sito Web, renderemo tale account disponibile all&#39;utente. L&#39;account include l&#39;accesso alle informazioni sui vostri precedenti accordi di viaggio e prenotazioni. Memorizzeremo anche nome utente e password. | Esecuzione di un contratto con voi | I dati del vostro account utente, così come le informazioni personali relative alle vostre precedenti disposizioni di viaggio e prenotazioni, saranno conservati fino a quando non deciderai di cancellare il tuo account utente tramite il nostro sito web. Tuttavia, se non hai effettuato l&#39;accesso al tuo account utente per 18 mesi, potremmo cancellare tale account e le informazioni al suo interno, a condizione che non abbiamo un&#39;altra base giuridica per l&#39;archiviazione. |
| Se avete avviato una procedura di prenotazione, ma non avete completato l&#39;acquisto, potremmo inviarvi un&#39;email con un link che riporta al risultato della ricerca, o alla prenotazione iniziata, a seconda di quando il processo di prenotazione sul sito web è stato interrotto. | Il nostro legittimo interesse a condurre affari e consentire di completare l&#39;acquisto senza dover riempire tutte le informazioni ancora una volta. Se non desiderate ricevere questi e-mail, è possibile disattivare in qualsiasi momento all&#39;interno dell&#39;e-mail. | 24 ore dal momento in cui avete lasciato il processo di prenotazione. |
| Prima dell&#39;inizio del vostro viaggio, vi forniremo ulteriori informazioni e offerte relative al vostro specifico viaggio, come ad esempio possibili extra come bagaglio extra e altre informazioni di utilizzo per il vostro viaggio. Alcune informazioni si basano sulla profilatura effettuata sulle informazioni fornite nel corso del processo di prenotazione (ad esempio la data (s) in cui si viaggia, la destinazione, ecc.). | Il nostro interesse legittimo è quello di offrirvi un viaggio più comodo e di consentirvi di trovare facilmente maggiori informazioni rilevanti per voi. Se non si desidera questa informazione, è possibile rinunciare in qualsiasi momento all&#39;interno dell&#39;e-mail. | Non più che fino a quando non iniziate il vostro viaggio. Altre elaborazioni analoghe possono continuare secondo le finalità indicate di seguito. |
| Dopo il vostro viaggio, potremmo fornirvi la nostra newsletter contenente consigli su altre disposizioni di viaggio e prodotti e servizi relativi ai viaggi che potrebbero interessarvi. Alcuni dei consigli si basano sulla profilatura delle tue scelte precedenti al momento della prenotazione di un viaggio e del vostro comportamento in relazione alle nostre e-mail. | Il nostro legittimo interesse a condurre affari e vi consente di organizzare viaggi che vi interessano. Se non desiderate ricevere questi e-mail, è possibile disattivare in qualsiasi momento all&#39;interno dell&#39;e-mail. Inoltre, prima di finalizzare un acquisto, è possibile opporsi alla ricezione della newsletter. | 3 anni dalla data di acquisto. |
| Registrare le chiamate telefoniche per scopi di garanzia della qualità e per eventuali richieste o domande future da parte dell&#39;utente. | Il nostro legittimo interesse a (i) migliorare il nostro servizio attraverso l&#39;educazione interna e, dove applicabile, (ii) risolvere le vostre potenziali richieste o reclami. Se non si desidera che la telefonata venga registrata, è possibile opporsi a tale registrazione prima che inizi la registrazione. | 6 mesi dalla data della telefonata. Solo un numero limitato di persone ha accesso alla vostra registrazione. |
| L&#39;uso dei cookie, ad esempio, migliora l&#39;usabilità di questo sito Web, fornisce un&#39;esperienza personalizzata e raccoglie statistiche sull&#39;utilizzo. Utilizziamo inoltre i cookie di sessione per migliorare la sicurezza di questo sito web | Il nostro legittimo interesse a (i) migliorare il nostro sito web, (ii) mostrarvi le offerte di vostro interesse e (iii) avere un&#39;offerta di servizi sicura e un sito web. Se non desiderate che archiviamo i cookie sul vostro computer, puoi modificare le impostazioni nel vostro browser in qualsiasi momento. | A seconda del tipo di cookie. [Vedete sotto per tempi di archiviazione più dettagliati.](https://flystrait.com/privacy#Cookies) |

In aggiunta a quanto sopra, adottiamo tali misure quotidiane necessarie per le aziende che forniscono servizi ai consumatori, quali contabilità, d&#39;allestimento dei conti, fatturazione, adempiere agli obblighi antiriciclaggio e mantenere la sicurezza del nostro sito Web. Nella misura in cui ciò non è obbligatorio ai sensi delle leggi applicabili, adottiamo tali misure in base al nostro legittimo interesse. Potremmo anche analizzare il comportamento dei nostri clienti al fine di migliorare i nostri siti Web e servizi a livello generale. Tuttavia, tale analisi utilizzerà dati generalizzati o resi anonimi su un livello aggregato.

#
# Condivisione dei vostri dati personali

Condivideremo i vostri dati personali solo laddove necessario per le finalità elencate in questa informativa sulla privacy. Questo potrebbe essere ad altre società all&#39;interno di Fly Strait, alle autorità governative e ai nostri partner commerciali di fiducia. Ad esempio, potremmo condividere i vostri dati personali (compresi i dati personali sensibili quando applicabile) con partner commerciali quali compagnie aeree, fornitori di servizi alberghieri, compagnie assicurative e sistemi di distribuzione globale (i cosiddetti GDS - Global Distribution Systems) per consentire le vostre disposizioni di viaggio e le prenotazioni.

Ogni partner è responsabile della propria gestione dei propri dati personali dopo averli ricevuti da noi, il che significa che è necessario contattare il partner in questione per qualsiasi richiesta relativa ai propri diritti ai sensi della legislazione sulla protezione dei dati applicabile. Vi consigliamo di leggere le rispettive politiche sulla privacy dei partner per informazioni sul loro trattamento dei dati personali. Condivideremo inoltre i vostri dati personali con altre società (i cosiddetti &quot;elaboratori di dati&quot;) necessari per fornire i servizi richiesti, come i fornitori di servizi che gestiscono i nostri call center e gli altri fornitori e distributori che gestiranno i vostri dati personali quando forniscono i loro servizi a noi (ad esempio archiviazione esterna).

A causa della natura globale del settore dei viaggi, i vostri dati personali potrebbero essere trattati in diverse località in tutto il mondo quando le parti con le quali condividiamo i vostri dati personali risiedono in un paese al di fuori dell&#39;UE / SEE. La nostra condivisione di dati personali al di fuori dell&#39;UE / SEE richiede determinati motivi legali ai sensi della legislazione applicabile sulla protezione dei dati. Se un paese è considerato dalla Commissione europea un paese con un livello adeguato di protezione dei dati personali, questa sarà la nostra base giuridica. Altrimenti ci sono tre principali tipi di basi giuridiche su cui basare questa condivisione. Nello specifico:

i. che il trasferimento è necessario per la nostra esecuzione del contratto con voi (ad esempio quando avete prenotato un volo con una compagnia aerea residente al di fuori dell&#39;UE / SEE);

ii. che il trasferimento si baserà sulle clausole standard di protezione dei dati per il trasferimento di dati personali verso paesi al di fuori dell&#39;UE / SEE adottati dalla Commissione europea (una copia di queste clausole standard sulla protezione dei dati è disponibile all&#39;indirizzo http: //ec.europa .eu / giustizia / dataprotection / internationaltransfers / transfer /) e

iii. lo scudo per la privacy UE-USA, in cui il trasferimento è effettuato negli Stati Uniti e il destinatario è debitamente certificato.

#
# Fornitori terzi

Si noti che il nostro sito Web contiene collegamenti ad altri siti Web e serve contenuti di fornitori di terze parti. Questa politica sulla privacy si applica al nostro sito e ai nostri servizi. Quando si seguono collegamenti ad altri siti Web o si utilizzano servizi e prodotti di terzi, è necessario leggere le connesse informative sulla privacy. Inoltre, se si sceglie di contattarci tramite social media, questa informative sulla privacy non si applica ai dati personali inviati dall&#39;utente come parte di tale contatto - in tal caso, si consiglia di leggere l&#39;informativa sulla privacy di tale provider di social media.

#
# I vostri diritti

In base alla legislazione sulla protezione dei dati applicabile, si dispone di determinati diritti come il cosiddetto &quot;soggetto dei dati&quot;. Di seguito, abbiamo elencato i vostri diritti. I vostri diritti includono quanto segue:

i. Diritto di accesso - Avete il diritto di accedere ai dati personali che gestiamo. Avete anche il diritto di ricevere determinate informazioni su ciò che facciamo con i dati personali. Tali informazioni sono fornite in questo documento.

ii. Diritto di rettifica - In determinate circostanze, avete il diritto di correggere dati personali inesatti che vi riguardano e di avere dati personali incompleti completati. Si prega di notare che potremmo non essere in grado di correggere dati personali imprecisi forniti dall&#39;utente a causa, ad esempio, delle regole della compagnia aerea, e che tale particolare cambiamento potrebbe comportare un costo.

iii. Diritto alla cancellazione - In determinate circostanze, avete il diritto di cancellare i vostri dati personali. E&#39; il cosiddetto diritto all&#39;oblio.

iv. Diritto alla limitazione del trattamento - In determinate circostanze, avete il diritto di limitare il modo in cui utilizziamo i vostri dati personali.

v. Diritto alla portabilità dei dati - Avete il diritto di ricevere i vostri dati personali (o di trasmettere i vostri dati personali direttamente ad un altro titolare di dati) in un formato strutturato, comunemente usato e leggibile da noi.

VI. Diritto di opporsi - Avete il diritto di opporvi a determinati tipi di trattamento dei dati personali che eseguiamo. Questo vale per tutte le nostre attività basate sul nostro &quot;interesse legittimo&quot;. Infine, avete anche il diritto di presentare un reclamo all&#39;autorità di vigilanza sulla protezione dei dati applicabile.

#
# Cookies

Un cookie è un piccolo file di testo che viene memorizzato sul vostro computer, alcuni solo fino alla chiusura del browser (i cosiddetti &quot;cookie di sessione&quot;) e alcuni per un lungo periodo di tempo (i cosiddetti &quot;cookie permanenti&quot;). Se non desiderate consentire la memorizzazione dei cookie sul vostro computer, potete modificare le impostazioni nel vostro browser. Si noti tuttavia che in alcuni casi alcune funzionalità del nostro sito Web potrebbero non funzionare correttamente e di conseguenza alcuni contenuti potrebbero non essere visualizzati correttamente.

Questo sito Web utilizza i cookie per una serie di motivi, tra cui la fornitura di un&#39;esperienza personalizzata, per migliorare l&#39;usabilità di questo sito Web e per la raccolta di statistiche sull&#39;utilizzo. Utilizziamo inoltre i cookie di sessione per migliorare la sicurezza di questo sito web.

In alcuni casi, quando utilizziamo i cookie, condividiamo i dati con terzi. Ad esempio, utilizziamo Google Analytics e Google AdWords, servizi che trasmettono i dati sul traffico del sito Web ai server di Google. Google Analytics non identifica i singoli utenti e non associa il vostro indirizzo IP a nessun altro dato posseduto da Google. Utilizziamo i report forniti da Google per aiutarci a capire il traffico del sito Web e l&#39;utilizzo della pagina Web e ottimizzare gli annunci pubblicitari acquistati dalla rete di Google e da altri network pubblicitari. Google può elaborare i dati secondo le modalità descritte nell[&#39; Informativa sulla privacy di Google](https://policies.google.com/privacy) e per gli scopi indicati in questa sezione. Potete disattivare Google Analytics se disabiliti o rifiutare il ​​cookie, disabiliti JavaScript o utilizzi il servizio di opt-out fornito da Google. [Per disattivare le funzioni pubblicitarie di Google, utilizzare questo link.](https://tools.google.com/dlpage/gaoptout)

Il nostro sito utilizza anche pixel di Facebook, che raccoglie dati aggregati anonimi che ci aiutano con l&#39;ottimizzazione degli acquisti di annunci su piattaforme diverse di Facebook (incluso Instagram). Facebook raccoglie un ID utente che consentirà loro di corrispondere se un utente ha visitato un sito con il pixel di Facebook. Tuttavia, nel nostro ruolo di inserzionisti non possiamo mai identificare il comportamento di un utente specifico. Facebook e le sue piattaforme correlate si trovano in un ecosistema pubblicitario chiuso in cui i loro utenti possono regolare se acconsentono agli inserzionisti che utilizzano i dati raccolti dai loro siti web per acquistare annunci su Facebook. [Usare questo link per visualizzare e modificare le vostre impostazioni pubblicitarie su Facebook.](https://facebook.com/ads/preferences)

Inoltre, il nostro sito web utilizza script di tracciamento delle conversioni e cookie di [Microsoft Bing](https://privacy.microsoft.com/), [TripAdvisor](https://www.smartertravel.com/privacy-policy) e [TvSquared](https://tvsquared.com/privacy-policy/) (è possibile visualizzare le loro informative sulla privacy seguendo i link).

Tutti questi servizi raccolgono dati statistici aggregati che ci aiutano a ottimizzare gli acquisti di annunci pubblicitari. In qualità di inserzionisti non possiamo identificare un singolo utente utilizzando questi dati. Potete disabilitare l&#39;uso dei cookie nelle impostazioni del vostro browser.

[Per ulteriori informazioni sui cookie visitare www.youronlinechoices.com.](https://www.youronlinechoices.com/)

#
# Sicurezza dei dati

Al fine di proteggere i vostri dati personali, abbiamo implementato una serie di misure di sicurezza tecniche e organizzative. Ad esempio, manteniamo alti livelli di sicurezza tecnica in tutti i sistemi (inclusi tracciabilità, disaster recovery, limitazioni di accesso, ecc.). Inoltre, abbiamo adottato politiche per garantire che i nostri dipendenti (che naturalmente sono soggetti a obblighi di riservatezza) non utilizzino dati personali quando non è necessario. Tali politiche stabiliscono anche i nostri standard per quando contrattiamo fornitori o introduciamo nuovi sistemi IT all&#39;interno delle nostre operazioni.

#
# Come contattarci

In caso di domande relative al trattamento dei dati personali o all&#39;uso dei cookie, o se si desidera invocare uno dei diritti previsti dalla legislazione applicabile sulla protezione dei dati, inviare un&#39;e-mail a: privacy@flystrait.com

#
# Modifiche dell&#39;informativa sulla privacy

Se cambiamo il modo in cui gestiamo i vostri dati personali o il modo in cui utilizziamo i cookie, aggiorneremo immediatamente questa informativa sulla privacy e la pubblicheremo su questo sito web.
`;
