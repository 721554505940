import React from "react";
import styled from "styled-components";
import Cropper from "react-easy-crop";
import { ItemTitle } from "components/Influencer";

const Wrapper = styled.div`
  padding-bottom: 50px;
  .imageWrapper {
    position: relative;
    width: 592px;
    height: 300px;
    @media (max-width: 800px) {
      width: 100%;
      height: 250px;
    }
  }
  .customTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btnWrapper {
      width: 100px;
    }
  }
`;
let timeout;
export default class extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      aspect: props.aspect || 8 / 3,
      image: props.image,
      crop: { x: 0, y: 0 },
      zoom: 1,
    };
  }
  componentWillMount() {
    clearTimeout(timeout);
    timeout = null;
  }
  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({
      croppedAreaPixels,
    });
  };
  onCropChange = (crop) => {
    clearTimeout(timeout);
    this.setState(
      {
        crop,
      },
      () => {
        timeout = setTimeout(() => {
          this.handleImageCrop();
        }, 500);
      }
    );
  };
  onZoomChange = (zoom) => {
    clearTimeout(timeout);
    this.setState(
      {
        zoom,
      },
      () => {
        timeout = setTimeout(() => {
          this.handleImageCrop();
        }, 500);
      }
    );
  };
  createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
      image.src = url;
    });

  getRadianAngle = (degreeValue) => {
    return (degreeValue * Math.PI) / 180;
  };

  getCroppedImg = async (imageSrc, pixelCrop, rotation = 0) => {
    const image = await this.createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const safeArea = Math.max(image.width, image.height) * 2;

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea;
    canvas.height = safeArea;

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2);
    ctx.rotate(this.getRadianAngle(rotation));
    ctx.translate(-safeArea / 2, -safeArea / 2);

    // draw rotated image and store data.
    ctx.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5
    );
    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
      data,
      0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
      0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y
    );

    // As Base64 string
    const base64 = canvas.toDataURL("image/jpeg");
    return new Promise((resolve) => {
      canvas.toBlob(async (file) => {
        resolve({ base64, file, pixelCrop });
        //resolve(URL.createObjectURL(file));
      }, "image/jpeg");
    });
  };

  handleImageCrop = async () => {
    try {
      const croppedImage = await this.getCroppedImg(
        this.state.image,
        this.state.croppedAreaPixels
      );
      this.props.handleCroppedImage(croppedImage);
      // console.log("donee", { croppedImage });
      // this.props.handleCroppedImage(croppedImage.secure_url);
    } catch (e) {
      console.error(e);
    }
  };
  render() {
    return (
      <Wrapper>
        <ItemTitle
          title={
            <div className="customTitle">
              <span>Crop Image</span>
            </div>
          }
        />
        <div className="imageWrapper">
          <Cropper
            image={this.state.image}
            crop={this.state.crop}
            zoom={this.state.zoom}
            aspect={this.state.aspect}
            onCropChange={this.onCropChange}
            onCropComplete={this.onCropComplete}
            onZoomChange={this.onZoomChange}
          />
        </div>
      </Wrapper>
    );
  }
}
