import React from "react";
import styled from "styled-components";
import DomainPayment from "components/common/DomainPayment";
import { EditIcon } from "components/common/SvgIcons";
import DomainSelectionModal from "components/Influencer/Modals/DomainSelectionModal";
import getTranslation from "utils/getTranslation";
import { setItem, currencyCoversion, getTipDomain } from "utils/utils";
import config from "config";
import link from "assets/tip/Link.svg";

const Wrapper = styled.div`
  p {
    color: #777777;
    font-size: 13px;
    line-height: 140%;
    a {
      color: #007ac7;
      text-decoration: underline;
      font-weight: 500;
    }
  }
  .activatedDomain {
    display: flex;
    flex-flow: column;
    div {
      display: flex;
      align-items: center;
      img {
        margin-right: 15px;
      }
      span {
        color: #007ac7;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.2px;
      }
    }
    span {
      color: #444444;
      font-size: 14px;
    }
  }
  .domainDetails {
    display: flex;
    color: #444444;
    .domain {
      font-weight: 500;
      color: #444444;
    }
    .price {
      color: #007ac7;
      font-weight: 500;
    }
    .edit {
      cursor: pointer;
      margin-left: 24px;
      display: flex;
      align-items: center;
      color: #007ac7;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500;
      font-family: "Roboto Condensed", sans-serif;
    }
  }
  .inputsWrapper {
    display: flex;
    justify-content: space-between;
  }
`;

export default class extends React.PureComponent {
  constructor(props) {
    super(props);
    const {
      customsitedomains,
      custom_domain_selected
    } = props.influencerDetails;
    const domainData =
      customsitedomains.length > 0 ? customsitedomains[0] : null;
    this.state = {
      openModal: false,
      activated: domainData ? domainData.activated_at : false,
      custom_domain_selected,
      domainData
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.influencerDetails.customsitedomains !==
      this.props.influencerDetails.customsitedomains
    ) {
      this.setState(
        {
          domainData: nextProps.influencerDetails.customsitedomains[0],
          activated: nextProps.influencerDetails.customsitedomains[0] ? nextProps.influencerDetails.customsitedomains[0].activated_at : false
        },
        () => {
          setItem("domainData", this.state.domainData);
        }
      );
    }
  }

  handleModalToggle = () => {
    this.setState({
      openModal: !this.state.openModal
    });
  };
  handleRedirect = () => {
    setItem("paymentType", "domain");
    setItem("domainRedirect", window.location.pathname);
    setItem("domainData", this.props.influencerDetails.customsitedomains[0]);
  };
  render() {

    const {
      openModal,
      activated,
      custom_domain_selected,
      domainData
    } = this.state;
    const { influencerDetails, showShare, handleShare, edit } = this.props;
    const generatedSubDomain = domainData
      ? domainData.domain.replace(new RegExp("[.]", "g"), "-")
      : null;
    return (
      <Wrapper>
        <DomainSelectionModal
          open={openModal}
          influencerDetails={influencerDetails}
          handleCloseModal={this.handleModalToggle}
        />
        {!showShare && custom_domain_selected &&
          (!edit ? (
            <h2>
              {getTranslation(
                activated
                  ? "domainActivated"
                  : "activateCustomDomain"
              )}
            </h2>
          ) : (
              <h2>{getTranslation("domainSetup")}</h2>
            ))
        }
        {/* {!custom_domain_selected && (
          <div className="activatedDomain">
            <div>
              <img src={link} alt="" />

              <a
                href={"https://" + getTipDomain(influencerDetails)}
                target="_blank"
              >
                <span>{getTipDomain(influencerDetails)}</span>
              </a>
            </div>
          </div>
        )} */}
        {activated && (
          <div className="activatedDomain">
            <div>
              <img src={link} alt="" />
              <span>{domainData.domain}</span>
            </div>
            <span>
              ACTIVE. Expires on 31 Dec 2020.{" "}
              {currencyCoversion(domainData.price_usd)} / 1 Year
            </span>
          </div>
        )}
        {/* {custom_domain_selected && (
          <p>
            {getTranslation("autoGeneratedSubDomain")}:{" "}
            <a
              href={`https://${generatedSubDomain}.${config.tipFrontUrl}`}
              target="_blank"
            >
              {generatedSubDomain}.{config.tipFrontUrl}
            </a>
          </p>
        )} */}
        {!activated && custom_domain_selected && !showShare && (
          <>
            <div className="domainDetails">
              <span className="domain">{domainData.domain}</span>
              <span style={{ margin: "0 10px" }}>-</span>
              <span>
                <span className="price">
                  {currencyCoversion(domainData.price_usd)}
                </span>{" "}
                / <span>First Year</span>
              </span>
              <div className="edit" onClick={this.handleModalToggle}>
                <EditIcon stroke="#007AC7" />
                <span>{getTranslation("edit")}</span>
              </div>
            </div>

            <DomainPayment handleShare={handleShare} handleRedirect={this.handleRedirect} domainData={domainData} />
          </>
        )}
      </Wrapper>
    );
  }
}
