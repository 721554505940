export default {
  v: "5.6.5",
  fr: 30,
  ip: 265,
  op: 291,
  w: 1000,
  h: 720,
  nm: "Feature-1 in",
  ddd: 0,
  assets: [
    {
      id: "image_0",
      w: 302,
      h: 12,
      u: "/static/ae-images/Feature-1/images/",
      p: "img_0.svg",
      e: 0
    },
    {
      id: "image_1",
      w: 139,
      h: 105,
      u: "/static/ae-images/Feature-1/images/",
      p: "img_1.svg",
      e: 0
    },
    {
      id: "image_2",
      w: 282,
      h: 433,
      u: "/static/ae-images/Feature-1/images/",
      p: "img_2.svg",
      e: 0
    },
    {
      id: "image_3",
      w: 131,
      h: 139,
      u: "/static/ae-images/Feature-1/images/",
      p: "img_3.svg",
      e: 0
    },
    {
      id: "image_4",
      w: 170,
      h: 181,
      u: "/static/ae-images/Feature-1/images/",
      p: "img_4.svg",
      e: 0
    },
    {
      id: "image_5",
      w: 102,
      h: 173,
      u: "/static/ae-images/Feature-1/images/",
      p: "img_5.svg",
      e: 0
    },
    {
      id: "image_6",
      w: 803,
      h: 470,
      u: "/static/ae-images/Feature-1/images/",
      p: "img_6.svg",
      e: 0
    },
    {
      id: "image_7",
      w: 559,
      h: 648,
      u: "/static/ae-images/Feature-1/images/",
      p: "img_7.svg",
      e: 0
    }
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 2,
      nm: "Vector.png",
      cl: "svg",
      refId: "image_0",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 270,
              s: [0]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 279,
              s: [100]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 310,
              s: [100]
            },
            { t: 318, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [598, 540, 0], ix: 2 },
        a: { a: 0, k: [151, 6, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 2,
      nm: "Vector.png",
      cl: "svg",
      refId: "image_0",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 270,
              s: [0]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 279,
              s: [100]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 310,
              s: [100]
            },
            { t: 318, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [598, 506, 0], ix: 2 },
        a: { a: 0, k: [151, 6, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 2,
      nm: "Vector.png",
      cl: "svg",
      refId: "image_0",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 270,
              s: [0]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 279,
              s: [100]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 310,
              s: [100]
            },
            { t: 318, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [598, 472, 0], ix: 2 },
        a: { a: 0, k: [151, 6, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 4,
      ty: 2,
      nm: "Vector.png",
      cl: "svg",
      refId: "image_0",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 270,
              s: [0]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 279,
              s: [100]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 310,
              s: [100]
            },
            { t: 318, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [598, 438, 0], ix: 2 },
        a: { a: 0, k: [151, 6, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 5,
      ty: 2,
      nm: "Vector.png",
      cl: "svg",
      refId: "image_0",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 270,
              s: [0]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 279,
              s: [100]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 310,
              s: [100]
            },
            { t: 318, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [598, 404, 0], ix: 2 },
        a: { a: 0, k: [151, 6, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 6,
      ty: 2,
      nm: "Vector.png",
      cl: "svg",
      refId: "image_0",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 270,
              s: [0]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 279,
              s: [100]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 310,
              s: [100]
            },
            { t: 318, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [598, 372, 0], ix: 2 },
        a: { a: 0, k: [151, 6, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 7,
      ty: 2,
      nm: "Vector.png",
      cl: "svg",
      refId: "image_0",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 270,
              s: [0]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 279,
              s: [100]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 310,
              s: [100]
            },
            { t: 318, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [598, 336, 0], ix: 2 },
        a: { a: 0, k: [151, 6, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 8,
      ty: 2,
      nm: "Vector.png",
      cl: "svg",
      refId: "image_0",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 270,
              s: [0]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 279,
              s: [100]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 310,
              s: [100]
            },
            { t: 318, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [598, 300, 0], ix: 2 },
        a: { a: 0, k: [151, 6, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 9,
      ty: 2,
      nm: "Vector.png",
      cl: "svg",
      refId: "image_0",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 270,
              s: [0]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 279,
              s: [100]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 310,
              s: [100]
            },
            { t: 318, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [598, 268, 0], ix: 2 },
        a: { a: 0, k: [151, 6, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 10,
      ty: 2,
      nm: "Vector.png",
      cl: "svg",
      refId: "image_0",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 270,
              s: [0]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 279,
              s: [100]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 310,
              s: [100]
            },
            { t: 318, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [598, 234, 0], ix: 2 },
        a: { a: 0, k: [151, 6, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 11,
      ty: 2,
      nm: "Group 18.png",
      cl: "svg",
      refId: "image_1",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 310,
              s: [100]
            },
            { t: 318, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [902, 617, 0], ix: 2 },
        a: { a: 0, k: [69.5, 52.5, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 265,
              s: [0, 0, 100]
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 273,
              s: [100, 100, 100]
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 276,
              s: [70, 70, 100]
            },
            { t: 279, s: [100, 100, 100] }
          ],
          ix: 6
        }
      },
      ao: 0,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 12,
      ty: 2,
      nm: "man.png",
      cl: "svg",
      refId: "image_2",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 265,
              s: [0]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 274,
              s: [100]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 310,
              s: [100]
            },
            { t: 318, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0, y: 1 },
              o: { x: 0, y: 0 },
              t: 265,
              s: [584.5, 472, 0],
              to: [-67, 0, 0],
              ti: [67, 0, 0]
            },
            { t: 280, s: [182.5, 472, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [141, 216.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 13,
      ty: 2,
      nm: "Group 15.png",
      cl: "svg",
      refId: "image_3",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 270,
              s: [0]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 279,
              s: [100]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 310,
              s: [100]
            },
            { t: 318, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [866, 478, 0], ix: 2 },
        a: { a: 0, k: [65.5, 69.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 14,
      ty: 2,
      nm: "Group 15.png",
      cl: "svg",
      refId: "image_3",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 270,
              s: [0]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 279,
              s: [100]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 310,
              s: [100]
            },
            { t: 318, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [866, 298, 0], ix: 2 },
        a: { a: 0, k: [65.5, 69.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 15,
      ty: 2,
      nm: "Group 14.png",
      cl: "svg",
      refId: "image_4",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 270,
              s: [0]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 279,
              s: [100]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 310,
              s: [100]
            },
            { t: 318, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [305.978, 318, 0], ix: 2 },
        a: { a: 0, k: [85, 90.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 16,
      ty: 2,
      nm: "Group 17.png",
      cl: "svg",
      refId: "image_5",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 310,
              s: [100]
            },
            { t: 318, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [85.5, 578, 0], ix: 2 },
        a: { a: 0, k: [51, 86.5, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 265,
              s: [0, 0, 100]
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 273,
              s: [100, 100, 100]
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 276,
              s: [70, 70, 100]
            },
            { t: 279, s: [100, 100, 100] }
          ],
          ix: 6
        }
      },
      ao: 0,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 17,
      ty: 2,
      nm: "Group 19.png",
      cl: "svg",
      refId: "image_6",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 265,
              s: [0]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 274,
              s: [100]
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 310,
              s: [100]
            },
            { t: 318, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [576, 370, 0], ix: 2 },
        a: { a: 0, k: [401.5, 235, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 18,
      ty: 2,
      nm: "Vector (1).png",
      cl: "svg",
      refId: "image_7",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 310,
              s: [100]
            },
            { t: 318, s: [0] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [372, 340, 0], ix: 2 },
        a: { a: 0, k: [279.5, 324, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0, 0, 0.667], y: [1, 1, 1] },
              o: { x: [0, 0, 0.333], y: [0, 0, 0] },
              t: 265,
              s: [0, 0, 100]
            },
            { t: 279, s: [100, 100, 100] }
          ],
          ix: 6
        }
      },
      ao: 0,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0
    }
  ],
  markers: []
};
