export default {
  v: "5.6.5",
  fr: 30,
  ip: 182,
  op: 221,
  w: 330,
  h: 360,
  nm: "Benefit-3",
  ddd: 0,
  assets: [
    {
      id: "image_0",
      w: 44,
      h: 44,
      u: "/static/ae-images/Benefit-3/images/",
      p: "img_0.svg",
      e: 0
    },
    {
      id: "image_1",
      w: 44,
      h: 44,
      u: "/static/ae-images/Benefit-3/images/",
      p: "img_1.svg",
      e: 0
    },
    {
      id: "image_2",
      w: 42,
      h: 42,
      u: "/static/ae-images/Benefit-3/images/",
      p: "img_2.svg",
      e: 0
    },
    {
      id: "image_3",
      w: 188,
      h: 190,
      u: "/static/ae-images/Benefit-3/images/",
      p: "img_3.svg",
      e: 0
    },
    {
      id: "image_4",
      w: 196,
      h: 196,
      u: "/static/ae-images/Benefit-3/images/",
      p: "img_4.svg",
      e: 0
    }
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 2,
      nm: "FB.png",
      cl: "svg",
      refId: "image_0",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 198,
              s: [100]
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 199,
              s: [0]
            },
            { t: 208, s: [100] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 1 },
              o: { x: 0.167, y: 0 },
              t: 198,
              s: [171.375, 86.51, 0],
              to: [-15.312, 15.583, 0],
              ti: [0, 0, 0]
            },
            {
              i: { x: 0, y: 1 },
              o: { x: 0.131, y: 0 },
              t: 199,
              s: [79.5, 180.01, 0],
              to: [0, 0, 0],
              ti: [-15.312, 15.583, 0]
            },
            { t: 215, s: [171.375, 86.51, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [22, 22, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 2,
      nm: "IN.png",
      cl: "svg",
      refId: "image_1",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 198,
              s: [100]
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 199,
              s: [0]
            },
            { t: 208, s: [100] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 1 },
              o: { x: 0.167, y: 0 },
              t: 198,
              s: [260.5, 126.51, 0],
              to: [-30.167, 8.917, 0],
              ti: [0, 0, 0]
            },
            {
              i: { x: 0, y: 1 },
              o: { x: 0.131, y: 0 },
              t: 199,
              s: [79.5, 180.01, 0],
              to: [0, 0, 0],
              ti: [-30.167, 8.917, 0]
            },
            { t: 215, s: [260.5, 126.51, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [22, 22, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 2,
      nm: "TW.png",
      cl: "svg",
      refId: "image_2",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 198,
              s: [100]
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 199,
              s: [0]
            },
            { t: 208, s: [100] }
          ],
          ix: 11
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 1 },
              o: { x: 0.167, y: 0 },
              t: 198,
              s: [266.5, 204.51, 0],
              to: [-31.167, -4.083, 0],
              ti: [0, 0, 0]
            },
            {
              i: { x: 0, y: 1 },
              o: { x: 0.131, y: 0 },
              t: 199,
              s: [79.5, 180.01, 0],
              to: [0, 0, 0],
              ti: [-31.167, -4.083, 0]
            },
            { t: 215, s: [266.5, 204.51, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [21, 21, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 4,
      ty: 2,
      nm: "woman.png",
      cl: "svg",
      refId: "image_3",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [106.5, 207.51, 0], ix: 2 },
        a: { a: 0, k: [94, 95, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 5,
      ty: 2,
      nm: "bg35.png",
      cl: "svg",
      refId: "image_4",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [177.5, 180.01, 0], ix: 2 },
        a: { a: 0, k: [98, 98, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ef: [
        {
          ty: 5,
          nm: "CC Line Sweep",
          np: 7,
          mn: "CS LineSweep",
          ix: 1,
          en: 1,
          ef: [
            {
              ty: 0,
              nm: "Completion",
              mn: "CS LineSweep-0001",
              ix: 1,
              v: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.167], y: [0] },
                    t: 199,
                    s: [0]
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 200,
                    s: [99]
                  },
                  { t: 211, s: [0] }
                ],
                ix: 1
              }
            },
            {
              ty: 0,
              nm: "Direction",
              mn: "CS LineSweep-0002",
              ix: 2,
              v: { a: 0, k: 1, ix: 2 }
            },
            {
              ty: 0,
              nm: "Thickness",
              mn: "CS LineSweep-0003",
              ix: 3,
              v: { a: 0, k: 50, ix: 3 }
            },
            {
              ty: 0,
              nm: "Slant",
              mn: "CS LineSweep-0004",
              ix: 4,
              v: { a: 0, k: 99, ix: 4 }
            },
            {
              ty: 7,
              nm: "Flip Direction",
              mn: "CS LineSweep-0005",
              ix: 5,
              v: { a: 0, k: 0, ix: 5 }
            }
          ]
        }
      ],
      ip: 0,
      op: 450,
      st: 0,
      bm: 0
    }
  ],
  markers: []
};
