import React from "react";
import styled from "styled-components";
import Dropzone from "react-dropzone";
import { Button } from "components/common";
import { InfoIcon } from "components/common/SvgIcons";
import { uploadImage } from "utils/api";
import getTranslation from "utils/getTranslation";
import { resizedImage } from "utils/utils";
import config from "config";
import upload from "assets/tip/Upload.svg";
import selectIcon from "assets/tip/btn-select.svg";
import selectedIcon from "assets/tip/btn-selected.svg";

const Wrapper = styled.div`
  margin-bottom: 100px;
  .info {
    display: flex;
    align-items: flex-start;
    margin-bottom: 24px;
    p {
      font-size: 13px;
      color: #777777;
      margin: 0 0 0 15px;
    }
  }
  .drop-wrapper {
    cursor: pointer;
    width: 100%;
    background: #ffffff;
    border: 3px dashed #d0f3fc;
    .inner {
      outline: none;
      display: flex;
      flex-flow: column;
      align-items: center;
      margin: 0px auto 0px auto;
      max-width: 700px;
      img {
        margin-top: 40px;
      }
      .submitMediaTOS {
        font-size: 12px;
        color: #222222;
        margin-top: 20px;
      }
    }
  }
  .images {
    display: flex;
    flex-wrap: wrap;
    .image {
      position: relative;
      margin: 0 20px 20px 0;
      cursor: pointer;
      @media (max-width: 800px) {
        :nth-child(2n) {
          margin-right: 0;
        }
      }
      .select {
        position: absolute;
        top: 12px;
        left: 12px;
      }
      .cover {
        height: 216px;
        width: 216px;
        object-fit: cover;
        @media (max-width: 800px) {
          height: 165px;
          width: 165px;
        }
      }
    }
  }
`;

export default class extends React.PureComponent {
  state = {};
  onDrop = async ([file]) => {
    if (file.type.includes("image") && file.size > config.imageSizeLimit) {
      this.setState({
        imageSizeError: true
      });
      return;
    }
    this.setState({ uploading: true, imageSizeError: false });
    try {
      const request = await uploadImage(file);
      this.setState({
        uploading: false
      });
      this.props.handleCoverImagesChange([
        ...this.props.images,
        { image: request.secure_url, selected: false }
      ]);
    } catch (err) {
      this.setState({ uploading: false });
    }
  };

  handleSelectToggle = i => {
    const selectedImage = this.props.images[i];
    selectedImage.selected = !selectedImage.selected;
    const coverImages = this.props.images.map((image, index) =>
      index !== i ? image : selectedImage
    );
    this.props.handleCoverImagesChange(coverImages);
  };
  render() {
    const { uploading, imageSizeError } = this.state;
    const { images } = this.props;
    return (
      <Wrapper>
        <div className="section">
          {/* <ItemTitle title={getTranslation("chooseCoverImage")} /> */}
          <div className="info">
            <InfoIcon stroke="#D0F3FC" />
            <p>{getTranslation("coverImageInfo")}</p>
          </div>
          <div className="images">
            {images.map((image, i) => (
              <div className="image" key={i}>
                <img
                  className="select"
                  src={image.selected ? selectedIcon : selectIcon}
                  alt=""
                  onClick={() => this.handleSelectToggle(i)}
                />
                <img
                  className="cover"
                  src={resizedImage(image.image, 216)}
                  alt=""
                  onClick={() => this.handleSelectToggle(i)}
                />
              </div>
            ))}
          </div>
          <div className="drop-wrapper">
            <Dropzone onDrop={this.onDrop} accept="image/*">
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()} style={{ outline: 0 }}>
                    <input {...getInputProps()} />
                    <div className="inner">
                      <img src={upload} alt="" />
                      <p>{getTranslation("dragDropText")}</p>
                      <div style={{ width: 272 }}>
                        <Button color="#D0F3FC" style={{ color: "#007AC7" }}>
                          {getTranslation(
                            uploading ? "uploading" : "uploadFile"
                          )}
                        </Button>
                      </div>
                      {imageSizeError && (
                        <p className="error">
                          {getTranslation("imageSizeError")}
                        </p>
                      )}
                      <p className="submitMediaTOS">
                        {getTranslation("maxImageSize")}
                      </p>
                      <p className="submitMediaTOS">
                        {getTranslation("submitMediaTOS")}
                      </p>
                    </div>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
        </div>
      </Wrapper>
    );
  }
}
