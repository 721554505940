import React from "react";
import styled from "styled-components";
import Fab from "@material-ui/core/Fab";

const Button = styled(Fab)`
  box-shadow: none !important;
  width: 100% !important;
  background-color: ${(props) =>
    props.color ? props.color : "#56CCF2"} !important;
  box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.12);
  &.Mui-disabled {
    background-color: #dddddd !important;
    color: #fff !important;
  }
  .MuiFab-label {
    font-weight: 400;
  }
`;

export default ({
  disabled,
  children,
  style = {},
  onClick,
  color,
  type,
  roundButton,
  className,
}) => (
  <Button
    type={type}
    variant={roundButton ? "" : "extended"}
    disabled={disabled}
    onClick={onClick}
    style={style}
    color={color}
  >
    {children}
  </Button>
);
