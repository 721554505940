export default `
# Algemene voorwaarden

Deze algemene voorwaarden (&quot;AV&quot;) zijn van toepassing tussen u (&quot;u&quot; of de &quot;klant&quot;) en Fly Strait OÜ (&quot;wij&quot; of &quot;ons&quot;), de exploitant van het boekingsportaal flystrait.com (hierna te noemen: als de &quot;Portal&quot;). U wordt verzocht om deze Algemene Voorwaarden zorgvuldig te lezen voordat u de Portal gebruikt. Door de Portal te gebruiken, gaat u akkoord met deze algemene voorwaarden. Als u deze AV niet accepteert, vragen wij u om de Portal niet te gebruiken en de website te verlaten.

Je moet ten minste 18 jaar oud zijn en handelen als particulier om een ​​boeking te kunnen maken op de Portal. Een particuliere consument is, in tegenstelling tot een onderneming, een natuurlijke persoon die een rechtshandeling aangaat voor doeleinden die voornamelijk buiten zijn commerciële en/of zelfstandige activiteiten liggen.

De exploitant van de Portal en uw contractpartner voor het gebruik van de Portal is:

Fly Strait OÜ (Fly Strait)

E-mail: support@flystrait.com

De algemene voorwaarden zijn als volgt uiteengezet:

#
# 1. Reikwijdte van de diensten

### 1.1 Bemiddeling van reisservices

1.1.1 Voor de vluchtboekingdiensten die op de Portal worden aangeboden (hierna te noemen &quot;reisdiensten&quot;), handelen wij uitsluitend in onze hoedanigheid van tussenpersoon. Daartoe zijn onze rol en verplichtingen beperkt tot bemiddeling in reisdiensten die worden verleend door derden zoals luchtvaartmaatschappijen (hierna telkens &quot;dienstverlener&quot;).

1.1.2 Bijgevolg wordt de overeenkomst voor de daadwerkelijke levering van reisdiensten rechtstreeks van kracht tussen u en de relevante dienstverlener. Wij zijn geen co-leverancier van de reisdiensten en we zijn geen partij bij de contractuele relatie tussen u en de dienstverlener.

1.1.3 Door de Portal te gebruiken om reisdiensten te kopen, machtigt u ons om te bemiddelen met de betreffende dienstverlener (s) namens u, inclusief bemiddeling van de betaling voor deze reisdiensten, om ervoor te zorgen dat de transactie tussen u en de dienstverlener (s) wordt uitgevoerd. De prijs die wordt uitgegeven voor de eigenlijke reisdiensten kan een commissie bevatten voor de diensten die we verlenen voor het bemiddelen tussen de overeenkomst tussen u en de relevante dienstverlener.

1.1.4 De verantwoordelijkheid voor de daadwerkelijke uitvoering van de reisdiensten die via de Portal wordt bemiddeld, is uitsluitend in handen van de betreffende dienstverlener. In onze rol als tussenpersoon, nemen wij geen enkele verantwoordelijkheid voor de levering van de reisdiensten door de dienstverlener en wij doen geen uitspraken of garanties (noch expliciet noch impliciet) met betrekking tot de geschiktheid of kwaliteit van reisdiensten die op de portal zijn bemiddeld. Voor elke claim die u mogelijk heeft in verband met de uitvoering of niet-uitvoering van de reisdienst, is de dienstverlener de uiteindelijke verantwoordelijke.

1.1.5 Mocht u de mogelijkheid hebben om speciale wensen (zoals speciale maaltijden, gehandicaptenvoorzieningen of kinderzitjes) aan te vragen bij het boeken van reisdiensten, dan zullen wij uw aanvraag doorgeven aan de betreffende dienstverlener. We kunnen echter geen verantwoordelijkheid nemen over de vraag of de dienstverlener daadwerkelijk aan dergelijke wensen kan voldoen.

### 1.2 Toepasselijke contractuele voorwaarden

1.2.1 Deze algemene voorwaarden zijn van toepassing op de bemiddeling van reisdiensten overeenkomstig paragraaf 1.1 en voor onze eigen diensten overeenkomstig sectie 1.2.

1.2.2 Voor de overeenkomst tussen u en de relevante dienstverlener (paragraaf 1.1.2), zijn de algemene voorwaarden van de desbetreffende dienstverlener (zoals vervoersvoorwaarden, overdrachtsvoorwaarden, verzekeringsvoorwaarden en dergelijke), welke uitgegeven is door de dienstverlener, van toepassing. Dergelijke algemene voorwaarden van de relevante dienstverlener zullen u tijdens het boekingsproces worden medegedeeld. Aangezien de algemene voorwaarden van de dienstverlener bepalingen kunnen bevatten met betrekking tot aansprakelijkheid, annulering, wijzigingen van boekingen en restituties (indien beschikbaar) en andere beperkingen, wordt u geadviseerd deze voorwaarden aandachtig te lezen.

#
# 2. Boekingsproces en contactgegevens

2.1 Tijdens het boekingsproces krijgt u de technische middelen die u nodig heeft om fouten in deelnameformulieren op te sporen en deze te corrigeren voordat u uw reserveringsaanvraag indient. U wordt verzocht om alle gegevens te controleren op nauwkeurigheid voordat u uw reserveringsaanvraag afsluit. Latere wijzigingsverzoeken kunnen tot aanzienlijke extra kosten leiden.

2.2 We moeten mogelijk contact met u opnemen, bijvoorbeeld in geval van latere wijzigingen in de gekochte reisdiensten. U moet correcte contactgegevens opgeven, zoals uw telefoonnummer en e-mailadres. U moet ook continu controleren of u een bericht van Ons heeft ontvangen.

Het is ook uw verantwoordelijkheid om ervoor te zorgen dat u onze berichten kunt ontvangen. Wij zijn niet verantwoordelijk indien u geen bericht van ons ontvangt vanwege omstandigheden die redelijkerwijs buiten onze macht liggen, inclusief maar niet beperkt tot het volgende: (i) u gaf ons een onjuist e-mailadres; (ii) uw e-mailinstellingen staan ​​niet toe dat onze e-mail u bereikt; of (iii) uw e-mailinstellingen behandelen onze e-mail als spam.

#
# 3. Bemiddeling van vluchten

### 3.1 Afsluiting van contracten en prijswijzigingen

3.1.1 Nadat u uw reserveringsaanvraag heeft ingediend, zullen wij de ontvangst van uw aanvraag via e-mail bevestigen. Dit is het moment dat het bemiddelingscontract tussen U en Ons tot stand komt. Als u niet binnen één uur na het voltooien van uw reserveringsverzoek een dergelijke bevestiging ontvangt en geen van onze operators contact met u opneemt per e-mail of telefoon om eventuele problemen aan te geven, neem dan telefonisch contact met ons op voor verificatie.

3.1.2 Zodra uw gevraagde tickets zijn uitgegeven, ontvangt u een bevestigingsmail met een ticketnummer. Dit is het moment waarop een bindend contract tussen u en de betreffende luchtvaartmaatschappij (en) is gesloten.

3.1.3 Prijzen voor de vlucht, evenals de beschikbaarheid van de plaatsen, worden rechtstreeks ingevoegd door de toepasselijke luchtvaartmaatschappij. Voor prijswijzigingen van luchtvaartmaatschappijen buiten onze controle die plaatsvinden na het indienen van het boekingsverzoek, maar voordat het contract met de luchtvaartmaatschappij bindend is geworden (zoals uiteengezet in Paragraaf 3.1.2 hierboven), wordt het contract niet van kracht en wordt uw betaling volledig terugbetaald. We kunnen contact met u opnemen en bieden u de mogelijkheid om de gewijzigde prijs tijdens de normale openingstijden te accepteren, echter niet later dan 24 uur nadat we kennis hebben genomen van de prijswijziging of op de eerste doordeweekse dag na die 24 uurs-periode.

### 3.2 Informatie over boeking en vervoersvoorwaarden

3.2.1 Met betrekking tot de vluchten aangeboden op de Portal, handelen wij uitsluitend in onze hoedanigheid van tussenpersoon. De overeenkomst voor de uitvoering van de vlucht wordt rechtstreeks van kracht tussen u en de betreffende luchtvaartmaatschappij en wij aanvaarden geen enkele contractuele verantwoordelijkheid met betrekking tot de uitvoering of niet-nakoming van uw vlucht. De uitvoerende luchtvaartmaatschappij is als enige verantwoordelijk voor de uitvoering / niet-nakoming van uw vlucht.

3.2.2 Voordat u de boeking afsluit, worden de algemene voorwaarden van de betreffende luchtvaartmaatschappij(en) u ter beschikking gesteld.

3.2.3 Hieronder, bij wijze van algemeen overzicht, bieden wij informatie over voorwaarden met betrekking tot boeking en vervoer die door luchtvaartmaatschappijen doorgaans op dergelijke of op zijn minst een vergelijkbare manier worden toegepast. Afwijkende bepalingen van de betreffende luchtvaartmaatschappij hebben echter voorrang boven de algemene informatie in deze sectie 3.2.3. Raadpleeg daarom altijd de van toepassing zijnde voorwaarden en bepalingen van de desbetreffende luchtvaartmaatschappij voordat u boekt.

#### a. Vluchttijden/inchecken

Alle opgegeven vluchttijden zijn lokaal. De aankomsten van de volgende dag worden aangegeven met een &quot;+1&quot; in de dienstregeling. De vermelde vluchttijden zijn voorlopig en kunnen op korte termijn worden gewijzigd nadat het ticket is uitgegeven; bijvoorbeeld vanwege beperkingen door vluchtcontrole, het weer of functionele beperkingen door de luchtvaartmaatschappij. Blijf alstublieft ruim voor de vlucht op de hoogte van de actuele tijden.

Houd u aan de inchecktijden die door de luchtvaartmaatschappij zijn vermeld. De luchtvaartmaatschappijen hebben het recht om instappen te weigeren als u te laat bent om in te checken. Houd er rekening mee dat sommige luchtvaartmaatschappijen het inchecken via hun eigen website aanmoedigen, omdat zij bij het handmatig inchecken op de luchthaven een incheck-toeslag in rekening kunnen brengen.

#### b. Combinatie van individuele tickets

Een combinatie van twee afzonderlijke eenrichtingskaartjes in plaats van een retourticket is duidelijk als zodanig gemarkeerd tijdens de boekingsprocedure. De tickets worden onafhankelijk van elkaar behandeld in geval van annulering, wijziging, verstoring van het luchtverkeer, zoals stakingen en wijzigingen in vluchtschema&#39;s. De eigen regels van iedere luchtvaartmaatschappij zijn van toepassing.

Vliegtickets met verschillende boekingsnummers worden altijd beschouwd als reizen die onafhankelijk van elkaar zijn.

#### c. Vliegtickets met verschillende segmenten/volgorde van gebruik

Uw retourticket of een enkele reis kan uit meerdere segmenten bestaan. Volgens de voorwaarden van de meeste luchtvaartmaatschappijen moeten dergelijke vluchtsegmenten op volgorde worden gebruikt. Als dat niet het geval is, zullen veel luchtvaartmaatschappijen vervoer weigeren op volgende vluchtsegmenten (bijvoorbeeld het niet gebruiken van één deel van een reis kan de rest van het ticket ongeldig maken). Voor retourtickets kan het niet op komen dagen op uw uitgaande vlucht ertoe leiden dat uw inkomende vlucht wordt geannuleerd door de luchtvaartmaatschappij

#### d. Zwangerschap

Sommige luchtvaartmaatschappijen weigeren vrouwen die voorbij week 28 van de zwangerschap zijn op het moment van de heen- of terugreis. Als u zwanger bent, moet u met de luchtvaartmaatschappij en uw arts overleggen of u wel of niet met de reis kunt beginnen.

#### e. Baby&#39;s en kinderkaartjes

Neem contact op met de luchtvaartmaatschappij om de voorwaarden te krijgen voor reizen met een kind dat geen eigen zitplaats heeft. Meestal hebben kinderen boven de leeftijd van 2 jaar een apart zitje nodig, terwijl kinderen tussen de 0 en 2 jaar als zuigelingen reizen en geen eigen zitplaats toegewezen krijgen. Als het kind vóór het einde van de reis de leeftijd van 2 jaar bereikt, moet een kindticket voor de hele reis worden geboekt. Tickets voor baby&#39;s kunnen niet vóór de geboorte worden geboekt, omdat de juiste naam en geboortedatum moeten overeenkomen met die in het paspoort. We vergoeden niet alle kosten die ontstaan ​​als het verkeerde type ticket vanaf het begin wordt geboekt.

#### f. Niet-begeleide minderjarigen

We bemiddelen niet in het boeken van tickets voor niet-begeleide minderjarigen. Kinderen onder de 18 jaar moeten geboekt worden voor een reis in het gezelschap van een volwassene. Sommige landen en luchtvaartmaatschappijen weigeren de toegang tot kinderen onder de 18 jaar, tenzij vergezeld door een wettelijke voogd. Houd er rekening mee dat sommige luchtvaartmaatschappijen van kinderen jonger dan 18 jaar vereisen dat ze een ​​geboorteakte mee nemen tijden hun reis.

#### g. Verloren / beschadigde bagage

In onze hoedanigheid als tussenpersoon aanvaarden wij geen aansprakelijkheid voor verloren of beschadigde bagage. Eventuele problemen moeten onmiddellijk worden gemeld aan de vertegenwoordiger van de luchtvaartmaatschappij op de luchthaven.

#### h. Transit- en overnachtingsaccommodatie

Over het algemeen is vervoer over de grond en / of overnachting tijdens uw reis niet inbegrepen in de prijs van het vliegticket. U bent zelf verantwoordelijk voor het controleren van dienstregelingen en prijzen van het openbaar vervoer.

#### i. Verbindingstijden tussen vluchten

Standaard tickets die op de Portal zijn geboekt, hebben goedgekeurde verbindingstijden. De benodigde tijden voor het maken van verbindingen tussen vluchten worden berekend door de luchtvaartmaatschappijen. Als een vluchtsegment wordt vertraagd en leidt tot een gemiste verbinding, zijn de luchtvaartmaatschappijen verplicht om u te helpen bij het bereiken van uw eindbestemming (zie paragraaf 9.1).

Wanneer afzonderlijke tickets worden geboekt, zijn de luchtvaartmaatschappijen niet verantwoordelijk voor gemiste verbindingen als gevolg van vertragingen. Daarom is het uw verantwoordelijkheid om ervoor te zorgen dat de verbindingstijd voldoende is, afhankelijk van de luchtvaartmaatschappijen en luchthavens. Eventuele extra kosten als gevolg van gemiste aansluitingen worden niet vergoed.

#### j. Dubbele boeking

Een dubbele boeking betekent dat twee of meer boekingen met dezelfde passagiersnaam zijn gemaakt met dezelfde luchtvaartmaatschappij. Als u een dubbele boeking heeft, kan de luchtvaartmaatschappij de reis (en) annuleren. Dit kan ook gebeuren als de boekingen zijn gemaakt met verschillende reisbureaus. Wij zijn niet verantwoordelijk voor annuleringen door luchtvaartmaatschappijen, noch voor geweigerde terugbetalingen door luchtvaartmaatschappijen in gevallen waarin zij een dubbele boeking vermoeden.

### 3.3 Luchtvaartmaatschappijen die verboden zijn in de EU (zwarte lijst)

Houd er rekening mee dat bepaalde luchtvaartmaatschappijen niet mogen opereren binnen de EU, volgens een besluit van de Europese Commissie in nauw overleg met de nationale luchtvervoersautoriteiten. Dergelijke luchtvaartmaatschappijen zijn verboden omdat ze als onveilig worden beschouwd of niet onderworpen zijn aan adequate controle door de autoriteiten van hun land.

U kunt via de volgende link controleren welke luchtvaartmaatschappijen worden getroffen door een exploitatieverbod: [Zwarte lijst (lijst met luchtvaartmaatschappijen die niet zijn toegestaan ​​om actief te zijn in de EU)](https://ec.europa.eu/transport/language-selector/site-language_en?destination=node/2840)

### 3.4 Wijzigingen in de dienstregeling en annulering door luchtvaartmaatschappijen

3.4.1 Uw overeenkomst met de betreffende luchtvaartmaatschappij kan hen toestaan ​​uw boekingen te annuleren of aan te passen. We zullen u op de hoogte brengen van eventuele wijzigingen zodra wij door de luchtvaartmaatschappij op de hoogte zijn gesteld.

3.4.2 De vluchttijden in uw boekingsbevestiging kunnen veranderen tussen de datum van uw boeking en de datum waarop u daadwerkelijk reist. In het geval van wijzigingen in het vluchtschema, zullen wij u op de hoogte brengen zodra wij hiervan door de luchtvaartmaatschappij op de hoogte worden gebracht. We raden u echter ten zeerste aan om ten minste 72 uur vóór het geplande vertrek van elke vlucht contact op te nemen met uw luchtvaartmaatschappij om ervoor te zorgen dat de vlucht (en) (en eventuele aansluitende vluchten) volgens schema verlopen. We hebben geen controle over wijzigingen in de dienstregeling van de luchtvaartmaatschappij en aanvaarden geen verantwoordelijkheid voor de kosten die kunnen voortvloeien uit dergelijke wijzigingen.

### 3.5 Veranderingen en annuleringen aangevraagd door u

Voor goedkope luchtvaartmaatschappij (en), zie Paragraaf 3.7.

3.5.1 De voorwaarden voor het wijzigen van vluchtboekingen (inclusief het wijzigen van de naam van de passagier, de bestemming en de reisdatum) en voor het verstrekken van annuleringsvergoedingen worden vastgesteld door de relevante luchtvaartmaatschappij, uw contractpartner voor het verstrekken van de vlucht. Wij als tussenpersoon hebben geen invloed op dergelijke voorwaarden.

3.5.2 Als u uw boeking wilt wijzigen of een annuleringsrestitutie wilt aanvragen, als een extra eigen service, bieden wij aan om het verzoek namens u af te handelen, op voorwaarde dat de voorwaarden van de luchtvaartmaatschappij een dergelijke wijziging of annuleringsrestitutie toestaan. Tijdens het proces van het boeken van dergelijke aanvullende services, zullen we u op de hoogte stellen van eventuele verdere voorwaarden voor dergelijke services. Als alternatief kunt u de betreffende luchtvaartmaatschappij ook rechtstreeks benaderen.

3.5.3 Om de door u gevraagde wijzigingen te kunnen verwerken, is het noodzakelijk dat wij uw wijzigingsverzoeken uiterlijk 24 uur voorafgaand aan het begin van de reis ontvangen (alleen telefonisch). Als u ons Flexibel Ticket-service hebt gekocht, raadpleegt u hoofdstuk 10.

Voor wijzigingsverzoeken op kortere termijn raden we aan dat u rechtstreeks contact opneemt met de betreffende luchtvaartmaatschappij.

### 3.6 Niet verschijnen of niet-aanwezig zijn op de vlucht

U machtigt ons hierbij om de niet-gebruikte vlucht namens u te annuleren in geval van niet verschijnen of afwezigheid zijn op de vlucht en om namens u een mogelijke terugbetalingen aan te vragen bij uw luchtvaartmaatschappij. We hebben het recht maar zijn niet verplicht om dit te doen en uw recht om rechtstreeks van de luchtvaartmaatschappij terugbetalingen te vragen blijft ongewijzigd.

### 3.7 Goedkope luchtvaartmaatschappijen

3.7.1 Het wordt tijdens de boekingsprocedure duidelijk aan u getoond als de toepasselijke vlucht wordt uitgevoerd door een goedkope luchtvaartmaatschappij. De volgende speciale bepalingen zijn van toepassing op vluchten uitgevoerd door goedkope luchtvaartmaatschappijen

3.7.2 Bij bemiddeling in reisdiensten door goedkopere maatschappijen, kunnen wij namens u handelen bij het sluiten van het contract tussen u en de goedkopere luchtvaartmaatschappij.

3.7.3 We hebben geen toegang tot de boekingssystemen van goedkope luchtvaartmaatschappijen. Neem daarom rechtstreeks contact op met de relevante goedkope luchtvaartmaatschappij voor vragen over boekingen, wijzigingen of annulering van uw boeking. Als u onze Flexibel Ticket-service heeft aangeschaft (zie Sectie 10), moeten alle omboekingsverzoeken via onze klantenservice worden gedaan zoals uiteengezet in paragraaf 10.1.4.

3.7.4 Houd er ook rekening mee dat sommige goedkope luchtvaartmaatschappijen kosten in rekening brengen voor handmatig inchecken op de luchthaven. Om dergelijke kosten te vermijden, moet u de mogelijkheid van online inchecken gebruiken via de website van de betreffende goedkope luchtvaartmaatschappij. Informeer uzelf tijdig voor uw vertrek over de incheckmogelijkheden en -voorwaarden van uw luchtvaartmaatschappij.

3.7.5 Boekingsbevestigingen voor goedkope vluchten worden verzonden zodra de reservering is bevestigd door de betreffende goedkopere luchtvaartmaatschappij. U kunt twee boekingsbevestigingen ontvangen - één van ons en één van de relevante goedkope luchtvaartmaatschappij. Als u twee boekingsbevestigingen hebt ontvangen, gebruikt u de boekingsbevestiging van de goedkope luchtvaartmaatschappij voor het inchecken.

#
# 4. Bijzondere bepalingen voor de bemiddeling van boekingen van meerdere diensten

De portal biedt de mogelijkheid om meerdere individuele diensten te mixen en matchen zoals u wenst (bijv. vlucht + hotel). In dit geval instrueert u ons om op te treden als tussenpersoon voor het boeken van verschillende reisdiensten van verschillende leveranciers. De relevante leveranciers worden duidelijk aan u gepresenteerd tijdens de boekingsprocedure voordat de boeking is voltooid. In het geval dat U individuele diensten combineert, wordt er geen reisovereenkomst gesloten tussen U en ons; in plaats daarvan sluit u verschillende contracten af ​​voor het leveren van individuele diensten bij elke afzonderlijke leverancier. In dit geval opereren wij uitsluitend in onze hoedanigheid van tussenpersoon met betrekking tot elke individuele reisdienst.

#
# 5. Informatie over paspoort-, visa- en gezondheidsvoorzieningen

5.1 Paspoort-, visum- en / of gezondheidseisen kunnen veranderen en u moet daarom ruim van tevoren met de bevoegde autoriteit (ambassade, consulaat enz.) overleggen. Het is uw verantwoordelijkheid om in het bezit te zijn van een geldig paspoort en, indien van toepassing, een visum. Het is belangrijk om visa vereisten voor alle transit punten op uw reis in gedachten te houden en eventueel aan te vragen. Het kan vaak enige tijd duren om een ​​visum te verkrijgen, dus u wordt geadviseerd om ruim van tevoren een aanvraag in te dienen. Wij aanvaarden geen verantwoordelijkheid voor klanten die niet over de juiste documenten beschikken.

5.2 Elke bestemming heeft zijn eigen vereisten voor inreisformaliteiten, vaccinaties, enz. Die ook kunnen variëren naargelang de nationaliteit van de passagier. Het is uw verantwoordelijkheid om die informatie te verzamelen. Geen enkel incident welke voortkomt uit het niet naleven van dergelijke officiële voorschriften, kan worden beschouwd als onze verantwoordelijkheid. We raden u daarom aan om altijd de verschillende formaliteiten van de gekozen bestemming of doorreislanden te controleren, evenals de tijd die nodig is om alle gerelateerde stappen te doorlopen.

#
# 6. Kosten en betaling

### 6.1 Betaling

6.1.1 Betaling voor reisservices wordt ofwel door ons verwerkt (in samenwerking met onze betalingsdienstaanbieder (s) die de betalingsverwerkingsfunctionaliteit leveren) of de dienstverlener. Houd er rekening mee dat we uw betalingsinformatie, zoals creditcard- of bankpasinformatie, moeten delen met onze betalingsdienstaanbieder (s) om de betaling te kunnen verwerken. Alle betalingsinformatie van onze klanten wordt gecodeerd op een beveiligde server wanneer deze wordt gedeeld met onze betalingsdienstaanbieder (s).

6.1.2 Om uw betaling te kunnen verwerken, moet u ervoor zorgen dat er voldoende geld beschikbaar is. In het geval dat er zich een probleem voordoet met betrekking tot de verwerking van uw betaling, zullen wij de verwerking van uw betaling opnieuw proberen in samenwerking met onze betalingsdienstaanbieder(s). Mocht dit niet resulteren in een voltooide betaling, dan nemen we zo snel mogelijk contact met u op voor instructies over andere betaalmiddelen. Als we na deze verdere instructies uw betaling niet ontvangen, wordt uw openstaande betaling doorverwezen naar een incassobureau.

### 6.2 Betalingsfraude

Als er redenen zijn om te vermoeden dat er fraude wordt gepleegd, behouden wij ons het recht voor om de verwerking van dergelijke betalingen te weigeren. Legitimisatie van Betaling kan worden geëist als een misdrijf wordt vermoed. Alle soorten betalingsfraude worden aan de politie gemeld en doorverwezen naar een incassobureau.

#
# 7. Klachten

### 7.1 Claims met betrekking tot de uitvoering van reisservices

Elk probleem, opmerking of claim met betrekking tot de daadwerkelijke uitvoering van de reisservices moet rechtstreeks worden gericht aan de relevante dienstverlener met wie u het contract voor de relevante reisdienst heeft. Zie ook sectie 9 voor uw rechten op grond van EU-regelgeving.

### 7.2 Klachten met betrekking tot onze eigen diensten

Klachten met betrekking tot onze eigen diensten worden uitsluitend schriftelijk afgehandeld en moeten binnen twee maanden na de einddatum van uw reis via e-mail worden ingediend.

E-mailadres: [complaints@flystrait.com](mailto:complaints@flystrait.com)

#
# 8. Rechten van de klant op grond van EU-regelgeving

### 8.1 Geannuleerde of vertraagde vluchten

Als u de EU binnenkomt of verlaat, of een EU-luchtvaartmaatschappij heeft, heeft u mogelijk het recht om kostenvergoeding te eisen die u rechtstreeks kunt doen gelden jegens de desbetreffende luchtvaartmaatschappij in het geval dat uw vlucht wordt geannuleerd, wordt uitgesteld of u wordt geweigerd tijdens instappen. [Klik hier voor meer informatie over EG-verordening 261/2004.](http://eur-lex.europa.eu/legal-content/EN/ALL/?uri=CELEX%3A32004R0261)

### 8.2 Aansprakelijkheid van de vervoerder

De EG-verordening (889/2002) betreffende aansprakelijkheid van luchtvervoerders bij ongevallen. [Regulering is hier te vinden.](http://eur-lex.europa.eu/legal-content/EN/TXT/?uri=celex%3A32002R0889)

#
# 9. Aansprakelijkheid

9.1 U accepteert dat wij optreden als tussenpersoon tussen u en de dienstverlener. Wij kunnen onder geen enkele omstandigheid aansprakelijk worden gesteld met betrekking tot reisdiensten die u bij een of meer dienstverleners heeft geboekt en wij aanvaarden geen enkele aansprakelijkheid voor eventuele onjuistheden in de informatie die op de portal wordt weergegeven en die door de betreffende dienstverlener is verstrekt.

9.2 Mocht een dienstverlener om welke reden dan ook niet in staat zijn om de reisdienst te bieden, ook wanneer een dienstverlener een faillissement aanvraagt, dan kunnen wij alleen optreden als tussenpersoon en restitutiebetalingen waar we die al hebben ontvangen van de betreffende dienstverlener.

9.3 Met betrekking tot onze eigen diensten zijn wij aansprakelijk voor schade die onderworpen is aan de beperkingen die in deze AV zijn uiteengezet en voor zover wettelijk is toegestaan. Wij zijn alleen aansprakelijk voor directe schade die u feitelijk heeft geleden, betaald of opgelopen als gevolg van een toerekenbare tekortkoming van onze verplichtingen met betrekking tot onze eigen diensten, tot een totaalbedrag van de kosten van uw boeking (voor één evenement of reeks van verbonden gebeurtenissen).

9.4 De beperking van aansprakelijkheid zoals uiteengezet in artikel 10.3 is ook van toepassing op plichtsverzuim door personen voor de schuld van wie wij volgens de wettelijke bepalingen verantwoordelijk zijn.

#
# 10. Toepasselijk recht en oplossen van geschillen

### 10.1 Toepasselijk recht

De Estlandse wetgeving, met uitzondering van het inhoudelijke uniforme recht, in het bijzonder het VN-verkooprecht op de internationale verkoop van goederen, is uitsluitend van toepassing op deze AV en het contract tussen U en ons.

Als consument profiteert u van alle dwingende bepalingen van de wetgeving van het land waar u woont. Niets in deze AV, waaronder deze sectie 11.1, heeft invloed op uw rechten als consument om op dergelijke dwingende bepalingen van lokale wetgeving te vertrouwen.

### 10.2 Online geschillenbeslechting

De Europese Commissie biedt een platform voor online geschillenbeslechting (ODS) onder: (http://ec.europa.eu/consumers/odr/)

#
# 11. Gegevensbescherming

Wij nemen de bescherming van uw persoonlijke gegevens serieus. Gedetailleerde informatie over het verzamelen, verwerken en gebruiken van uw persoonlijke gegevens vindt u in ons [Privacybeleid.](https://flystrait.com/privacy)
`;
