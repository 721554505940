import React, { Component } from 'react';
import styled from "styled-components";

const Input = styled.div`
  height: 35px;
  color: var(--black-color);
  padding: 17px 22px;
  font-size: 12px;
  outline: none;
  border-bottom: 1px solid var(--black-color);
  letter-spacing: 0.5px;
  white-space: nowrap;
  overflow: hidden;
  &:empty:before{
    content: attr(placeholder);
    display: block; /* For Firefox */
    opacity: 0.3
  }
   br {
    display:none;
  }
  * {
    display:inline;
    white-space:nowrap;
  }
`;

export default class extends Component {
  componentDidMount() {
    document.getElementById('ffNumber').addEventListener('input', this.handleChange, false);
  }

  setEndOfContenteditable = (contentEditableElement) => {
    let range;
    let selection;
    if (document.createRange) {
      range = document.createRange();
      range.selectNodeContents(contentEditableElement);
      range.collapse(false);
      selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
    } else if (document.selection) {
      range = document.body.createTextRange();
      range.moveToElementText(contentEditableElement);
      range.collapse(false);
      range.select();
    }
  }

  handleChange = (e) => {
    const value = e.target.innerText;
    this.props.onChange(value);
    const element = document.getElementById('ffNumber');
    const html = value.slice(0, 2).toUpperCase().bold() + value.slice(2);
    element.innerHTML = html;
    this.setEndOfContenteditable(element);
    element.blur();
    element.focus();
  }

  handleClear = () => {
    document.getElementById('ffNumber').innerText = '';
    this.props.onChange('');
    document.getElementById('ffNumber').focus();
  }
  render() {
    const {
      width,
      hintText,
      value,
      tabIndex,
      image,
    } = this.props;
    const ffCode = value ? value.slice(0, 2) : '';
    return (
      <div style={{ position: 'relative', width: width || 'auto' }}>
        <Input
          ref={(c) => { this._input = c; }}
          contentEditable
          id="ffNumber"
          placeholder={hintText}
          tabIndex={tabIndex}
          spellcheck="false"
          onChange={this.handleChange}
        />
        {image &&
          <img
            src={image}
            alt=""
            style={{
              height: 18,
              position: 'absolute',
              left: 0,
              bottom: 4,
            }}
          />}
        {ffCode.length === 2 &&
          <img
            src={`/static/airlines/${ffCode}.svg`}
            alt=""
            style={{ position: 'absolute', right: 0, bottom: 8 }}
            height="20"
          />}
      </div>
    );
  }
}
