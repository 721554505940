import React from "react";
import styled, { withTheme } from "styled-components";
import moment from "moment";
import ClickOutside from "react-click-outside";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Fade from "@material-ui/core/Fade";
import { isMobile } from "react-device-detect";
import { DateRangePicker, createStaticRanges } from "react-date-range";
import { enGB, enUS, nl, fr, de, it, pt, es } from "date-fns/esm/locale";
import { Input, Button as CustomButton } from "components/common";
import { DateIcon } from "components/common/SvgIcons";
import ActionButtons from "./ActionButtons";
import { localeDate, getItem } from "utils/utils";
import { getDateSuggestions } from "utils/api";
import getTranslation from "utils/getTranslation";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

const locales = [
  {
    country: "AT",
    locale: de,
  },
  {
    country: "BE",
    locale: nl,
  },
  {
    country: "DE",
    locale: de,
  },
  {
    country: "ES",
    locale: es,
  },
  {
    country: "FR",
    locale: fr,
  },
  {
    country: "GB",
    locale: enGB,
  },
  {
    country: "IE",
    locale: enUS,
  },
  {
    country: "IN",
    locale: enUS,
  },
  {
    country: "IT",
    locale: it,
  },
  {
    country: "LU",
    locale: fr,
  },
  {
    country: "MT",
    locale: enUS,
  },
  {
    country: "NL",
    locale: nl,
  },
  {
    country: "PT",
    locale: pt,
  },
  {
    country: "US",
    locale: enUS,
  },
  {
    country: "XX",
    locale: enUS,
  },
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />;
});

const StyledPopper = styled(Popper)`
  z-index: 999;
  .rdrStaticRangeLabel {
    color: ${(props) => props.theme.secondary};
    font-size: 12px;
    font-family: "Roboto";
  }
  .rdrDayNumber span {
    color: ${(props) => props.theme.secondary};
  }
  .rdrInRange ~ .rdrDayNumber span {
    color: ${(props) => props.theme.secondary} !important;
  }
  .rdrInRange {
    color: ${(props) => props.theme.primaryBackground} !important;
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: ${(props) => (props.isMobile ? "start" : "center")};
  font-size: 18px;
  cursor: pointer;
  @media (max-width: 800px) {
    text-align: left;
  }
`;
const Dates = styled.div`
  width: ${(props) => (props.isMobile ? "100%" : "45%")};
  border-bottom: 1px solid var(--black-color);
  color: ${(props) => (props.hasDate ? "var(--black-color)" : "#ccc")};
  position: relative;
  cursor: pointer;
  @media (min-width: 1000px) {
    width: 100%;
    margin-right: 5px;
  }
`;

const DateWrapper = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.16);
`;

const Overlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  @media (max-width: 800px) {
    cursor: none;
  }
`;

const HolidayLabel = styled.div`
  position: absolute;
  top: 10px;
  left: 20px;
  color: #777777;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 12px;
  text-transform: uppercase;
`;

class NewDatePicker extends React.PureComponent {
  constructor(props) {
    super(props);
    const date = props.date || moment().subtract(1, "month");
    const dateSelectedOnce = !!props.date;
    const initialDates = {
      startDate: date,
      endDate: props.returnDate ? props.returnDate : date,
      key: "selection",
      color: props.theme.primary,
      showDateDisplay: false,
    };
    const predefinedRanges = [];
    this.state = {
      selectionRange: initialDates ? [initialDates] : [],
      suggestions: predefinedRanges,
      saveBtnText: props.returnDate
        ? this.props.okLabel
        : this.props.oneWayLabel,
      title: props.departureLabel,
      minDate: props.prevHopReqDate
        ? moment(props.prevHopReqDate).toDate()
        : new Date(),
      dateSelectedOnce,
      open: false,
    };
    if (props.fromAirport) {
      this.getHolidays(props.fromAirport.airportIc);
    }
  }
  async componentWillReceiveProps(nextProps) {
    if (
      nextProps.openDate &&
      nextProps.openDate !== this.props.openDate &&
      !this.state.open
    ) {
      document.getElementById(this.props.id).click();
      // this.handleOpen();
    }

    if (
      nextProps.fromAirport &&
      nextProps.fromAirport !== this.props.fromAirport
    ) {
      this.getHolidays(nextProps.fromAirport.airportIc);
    }

    if (nextProps.date === null && nextProps.date !== this.props.date) {
      const date = moment().subtract(1, "month");
      this.setState({
        dateSelectedOnce: false,
        selectionRange: [
          {
            startDate: date,
            endDate: date,
            key: "selection",
            color: this.props.theme.primary,
            showDateDisplay: false,
          },
        ],
      });
    }
  }

  getLocale = () => {
    const locale = locales.find(
      (item) => item.country === getItem("selectedCountry")
    );
    return locale.locale || enUS;
  };

  getHolidays = async (airportIc) => {
    try {
      const suggestions = await getDateSuggestions(airportIc);
      if (suggestions.suggestions) {
        const allSuggestions = suggestions.suggestions.map((item) => ({
          label: item.name,
          holidays: item.holDates,
          range: () => ({
            startDate: moment(item.fromDate).toDate(),
            endDate: moment(item.toDate).toDate(),
          }),
        }));
        const predefinedRanges = createStaticRanges(allSuggestions);
        this.setState({
          suggestions: predefinedRanges,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  getDates = () => {
    const { selectionRange } = this.state;
    const startDate = localeDate(selectionRange[0].startDate);
    const endDate =
      moment(selectionRange[0].startDate).unix() !==
        moment(selectionRange[0].endDate).unix()
        ? ` - ${localeDate(selectionRange[0].endDate)}`
        : "";
    return startDate + endDate;
  };

  getPrevDates = () => {
    const { prevDate } = this.props;
    const date = prevDate.length > 0 ? prevDate[0] : new Date();
    const initialDates = {
      startDate: date,
      endDate: prevDate.length > 1 ? prevDate[1] : date,
      key: "selection",
      color: this.props.theme.primary,
      showDateDisplay: false,
    };
    return initialDates;
  };

  checkIfMatchesHoliday = () => {
    const { selectionRange, suggestions } = this.state;
    const match = suggestions.find(
      (item) =>
        moment(item.range().startDate).format("DD-MM-YYYY") ===
        moment(selectionRange[0].startDate).format("DD-MM-YYYY") &&
        moment(item.range().endDate).format("DD-MM-YYYY") ===
        moment(selectionRange[0].endDate).format("DD-MM-YYYY")
    );
    return match;
  };

  handleScroll = () => {
    document.querySelector(".rdrInfiniteMonths").scroll({
      top: document.querySelector(".rdrInfiniteMonths").scrollTop + 36,
      behavior: "smooth",
    });
  };

  handleOpen = (e) => {
    if (this.state.open) {
      return;
    }
    this.setState(
      {
        open: true,
        firstSelection: false,
        title: this.props.departureLabel,
        anchorEl: e.currentTarget,
      },
      () => {
        this.scrollToDate(true);
      }
    );
  };

  scrollToDate = (delay) => {
    if (isMobile) return;
    setTimeout(
      () => {
        const currentMonth = moment().format("M");
        const startDateMonth = moment(
          this.state.selectionRange[0].startDate
        ).format("M");
        const diff = startDateMonth - currentMonth;
        if (!isMobile && diff === 1) {
          document.querySelector(".rdrInfiniteMonths").scroll({
            top: 200,
          });
        }
        this.handleScroll();
      },
      delay ? 100 : 10
    );
  };

  handleClose = () => {
    this.setState({
      open: false,
      anchorEl: null,
    });
  };

  handleCancel = () => {
    const initialDates = this.getPrevDates();
    this.setState({
      selectionRange: [initialDates],
      open: false,
      dateSelectedOnce: this.props.prevDate.length > 0,
      anchorEl: false,
    });
  };

  handleSelect = (selectionRange) => {
    const datesDifferent =
      this.props.onlyOneWay ||
      moment(selectionRange.selection.startDate).unix() !==
      moment(selectionRange.selection.endDate).unix();
    if (this.props.onlyOneWay) {
      selectionRange.selection.startDate =
        this.state.selectionRange[0].startDate !==
          selectionRange.selection.startDate
          ? selectionRange.selection.startDate
          : selectionRange.selection.endDate;
      selectionRange.selection.endDate = selectionRange.selection.startDate;
    }
    this.setState(
      {
        dateSelectedOnce: true,
        firstSelection: true,
        selectionRange: [selectionRange.selection],
        saveBtnText: datesDifferent
          ? this.props.okLabel
          : this.props.oneWayLabel,
      },
      () => {
        if (!datesDifferent) {
          this.scrollToDate();
        }
        this.handleIfCanSubmit();
      }
    );
  };

  handleIfCanSubmit = () => {
    const checkIfDateMatchesHoliday = this.checkIfMatchesHoliday();
    if (
      moment(this.state.selectionRange[0].startDate).unix() !==
      moment(this.state.selectionRange[0].endDate).unix() &&
      !checkIfDateMatchesHoliday
    ) {
      this.handleSubmit();
    }
    if (checkIfDateMatchesHoliday) {
      const holidays = checkIfDateMatchesHoliday.holidays.map((item) => ({
        startDate: item,
        endDate: item,
        key: "holiday",
        color: "#feb04c",
        showDateDisplay: false,
      }));
      this.setState({
        selectionRange: [...this.state.selectionRange, ...holidays],
      });
    }
  };

  handleSubmit = () => {
    this.props.handleDates(this.state.selectionRange[0]);
    this.handleClose();
  };

  handleKeyDown = (e) => {
    if (e.keyCode === 13 || (e.keyCode === 9 && this.state.open)) {
      if (this.props.id) {
        document.getElementById(this.props.id).blur();
      }
      e.preventDefault();
      this.state.open
        ? this.handleSubmit()
        : this.props.handleSearchFlight(false);
    }
  };

  render() {
    const {
      open,
      anchorEl,
      selectionRange,
      suggestions,
      saveBtnText,
      dateSelectedOnce,
      firstSelection,
    } = this.state;
    const {
      onlyOneWay,
      departureLabel,
      returnLabel,
      id,
      tripIndex,
      theme,
    } = this.props;
    const diffDates =
      moment(selectionRange[0].startDate).unix() !==
      moment(selectionRange[0].endDate).unix();
    const placeholder = (
      <span
        dangerouslySetInnerHTML={{
          __html: `${departureLabel} ${saveBtnText !== "Ok" && open && !onlyOneWay && firstSelection
            ? `<b style="color: ${theme.secondary}">- ${returnLabel}</b>`
            : onlyOneWay
              ? ""
              : diffDates
                ? "- " + returnLabel
                : ""
            }`,
        }}
      />
    );
    const minDate = this.props.getMinDate(tripIndex);
    const maxDate = this.props.getMaxDate(tripIndex);
    return (
      <Container isMobile={isMobile}>
        <Dates
          id={id}
          isMobile={isMobile}
          onClick={this.handleOpen}
          onFocus={this.handleOpen}
          hasDate={selectionRange.length > 0}
          style={
            selectionRange.length > 0 && dateSelectedOnce
              ? {}
              : { color: "rgba(0, 0, 0, 0.3)", position: "relative" }
          }
        >
          <Overlay />
          <Input
            label={placeholder}
            value={
              selectionRange.length > 0 && dateSelectedOnce
                ? this.getDates()
                : ""
            }
            hideClear
            icon={<DateIcon />}
            onKeyDown={this.handleKeyDown}
          />
          {!isMobile && (
            <StyledPopper
              open={open}
              anchorEl={anchorEl}
              placement="bottom-end"
              transition
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper>
                    {open && (
                      <ClickOutside onClickOutside={this.handleCancel}>
                        {suggestions.length > 0 && (
                          <HolidayLabel>
                            {getTranslation("holidayLabel")}
                          </HolidayLabel>
                        )}
                        <DateRangePicker
                          locale={this.getLocale()}
                          onChange={this.handleSelect}
                          staticRanges={suggestions}
                          inputRanges={[]}
                          minDate={minDate}
                          maxDate={maxDate}
                          direction="vertical"
                          scroll={{
                            enabled: true,
                          }}
                          ranges={selectionRange}
                          showPreview={!onlyOneWay}
                          showMonthAndYearPickers={false}
                          showMonthArrow={false}
                          months={1}
                          rangeColors={["red"]}
                          className={
                            suggestions.length === 0 ? "hideHolidays" : ""
                          }
                        />
                        <ActionButtons
                          saveBtnText={saveBtnText}
                          cancelLabel={this.props.cancelLabel}
                          handleCancel={this.handleCancel}
                          handleSubmit={this.handleSubmit}
                          fullWidth={suggestions.length === 0}
                        />
                      </ClickOutside>
                    )}
                  </Paper>
                </Fade>
              )}
            </StyledPopper>
          )}
        </Dates>
        {isMobile && (
          <Dialog
            open={open}
            TransitionComponent={Transition}
            onClose={this.handleCancel}
            fullScreen={isMobile}
            Transition
          >
            <DialogContent
              style={{
                padding: 0,
                display: "flex",
                flexFlow: "column",
                overflowY: "hidden",
              }}
            >
              <DateWrapper>
                <Dates style={{ position: "relative", width: "100%" }}>
                  <Overlay />
                  <Input
                    label={placeholder}
                    value={
                      selectionRange.length > 0 && dateSelectedOnce
                        ? this.getDates()
                        : ""
                    }
                    hideClear
                    icon={<DateIcon />}
                  />
                </Dates>
              </DateWrapper>
              {open && (
                <DateRangePicker
                  locale={this.getLocale()}
                  onChange={this.handleSelect}
                  staticRanges={suggestions}
                  inputRanges={[]}
                  minDate={minDate}
                  maxDate={maxDate}
                  direction="vertical"
                  scroll={{
                    enabled: true,
                  }}
                  ranges={selectionRange}
                  showPreview={!onlyOneWay}
                  showMonthAndYearPickers={false}
                  months={isMobile ? 3 : 1}
                  showMonthArrow={false}
                  rangeColors={["red"]}
                  className={suggestions.length === 0 ? "hideHolidays" : ""}
                />
              )}
            </DialogContent>
            <DialogActions>
              <CustomButton
                style={{ margin: "0 40px" }}
                color={theme.primary}
                onClick={this.handleSubmit}
              >
                {saveBtnText}
              </CustomButton>
            </DialogActions>
          </Dialog>
        )}
      </Container>
    );
  }
}

export default withTheme(NewDatePicker);
