import gql from 'graphql-tag';

export const generateEticket = gql`mutation generateEticket(
  $tripId: Int!
) {
  generateEticket(
    tripId: $tripId
  ) {
    eticket,
    hopRequests {
      id
      hopSeqNum,
      adultCount,
      childCount,
      infantCount,
      stdDate,
      fromAirportIc,
      toAirportIc,
      stopCount,
      hopSegmentControls {
        id,
        airlinePnr,
        bcUndefined,
        seatClass,
        cancellationPenalty,
        dateChangePenalty,
        seatChoice,
        baggagePieceCount,
        baggagePieceMaxWeight,
        baggagePieceImperial,
        flightSegments {
          airlineIc,
          flightNum,
          fromAirportIc,
          stdDate,
          stdTime,
          toAirportIc,
          staDate,
          staTime,
          aircraftIcao,
          durationMins,
          toTerminal,
          fromTerminal
        }
      },
      passengers {
        id,
        title,
        firstName,
        lastName,
        birthdate,
        gender,
        nationality,
        passportCountry,
        passportExpdate,
        passportNumber,
        cardHolder
        extraBaggages {
          id
          baggagePieceCount
          baggagePieceMaxWeight
        }
      }
    },
    tripCostItems {
        cost,
        costItemLabel,
        costItemCurrencyIso,
        costDecimalPlaces
      }
  }
}`;


export const xairportByIataLike = gql`query xairportByIataLike(
    $iata: String!,
  ) {
    xairportByIataLike(
      iata: $iata,
    ) {
      airportIc
      airportDesc
      city
      country
    }
  }
`;

export const xairlineByIc = gql`query xairlineByIc(
    $airlineIc: String!,
  ) {
    xairlineByIc(
      airlineIc: $airlineIc,
    ) {
      airlineDesc
    }
  }
`;
