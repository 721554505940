import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";
import config from "config";
import { getItem } from "utils/utils";

const httpLink = createHttpLink({
  uri: config.tipGraphqlUrl,
  headers: {
    //"x-hasura-admin-secret": "tipOct2019"
  }
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const tokenDetails = getItem("tokenDetails");
  let guestHeader = {};
  const hostname = window.location.hostname.split(".");
  if (!tokenDetails && hostname.length === 4) {
    guestHeader = {
      "X-Flystrait-Influencer-Site": hostname[0]
    };
  }
  // return the headers to the context so httpLink can read them
  const authHeader = tokenDetails
    ? { authorization: `Bearer ${tokenDetails.access_token}` }
    : {};
  return {
    headers: {
      ...headers,
      ...authHeader,
      ...guestHeader
    }
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

export default client;
