import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import FilledInput from '@material-ui/core/FilledInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import { MaleIcon, FemaleIcon } from 'components/common/SvgIcons';
import { Select } from 'components/common';
import { capitalizeFirstLetter } from 'utils/utils';
import getTranslation from 'utils/getTranslation';

const genders = ['MALE', 'FEMALE'];

export default ({
  value,
  handleChange,
  error
}) => (
  <Select
    label={getTranslation('gender')}
    value={value}
    handleChange={handleChange}
    menuItems={genders.map(gender => (
      <MenuItem value={gender} key={gender}>{capitalizeFirstLetter(gender.toLowerCase())}</MenuItem>
    ))
    }
    input={
      <FilledInput
        name="age"
        id="filled-age-simple"
        startAdornment={
          value && <InputAdornment position="start">
            {value === 'MALE' ? <MaleIcon /> : <FemaleIcon /> }
          </InputAdornment>
        }
      />}
    style={{ width: '48%' }}
    errorState={error}
  />
);
