import React from "react";
import styled from "styled-components";
import { withApollo, ApolloProvider } from "react-apollo";
import { Loader, HeaderTags } from "components/common";
import { getPostById } from "containers/Influencer/queries";
import BlogPost from "./BlogPost";
import QuickPost from "./QuickPost";
import ItnineraryPost from "./ItnineraryPost";
import config from "config";
import apolloClient from "apolloClient";

const Wrapper = styled.div`
  margin-top: 25px;
`;

class Post extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      postId: props.match.params.id,
      loading: true,
      blogData: null
    };
  }
  async componentWillMount() {
    try {
      const response = await this.props.client.query({
        query: getPostById,
        variables: {
          id: parseInt(this.state.postId, 10)
        }
      });
      this.setState(
        {
          loading: false,
          blogData: response.data.post_by_pk
        },
        () => {
          window.gtag("config", config.gaId, {
            custom_map: { dimension1: "influencerId" }
          });
          window.gtag("event", "page_view", {
            influencerId: this.props.influencerDetails.id
          });
        }
      );
    } catch (err) {
      console.log(err);
      this.setState({
        loading: false,
        error: true
      });
    }
  }
  render() {
    const { blogData, loading } = this.state;
    const { influencerDetails } = this.props;
    return (
      <Wrapper>
        {loading && <Loader color={influencerDetails.primary_colour} />}
        {blogData && (
          <HeaderTags
            articleData={{
              title: blogData.title,
              imageUrl: blogData.coverimage || blogData.coverimage1,
              url: window.location.href
            }}
          />
        )}
        {blogData && blogData.type === "QUICK" && (
          <QuickPost
            blogData={blogData}
            color={influencerDetails.primary_colour}
          />
        )}
        <ApolloProvider client={apolloClient}>
          {blogData && blogData.type === "BLOG" && (
            <BlogPost
              blogData={blogData}
              color={influencerDetails.primary_colour}
            />
          )}
          {blogData && blogData.type === "ITINERARY" && (
            <ItnineraryPost
              blogData={blogData}
              color={influencerDetails.primary_colour}
            />
          )}
        </ApolloProvider>
      </Wrapper>
    );
  }
}

export default withApollo(Post);
