import React from "react";
import { connect } from "react-redux";
import { graphql, compose } from "react-apollo";
import { withApollo } from "react-apollo";
import moment from "moment";
import { Mask, Steps } from "components/Influencer";
import { Wrapper } from "containers/Influencer/Setup/Styles";
import CoverSelectionSteps from "components/Influencer/Partials/CoverSelectionSteps";
import { Loader } from "components/common";
import { getPostById, updatePost } from "containers/Influencer/queries";
import getTranslation from "utils/getTranslation";

class Step4 extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      postId: props.match.params.postId,
      loading: true,
      blogData: null,
      selectedLayout: "1"
    };
  }
  async componentWillMount() {
    try {
      const response = await this.props.client.query({
        query: getPostById,
        fetchPolicy: "no-cache",
        variables: {
          id: parseInt(this.state.postId, 10)
        }
      });
      this.setState({
        loading: false,
        blogData: response.data.post_by_pk
      });
    } catch (err) {
      this.setState({
        loading: false,
        error: true
      });
    }
  }
  handleBack = () => {
    this.props.history.push("/influencer/setup/step3");
  };

  handleSubmit = () => {
    this.props.history.push("/influencer/setup/share/" + this.state.postId);
  };
  render() {
    const { loading, blogData } = this.state;
    const { influencerDetails } = this.props;
    return (
      <Wrapper>
        <Mask style={{ height: 52 }} />
        <h5 style={{ textAlign: "center", paddingTop: 10 }}>
          {getTranslation("tipRegistration")}
        </h5>
        <Steps activeStep={4} />
        <div className="container">
          {loading && <Loader />}
          {blogData && (
            <CoverSelectionSteps
              blogData={blogData}
              influencerDetails={influencerDetails}
              handleSubmit={this.handleSubmit}
            />
          )}
        </div>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  influencerDetails: state.influencer.influencerDetails
});

export default withApollo(
  compose(
    graphql(updatePost, { name: "updatePost" }),
    connect(mapStateToProps)
  )(Step4)
);
