export const INCREASE_TOTAL_PASSENGERS = 'INCREASE_TOTAL_PASSENGERS';
export const DECREASE_TOTAL_PASSENGERS = 'DECREASE_TOTAL_PASSENGERS';
export const ADD_PASSENGER_TO_STACK = 'ADD_PASSENGER_TO_STACK';
export const REMOVE_PASSENGER_FROM_STACK = 'REMOVE_PASSENGER_FROM_STACK';
export const UPDATE_PAYMENT_RECEIVE = 'UPDATE_PAYMENT_RECEIVE';
export const UPDATE_PNR_RECEIVE = 'UPDATE_PNR_RECEIVE';
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
export const HOP_SEG_BUTTON_ERROR = 'HOP_SEG_BUTTON_ERROR';
export const UNIQUE_FS_CODE = 'UNIQUE_FS_CODE';
export const REMOVE_UNIQUE_FS_CODE = 'REMOVE_UNIQUE_FS_CODE';
export const UPDATE_COUNTRY = 'UPDATE_COUNTRY';
export const DISABLE_PASSENGER_STICKY = 'DISABLE_PASSENGER_STICKY';
export const ENABLE_PASSENGER_STICKY = 'ENABLE_PASSENGER_STICKY';
export const UPDATE_TIME = 'UPDATE_TIME';

export function UpdatePnrReceive() {
  return {
    type: UPDATE_PNR_RECEIVE,
  };
}

export function updateUserDate(payload) {
  return {
    type: UPDATE_USER_DATA,
    payload,
  };
}

export function UpdatePaymentReceive(trnsId) {
  return {
    type: UPDATE_USER_DATA,
    payload: trnsId,
  };
}

export function HopSegButtonError(payload) {
  return {
    type: HOP_SEG_BUTTON_ERROR,
    payload,
  };
}

export function UniqueFsCode() {
  return {
    type: UNIQUE_FS_CODE,
  };
}

export function RemoveUniqueFsCode() {
  return {
    type: REMOVE_UNIQUE_FS_CODE,
  };
}

export function updateCountry(country) {
  return {
    type: UPDATE_COUNTRY,
    payload: country,
  };
}

export function disablePassengerSticky() {
  return {
    type: DISABLE_PASSENGER_STICKY,
  };
}

export function enablePassengerSticky() {
  return {
    type: ENABLE_PASSENGER_STICKY,
  };
}

export function updateTime() {
  return {
    type: UPDATE_TIME,
  }
}
