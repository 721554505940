import React from "react";
import styled from "styled-components";
import { ItemTitle, CreateNew } from "components/Influencer";
import { Sections, FilterSection } from "containers/Influencer/Setup/Styles";
import { connect } from "react-redux";
import getTranslation from "utils/getTranslation";
import SortIcon from "assets/icons/Sorting-Down.svg";

const Wrapper = styled.div`
  width: 100%;
  .domainWrapper {
    width: 296px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #d0f3fc;
    border-radius: 10em;
    font-size: 14px;
    span {
      margin-left: 5px;
      color: #007ac7;
    }
  }
  .section {
    margin-top: 30px;
    width: 100%;
    .title {
      margin-bottom: 0;
    }
    .postsWrapper {
      display: flex;
      margin-top: 30px;
    }
  }
`;

class Posts extends React.PureComponent {
  render() {
    const { influencerDetails } = this.props;
    return (
      <Wrapper>
        <Sections>
          <div className="section">
            <ItemTitle title={getTranslation("mediaLibrary")} />
            <FilterSection>
              <div className="tabs">
                <div className="tab">{getTranslation("all")}</div>
                <div className="tab">{getTranslation("images")}</div>
                <div className="tab">{getTranslation("video")}</div>
                <div className="tab">{getTranslation("audio")}</div>
              </div>
              <div className="sorting">
                <div>
                  Sort By:{" "}
                  <div className="sortItem">
                    {getTranslation("locationAtoZ")}
                  </div>
                  <div className="sortItem active">
                    {getTranslation("datePublished")}
                  </div>
                  <img src={SortIcon} alt="" />
                </div>
              </div>
            </FilterSection>
            <div className="postsWrapper">
              <CreateNew text={getTranslation("addNewMedia")} />
            </div>
          </div>
        </Sections>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  influencerDetails: state.influencer.influencerDetails
});

export default connect(mapStateToProps)(Posts);
