export default {
  v: "5.6.5",
  fr: 30,
  ip: 114,
  op: 158,
  w: 360,
  h: 360,
  nm: "Benefit-1",
  ddd: 0,
  assets: [
    {
      id: "image_0",
      w: 44,
      h: 100,
      u: "/static/ae-images/Benefit-1/images/",
      p: "img_0.svg",
      e: 0
    },
    {
      id: "image_1",
      w: 124,
      h: 182,
      u: "/static/ae-images/Benefit-1/images/",
      p: "img_1.svg",
      e: 0
    },
    {
      id: "image_2",
      w: 163,
      h: 192,
      u: "/static/ae-images/Benefit-1/images/",
      p: "img_2.svg",
      e: 0
    },
    {
      id: "image_3",
      w: 196,
      h: 196,
      u: "/static/ae-images/Benefit-1/images/",
      p: "img_3.svg",
      e: 0
    }
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 2,
      nm: "point.png",
      cl: "svg",
      refId: "image_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [238.5, 157.01, 0], ix: 2 },
        a: { a: 0, k: [22, 50, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
              t: 123,
              s: [100, 100, 100]
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 125,
              s: [0, 0, 100]
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 133,
              s: [100, 100, 100]
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 136,
              s: [70, 70, 100]
            },
            { t: 139, s: [100, 100, 100] }
          ],
          ix: 6
        }
      },
      ao: 0,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 2,
      nm: "man (1).png",
      cl: "svg",
      refId: "image_1",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [128.352, 225.834, 0], ix: 2 },
        a: { a: 0, k: [62, 91, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 2,
      nm: "tablet.png",
      cl: "svg",
      refId: "image_2",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [231.5, 156.76, 0], ix: 2 },
        a: { a: 0, k: [81.5, 96, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 450,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 4,
      ty: 2,
      nm: "bg.png",
      cl: "svg",
      refId: "image_3",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [131.5, 176.51, 0], ix: 2 },
        a: { a: 0, k: [98, 98, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ef: [
        {
          ty: 5,
          nm: "CC Line Sweep",
          np: 7,
          mn: "CS LineSweep",
          ix: 1,
          en: 1,
          ef: [
            {
              ty: 0,
              nm: "Completion",
              mn: "CS LineSweep-0001",
              ix: 1,
              v: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.167], y: [0] },
                    t: 122,
                    s: [0]
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 125,
                    s: [99]
                  },
                  { t: 136, s: [0] }
                ],
                ix: 1
              }
            },
            {
              ty: 0,
              nm: "Direction",
              mn: "CS LineSweep-0002",
              ix: 2,
              v: { a: 0, k: 358, ix: 2 }
            },
            {
              ty: 0,
              nm: "Thickness",
              mn: "CS LineSweep-0003",
              ix: 3,
              v: { a: 0, k: 50, ix: 3 }
            },
            {
              ty: 0,
              nm: "Slant",
              mn: "CS LineSweep-0004",
              ix: 4,
              v: { a: 0, k: 99, ix: 4 }
            },
            {
              ty: 7,
              nm: "Flip Direction",
              mn: "CS LineSweep-0005",
              ix: 5,
              v: { a: 0, k: 0, ix: 5 }
            }
          ]
        }
      ],
      ip: 0,
      op: 450,
      st: 0,
      bm: 0
    }
  ],
  markers: []
};
