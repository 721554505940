import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: #fff;
  position: relative;
  min-height: 100vh;
  h5 {
    font-size: 16px;
    text-transform: uppercase;
    font-family: "Roboto Condensed", sans-serif;
    color: #777777;
    line-height: 19px;
    letter-spacing: 0.3px;
    font-weight: 400;
    margin-top: 0;
  }
  h2 {
    font-size: 22px;
    font-weight: 400;
    color: #777777;
  }
  .container {
    width: 1094px;
    margin: auto;
    @media (max-width: 800px) {
      width: 100%;
    }
  }
  .titleWithEdit {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const Sections = styled.div`
  display: flex;
  position: relative;
  .section {
    width: 296px;
    display: flex;
    flex-flow: column;
    .text {
      font-size: 13px;
      color: #777777;
      line-height: 140%;
      a {
        color: #007ac7;
      }
    }
  }
`;

export const FilterSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  @media (max-width: 800px) {
    flex-flow: column;
    align-items: flex-start;
  }
  .tabs {
    display: flex;
    .tab {
      cursor: pointer;
      min-width: 52px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      margin-right: 10px;
      font-size: 14px;
      font-family: "Roboto Condensed", sans-serif;
      color: #777777;
      padding: 12px;
      svg {
        width: 22px;
        margin-right: 5px;
        path {
          fill: #56ccf2;
        }
      }
      &.active {
        background-color: #d0f3fc;
        color: #007ac7;
        font-weight: 600;
        box-shadow: inset 0px 2px 0px #56ccf2;
        svg {
          path {
            fill: #007ac7;
          }
        }
      }
    }
  }
  .sorting {
    font-family: "Roboto Condensed", sans-serif;
    font-size: 12px;
    color: #777777;
    @media (max-width: 800px) {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }
    div {
      display: flex;
      align-items: center;
      .sortItem {
        margin-left: 10px;
        color: #007ac7;
        line-height: 14px;
        position: relative;
        text-transform: capitalize;
        &.active {
          font-weight: 600;
          &:before {
            content: "";
            position: absolute;
            bottom: -2px;
            left: 0;
            height: 1px;
            width: 100%;
            background-color: #56ccf2;
          }
        }
      }
    }
  }
`;
