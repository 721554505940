import React, { Component } from "react";
import styled, { withTheme } from "styled-components";
import { Input, Button } from "components/common";


import ChildAge from "./childAge";
import FormFields from "components/HopRequest/FormFields";

import FilledInput from "@material-ui/core/FilledInput";

const InputBtnContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

class AddChildrenAge extends Component {
    constructor(props) {
        super(props);
        this.state = { childrenAges: this.props.childrenAges, errors: {}, openSelect: false };
    }

    handleAddNewChildAge = (age) => {
        let { childrenAges } = this.state;
        childrenAges.push(age);
        console.log('childrenAges before sending across to props ' + childrenAges);
        this.setState({
            childrenAges
        });
        // document.getElementById('childAgeTextInput').focus();
        this.props.updateChildrenCount(childrenAges);
    }
    handleDelete = index => {
        let { childrenAges } = this.state;
        childrenAges = childrenAges.filter((ca, idx) => idx != index);
        this.setState({ childrenAges });
        this.props.updateChildrenCount(childrenAges);
    };
    render() {
        let { childrenAges, errors } = this.state;
        console.log('childrenAges ' + childrenAges);
        return (
            <div style={{ background: "#fff", position: "absolute", display: "flex", borderRadius: 4, height: 104, padding: "12px 12px 12px 0px", marginTop: 1 }}>
                <>
                    {childrenAges.map((childAge, index) =>
                        <ChildAge
                            type={(childAge < 2 ? "infant" : "child")}
                            childAge={childAge}
                            index={index}
                            handleDelete={this.handleDelete} />
                    )}
                    <ChildAge isPlaceholder handleAddNewChildAge={this.handleAddNewChildAge} />
                </>
            </div>
        )
    }
}

export default AddChildrenAge;