import gql from 'graphql-tag';

export const addTripPassengerQuery = gql`mutation addTrippassenger(
    $firstName: String!
    $lastName: String!
    $birthdate: String!
    $gender: Gender!
    $nationality: String
    $passportCountry: String
    $passportExpdate: String
    $passportNumber: String
    $cardHolder: Boolean!
    $ffNumber: String
    $mealCode: String
    $extrabaggageIds: [Int!]
    $title: String!
    $cardHoldername: String
    $cardHoldercountryic: String
    $tripId: Int!
  ) {
    addTrippassenger(
      firstName: $firstName
      lastName: $lastName
      birthdate: $birthdate
      gender: $gender
      nationality: $nationality
      passportCountry: $passportCountry
      passportExpdate: $passportExpdate
      passportNumber: $passportNumber
      cardHolder: $cardHolder
      ffNumber: $ffNumber
      mealCode: $mealCode
      extrabaggageIds: $extrabaggageIds
      title: $title
      cardHoldername: $cardHoldername
      cardHoldercountryic: $cardHoldercountryic
      tripId: $tripId
    ) {
      id
      hoprequestId
      title
      firstName
      lastName
      birthdate
      gender
      nationality
      passportCountry
      passportExpdate
      passportNumber
      cardHolder
      cardHoldername
      cardHoldercountryic
      extraBaggages {
        id
        baggagePieceCount
        baggagePieceMaxWeight
      }
    }
  }
`;

export const updPassengerQuery = gql`mutation updPassenger(
    $passengerId: Int!
    $firstName: String!
    $lastName: String!
    $birthdate: String!
    $gender: Gender!
    $nationality: String
    $passportCountry: String
    $passportExpdate: String
    $passportNumber: String
    $cardHolder: Boolean!
    $ffNumber: String
    $mealCode: String
    $cardHoldername: String
    $cardHoldercountryic: String
    $tripId: Int!
  ) {
    updPassenger(
      passengerId: $passengerId
      firstName: $firstName
      lastName: $lastName
      birthdate: $birthdate
      gender: $gender
      nationality: $nationality
      passportCountry: $passportCountry
      passportExpdate: $passportExpdate
      passportNumber: $passportNumber
      cardHolder: $cardHolder
      ffNumber: $ffNumber
      mealCode: $mealCode
      cardHoldername: $cardHoldername
      cardHoldercountryic: $cardHoldercountryic
      tripId: $tripId
    ) {
      id
      hoprequestId
      title
      firstName
      lastName
      birthdate
      gender
      nationality
      passportCountry
      passportExpdate
      passportNumber
      cardHolder
      cardHoldername
      cardHoldercountryic
      extraBaggages {
        id
        baggagePieceCount
        baggagePieceMaxWeight
      }
    }
  }
`;

export const delTripPassenger = gql`mutation delTrippassenger(
      $tripId: Int!
      $firstName: String!
      $lastName: String!
      $birthdate: String!
      $gender: Gender!
    ) {
      delTrippassenger(
        tripId: $tripId
        firstName: $firstName
        lastName: $lastName
        gender: $gender
        birthdate: $birthdate
      ) {
        id
      }
    }
`;

export const updAdpasiQuery = gql`mutation extraCheck(
  $tripId: Int!
) {
  extraCheck(tripId: $tripId) {
    id,
    adpasi,
    priceNum,
    passportDetails
    hopRequests {
      id
      hopSeqNum,
      adultCount,
      childCount,
      infantCount,
      stdDate,
      fromAirportIc,
      toAirportIc,
      stopCount,
      hopSegmentControls {
        id,
        fscode,
        paxnameCharLimit,
        bcUndefined,
        airlinePnr,
        seatClass,
        cancellationPenalty,
        dateChangePenalty,
        seatChoice,
        baggagePieceCount,
        baggagePieceMaxWeight,
        baggagePieceImperial,
        webfare,
        costDecimalPlaces
        costCurrencyIso
        transferValue
        refundValue
        priceBreakups {
          id
          cost
          costCurrencyIso
          costDecimalPlaces
          passengerTyp
          isTax
          taxCode
          taxName
        }
        extraBaggages {
          id
          createdDttm
          modifiedDttm
          inbound
          baggagePieceCount
          baggagePieceMaxWeight
          baggagePieceImperial
          serviceId
          serviceCostAmount
          serviceCostCurrencyIso
          costDecimalPlaces
          quantity
          hopsegmentcontrolId
          passengerNum
          passengerId
        }
        farerules {
          id,
          category,
          detail
        }
        flightSegments {
          airlineIc,
          flightNum,
          fromAirportIc,
          stdDate,
          stdTime,
          toAirportIc,
          staDate,
          staTime,
          aircraftIcao,
          durationMins
        }
      },
      passengers {
        id,
        title,
        firstName,
        lastName,
        birthdate,
        gender,
        nationality,
        passportCountry,
        passportExpdate,
        passportNumber,
        cardHolder,
        cardHoldername
        cardHoldercountryic
        extraBaggages {
          id
          baggagePieceCount
          baggagePieceMaxWeight
        }
      }
    },
    tripCostItems {
        cost,
        costItemLabel,
        costItemCurrencyIso,
        costDecimalPlaces
      }
  }
}`;

export const xmealByTripId = gql`query xmealByTripId(
  $tripId: Int!
) {
  xmealByTripId(tripId: $tripId) {
    mealCode
    mealName
  }
}`;

export const updGuestTripowner = gql`mutation updGuestTripowner(
      $tripId: Int!
      $guestEmail: String!
    ) {
      updGuestTripowner(
        tripId: $tripId
        guestEmail: $guestEmail
      ) {
        id
      }
    }
`;

export const readyToPay = gql`mutation readyToPay(
  $tripId: Int!
) {
  readyToPay(tripId: $tripId) {
    id,
    priceNum,
  }
}`;
