import React from "react";
import styled from "styled-components";
import { Sections } from "containers/Influencer/Setup/Styles";
import { ItemTitle } from "components/Influencer";
import SocialNetworkItem from "./SocialNetworkItem";
import getTranslation from "utils/getTranslation";
import { socialNetworks } from "utils/utils";

const Wrapper = styled.div`
  @media (max-width: 800px) {
    padding: 0 16px;
  }
  .section {
    width: 536px;
  }
  .socialNetworks {
    margin-bottom: 50px;
  }
`;

export default class extends React.PureComponent {
  getConnectDetails = network => {
    return this.props.influencerConnects.find(
      connect => connect.connect === network.toUpperCase()
    );
  };
  render() {
    const { dashboard, handleInsertConnect, handleDeleteConnect } = this.props;
    return (
      <Wrapper>
        {!dashboard && <h2>{getTranslation("connectSN")}</h2>}
        <Sections>
          <div className="section">
            <ItemTitle title={getTranslation("addSNaccount")} />
            <div className="text" style={{ marginBottom: 20 }}>
              <span>{getTranslation("addTIPchannels")} </span>
              <a href="google.com">{getTranslation("learnMore")}</a>
              <br />
              <br />
              <span>{getTranslation("arrangeChannels")}</span>
            </div>
            <div className="socialNetworks">
              {socialNetworks.map(network => (
                <SocialNetworkItem
                  dashboard={dashboard}
                  connectDetails={this.getConnectDetails(network.name)}
                  handleInsertConnect={handleInsertConnect}
                  handleDeleteConnect={handleDeleteConnect}
                  network={network}
                  key={network.name}
                />
              ))}
            </div>
          </div>
        </Sections>
      </Wrapper>
    );
  }
}
