
export const SET_FILTERS = 'SET_FILTERS';
export const ADD_FILTER = 'ADD_FILTER';
export const DELETE_FILTER = 'DELETE_FILTER';

export function setFilters(data) {
  return {
    type: SET_FILTERS,
    payload: data,
  };
}

export function addFilter(data) {
  return {
    type: ADD_FILTER,
    payload: data,
  };
}

export function removeFilter(data) {
  return {
    type: DELETE_FILTER,
    payload: data,
  };
}
