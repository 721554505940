import React from "react";
import styled from "styled-components";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Button } from "components/common";
import { AddIcon } from "components/common/SvgIcons";
import UploaderAdapter from "./UploaderAdapter";
import TransportModal from "./TransportModal";
import TransportDetails from "./TransportDetails";
import getTranslation from "utils/getTranslation";
import { inWords } from "utils/utils";
import deleteIcon from "assets/tip/Delete.svg";

const Wrapper = styled.div`
  margin-bottom: 20px;
  .ck-editor__editable {
    min-height: 100px;
  }
  .dayTitle {
    width: 100%;
    padding: 10px;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    border: 1px solid #dddddd;
    margin: auto;
    margin-bottom: 20px;
    @media (max-width: 800px) {
      width: 100%;
    }
    span {
      font-size: 16px;
      font-family: "Roboto Condensed", sans-serif;
      text-transform: uppercase;
      color: #777777;
      font-weight: 600;
    }
    .remove {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;
      position: absolute;
      right: 16px;
      span {
        color: #eb5757;
      }
    }
    input {
      margin-top: 10px;
      border: 0;
      font-size: 28px;
      color: #444444;
      width: 100%;
      outline: none;
    }
  }
  .btnWrapper {
    width: 296px !important;
    margin-top: 40px;
  }
`;

const editorConfig = {
  image: {
    // You need to configure the image toolbar, too, so it uses the new style buttons.
    toolbar: [
      "imageStyle:alignLeft",
      "imageStyle:full",
      "imageStyle:alignRight"
    ],

    styles: [
      // This option is equal to a situation where no style is applied.
      "full",

      // This represents an image aligned to the left.
      "alignLeft",

      // This represents an image aligned to the right.
      "alignRight"
    ]
  }
};

export default class extends React.PureComponent {
  state = { openModal: false };
  handleChange = e => {
    this.props.handleChange(
      "title",
      e.target.value,
      parseInt(this.props.dayNumber - 1)
    );
  };

  handleToggle = () => {
    this.setState({
      openModal: !this.state.openModal,
      transportData: null
    });
  };

  handleTransportEdit = (transportData, index) => {
    this.setState({
      openModal: true,
      transportData,
      transportIndex: index
    });
  };

  handleTransportSubmit = (transport, isEdit) => {
    if (isEdit) {
      this.props.handleChange(
        "itinerarydaytransports",
        transport,
        parseInt(this.props.dayNumber - 1),
        this.state.transportIndex
      );
    } else {
      this.props.handleChange(
        "itinerarydaytransports",
        transport,
        parseInt(this.props.dayNumber - 1)
      );
    }
    this.handleToggle();
  };
  render() {
    const { openModal, transportData } = this.state;
    const {
      data,
      dayNumber,
      selectedDayIndex,
      handleRemoveTransport,
      handleRemove
    } = this.props;
    return (
      <Wrapper>
        {openModal && (
          <TransportModal
            open={openModal}
            transportData={transportData}
            handleCloseModal={this.handleToggle}
            handleSubmit={this.handleTransportSubmit}
          />
        )}
        <div className="dayTitle">
          <span>Day {inWords(dayNumber)}</span>
          {selectedDayIndex > 0 && (
            <div className="remove" onClick={handleRemove}>
              <span>{getTranslation("removeDay")}</span>
              <img src={deleteIcon} alt="" />
            </div>
          )}
          <input
            placeholder="Title"
            onChange={this.handleChange}
            value={data.title}
          />
        </div>
        <div>
          <CKEditor
            editor={ClassicEditor}
            config={editorConfig}
            data={data.text}
            onInit={editor => {
              // You can store the "editor" and use when it is needed.
              // console.log("Editor is ready to use!", editor);
              // console.log(Array.from(editor.ui.componentFactory.names()));
              editor.plugins.get(
                "FileRepository"
              ).createUploadAdapter = loader => {
                return new UploaderAdapter(loader);
              };
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              // console.log({ event, editor, data });
              this.props.handleChange(
                "text",
                data,
                parseInt(this.props.dayNumber - 1)
              );
            }}
            onBlur={(event, editor) => {
              //console.log("Blur.", editor);
            }}
            onFocus={(event, editor) => {
              //console.log("Focus.", editor);
            }}
          />
        </div>
        <div className="transportsWrapper">
          {data.itinerarydaytransports.data.map((item, i) => (
            <TransportDetails
              data={item}
              index={i}
              key={i}
              handleEdit={this.handleTransportEdit}
              handleRemoveTransport={handleRemoveTransport}
            />
          ))}
        </div>
        <div className="btnWrapper">
          <Button
            onClick={this.handleToggle}
            color="#D0F3FC"
            style={{ color: "#007AC7" }}
          >
            <AddIcon stroke="#007AC7" />
            <span>{getTranslation("addTravel")}</span>
          </Button>
        </div>
      </Wrapper>
    );
  }
}
