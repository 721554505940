import React from "react";
import styled from "styled-components";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ItemTitle } from "components/Influencer";
import { Input } from "components/common";
import getTranslation from "utils/getTranslation";
import UploaderAdapter from "./UploaderAdapter";

const Wrapper = styled.div`
  margin-top: 50px;
  .MuiFormControl-marginNormal {
    margin-top: 0;
  }
`;

const editorConfig = {
  image: {
    // You need to configure the image toolbar, too, so it uses the new style buttons.
    toolbar: [
      "imageStyle:alignLeft",
      "imageStyle:full",
      "imageStyle:alignRight"
    ],

    styles: [
      // This option is equal to a situation where no style is applied.
      "full",

      // This represents an image aligned to the left.
      "alignLeft",

      // This represents an image aligned to the right.
      "alignRight"
    ]
  }
};

export default class extends React.PureComponent {
  handleChange = e => {
    const { name, value } = e.target;
    this.props.handleChange(name, value);
  };
  render() {
    const { html, title } = this.props;
    return (
      <Wrapper className="section">
        <ItemTitle title={getTranslation("addBlogTitle")} />
        <div style={{ marginBottom: 20 }}>
          <Input
            label={getTranslation("blogTitle")}
            fullWidth
            value={title}
            name="title"
            onChange={this.handleChange}
            hideClear
          />
        </div>
        <ItemTitle title={getTranslation("addContent")} />
        <CKEditor
          editor={ClassicEditor}
          config={editorConfig}
          data={html}
          onInit={editor => {
            // You can store the "editor" and use when it is needed.
            editor.plugins.get(
              "FileRepository"
            ).createUploadAdapter = loader => {
              return new UploaderAdapter(loader);
            };
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            // console.log({ event, editor, data });
            this.props.handleEditorChange(data);
          }}
          onBlur={(event, editor) => {}}
          onFocus={(event, editor) => {}}
        />
      </Wrapper>
    );
  }
}
