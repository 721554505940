import React from "react";
import styled from "styled-components";
import { graphql, compose } from "react-apollo";
import { connect } from "react-redux";
import parser from "react-html-parser";
import { isMobile } from "react-device-detect";
import moment from "moment";
import {
  deleteConnectPost,
  updateConnectPost
} from "containers/Influencer/queries";
import { getRecurrences } from "./helpers";
import ShareModal from "components/Influencer/Partials/ShareModal";
import { SocialNetworksPosted } from "components/Influencer/Partials";
import DestinationLabels from "components/Influencer/BlogCards/DestinationLabels";
import { EditIcon } from "components/common/SvgIcons";
import PostDetails from "./PostDetails";
import playIcon from "assets/tip/Play.svg";

const Wrapper = styled.div`
  width: 100%;
  padding: 12px;
  background-color: #fff;
  border: 1px solid #dddddd;
  position: relative;
  margin-bottom: 20px;
  .card {
    display: flex;
    width: 100%;
    @media (max-width: 800px) {
      flex-flow: column;
    }
  }
  .leftSection {
    display: flex;
    flex-flow: column;
    width: 50%;
    @media (max-width: 800px) {
      width: 100%;
    }
    textarea {
      padding: 16px;
      margin-bottom: 20px;
      font-size: 16px;
      background-color: #f0f0f0;
      box-shadow: 0px 0px 2px rgba(255, 255, 255, 0.16);
      border-radius: 4px;
      border: 0;
      resize: none;
    }
  }
  .metaSection {
    width: 50%;
    display: flex;
    flex-flow: column;
    padding-left: 20px;
    @media (max-width: 800px) {
      width: 100%;
      padding-left: 0;
    }
  }
  .recurrence {
    display: flex;
    margin-top: 16px;
    .item {
      margin-right: 23px;
      display: flex;
      flex-flow: column;
      width: 82px;
      .title {
        font-family: "Roboto Condensed", sans-serif;
        font-size: 16px;
        color: #777777;
      }
      .line {
        width: 90%;
        background-color: #dddddd;
        margin: 7px 0;
        height: 1px;
      }
      .value {
        color: #222222;
        font-size: 14px;
      }
    }
  }
  .buttons {
    position: absolute;
    right: 0;
    background-color: #f0f0f0;
    box-shadow: -1px 0px 1px rgba(0, 0, 0, 0.12);
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    @media (max-width: 800px) {
      top: 85px;
    }
    div {
      cursor: pointer;
      padding: 12px;
      display: flex;
      align-items: center;
      &:first-child {
        position: relative;
        &:before {
          content: "";
          position: absolute;
          background-color: #dddddd;
          height: 1px;
          width: 20px;
          bottom: 0;
        }
      }
    }
  }
`;

class RecurrenceCard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentWillMount() {
    parser(this.props.connectPost.post.text, {
      transform: this.cleanArticle
    });
  }
  cleanArticle = (node, index) => {
    if (node.type === "text" && !this.state.blogText) {
      this.setState({
        blogText: node.data
      });
    }
  };
  handleRepeatToggle = () => {
    this.setState({ repeatModalOpen: !this.state.repeatModalOpen });
  };

  handleShareNow = async () => {
    const { post } = this.props.connectPost;
    try {
      await this.props.updateConnectPost({
        variables: {
          _set: {
            shareschedule_at: moment().format()
          },
          where: {
            post_id: { _eq: post.post_id },
            shareschedule_at: { _eq: post.shareschedule_at }
          }
        }
      });
    } catch (err) {
      //
    }
  };
  render() {
    const { repeatModalOpen, blogText } = this.state;
    const { connectPost, connectPosts } = this.props;
    const { post, recurpattern, sharenote } = connectPost;
    const recurrences = getRecurrences(connectPost, connectPosts);
    return (
      <Wrapper>
        <ShareModal
          open={repeatModalOpen}
          blogData={post}
          connectPost={connectPost}
          handleCloseModal={this.handleRepeatToggle}
          handleSubmit={this.handleRepeatToggle}
          isEdit
        />
        <div className="buttons">
          <div onClick={this.handleRepeatToggle}>
            <EditIcon stroke="#007AC7" style={{ height: 18, width: 18 }} />
          </div>
          <div onClick={this.handleShareNow}>
            <img src={playIcon} alt="" />
          </div>
        </div>
        <div className="card">
          <div className="leftSection">
            <textarea value={sharenote} disabled />
            {!isMobile && <PostDetails post={post} blogText={blogText} />}
          </div>
          <div className="metaSection">
            <SocialNetworksPosted
              style={{ backgroundColor: "#F0F0F0" }}
              connects={connectPost.connects}
            />
            {post.postdestinations.length > 0 && (
              <div style={{ position: "relative", height: 23, marginTop: 40 }}>
                <DestinationLabels
                  blogData={post}
                  backgroundColor="rgba(63, 140, 82, 0.12)"
                />
              </div>
            )}
            {recurrences && (
              <div className="recurrence">
                <div className="item">
                  <span className="title">Repeat every</span>
                  <div className="line" />
                  <span className="value">
                    {recurpattern.recur_frequency}{" "}
                    {recurpattern.recur_type.toLowerCase()}s
                  </span>
                </div>
                <div className="item">
                  <span className="title">Remaining</span>
                  <div className="line" />
                  <span className="value">{recurrences.length} times</span>
                </div>
                <div className="item" style={{ width: 141 }}>
                  <span className="title">Next</span>
                  <div className="line" style={{ width: 71 }} />
                  <span className="value" style={{ fontWeight: 600 }}>
                    {moment(recurrences[0]).format("MMM DD, YYYY HH:mm")}
                  </span>
                </div>
              </div>
            )}
            {isMobile && <PostDetails post={post} blogText={blogText} />}
          </div>
        </div>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  connectPosts: state.influencer.connectPosts
});

export default compose(
  graphql(deleteConnectPost, { name: "deleteConnectPost" }),
  graphql(updateConnectPost, { name: "updateConnectPost" }),
  connect(mapStateToProps)
)(RecurrenceCard);
