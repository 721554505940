import React from 'react';
import { Query } from 'react-apollo';
import { xairportByIataLike } from '../queries';

export default ({
  airportIc,
}) => (
  <Query query={xairportByIataLike} variables={{ iata: `${airportIc}%` }}>
    {({ data }) => (
      data.xairportByIataLike ?
        <span>
          {data.xairportByIataLike[0].airportDesc} {(airportIc)}
        </span>
       : null
    )}
  </Query>
);
