import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import {
  IconQueue,
  IconRecentPost,
  IconChannelSetup,
  IconRecurrence,
  DayIcon
} from "components/common/SvgIcons";
import { Wrapper } from "containers/Influencer/Setup/Styles";
import { setConnectPosts, setAllConnectPosts } from "redux-store/actions";
import { getInfluencerConnectPosts } from "containers/Influencer/queries";
import {
  RecentPosts,
  Queue,
  Setup,
  Recurrences,
  CalendarView
} from "./Partials";
import getTranslation from "utils/getTranslation";
import { getItem, removeItem } from "utils/utils";

const Container = styled.div`
  width: 100%;
  padding-top: 30px;
  .tabs {
    display: flex;
    margin-bottom: 30px;
    .tab {
      cursor: pointer;
      font-family: "Roboto Condensed", sans-serif;
      color: #777777;
      font-size: 13px;
      text-transform: uppercase;
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      margin-right: 30px;
      path {
        fill: #56ccf2;
      }
      div {
        margin-top: 7px;
      }
      &.active {
        color: #007ac7;
        path {
          fill: #007ac7;
        }
        div {
          position: relative;
          &:before {
            content: "";
            position: absolute;
            background-color: #56ccf2;
            height: 2px;
            width: 100%;
            bottom: -3px;
            left: 0;
          }
        }
      }
    }
  }
  .emptyQueue {
    border: 1px solid #dddddd;
    border-radius: 4px;
    padding: 15px 12px;
    font-size: 13px;
    color: #777777;
    margin-bottom: 40px;
  }
  .connectNew {
    h1 {
      font-size: 22px;
      font-weight: 400;
      color: #777777;
      text-transform: capitalize;
    }
  }
`;

class Connect extends React.PureComponent {
  constructor(props) {
    super(props);
    const route = getItem("twitterRoute");
    this.state = {
      activeTab: route === "dashboard" ? "setup" : "queue",
      loading: true
    };
    setTimeout(() => {
      removeItem("twitterRoute");
    });
  }

  componentWillMount() {
    this.getInfluencerConnects();
  }
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.connectPostRefetchTime !== this.props.connectPostRefetchTime
    ) {
      this.setState({ loading: true });
      this.getInfluencerConnects();
    }
  }
  getInfluencerConnects = async id => {
    try {
      const connectIds = this.props.influencerDetails.influencerconnects.map(
        connect => connect.id
      );
      const response = await this.props.client.query({
        query: getInfluencerConnectPosts,
        variables: {
          where: {
            influencerconnect_id: { _in: connectIds },
            lastshared_at: { _is_null: true }
          },
          order_by: { shareschedule_at: "asc" }
        },
        fetchPolicy: "no-cache"
      });
      this.props.setAllConnectPosts(response.data.influencerconnectpost);
      this.handleGrouping(response.data.influencerconnectpost);
    } catch (err) {
      console.log(err);
      this.setState({
        loading: false
      });
    }
  };

  handleGrouping = posts => {
    let groupedPosts = [];
    posts.forEach(post => {
      const postIndex = groupedPosts.findIndex(
        item =>
          item.post_id === post.post_id &&
          item.shareschedule_at === post.shareschedule_at
      );
      if (postIndex > -1) {
        if (
          groupedPosts[postIndex].connects.findIndex(
            connect => connect.connect === post.influencerconnect.connect
          ) === -1
        ) {
          post.connects = [
            ...groupedPosts[postIndex].connects,
            post.influencerconnect
          ];
          groupedPosts[postIndex] = post;
        }
      } else {
        post.connects = [post.influencerconnect];
        groupedPosts = [...groupedPosts, post];
      }
    });
    this.props.setConnectPosts(groupedPosts);
    this.setState({
      posts: groupedPosts,
      loading: false
    });
  };

  handleTabChange = activeTab => {
    this.setState({ activeTab });
  };
  render() {
    const { activeTab, loading } = this.state;
    const { influencerDetails } = this.props;
    return (
      <Wrapper>
        <Container>
          <div className="tabs">
            <div
              className={`tab ${activeTab === "queue" ? "active" : ""}`}
              onClick={() => this.handleTabChange("queue")}
            >
              <IconQueue />
              <div>{getTranslation("queue")}</div>
            </div>
            <div
              className={`tab ${activeTab === "recent" ? "active" : ""}`}
              onClick={() => this.handleTabChange("recent")}
            >
              <IconRecentPost />
              <div>{getTranslation("recent")}</div>
            </div>
            <div
              className={`tab ${activeTab === "calendar" ? "active" : ""}`}
              onClick={() => this.handleTabChange("calendar")}
            >
              <DayIcon style={{ height: 24 }} />
              <div>{getTranslation("calendar")}</div>
            </div>
            <div
              className={`tab ${activeTab === "setup" ? "active" : ""}`}
              onClick={() => this.handleTabChange("setup")}
            >
              <IconChannelSetup />
              <div>{getTranslation("setup")}</div>
            </div>
          </div>
          <>
            {activeTab === "queue" && (
              <Queue
                loading={loading}
                influencerDetails={influencerDetails}
                handleTabChange={this.handleTabChange}
              />
            )}
          </>
          <>{activeTab === "recent" && <RecentPosts />}</>
          <>{activeTab === "setup" && <Setup />}</>
          <>{activeTab === "recurrences" && <Recurrences />}</>
          <>{activeTab === "calendar" && <CalendarView />}</>
        </Container>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  influencerDetails: state.influencer.influencerDetails,
  connectPostRefetchTime: state.influencer.connectPostRefetchTime
});

export default withApollo(
  connect(mapStateToProps, { setConnectPosts, setAllConnectPosts })(Connect)
);
