import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled, { withTheme } from "styled-components";
import { getItem, numberToLocaleString, categoryCodeToStar } from 'utils/utils';
import { StarIcon, SmileyRatingIcon, HotelSelectedIcon, HotelUnselectedIcon } from "components/common/SvgIcons";
import config from "config";

const SliderWrapper = styled.div`
  .slick-slide {
    opacity: 0.8;
  }
  .slick-center {
    opacity: 1;
  }
  .imageWrapper {
    height: 268px;
    align-items: center;
    outline: none;
    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
`;

const HotelListItem = styled.div`
align-self: center;
margin: 0px 0px 20px;
background: #FFFFFF;

border: 1px solid #F0F0F0;
box-sizing: border-box;
box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.06);

flex-direction: column;
justify-content: space-between;
cursor: pointer;
position: relative;

font-family: Roboto;
font-style: normal;
font-weight: normal;


.totalPrice {
    font-size: 20px;
    text-decoration: none !important;
    color: ${(props) => props.theme.secondary};
  }
.topRightBox {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-around;    
    display: flex;
    flex-direction: column;
    padding: 8px;

    top: 0;
    right: 0;
    position: absolute;
    
    width: 111px;
    height: 94px;
    background: rgba(255, 255, 255, 0.88);
    border-radius: 0px 0px 0px 16px;
}
.topRightDividerLine {
    border: 1px solid #777777;
    transform: rotate(180deg);
    width: 64px;
}
.topRightText {
    font-size: 12px;
    color: #222222;
  }
.starsInLine {
    display: flex;
    flex-direction: row;
    padding: 0px;
}
.starSingle {
    flex: none;
    align-self: center;
    margin: 2px 0px;
}
.smileyRatingText {
    flex: none;
    align-self: center;
    font-size: 14px;
    line-height: 17px;
    margin: 0px 4px;
}
.hotelTitleText {
    font-size: 18px;
    color: #222222;
}

    `;
export default class HotelItem extends Component {
    handleSelect = () => {
        const { item, numOfRooms, handleSelect } = this.props;
        if (handleSelect) {
            handleSelect({ hotelCode: item.code, rateKeys: item.rooms.map(room => room.rates.map(rate => rate.rateKey)).flat().slice(0, numOfRooms) });
        }
    };
    render() {
        const { item, active, numOfRooms } = this.props;
        let totalCustomCurrencySellingRate = item.rooms.map(room => room.rates.map(rate => rate.customCurrencySellingRate)).flat().slice(0, numOfRooms).reduce((a, b) => (a + b), 0);
        const localizedTotalCost = numberToLocaleString(totalCustomCurrencySellingRate, {
            selectedCountry: getItem("selectedLanguage"),
            selectedCurrency: item.rooms[0].rates[0].customCurrency,
        });
        var settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            center: false,
            adaptiveHeight: true
        };

        const imagedata = {};
        let sortedItemImages = item.hotelcontent.images.sort((a, b) => a.order - b.order);
        sortedItemImages = sortedItemImages.slice(0, 5);
        imagedata.images = sortedItemImages.map(i => config.hotelImagePathPrefix + i.path);
        // console.log('item ' + item);
        let starsFilled = categoryCodeToStar(item.categoryCode) || 0;
        let starsUnfilled = 5 - starsFilled;
        let stars = [];
        for (var i = 0; i < starsFilled; i++) {
            stars.push(<StarIcon fill={true} />)
        }
        for (var i = 0; i < starsUnfilled; i++) {
            stars.push(<StarIcon fill={false} />)
        }

        let nameRatekeys = item.rooms.map(room => room.rates.map(rate => { return { roomName: room.name, roomRatekey: rate.rateKey } })).flat().slice(0, numOfRooms);
        // console.log('nameRatekeys ' + JSON.stringify(nameRatekeys, null, 2));
        let rooms = [];
        for (var i = 0; i < numOfRooms; i++) {
            rooms.push(<span> {(numOfRooms > 1 ? '| 1 x ' : '') + nameRatekeys[i].roomName} </span>);
        }

        return (item != null) ? (
            <HotelListItem
                id={item.code}
                onClick={this.handleSelect}
                active={active}
            >
                <SliderWrapper>
                    <div>
                        <Slider
                            {...settings}
                            ref={c => (this.slider = c)} {...settings}>
                            {imagedata.images.map((image, i) => (
                                <div className="imageWrapper" key={i}>
                                    <img src={image} alt="" />
                                </div>
                            ))}
                        </Slider>
                    </div>
                </SliderWrapper>
                {active ?
                    (<div style={{ top: "16px", left: "16px", position: "absolute" }}>
                        <HotelSelectedIcon />
                    </div>)
                    :
                    (<div style={{ top: "16px", left: "16px", position: "absolute" }}>
                        <HotelUnselectedIcon />
                    </div>)
                }
                <div className="topRightBox">
                    <span className="totalPrice">{localizedTotalCost}</span>
                    <div className="topRightText">PAY AT CHECK-IN</div>
                    <div className="topRightDividerLine"></div>
                    <div className="topRightText">Free Cancellation</div>
                </div>

                <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-around", height: "135px", padding: "8px" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", height: "24px" }}>
                        <div>
                            {stars}
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", width: "54px" }}>
                            <SmileyRatingIcon style={{ margin: "0px 4px" }} />
                            <div className="smileyRatingText">33%</div>
                        </div>
                    </div>
                    <div className="hotelTitleText">
                        {item.name}
                    </div>
                    <div>
                        {rooms}
                    </div>
                    <div>
                        <span>City Centre</span>
                    </div>
                </div>
            </HotelListItem >
        ) : null;
    }
}