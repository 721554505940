import React from "react";
import { connect } from "react-redux";
import styled, { withTheme } from "styled-components";
import orderBy from "lodash/orderBy";
import { SortingDown } from "components/common/SvgIcons";
import getTranslation from "utils/getTranslation";
import { setItem, removeItem } from "utils/utils";

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
  .container {
    max-width: 351px;
    display: flex;
    div,
    span {
      font-size: 12px;
      font-family: "Roboto Condensed", sans-serif;
      color: ${(props) => props.theme.secondary};
      margin-right: 24px;
      cursor: pointer;
      &:last-child {
        margin-right: 15px;
      }
      &.active {
        font-weight: bold;
        border-bottom: 1px solid ${(props) => props.theme.primary};
      }
      &.label {
        color: #777777;
        cursor: default;
      }
    }
    div {
      position: relative;
      svg {
        position: absolute;
        right: -15px;
        top: -2px;
        &.asc {
          transform: rotate(180deg);
        }
        &.desc {
          transform: rotate(0deg);
        }
      }
    }
  }
`;

class Sorter extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeSort: null,
      sortOrder: null,
    };
  }

  componentWillMount() {
    removeItem("activeSort");
    removeItem("sortOrder");
  }

  handleSortClick = (e) => {
    const sortBy = e.target.id;
    let sortOrder = this.state.sortOrder;
    if (this.state.activeSort !== sortBy) {
      sortOrder = "asc";
    } else {
      sortOrder = sortOrder === "asc" ? "desc" : "asc";
    }
    const sortedResults = orderBy(this.props.data, sortBy, sortOrder);
    this.setState(
      {
        activeSort: sortBy,
        sortOrder,
      },
      () => {
        setItem("activeSort", sortBy);
        setItem("sortOrder", sortOrder);
      }
    );
    this.props.handleUpdateFilteredResults(sortedResults);
  };
  render() {
    const { activeSort, sortOrder } = this.state;
    const { theme, sortArray } = this.props;
    return (
      <Wrapper>
        <div className="container">
          <span className="label">{getTranslation("sortBy")}</span>
          {sortArray.map((item) => (
            <div
              className={activeSort === item.name ? "active" : ""}
              id={item.name}
              onClick={this.handleSortClick}
            >
              {getTranslation(item.label)}
              {activeSort === item.name && (
                <SortingDown stroke={theme.secondary} className={sortOrder} />
              )}
            </div>
          ))}
        </div>
      </Wrapper>
    );
  }
}

export default withTheme(Sorter);
