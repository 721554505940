import React from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { compose, withApollo } from "react-apollo";
import moment from "moment";
import Checkbox from "@material-ui/core/Checkbox";
import { connect } from "react-redux";
import CreditCardInput from "react-credit-card-input";
import { addPassengerToAllHopRequests } from "redux-store/actions";
import { Toast, Button, Autocomplete, Input } from "components/common";
import PaymentIssueModal from "components/common/PaymentIssueModal";
import { xcountryLocalesAndCurrencies } from "components/common/commonQueries";
import { xcurrencyPerUsd } from "./queries";
import { getDomainRedirectUrl, paypalCustdomSetupAuthorize, paypalCustdomAuthorize } from "utils/api";
import { getItem, setItem } from "utils/utils";
import getTranslation from "utils/getTranslation";
import countryList from "utils/countryList";
import countries from "utils/countries";
import paymentLogo from "assets/emerchantpayLogo.svg";
import { PayPalButton } from "react-paypal-button-v2";

const Wrapper = styled.div`
  margin: auto;
`;

const Meta = styled.div`
  div {
    display: flex;
    color: #777777;
    margin-top: 10px;
    font-size: 12px;
  }
`;

const InputWrapper = styled.div`
  .cardInputs {
    display: flex;
    .autoCompleteWrapper {
      width: 256px;
      margin: 0 24px;
    }
  }
  .btnWrapper {
    display: flex;
    width: 300px !important;
  }
  .cvv {
    display: flex;
    justify-content: space-between;
  }
  .creditCardInput {
    margin-top: 16px;
    margin-bottom: 8px;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.08), inset 0px -1px 0px #007ac7 !important;
    background-color: rgba(0, 0, 0, 0.09);
    border-radius: 0;
    padding: 30px 12px 10px;
    .floating {
      color: rgba(0, 0, 0, 0.54);
      position: absolute;
      left: 0;
      top: -32px;
      transform: translate(0, 20px) scale(1);
      transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
        transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      &.focus {
        /* top: -27px; */
        transform: translate(-5px, 0px) scale(0.75);
        /* font-size: 12px; */
      }
      &.cardNum {
        transform: translate(-10px, 0px) scale(0.75);
      }
    }
    .credit-card-input {
      top: -10px;
    }
    input {
      background-color: transparent;
    }
    .mask {
      width: 28px;
      height: 20px;
      position: absolute;
      background-color: #fff;
      left: -34px;
      z-index: -1;
    }
    img {
      position: relative;
      z-index: 1;
    }
  }
  .acceptTerms {
    display: flex;
    font-size: 12px;
    align-items: center;
    color: #222222;
    a {
      color: #007ac7;
    }
  }
`;

let defaultCountry = "";
const selectedCountry = getItem("selectedCountry") || "gb";
if (selectedCountry.toLowerCase() !== "xx") {
  defaultCountry =
    countryList.find(item => item.Code === selectedCountry.toUpperCase()).en ||
    "";
}

class PaymentGateway extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showIframe: false,
      startPolling: false,
      cardNumber: "",
      cardCvv: "",
      cardExpMm: "",
      cardExpYyyy: "",
      cardHoldername: "nimit walia",
      isFormCompleted: false,
      cardInputTime: 0,
      errorTime: 0,
      countriesList: [],
      searchTextCountry: defaultCountry,
      cardHolderCountry: defaultCountry,
      empTransactionid: ""
    };
  }

  async componentWillMount() {
    const response = await this.props.client.query({
      query: xcurrencyPerUsd,
      variables: {
        currencyIso: getItem("selectedCurrency")
      }
    });
    const { price_usd } = this.props.domainData;
    const priceMultiplier = this.getTruncatedValue(
      price_usd * response.data.xcurrencyPerUsd
    );
    this.getCurrencyDetails(priceMultiplier);
  }

  getTruncatedValue = num => {
    num = num.toString(); //If it's not already a String
    num = num.slice(0, num.indexOf(".") + 3); //With 3 exposing the hundredths place
    return Number(num);
  };

  getCurrencyDetails = async price => {
    const countries = await this.props.client.query({
      query: xcountryLocalesAndCurrencies,
      variables: {
        fsSupport: true,
        countryIc: getItem("selectedCountry")
      }
    });
    const currency = countries.data.xcountryLocalesAndCurrencies[0].currencies.find(
      item => item.currencyIso === getItem("selectedCurrency")
    );
    const amountDecimalPlaces = currency.minorUnit;
    const amount = price * Math.pow(10, amountDecimalPlaces);
    this.setState({
      amount,
      amountDecimalPlaces
    });
  };

  componentDidUpdate() {
    const {
      cardCvv,
      cardNumber,
      cardExpMm,
      cardExpYyyy,
      cardInputTime,
      errorTime
    } = this.state;
    const isFormCompleted =
      cardCvv.length > 2 &&
      cardNumber.replace(/\s/g, "").length >= 16 &&
      cardExpMm.length === 2 &&
      cardExpYyyy.length === 4 &&
      cardInputTime > errorTime;
    if (isFormCompleted !== this.state.isFormCompleted) {
      this.setState({ isFormCompleted });
    }
  }

  handleGetUrl = async () => {
    this.setState({ startIframeLoading: true, loading: true });
    try {





      // window.location = setup.redirect_url;
    } catch (err) {
      console.log(err);
      this.setState({
        paymentError: true,
        paymentSetupFailed: true,
        errorMessage: getTranslation("paymentInitFailed"),
        startIframeLoading: false,
        loading: false
      });
    }
  };

  handleWarningAccept = () => {
    this.props.history.push("/new-trip");
  };

  handleHidePaymentError = msg => {
    this.setState({
      paymentError: false
    });
  };

  handleCardNumberChange = e => {
    this.setState({
      cardNumber: e.target.value,
      cardInputTime: moment().valueOf()
    });
  };
  handleCardExpiryChange = e => {
    const monthYear = e.target.value;
    const monthYearArray = monthYear.split(" / ");
    this.setState(
      {
        cardExpMm: monthYearArray[0] ? monthYearArray[0] : "",
        cardExpYyyy: monthYearArray[1]
          ? moment()
            .format("YYYY")
            .slice(0, 2) + monthYearArray[1]
          : "",
        cardInputTime: moment().unix()
      },
      () => {
        console.log(this.state.cardExpYyyy);
      }
    );
  };
  handleCardCVCChange = e => {
    this.setState({
      cardCvv: e.target.value,
      cardInputTime: moment().valueOf()
    });
  };
  handleAcceptChange = (e, checked) => {
    this.setState({ termsAccepted: checked });
  };

  handleCardHolderCountryInputChange = txt => {
    this.setState(
      {
        searchTextCountry: txt,
        cardHoldercountry: null
      },
      () => {
        this.handleCountryFilter(txt.toLowerCase());
      }
    );
  };
  handleCountryFilter = txt => {
    const countriesList = countries.filter(country =>
      country.toLowerCase().startsWith(txt)
    );
    const list = countriesList.slice(0, 5);
    this.setState({
      countriesList: list
    });
  };
  onCardHolderCountrySelect = (index, dataSource) => {
    if (index === -1) return;
    const country = dataSource[index];
    if (country) {
      const countryIc = countryList.find(item => item.en === country);
      this.setState({
        cardHoldercountry: country,
        cardHoldercountryic: countryIc ? countryIc.Code : "",
        countriesList: []
      });
    }
  };
  handleCardHolderCountryAutocompleteClose = () => {
    this.setState({ countriesList: [] });
  };
  handleCardHolderCountryBlurCheck = (text, dataSource) => {
    const countryIndex = dataSource.findIndex(
      item => item.toLowerCase() === text.toLowerCase()
    );
    if (countryIndex > -1) {
      this.onCardHolderCountrySelect(text, countryIndex, dataSource);
      this.setState({ searchTextCountry: dataSource[countryIndex] });
    }
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  };

  handleError = e => {
    this.setState({
      errorTime: moment().valueOf()
    });
  };

  handleCreateOrder = async () => {
    const {
      amount,
      amountDecimalPlaces
    } = this.state;
    const payload = {
      customDomainId: this.props.domainData.id,
      amount,
      amountDecimalPlaces,
      currencyIso: getItem("selectedCurrency"),
      inflEmail: getItem("loggedInUsername"),
      local: false,
      domain: window.location.origin
    };
    await this.props.handleRedirect();
    const data = await paypalCustdomSetupAuthorize({
      searchparams: {
        ...payload
      }
    });
    // setItem("emp_transactionid", data.emp_transactionid);
    this.setState({
      empTransactionid: data.emp_transactionid
    });
    return data.orderID
  }

  handleApprove = async (data) => {
    // setItem("approved_orderid", data.orderID);
    this.setState({ startIframeLoading: true, loading: true, authorizing: true });

    try {
      const payload2 = {
        orderID: data.orderID,
        empTransactionid: this.state.empTransactionid,
      }
      const result = await paypalCustdomAuthorize(payload2);
      setItem("empTransactionid", result.emp_transactionid);
      if (getItem("paymentType") === "domain") {
        this.props.history.replace("/domain-payment-process");
      }
    } catch (err) {
      console.log(err);
      this.setState({
        paymentError: true,
        paymentSetupFailed: true,
        errorMessage: getTranslation("paymentInitFailed"),
        startIframeLoading: false,
        loading: false,
        authorizing: false
      });
    }
  }

  render() {
    const {
      loading,
      openWarning,
      warningMessage,
      termsAccepted,
      isFormCompleted,
    } = this.state;

    const { style = {}, localizedTotalCost, handleShare } = this.props;
    return (
      <Wrapper style={style}>
        <PaymentIssueModal
          open={openWarning}
          handleAccept={this.handleWarningAccept}
          errorMsg={warningMessage}
        />
        <div style={{ marginTop: 20 }}>
          <InputWrapper>
            <div className="acceptTerms">
              <Checkbox color="secondary" onChange={this.handleAcceptChange} />
              <div>
                I acknowledge that I have read the{" "}
                <a href="/terms" target="_blank">
                  Terms & Conditions
                </a>{" "}
                of purchase,{" "}
                <a href="/privacy" target="_blank">
                  Privacy Policy
                </a>{" "}
                and authorize the payment.
              </div>
            </div>

            <PayPalButton
              disabled={loading || !termsAccepted || !isFormCompleted}
              createOrder={this.handleCreateOrder}
              onApprove={this.handleApprove}
            />


            <h2>
              {getTranslation("skipActivation")}
            </h2>
            <div className="btnWrapper">
              <Button
                color="#56CCF2"
                style={{ height: 40, color: "#222222" }}
                onClick={handleShare}
              >
                {getTranslation("finish")}
              </Button>
            </div>
          </InputWrapper>
        </div>
        <Toast
          open={this.state.paymentError}
          message={this.state.errorMessage}
          handleClose={this.handleHidePaymentError}
        />
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  hopRequests: state.flightResults.hopRequests,
  localizedTotalCost: state.flightResults.localizedTotalCost
});

export default withApollo(
  withRouter(
    compose(
      // graphql(updPassengerQuery, { name: "updPassengerQuery" }),
      connect(mapStateToProps, { addPassengerToAllHopRequests })
    )(PaymentGateway)
  )
);
