import React from "react";
import styled from "styled-components";
import HopSegActionBtn from "components/common/HopSegActionBtn";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .label {
    font-family: "Roboto Condensed", sans-serif;
    margin-bottom: 5px;
    color: #777777;
    font-size: 12px;
    height: 28px;
    width: 110px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .__release-text {
    font-weight: 300;
    font-size: 14px;
    color: #222222;
    line-height: 100%;
    text-align: center;
    height: 40px;
    display: flex;
    align-items: center;
  }
  p {
    margin-top: 0;
    margin-bottom: 0;
  }
  .childWrapper {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    margin-bottom: 2px;
    svg {
      position: absolute;
      top: 47px;
      height: 32px;
      width: 32px;
    }
    .count {
      background: #ec9719;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.16);
      color: #fff;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      position: absolute;
      right: 10px;
      top: 52px;
    }
  }
`;

const Button = styled.div`
  position: relative;
  border: 1px solid ${(props) => props.theme.primary};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  background-color: #fff;
  opacity: 1;
  transition: all 0.1s ease-in-out;
  &.active {
    background-color: #d0f3fc;
    border: 0;
  }
  div {
    margin-top: 3px;
    margin-bottom: 5px;
  }
  p {
    font-size: 12px;
    font-weight: 300;
    color: #444444;
    text-align: center;
    line-height: 14px;
    font-family: "Roboto Condensed", sans-serif;
    ${"" /* @media (min-width: 1000px) {
      font-size: 10px !important;
    } */}
  }
  svg {
    height: 22px;
    width: 22px;
    margin-right: 5px;
  }
  img {
    position: absolute;
    bottom: 10px;
    right: 25px;
  }
`;

const HopSegButton = ({
  onClick,
  disabled,
  btnContent,
  text,
  active,
  label,
}) => (
  <Wrapper>
    <Button
      disabled={disabled}
      className={`hopSegBtn ${active ? "active" : ""} ${
        disabled ? "disabled" : ""
      }`}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          padding: "0 5px",
        }}
      >
        <HopSegActionBtn
          disabled={disabled}
          onClick={!disabled ? onClick : null}
        >
          {btnContent}
        </HopSegActionBtn>
        {text}
      </div>
    </Button>
    <span className="label">{label}</span>
  </Wrapper>
);

export default HopSegButton;
