import React from "react";

export default class extends React.PureComponent {
  handleSelect = () => {
    this.props.handleSelect(this.props.type.type);
  };
  render() {
    const { type, selectedType } = this.props;
    const active = selectedType === type.type;
    return (
      <div
        key={type.id}
        className={`postType ${active ? "active" : ""}`}
        onClick={this.handleSelect}
      >
        <div className="imageWrapper">
          <type.image stroke={active ? "#222222" : "#007AC7"} />
        </div>
        <div className="details">
          <span>{type.name}</span>
        </div>
      </div>
    );
  }
}
