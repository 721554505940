import React, { PureComponent } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { isMobile } from 'react-device-detect';
import Checkbox from '@material-ui/core/Checkbox';
import styled from 'styled-components';
import Modal from 'components/common/Modal';
import { Button } from 'components/common';
import getTranslation from 'utils/getTranslation';

const Wrapper = styled.div`
  text-align: left;
  color: #fff;
  font-size: 12px;
  background-color: #ec9719;
  padding: 50px 30px;
  @media (max-width: 800px) {
    height: 100vh;
  }
  h4 {
    margin-bottom: 10px;
  }
  .header {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    margin-bottom: 20px;
    span {
      font-size: 24px;
      margin-top: 20px;
    }
  }
  .text {
    color: #222222;
    font-size: 16px;
    line-height: 19px;
  }
`;

class WarningModal extends PureComponent {
  state = {
    continueBooking: false
  };
  handleClose = () => {
    // this.props.handleCloseModal();
  };

  handleChange = (e, checked) => {
    this.setState({ continueBooking: checked })
  }

  render() {
    const { continueBooking } = this.state;
    const {
      open,
      handleDeny,
      handleAccept,
    } = this.props;
    return (
      <Modal
        open={open}
        handleCloseModal={this.handleClose}
        disableFullScreen={!isMobile}
        dialogStyle={{
          width: isMobile ? "100%" : 375,
          maxWidth: isMobile ? "100%" : 375
        }}
      >
        <Wrapper>
          <div className="header">
            <img src={require("assets/icons/warning.svg")} alt="" />
            <span>{getTranslation("baggageDiffFound")}</span>
          </div>
          <div
            className="text"
            style={{ letterSpacing: "0.05em", marginBottom: 20 }}
          >
            <p>
              {getTranslation("baggageDiffP1")}
              <br />
              <br />
              {getTranslation("baggageDiffP2")}
            </p>
          </div>
          <Button
            color="#ffffff"
            label="Cancel And Go Back"
            style={{ color: "#007AC7" }}
            onClick={handleDeny}
          >
            {getTranslation("cancelAndGoBack")}
          </Button>
          <FormControlLabel
            style={{ margin: "10px 0" }}
            control={
              <Checkbox color="primary" onChange={this.handleChange} />
            }
            label={getTranslation("continueBooking")}
          />
          <Button
            bgColor="rgba(242, 153, 74, 0.8)"
            onClick={handleAccept}
            disabled={!continueBooking}
          >
            {getTranslation("proceedToConfirm")}
          </Button>
        </Wrapper>
      </Modal>
    );
  }
}

export default WarningModal;
