import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { graphql, compose } from "react-apollo";
import styled from "styled-components";
import CircularProgress from "@material-ui/core/CircularProgress";
import OtpInput from "react-otp-input";
import { Loader, Button, Toast } from "components/common";
import BookedTrip from "components/common/BookedTrip";
import Polling from "components/Payment/childs/Polling";
import { generateEticket } from "./queries";
import { getTripById } from "containers/FlightResults/queries";
import { verifyOtp } from "utils/api";
import { removeItem, getItem, setItem, clearLocalStore } from "utils/utils";
import getTranslation from "utils/getTranslation";
import { capitalizeFirstLetter } from "utils/utils";
import config from "config";
import flyIcon from "assets/icons/flyIcon.svg";
import { bookingByTripIdGql } from "components/Hotel/HotelQuery";
import { paypalAuthorize } from "../../utils/api";

const Wrapper = styled.div`
  position: relative;
  min-height: 100vh;
  .bookingWrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(204, 204, 204, 0.84);
    backdrop-filter: blur(4px);
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .booking {
    font-size: 18px;
    color: #444444;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding: 80px;
    flex-flow: column;
    background-color: #fff;
    border-radius: 10px;
    @media (max-width: 800px) {
      padding: 20px;
      width: 98%;
    }
    .inputStyle {
      width: 3rem !important;
      height: 3rem;
      margin: 0 1rem;
      font-size: 2rem;
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.3);
      @media (max-width: 800px) {
        width: 2rem !important;
        height: 2rem;
      }
    }
    .btns {
      display: flex;
      button {
        min-width: 100px !important;
      }
    }
  }
  .loaderWrapper {
    position: relative;
    height: 48px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const NoFlight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  flex-direction: column;
  text-align: center;
  padding: 30px;
  h5 {
    color: #000;
    font-size: 16px;
  }
  div {
    margin-top: 20px;
    a {
      color: #000;
      transition: none;
      font-size: 14px;
    }
  }
`;

const StyledLoader = styled(CircularProgress)`
  position: absolute;
  left: 0;
  circle {
    stroke: #d0f3fc;
  }
`;
let event;
class FlightResults extends Component {
  constructor(props) {
    super(props);
    const noFlight = false;
    this.state = {
      tripItem: null,
      noFlight,
      loadingScreen: true,
      bookingStatus: getTranslation("paymentInitiated"),
      otp: "",
      retries: 0,
      // paymentComplete: false,
      tripBooked: false,
      verifying: false,
      verified: false
    };
  }

  async componentWillMount() {
    if (!getItem("tripId")) {
      // this.handlePaymentComplete();
    } else {
      this.handleFetchTrip();
    }
  }

  componentWillUnmount() {
    if (event) {
      event.close();
    }
  }

  handleStartEvent = () => {
    const tokenDetails = getItem("tokenDetails");
    const guestLogin = getItem("guestLogin");
    let params = "";
    if (tokenDetails) {
      params = tokenDetails ? `&bearer=${tokenDetails.access_token}` : "";
    } else {
      params = guestLogin ? `&guestEmail=${guestLogin.email}` : "";
    }
    const empTransactionid = getItem("emp_transactionid");
    event = new EventSource(
      config.paymentHost +
      "/ticketed_status_sse?tripId=" +
      this.props.tripId + "&empTransactionid=" + empTransactionid +
      params
    );

    event.addEventListener("message", result => {
      console.log(result);
      if (result.data) {
        const data = JSON.parse(result.data);
        if (data.ticketed) {
          event.close();
          this.handleFetchTrip();
          this.handleFetchBookings();
          clearLocalStore();
          removeItem("tripId");
        }
      }
    });

    event.addEventListener("error", e => {
      console.log("Error", e);
      //this.props.handleShowBookingStatus(getTranslation("bookingFailed"));
      //this.handleTripFailed();
    });
  };

  handleFetchTrip = async () => {
    try {
      const response = await this.props.client.query({
        query: getTripById,
        variables: { id: this.props.tripId },
        fetchPolicy: "no-cache"
      });
      this.setState({
        tripItem: response.data.tripById[0],
        loadingScreen: false
      });
    } catch (err) {
      this.setState({
        noFlight: true,
        loadingScreen: false
      });
    }
  };

  handleFetchBookings = async () => {
    const response = await this.props.bookingByTripId({
      variables: {
        tripId: this.props.tripId,
      },
      fetchPolicy: "no-cache"
    });
    let gqlout = response.data.bookingByTripId ? response.data.bookingByTripId[0] : null;
    console.log('handleFetchBookings gqlout ' + JSON.stringify(gqlout));
    if (gqlout != null) {
      let bookings = gqlout.hotels.length > 0 ? gqlout.hotels.filter(hotel => hotel.hotelcontent.images != null && hotel.hotelcontent.images.length > 0) : [];
      console.log('now length ' + gqlout.hotels.length);
      // this.props.handleUpdateHotelFilteredResults(prevHotelDestinationCode, prevSkipped, decodedData[currentDecodedDataIndex].to.airportIc, gqlout.destinationCode, hotels, gqlout._key, gqlout.occupancies);
      // this.props.handleFinaliseHotel();
      // this.props.handleSelectedHotel({ destinationCode, skipped: true, finalised: false }
    }
  }



  // handleFetchETicket = () => {
  //   this.props
  //     .generateEticket({
  //       variables: {
  //         tripId: parseInt(this.props.tripId, 10)
  //       }
  //     })
  //     .then(tripData => {
  //       if (tripData.data.generateEticket) {
  //         this.setState(
  //           {
  //             tripItem: tripData.data.generateEticket,
  //             loadingScreen: false
  //           },
  //           () => {
  //             if (!this.state.tripItem.eticket) {
  //               setTimeout(() => {
  //                 this.handleFetchETicket();
  //               }, 120 * 1000);
  //             } else {
  //               setItem("bookingComplete", true);
  //             }
  //           }
  //         );
  //       } else {
  //         this.setState({
  //           noFlight: true
  //         });
  //         console.log("SOMETHING WENT WRONG");
  //       }
  //     })
  //     .catch(err => {
  //       console.log(err);
  //       this.setState({
  //         noFlight: true,
  //         loadingScreen: false
  //       });
  //     });
  // };

  handleNoFlightSegments = () => {
    this.setState({
      noFlight: true,
      loadingScreen: false
    });
  };

  handleTripBooked = () => {
    this.setState(
      {
        tripBooked: true
      },
      () => {
        // clearLocalStore();
        // removeItem("tripId");
        this.handleStartEvent();
      }
    );
  };

  handlePaymentApproved = async () => {
    this.setState({
      paymentError: true,
      bookingStatus: getTranslation("paymentPending")
    });
  };
  handleShowBookingStatus = msg => {
    this.setState({
      paymentError: true,
      bookingStatus: msg
    });
  };

  handlePaymentError = (error, failed) => {
    console.log("paymentError");
    if (failed) {
      this.handleTripFailed();
    }
    this.setState({
      startPolling: false,
      showIframe: false,
      paymentError: true,
      bookingStatus: `Payment ${capitalizeFirstLetter(error)}.`
    });
  };

  handleTripFailed = () => {
    setItem("bookingError", true);
    this.props.history.push(`/flights/${getItem("encodedTripSearch")}`);
  };

  handleOtpChange = otp => {
    this.setState({ otp }, () => {
      if (this.state.otp.length === 6) {
        this.handleOtpVerification();
      }
    });
  };

  handleOtpVerification = async () => {
    this.setState({
      verifying: true
    });
    try {
      const payload = {
        email: getItem("tripowner_email"),
        otp: this.state.otp
      };
      const verify = await verifyOtp(payload);
      if (verify.success) {
        // window.location = getItem("gateway_redirect_url");
        const payload2 = {
          orderID: getItem("approved_orderid"),
          empTransactionid: getItem("emp_transactionid"),
          tripId: getItem("tripId"),
          flightsOnly: true,
          domain: window.location.origin
        }
        const result = await paypalAuthorize(payload2);
        this.setState({ verifying: false, verified: true });
      } else {
        this.handleVerifyError(verify);
      }
    } catch (err) {
      console.log(err);
      this.handleVerifyError(err);
    }
  };

  handleVerifyError = response => {
    if (this.state.retries < 5) {
      this.setState({
        verifying: false,
        retries: this.state.retries + 1,
        errorMsg: response.message,
        error: true,
        otp: null
      });
    } else {
      this.handleCancelOtp();
    }
  };

  handleCancelOtp = () => {
    this.props.history.goBack();
  };

  handleToastClose = () => {
    this.setState({ errorMsg: null, error: false });
  };

  render() {
    const {
      noFlight,
      loadingScreen,
      tripItem,
      // paymentComplete,
      tripBooked,
      bookingStatus,
      otp,
      errorMsg,
      error,
      verifying,
      verified
    } = this.state;
    const { client, tripId } = this.props;
    return (
      <Wrapper>
        {!tripBooked && verified && (
          <Polling
            tripId={tripId}
            client={client}
            handleTripBooked={this.handleTripBooked}
            handlePaymentApproved={this.handlePaymentApproved}
            handleShowBookingStatus={this.handleShowBookingStatus}
            handlePaymentError={this.handlePaymentError}
            handleTripFailed={this.handleTripFailed}
          />
        )}
        {!tripBooked && (
          <div className="bookingWrapper">
            <div className="booking">
              {!verifying && !verified ? (
                <>
                  <OtpInput
                    onChange={this.handleOtpChange}
                    numInputs={6}
                    separator={<span>-</span>}
                    value={otp}
                    shouldAutoFocus
                    //isInputNum
                    inputStyle="inputStyle"
                  />
                  <p>
                    {getTranslation("verifyOtp")} {getItem("mobile_number_obf")}
                  </p>
                  <div className="btns">
                    <Button
                      onClick={this.handleCancelOtp}
                      color="#F0F0F0"
                      style={{ color: "#007AC7" }}
                    >
                      {getTranslation("cancel")}
                    </Button>
                  </div>
                </>
              )
                : verified ?
                  (
                    <>
                      <div className="loaderWrapper">
                        <img src={flyIcon} alt="" />
                        <StyledLoader size={48} />
                      </div>
                      <p>{bookingStatus}</p>
                    </>
                  )
                  : (
                    <div className="loaderWrapper">
                      <img src={flyIcon} alt="" />
                      <StyledLoader size={48} />
                    </div>
                  )}
            </div>
          </div>
        )}
        <div
          style={{
            padding: 10,
            position: "relative",
            margin: "auto"
          }}
        >
          {noFlight && (
            <NoFlight>
              <h5>{getTranslation("genericErrorMsg")}</h5>
              <div>
                <Link to="/trip">{getTranslation("bookNewTrip")}</Link>
              </div>
            </NoFlight>
          )}
          {!noFlight && !loadingScreen && tripItem && (
            <BookedTrip tripItem={tripItem} tripId={this.props.tripId} />
          )}
          {loadingScreen && <Loader />}
          {!loadingScreen && getItem("tokenDetails") && (
            <Link to="/my-trips">{getTranslation("viewPrevFlights")}</Link>
          )}
        </div>
        <Toast
          open={error}
          message={errorMsg}
          handleClose={this.handleToastClose}
        />
      </Wrapper>
    );
  }
}

export default withRouter(
  compose(
    graphql(generateEticket, { name: "generateEticket" }),
    graphql(bookingByTripIdGql, { name: "bookingByTripId" }))(FlightResults)
);

