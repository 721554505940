import React from "react";
import styled from "styled-components";
import FlightNumAndDate from "components/FlightSegment/Partials/FlightNumAndDate";
import { AirlineImg, AirlineName, Button } from "components/common";
import {
  EconomyClassIcon,
  PremiumClassIcon,
  BusinessClassIcon,
  FirstClassIcon,
} from "components/common/SvgIcons";
import HopSegActionBtn from "components/common/HopSegActionBtn";
import HopSegButtonLoader from "components/common/HopSegButtonLoader";
import { addDecimal, numberToLocaleString, getItem } from "utils/utils";

const Card = styled.div`
  width: 340px;
  background-color: ${(props) => (props.selected ? "#D0F3FC" : "#fff")};
  margin-bottom: 15px;
  border: ${(props) =>
    props.selected ? "2px solid #56CCF2" : "1px solid #56CCF2"};
  border-radius: 8px;
  display: flex;
  flex-flow: column;
  padding-bottom: 10px;
  align-items: center;
  font-size: 14px;
  color: #444444;
  @media (max-width: 800px) {
    width: 100%;
    margin-bottom: 10px;
  }
  .row {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
    .price {
      color: #007ac7;
      font-size: 12px;
    }
  }
  .btnWrapper {
    width: 165px;
    margin-bottom: 10px;
    &.selected {
      .MuiFab-root {
        color: #eb5757;
      }
    }
  }
`;

export default class extends React.PureComponent {
  components = {
    ECONOMY: EconomyClassIcon,
    PREMIUM: PremiumClassIcon,
    BUSINESS: BusinessClassIcon,
    FIRST: FirstClassIcon,
  };
  getCost = (price) =>
    numberToLocaleString(addDecimal(price.priceDiff, price.costDecimalPlaces), {
      selectedCountry: getItem("selectedLanguage"),
      selectedCurrency: price.currencyIso,
    });
  handleSelect = () => {
    const { details } = this.props;
    const cost = this.getCost(details);
    this.props.handleSelect(
      details,
      `${details.priceDiff > 0 ? "+" : ""} ${cost}`
    );
  };
  render() {
    const {
      details,
      hopSeqNum,
      selected,
      dummy,
      otherFlight,
      loading,
    } = this.props;
    let hopRequest;
    let dummySeatClass;
    let flightInfo;
    if (!loading) {
      if (!dummy) {
        hopRequest = details.hopRequests.find(
          (item) => item.hopSeqNum === hopSeqNum
        );
      } else {
        hopRequest = details;
        if (otherFlight) {
          const flight = otherFlight.hopRequests.find(
            (item) => item.hopSeqNum === hopSeqNum
          );
          dummySeatClass = flight.hopSegmentControls[0].seatClass;
        } else {
          dummySeatClass = hopRequest.hopSegmentControls[0].seatClass;
        }
      }

      flightInfo = hopRequest.hopSegmentControls[0].flightSegments[0];
    }
    const { seatClass } = hopRequest ? hopRequest.hopSegmentControls[0] : {};
    const seatClassText = !dummy ? seatClass : dummySeatClass;
    const SeatIcon = this.components[seatClass || "ECONOMY"];
    return (
      <Card selected={selected}>
        {!loading ? (
          <React.Fragment>
            <div className="row">
              <FlightNumAndDate flightInfo={flightInfo} />
            </div>
            <div className="row airline">
              <AirlineImg
                airlineIc={flightInfo.airlineIc}
                style={{ height: 30 }}
              />
            </div>
            <div className="row">
              <AirlineName airlineIc={flightInfo.airlineIc} />
              {/* <span>{seatClassText.toLowerCase()} class</span> */}
            </div>
            {/* <div className="row">
              <SeatIcon stroke="#777777" />
            </div> */}
            <div className={`btnWrapper ${selected ? "selected" : ""}`}>
              <HopSegActionBtn
                disabled={dummy}
                onClick={this.handleSelect}
                selected={selected}
              >
                <SeatIcon stroke="#222222" />
                <b className="__release-text">
                  {seatClassText.toLowerCase()}{" "}
                  {seatClass === "PREMIUM" ? "Economy" : "class"}
                </b>
              </HopSegActionBtn>
              {/* <Button
                style={{
                  height: 36,
                }}
                disabled={dummy}
                color={selected ? "#F0F0F0" : undefined}
                onClick={this.handleSelect}
              >
                {getTranslation(selected ? "discard" : "select")}
              </Button> */}
            </div>
            <div className="row">
              {!dummy ? (
                <span className="price">
                  <span style={{ color: "#222222" }}>
                    {details.priceDiff > 0 ? "Add" : "Save"}
                  </span>{" "}
                  {details.priceDiff > 0 ? " +" : " "} {this.getCost(details)}
                </span>
              ) : (
                <span className="price">-</span>
              )}
            </div>
          </React.Fragment>
        ) : (
          <HopSegButtonLoader type="seatClass" />
        )}
      </Card>
    );
  }
}
