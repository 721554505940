import React, { PureComponent } from "react";
import styled from "styled-components";
import { FadeLine } from "components/common";
import Modal from "components/common/Modal";
import ExtraBaggage from "./ExtraBaggage";
import FlightNumAndDate from "components/FlightSegment/Partials/FlightNumAndDate";
import { addDecimal, numberToLocaleString, getItem } from "utils/utils";
import { ModalSelectedTitle } from "components/common/CommonStyles";
import getTranslation from "utils/getTranslation";

const Wrapper = styled.div`
  width: 616px;
  @media (max-width: 800px) {
    width: 100%;
  }
  .container {
    width: 375px;
    margin: auto;
    @media (max-width: 800px) {
      width: 100%;
      padding: 12px;
    }
    h4 {
      font-size: 22px;
      font-weight: 400;
      color: #444444;
    }
    .price-breakdown {
      padding: 0 16px;
      .item {
        display: flex;
        padding: 15px 0;
        &.passenger {
          font-size: 18px;
          margin-top: 10px;
          font-family: "Roboto Condensed", sans-serif;
          .passengerType {
            display: flex;
            align-items: center;
            img {
              margin-right: 10px;
            }
          }
          .cost {
            font-size: 22px;
            color: ${props => props.theme.secondary};
          }
        }
        div {
          width: 70%;
          &.price {
            width: 30%;
          }
          &:last-child {
            text-align: right;
          }
        }
      }
    }
  }
`;

class InfoModal extends PureComponent {
  state = {};
  componentWillMount() {
    this.handleFsCodeCheck(this.props.hopRequests);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.hopRequests !== this.props.hopRequests) {
      this.handleFsCodeCheck(nextProps.hopRequests);
    }
  }
  getTotalPrice = (passenger, hopRequest, returnPrice, passengerNum) => {
    const { priceBreakups, extraBaggages } = hopRequest.hopSegmentControls[0];
    const price = priceBreakups.filter(
      item => item.passengerTyp === passenger.passengerTyp && !item.isTax
    );
    const sum = price.reduce(
      (accumulator, currentVal) => accumulator + currentVal.cost,
      0
    );
    let baggageCost = 0;
    let additionalBaggageCost = 0;
    const extraBaggage = extraBaggages.find(
      item => item.quantity > 0 && item.passengerNum === passengerNum
    );
    if (extraBaggage) {
      baggageCost = extraBaggage.quantity * extraBaggage.serviceCostAmount;
    }
    if (this.state.sameFsCode) {
      additionalBaggageCost = this.getSameFsCodeBaggageCost();
    }
    const { costDecimalPlaces, costCurrencyIso } = price[0];
    const finalPrice = baggageCost + sum + additionalBaggageCost;
    return returnPrice
      ? finalPrice
      : this.getLocalizedPrice(finalPrice, costDecimalPlaces, costCurrencyIso);
  };

  getTotalTripPrice = hopRequest => {
    let totalCost = 0;
    this.props.passengers.forEach((passenger, i) => {
      totalCost += this.getTotalPrice(passenger, hopRequest, true, i + 1);
    });
    const {
      costDecimalPlaces,
      costCurrencyIso
    } = hopRequest.hopSegmentControls[0].priceBreakups[0];
    return this.getLocalizedPrice(
      totalCost,
      costDecimalPlaces,
      costCurrencyIso
    );
  };

  getBaseFare = (priceBreakups, passengerTyp) => {
    const totalPrice = priceBreakups.find(
      item => item.passengerTyp === passengerTyp && !item.isTax
    );
    const taxPrices = priceBreakups.filter(
      item => item.passengerTyp === passengerTyp && item.isTax
    );
    const totalTax = taxPrices.reduce(
      (accumulator, currentVal) => accumulator + currentVal.cost,
      0
    );
    return totalPrice.cost - totalTax;
  };

  getSameFsCodeBaggageCost = () => {
    let baggageCost = 0;
    this.props.hopRequests.forEach((hopR, index) => {
      if (index > 0) {
        this.props.passengers.forEach((passenger, passengerIndex) => {
          const extraBaggage = hopR.hopSegmentControls[0].extraBaggages.find(
            item =>
              item.quantity > 0 && item.passengerNum === passengerIndex + 1
          );
          if (extraBaggage) {
            baggageCost =
              baggageCost +
              extraBaggage.quantity * extraBaggage.serviceCostAmount;
          }
        });
      }
    });
    return baggageCost;
  };

  getLocalizedPrice = (cost, costDecimalPlaces, costCurrencyIso) =>
    numberToLocaleString(addDecimal(cost, costDecimalPlaces), {
      selectedCountry: getItem("selectedLanguage"),
      selectedCurrency: costCurrencyIso
    });

  handleFsCodeCheck = hopRequests => {
    let fsCode;
    hopRequests.forEach(hopRequest => {
      if (hopRequest.hopSegmentControls[0].fscode === fsCode) {
        this.setState({
          sameFsCode: true
        });
      } else {
        fsCode = hopRequest.hopSegmentControls[0].fscode;
      }
    });
  };
  handleClose = () => {
    this.props.handleCloseModal();
  };

  render() {
    const { sameFsCode } = this.state;
    const {
      open,
      handleCloseModal,
      title,
      passengers,
      hopRequests
    } = this.props;
    return (
      <Modal
        open={open}
        title={
          <ModalSelectedTitle>
            <span>{getTranslation("totalPrice")}</span>
            <span className="price">{title}</span>
          </ModalSelectedTitle>
        }
        handleCloseModal={handleCloseModal}
        dialogStyle={{ width: 616, maxWidth: 616 }}
        titleHtml
        titleStyle={{ padding: "8px 8px 8px 24px" }}
      >
        <Wrapper>
          <div className="container">
            <h4>{getTranslation("totalPriceIncludes")}:</h4>
            {hopRequests.map(
              (hopRequest, index) =>
                (index === 0 || !sameFsCode) && (
                  <React.Fragment>
                    {!sameFsCode && (
                      <FlightNumAndDate
                        flightInfo={
                          hopRequest.hopSegmentControls[0].flightSegments[0]
                        }
                        style={{ marginTop: 20 }}
                        withPrice
                        price={this.getTotalTripPrice(hopRequest)}
                      />
                    )}
                    {passengers.map((item, i) => (
                      <div className="price-breakdown">
                        <div className="item passenger">
                          <div className="passengerType">
                            <img
                              src={require(`assets/icons/${item.type}.svg`)}
                              alt=""
                            />{" "}
                            #{i + 1}
                          </div>
                          <div className="cost">
                            {this.getTotalPrice(item, hopRequest, false, i + 1)}
                          </div>
                        </div>
                        <FadeLine />
                        {hopRequest.hopSegmentControls[0].priceBreakups.map(
                          price =>
                            price.passengerTyp === item.passengerTyp && (
                              <React.Fragment key={price.id}>
                                <div className="item">
                                  <div>
                                    {price.isTax
                                      ? `${price.taxName ||
                                          getTranslation("taxUnspecified")}`
                                      : getTranslation("baseFare")}
                                  </div>
                                  <div className="price">
                                    {numberToLocaleString(
                                      addDecimal(
                                        price.isTax
                                          ? price.cost
                                          : this.getBaseFare(
                                              hopRequest.hopSegmentControls[0]
                                                .priceBreakups,
                                              price.passengerTyp
                                            ),
                                        price.costDecimalPlaces
                                      ),
                                      {
                                        selectedCountry: getItem(
                                          "selectedLanguage"
                                        ),
                                        selectedCurrency: price.costCurrencyIso
                                      }
                                    )}
                                  </div>
                                </div>
                                <FadeLine />
                              </React.Fragment>
                            )
                        )}
                        <ExtraBaggage
                          passengerNum={i + 1}
                          extraBaggages={
                            hopRequest.hopSegmentControls[0].extraBaggages
                          }
                          fromAirportIc={
                            sameFsCode ? hopRequest.fromAirportIc : null
                          }
                          toAirportIc={
                            sameFsCode ? hopRequest.toAirportIc : null
                          }
                        />
                        {sameFsCode &&
                          hopRequests.map(
                            (hopR, hopIndex) =>
                              hopIndex > 0 &&
                              passengers.map((item, pIndex) => (
                                <ExtraBaggage
                                  passengerNum={pIndex + 1}
                                  extraBaggages={
                                    hopR.hopSegmentControls[0].extraBaggages
                                  }
                                  fromAirportIc={hopR.fromAirportIc}
                                  toAirportIc={hopR.toAirportIc}
                                />
                              ))
                          )}
                      </div>
                    ))}
                  </React.Fragment>
                )
            )}
          </div>
        </Wrapper>
      </Modal>
    );
  }
}

export default InfoModal;
