/* eslint-disable no-await-in-loop */
import React, { Component } from "react";
import { graphql, compose } from "react-apollo";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { isMobile } from "react-device-detect";
import Drawer from "@material-ui/core/Drawer";
import { Link, withRouter } from "react-router-dom";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import moment from "moment";
import styled from "styled-components";
import {
  setHopRequestsData,
  setTripCost,
  UpdatePnrReceive,
  updateHopRequestsData,
  HopSegButtonError,
  handleUpdateFilteredResults,
  updateSplitBooking,
  clearFilteredResults,
  togglePayment,
  toggleMutlitrip,
} from "redux-store/actions";
import Modal from "components/common/Modal";
import { Toast, PassengerHeader, Button } from "components/common";
import PaymentComponent from "components/Payment/PaymentComponent";
import HopSegmentComponent from "components/HopSegment/HopSegmentComponent";
import {
  addHOPRequest,
  addHopSegemntControl,
  updTripItineraryQuery,
  getTripById,
  updTripFareruleQuery,
  disableSplitBooking,
  enableSplitBooking,
} from "./queries";
import {
  delTripPassenger,
  updAdpasiQuery,
} from "components/Payment/PaymentQuery";
import { filteredResultsQuery } from "components/common/FilterModal/queries";
import LoadingResults from "./LoadingResults";
import {
  decode,
  setItem,
  getItem,
  removeItem,
  differenceInMinutes,
  capitalizeWords,
  cancelAllRequests,
} from "utils/utils";
import getTranslation from "utils/getTranslation";
import { handleRemovePassenger } from "utils/removePassenger";
import warning from "assets/icons/Sorry.svg";

const Wrapper = styled.div`
  @media (max-width: 800px) {
    .leftSection {
      margin-bottom: 100px;
    }
  }
  @media (min-width: 1000px) {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: auto;
    .leftSection {
      width: 100%;
      .__hop-segments-control-container {
        #hopSegCmp {
          width: 744px;
          margin: auto;
          margin-top: 50px;
        }
      }
    }
    .rightSection {
      width: 30%;
      background-color: #e9e9e9;
      position: relative;
    }
    .__hop-container {
      width: 800px;
      margin: auto;
      .inputsContainer {
        display: inline-flex;
        flex-direction: row;
        > div {
          width: 100% !important;
        }
      }
    }
    .__payment-container {
      width: 320px;
      margin: auto;
      margin-top: 50px;
      .__payment-button {
        position: relative !important;
      }
    }
  }
  .lineWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 100%;
    .line {
      height: 3px;
      background-color: #979797;
      width: 80px;
    }
  }
`;

const NoFlight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 30px;
  max-width: 492px;
  margin: auto;
  h5 {
    color: #444444;
    font-size: 18px;
    font-weight: 400;
    line-height: 150%;
  }
  div {
    min-width: 296px;
    a {
      color: #222222;
      transition: none;
      font-size: 14px;
      text-decoration: none;
    }
  }
`;

class FlightResults extends Component {
  constructor(props) {
    super(props);
    handleRemovePassenger();
    let decodedData = {};
    let noFlight = false;
    const encodedData = props.encoded;
    setItem("encodedTripSearch", encodedData);
    try {
      decodedData = decode(encodedData);
    } catch (err) {
      console.log("Tampered parameter");
      noFlight = true;
    }
    const tripId = parseInt(decodedData[0].tripID, 10) || 0;
    setItem("tripId", tripId);
    let defaultError = {};
    if (getItem("bookingError")) {
      defaultError = {
        error: true,
        errorMsg: getTranslation("bookingFailed"),
      };
      removeItem("bookingError");
    }
    this.state = {
      hopRequestDone: false,
      tripID: tripId,
      hopRequestId: 0,
      hopSegmentData: {},
      // flightSegmentId: 0,
      loadingScreen: !!getItem("tripSearched"),
      loadingMessage: "Connecting...",
      paymentLoading: false,
      paymentDone: false,
      passengers: {},
      costDetails: {},
      hadResults: false,
      decodedData,
      noFlight,
      adpasiFound: getItem("adpasi") || false,
      adpasi: getItem("adpasi"),
      canScroll: true,
      showPassengers: false,
      ...defaultError,
    };
  }

  componentDidMount() {
    if (!isMobile) {
      document.getElementById("root").style.width = "calc(100% - 412px)";
    }
    if (isMobile) {
      window.addEventListener("scroll", this.handleWindowScroll);
    }
    if (!getItem("tripSearched")) {
      if (this.state.decodedData[0].date) {
        const { decodedData } = this.state;
        this.onHopRequestDone(JSON.parse(JSON.stringify(decodedData)));
      }
    } else {
      if (differenceInMinutes(getItem("lastFlightModified")) > 5) {
        console.log("Getting updated Results");
        this.handleUpdTriItenerary();
      } else {
        this.refetchFlights(this.props.client, true);
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedCountry !== this.props.selectedCountry) {
      this.handleUpdTriItenerary();
    }
    if (
      !nextProps.enablePaymentGateway &&
      this.props.enablePaymentGateway !== nextProps.enablePaymentGateway &&
      isMobile
    ) {
      this.setState({
        showPassengers: true,
      });
    }
  }

  componentWillUnmount() {
    document.getElementById("root").style.width = "100%";
    if (isMobile) {
      window.removeEventListener("scroll", this.handleWindowScroll);
    }
    this.props.togglePayment(false);
  }

  onHopRequestDone = async (searchArray) => {
    let hasError = false;
    this.setState({ loadingScreen: true, hopRequestDone: false });
    // searchArray.forEach(async (data, i) => {
    for (let i = 0, j = searchArray.length; i < j; i++) {
      const data = searchArray[i];
      try {
        data.date = moment(data.date * 1000).toDate();
        data.returnDate = moment(data.returnDate * 1000).toDate();
        const month = moment(data.date).format("MM");
        const day = moment(data.date).format("DD");
        const hopReq1 = await this.props.addHOPRequestQuery({
          variables: {
            hopSeqNum: i + 1,
            adultCount: data.passengers.adult,
            childCount: data.passengers.child,
            infantCount: data.passengers.infant,
            stdDate: `${data.date.getFullYear()}-${month}-${day}`,
            fromAirportIc: data.from.airportIc,
            toAirportIc: data.to.airportIc,
            tripId: this.state.tripID,
          },
        });
        if (!hopReq1.data.addHoprequest.id) {
          hasError = true;
        }
        if (moment(data.returnDate).isAfter(data.date)) {
          const returnMonth = moment(data.returnDate).format("MM");
          const returnDay = moment(data.returnDate).format("DD");
          const hopReq = await this.props.addHOPRequestQuery({
            variables: {
              hopSeqNum: 2,
              adultCount: data.passengers.adult,
              childCount: data.passengers.child,
              infantCount: data.passengers.infant,
              stdDate: `${data.returnDate.getFullYear()}-${returnMonth}-${returnDay}`,
              fromAirportIc: data.to.airportIc,
              toAirportIc: data.from.airportIc,
              tripId: this.state.tripID,
            },
          });
          if (!hopReq.data.addHoprequest.id) {
            hasError = true;
          }
        }
        if (i + 1 === searchArray.length && !hasError) {
          if (
            getItem("showMultiBookingIcon") &&
            getItem("splitBooking") === false
          ) {
            await this.props.disableSplitBooking({
              variables: {
                tripId: this.state.tripID,
              },
            });
          }
          this.handleUpdTriItenerary();
        }
        if (i + 1 === searchArray.length && hasError) {
          this.handleNoFlightSegments();
          this.setState({
            loadingScreen: false,
          });
        }
      } catch (err) {
        hasError = true;
        console.log("addHOPRequestQuery", err);
        this.handleNoFlightSegments();
        this.setState({
          loadingScreen: false,
        });
      }
      // });
    }
  };

  onCostUpdate = (costItems) => {
    this.setState({
      costDetails: costItems,
    });
  };

  getMinPassportExpiry = (hopRequests) => {
    const arrivadDate =
      hopRequests[hopRequests.length - 1].hopSegmentControls[0]
        .flightSegments[0].staDate;
    // hopRequests.forEach(req => {
    //   const arrivadDate = req.hopSegmentControls[0].flightSegments[0].staDate;
    //   if (moment(arrivadDate).isAfter(moment(date * 1000))) {
    //     date = moment(req.stdDate).unix();
    //   }
    // });
    const minDate = moment(arrivadDate).add(6, "months");
    setItem("minPassportExpiry", moment(minDate).unix());
  };

  getFilteredResults = () => {
    this.setState({ loadingFiltered: true });
    this.props.client
      .query({
        query: filteredResultsQuery,
        fetchPolicy: "no-cache",
        variables: { tripId: this.state.tripID },
      })
      .then((results) => {
        this.setState({ loadingFiltered: false });
        const mappedResults = results.data.filteredResults.map((filter) => {
          const {
            stdDate,
            staDate,
            stdTime,
            staTime,
            airlineIc,
          } = filter.hopRequests[0].hopSegmentControls[0].flightSegments[0];
          return {
            ...filter,
            departureTime: moment(`${stdDate} ${stdTime}`).unix(),
            arrtivalTime: moment(`${staDate} ${staTime}`).unix(),
            airlineIc,
            totalCost: filter.baseFare + filter.totalTax,
          };
        });
        this.props.handleUpdateFilteredResults(mappedResults);
      })
      .catch((err) => {
        console.log("getFilteredResults error", err);
        this.setState({ loadingFiltered: false });
      });
  };

  handleUpdTriItenerary = (
    retry,
    from,
    type,
    value,
    refetchFiltered,
    priceNum
  ) => {
    window.scrollTo(0, 0);
    if (getItem("cancelBeforeResult")) {
      removeItem("cancelBeforeResult");
    }
    this.setState({ loadingScreen: true, adpasiFound: false });
    this.props
      .updTripItinerary({
        variables: {
          tripId: this.state.tripID,
          priceNum: priceNum ? priceNum : undefined,
        },
      })
      .then((itenaryData) => {
        if (!getItem("cancelBeforeResult")) {
          // console.log("UpdTriItenerary call succeeded");
          removeItem("doAsyncFareRule");
          setItem("tripSearched", true);
          setItem("lastFlightModified", moment().unix());
          let { hopRequests } = itenaryData.data.updTripItinerary;
          const noFlight =
            hopRequests[0].hopSegmentControls[0].flightSegments.length === 0;
          if (from === "hopSegButton" && noFlight) {
            this.handleHopReqUpdateError(type, value);
            return;
          }
          const { priceNum } = itenaryData.data.updTripItinerary;
          const savedTimes = getItem("savedPriceNumTimes") || {};
          const currentRankLastTime = savedTimes[`priceNum${priceNum}`]
            ? savedTimes[`priceNum${priceNum}`].lastTime
            : null;
          setItem("savedPriceNumTimes", {
            ...savedTimes,
            [`priceNum${priceNum}`]: { lastTime: moment().unix() },
          });
          if (hopRequests[0].passengers.length === 0 && getItem("passengers")) {
            hopRequests = hopRequests.map((hopRequest) => ({
              ...hopRequest,
              passengers: getItem("passengers"),
            }));
          }
          this.props.setHopRequestsData(hopRequests);
          this.props.setTripCost(
            itenaryData.data.updTripItinerary.tripCostItems
          );
          this.props.updateSplitBooking(
            itenaryData.data.updTripItinerary.splitBooking
          );
          this.getMinPassportExpiry(hopRequests);
          this.handleHopSegInitialValues(hopRequests);
          const showPassengers = this.handlePassengerCheck(hopRequests[0]);
          this.setState(
            {
              hopRequestDone: true,
              priceNum: itenaryData.data.updTripItinerary.priceNum,
              loadingScreen: false,
              hadResults: !this.state.hadResults ? !noFlight : true,
              filterKvs: itenaryData.data.updTripItinerary.filterKvs,
              adpasiFound: true,
              noFlight,
              showPassengers,
            },
            async () => {
              this.handleTripFareRule(0);
              if (!noFlight) {
                this.handleExtraCheck(1);
              }
              if (this.props.filteredResults.length === 0 || refetchFiltered) {
                this.props.clearFilteredResults();
                this.getFilteredResults();
              }
            }
          );
        }
      })
      .catch((err) => {
        console.log("updTripItinerary error", err.graphQLErrors);
        if (!err) {
          return;
        }
        if (from === "hopSegButton") {
          this.handleHopReqUpdateError(type, value);
          return;
        }
        if (
          err.graphQLErrors.length > 0 &&
          !retry &&
          err.graphQLErrors[0].data.internal_error.includes("ERSER002")
        ) {
          this.handleUpdTriItenerary(true);
        } else {
          this.handleNoFlightSegments(type, value);
        }
      });
  };

  handleHopReqUpdateError = (type, value) => {
    this.setState({
      error: true,
      errorMsg: `No Result found for ${capitalizeWords(value)} class`,
      loadingScreen: false,
      adpasiFound: true,
    });
    this.props.HopSegButtonError({ type, value });
  };

  handleHopSegInitialValues = (hopRequests) => {
    let values = [];
    const initialValues = getItem("hopSegInitialValues") || [];
    hopRequests.forEach((hopRequest) => {
      const { seatClass, dateChangePenalty } = hopRequest.hopSegmentControls[0];
      const {
        airlineIc,
        flightNum,
      } = hopRequest.hopSegmentControls[0].flightSegments[0];
      const oldValues = initialValues.find(
        (item) =>
          item.hopSeqNum === hopRequest.hopSeqNum &&
          item.flightNum === airlineIc + flightNum
      );
      if (oldValues) {
        values = [...values, oldValues];
      } else {
        const payload = {
          hopSeqNum: hopRequest.hopSeqNum,
          seatClass,
          dateChangePenalty,
          flightNum: airlineIc + flightNum,
        };
        values = [...values, payload];
      }
    });

    setItem("hopSegInitialValues", values);
  };

  handleExtraCheck = async (count) => {
    try {
      removeItem("extraCheckDone");
      this.setState({ adpasiFound: false });
      const adpasiData = await this.props.updAdpasiQuery({
        variables: {
          tripId: this.state.tripID,
        },
      });
      this.props.setHopRequestsData(adpasiData.data.extraCheck.hopRequests);
      this.setState(
        {
          adpasi: adpasiData.data.extraCheck.adpasi,
          passportDetails: adpasiData.data.extraCheck.passportDetails,
          adpasiFound: true,
        },
        () => {
          setItem("adpasi", this.state.adpasi);
        }
      );
      setItem("extraCheckDone", true);
      // console.log("Extra check done");
    } catch (err) {
      if (count < 2) {
        setTimeout(() => {
          this.handleExtraCheck(count + 1);
        }, 2000);
      } else {
        this.setState({ adpasiFound: true });
      }
    }
  };

  handleTripFareRule = async (retries) => {
    this.setState({ fareRulesLoading: true });
    try {
      await this.props.updTripFarerule({
        variables: {
          tripId: this.state.tripID,
        },
      });
      this.setState({
        fareRulesLoading: false,
        fareRulesFound: true,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        error: true,
        fareRulesLoading: false,
        errorMsg: getTranslation("fareRuleUnable"),
      });
    }
  };

  handleAddNewHopComposite = (data) => {
    this.onHopRequestDone(data);
  };

  goToTripBooking = () => {
    this.props.history.push("/trip");
  };

  handleNoFlightSegments = () => {
    removeItem("tripId");
    this.setState({
      noFlight: true,
      loadingScreen: false,
    });
  };

  refetchFlights = (client, refreshed) => {
    console.log("Refetch");
    this.setState({ loadingScreen: true, adpasiFound: false });
    client
      .query({
        query: getTripById,
        variables: { id: this.state.tripID },
        fetchPolicy: "no-cache",
      })
      .then((tripData) => {
        try {
          if (tripData.data.tripById.length > 0) {
            console.log("GOT refetched Trip Data");
            const tripDetails = tripData.data.tripById[0];
            this.props.setHopRequestsData(tripDetails.hopRequests);
            this.props.setTripCost(tripDetails.tripCostItems);
            this.props.updateSplitBooking(tripDetails.splitBooking);
            if (this.props.filteredResults.length === 0) {
              this.getFilteredResults();
            }
            if (!refreshed) {
              this.props.UpdatePnrReceive();
            }
            if (refreshed) {
              const noFlight =
                tripDetails.hopRequests[0].hopSegmentControls[0].flightSegments
                  .length === 0;
              const {
                farerules,
              } = tripDetails.hopRequests[0].hopSegmentControls[0];
              if (farerules.length === 0) {
                this.handleTripFareRule(0);
              }
              const showPassengers = this.handlePassengerCheck(
                tripDetails.hopRequests[0]
              );
              this.setState({
                hopRequestDone: true,
                priceNum: tripDetails.priceNum,
                loadingScreen: false,
                hadResults: !this.state.hadResults ? !noFlight : true,
                filterKvs: tripDetails.filterKvs,
                noFlight,
                adpasiFound: true,
                fareRulesFound: farerules.length > 0,
                showPassengers,
                passportDetails: tripDetails.passportDetails,
              });
            }
          } else {
            console.log("SOMETHING WENT WRONG");
            this.setState({
              noFlight: true,
            });
          }
        } catch (err) {
          this.setState({
            noFlight: true,
          });
        }
      })
      .catch((err) => {
        console.log("refetchFlights error", err);
        this.setState({
          noFlight: true,
        });
      });
  };

  handleMultiBookingDisable = async () => {
    try {
      await this.props.disableSplitBooking({
        variables: {
          tripId: this.state.tripID,
        },
      });
      setItem("showMultiBookingIcon", true);
      this.handleRemoveAllPassengers();
    } catch (err) {
      console.log(err);
    }
  };

  handleMultiBookingEnable = async () => {
    try {
      await this.props.enableSplitBooking({
        variables: {
          tripId: this.state.tripID,
        },
      });
      setItem("showMultiBookingIcon", true);
      this.handleRemoveAllPassengers();
    } catch (err) {
      console.log(err);
    }
  };

  handlePassengerCheck = (hopRequest) => {
    let showPassengers = false;
    const { adultCount, infantCount, childCount } = hopRequest;
    const totalPassengers = adultCount + infantCount + childCount;
    if (totalPassengers === hopRequest.passengers.length && isMobile) {
      showPassengers = true;
    }
    return showPassengers;
  };

  handleRemoveAllPassengers = async () => {
    const passengers = JSON.parse(
      JSON.stringify(this.props.hopRequests[0].passengers)
    );
    const arrayLength = passengers.length;
    for (let i = 0, j = arrayLength; i < j; i++) {
      const passenger = passengers[i];
      await this.props.delTripPassenger({
        variables: {
          firstName: passenger.firstName,
          lastName: passenger.lastName,
          gender: passenger.gender,
          birthdate: passenger.birthdate,
          tripId: this.state.tripID,
        },
      });
      handleRemovePassenger(passenger);
      if (i + 1 === passengers.length) {
        this.handleUpdTriItenerary("", "", "", "", true);
      }
    }
    if (passengers.length === 0) {
      this.handleUpdTriItenerary("", "", "", "", true);
    }
  };

  handleFareError = () => {
    this.setState({ noFlight: true });
  };

  handleRefetchFlights = () => {
    this.refetchFlights(this.props.client, true);
  };

  handleRequestClose = () => {
    this.setState({
      error: false,
    });
  };

  handleWindowScroll = () => {
    if (window.scrollY === 0 && !this.state.canScroll) {
      this.setState({
        canScroll: true,
      });
    }
  };

  handleScroll = () => {
    window.scroll({
      top: document.getElementById("rightSection").offsetTop,
      behavior: "smooth",
    });
    this.setState({
      canScroll: false,
    });
  };

  handleRightSectionClick = () => {
    if (this.state.canScroll) {
      this.handleScroll();
    }
  };

  handleDrawerToggle = () => {
    if (!this.state.loadingScreen && this.state.adpasiFound) {
      this.setState({ showPassengers: !this.state.showPassengers });
    }
  };

  handleDrawerClose = () => {
    this.setState({ showPassengers: false });
  };

  handleCancel = () => {
    cancelAllRequests();
    this.goToTripBooking();
  };

  handleMultiTrip = (value) => {
    this.props.toggleMutlitrip(value);
  };

  render() {
    const {
      noFlight,
      loadingScreen,
      hopRequestDone,
      priceNum,
      adpasiFound,
      filterKvs,
      errorMsg,
      error,
      fareRulesLoading,
      fareRulesFound,
      loadingFiltered,
      showPassengers,
      decodedData,
      passportDetails,
    } = this.state;
    const { client, enablePaymentGateway } = this.props;
    return (
      <div>
        {noFlight && (
          <NoFlight>
            <img src={warning} height="50" alt="" />
            <h5>{getTranslation("noFlights")}</h5>
            <div className="searchAgain">
              <Link to="/trip">
                <Button>{getTranslation("searchAgain")}</Button>
              </Link>
            </div>
          </NoFlight>
        )}
        {!noFlight && (
          <Wrapper>
            <div
              className="leftSection"
              id="leftSection"
              style={{ marginBottom: enablePaymentGateway ? 0 : null }}
            >
              {loadingScreen && (
                <LoadingResults
                  hopRequests={decodedData}
                  dummy
                  handleMultiTrip={this.handleMultiTrip}
                  handleCancel={this.handleCancel}
                />
              )}
              <ReactCSSTransitionGroup
                transitionName="example"
                transitionAppearTimeout={500}
                transitionEnter
                transitionLeave={false}
              >
                {!loadingScreen && (
                  <HopSegmentComponent
                    client={client}
                    tripId={this.state.tripID}
                    priceNum={priceNum}
                    loading={loadingScreen}
                    hopRequestDone={hopRequestDone}
                    hopRequestId={this.state.hopRequestId}
                    hopSegmentData={this.state.hopSegmentData}
                    paymentDone={this.state.paymentDone}
                    onCancel={this.goToTripBooking}
                    onCostUpdate={this.onCostUpdate}
                    handleNoFlightSegments={this.handleNoFlightSegments}
                    refetchFlights={() => this.refetchFlights(client)}
                    handleRefetchFlights={this.handleRefetchFlights}
                    addNewHopComposite={this.handleAddNewHopComposite}
                    handleUpdTriItenerary={this.handleUpdTriItenerary}
                    extraCheckDone={adpasiFound}
                    fareRulesLoading={fareRulesLoading}
                    fareRulesFound={fareRulesFound}
                    filterKvs={filterKvs}
                    loadingFiltered={loadingFiltered}
                    handleMultiBookingDisable={this.handleMultiBookingDisable}
                    handleMultiBookingEnable={this.handleMultiBookingEnable}
                  />
                )}
              </ReactCSSTransitionGroup>
            </div>
            {isMobile && !enablePaymentGateway && (
              <PassengerHeader
                loading={loadingScreen || !adpasiFound}
                fixed
                handleClose={this.handleDrawerToggle}
              />
            )}
          </Wrapper>
        )}
        <Toast
          open={error}
          message={errorMsg}
          handleClose={this.handleRequestClose}
        />
        {!isMobile && (
          <Drawer
            className="passengerDrawer"
            variant="permanent"
            anchor="right"
            style={{
              width: 412,
            }}
          >
            <PaymentComponent
              tripId={this.state.tripID}
              hopRequestId={this.state.hopRequestId}
              adpasi={this.state.adpasi}
              passportDetails={this.state.passportDetails}
              adpasiFound={adpasiFound}
              costItems={!hopRequestDone ? {} : this.state.costDetails}
              disabled={
                !hopRequestDone || Boolean(this.props.pnrDone) || !adpasiFound
              }
              handleMutliBookingWarningDeny={this.handleMultiBookingDisable}
              handleFareError={this.handleFareError}
              decodedData={decodedData}
            />
          </Drawer>
        )}
        {isMobile && (
          <Modal
            className="passengerDrawer"
            anchor="bottom"
            open={showPassengers}
            handleCloseModal={this.handleDrawerToggle}
            dialogStyle={{ backgroundColor: "#fff" }}
            slide
          >
            <PaymentComponent
              tripId={this.state.tripID}
              hopRequestId={this.state.hopRequestId}
              adpasi={this.state.adpasi}
              passportDetails={this.state.passportDetails}
              adpasiFound={adpasiFound}
              costItems={!hopRequestDone ? {} : this.state.costDetails}
              disabled={
                !hopRequestDone || Boolean(this.props.pnrDone) || !adpasiFound
              }
              handleMutliBookingWarningDeny={this.handleMultiBookingDisable}
              handleFareError={this.handleFareError}
              handleClosePassenger={this.handleDrawerClose}
            />
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  pnrDone: state.reducers.payment.pnrDone,
  selectedCountry: state.reducers.selectedCountry,
  filteredResults: state.flightResults.filteredResults,
  hopRequests: state.flightResults.hopRequests,
  enablePaymentGateway: state.flightResults.enablePaymentGateway,
});

const mapDispatchToProps = {
  setHopRequestsData,
  setTripCost,
  push,
  UpdatePnrReceive,
  updateHopRequestsData,
  HopSegButtonError,
  handleUpdateFilteredResults,
  updateSplitBooking,
  clearFilteredResults,
  togglePayment,
  toggleMutlitrip,
};

export default withRouter(
  compose(
    graphql(addHOPRequest, { name: "addHOPRequestQuery" }),
    graphql(addHopSegemntControl, {
      name: "addHopSegmentControlsForHoprequest",
    }),
    graphql(updTripItineraryQuery, { name: "updTripItinerary" }),
    graphql(updAdpasiQuery, { name: "updAdpasiQuery" }),
    graphql(updTripFareruleQuery, { name: "updTripFarerule" }),
    graphql(disableSplitBooking, { name: "disableSplitBooking" }),
    graphql(enableSplitBooking, { name: "enableSplitBooking" }),
    graphql(delTripPassenger, { name: "delTripPassenger" }),
    connect(mapStateToProps, mapDispatchToProps)
  )(FlightResults)
);
