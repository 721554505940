import React from "react";
import styled from "styled-components";
import { removeItem, clearLocalStore } from "utils/utils";
import getTranslation from "utils/getTranslation";
import warning from "assets/icons/WarningIcon.svg";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  div {
    max-width: 492px;
    text-align: center;
    h3 {
      font-size: 18px;
      color: #444444;
      font-weight: 400;
      line-height: 150%;
    }
    @media (max-width: 800px) {
      width: 90%;
    }
  }
`;

const Failed = ({ text }) => {
  removeItem("tripId");
  clearLocalStore();
  return (
    <Wrapper>
      <div>
        <img src={warning} alt="" />
        <h3>{text}</h3>
      </div>
    </Wrapper>
  );
};

export default Failed;
