import React from "react";
import styled from "styled-components";
import {
  HeroSection,
  About,
  Benefits,
  Features,
  NewDestinations,
  HighlitedInfluencer
} from "./Partials";

const Wrapper = styled.div`
  background-color: #fff;
  position: relative;
  .main-container {
    width: 1250px;
    margin: auto;
    @media (max-width: 800px) {
      width: 100%;
      padding: 0 16px;
    }
  }
  h1 {
    letter-spacing: 0.2px;
    color: #007ac7;
    font-size: 36px;
    line-height: 50px;
    font-weight: 400;
  }
  h5 {
    color: #007ac7;
    font-size: 26px;
    margin-bottom: 40px;
    font-weight: 400;
  }
  p {
    font-weight: 300;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.2px;
    color: #002a44;
  }
`;

export default class extends React.PureComponent {
  handleSetup = () => {
    this.props.history.push("/influencer/setup/step1");
  };
  render() {
    return (
      <Wrapper>
        <div className="main-container">
          <HeroSection />
          <About />
          <Benefits />
        </div>
        <Features />
        <NewDestinations />
        <HighlitedInfluencer />
      </Wrapper>
    );
  }
}
