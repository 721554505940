import config from "config";
import { getItem } from "utils/utils";

const handleErrors = async (response) => {
  if (!response.ok) {
    if (response.status === 404) return null;
    throw await response.json();
  }
  return response;
};

const fetchOverCustom = async (url, params, headers) => {
  const fetched = await fetch(url, {
    ...params,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...headers,
    },
  });
  try {
    const noError = await handleErrors(fetched);
    const json = noError ? await noError.json() : null;
    return json;
  } catch (err) {
    throw err;
  }
};

const fetchOver = async (url, params, header) => {
  const fetched = await fetch(url, {
    ...params,
    headers: {
      Accept:
        "text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8",
      "Content-Type": "application/x-www-form-urlencoded",
      ...header,
    },
  });
  try {
    const noError = await handleErrors(fetched);
    const json = noError ? await noError.json() : null;
    return json;
  } catch (err) {
    throw err;
  }
};

const fetchWithAuth = async (url, params) => {
  const tokenDetails = getItem("tokenDetails");
  const guestLogin = getItem("guestLogin");
  const authHeader = tokenDetails
    ? { authorization: `Bearer ${tokenDetails.access_token}` }
    : {};
  const guestHeader = guestLogin ? { guest_email: guestLogin.email } : {};
  const { searchparams, ...remaining } = params;
  let urlobj = new URL(url);
  if (searchparams) {
    urlobj.search = new URLSearchParams(searchparams);
  }
  const fetched = await fetch(urlobj, {
    ...remaining,
    headers: {
      Accept:
        "text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8",
      "Content-Type": "application/json",
      ...authHeader,
      ...guestHeader,
    },
  });
  try {
    const noError = await handleErrors(fetched);
    const json = noError ? await noError.json() : null;
    return json;
  } catch (err) {
    throw err;
  }
};

export const authenticate = async (payload) => {
  const formData = new FormData();
  formData.append("login", payload.login);
  formData.append("password", payload.password);
  const data = await fetchOver(
    `${config.auth.url}/authorize?response_type=code&client_id=fsWeb`,
    {
      method: "POST",
      body: `login=${payload.login}&password=${payload.password}`,
    }
  );

  return data;
};

export const forgot = async (payload) => {
  const formData = new FormData();
  formData.append("login", payload.login);
  const data = await fetchOver(`${config.auth.url}/forgot`, {
    method: "POST",
    body: `login=${payload.login}`,
  });

  return data;
};
export const resetpassword = async (payload) => {
  // const formData = new FormData();
  // formData.append("password", payload.password);
  // formData.append("code", payload.code);
  // formData.append("email", payload.email);
  const data = await fetchOver(`${config.auth.url}/resetpassword`, {
    method: "POST",
    body: `password=${payload.password}&code=${payload.code}&email=${payload.email}`,
  });

  return data;
};

export const getAccessToken = async (code, refreshToken) => {
  const data = await fetchOver(`${config.auth.url}/token`, {
    method: "POST",
    body: `client_id=${config.auth.client_id}&grant_type=${refreshToken ? "refresh_token" : config.auth.grant_type
      }&redirect_uri=${config.auth.redirect_uri}&${refreshToken ? "refresh_token" : "code"
      }=${encodeURIComponent(code)}`,
  });
  return data;
};

export const getRefreshAuth = async (refreshToken) => {
  const data = await fetchOver(
    `${config.auth.url}/refresh?response_type=code&client_id=fsWeb`,
    {
      method: "POST",
      body: `refresh_token=${refreshToken}`,
    }
  );
  return data;
};

export const signup = async (payload) => {
  const data = await fetchOver(`${config.auth.url}/newuser`, {
    method: "POST",
    body: `email=${encodeURIComponent(payload.email)}&password=${payload.password
      }&first_name=${payload.firstname}&last_name=${payload.lastname}&country=${payload.country
      }`,
  });
  return data;
};

export const validateEmail = async (countryIc, email) => {
  const queryParams = email
    ? "?email=" + email + "&country_ic=" + countryIc
    : "?country_ic=" + countryIc;
  const tokenDetails = getItem("tokenDetails");
  const authHeader = tokenDetails
    ? { authorization: `Bearer ${tokenDetails.access_token}` }
    : {};
  const data = await fetchOver(
    `${config.auth.url}/valemail${queryParams}`,
    {
      method: "GET",
    },
    authHeader
  );
  return data;
};

export const getDateSuggestions = async (airport) => {
  const data = await fetchOver(
    `${config.holidayUrl}/suggestions?airportIc=${airport}`,
    {
      method: "GET",
    }
  );
  return data;
};

export const getCardDetails = async (digits) => {
  const data = await fetchOver(
    `${config.paymentHost}/lookup?first6=${digits}`,
    {
      method: "GET",
    }
  );
  return data;
};

export const paypalCustdomSetupAuthorize = async (payload) => {
  const data = await fetchWithAuth(`${config.paymentHost}/custdom/setup_authorize`, {
    method: "GET",
    searchparams: payload.searchparams
  });
  return data;
};

export const paypalSetupAuthorize = async (payload) => {
  const data = await fetchWithAuth(`${config.paymentHost}/setup_authorize`, {
    method: "GET",
    searchparams: payload.searchparams
  });
  return data;
};

export const paypalCustdomAuthorize = async (payload) => {
  const data = await fetchWithAuth(`${config.paymentHost}/custdom/authorize`, {
    method: "POST",
    body: JSON.stringify(payload),
  });
  return data;
};

export const paypalAuthorize = async (payload) => {
  const data = await fetchWithAuth(`${config.paymentHost}/authorize`, {
    method: "POST",
    body: JSON.stringify(payload),
  });
  return data;
};

export const getRedirectUrl = async (payload) => {
  const data = await fetchWithAuth(`${config.paymentHost}/authorize`, {
    method: "POST",
    body: JSON.stringify(payload),
  });
  return data;
};

export const getCheckoutStatus = async (tripId) => {
  const data = await fetchWithAuth(
    `${config.paymentHost}/checkout_status?tripId=${tripId}`,
    {
      method: "GET",
    }
  );
  return data;
};

export const getDomainRedirectUrl = async (payload) => {
  const data = await fetchWithAuth(`${config.paymentHost}/custdom/authorize`, {
    method: "POST",
    body: JSON.stringify(payload),
  });
  return data;
};

export const getDomainCheckoutStatus = async (customDomainId) => {
  const data = await fetchWithAuth(
    `${config.paymentHost}/custdom/checkout_status?customDomainId=${customDomainId}`,
    {
      method: "GET",
    }
  );
  return data;
};

export const getCardTokens = async () => {
  const data = await fetchWithAuth(`${config.auth.url}/valid_cardtokens`, {
    method: "GET",
  });
  return data;
};

export const submitContactForm = async (payload) => {
  const { url, group, type, priority, accesstoken } = config.helpdesk;
  const subject = `${payload.firstname} ${payload.lastname} (${payload.email}) - ${payload.subject}`;
  const issue = `Comments - ${payload.message}`;
  const data = await fetchOver(
    url,
    {
      method: "POST",
      body: `subject=${subject}&issue=${issue}&group=${group}&type=${type}&priority=${priority}`,
    },
    {
      accesstoken,
    }
  );
  return data;
};

export const submitUserPriceCheck = async (payload) => {
  const { url, group, type, priority, accesstoken } = config.helpdesk;
  const data = await fetchOver(
    url,
    {
      method: "POST",
      body: `subject=${payload.subject}&issue=${payload.issue}&group=${group}&type=${type}&priority=${priority}`,
    },
    {
      accesstoken,
    }
  );
  return data;
};

export const getAirports = async () => {
  const data = await fetchOver("/static/airports.json", {
    method: "GET",
  });
  return data;
};

export const getHotelDests = async () => {
  const data = await fetchOver("/static/hoteldests.json", {
    method: "GET",
  });
  return data;
};


export const uploadImage = async (blobInfo) => {
  const formData = new FormData();
  formData.append("file", blobInfo);
  formData.append("upload_preset", config.cloudinaryUnisgnedUploadPreset);
  try {
    const response = await fetch(config.cloudinaryApiUrl, {
      method: "POST",
      body: formData,
    }).then((response) => response.json());
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const getFacebookAccessToken = async (accessToken) => {
  const data = await fetchOver(
    config.smRelayUrl + "/facebook/long-token/" + accessToken,
    {
      method: "GET",
    }
  );
  return data;
};

export const getTwitterRequestToken = async () => {
  const data = await fetchOver(config.smRelayUrl + "/twitter/request-token", {
    method: "GET",
  });
  return data;
};

export const getTwitterAuthToken = async (payload) => {
  const data = await fetchOverCustom(
    config.smRelayUrl + "/twitter/access-token",
    {
      method: "POST",
      body: JSON.stringify(payload),
    }
  );
  return data;
};

export const handleTwitterPost = async (payload) => {
  const data = await fetchOverCustom(
    config.smRelayUrl + "/twitter/post-status",
    {
      method: "POST",
      body: JSON.stringify(payload),
    }
  );
  return data;
};

export const getTwitterUserDetails = async (payload) => {
  const data = await fetchOverCustom(
    config.smRelayUrl + "/twitter/user-details",
    {
      method: "POST",
      body: JSON.stringify(payload),
    }
  );
  return data;
};

export const test = async () => {
  const data = await fetchOver(config.smRelayUrl + "/test", {
    method: "GET",
  });
  return data;
};

export const schedulePost = async (payload) => {
  const data = await fetchOverCustom(config.smRelayUrl + "/schedule-post", {
    method: "POST",
    body: JSON.stringify(payload),
  });
  return data;
};

export const searchDomain = async (payload) => {
  const data = await fetchOverCustom(
    "https://devcustdom.flystrait.com/api/search",
    {
      method: "POST",
      body: JSON.stringify(payload),
    }
  );
  return data;
};

export const deleteTipAccount = async () => {
  const data = await fetchWithAuth(`${config.auth.url}/delinfl`, {
    method: "POST",
  });
  return data;
};

export const verifyOtp = async (payload) => {
  const data = await fetchOver(`${config.auth.url}/verifyotp`, {
    method: "POST",
    body: `email=${encodeURIComponent(payload.email)}&otp=${payload.otp}`,
  });
  return data;
};

export const getUserCards = async (countryIc) => {
  const tokenDetails = getItem("tokenDetails");
  const authHeader = tokenDetails
    ? { authorization: `Bearer ${tokenDetails.access_token}` }
    : {};
  const data = await fetchOver(
    `${config.auth.url}/usercards?country_ic=${countryIc}`,
    {
      method: "GET",
    },
    authHeader
  );
  return data;
};

export const removeCard = async (cardId) => {
  const tokenDetails = getItem("tokenDetails");
  const authHeader = tokenDetails
    ? { authorization: `Bearer ${tokenDetails.access_token}` }
    : {};
  const data = await fetchWithAuth(
    `${config.auth.url}/delcard`,
    {
      method: "POST",
      body: JSON.stringify({ card_id: cardId }),
    },
    authHeader
  );
  return data;
};

export const registerGuestPhone = async (payload) => {
  const data = await fetchOver(`${config.auth.url}/newmobuser`, {
    method: "POST",
    body: `email=${encodeURIComponent(payload.email)}&mobile=${payload.mobile
      }&country_code=${payload.country_code}`,
  });
  return data;
};
