import React from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import styled, { withTheme } from "styled-components";
import { CloseIcon } from "components/common/SvgIcons";

const Wrapper = styled.div`
  .closeWrapper {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-30%);
    z-index: 99;
    display: none;
  }
  &:hover {
    .closeWrapper {
      display: flex !important;
    }
  }
  &.filled {
    .MuiInputBase-root {
      background-color: rgba(0, 0, 0, 0.09);
    }
  }
`;

const Input = styled(TextField)`
  overflow: hidden;
  .MuiInputBase-root {
    background-color: ${(props) => props.theme.primaryBackground};
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.08),
      inset 0px -1px 0px ${(props) => props.theme.secondary} !important;
  }
  .MuiFilledInput-underline:before {
    border-bottom: 0;
  }
  &.withIcon {
    .MuiInputLabel-filled {
      transform: translate(44px, 29px) scale(1);
      &.MuiFormLabel-filled,
      &.Mui-focused {
        transform: translate(44px, 10px) scale(0.75);
      }
    }
  }
  &.hasError {
    .MuiInputBase-root {
      background-color: #fbdddd;
      box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.08), inset 0px -1px 0px #eb5757 !important;
    }
    label {
      color: #eb5757;
    }
    .MuiFilledInput-underline:after {
      border-bottom-color: #eb5757;
    }
  }
`;
let timeout;
class InputClass extends React.PureComponent {
  state = {
    focused: false,
  };
  componentDidMount() {
    if (this.props.doFocus) {
      setTimeout(() => {
        this._input.focus();
      }, 300);
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.doFocus && this.props.doFocus !== nextProps.doFocus) {
      this._input.focus();
      this.handleFocusUpdate(true);
    }
  }
  componentWillUnmount() {
    document.execCommand("delete", null, false);
  }
  handleClearMouseDown = () => {
    setTimeout(() => {
      clearTimeout(timeout);
    }, 100);
    timeout = null;
    this.setState({ focused: true });
    this.handleFocusUpdate(true);
  };
  handleFocus = () => {
    this.setState({ focused: true });
    this.handleFocusUpdate(true);
    if (this.props.onFocus) {
      this.props.onFocus();
    }
    if (this.props.doSelection) {
      setTimeout(() => {
        document.execCommand("selectall", null, false);
      }, 50);
    }
  };
  handleFocusUpdate = (focus) => {
    if (this.props.handleFocusUpdate) {
      this.props.handleFocusUpdate(focus);
    }
  };
  handleClear = (e) => {
    const target = {
      target: {
        name: this.props.name,
        value: "",
      },
    };
    this.props.onChange(target);
    this._input.focus();
    this.handleFocus();
  };
  handleBlur = () => {
    timeout = setTimeout(() => {
      this.setState({ focused: false });
      this.handleFocusUpdate(false);
    }, 200);
    if (this.props.onBlur) {
      this.props.onBlur();
    }
  };
  render() {
    const {
      className,
      label,
      name,
      type = "text",
      value,
      width,
      disabled,
      marginRight,
      onChange,
      hideClear,
      tabIndex,
      errorState,
      errorText,
      id,
      autoComplete,
      icon,
      multiline,
      rows,
      required,
      onKeyDown,
      theme,
      autoFocus,
    } = this.props;
    return (
      <Wrapper
        style={{ position: "relative", width: width || "100%", marginRight }}
        className={value ? "filled" : ""}
      >
        <Input
          id={id}
          inputRef={(c) => {
            this._input = c;
          }}
          type={type}
          name={name}
          label={label}
          required={required}
          className={`${className} ${icon ? "withIcon" : ""} ${
            errorState || errorText ? "hasError" : ""
          }`}
          value={value}
          disabled={disabled}
          onChange={onChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onKeyDown={onKeyDown}
          tabIndex={tabIndex}
          fullWidth
          margin="normal"
          variant="filled"
          error={errorText}
          helperText={errorText ? errorText : ""}
          multiline={multiline}
          autoComplete={autoComplete}
          rows={rows}
          InputProps={{
            autoComplete: autoComplete,
            autofill: autoComplete,
            autoFocus: autoFocus,
            startAdornment: icon ? (
              <InputAdornment position="start">{icon}</InputAdornment>
            ) : null,
          }}
          // {...rest}
        />
        {value && !hideClear && (
          <div className="closeWrapper">
            <CloseIcon
              stroke={theme.secondary}
              onClick={this.handleClear}
              onMouseDown={this.handleClearMouseDown}
            />
          </div>
        )}
      </Wrapper>
    );
  }
}

export default withTheme(InputClass);
