import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { isMobile } from "react-device-detect";
import { Switch, Route, Link } from "react-router-dom";
import InfluencerDashboardPosts from "containers/Influencer/Dashboard/Posts";
import InfluencerDashboardCreatePosts from "containers/Influencer/Dashboard/Posts/Create";
import InfluencerDashboardEditPost from "containers/Influencer/Dashboard/Posts/Edit";
import InfluencerDashboardMedia from "containers/Influencer/Dashboard/Media";
import InfluencerDashboardConnect from "containers/Influencer/Dashboard/Connect";
import InfluencerDashboardEarning from "containers/Influencer/Dashboard/Earning";
import InfluencerDashboardSettings from "containers/Influencer/Dashboard/Settings";
import { getInfluencer } from "containers/Influencer/queries";
import {
  postIcon,
  mediaIcon,
  connectIcon,
  earningIcon,
  siteActivityIcon,
  settingsIcon
} from "components/common/SvgIcons";
import { setInfluencer } from "redux-store/actions";
import getTranslation from "utils/getTranslation";
import { getItem } from "utils/utils";

const Wrapper = styled.div`
  display: flex;
  background-color: #fff;
  min-height: calc(100vh - 140px);
  .sideMenu {
    display: flex;
    flex-flow: column;
    width: 148px;
    border-right: 1px solid #eeeeee;
    a {
      text-decoration: none;
    }
    .item {
      padding: 18px 0px 15px 24px;
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      height: 72px;
      &.active {
        background: rgba(208, 243, 252, 0.5);
        box-shadow: inset 3px 0px 0px #56ccf2;
        span {
          font-weight: 600;
        }
      }
      img {
        height: 20px;
        margin-bottom: 5px;
      }
      span {
        font-size: 13px;
        color: #007ac7;
        text-transform: uppercase;
        font-family: "Roboto Condensed", sans-serif;
      }
    }
  }
  .content {
    padding: 0 105px;
    width: 100%;
    position: relative;
    @media (max-width: 800px) {
      padding: 0 16px;
    }
  }
`;

const menuItems = [
  {
    name: getTranslation("posts"),
    route: "posts",
    icon: postIcon
  },
  {
    name: getTranslation("media"),
    route: "media",
    icon: mediaIcon
  },
  {
    name: getTranslation("connect"),
    route: "connect",
    icon: connectIcon
  },
  {
    name: getTranslation("earning"),
    route: "earning",
    icon: earningIcon
  },
  {
    name: getTranslation("siteActivity"),
    route: "site-activity",
    icon: siteActivityIcon
  },
  {
    name: getTranslation("settings"),
    route: "settings",
    icon: settingsIcon
  }
];

class Dashboard extends React.PureComponent {
  async componentWillMount() {
    try {
      const influencer = await this.props.client.query({
        query: getInfluencer,
        variables: {
          limit: 1,
          where: {
            fsuseraccount_id: { _eq: getItem("useraccount_id") }
          }
        }
      });
      if (influencer.data.influencer.length > 0) {
        this.props.setInfluencer(influencer.data.influencer[0]);
      }
    } catch (err) {}
  }
  render() {
    const { influencerDetails } = this.props;
    const route = this.props.match.params.route;
    return (
      <Wrapper>
        {!isMobile && (
          <div className="sideMenu">
            {menuItems.map((item,i) => (
              <Link key={i} to={`/influencer/dashboard/${item.route}`}>
                <div
                  className={`item ${item.route === route ? "active" : ""}`}
                >
                  <item.icon
                    stroke={item.route === route ? "#007AC7" : "#56CCF2"}
                  />
                  <span>{item.name}</span>
                </div>
              </Link>
            ))}
          </div>
        )}
        <div className="content">
          {!influencerDetails ? (
            <h1>loading</h1>
          ) : (
            <Switch>
              <Route
                exact
                path="/influencer/dashboard/posts"
                component={InfluencerDashboardPosts}
              />
              <Route
                exact
                path="/influencer/dashboard/posts/create"
                component={InfluencerDashboardCreatePosts}
              />
              <Route
                exact
                path="/influencer/dashboard/posts/:id"
                component={InfluencerDashboardEditPost}
              />
              <Route
                exact
                path="/influencer/dashboard/media"
                component={InfluencerDashboardMedia}
              />
              <Route
                exact
                path="/influencer/dashboard/connect"
                component={InfluencerDashboardConnect}
              />
              <Route
                exact
                path="/influencer/dashboard/earning"
                component={InfluencerDashboardEarning}
              />
              <Route
                exact
                path="/influencer/dashboard/settings"
                component={InfluencerDashboardSettings}
              />
            </Switch>
          )}
        </div>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  influencerDetails: state.influencer.influencerDetails
});

export default withApollo(
  connect(mapStateToProps, { setInfluencer })(Dashboard)
);
