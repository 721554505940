import * as actionTypes from 'redux-store/actions';
import { getItem } from 'utils/utils';

const initialState = {
  addedPassengersStack: [],
  totalPassengers: 0,
  payment: {
    received: false,
    pnrDone: false,
    airlinePnr: {},
    transactionId: '',
  },
  hopSegButtonError: {},
  hasUniqueFsCode: false,
  selectedCountry: getItem('selectedLanguage'),
  disablePassengerSticky: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.INCREASE_TOTAL_PASSENGERS:
      return { ...state, totalPassengers: state.totalPassengers + 1 };
    case actionTypes.DECREASE_TOTAL_PASSENGERS:
      return { ...state, totalPassengers: state.totalPassengers - 1 };
    case actionTypes.ADD_PASSENGER_TO_STACK:
      return { ...state, addedPassengersStack: state.addedPassengersStack.concat(action.payload) };
    case actionTypes.UPDATE_PAYMENT_RECEIVE:
      return {
        ...state,
        payment: {
          ...state.payment,
          received: true,
          transactionId: action.payload,
        },
      };
    case actionTypes.UPDATE_PNR_RECEIVE:
      return { ...state, payment: { ...state.payment, pnrDone: true } };
    case actionTypes.HOP_SEG_BUTTON_ERROR:
      return { ...state, hopSegButtonError: action.payload };
    case actionTypes.UNIQUE_FS_CODE:
      return { ...state, hasUniqueFsCode: true };
    case actionTypes.REMOVE_UNIQUE_FS_CODE:
      return { ...state, hasUniqueFsCode: false };
    case actionTypes.UPDATE_COUNTRY:
      return { ...state, selectedCountry: action.payload };
    case actionTypes.DISABLE_PASSENGER_STICKY:
      return { ...state, disablePassengerSticky: true };
    case actionTypes.ENABLE_PASSENGER_STICKY:
      return { ...state, disablePassengerSticky: false };
    default:
      return state;
  }
};
