import React from "react";
import styled from "styled-components";
import { Button } from "components/common";
import { AddIcon } from "components/common/SvgIcons";
import getTranslation from "utils/getTranslation";

const Container = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-bottom: 20px;
`;

const Wrapper = styled.div`
  background-color: #d0f3fc;
  border-radius: 8px;
  height: 48px;
  padding: 4px;
  display: flex;
  width: fit-content;
  margin-right: 5px;
  .active {
    .MuiFab-label {
      font-weight: 600;
    }
  }
`;

export default class extends React.PureComponent {
  render() {
    const {
      daysArray,
      selectedDayIndex,
      handleDayChange,
      handleIncrease
    } = this.props;
    return (
      <Container>
        <Wrapper>
          {daysArray.map((item, index) => (
            <div
              key={index}
              style={{ marginLeft: index > 0 ? 8 : 0 }}
              className={selectedDayIndex === index ? "active" : ""}
            >
              <Button
                style={{
                  height: 40,
                  color: selectedDayIndex === index ? "#222222" : "#007AC7",
                  borderRadius: 8
                }}
                color={selectedDayIndex === index ? "#56CCF2" : "#D0F3FC"}
                onClick={() => handleDayChange(index)}
              >
                {getTranslation("day")} {index + 1}
              </Button>
            </div>
          ))}
        </Wrapper>
        <div style={{ width: 122 }}>
          <Button
            color="#D0F3FC"
            style={{ height: 40, color: "#007AC7" }}
            onClick={handleIncrease}
          >
            <AddIcon stroke="#007AC7" /> <span>{getTranslation("addDay")}</span>
          </Button>
        </div>
      </Container>
    );
  }
}
