import React from "react";
import styled from "styled-components";
import moment from "moment";
import { ItemTitle, DestinationsInput } from "components/Influencer";
import { Input } from "components/common";
import InteneraryDay from "./InteneraryDay";
import DaysTab from "./DaysTab";
import getTranslation from "utils/getTranslation";

const Wrapper = styled.div`
  margin-top: 50px;
  .titleAndDays {
    display: flex;
    .blogtitle {
      width: 100%;
    }
  }
  .daysWrapper {
  }
  .MuiFormControl-marginNormal {
    margin-top: 0;
  }
  .addDayStoryTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export default class extends React.PureComponent {
  constructor(props) {
    super(props);
    let itinerarydays =
      props.data.itinerarydays && props.data.itinerarydays.length > 0
        ? props.data.itinerarydays
        : undefined;
    if (itinerarydays) {
      itinerarydays = itinerarydays.map(item => ({
        ...item,
        itinerarydaytransports: { data: item.itinerarydaytransports },
        __typename: undefined
      }));
      this.props.handleItineraryChange(itinerarydays);
    }
    this.state = {
      daysArray: itinerarydays || [
        { title: "", text: "", itinerarydaytransports: { data: [] } }
      ],
      selectedDayIndex: 0
    };
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.props.handleChange(name, value);
  };

  handleIncrease = () => {
    this.setState({
      daysArray: [
        ...this.state.daysArray,
        { title: "", text: "", itinerarydaytransports: { data: [] } }
      ]
    });
  };
  handleItineraryChange = (name, value, index, transportIndex) => {
    const { daysArray } = this.state;
    if (name === "itinerarydaytransports") {
      if (transportIndex !== undefined) {
        daysArray[index].itinerarydaytransports.data[transportIndex] = value;
      } else {
        daysArray[index].itinerarydaytransports.data = [
          ...daysArray[index].itinerarydaytransports.data,
          value
        ];
      }
    } else {
      daysArray[index] = {
        ...daysArray[index],
        [name]: value
      };
    }
    this.setState({
      daysArray: [...daysArray]
    });
    this.props.handleItineraryChange(daysArray);
  };
  handleDayChange = selectedDayIndex => {
    this.setState({
      selectedDayIndex
    });
  };

  handleRemove = () => {
    const { daysArray, selectedDayIndex } = this.state;
    this.setState({
      daysArray: daysArray.filter(
        (item, index) => index !== this.state.selectedDayIndex
      ),
      selectedDayIndex: selectedDayIndex > 1 ? selectedDayIndex - 1 : 0
    });
  };

  handleRemoveTransport = index => {
    const { daysArray, selectedDayIndex } = this.state;
    const itinerarydaytransportsFilter = daysArray[
      selectedDayIndex
    ].itinerarydaytransports.data.filter((item, i) => i !== index);
    daysArray[
      selectedDayIndex
    ].itinerarydaytransports.data = itinerarydaytransportsFilter;
    this.setState(
      {
        daysArray,
        updated: moment().unix()
      },
      () => {
        this.props.handleItineraryChange(daysArray);
      }
    );
  };
  render() {
    const { daysArray, selectedDayIndex, updated } = this.state;
    const { html, title, data, handleDestinationChange } = this.props;
    const dayNumber = parseInt(selectedDayIndex + 1);
    return (
      <Wrapper className="section">
        <div className="titleAndDays">
          <div className="blogtitle">
            <ItemTitle title={getTranslation("addBlogTitle")} />
            <div style={{ marginBottom: 20 }}>
              <Input
                label={getTranslation("blogTitle")}
                fullWidth
                value={title}
                name="title"
                onChange={this.handleChange}
                hideClear
              />
            </div>
          </div>
        </div>
        <DestinationsInput
          style={{ marginTop: 0, marginBottom: 0 }}
          postdestinations={data.postdestinations}
          placeholder="nearestStartingAirportCity"
          handleChange={handleDestinationChange}
        />
        <DaysTab
          daysArray={daysArray}
          selectedDayIndex={selectedDayIndex}
          handleDayChange={this.handleDayChange}
          handleIncrease={this.handleIncrease}
        />
        <div className="daysWrapper">
          <InteneraryDay
            html={html}
            data={daysArray[selectedDayIndex]}
            selectedDayIndex={selectedDayIndex}
            dayNumber={dayNumber}
            updated={updated}
            handleChange={this.handleItineraryChange}
            handleRemoveTransport={this.handleRemoveTransport}
            handleRemove={this.handleRemove}
          />
        </div>
      </Wrapper>
    );
  }
}
