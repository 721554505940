export default `
# Términos y condiciones

Estos términos y condiciones generales (&quot;T &amp; Cs&quot;) se aplican entre usted (&quot;Usted&quot; o el &quot;Cliente&quot;) y Fly Strait OÜ (&quot;Nosotros&quot; o &quot;Nosotros&quot;), el operador del portal de reservas flystrait.com (en lo sucesivo, como el &quot;Portal&quot;). Se solicita que lea estos términos y condiciones cuidadosamente antes de usar el Portal. Al utilizar el Portal, usted acepta estos términos y condiciones. Si no acepta estos T &amp; C, le pedimos que se abstenga de usar el Portal y salga del sitio web.

Debe tener al menos 18 años de edad y actuar en su rol de consumidor privado para realizar una reserva en el Portal. Un consumidor privado es, a diferencia de un negocio, una persona física que realiza una transacción legal con fines que quedan principalmente fuera de sus actividades comerciales y / o por cuenta propia.

El operador del Portal y su socio contractual para utilizar el Portal es:

Fly Strait OÜ (Fly Strait)

Correo electrónico: support@flystrait.com

Los T&amp;C se exponen de la siguiente manera:

#
# 1. OBJETO DEL CONTRATO.

### 1.1 Mediación de servicios de viaje

1.1.1 Para los servicios de reserva de vuelos que se ofrecen en el Portal (denominados &quot;Servicios de viaje&quot;), actuamos exclusivamente como intermediarios. Con ese fin, nuestra función y obligaciones se limitan a mediar en los Servicios de viaje que serán prestados por terceros, como las aerolíneas (en lo sucesivo, en cada caso, el &quot;Proveedor de servicios&quot;).

1.1.2 En consecuencia, el acuerdo para la prestación real de los Servicios de viaje entra en vigencia directamente entre Usted y el Proveedor de servicios correspondiente. No somos un co-proveedor de los Servicios de viaje y no somos parte de la relación contractual entre usted y el Proveedor de servicios.

1.1.3 Al utilizar el Portal para comprar servicios de viaje, usted nos autoriza a mediar con los proveedores de servicios correspondientes en su nombre, incluida la mediación en el pago de estos servicios de viaje, para asegurar que la transacción entre usted y El Proveedor (s) de Servicios se lleva a cabo. El precio emitido por los Servicios de viaje reales puede incluir una comisión por los servicios que prestamos para mediar el acuerdo entre usted y el Proveedor de servicios correspondiente.

1.1.4 La responsabilidad por el desempeño real de los Servicios de viaje mediados por el Portal es pertenece exclusivamente al Proveedor de servicios correspondiente. En nuestra función de intermediario, no asumimos ninguna responsabilidad por los Servicios de viajes que deben ser prestados por los Proveedores de servicios y no hacemos ninguna declaración o garantía (ni expresa ni implícita) con respecto a la idoneidad o la calidad de los Servicios de viajes mediados en el Portal. Para cualquier reclamación que pueda tener respecto al rendimiento o el incumplimiento del Servicio de viajes, el Proveedor de servicios es el destinatario responsable.

1.1.5 Si tiene la oportunidad de solicitar opciones especiales (como comidas especiales, instalaciones para discapacitados o asientos para niños) al reservar Servicios de viaje, transmitiremos su solicitud al Proveedor de servicios correspondiente. Sin embargo, no podemos asumir ninguna responsabilidad sobre si el Proveedor de servicios puede cumplir realmente dichos deseos.

### 1.2 Condiciones contractuales aplicables.

1.2.1 Estos TyC se aplican a la mediación de los Servicios de viaje según la Sección 1.1, así como a nuestros propios servicios de conformidad con la Sección 1.2.

1.2.2 Para el acuerdo entre usted y el Proveedor de servicios pertinente (Sección 1.1.2), los términos y condiciones generales del Proveedor de servicios pertinente (como las condiciones de transporte, las condiciones de transferencia, las condiciones de seguro o similares), según lo expida el Proveedor de servicios. Dichos términos y condiciones generales del Proveedor de servicios pertinente le serán notificados durante el proceso de reserva. Como los términos y condiciones del proveedor del servicio pueden incluir disposiciones relacionadas con la responsabilidad, la cancelación, los cambios en las reservas y los reembolsos (si están disponibles) y otras restricciones, se recomienda que lea estos términos con atención.

#
# 2. Proceso de reserva e información de contacto.

2.1 Durante el proceso de reserva, se le proporcionan los medios técnicos necesarios para detectar errores en los formularios de entrada y corregirlos antes de enviar su solicitud de reserva. Se le solicita que verifique la exactitud de todos los datos antes de finalizar su solicitud de reserva. Las solicitudes de cambio posteriores pueden llevar a costos adicionales significativos.

2.2 Es posible que debamos contactarlo, por ejemplo, en caso de cambios posteriores en los Servicios de viaje comprados. Debe enviar información de contacto precisa, como su número de teléfono y dirección de correo electrónico. También debe verificar continuamente si ha recibido un mensaje de nuestra parte.

También es su responsabilidad asegurarse de que pueda recibir nuestros mensajes. No somos responsables si no recibe un mensaje de nuestra parte debido a circunstancias razonablemente fuera de nuestro control, que incluyen, entre otras, las siguientes: (i) Nos dio una dirección de correo electrónico incorrecta; (ii) la configuración de su correo electrónico no permite que nuestro correo electrónico le llegue; o (iii) su configuración de correo electrónico trata nuestro correo electrónico como correo no deseado.

#
# 3. Mediación de vuelos.

### 3.1 Conclusión del contrato y cambios de precio.

3.1.1 Una vez que haya enviado su solicitud de reserva, le confirmaremos su recepción por correo electrónico. Este es el momento en que el contrato de mediación entre Usted y Nosotros entra en vigencia. Si no recibe dicha confirmación en el plazo de una hora después de completar su solicitud de reserva, y ninguno de nuestros operadores lo contactó por correo electrónico o por teléfono para indicarle alguna incidencia, comuníquese con nosotros por teléfono para verificarlo.

3.1.2 Tan pronto como se hayan emitido los billetes solicitados, recibirá un correo electrónico de confirmación con un número de billete. Este es el momento en que se ha celebrado un contrato vinculante entre Usted y la aerolínea (s) correspondiente (s).

3.1.3 Los precios del vuelo, así como la disponibilidad de asientos, son insertados directamente por la aerolínea correspondiente. Para los cambios de precios de la aerolínea fuera de nuestro control que se producen después de la presentación de la solicitud de reserva, pero antes de que el contrato con la línea aérea se convierta en vinculante (como se establece en la Sección 3.1.2 anterior), el contrato no entrará en vigor y su pago será reembolsado en su totalidad. Podemos comunicarnos con Usted y ofrecerle la opción de aceptar el precio modificado durante las horas de apertura normales, sin embargo, no más tarde de 24 horas después de que hayamos tenido conocimiento del cambio de precio o en el primer día de la semana después de dicho período de 24 horas.

### 3.2 Información sobre condiciones de reserva y transporte.

3.2.1 Respecto a los vuelos ofrecidos en el Portal, actuamos exclusivamente dentro de nuestra capacidad como intermediarios. El acuerdo para la realización del vuelo entra en vigencia directamente entre Usted y la aerolínea correspondiente y no aceptamos ninguna responsabilidad contractual relacionada con la realización o incumplimiento de su vuelo. La aerolínea operadora es la única responsable de la realización / incumplimiento de su vuelo.

3.2.2 Antes de que concluya la reserva, los términos y condiciones de la (s) línea (s) aérea (s) relevante (s) estarán a su disposición.

3.2.3 A continuación, a modo de resumen general, proporcionamos información sobre las condiciones relacionadas con la reserva y el transporte que las líneas aéreas suelen aplicar de forma similar o al menos similar. Sin embargo, cualquier disposición divergente de la aerolínea relevante prevalece sobre la información general proporcionada en esta Sección 3.2.3. Por lo tanto, en cada caso, verifique los términos y condiciones aplicables de la aerolínea correspondiente antes de su reserva.

#### a. Horarios de vuelo / check in

Todos los horarios de vuelo especificados son locales. Las llegadas al día siguiente se indican con un &quot;+1&quot; en el calendario. Los horarios de vuelo indicados son preliminares y están sujetos a cambios con poca antelación después de que se haya emitido el billete; por ejemplo, debido a restricciones de la parte de los controladores aéreos, el clima o restricciones funcionales por parte de la aerolínea. Por favor, manténgase informado de los horarios actuales con suficiente antelacion a su vuelo.

Por favor, respete los horarios de facturación indicados por la aerolínea. Las aerolíneas tienen derecho a rechazar el embarque si llega tarde a la facturación. Tenga en cuenta que algunas compañías aéreas recomiendan el check-in a través de su propio sitio web, ya que pueden cobrar una tarifa de check-in cuando se registran manualmente en el aeropuerto.

#### b. Combinación de billetes individuales.

Una combinación de dos billetes de ida en lugar de un billete de ida y vuelta está claramente marcada como tal durante el proceso de reserva. Los billetes se tratan de forma independiente uno del otro en caso de cancelación, cambio, interrupción del tráfico aéreo, como huelgas y cambios en los horarios de vuelo. Se aplicarán las normas propias de cada aerolínea.

Los billetes de avión con diferentes números de reserva siempre se consideran viajes independientes entre sí.

#### c. Billetes de avión con varios segmentos / orden de uso.

Su billete de ida y vuelta o billete de ida puede constar de varios segmentos. De acuerdo con las condiciones de la mayoría de las aerolíneas, dichos segmentos de vuelo se deben usar en secuencia. De lo contrario, muchas aerolíneas rechazarán el transporte en segmentos de vuelo subsiguientes (por ejemplo, si no se utiliza un segmento de un viaje, se podría invalidar el resto del billete). Si para los billetes de ida y vuelta el pasajero no se presentan en su vuelo de ida, la aerolínea puede cancelar su vuelo de vuelta.

#### d. Embarazo

Algunas líneas aéreas se niegan a transportar a mujeres que hayan pasado la 28 semana de gestación en el momento del vuelo de ida o vuelta. Si está embarazada, debe aclarar con la línea aérea y con su médico si puede o no comenzar el viaje.

#### e. Billetes para bebés y niños.

Comuníquese con la aerolínea para obtener las condiciones para viajar con un niño que no tiene un asiento separado. Por lo general, los niños mayores de 2 años requieren un asiento separado, mientras que los niños entre 0 y 2 años viajan como bebés y no se les asignará un asiento propio. Si el bebé llega a la edad de 2 años antes del final del viaje, se debe reservar un billete para todo el viaje. Las entradas para bebés no se pueden reservar antes del nacimiento, ya que el nombre y la fecha de nacimiento correctos deben coincidir con los indicados en el pasaporte. No reembolsaremos ningún gasto que surja si se reserva un billete incorrecto desde el principio.

#### f. Menores no acompañados

No mediamos la reserva de billetes para menores no acompañados. Para los menores de 18 años debe reservarse un viaje acompañados de un adulto. Algunos países y aerolíneas rechazan la entrada a niños menores de 18 años a menos que estén acompañados por un tutor legal. Tenga en cuenta que algunas aerolíneas requieren que los niños menores de 18 años traigan un certificado de nacimiento para viajar.

#### G. Equipaje perdido / dañado

Dentro de nuestra función como intermediario, no aceptamos ninguna responsabilidad por el equipaje perdido o dañado. Cualquier problema debe informarse de inmediato al representante de la aerolínea en el aeropuerto.

#### h. Tránsito y alojamiento.

Generalmente, el transporte terrestre y / o el alojamiento nocturno durante su viaje no están incluidos en el precio del billete de avión. Usted es personalmente responsable de verificar los horarios y precios del transporte terrestre.

#### I. Tiempo de escala entre vuelos

Los billetes estándar reservados en el Portal tienen tiempos de escala aprobados. El tiempo requerido para hacer escala entre vuelos ees calculado por las aerolíneas. Si un segmento de vuelo se retrasa y conduce a una escala perdida, las aerolíneas están obligadas a ayudarlo a llegar a su destino final (consulte la Sección 9.1).

Cuando se reservan billetes separados, las aerolíneas no son responsables por las escalas perdidas que resulten de retrasos. Por lo tanto, es su responsabilidad asegurarse de que el tiempo de conexión sea suficiente de acuerdo con las aerolíneas y los aeropuertos. Cualquier costo adicional incurrido a causa de escalas perdidas no será reembolsado.

#### j. Doble reserva.

Una doble reserva significa que se han realizado dos o más reservas con el mismo nombre de pasajero con la misma aerolínea. Si tiene una reserva doble, la aerolínea puede cancelar el (los) viaje (s). Esto también puede ocurrir si las reservas se han hecho con diferentes agencias de viajes. No somos responsables de las cancelaciones hechas por las aerolíneas, ni de los reembolsos rechazados por las aerolíneas, en los casos en que sospechen una doble reserva.

### 3.3 Líneas aéreas prohibidas de operar en la UE (lista negra)

Tenga en cuenta que ciertas aerolíneas tienen prohibido operar dentro de la UE, según una decisión tomada por la Comisión Europea en estrecha consulta con las autoridades nacionales de transporte aéreo. Estas aerolíneas están prohibidas porque se consideran inseguras o no están sujetas a un control adecuado por parte de las autoridades de su país.

Puede consultar qué aerolíneas se ven afectadas por una prohibición operativa a través del siguiente enlace: [Lista negra (Lista de aerolíneas que no pueden operar en la UE)](https://ec.europa.eu/transport/language-selector/site-language_en?destination=node/2840)

### 3.4 Cambios de horario y cancelación por parte de las compañías aéreas.

3.4.1 Su acuerdo con la aerolínea aplicable puede permitirles cancelar o modificar sus reservas. Le notificaremos cualquier cambio una vez que la aerolínea nos lo informe.

3.4.2 Los tiempos de vuelo que aparecen en la confirmación de su reserva pueden cambiar entre la fecha de su reserva y la fecha en que realmente viaja. En caso de cambios en el horario de vuelo, se lo notificaremos tan pronto como la aerolínea nos lo informe. Sin embargo, le recomendamos que se comunique con su aerolínea al menos 72 horas antes de la salida programada de cada vuelo para asegurarse de que los vuelos (y los vuelos de conexión) estén operando según el horario programado. No tenemos control sobre los cambios de horario de la aerolínea y no aceptamos ninguna responsabilidad por los costos que puedan surgir como resultado de dichos cambios.

### 3.5 Cambios y cancelaciones solicitados por usted.

Para aerolíneas de bajo costo, vea la Sección 3.7.

3.5.1 Las condiciones para cambiar las reservas de vuelo (incluido el cambio de nombre del pasajero, destino y fecha de viaje) y para proporcionar reembolsos de cancelación son establecidas por la aerolínea correspondiente, que es su socio contractual para efectuar el vuelo. Nosotros, como intermediarios, no tenemos influencia sobre tales condiciones.

3.5.2 Si desea realizar un cambio en su reserva o solicitar un reembolso por cancelación, como un servicio propio adicional, ofrecemos tratar la solicitud en su nombre, siempre que las condiciones de la línea aérea permitan dicho cambio o reembolso de cancelación. Durante el proceso de reserva de dichos servicios adicionales, le informaremos de cualquier condición adicional para dichos servicios. Alternativamente, puede, por supuesto, acercarse directamente a la aerolínea correspondiente.

3.5.3 Para que podamos tratar los cambios solicitados por Usted, es necesario que recibamos sus solicitudes de cambio con una antelación minima de 24 horas antes del comienzo del viaje (solo por teléfono). Si ha comprado nuestro servicio de billetes flexibles, consulte la sección 10.

Para solicitudes de cambio a corto plazo, le recomendamos que se comunique directamente con la aerolínea correspondiente.

### 3.6 No presentarse o no asistir al vuelo

Por la presente, nos autoriza a cancelar el vuelo no utilizado en su nombre en caso de que no se presente o no se presente al vuelo y para solicitar posibles reembolsos de la aerolínea en su nombre. Tenemos derecho, pero no estamos obligados a hacerlo, y su derecho a solicitar reembolsos directamente de la aerolínea no se verá afectado.

### 3.7 Aerolíneas de bajo coste

3.7.1 Se le mostrará claramente a Usted durante el proceso de reserva si el vuelo aplicable es operado por una aerolínea de bajo costo. Las siguientes disposiciones especiales se aplican a los vuelos realizados por compañías aéreas de bajo coste.

3.7.2 Al mediar en los Servicios de viaje prestados por aerolíneas de bajo costo, podemos actuar en su nombre al concluir el contrato entre Usted y la aerolínea de bajo costo.

3.7.3 No tenemos acceso a los sistemas de reserva de aerolíneas de bajo coste. Por lo tanto, comuníquese directamente con la aerolínea de bajo costo correspondiente si tiene preguntas sobre las reservas, los cambios o la cancelación de su reserva. Si ha adquirido nuestro servicio de billetes flexibles (consulte la Sección 10), todas las solicitudes de cambio de reserva deben realizarse a través de nuestro servicio de atención al cliente como se establece en la Sección 10.1.4.

3.7.4 Tenga en cuenta que algunas aerolíneas de bajo costo cobran tarifas por el check-in manual en el aeropuerto. Para evitar dichos cargos, debe utilizar la posibilidad de realizar la facturación en línea a través del sitio web de la aerolínea de bajo costo correspondiente. Infórmese con tiempo antes de su salida sobre las posibilidades y condiciones de facturación de su aerolínea.

3.7.5 Las confirmaciones de reserva para vuelos de bajo costo se envían tan pronto como la reserva haya sido confirmada por la aerolínea de bajo costo correspondiente. Puede recibir dos confirmaciones de reserva, una de nosotros y otra de la aerolínea de bajo costo correspondiente. Si ha recibido dos confirmaciones de reserva, utilice la confirmación de reserva de la aerolínea de bajo costo para la facturación.

#
# 4. Disposiciones especiales para el trámite de reservas de servicios múltiples.

El Portal ofrece la posibilidad de añadir y combinar múltiples servicios individuales como desee (por ejemplo, vuelo + hotel). En este caso, nos indica que actuemos como intermediarios para la reserva de diversos servicios de viaje de diversos proveedores. Los proveedores concernidos se le presentarán claramente durante el proceso de reserva antes de que se complete la reserva. En el caso de que combine servicios individuales, no se celebrará ningún contrato de viaje entre usted y nosotros; En su lugar, usted concluye varios contratos para la prestación de servicios individuales con cada proveedor por separado. En este caso, operamos únicamente dentro de nuestra función como intermediarios en relación con cada servicio de viaje individual.

#
# 5. Información sobre pasaportes, visados ​​y disposiciones sanitarias.

5.1 Los requisitos de pasaporte, visado y / o salud pueden cambiar y, por lo tanto, debe consultar con la autoridad competente (embajada, consulado, etc.) con suficiente antelación al viaje. Es su responsabilidad estar en posesión de un pasaporte válido y, si corresponde, un visado. Es importante recordar incluir todos los puntos de tránsito en su viaje, lo que también puede requerir que obtenga un visado. Con frecuencia, puede tomar algún tiempo obtener un visado, por lo que se recomienda que presente la solicitud con suficiente antelación. No aceptamos ninguna responsabilidad por los clientes que no posean los documentos correctos.

5.2 Cada destino tiene sus propios requisitos en cuanto a formalidades de entrada, vacunaciones, etc., que también pueden variar según la nacionalidad del pasajero. Es su responsabilidad obtener esa información. Ningún incidente derivado del incumplimiento de dichas regulaciones oficiales será considerado nuestra responsabilidad. Por lo tanto, le instamos a que siempre se asegure de las diferentes formalidades de los países de destino o de tránsito elegidos, así como el tiempo necesario para tomar todos los pasos relacionados.

#
# 6. Facturación y pago

### 6.1 pago

6.1.1 El pago de los Servicios de viaje lo procesamos nosotros (en colaboración con nuestros proveedores de servicios de pago que proporcionan la funcionalidad de procesamiento de pagos) o el Proveedor de servicios. Tenga en cuenta que es posible que debamos transmitir su informacion de pago, como los datos de la tarjeta de crédito o débito, con nuestros proveedores de servicios de pago para poder procesar el pago. Toda la información de pago que pertenece a nuestros clientes se cifra en un servidor seguro cuando se transmite a nuestros proveedores de servicios de pago.

6.1.2 Para que podamos procesar su pago, debe asegurarse de que haya fondos suficientes disponibles. En caso de que surja algún problema relacionado con el procesamiento de su pago, volveremos a intentar el procesamiento de su pago en cooperación con nuestros proveedores de servicios de pago. En caso de que esto no resulte en la obtención de la suma total del pago, nos pondremos en contacto con usted lo antes posible para recibir instrucciones sobre otros medios de pago. Si no recibimos su pago después de tales instrucciones adicionales, su pago pendiente se remitirá a una compañía de cobro de deudas.

### 6.2 Fraude de pagos

Si hay razones para sospechar que se está cometiendo un fraude, nos reservamos el derecho de rechazar el procesamiento de dicho pago. La legitimación del pago puede ser exigida si se sospecha un delito. Todos los tipos de fraude de pagos se informarán a la policía y se remitirán a una empresa de cobro de deudas.

#
# 7. Reclamaciones

### 7.1 Reclamaciones relativas a la prestación de servicios de viaje

Cualquier problema, comentario o reclamación relacionados con el desempeño real de los Servicios de viaje debe dirigirse directamente al Proveedor de servicios correspondiente con el que tenga el contrato del Servicio de viajes correspondiente. Consulte también la sección 9 para conocer sus derechos conforme a la normativa de la UE.

### 7.2 Reclamaciones relativas a nuestros propios servicios.

Las quejas relacionadas con nuestros propios servicios se tratan únicamente por escrito y se deben enviar dentro de los dos meses posteriores a la fecha de finalización de su viaje por correo electrónico.

Dirección de correo electrónico: [complaints@flystrait.com](mailto:complaints@flystrait.com)

#
# 8. Derechos de los clientes según la normativa de la UE

### 8.1 Vuelos cancelados o retrasados

Si viaja dentro o fuera de la UE, o en una aerolínea de la UE, puede tener derecho a exigir el reembolso de los costos que puede reclamar directamente a la aerolínea correspondiente en caso de que su vuelo se cancele, se retrase o se le niegue el embarque. [Para obtener más información sobre el Reglamento CE 261/2004, haga clic aquí.](http://eur-lex.europa.eu/legal-content/EN/ALL/?uri=CELEX%3A32004R0261)

### 8.2 Responsabilidad del transportista

El Reglamento CE (889/2002) sobre la responsabilidad de las compañías aéreas en caso de accidente. [La regulación se puede encontrar aquí.](http://eur-lex.europa.eu/legal-content/EN/TXT/?uri=celex%3A32002R0889)

#
# 9. Responsabilidad

9.1 Usted acepta que actuamos como intermediarios entre usted y el Proveedor de servicios. Bajo ninguna circunstancia seremos responsables con respecto a los servicios de viaje que haya reservado con uno o más proveedores de servicios y no aceptamos ninguna responsabilidad por cualquier inexactitud en la información mostrada en el Portal que haya proporcionado el proveedor de servicios correspondiente.

9.2 En caso de que un Proveedor de servicios no pueda proporcionar el Servicio de viajes por algún motivo, incluso cuando un Proveedor de servicios se declara en bancarrota, solo podemos actuar como intermediario y reembolsar los pagos en el caso en que ya lo hayamos recibido del Proveedor de servicios correspondiente.

9.3 Con respecto a nuestros propios servicios, somos responsables de los daños sujetos a las limitaciones establecidas en estos términos y condiciones y en la medida permitida por la ley. Solo seremos responsables por los daños directos realmente sufridos, pagados o incurridos por Usted debido a una falta atribuible a nuestras obligaciones con respecto a nuestros propios servicios, hasta una suma total del costo de su reserva (ya sea para un evento o una serie de eventos conectados).

9.4 La limitación de responsabilidad establecida en la Sección 10.3 también se aplica a los incumplimientos del deber por parte de personas por los cuales somos responsables de acuerdo con las disposiciones legales.

#
# 10 Ley aplicable y resolución de litigios.

### 10.1 Ley aplicable

La ley de Estonia, con la exclusión de la ley uniforme de fondo, en particular la Ley de Ventas de las Naciones Unidas sobre la Venta Internacional de Bienes, se aplica exclusivamente a estos TyC y al contrato entre Usted y nosotros.

Como consumidor, se beneficiará de todas las disposiciones obligatorias de la ley del país en el que reside. Nada en estos T&amp;C, incluida esta Sección 11.1, afecta sus derechos como consumidor de confiar en las disposiciones obligatorias de la ley local.

### 10.2 Resolución de litigios en línea

La Comisión Europea proporciona una plataforma para la resolución de litigios en línea (ODS) en: (http://ec.europa.eu/consumers/odr/)

#
# 11. Protección de datos

Consideramos la protección de sus datos personales muy en serio. Puede encontrar información detallada sobre la recopilación, el procesamiento y el uso de sus datos personales en nuestra [Política de privacidad.](https://flystrait.com/privacy)
`;
