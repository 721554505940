import React, { PureComponent } from "react";
import styled, { withTheme } from "styled-components";
import { isMobile } from "react-device-detect";
import Btn from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Fade from "@material-ui/core/Fade";
import Slide from "@material-ui/core/Slide";
import { Button } from "components/common";
import { CloseIcon, CheckIcon } from "components/common/SvgIcons";
import getTranslation from "utils/getTranslation";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />;
});

const TransitionSlide = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Container = styled.div``;

const Header = styled.div`
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${props => (props.titleHtml ? "0" : "8px 8px 8px 24px")};
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.16);
  text-transform: capitalize;
  span {
    line-height: 2;
    color: #777777;
    font-weight: 400;
  }
`;

const StyledButton = styled(Btn)`
  &.MuiButtonBase-root {
    margin-right: 10px !important;
  }
  .MuiButton-label {
    color: ${props => props.theme.secondary};
    text-decoration: underline;
    font-size: 12px;
    font-family: "Roboto Condensed", sans-serif;
  }
`;

class Modal extends PureComponent {
  handleClose = () => {
    this.props.handleCloseModal();
  };

  handleCheckClick = () => {
    const { handleCheckClick, handleCloseModal, showChecked } = this.props;
    if (handleCheckClick && showChecked) {
      handleCheckClick();
    } else {
      handleCloseModal();
    }
  };

  render() {
    const {
      open,
      title,
      children,
      dialogStyle = {},
      titleStyle = {},
      titleHtml,
      disableFullScreen,
      showChecked,
      showReset,
      handleReset,
      slide,
      fallbackResetStyle = {},
      theme
    } = this.props;
    return (
      <Container>
        <Dialog
          modal={false}
          open={open}
          fullScreen={isMobile && !disableFullScreen}
          TransitionComponent={slide ? TransitionSlide : Transition}
          onClose={this.handleClose}
          Transition
          scroll="paper"
          bodyStyle={{ padding: 0 }}
          contentClassName="test"
          PaperProps={{
            style: { backgroundColor: "#EEEEEE", ...dialogStyle }
          }}
        >
          {title && (
            <DialogTitle
              id="alert-dialog-slide-title"
              style={{
                boxShadow: "inset 0px -1px 0px rgba(0, 0, 0, 0.16)",
                padding: "0"
              }}
            >
              <Header titleHtml={titleHtml} style={titleStyle}>
                {titleHtml ? (
                  title
                ) : (
                    <span
                      style={{ lineHeight: 2, color: "#777777", fontWeight: 400 }}
                    >
                      {title}
                    </span>
                  )}
                <div
                  style={showReset ? { marginRight: 8 } : fallbackResetStyle}
                >
                  {showReset && (
                    <StyledButton
                      edge="start"
                      color="primary"
                      onClick={handleReset}
                    >
                      {getTranslation("reset")}
                    </StyledButton>
                  )}
                  <Button
                    onClick={this.handleCheckClick}
                    color={theme.primaryBackground}
                    style={{ height: 32, maxWidth: 32, minWidth: 32 }}
                  >
                    {showChecked ? (
                      <CheckIcon stroke={theme.secondary} />
                    ) : (
                        <CloseIcon stroke={theme.secondary} />
                      )}
                  </Button>
                </div>
              </Header>
            </DialogTitle>
          )}
          <DialogContent style={{ padding: 0 }}>{children}</DialogContent>
        </Dialog>
      </Container>
    );
  }
}

export default withTheme(Modal);
