import React from "react";
import { getDomainCheckoutStatus } from "utils/api";
import { getItem } from "utils/utils";
import config from "config";
import getTranslation from "utils/getTranslation";


let event;
export default class extends React.Component {
  constructor(props) {
    super(props);
    const domainData = getItem("domainData") || {};
    this.state = {
      customDomainId: domainData.id,
      empTransactionid: getItem("emp_transactionid")
    };

    event = new EventSource(
      config.paymentHost +
      "/custdom/checkout_status_sse?empTransactionid=" +
      getItem("empTransactionid")
    );

  }
  componentDidMount() {
    event.addEventListener("message", result => {
      if (result.data) {
        const data = JSON.parse(result.data);
        this.handleEventResponse(data);
      }
    });
    event.addEventListener("error", e => {
      console.log(e.data);
      this.props.handleShowBookingStatus(getTranslation("bookingFailed"));
      this.props.handleFailed();
    });
  }
  componentWillUnmount() {
    event.close();
  }
  handleEventResponse = async (data) => {
    const { lastStatus } = this.state;
    const {
      handleShowBookingStatus,
      handlePaymentApproved,
      handlePaymentComplete,
      handlePaymentError,
      handleFailed
    } = this.props;
    try {
      const currentStatus = data.checkoutStatus;
      if (currentStatus !== lastStatus) {
        this.setState({ lastStatus: currentStatus });
        if (currentStatus === "user_approved") {
          handleShowBookingStatus(getTranslation("paymentInitiated"));
        }
        if (currentStatus === "emp_approved") {
          handleShowBookingStatus(getTranslation("paymentApprovedDomain"));
          handlePaymentApproved();
        }
        if (
          currentStatus === "reconcile_failed" ||
          currentStatus === "declined" ||
          currentStatus === "error" ||
          currentStatus === "timeout"
        ) {
          handleShowBookingStatus(getTranslation("paymentAuthIssue"));
          handlePaymentError(currentStatus);
        }
        if (currentStatus === "trip_booked") {
          handleShowBookingStatus(getTranslation("tripBooked"));
        }
        if (currentStatus === "payment_complete") {
          handleShowBookingStatus(getTranslation("paymentComplete"));
        }
        if (currentStatus === "domain_activated") {
          console.log("I know domain_activated, about to call handlepaymentcomplete")
          handlePaymentComplete();
          event.close();
        }
        if (
          currentStatus === "registration_failure" ||
          currentStatus === "voided"
        ) {
          handleFailed();
        }
      }
    } catch (err) {
      console.log(err);
      this.props.handlePaymentError("failed");
    }
  };

  render() {
    return null;
  }
}
