import React from "react";
import styled from "styled-components";
import moment from "moment";
import SearchIcon from "assets/icons/search.svg";

const Wrapper = styled.div`
  cursor: pointer;
  width: 440px;
  display: flex;
  padding: 2px;
  align-items: center;
  background-color: ${(props) => props.theme.primaryBackground};
  border-radius: 8px;
  font-size: 16px;
  color: #222222;
  .search {
    margin-right: 10px;
  }
  .hopItem {
    display: flex;
    flex-flow: column;
    span {
      font-size: 16px;
      color: #222222;
      line-height: 16px;
    }
    .date {
      font-size: 12px;
      line-height: 24px;
    }
  }
  @media (max-width: 800px) {
    width: 100%;
    margin-bottom: 10px;
  }
  .passengers {
    display: flex;
    .passenger {
      display: flex;
      flex-flow: column;
      align-items: center;
      background-color: #fff;
      margin: 0 2px;
      padding: 2px;
      img {
        margin-bottom: 4px;
      }
      .count {
        background: #ec9719;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 12px;
        height: 12px;
        font-size: 12px;
        border-radius: 50%;
      }
    }
  }
`;

const Pipe = styled.div`
  margin: 0 10px;
  border-right: 1px solid #f0f0f0;
  width: 1px;
  height: 40px;
`;

export default ({ hopRequests, dummy, handleClose }) => {
  const modifiedHopReqs = hopRequests.map((hopReq) => ({
    stdDate: moment(hopReq.stdDate || hopReq.date * 1000).format("D MMM"),
    fromAirportIc: hopReq.fromAirportIc || hopReq.from.airportIc,
    toAirportIc: hopReq.toAirportIc || hopReq.from.airportIc,
    passengers: [
      {
        type: "adult",
        count: hopReq.adultCount || hopReq.passengers.adult,
      },
      {
        type: "child",
        count: hopReq.childCount || hopReq.passengers.child,
      },
      {
        type: "infant",
        count: hopReq.infantCount || hopReq.passengers.infant,
      },
    ],
  }));
  return (
    <Wrapper onClick={handleClose}>
      <img className="search" src={SearchIcon} alt="" />
      {modifiedHopReqs.map((item, i) => (
        <>
          <div className="hopItem" key={i}>
            <span className="date">{item.stdDate}</span>
            <span>
              {item.fromAirportIc} > {item.toAirportIc}
            </span>
          </div>
          <Pipe />
        </>
      ))}
      <div className="passengers">
        {modifiedHopReqs[0].passengers.map(
          (passenger) =>
            passenger.count > 0 && (
              <div className="passenger" key={passenger.type}>
                <img
                  src={require(`assets/icons/${passenger.type}.svg`)}
                  height={16}
                  alt=""
                />
                <span className="count">{passenger.count}</span>
              </div>
            )
        )}
      </div>
    </Wrapper>
  );
};
