import gql from 'graphql-tag';

export const filteredResultsQuery = gql`query filteredResults(
  $tripId: Int!
) {
  filteredResults(
    tripId: $tripId
  ) {
    priceNum,
    hopRequests {
      id,
      fromAirportIc,
      toAirportIc,
      hopSegmentControls {
        id,
        dollarRating,
        seatClass
        flightSegments {
          airlineIc,
          flightNum,
          stdTime,
          staTime,
          stdDate,
          staDate
        }
      },
    },
    baseFare,
    totalTax,
    currencyIso,
    costDecimalPlaces,
  }
}`;

export const addTripFilter = gql`mutation addTripFilter(
  $filterKey: FilterKey!,
  $value: String!
  $tripId: Int!
) {
  addTripFilter(
    filterKey: $filterKey,
    value: $value,
    tripId: $tripId
  ) {
    priceNum,
    hopRequests {
      id,
      fromAirportIc,
      toAirportIc,
      hopSegmentControls {
        id,
        dollarRating,
        flightSegments {
          airlineIc,
          flightNum,
          stdTime,
          staTime,
        }
      },
    },
    filterKvs {
      id
      filterKey
      value
      tripId
    }
  }
}`;

export const delTripFilter = gql`mutation delTripFilter(
  $id: Int!
  $tripId: Int!
) {
  delTripFilter(
    id: $id,
    tripId: $tripId
  ) {
    priceNum,
    hopRequests {
      id,
      fromAirportIc,
      toAirportIc,
      hopSegmentControls {
        id,
        dollarRating,
        flightSegments {
          airlineIc,
          flightNum,
          stdTime,
          staTime,
        }
      },
    },
    filterKvs {
      id
      filterKey
      value
      tripId
    }
  }
}`;

export const clearTripFilters = gql`mutation clearTripFilters(
  $tripId: Int!
) {
  clearTripFilters(
    tripId: $tripId
  ) {
    priceNum,
    filterKvs {
      id
      filterKey
      value
      tripId
    }
  }
}`;

export const xairlineByIc = gql`query xairlineByIc(
    $airlineIc: String!,
  ) {
    xairlineByIc(
      airlineIc: $airlineIc,
    ) {
      airlineDesc
    }
  }
`;
