import React from "react";

export const Layout = ({ primary = "#3F8C52", background = "white" }) => (
  <svg
    width="266"
    height="168"
    viewBox="0 0 266 168"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d)">
      <rect x="12" y="12" width="242" height="144" fill={background} />
      <g filter="url(#filter1_d)">
        <rect x="12" y="20" width="242" height="24" fill="white" />
      </g>
      <rect x="173" y="30" width="32" height="4" rx="2" fill={primary} />
      <rect x="213" y="30" width="32" height="4" rx="2" fill={primary} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.9125 33.3841L28.2333 36.0424C30.2041 35.5382 32.3583 35.3549 34.4208 35.5382C31.5791 36.0882 29.3333 38.2882 28.7375 41.084H22C22.1833 39.434 23.6042 37.9215 25.8041 36.9132L26.2166 33.3841L22 34.5757L25.1625 30.5424L22.5958 31.3674L25.3458 27.4258L23.0542 28.2508L27.0416 23.3008L31.1208 28.2966L28.8291 27.4716L31.5791 31.4132L29.0125 30.5882L32.1291 34.5757L27.9125 33.3841ZM41.8 36.1799L44 37.0507L42.35 34.1632L43.6791 34.759L42.2125 31.9174L43.4041 32.5132L41.2958 28.9382L40.1041 31.0007L38.5917 28.8007L40.4708 29.4424L37.1708 25.3174L33.9167 29.3966L35.7958 28.7549L33.55 32.0091L35.6583 31.3674L33.0917 34.6215L36.5292 33.6591L36.3 35.7674C36.2542 35.7674 36.2083 35.7674 36.1625 35.7215C35.6125 35.9049 35.1083 36.0882 34.65 36.3632C36.1167 37.6007 36.9875 39.2507 37.2167 41.084H44C43.9083 39.8924 43.1291 38.8382 41.9375 37.9215L41.8 36.1799ZM39.9666 32.8341L39.5541 32.3299L40.425 31.9174L39.9666 32.8341ZM40.7916 37.1882C40.425 37.0049 40.0125 36.8215 39.6 36.6382L40.8833 36.134L40.7916 37.1882ZM39.7375 31.6424L39.4166 32.1466L38.775 31.3216L39.7375 31.6424ZM37.9042 33.6132L39.3708 34.0257L38.9583 34.759L40.1041 34.2549L40.2416 34.3007L39.0041 36.4549L38.1792 36.1799L37.9042 33.6132ZM31.1666 41.084H35.5207C35.7041 39.4799 35.3832 37.8299 34.5124 36.4549C32.8166 37.4632 31.5791 39.1132 31.1666 41.084Z"
        fill={primary}
      />
      <path
        d="M12 14C12 12.8954 12.8954 12 14 12H252C253.105 12 254 12.8954 254 14V20H12V14Z"
        fill="#DDDDDD"
      />
      <rect x="15" y="14" width="4" height="4" rx="2" fill="white" />
      <rect x="23" y="14" width="4" height="4" rx="2" fill="white" />
      <rect x="31" y="14" width="4" height="4" rx="2" fill="white" />
      <g clipPath="url(#clip0)">
        <rect
          x="20.5"
          y="52.5"
          width="69"
          height="79"
          rx="1.5"
          fill="white"
          stroke="#F0F0F0"
        />
        <path
          d="M72.2498 61.75H37.7498C35.8332 61.75 33.9165 63.6667 33.9165 65.5833V92.4167C33.9165 94.525 35.6415 96.25 37.7498 96.25H72.2498C74.1665 96.25 76.0832 94.3333 76.0832 92.4167V65.5833C76.0832 63.6667 74.1665 61.75 72.2498 61.75ZM41.5832 88.5833L48.2915 79.9583L53.0832 85.7275L59.7915 77.0833L68.4165 88.5833H41.5832Z"
          fill="#F0F0F0"
        />
        <rect x="34" y="104" width="42" height="4" rx="2" fill={primary} />
        <rect x="34" y="114" width="30" height="4" rx="2" fill={primary} />
        <rect
          x="98.5"
          y="52.5"
          width="69"
          height="79"
          rx="1.5"
          fill="white"
          stroke="#F0F0F0"
        />
        <path
          d="M150.25 61.75H115.75C113.833 61.75 111.917 63.6667 111.917 65.5833V92.4167C111.917 94.525 113.642 96.25 115.75 96.25H150.25C152.167 96.25 154.083 94.3333 154.083 92.4167V65.5833C154.083 63.6667 152.167 61.75 150.25 61.75ZM119.583 88.5833L126.292 79.9583L131.083 85.7275L137.792 77.0833L146.417 88.5833H119.583Z"
          fill="#F0F0F0"
        />
        <rect x="112" y="104" width="42" height="4" rx="2" fill={primary} />
        <rect x="112" y="114" width="30" height="4" rx="2" fill={primary} />
        <rect
          x="176.5"
          y="52.5"
          width="69"
          height="79"
          rx="1.5"
          fill="white"
          stroke="#F0F0F0"
        />
        <path
          d="M228.25 61.75H193.75C191.833 61.75 189.917 63.6667 189.917 65.5833V92.4167C189.917 94.525 191.642 96.25 193.75 96.25H228.25C230.167 96.25 232.083 94.3333 232.083 92.4167V65.5833C232.083 63.6667 230.167 61.75 228.25 61.75ZM197.583 88.5833L204.292 79.9583L209.083 85.7275L215.792 77.0833L224.417 88.5833H197.583Z"
          fill="#F0F0F0"
        />
        <rect x="190" y="104" width="42" height="4" rx="2" fill={primary} />
        <rect x="190" y="114" width="30" height="4" rx="2" fill={primary} />
        <rect
          x="20.5"
          y="140.5"
          width="69"
          height="79"
          rx="1.5"
          fill="white"
          stroke="#F0F0F0"
        />
        <path
          d="M72.2498 149.75H37.7498C35.8332 149.75 33.9165 151.667 33.9165 153.583V180.417C33.9165 182.525 35.6415 184.25 37.7498 184.25H72.2498C74.1665 184.25 76.0832 182.333 76.0832 180.417V153.583C76.0832 151.667 74.1665 149.75 72.2498 149.75ZM41.5832 176.583L48.2915 167.958L53.0832 173.727L59.7915 165.083L68.4165 176.583H41.5832Z"
          fill="#F0F0F0"
        />
        <rect
          x="98.5"
          y="140.5"
          width="69"
          height="79"
          rx="1.5"
          fill="white"
          stroke="#F0F0F0"
        />
        <path
          d="M150.25 149.75H115.75C113.833 149.75 111.917 151.667 111.917 153.583V180.417C111.917 182.525 113.642 184.25 115.75 184.25H150.25C152.167 184.25 154.083 182.333 154.083 180.417V153.583C154.083 151.667 152.167 149.75 150.25 149.75ZM119.583 176.583L126.292 167.958L131.083 173.727L137.792 165.083L146.417 176.583H119.583Z"
          fill="#F0F0F0"
        />
        <rect
          x="176.5"
          y="140.5"
          width="69"
          height="79"
          rx="1.5"
          fill="white"
          stroke="#F0F0F0"
        />
        <path
          d="M228.25 149.75H193.75C191.833 149.75 189.917 151.667 189.917 153.583V180.417C189.917 182.525 191.642 184.25 193.75 184.25H228.25C230.167 184.25 232.083 182.333 232.083 180.417V153.583C232.083 151.667 230.167 149.75 228.25 149.75ZM197.583 176.583L204.292 167.958L209.083 173.727L215.792 165.083L224.417 176.583H197.583Z"
          fill="#F0F0F0"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="0"
        y="0"
        width="266"
        height="168"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="6" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d"
        x="11"
        y="19.5"
        width="244"
        height="26"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="0.5" />
        <feGaussianBlur stdDeviation="0.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <clipPath id="clip0">
        <rect x="12" y="52" width="242" height="104" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
