import React, { PureComponent } from "react";
import styled from "styled-components";
import moment from "moment";
import { connect } from "react-redux";
import { graphql, compose } from "react-apollo";
import DateTimePicker from "components/common/DateTimePicker";
import {
  RepeatPatternIcon,
  RepeatSimpleIcon,
  AddIcon
} from "components/common/SvgIcons";
import { Button } from "components/common";
import { updateConnectPostRefetch } from "redux-store/actions";
import {
  insertConnectPost,
  deleteConnectPost,
  updateRecurPattern
} from "containers/Influencer/queries";
import { PatternRecurrence, TabbedRadio } from "components/Influencer/Partials";
import getTranslation from "utils/getTranslation";
import deleteIcon from "assets/tip/Delete.svg";

const Wrapper = styled.div`
  width: 456px;
  background-color: #fff;
  padding: 24px 0 0 0;
  @media (max-width: 800px) {
    width: 100%;
  }
  .recurContainer {
    margin: 40px 0;
    .simpleWrapper {
      width: 296px;
      margin: auto;
      .dateWrapper {
        position: relative;
        button {
          position: absolute;
          right: 10px;
          top: 10px;
          z-index: 9;
        }
      }
      .recurrences {
        margin-top: 30px;
      }
    }
    .label {
      color: #777777;
      font-size: 16px;
      line-height: 26px;
      font-family: "Roboto Condensed", sans-serif;
      text-transform: uppercase;
    }
  }
`;

const items = [
  {
    name: "simple",
    label: "Simple",
    icon: RepeatSimpleIcon
  },
  {
    name: "pattern",
    label: "Pattern",
    icon: RepeatPatternIcon
  }
];

class RepeatPostModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      doShare: false,
      active: props.isEdit ? "pattern" : "simple",
      date: null,
      recurrences: []
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.submitRecurrence &&
      this.props.submitRecurrence !== nextProps.submitRecurrence
    ) {
      this.handleSubmit();
    }
  }

  handleLayoutChange = selectedLayout => {
    this.setState({ selectedLayout });
  };

  handleOptionChange = option => {
    this.setState({
      active: option
    });
  };

  handleDateChange = date => {
    this.setState({ date });
  };

  handleDateEdit = (date, index) => {
    const recurrences = this.state.recurrences;
    recurrences[index] = date;
    this.setState({
      recurrences,
      changedDate: moment().unix()
    });
  };

  handleAddRepeat = () => {
    this.setState({
      recurrences: [...this.state.recurrences, this.state.date],
      date: null
    });
  };

  handleDeleteRepeat = index => {
    this.setState({
      recurrences: this.state.recurrences.filter((item, i) => i !== index)
    });
  };

  handleSubmit = async () => {
    const { active, recurrences } = this.state;
    this.setState({
      loading: true
    });
    if (active === "simple" && recurrences.length > 0) {
      this.handleInsertConnectPosts(recurrences);
    }
    if (active === "pattern") {
      this.setState({
        submitPattern: true
      });
    }
  };

  handleInsert = async (recurrences, recurpattern_id) =>
    new Promise(async (resolve, reject) => {
      try {
        const { sharenote, postId, postUrl } = this.props;
        const { influencerconnects } = this.props.influencerDetails;
        for (var k = 0, l = recurrences.length; k < l; k++) {
          for (var i = 0, j = influencerconnects.length; i < j; i++) {
            try {
              const payload = {
                sharenote: sharenote + " " + postUrl,
                post_id: postId,
                influencerconnect_id: influencerconnects[i].id,
                shareschedule_at: moment(recurrences[k]).format(),
                recurpattern_id
              };
              await this.props.insertConnectPost({
                variables: {
                  objects: [payload]
                }
              });
            } catch (err) {
              return reject({ success: false });
            }
          }
          if (k === recurrences.length - 1) {
            return resolve({ success: true });
          }
        }
      } catch (err) {
        console.log("Try failed", err);
        return reject({ success: false });
      }
    });

  handleDelete = (recurpattern_id, firstRecurrence) =>
    new Promise(async (resolve, reject) => {
      try {
        let ids = [];
        const { allConnectPosts } = this.props;
        const connectPosts = allConnectPosts.filter(
          item => item.recurpattern_id === recurpattern_id
        );
        for (var i = 0, j = connectPosts.length; i < j; i++) {
          if (
            moment(connectPosts[i].shareschedule_at).isAfter(
              moment(firstRecurrence)
            )
          ) {
            ids = [...ids, connectPosts[i].id];
          }
          if (i === connectPosts.length - 1) {
            await this.props.deleteConnectPost({
              variables: {
                where: {
                  id: { _in: ids }
                }
              }
            });
            return resolve({
              success: true
            });
          }
        }
      } catch (err) {
        return reject({ success: false });
      }
    });

  handleInsertConnectPosts = async (recurrences, recurpattern_id) => {
    await this.handleInsert(recurrences, recurpattern_id);
    this.props.updateConnectPostRefetch(moment().unix());
    this.props.handleCloseModal();
  };

  handleUpdateConnectPosts = async (recurrences, recurpattern_id) => {
    await this.handleDelete(recurpattern_id, recurrences[0]);
    await this.handleInsert(recurrences, recurpattern_id);
    this.props.updateConnectPostRefetch(moment().unix());
    this.props.handleCloseModal();
  };

  render() {
    const { active, date, recurrences, submitPattern } = this.state;
    const { isEdit, connectPost, connectPosts } = this.props;
    const simpleActive = active === "simple";
    const patternActive = active === "pattern";
    return (
      <Wrapper>
        {!isEdit && (
          <TabbedRadio
            active={active}
            items={items}
            handleOptionChange={this.handleOptionChange}
          />
        )}
        <div className="recurContainer" style={isEdit ? { marginTop: 0 } : {}}>
          {simpleActive && (
            <div className="simpleWrapper">
              <div className="label">{getTranslation("createRecurrence")}</div>
              <div className="dateWrapper">
                <Button
                  onClick={this.handleAddRepeat}
                  disabled={!date}
                  color="#F2CC56"
                  style={{ height: 32, maxWidth: 32, minWidth: 32 }}
                >
                  <AddIcon />
                </Button>
                <DateTimePicker
                  date={date}
                  placeholder={getTranslation("setRecDateTime")}
                  handleDateChange={this.handleDateChange}
                />
              </div>
              {recurrences.length > 0 && (
                <div className="recurrences">
                  {recurrences.map((item, index) => (
                    <div key={index} style={{ marginBottom: 20 }}>
                      <div className="label">
                        {getTranslation("recurrence")} #{index + 1}
                      </div>
                      <div className="dateWrapper">
                        <Button
                          onClick={() => this.handleDeleteRepeat(index)}
                          color="#F8F8F8"
                          style={{ height: 32, maxWidth: 32, minWidth: 32 }}
                        >
                          <img src={deleteIcon} alt="" />
                        </Button>
                        <DateTimePicker
                          date={item}
                          handleDateChange={date =>
                            this.handleDateEdit(date, index)
                          }
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
          {patternActive && (
            <PatternRecurrence
              connectPost={connectPost}
              connectPosts={connectPosts}
              isEdit={isEdit}
              submitPattern={submitPattern}
              handleInsertConnectPosts={this.handleInsertConnectPosts}
              handleUpdateConnectPosts={this.handleUpdateConnectPosts}
            />
          )}
        </div>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  influencerDetails: state.influencer.influencerDetails,
  allConnectPosts: state.influencer.allConnectPosts,
  connectPosts: state.influencer.connectPosts
});

export default compose(
  graphql(insertConnectPost, { name: "insertConnectPost" }),
  graphql(updateRecurPattern, { name: "updateRecurPattern" }),
  graphql(deleteConnectPost, { name: "deleteConnectPost" }),
  connect(mapStateToProps, { updateConnectPostRefetch })
)(RepeatPostModal);
