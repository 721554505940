import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { withApollo, ApolloProvider } from "react-apollo";
import { Switch, Route, Link } from "react-router-dom";
import Sticky from "react-sticky-el";
import InfluencerHome from "containers/Influencer/Blog/Home";
import InfluencerPost from "containers/Influencer/Blog/Post";
// flight booking routes
import TripBooking from "containers/TripBooking";
import LoginSignup from "containers/LoginSignup";
import FlightResults from "containers/FlightResults";
import BookingFailed from "containers/BookingFailed";
import Booking from "containers/Booking";
import ConfirmBooking from "containers/ConfirmBooking";
import PaymentSuccess from "containers/PaymentSuccess";
import PaymentProcess from "containers/PaymentProcess";
import Logout from "containers/Logout";
import Header from "components/Header";
//
import { getGuestInfluencer } from "containers/Influencer/queries";
import { IconPhone, IconEmail } from "components/common/SvgIcons";
import { setInfluencer } from "redux-store/actions";
import getTranslation from "utils/getTranslation";
import { hex2rgb, setItem } from "utils/utils";
import apolloClient from "apolloClient";

const Wrapper = styled.div`
  background-color: #fff;
  .content {
    max-width: ${(props) => (props.fullWidth ? "100%" : "1256px")};
    margin: auto;
    padding: ${(props) => (props.fullWidth ? "0" : "24px 0 12px 0")};
    min-height: calc(100vh - 105px);
  }
`;

const StyledHeader = styled.div`
  background-color: #ffffff;
  box-shadow: 0px 1px 4px rgba(101, 171, 120, 0.24);
  backdrop-filter: blur(6px);
  height: 56px;
  width: 100%;
  position: relative;
  img {
    position: absolute;
    top: 12px;
    height: 29px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #dddddd;
  padding: 12px;
  .left {
    display: flex;
    div {
      display: flex;
      align-items: center;
      margin-right: 50px;
      span {
        margin-left: 10px;
      }
    }
  }
  a {
    margin-left: 50px;
    text-decoration: underline;
  }
`;

class Dashboard extends React.PureComponent {
  async componentWillMount() {
    const hostArray = window.location.hostname.split(".");
    try {
      const influencer = await this.props.client.query({
        query: getGuestInfluencer,
        variables: {
          limit: 1,
          where: {
            tip_sub_domain: { _eq: hostArray[0] },
          },
        },
      });
      if (influencer.data.influencer.length > 0) {
        this.props.setInfluencer(influencer.data.influencer[0]);
        setItem("inflencerData", influencer.data.influencer[0]);
      }
    } catch (err) {}
  }
  render() {
    const { influencerDetails } = this.props;
    const backgroundColor = influencerDetails
      ? hex2rgb(influencerDetails.primary_colour, 0.08)
      : "#fff";
    const pathname = this.props.location.pathname;
    const bookingRoutes = pathname !== "/" && !pathname.includes("post/");
    return (
      <Wrapper
        style={{ backgroundColor: backgroundColor }}
        fullWidth={bookingRoutes}
      >
        {!influencerDetails ? null : (
          <>
            <Sticky className="stickyHeader" stickyStyle={{ zIndex: 1000 }}>
              {!bookingRoutes ? (
                <StyledHeader>
                  <Link to="/">
                    <img src={influencerDetails.logo} alt="" />
                  </Link>
                </StyledHeader>
              ) : (
                <Header />
              )}
            </Sticky>
            <div className="content">
              <Switch>
                <Route
                  exact
                  path="/"
                  component={() => (
                    <InfluencerHome influencerDetails={influencerDetails} />
                  )}
                />
                <Route
                  exact
                  path="/post/:id"
                  render={(props) => (
                    <InfluencerPost
                      influencerDetails={influencerDetails}
                      {...props}
                    />
                  )}
                />
                <ApolloProvider client={apolloClient}>
                  <Route
                    exact
                    path="/new-trip"
                    render={(props) => {
                      return <TripBooking isNewTrip {...props} />;
                    }}
                  />
                  <Route path="/login" exact component={LoginSignup} />
                  <Route exact path="/booking/:tripId" component={Booking} />
                  <Route
                    exact
                    path="/confirm-booking/:tripId"
                    component={ConfirmBooking}
                  />
                  <Route
                    path="/booking-failed"
                    exact
                    component={BookingFailed}
                  />
                  <Route
                    exact
                    path="/flights/:encoded"
                    component={FlightResults}
                  />
                  <Route
                    exact
                    path="/emp-redirect"
                    component={PaymentSuccess}
                  />
                  <Route exact path="/logout" component={Logout} />
                  <Route
                    exact
                    path="/payment-process"
                    component={PaymentProcess}
                  />
                </ApolloProvider>
              </Switch>
            </div>
            <Footer style={{ color: influencerDetails.primary_colour }}>
              <div className="left">
                <div>
                  <IconPhone stroke={influencerDetails.primary_colour} />
                  <span>+123123123</span>
                </div>
                <div>
                  <IconEmail stroke={influencerDetails.primary_colour} />
                  <span>nimitw@gmail.com</span>
                </div>
              </div>
              <div className="right">
                <a>{getTranslation("privacy")}</a>
                <a>{getTranslation("terms")}</a>
              </div>
            </Footer>
          </>
        )}
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  influencerDetails: state.influencer.influencerDetails,
});

export default withApollo(
  connect(mapStateToProps, { setInfluencer })(Dashboard)
);
