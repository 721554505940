import React from "react";
import styled from "styled-components";
import moment from "moment";
import parser from "react-html-parser";
import { DateWrapper } from "components/Influencer/BlogCards/CardStyles";
import DestinationLabels from "components/Influencer/BlogCards/DestinationLabels";
import BookingSection from "./BookingSection";
import { resizedImageWidth } from "utils/utils";

const Wrapper = styled.div`
  background-color: #fff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
  max-width: 936px;
  margin: auto;
`;
const Cover = styled.div`
  position: relative;
  height: 703px;
  width: 100%;
  background-size: cover;
`;

const Content = styled.div`
  padding: 30px 80px;
  p {
    font-size: 16px;
    line-height: 150%;
    color: #777777;
    margin: 0 0 30px 0;
  }
  figure {
    margin: 0 0 30px 0;
  }
  img {
    width: 100%;
    object-fit: cover;
  }
`;

export default class extends React.PureComponent {
  constructor(props) {
    super(props);
    const content = parser(this.props.blogData.text, {
      transform: this.cleanArticle,
    });
    this.state = {
      content,
    };
  }

  cleanArticle = (node, index) => {
    if (node.type === "tag" && node.name === "img") {
      const { ...props } = node.attribs;
      delete props.style; // styles should not have a string content
      return (
        <img
          {...props}
          alt={props.src}
          src={resizedImageWidth(props.src, 776)}
        />
      );
    }
  };

  render() {
    const { content } = this.state;
    const { blogData, color } = this.props;
    let dateArray = [];
    if (blogData) {
      const date = moment(blogData.created_at).format("DD,MMM,YYYY");
      dateArray = date.split(",");
    }
    const coverimage = blogData.coverimage || blogData.coverimage1;
    return (
      <Wrapper>
        <Cover style={{ backgroundImage: `url(${coverimage})` }}>
          <DateWrapper>
            <span className="date">{dateArray[0]}</span>
            <span className="month">{dateArray[1]}</span>
          </DateWrapper>
          <div className="destinations">
            <DestinationLabels blogData={blogData} color={color} />
          </div>
        </Cover>
        <Content>
          <>{content}</>
          {blogData.postdestinations.length > 0 && (
            <BookingSection
              destinations={blogData.postdestinations}
              color={color}
            />
          )}
        </Content>
      </Wrapper>
    );
  }
}
