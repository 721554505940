export default `
# Einführung
Fly Strait OÜ, registriert in Tallinn, Estland mit der Registrierungsnummer 14373971 ("wir", "uns", "unser" oder einfach nur "Fly Strait"), ist der so genannte "Datenverantwortliche" Ihrer personenbezogenen Daten und ist daher für die Rechtmäßigkeit dessen verantwortlich, was wir mit Ihren personenbezogenen Daten tun.

&nbsp;

Diese Website wird von Fly Strait betrieben. Wir verarbeiten eine Vielzahl von personenbezogenen Daten wie Namen, E-Mail-Adressen und andere reisebezogene Informationen im täglichen Geschäft. Daher nehmen wir die Datensicherheit und die Einhaltung der Datenschutzgesetze sehr ernst. Diese Datenschutzerklärung erläutert, wie wir alle personenbezogenen Daten, die wir über Sie bei der Nutzung dieser Website erfassen, speichern, verwenden und offenlegen, sowie wie wir die Privatsphäre und Vertraulichkeit Ihrer personenbezogenen Daten schützen. Ihre Privatsphäre ist uns wichtig. Egal, ob Sie neu in unserem Service sind oder ein langjähriger Nutzer, nehmen Sie sich bitte die Zeit, unsere Arbeitsweise kennenzulernen - und kontaktieren Sie uns, wenn Sie Fragen haben.

# WELCHE PERSONENBEZOGENEN DATEN WIR ERHEBEN
Im Allgemeinen ist die Art der von uns erfassten personenbezogenen Daten die Information, die wir benötigen, damit Sie Ihre Reisevorbereitungen und Buchungen vornehmen können. Darunter fallen Informationen wie Ihr Vor- und Nachname, Geburtsdatum, Telefonnummer und E-Mail-Adresse. Die personenbezogenen Daten, die wir erhalten müssen, um Ihnen die von Ihnen über unsere Websites gebuchte Reiseorganisation zur Verfügung zu stellen, sind die einzigen Daten, die zwingend erforderlich sind. Abhängig von der Art der von Ihnen genutzten Reisedienstleistungen können wir auch Ihre Vielfliegernummer, Informationen über Ihre Ernährungsbedürfnisse und Gesundheitsprobleme (falls vorhanden) sowie andere Informationen erfassen, die für Ihre Reisevorbereitungen relevant sind oder von einem anderen Reisedienstleister (wie Fluggesellschaften und Hotels) benötigt werden. Dies ist keine abschließende Aufzählung. Wenn Sie unseren Support anrufen, werden wir die von Ihnen während des Telefonats angegebenen Daten erheben. Wie Sie unten sehen können, sammeln unsere Cookies auch einige Informationen.

&nbsp;

Wenn Sie eine Reservierung für eine andere Person über unsere Website vornehmen, werden wir personenbezogene Daten über diese Person erfragen. Unter diesen Umständen verlassen wir uns darauf, dass Sie diese Personen über diese Datenschutzerklärung informieren.

# WELCHE PERSONENBEZOGENEN DATEN WIR ERHEBEN
In einigen Fällen können wir so genannte "spezielle Kategorien von personenbezogenen Daten" über Sie verarbeiten, die als sensibel angesehen werden können. Dies wäre z.B. der Fall, wenn Sie (i) ein ärztliches Attest zur Inanspruchnahme eines Kündigungsschutzes oder einer Rückerstattung durch eine Fluggesellschaft eingereicht haben, (ii) einen medizinischen oder gesundheitlichen Zustand haben, der Ihre Reise beeinträchtigt und für den Sie Hilfe anfordern oder für den eine bestimmte Genehmigung erforderlich ist, oder (iii) eine Anfrage gestellt haben, die einige andere sensible personenbezogene Daten über Sie offenbart.

&nbsp;

Bevor wir sensible personenbezogene Daten von Ihnen verarbeiten, benötigen wir Ihre Zustimmung. Wir bitten Sie daher, für die Übermittlung sensibler Daten die entsprechenden Kontaktformulare auf unseren Websites zu verwenden. Mit den Kontaktformularen können Sie uns die nach den geltenden datenschutzrechtlichen Bestimmungen erforderliche Einwilligung erteilen. Die Zustimmung kann jederzeit widerrufen werden. Wir werden keine sensiblen personenbezogenen Daten verarbeiten, die wir von Ihnen nicht verarbeiten dürfen oder die Sie uns nicht zur Verfügung gestellt haben. Ein begrenzter Teil unserer Mitarbeiter hat Zugang zu Ihren sensiblen personenbezogenen Daten, und nachdem wir Ihre sensiblen Daten gemäß Ihrer Anfrage behandelt haben, werden wir die Daten so schnell wie möglich löschen.

# Was passiert mit Ihren persönlichen Daten
Um mit Ihren personenbezogenen Daten umgehen zu dürfen, verpflichten uns die geltenden Datenschutzgesetze, für jeden unserer Zwecke zur Verarbeitung Ihrer personenbezogenen Daten eine sogenannte "Rechtsgrundlage" zu haben. Aus diesem Grund haben wir die folgende Tabelle erstellt, um unsere Rechtsgrundlage für jeden unserer Zwecke darzustellen.

| Was wir tun (unsere Ziele beim Umgang mit Ihren personenbezogenen Daten) | Rechtliche Grundlage | Speicherzeit |
| --- | --- | --- |
| Ermöglichen Sie die von Ihnen bei uns angeforderten Reisearrangements und Buchungen (d.h. Buchung der von uns vermittelten Reiseleistungen sowie Erbringung eigener Leistungen). | Erfüllung eines Vertrages mit Ihnen Wenn Sie uns sensible personenbezogene Daten zur Verfügung gestellt haben, ist die Zustimmung die Rechtsgrundlage. | 3 Jahre ab Kaufdatum. Die Zustimmung zu sensiblen personenbezogenen Daten kann jederzeit widerrufen werden.|
| Wenn Sie sich für die Einrichtung eines Benutzerkontos auf unserer Website entschieden haben, stellen wir Ihnen dieses Konto zur Verfügung. Das Konto beinhaltet den Zugang zu Informationen über Ihre bisherigen Reisearrangements und Buchungen. Wir speichern auch Ihren Benutzernamen und Ihr Passwort. | Erfüllung eines Vertrages mit Ihnen | Ihre Benutzerkonto-Daten sowie personenbezogene Daten im Zusammenhang mit Ihren früheren Reisearrangements und Buchungen werden gespeichert, bis Sie sich entscheiden, Ihr Benutzerkonto über unsere Website aufzuheben. Wenn Sie sich jedoch 18 Monate lang nicht in Ihrem Benutzerkonto angemeldet haben, können wir dieses Konto und die darin enthaltenen Informationen löschen, sofern wir keine andere Rechtsgrundlage für die Speicherung haben. |
| If you have commenced a booking process, but not completed the purchase, we may send you an email with a link back to the search result, or to the commenced booking, depending on at which point your booking process on the website was discontinued. | Our legitimate interest to conduct business and enable you to complete your purchase without having to fill out all information once more. If you do not want these emails, you may opt-out at any time within the email. | 24 hours from the time you left the booking process. |
| Before your travel arrangement commences, we will provide you with additional information and offers related to your specific travel arrangement, such as possible add-ons like extra baggage and other information of use for your travel. Some of the information is based on profiling carried out on the information you have provided over the course of the booking process (for example the date(s) you are travelling, your destination etc.). | Our legitimate interest to offer you a more convenient travel, and to enable you to easily find more information of relevance to you. If you do not want this information, you may opt-out at any time within the email. | No longer than until your travel arrangement commences. Other similar processing may continue in accordance with the purposes set out below. |
| After your travel arrangement, we may provide you with our newsletter containing recommendations on other travel arrangements and travel related products and services that might be of interest to you. Some of the recommendations are based on profiling on your previous choices when booking a trip and your behavior in relation to our emails. | Our legitimate interest to conduct business and allow you to make travel arrangements of interest to you. If you do not want these emails, you may opt-out at any time within the email or on our website. Also, before you finalize a purchase, you may object to receiving the newsletter. | 3 years from the date of purchase. |
| Record phone calls for quality assurance purposes and for any future requests or inquiries by you. | Our legitimate interest to (i) improve our service through internal education, and where applicable (ii) solve your potential requests or claims. If you do not want the phone call to be recorded, you may object to such recording before the recording starts. | 6 months from the date of the phone call. Only a limited number of persons have access to your recording. |
| Use of cookies to for example improve the usability of this website, provision of a personalized experience and for collecting usage statistics. We also use session cookies to improve the security of this website | Our legitimate interest to (i) improve our website, (ii) show you offers of interest to you, and (iii) have a secure service offering and website. If you don’t want us to store cookies on your computer, you may change the settings in your browser at any time. | Depending on the type of cookie. [See below for more detailed storage times.](/privacy#Cookies) |

&nbsp;

In addition to the above, we undertake such day-to-day measures that are necessary for businesses providing services to consumers, such as bookkeeping, accounting, billing, fulfilling anti-money laundering obligations and maintaining our website security. To the extent this is not mandatory under applicable laws, we undertake these measures based on our legitimate interest. We may also analyze our customers’ behavior in order to improve our websites and services on a general level. However, such analysis will use generalized or anonymized data on an aggregated level.

# Sharing your personal data
We will only share your personal data where necessary for the purposes listed in this privacy policy. This may be to other companies within Fly Strait, government authorities and our trusted business partners. For example we may share your personal data (including sensitive personal data when applicable) with business partners such as airlines, hotel providers, insurance companies and Global Distribution Systems (so called GDSs) to enable your travel arrangements and bookings.

&nbsp;

Each partner is responsible for its own handling of your personal data after it has received it from us, meaning that you must contact the partner in question for any requests related to your rights under applicable data protection legislation. We recommend that you read the partners’ respective privacy policies for information on their handling of your personal data. We will also share your personal data with other companies (so called “data processors”) needed to deliver the services you requested, such as service providers running our call centers and our other suppliers and vendors that will handle your personal data when providing their services to us (for example external storage).

&nbsp;

Due to the global nature of the travel industry, your personal data may be processed in different locations around the world when the parties we share your personal data with, reside in a country outside the EU/EEA. Our sharing of personal data outside the EU/EEA requires certain legal ground under applicable data protection legislation. Where a country is regarded by the European Commission to be a country with adequate level of protection for personal data, this will be our legal ground. Otherwise there are three main types of legal ground on which that we may base such sharing. These are:

&nbsp;

i. that the transfer is necessary for our performance of the contract with you (for example when you have booked a flight with an airline residing outside the EU/EEA);

&nbsp;

ii. that the transfer will be based on the standard data protection clauses for transfer of personal data to countries outside of the EU/EEA adopted by the European Commission (a copy of these standard data protection clauses can be found at http://ec.europa.eu/justice/dataprotection/internationaltransfers/transfer/); and

&nbsp;

iii. the EU-U.S. Privacy Shield, where the transfer is made to the United States and the recipient is duly
certified.

# Third party providers
Please note that our website contains links to other websites and serves content from third-party providers. This privacy policy only applies to our website and our services. When you follow links to other websites, or use third-party services and products, you should read their privacy policies. Also, if you choose to contact us via social media, this privacy policy does not apply to any personal data submitted by you as part of such contact – in such case, we recommend that you read the privacy policy of such social media provider.

# Your rights
According to the applicable data protection legislation, you have certain rights as a so-called “data subject”. Below, we have listed your rights. Your rights include the following:

i. Right to access – You are entitled to access the personal data that we handle. You are also entitled to receive certain information about what we do with the personal data. Such information is provided in this document.

&nbsp;

ii. Right to rectification – Under certain circumstances, you are entitled to correct inaccurate personal data concerning you and to have incomplete personal data completed. Please note that we might not be able to correct inaccurate personal data provided by you due to e.g. the airlines rules, and that such particular change may incur a cost.

&nbsp;

iii. Right to erasure – Under certain circumstances, you are entitled to have your personal data erased. This is the so-called “right to be forgotten”.

&nbsp;

iv. Right to restriction of processing – Under certain circumstances, you are entitled to restrict how we use your personal data.

&nbsp;

v. Right to data portability – You are entitled to receive your personal data (or have your personal data directly transmitted to another data controller) in a structured, commonly used and machine-readable format from us.

&nbsp;

vi. Right to object – You are entitled to object to certain types of handling of personal data that we carry out. This applies to all our activities that are based on our “legitimate interest”. Finally, you also have the right to lodge a complaint with the applicable data protection supervisory authority.

# Cookies
A cookie is a small text file that is stored on your computer, some only until you close down your browser (so-called “session cookies”) and some for an extended period of time (so-called “permanent cookies”). If you do not wish to allow storage of cookies on your computer, you can change the settings in your browser. Note however that in a few cases some of our website features may not function properly and some content may not be displayed correctly as a result.

&nbsp;

This website uses cookies for a number of reasons, including for provision of a personalized experience, for improving the usability of this website and for collecting usage statistics. We also use session cookies to improve the security of this website.

&nbsp;

In some cases when using cookies, we share data with third parties. For example, we use Google Analytics and Google AdWords, services which transmits website traffic data to Google servers. Google Analytics does not identify individual users and does not associate your IP address with any other data held by Google. We use reports provided by Google to help us understand website traffic and webpage usage and optimize advertisements bought from Googles own and other advertising network. Google may process the data in the manner described in [Google's Privacy Policy](https://policies.google.com/privacy) and for the purposes set out above in this section. You can opt out of Google Analytics if you disable or refuse the cookie, disable JavaScript, or use the opt-out service provided by Google. [To opt out of advertising features from Google use this link.](https://tools.google.com/dlpage/gaoptout)

&nbsp;

Our website also uses Facebook pixel, which collects anonymized aggregated data that helps us with optimization of ad purchases on Facebooks different platforms (including Instagram). Facebook collects a user id that will allow them to match if a user has visited a site with the Facebook pixel. We as advertisers can however never identify the behavior of a specific user. Facebook and its related platforms are in a closed advertising ecosystem where their users can regulate if they consent to advertisers using data collected from their websites to purchase ads on Facebook. [To view and change your advertising settings on Facebook use this link.](https://facebook.com/ads/preferences)

&nbsp;

Further, our website uses conversion tracking scripts and cookies from [Microsoft Bing](https://privacy.microsoft.com/), [TripAdvisor](https://www.smartertravel.com/privacy-policy) and [TvSquared](https://tvsquared.com/privacy-policy/) (you can view their privacy policies by following the links).

&nbsp;

All these services collect aggregated statistical data that helps us optimize purchases of advertisements. We as advertisers cannot identify a single user using this data. You can turn of the use of cookies in your browser settings.

&nbsp;

<!-- [To be transparent, we have summarized the cookies used on this website here. ](https://flystrait.com/cookie-sessions) -->
[For further information about cookies visit www.youronlinechoices.com.](https://www.youronlinechoices.com)

# Data security
In order to keep your personal data secure, we have implemented a number of technical and organizational security measures. For example, we maintain high levels of technical security in all systems (including traceability, disaster recovery, access limitations etc.). In addition, we have adopted policies to ensure that our employees (which of course are subject to confidentiality obligations) do not use personal data when it is not necessary. Such policies also set out our standards for when we contract suppliers or introduce new IT systems within our operations.

# How to contact us
If you have any questions relating to our handling of your personal data or our use of cookies, or if you
would like to invoke any of your rights under applicable data protection legislation, please send an email
to: privacy@flystrait.com

# Changes to the privacy policy
If we change how we handle your personal data or how we use cookies, we will promptly update this privacy policy and publish it on this website.
`;
