import React, { useState } from "react";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import Lottie from "react-lottie";
import { Button } from "components/common";
import { getItem } from "utils/utils";
import getTranslation from "utils/getTranslation";
import animData from "assets/ae-animations/TourEiffel/data";
import { setItem } from 'utils/utils';

const Wrapper = styled.div`
  height: 550px;
  width: 1250px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 800px) {
    display: flex;
    flex-flow: column-reverse;
    width: 100%;
  }
  .details {
    min-width: 325px;
    width: 325px;
    @media (max-width: 800px) {
      width: 100%;
    }
    h1 {
      color: #007ac7;
      font-size: 36px;
      line-height: 50px;
      font-weight: 400;
      @media (max-width: 800px) {
        text-align: center;
      }
    }
    p {
      color: #3f3d56;
      font-weight: 300;
      font-size: 16px;
      line-height: 26px;
    }
    .btnWrapper {
      margin-top: 60px;
      width: 317px;
      @media (max-width: 800px) {
        width: 100%;
        margin-top: 30px;
      }
    }
  }
  .illustrations {
    display: flex;
    width: 925px;
    height: 100%;
    position: relative;
    @media (max-width: 800px) {
      width: 100%;
      overflow: hidden;
    }
    .left {
      display: flex;
    }
    .right {
      display: flex;
      align-items: flex-end;
    }
  }
`;

const HeroSection = () => {
  const [revealed, setRevealed] = useState(false);
  function handleReveal() {
    setTimeout(() => {
      setRevealed(true);
    }, 300);
  }
  return (
    <Wrapper>
      <div className="details">
        <Fade right={!isMobile} left={isMobile}>
          <h1>{getTranslation("beginJourney")}</h1>
        </Fade>
        <Fade right={!isMobile} left={isMobile} delay={200}>
          <p>{getTranslation("tipLandingText")}</p>
        </Fade>
        <div className="btnWrapper">
          <Fade right={!isMobile} left={isMobile} delay={300}>
            <Link
              onClick={(e) => {
                setItem("redirectTo", "/influencer/setup/step1");
              }
              }
              to={
                getItem("useraccount_id")
                  ? "/influencer/setup/step1"
                  : "/login/register"
              }
            >
              <Button
                style={{
                  color: "#007AC7",
                  border: "1px solid #007AC7",
                }}
                color="#fff"
              >
                {getTranslation(getItem("useraccount_id") ? "begin" : "signup")}
              </Button>
            </Link>
          </Fade>
        </div>
      </div>
      <Fade onReveal={handleReveal}>
        <div className="illustrations" id="tower-anim">
          {revealed && (
            <Lottie
              options={{
                loop: false,
                autoplay: true,
                animationData: animData,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
            />
          )}
        </div>
      </Fade>
    </Wrapper>
  );
};

export default HeroSection;
