import React from "react";
import styled from "styled-components";
import MomentUtils from "@date-io/moment";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

const Wrapper = styled.div`
  overflow: hidden;
  .MuiTextField-root {
    width: 100%;
  }
  .MuiInputBase-root {
    background-color: #d0f3fc;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.08), inset 0px -1px 0px #007ac7 !important;
  }
  .MuiFilledInput-underline:before {
    border-bottom: 0;
  }
  &.withIcon {
    .MuiInputLabel-filled {
      transform: translate(44px, 29px) scale(1);
      &.MuiFormLabel-filled,
      &.Mui-focused {
        transform: translate(44px, 10px) scale(0.75);
      }
    }
  }
  &.hasError {
    .MuiInputBase-root {
      background-color: #fbdddd;
      box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.08), inset 0px -1px 0px #eb5757 !important;
    }
    label {
      color: #eb5757;
    }
    .MuiFilledInput-underline:after {
      border-bottom-color: #eb5757;
    }
  }
`;

export default class extends React.PureComponent {
  handleDateChange = date => {
    this.props.handleDateChange(date);
  };
  render() {
    const { placeholder, date, disabled } = this.props;
    return (
      <Wrapper>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DateTimePicker
            label={date ? "Day. Date. 24h Clock" : placeholder}
            inputVariant="filled"
            value={date}
            disablePast
            disabled={disabled}
            onChange={this.handleDateChange}
            format="ddd. MMM DD, HH:mm"
          />
        </MuiPickersUtilsProvider>
      </Wrapper>
    );
  }
}
