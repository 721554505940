import React, { useState } from "react";
import styled from "styled-components";
import { FacebookIcon, TwitterIcon } from "components/common/SvgIcons";
import { socialNetworks } from "utils/utils";
import Check from "assets/tip/Check-circle.svg";

const Wrapper = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 1px solid #56ccf2;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-right: 16px;
  cursor: pointer;
  &.active {
    background-color: #f0f0f0;
    border: 0;
  }
  .select {
    position: absolute;
    top: 0;
    right: 0;
  }
`;

export default ({ connect, handleToggle }) => {
  const [selected, setSelected] = useState(true);
  function handleClick() {
    if (handleToggle) {
      setSelected(!selected);
      handleToggle(connect, selected);
    }
  }
  return (
    <Wrapper onClick={handleClick} className={connect.selected ? "active" : ""}>
      {connect.selected && <img src={Check} className="select" alt="" />}
      {connect.connect.toLowerCase() === "facebook" && (
        <FacebookIcon stroke={connect.selected ? undefined : "#DDDDDD"} />
      )}
      {connect.connect.toLowerCase() === "twitter" && (
        <TwitterIcon stroke={connect.selected ? undefined : "#DDDDDD"} />
      )}
    </Wrapper>
  );
};
