import React from "react";
import styled from "styled-components";
import { withApollo } from "react-apollo";
import { Loader } from "components/common";
import { getItem } from "utils/utils";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  flex-flow: column;
  h4 {
    font-size: 22px;
    color: #444444;
  }
`;

class PaymentSuccess extends React.PureComponent {
  constructor(props) {
    super(props);
    if (getItem("paymentType") === "domain") {
      props.history.replace("/domain-payment-process");
    } else {
      this.handlePaymentComplete();
    }
    this.state = {};
  }
  handlePaymentComplete = () => {
    this.props.history.push(`/booking/${getItem("tripId")}`);
  };
  render() {
    return (
      <Wrapper>
        <Loader size={50} />
      </Wrapper>
    );
  }
}

export default withApollo(PaymentSuccess);
