import React from "react";
import styled from "styled-components";
import parser from "react-html-parser";
import TransportDetails from "components/Influencer/PostTypes/TransportDetails";
import { resizedImageWidth } from "utils/utils";

const Wrapper = styled.div`
  .day {
    h5 {
      font-size: 16px;
      font-family: "Roboto Condensed", sans-serif;
      text-transform: uppercase;
      font-weight: 600;
      margin-bottom: 10px;
    }
    h3 {
      font-size: 24px;
      line-height: 28px;
      font-weight: 400;
      color: #222222;
      margin-top: 0;
    }
  }
`;

const Content = styled.div`
  p {
    font-size: 16px;
    line-height: 150%;
    color: #777777;
    margin: 0 0 30px 0;
  }
  figure {
    margin: 0 0 30px 0;
  }
  img {
    width: 100%;
    object-fit: cover;
  }
`;

export default class extends React.PureComponent {
  constructor(props) {
    super(props);
    const content = parser(props.data.text, {
      transform: this.cleanArticle,
    });
    this.state = {
      content,
    };
  }

  cleanArticle = (node, index) => {
    if (node.type === "tag" && node.name === "img") {
      const { ...props } = node.attribs;
      delete props.style; // styles should not have a string content
      return (
        <img
          {...props}
          alt={props.src}
          src={resizedImageWidth(props.src, 776)}
        />
      );
    }
  };
  render() {
    const { content } = this.state;
    const { data, color } = this.props;
    return (
      <Wrapper>
        <div className="day">
          <h5 style={{ color: color }}>Day {data.day}:</h5>
          <h3>{data.title}</h3>
        </div>
        <Content>{content}</Content>
        {data.itinerarydaytransports.map((transport) => (
          <TransportDetails data={transport} noEdit />
        ))}
      </Wrapper>
    );
  }
}
