import React from "react";
import styled from "styled-components";
import patternImg from "assets/tip/Pattern.svg";

const Wrapper = styled.div`
  div {
    display: flex;
    align-items: center;
  }
`;

export default class extends React.PureComponent {
  render() {
    const { event } = this.props;
    return (
      <Wrapper>
        <div style={{ position: "relative" }}>
          <img src={patternImg} alt="" />
          <span>{event.title}</span>
        </div>
      </Wrapper>
    );
  }
}
