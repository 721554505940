import React, { PureComponent } from "react";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Fade from "@material-ui/core/Fade";
import Slide from "@material-ui/core/Slide";
import { Button } from "components/common";
import { CloseIcon } from "components/common/SvgIcons";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />;
});

const TransitionSlide = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Container = styled.div``;

const Header = styled.div`
  background-color: #f0f0f0;
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  .texts {
    display: flex;
    flex-flow: column;
    margin-left: 20px;
  }
  .text {
    font-family: "Roboto Condensed", sans-serif;
    text-transform: uppercase;
    color: #777777;
    font-size: 18px;
    line-height: 21px;
  }
  .text2 {
    font-family: "Roboto Condensed", sans-serif;
    color: #444444;
    font-size: 16px;
    line-height: 19px;
    a {
      color: #007ac7;
      font-weight: 600;
    }
  }
  .leftSection {
    display: flex;
    align-items: center;
  }
  .rightSection {
    display: flex;
    align-items: center;
  }
`;

class TipModal extends PureComponent {
  handleClose = () => {
    this.props.handleCloseModal();
  };

  render() {
    const {
      open,
      text1,
      text2,
      submitText,
      children,
      dialogStyle = {},
      titleStyle = {},
      disableFullScreen,
      slide,
      btnWidth,
      loading,
      handleSubmit,
      additionalButtons
    } = this.props;
    return (
      <Container>
        <Dialog
          modal={false}
          open={open}
          fullScreen={isMobile && !disableFullScreen}
          TransitionComponent={slide ? TransitionSlide : Transition}
          onClose={this.handleClose}
          Transition
          scroll="paper"
          bodyStyle={{ padding: 0 }}
          contentClassName="test"
          PaperProps={{
            style: { ...dialogStyle }
          }}
        >
          {text1 && (
            <DialogTitle
              id="alert-dialog-slide-title"
              style={{
                boxShadow: "inset 0px -1px 0px rgba(0, 0, 0, 0.16)",
                padding: "0"
              }}
            >
              <Header style={titleStyle}>
                <div className="leftSection">
                  <Button
                    onClick={this.handleClose}
                    color="#D0F3FC"
                    style={{ height: 32, maxWidth: 32, minWidth: 32 }}
                  >
                    <CloseIcon />
                  </Button>
                  {!isMobile && (
                    <div className="texts">
                      <span className="text">{text1}</span>
                      <span className="text2">{text2}</span>
                    </div>
                  )}
                </div>
                <div className="rightSection">
                  {additionalButtons}
                  <Button
                    onClick={handleSubmit}
                    disabled={loading}
                    style={{
                      height: 36,
                      minWidth: btnWidth || "fit-content !important"
                    }}
                  >
                    {submitText}
                  </Button>
                </div>
              </Header>
            </DialogTitle>
          )}
          <DialogContent style={{ padding: 0 }}>{children}</DialogContent>
        </Dialog>
      </Container>
    );
  }
}

export default TipModal;
