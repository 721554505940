import React from "react";
import styled from "styled-components";
import { getPosts } from "containers/Influencer/queries";
import { withApollo } from "react-apollo";
import { Link } from "react-router-dom";
import { Loader } from "components/common";
import { BlogPostCard } from "components/Influencer/BlogCards";
import config from "config";

const Wrapper = styled.div`
  .posts {
    display: flex;
    flex-wrap: wrap;
    .post {
      margin-right: 24px;
      text-decoration: none;
      &:nth-child(4n + 0) {
        margin-right: 0;
      }
      .blogPostCard {
        animation-name: fadeInUp;
        animation-duration: 0.5s;
        animation-fill-mode: both;
      }
    }
  }
`;

class Home extends React.PureComponent {
  state = {
    posts: [],
    loading: true
  };
  async componentWillMount() {
    try {
      const posts = await this.props.client.query({
        query: getPosts,
        variables: {
          where: {
            influencer_id: { _eq: this.props.influencerDetails.id },
            lastpublished_at: { _is_null: false }
          }
        }
      });
      this.setState({ posts: posts.data.post, loading: false }, () => {
        window.gtag("config", config.gaId, {
          custom_map: { dimension1: "influencerId" }
        });
        window.gtag("event", "page_view", {
          influencerId: this.props.influencerDetails.id
        });
      });
    } catch (err) {}
  }
  render() {
    const { loading, posts } = this.state;
    const { influencerDetails } = this.props;
    return (
      <Wrapper>
        {loading ? (
          <Loader color={influencerDetails.primary_colour} />
        ) : (
          <div className="posts">
            {posts.map((post, i) => (
              <div className="post" key={post.id}>
                <Link to={`/post/${post.id}`}>
                  <BlogPostCard
                    color={influencerDetails.primary_colour}
                    blogData={post}
                    layout={post.coverimage_layout}
                    style={{ animationDelay: `${0.2 * i}s` }}
                  />
                </Link>
              </div>
            ))}
          </div>
        )}
      </Wrapper>
    );
  }
}

export default withApollo(Home);
