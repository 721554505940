import React, { PureComponent } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { graphql, compose } from "react-apollo";
import parser from "react-html-parser";
import moment from "moment";
import { CoverImagesSelection } from "components/Influencer";
import { updatePost } from "containers/Influencer/queries";
import FormAction from "containers/Influencer/Setup/FormAction";
import { CoverSelection, CoverReorder } from "components/Influencer/Partials";
import { uploadImage } from "utils/api";
import getTranslation from "utils/getTranslation";

const Wrapper = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 24px 24px 0 24px;
  @media (max-width: 800px) {
    width: 100%;
  }
`;
let blogImages = [];
class CoverSelectionModal extends PureComponent {
  constructor(props) {
    super(props);
    let coverImages = [];
    const { blogData } = props;
    coverImages = this.getCoverImages(blogData);
    if (blogData.type === "BLOG") {
      parser(blogData.text, {
        transform: this.cleanArticle
      });
    }
    if (blogData.type === "ITINERARY") {
      blogData.itinerarydays.forEach(day => {
        parser(day.text, {
          transform: this.cleanArticle
        });
      });
    }
    blogImages.forEach(img => {
      const exists = coverImages.find(item => item.image === img);
      if (!exists) {
        coverImages = [
          ...coverImages,
          { image: img, selected: false, persist: true }
        ];
      }
    });
    this.state = {
      selectedLayout: "||",
      coverImages,
      step: 1,
 
      postData: blogData
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.submitStep &&
      this.props.submitStep !== nextProps.submitStep
    ) {
      // this.handleSubmit();
    }
  }
  componentDidUpdate() {
    const {
      coverImages
    } = this.state;
    const isFormCompleted = !!coverImages.find(image => image.selected);
    if (isFormCompleted && isFormCompleted !== this.state.isFormCompleted) {
      this.setState({
        isFormCompleted,
      });
    }
  }

  componentWillUnmount() {
    blogImages = [];
  }

  getCoverImages = blogData => {
    let coverImages = [];
    if (blogData.coverimage1) {
      const payload = {
        image: blogData.coverimage1,
        selected: true
      };
      coverImages = [...coverImages, payload];
    }
    if (blogData.coverimage2) {
      const payload = {
        image: blogData.coverimage2,
        selected: true
      };
      coverImages = [...coverImages, payload];
    }
    if (blogData.coverimage3) {
      const payload = {
        image: blogData.coverimage3,
        selected: true
      };
      coverImages = [...coverImages, payload];
    }
    if (blogData.coverimage4) {
      const payload = {
        image: blogData.coverimage4,
        selected: true
      };
      coverImages = [...coverImages, payload];
    }
    if (blogData.type === "QUICK") {
      const images = blogData && blogData.text ? blogData.text.split("!@#") : [];
      coverImages = images.map(image => ({
        image,
        selected: coverImages.find(img => img.image === image) ? true : false
      }));
    }
    return coverImages;
  };

  cleanArticle = (node, index) => {
    if (node.type === "tag" && node.name === "img") {
      const src = node.attribs.src;
      blogImages = [...blogImages, src];
    }
  };

  handleLayoutChange = (layout, doSubmit) => {
    this.setState({ selectedLayout: layout }, () => {
      if (doSubmit) {
        this.handleSubmit();
      }
    });
  };

  handleCoverImagesChange = coverImages => {
    this.setState({
      coverImages
    });
  };

  handleSubmit = async () => {
    this.setState({ loading: true });
    try {
      const { step, coverImages } = this.state;
      let images = [];
      coverImages.forEach(image => {
        if (image.selected) {
          images = [...images, image.image];
        }
      });
      const skipLayoutSelection = images.length === 1;
      if (step === 1) {
        const response = await this.props.updatePost({
          variables: {
            _set: {
              coverimage1: images[0] || null,
              coverimage2: images[1] || null,
              coverimage3: images[2] || null,
              coverimage4: images[3] || null
            },
            where: {
              influencer_id: {
                _eq: this.props.influencerDetails.id
              },
              id: { _eq: this.props.blogData.id }
            }
          }
        });
        this.setState({
          postData: response.data.update_post.returning[0],
          step: skipLayoutSelection ? 1 : 2,
          loading: false
        });
      }
      if (step === 2 || (step === 1 && skipLayoutSelection)) {
        const response = await this.props.updatePost({
          variables: {
            _set: {
              coverimage_layout: skipLayoutSelection
                ? "1"
                : this.state.selectedLayout
            },
            where: {
              influencer_id: {
                _eq: this.props.influencerDetails.id
              },
              id: { _eq: this.props.blogData.id }
            }
          }
        });
        this.setState({
          postData: response.data.update_post.returning[0],
          step: 3,
          loading: false
        });
      } else if (step === 3) {
        this.setState({
          requestData: moment().unix()
        });
      }
    } catch (err) {
      console.log(err);
      this.setState({ loading: false });
    }
  };

  handleCropSubmit = async (croppedImages, blogData, images, file) => {
    this.setState({ loading: true });
    let postData = blogData;
    const upload = await uploadImage(file);
    for (let i = 0, j = croppedImages.length; i < j; i++) {
      if (croppedImages[i].file) {
        postData[`coverimage${i + 1}_meta`] = JSON.stringify(
          croppedImages[i].pixelCrop
        );
      }
      if (croppedImages.length === i + 1) {
        const response = await this.props.updatePost({
          variables: {
            _set: {
              coverimage: upload.secure_url,
              lastpublished_at: moment().format(),
              coverimage1: images[0],
              coverimage1_meta: postData.coverimage1_meta,
              coverimage2: images[1],
              coverimage2_meta: postData.coverimage2_meta,
              coverimage3: images[2],
              coverimage3_meta: postData.coverimage3_meta,
              coverimage4: images[3],
              coverimage4_meta: postData.coverimage4_meta
            },
            where: {
              influencer_id: {
                _eq: this.props.influencerDetails.id
              },
              id: {
                _eq: this.props.blogData.id
              }
            }
          }
        });
        this.props.handleSubmit(response.data.update_post.returning[0]);
      }
    }
    return;
  };

  handleBack = () => {
    if (this.state.step !== 1) {
      this.setState({
        step: this.state.step - 1
      });
    } else {
      this.props.history.push("/influencer/setup/step3");
    }
  };

  render() {
    const {
      selectedLayout,
      coverImages,
      postData,
      step,
      requestData,
      loading,
      isFormCompleted
    } = this.state;
    return (
      <>
        <Wrapper>
          {step === 1 && (
            <>
              <h2>{getTranslation("setCoverImage")}</h2>
              <CoverImagesSelection
                images={coverImages}
                handleCoverImagesChange={this.handleCoverImagesChange}
              />
            </>
          )}
          {step === 2 && (
            <>
              <h2>{getTranslation("selectLayout")}</h2>
              <CoverSelection
                blogData={postData}
                selectedLayout={selectedLayout}
                handleLayoutChange={this.handleLayoutChange}
              />
            </>
          )}
          {step === 3 && (
            <>
              <h2>{getTranslation("setCoverImage")}</h2>
              <CoverReorder
                blogData={postData}
                requestData={requestData}
                handleCropSubmit={this.handleCropSubmit}
              />
            </>
          )}
        </Wrapper>
        <FormAction
          loading={loading}
          invalid={!isFormCompleted}
          activeStep={4}
          handleBack={this.handleBack}
          handleNext={this.handleSubmit}
        />
      </>
    );
  }
}

export default withRouter(
  compose(graphql(updatePost, { name: "updatePost" }))(CoverSelectionModal)
);
