import React from "react";
import { getTransportDetails, fancyTimeFormat, hex2rgb } from "utils/utils";

export default ({ data, color }) => {
  const transportDetails = getTransportDetails(data.transport_mode);
  const timeArray = fancyTimeFormat(data.duration_minutes * 60).split(":");
  const rgbaColor = hex2rgb(color, 0.04);
  const borderColor = hex2rgb(color, 0.32);
  return (
    <div
      className="transport"
      style={{
        background: `linear-gradient(0deg, ${rgbaColor}, ${rgbaColor}), #FFFFFF`,
        border: `1px solid ${borderColor}`
      }}
    >
      <transportDetails.icon stroke={color} />
      <span>
        {timeArray[0]}h {timeArray[1] !== "00" ? timeArray[1] + "m" : ""}
      </span>
    </div>
  );
};
