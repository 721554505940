import { getItem } from 'utils/utils';
import { UPDATE_USER_DATA } from 'redux-store/actions';

const initialProps = {
  userData: getItem('tokenDetails') ? getItem('tokenDetails') : null,
};
export default function (state = initialProps, action) {
  switch (action.type) {
    case UPDATE_USER_DATA:
      return {
        ...state,
        userData: action.payload,
      };
    default:
      return state;
  }
}
