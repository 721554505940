export default `
# Giriş
14373971  kayıt  numarasıyla  Talinn'de  (Estonya)  kayıtlı  olan  Fly  Strait  OÜ(“biz,  “bize”,  “bizim”veya  sadece  “Fly  Strait”),  kişisel  verileriniz  için  “veri  denetleyicisi”  olarak  adlandırılır,  bu  nedenle  kişisel  verilerinizle  yaptıklarımızın  yasallığından  sorumludur.

&nbsp;

Bu  web  sitesi  Fly  Strait  tarafından  yönetilmektedir.  Günlük  işlerde  kullanılan  isimler,  e-posta  adresleri  ve  diğer  seyahatle  ilgili  bilgiler  gibi  çeşitli  kişisel  verileri  işliyoruz.

&nbsp;

Bu  nedenle  veri  güvenliğini  ve  veri  koruma  mevzuatına  uygunluğu  son  derece  ciddiye  alıyoruz.  Bu  gizlilik  politikasında,  bu  web  sitesini  kullanırken  sizden  aldığımız,  kişisel  verilerinizi  nasıl  topladığımızı,  sakladığımızı,  kullandığımızı  ve  açıkladığımızı,  ayrıca  kişisel  bilgilerinizin  mahremiyetini  ve  gizliliğini  nasıl  koruduğumuzu  açıklıyoruz.  Mahremiyetiniz  bizim  için  önemlidir,  bu  nedenle  isteryeni  isterse  de  eski  kullanıcımız  olun,  lütfen  uygulamalarımızı  öğrenmek  için  zaman  ayırın  ve  herhangi  bir  sorunuz  olursa  bizimle  iletişime  geçin.

# Topladığımız  Kişisel  Veriler
Genel  olarak,  topladığımız  kişisel  veri  türü,  seyahat  planlarınızı  ve  rezervasyonlarınızıyapabilmeniz  için  ihtiyaç  duyduğumuz  bilgilerdir.  Buna  adınız  ve  soyadınız,  doğum  tarihiniz,  telefon  numaranız  ve  e-posta  adresiniz  gibi  bilgiler  dahildir.  Web  sitelerimizden  rezervasyon  yaptığınız  seyahati  size  sağlamak  için  almamız  gereken  kişisel  veriler,  sağlamak  zorunda  olduğunuz  tek  veridir.  Kullandığınız  seyahat  hizmetlerinin  türüne  bağlı  olarak,  seyahat  planlarınızla  ilgili  olan  ya  da  başka  bir  seyahat  hizmeti  sağlayıcısı  (örneğin  havayolu  şirketleri  ve  oteller)  tarafından  istenen  frequent  flyer(sık  uçuş  yapan  yolcu)  numarası,  (varsa)  yemek  talepleriniz  ve  sağlık  sorunlarınız  hakkında  bilgi  ve  diğer  ayrıntıları  da  toplayabiliriz.  Bu  kapsamlı  bir  liste  değildir.  Destek  birimimizi  ararsanız,  telefon  görüşmesi  sırasında  verdiğiniz  verileri  de  toplarız.  Aşağıda  görebileceğiniz  gibi,  çerezlerimiz  de  bazı  bilgileri  toplar.

&nbsp;

Web  sitemiz  üzerinden  başkası  için  rezervasyon  yaparsanız,  o  kişi  hakkında  da  kişisel  veriler  talep  edeceğiz.  Bu  durumda,  söz  konusu  kişilere  bu  gizlilik  politikası  hakkında  sizin  bilgi  vermenizi  bekleriz.

# Topladığımız  Hassas  Kişisel  Veriler
Bazı  durumlarda,  sizin  hakkınızda  “özel  kişisel  veri  kategorileri”adı  verilen  ve  hassas  olarak  değerlendirilebilecek  olan  bilgileri  işleyebiliriz.  Örneğin,  havayolu  şirketinden  iade  almak  için  bir  sağlı
k  sertifikası  ibraz  ettiyseniz,  (ii)  seyahatinizi  etkileyen  ve  yardım  talep  ettiğiniz  veya  izin  alınmasıgereken  bir  sağlık  sorununuz  varsa  ya  da  (iii)  sizinle  ilgili  başka  bir  hassas  bilgiyi  açıklayan  bir  talepte  bulunduysanız  böyle  bir  durum  söz  konusu  olur.

&nbsp;

Sizinle  ilgili  hassas  kişisel  verileri  işlemeden  önce  sizden  onay  alırız.  Bu  nedenle,  hassas  verileri  vermek  için  web  sitelerimizdeki  özel  iletişim  formlarını  kullanmanızı  rica  ediyoruz.  İletişim  formları,  geçerli  veri  koruma  mevzuatı  uyarınca  gereken  izni  vermenizi  sağlar.  Böyle  bir  onay  elbette  her  zaman  iptal  edilebilir.  Sizin  tarafınızdan  izin  verilmeyen  veya  bize  sunmadığınız  herhangi  bir  hassas  kişisel  veriyi  işlemeyiz.  Personelimizden  sınırlı  sayıda  kişi  hassas  kişisel  verilerinize  erişebilecek  ve  hassas  verileriniz  talebinize  göre  işlendikten  sonra  en  kısa  zamanda  silinecektir.

# Kişisel  Verilerinizle  Ne  Yapıyoruz?
Kişisel  verilerinizi  işlememize  izin  verilebilmesi  için,  yürürlükteki  veri  koruma  mevzuatına  göre  kişisel  verilerinizi  işleme  amaçlarımızdan  her  biri  için  bir  "hukuki  dayanağımızın"  olması  gereklidir.  Bu  nedenle,  amaçlarımızdan  her  birinin  hukuki  dayanağını  göstermek  için  aşağıdaki  tabloyu  hazırladık.

| Yaptığımız  İş  (Kişisel  Verilerinizi  İşleme  Amaçlarımız) | Hukuki  Dayanağımız | Saklama  Süresi |
| --- | --- | --- |
| Bizden  talep  ettiğiniz  seyahat  planlarını  ve  rezervasyonları  gerçekleştirmek  (örneğin  aracılık  ettiğimiz  seyahat  hizmetlerinin  rezervasyonuyla  birlikte  bizim  verdiğimiz  hizmetlerin  sağlanması). | Sizinle  yaptığımız  sözleşmemizi  yerine  getirmek.  Bize  hassas  kişisel  verilerinizi  sağladığınızda  hukuki  dayanak  sağlanmışolur. | Satın  alma  tarihinden  itibaren  3  yıl.  Hassas  kişisel  veriler  için  verilen  onay  istendiği  zaman  iptal  edilebilir.|
| Web  sitemizde  bir  kullanıcı  hesabıoluşturmayıseçtiyseniz  size  bu  hesabı  sağlarız.  Hesap,  önceki  seyahat  planlarınız  ve  rezervasyonlarınız  hakkında  bilgiye  erişimi  de  içerir.  Ayrıca  kullanıcıadınızı  ve  şifrenizi  de  saklarız. | Sizinle  olan  sözleşmemizi  yerine  getirmek. | Hem  kullanıcı  hesabınız  hem  de  önceki  seyahat  planlarınız  ve  rezervasyonlarınızla  ilişkili  kişisel  bilgileriniz,  web  sitemizdeki  kullanıcı  hesabınızı  iptal  etmeye  karar  verene  kadar  saklanacaktır.  Ancak,  18  ay  boyunca  kullanıcı  hesabınıza  giriş  yapmadıysanız,  saklama  için  başka  hukuki  dayanağımız  olmaması  koşuluyla  bu  tür  hesapları  ve  bilgileri  sileriz. |
| Bir  rezervasyon  işlemine  başladıysanız  ancak  satın  almayıtamamlamadıysanız,  web  sitesindeki  rezervasyon  işleminizin  hangi  noktada  kesildiğine  bağlı  olarak  size  arama  sonucunun  ya  da  başlatılan  rezervasyonun  bağlantısını  içeren  bir  e-posta  gönderebiliriz. | Meşru  menfaatimiz:  İşimizi  yürütmek  ve  tüm  bilgileri  tekrar  doldurmak  zorunda  kalmadan  satın  alma  işleminizi  tamamlamanızısağlamak.  Bu  e-postalarıistemiyorsanız,  dilediğiniz  zaman  e-posta  içinden  iptal  etmeyi  seçebilirsiniz. | Rezervasyon  işleminden  ayrıldığınız  andan  itibaren  24  saat. |
| Seyahat  planınız  başlamadan  önce  size  ekstra  bagaj  gibi  olası  ek  hizmetler  ve  seyahatiniz  için  faydalıdiğer  bilgiler  gibi,  seyahatinizle  ilgili  ek  bilgiler  ve  teklifler  sunarız.  Bilgilerden  bazıları,  rezervasyon  işleminiz  boyunca  verdiğiniz  bilgiye  göre  (örneğin  seyahat  ettiğiniz  tarihler,  varış  noktanız  vs.)  oluşturduğumuz  profile  dayanmaktadır. | Meşru  menfaatimiz:  Size  daha  rahat  bir  seyahat  sunmaya  ve  sizi  ilgilendirecek  bilgileri  daha  kolay  bulmanızısağlamak.  Bu  bilgileri  istemiyorsanız  dilediğiniz  zaman  e-posta  içinden  iptal  etmeyi  seçebilirsiniz. | Seyahatiniz  başlayana  kadar.  Aşağıda  belirtilen  amaçlara  uygun  olarak  benzer  işlemler  devam  edebilir. |
| Seyahatinizden  sonra  size  diğer  seyahat  teklifleri  ve  seyahatle  ilgili  ilginizi  çekebilecek  ürünler  ve  hizmetlere  hakkında  öneriler  içeren  bültenimizi  gönderebiliriz.  Önerilerden  bazıları,  seyahat  rezervasyonu  yaparken  önceki  seçimlerinize  dayanarak  oluşturduğumuz  profile  ve  e-postalarımıza  karşı  tutumunuza  dayanmaktadır. | Meşru  menfaatimiz:  İşimizi  yürütmeye  ve  ilginizi  çekebilecek  seyahat  planlarıyapmanızısağlamak.  Bu  e-postaları  istemiyorsanız  dilediğiniz  zaman  e-posta  içinden  iptal  etmeyi  seçebilirsiniz.  Ayrıca  bir  satın  alma  işlemini  tamamlamadan  önce  bülteni  almaya  itiraz  edebilirsiniz. | Satın  alma  tarihinden  itibaren  3  yıl. |
| Kalite  güvencesi  amacıyla  ve  ileride  sizden  gelebilecek  talepler  veya  sorular  için  telefon  görüşmelerini  kaydetmek. | Meşru  menfaatimiz:  (i)  Kurum  içi  eğitim  aracılığıyla  hizmetlerimizi  geliştirmek  ve  varsa  (ii)  olasıistek  veya  taleplerinizi  karşılamak.  Telefon  görüşmesinin  kaydedilmesini  istemiyorsanız  kayıt  başlamadan  önce  kayıt  yapılmasına  itiraz  edebilirsiniz. | Telefon  görüşmesinden  itibaren  6  ay.  Kaydınıza  yalnızca  sınırlı  sayıda  kişi  erişebilir. |
| Örneğin,  bu  web  sitesinin  kullanılabilirliğini  geliştirmek,  kişiselleştirilmiş  bir  deneyim  sunmak  ve  kullanım  istatistiklerini  toplamak  için  çerezlerin  kullanılması.  Bu  web  sitesinin  güvenliğini  arttırmak  için  oturum  çerezlerini  de  kullanıyoruz. | Meşru  menfaatimiz:  (i)  Web  sitemizi  geliştirmeye,  (ii)  ilginizi  çekebilecek  teklifleri  size  göstermeye  ve  (iii)  güvenli  bir  hizmet  ve  web  sitesi  sunmak.  Bilgisayarınızda  çerezleri  saklamamızı  istemiyorsanız  dilediğiniz  zaman  tarayıcınızdan  ayarları  değiştirebilirsiniz. | Çerez  türüne  bağlıdır.  Daha  ayrıntılı  saklama  süreleri  için  aşağıya  bakınız. |

&nbsp;

Yukarıdakilere  ek  olarak,  defter  tutmak,  muhasebe,  faturalandırmak,  kara  para  aklanmasının  önlenmesi  zorunluluklarını  yerine  getirmek  ve  web  sitemizin  güvenliğini  korumak  gibi  müşterilerimize  hizmet  verirken  işletmeler  için  gerekli  olan  günlük  önlemler  de  alırız.  Yürürlükteki  yasalar  uyarınca  bu  zorunlu  olmadığı  ölçüde,  bu  önlemleri  meşru  menfaatimize  dayanarak  üstleniriz.  Web  sitelerimizi  ve  hizmetlerimizi  genel  anlamda  geliştirmek  için  de  müşterilerimizin  davranışlarını  analiz  edebiliriz.  Ancak  bu  analizler,  toplu  düzeyde  genelleştirilmiş  veya  isimsiz  verileri  kullanır.

# Kişisel  Verilerinizin  Paylaşılması
Yalnızca  bu  gizlilik  politikasında  belirtilen  amaçlar  için  gerektiğinde  kişisel  verilerinizi  paylaşırız.  Fly  Strait  bünyesindeki  başka  şirketlerle,  hükümet  yetkilileri  ve  güvenilir  iş  ortaklarımızla  paylaşabiliriz.  Örneğin,  seyahat  planlarınızı  ve  rezervasyonlarınızı  gerçekleştirmek  için  kişisel  verilerinizi  (uygun  olduğunda  hassas  kişisel  verileriniz  dahil)  havayolu  şirketleri,  otel  tedarikçileri,  sigorta  şirketleri  ve  Küresel  Dağıtım  Kanalları  (GDS’ler)  gibi  iş  ortaklarıyla  paylaşabiliriz.

&nbsp;

Her  bir  ortak,  kişisel  verilerinizi  bizden  aldıktan  sonra  bunların  işlenmesinden  kendi  sorumludur,  yani  yürürlükteki  veri  koruma  mevzuatı  uyarınca  haklarınıza  ilişkin  talepleriniz  için  söz  konusu  ortakla  iletişim  kurmalısınız.  Kişisel  verilerinizin  işlenmesiyle  ilgili  bilgi  için  iş  ortaklarının  gizlilik  politikalarını  okumanızı  öneririz.

&nbsp;

Kişisel  verilerinizi,  çağrı  merkezlerimizi  işleten  hizmet  sağlayıcılar  ve  bize  hizmet  sunarken  (örneğin  harici  depolama)  kişisel  verilerinizi  işleyecek  olan  diğer  tedarikçiler  gibi  diğer  şirketlerle  de  ("veri  işleyiciler")  kişisel  verilerinizi  paylaşırız.

&nbsp;

Seyahat  sektörünün  küresel  yapısı  nedeniyle,  kişisel  verilerinizi  paylaştığımız  taraflar  AB/AEA  dışında  bir  ülkede  ikamet  ediyorsa  kişisel  verileriniz  dünyanın  farklı  yerlerinde  işlenebilir.  Kişisel  verilerinizi  AB/AEA  dışında  paylaşmamız  için,  yürürlükteki  veri  koruma  mevzuatı  uyarınca  bazıhukuki  dayanaklar  olması  gereklidir.  Bir  ülke,  Avrupa  Komisyonu  tarafından  yeterli  kişisel  veri  koruma  seviyesine  sahip  olarak  değerlendiriliyorsa,  hukuki  dayanağımız  bu  olacaktır.  Bunun  dışında,  böyle  bir  paylaşımı  dayandırabileceğimiz  üç  tür  hukuki  dayanak  bulunmaktadır.  Bunlar:

&nbsp;

i. aktarımın  sizinle  olan  sözleşmemizi  yerine  getirmek  için  gerekli  olması  (örneğin  AB/AEA  dışındaki  bir  ülkede  genel  merkezi  bulunan  bir  havayolu  şirketinden  uçak  rezervasyonu  yaptıysanız);

&nbsp;

ii. aktarımın Avrupa  Komisyonu  kabul  edilen  benimsenen  kişisel  verilerin  AB/AEA  dışına  aktarılmasına  ilişkin  standart  veri  koruma  maddelerine  dayanması  (bu  standart  veri  koruma  maddelerinin  bir  kopyasışu  adresten  bulunabilir: http://ec.europa.eu/justice/data-protection/international-transfers/transfer/);  ve

&nbsp;

iii. aktarımın ABD’ye  yapıldığı  ve  alıcının  onaylı  olduğu  AB-ABD  Gizlilik  Kalkanı.

# Üçüncü  Taraf  Hizmet  Sağlayıcılar
Web  sitemizde  başka  web  sitelerine  bağlantılar  bulunduğun  ve  üçüncü  taraf  hizmet  sağlayıcılarından  içerikler  sunulduğunu  lütfen  unutmayın.  Bu  gizlilik  politikası,  yalnızca  bizim  web  sitemiz  ve  hizmetlerimiz  için  geçerlidir.  Başka  web  sitelerinin  bağlantılarını  takip  ettiğinizde  ya  da  üçüncü  taraf  hizmetler  veya  ürünler  kullandığınızda  onların  gizlilik  politikalarını  okumalısınız.  Ayrıca  bizimle  sosyal  medya  aracılığıyla  iletişim  kurmayı  tercih  ederseniz,  bu  gizlilik  politikası  böyle  bir  iletişim  kapsamında  verdiğiniz  kişisel  veriler  için  geçerli  değildir,  bu  durumda  sosyal  medya  sağlayıcısının  gizlilik  politikasını  okumanızı  tavsiye  ederiz.

# Haklarınız
Yürürlükteki  veri  koruma  mevzuatına  göre,  "veri  sahibi"  olarak  bazı  haklara  sahipsiniz.  Aşağıda  haklarınızı  sıraladık.  Haklarınız  şunları  içermektedir:

i. Erişim  hakkı‐İşlediğimiz  kişisel  verilere  erişme  hakkına  sahipsiniz.  Kişisel  verilerle  ne  yaptığımıza  ilişkin  belirli  bilgileri  alma  hakkına  da  sahipsiniz.  Bu  bilgiler  bu  belgede  sağlanmaktadır.

&nbsp;

ii. Düzeltme  hakkı‐  Belirli  koşullar  altında,  sizinle  ilgili  yanlış  kişisel  verileri  düzeltme  ve  eksik  kişisel  verileri  tamamlatma  hakkına  sahipsiniz.  Havayolu  kuralları  gibi  nedenlerle  sizin
verdiğiniz  yanlış  kişisel  verileri  düzeltemeyebileceğimizi  ve  söz  konusu  değişiklikler  için  ücret  talep  edilebileceğini  lütfen  unutmayın.

&nbsp;

iii. Silme  hakkı‐  Belirli  koşullar  altında,  kişisel  verilerinizi  sildirme  hakkına  sahipsiniz.  Bu  "unutulma  hakkı"  olarak  adlandırılır.

&nbsp;

iv. İşlemenin  sınırlandırılması  hakkı‐  Belirli  koşullar  altında,  kişisel  verilerinizi  işleme  şeklimizi  kısıtlama  hakkına  sahipsiniz.

&nbsp;

v. Veri  taşınabilirliği  hakkı‐  Bizden  yapılandırılmış  kişisel  verilerinizi  (ya  da  doğrudan  başka  bir  veri  kontrolörüne  aktarılmışkişisel  verilerinizi),  yaygın  olarak  kullanılan  ve  bilgisayarla  okunabilen  bir  formatta  alma  hakkına  sahipsiniz.

&nbsp;

vi. İtiraz  hakkı‐  Yaptığımız  bazıkişisel  veri  işleme  türlerine  itiraz  etme  hakkına  sahipsiniz.  Bu,  "meşru  menfaatimize"  dayanan  tüm  faaliyetlerimiz  için  geçerlidir.

vii. Sonn  olarak,  ilgili  veri  koruma  denetleme  kurumuna  şikâyette  bulunma  hakkına  da  sahipsiniz.

# ÇEREZLER
Çerez, bazıları yalnızca tarayıcınızı kapatana kadar ("oturum çerezleri") ve bazıları da daha uzun süre ("kalıcı çerezler") bilgisayarınızda saklanan küçük bir metin dosyasıdır. Çerezlerin bilgisayarınızda saklanmasına izin vermek istemiyorsanız tarayıcınızdaki ayarları değiştirebilirsiniz. Ancak bazı durumlarda web sitemizin bazı özelliklerinin düzgün çalışmayabileceğini ve bu nedenle bazı içeriklerin doğru görüntülenmeyebileceğini unutmayın.

&nbsp;

Bu web sitesi, kişiselleştirilmiş bir deneyim sunmak, web sitesinin kullanılabilirliğini geliştirmek ve kullanım istatistikleri toplamak gibi çeşitli nedenlerle çerezler kullanmaktadır. Bu web sitesinin güvenliğini arttırmak için oturum çerezlerini de kullanıyoruz.

&nbsp;

Bazı durumlarda çerezleri kullanırken verilerinizi üçüncü taraflarla paylaşırız. Örneğin, web sitesi trafik verilerini Google sunucularına aktaran hizmetler olan Google Analytics ve Google AdWords’ü kullanıyoruz. Google Analytics kullanıcıların kimliğini belirlemez ve IP adresinizi Google’ın elinde bulunan başka verilerle ilişkilendirmez. Google’ın sağladığı raporları, web sitesi trafiğini ve web sayfası kullanımını anlamamıza ve Google’ın kendinden ve diğer reklam ağlarından satın alınan reklamları optimize etmemize yardımcı olması için kullanıyoruz. Google, verileri Google's Privacy Policy’nda tanımlanan şekilde ve bu bölümde yukarıda belirtilen amaçlarla işleyebilir. Çerezi devre dışı bırakırsanız ya da reddederseniz, JavaScript’i devre dışı bırakırsanız ya da Google’ın sağladığı hizmetten çıkarsanız Google Analytics’ten çıkabilirsiniz. Google’dan gelen reklam özelliklerinden çıkmak için bu bağlantıyı kullanın.

&nbsp;

Web sitemiz ayrıca Facebook’un farklı platformlarından (Instagram dahil) yapılan reklam alışverişlerinin optimizasyonuna yardımcı olan anonim birleştirilmiş veri toplayan Facebook pixel’i de kullanmaktadır. Facebook, bir kullanıcı Facebook pixel ile bir siteyi ziyaret ettiğinde eşleştirmelerine olanak tanıyan bir kullanıcı kimliği toplar. Ancak biz reklam verenler olarak hiçbir zaman belirli bir kullanıcının davranışını ayırt edemeyiz. Facebook ve ilgili platformları, kullanıcılarının reklam verenlerin Facebook’taki reklamlardan alışveriş yapmak için topladığı verileri kullanmasına onay verip vermediklerini düzenleyebilecekleri kapalı bir reklam ekosisteminde yer almaktadır. Facebook’taki reklam ayarlarınızı görüntülemek ve değiştirmek için bu bağlantıyı kullanın.

&nbsp;

Ayrıca web sitemiz Microsoft Bing, TripAdvisor ve TvSquared’dan dönüşüm izleme komut dosyalarını ve çerezleri kullanıyor (gizlilik politikalarını bağlantıları takip ederek görebilirsiniz).

&nbsp;

Tüm bu hizmetler, reklam alımlarını optimize etmemize yardımcı olan toplu istatistikî verileri toplar. Reklam verenler olarak biz bu verileri kullanarak tek bir kullanıcıyı tanımlayamayız. Tarayıcı ayarlarınızda çerez kullanımını açabilirsiniz.

&nbsp;

Şeffaf olmak için, bu web sitesinde kullanılan tanımlama bilgilerini burada özetledik.

Çerezler hakkında daha fazla bilgi için www.youronlinechoices.com adresini ziyaret edin.

# Veri Güvenliği
Kişisel verilerinizi güvende tutmak için bir dizi teknik ve organizasyonel güvenlik önlemi aldık. Örneğin, tüm sistemlerde üst düzey teknik güvenlik uyguluyoruz (takip edilebilirlik, acil kurtarma, erişim kısıtlamaları vs.). Ayrıca (kendileri elbette gizlilik yükümlülüklerine tabi olan) çalışanlarımızın gerekli olmadıkça kişisel verileri kullanmamasını sağlayan politikalar benimsedik. Bu politikalar, tedarikçilerle sözleşme yaptığımızda veya operasyonlarımıza yeni BT sistemleri getirdiğimizde standartlarımızı da belirlemektedir.

# İletişim Bilgileri
Kişisel verilerinizi işlememiz ya da çerez kullanımımızla ilgili sorularınız varsa ya da yürürlükteki veri koruma mevzuatı uyarınca haklarınızdan herhangi birini kullanmak istiyorsanız lütfen privacy@flystrait.com adresine e-posta gönderin.

# Gizlilik Politikasındaki Değişiklikler
Kişisel verilerinizi işleme ya da çerezleri kullanma şeklimizi değiştirirsek bu gizlilik politikasını derhal güncelleyeceğiz ve bu web sitesinde yayınlayacağız.
`;
