import {
  SET_FILTERS,
  ADD_FILTER,
  DELETE_FILTER,
} from 'redux-store/actions';

const initialProps = {
  filters: [],
};
export default function (state = initialProps, action) {
  switch (action.type) {
    case SET_FILTERS:
      return {
        ...state,
        filters: [...action.data],
      };
    case ADD_FILTER:
      return {
        ...state,
        filters: [...state.filters, action.data],
      };
    case DELETE_FILTER:
      return {
        ...state,
        filters: state.filters.filter(item => item.id !== action.data),
      };
    default:
      return state;
  }
}
