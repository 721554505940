import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';

export default class extends React.PureComponent {
  render() {
    const { open, handleClose, message } = this.props;
    return (
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        onClose={handleClose}
        autoHideDuration={4000}
        message={<span id="message-id">{message}</span>}
      />
    );
  }
}
