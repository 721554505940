import React from "react";
import styled from "styled-components";
import { withApollo } from "react-apollo";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getPosts } from "containers/Influencer/queries";
import { ItemTitle, CreateNew } from "components/Influencer";
import { Loader, Button } from "components/common";
import { Sections, FilterSection } from "containers/Influencer/Setup/Styles";
import { BlogPostCard } from "components/Influencer/BlogCards";
import {
  QuickPostIcon,
  BlogPostIcon,
  ItineraryPostIcon
} from "components/common/SvgIcons";
import config from "config";
import getTranslation from "utils/getTranslation";
import { getTipDomain } from "utils/utils";
import SortDownIcon from "assets/icons/Sorting-Down.svg";
import SortUpIcon from "assets/icons/Sorting-Up.svg";

const Wrapper = styled.div`
  width: 100%;
  padding-top: 20px;
  .domainWrapper {
    width: fit-content;
    padding: 0 10px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #d0f3fc;
    border-radius: 10em;
    font-size: 14px;
    @media (max-width: 800px) {
      width: 100%;
      height: 48px;
    }
    a {
      margin-left: 5px;
      color: #007ac7;
      text-decoration: none;
    }
  }
  .section {
    margin-top: 30px;
    width: 100%;
    .title {
      margin-bottom: 0;
    }
    .postsWrapper {
      display: flex;
      margin-top: 30px;
      .posts {
        display: flex;
        flex-wrap: wrap;
        .post {
          margin-right: 24px;
          text-decoration: none;
          &:nth-child(3n + 0) {
            margin-right: 0;
          }
          @media (max-width: 800px) {
            width: 100%;
            margin-right: 0;
          }
        }
      }
    }
  }
`;

class Posts extends React.PureComponent {
  state = {
    posts: [],
    loading: true,
    filteredPosts: [],
    filter: "all",
    sortOrder: 1
  };
  async componentWillMount() {
    try {
      const posts = await this.props.client.query({
        query: getPosts,
        fetchPolicy: "no-cache",
        variables: {
          where: {
            influencer_id: { _eq: this.props.influencerDetails.id },
            lastpublished_at: { _is_null: false }
          }
        }
      });

      this.setState({
        posts: posts.data.post,
        filteredPosts: this.changeSorting(posts.data.post,this.state.sortOrder),
        loading: false
      });
    } catch (err) {}
  }

  handleFilter = e => {
    const { id } = e.target;
    console.log(id);
    if (id !== "all") {
      const filteredPosts = this.state.posts.filter(item => item.type === id);
      this.setState({ filteredPosts, filter: id });
    } else {
      this.setState({
        filteredPosts: this.state.posts,
        filter: id
      });
    }
  };


  changeSorting = (posts,order) => {
    if(!(posts && posts.length > 0)) {
      return [];
    }
    let filteredPosts = [...posts];
    filteredPosts.sort((a,b) => (a.created_at > b.created_at) ? -1*order : ((b.created_at > a.created_at) ? 1*order : 0));
    return filteredPosts;
  }

  getReversedSortOrder = () => {
    return this.state.sortOrder == 1 ? -1 : 1;
  }

  changeSortOrder = e => {
    let order = this.getReversedSortOrder();
    this.setState({
      sortOrder: order,
      filteredPosts: this.changeSorting(this.state.filteredPosts,order)
    })
  }

  render() {
    const { filteredPosts, loading, filter, sortOrder } = this.state;
    const { influencerDetails } = this.props;
    return (
      <Wrapper>
        <div className="domainWrapper">
          View on{" "}
          <a
            href={"https://" + getTipDomain(influencerDetails)}
            target="_blank"
          >
            {getTipDomain(influencerDetails)}
          </a>
        </div>
        {isMobile && (
          <>
            <Link to="/influencer/dashboard/posts/create">
              <Button style={{ marginTop: 20 }}>
                {getTranslation("createNewPost")}
              </Button>
            </Link>
          </>
        )}
        <Sections>
          <div className="section">
            <ItemTitle title={getTranslation("publishedPosts")} />
            <FilterSection>
              <div className="tabs">
                <div
                  className={`tab ${filter === "all" ? "active" : ""}`}
                  id="all"
                  onClick={this.handleFilter}
                >
                  {getTranslation("all")}
                </div>
                <div
                  className={`tab ${filter === "QUICK" ? "active" : ""}`}
                  id="QUICK"
                  onClick={this.handleFilter}
                >
                  <QuickPostIcon id="QUICK"/>
                  {getTranslation("quickPost")}
                </div>
                <div
                  className={`tab ${filter === "BLOG" ? "active" : ""}`}
                  id="BLOG"
                  onClick={this.handleFilter}
                >
                  <BlogPostIcon id="BLOG"/>
                  {getTranslation("blog")}
                </div>
                <div
                  className={`tab ${filter === "ITINERARY" ? "active" : ""}`}
                  id="ITINERARY"
                  onClick={this.handleFilter}
                >
                  <ItineraryPostIcon id="ITINERARY"/>
                  {getTranslation("itinerary")}
                </div>
              </div>
              <div className="sorting">
                <div>
                  Sort By:{" "}
                  <div onClick={this.changeSortOrder}>
                    <div className="sortItem active">
                      {getTranslation("datePublished")}
                    </div>
                    {sortOrder == 1 ? <img src={SortDownIcon} alt="" /> : <img src={SortUpIcon} alt="" />}
                  </div>
                </div>
              </div>
            </FilterSection>
            <div className="postsWrapper">
              {loading ? (
                <Loader color={influencerDetails.primary_colour} />
              ) : (
                <>
                  <div className="posts">
                    {!isMobile && (
                      <Link to="/influencer/dashboard/posts/create">
                        <CreateNew
                          className="post"
                          text={getTranslation("createNewPost")}
                        />
                      </Link>
                    )}
                    {filteredPosts.map(post => (
                      <div className="post" key={post.id}>
                        <a
                          href={`https://${influencerDetails.tip_sub_domain}.${config.tipFrontUrl}/post/${post.id}`}
                          target="_blank"
                        >
                          <BlogPostCard
                            preview
                            canEdit
                            color={influencerDetails.primary_colour}
                            blogData={post}
                            layout={post.coverimage_layout}
                            fullWidth={isMobile}
                          />
                          {/* {post.type === "ITINERARY" && (
                            <ItineraryPostCard blogData={post} />
                          )} */}
                        </a>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </Sections>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  influencerDetails: state.influencer.influencerDetails
});

export default withApollo(connect(mapStateToProps)(Posts));
