import React from 'react';
import { getHotelDests } from 'utils/api';
import { getItem, setItem } from 'utils/utils';

export default class extends React.Component {
    async componentDidMount() {
        if (!getItem('allHotelDests')) {
            const hotelDests = await getHotelDests();
            setItem('allHotelDests', hotelDests);
            this.props.handleComplete();
        } else {
            this.props.handleComplete();
        }
    }
    render() {
        return null;
    }
}
