import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import Modal from 'components/common/Modal';
import { Button } from 'components/common';
import getTranslation from 'utils/getTranslation';

const Wrapper = styled.div`
  text-align: left;
  color: #fff;
  font-size: 12px;
  background-color: rgba(235, 87, 87, 0.8);
  padding: 50px 30px;
  @media (max-width: 800px) {
    height: 100vh;
  }
  h4 {
    margin-bottom: 10px;
    font-size: 24px;
    color: #fff;
    line-height: 1.1;
  }
  .header {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    margin-bottom: 20px;
    span {
      font-size: 24px;
      margin-top: 20px;
    }
  }
  .text {
    color: #222222;
    font-size: 16px;
    line-height: 19px;
  }
`;

export default class extends PureComponent {
  handleClose = () => {
    // this.props.handleCloseModal();
  };

  render() {
    const {
      open,
      errorMsg,
      handleAccept,
    } = this.props;
    return (
      <Modal
        open={open}
        disableFullScreen={!isMobile}
        handleCloseModal={this.handleClose}
        dialogStyle={{
          width: isMobile ? "100%" : 375,
          maxWidth: isMobile ? "100%" : 375
        }}
      >
        <Wrapper>
          <div className="header">
            <img src={require("assets/icons/warning.svg")} alt="" />
          </div>
          <div
            className="text"
            style={{ letterSpacing: "0.05em", marginBottom: 20 }}
          >
            <h4>{getTranslation("paymentError")}</h4>
            <p>{errorMsg}</p>
          </div>
          <Button
            color="#ffffff"
            style={{ color: "#EB5757" }}
            onClick={handleAccept}
          >
            {getTranslation("ok")}
          </Button>
        </Wrapper>
      </Modal>
    );
  }
}
