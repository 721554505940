import React, { PureComponent } from "react";
import styled from "styled-components";
import moment from "moment";
import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { ItemTitle } from "components/Influencer";
import Modal from "components/Influencer/TipModal";
import { Input } from "components/common";
import TransportSelection from "./TransportSelection";
import getTranslation from "utils/getTranslation";
import { fancyTimeFormat } from "utils/utils";

const Wrapper = styled.div`
  width: 664px;
  background-color: #fff;
  padding: 24px 24px 0 24px;
  @media (max-width: 800px) {
    width: 100%;
  }
  .inputsWrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    .title {
      border-bottom: 0;
      margin-bottom: 0;
      line-height: 0.3;
    }
    .MuiInputBase-root {
      background-color: #d0f3fc;
      box-shadow: inset 0px -1px 0px #007ac7 !important;
    }
    .MuiFilledInput-underline:before {
      border-bottom: 0;
    }
    &.withIcon {
      .MuiInputLabel-filled {
        transform: translate(44px, 29px) scale(1);
        &.MuiFormLabel-filled,
        &.Mui-focused {
          transform: translate(44px, 10px) scale(0.75);
        }
      }
    }
    &.hasError {
      .MuiInputBase-root {
        background-color: #fbdddd;
        box-shadow: inset 0px -1px 0px #eb5757 !important;
      }
      label {
        color: #eb5757;
      }
      .MuiFilledInput-underline:after {
        border-bottom-color: #eb5757;
      }
    }
  }
  .textareaWrapper {
    position: relative;
    margin: 30px 0px;
    .title {
      border-bottom: 0;
    }
    span {
      position: absolute;
      right: 16px;
      bottom: 16px;
      font-size: 12px;
      color: #777777;
    }
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  background: #ffffff;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  resize: none;
  padding: 16px;
  outline: none;
  font-size: 14px;
  &.active {
    border: 2px solid #56ccf2;
    box-shadow: 0px 0px 6px rgba(86, 204, 242, 0.9);
  }
`;

export default class extends PureComponent {
  constructor(props) {
    super(props);
    const { transportData } = props;
    let state = {};
    if (transportData) {
      const {
        transport_notes,
        transport_mode,
        origin,
        destination,
        duration_minutes
      } = transportData;
      const timeArray = fancyTimeFormat(duration_minutes * 60).split(":");
      state = {
        wordLimit: transport_notes.length,
        transport_notes,
        transport_mode,
        origin,
        destination,
        formCompleted: true,
        time: moment(
          `01-01-2001 ${timeArray[0]}:${timeArray[1]}`,
          "MM-DD-YYY HH:mm"
        )
      };
    } else {
      state = {
        wordLimit: 120,
        time: moment("01-01-2001 00:00", "MM-DD-YYY HH:mm")
      };
    }
    this.state = state;
  }

  componentDidUpdate() {
    const {
      time,
      origin,
      destination,
      transport_notes,
      transport_mode,
      formCompleted
    } = this.state;
    const isComplete =
      time && origin && destination && transport_mode && transport_notes;
    if (isComplete !== formCompleted) {
      this.setState({
        formCompleted: isComplete
      });
    }
  }

  handleChange = e => {
    const { name, value } = e.target;
    const wordLimit = 120;
    let limit;
    if (name === "transport_notes") {
      if (value.length > wordLimit) {
        return;
      } else {
        limit = wordLimit - value.length;
      }
    }
    this.setState({
      [name]: value,
      wordLimit: name === "transport_notes" ? limit : this.state.wordLimit
    });
  };

  handleTimeChange = time => {
    this.setState({
      time
    });
  };

  handleTransportSelect = transport_mode => {
    this.setState({ transport_mode });
  };

  handleSubmit = () => {
    const {
      origin,
      destination,
      transport_notes,
      time,
      transport_mode
    } = this.state;
    const timeToHrsMins = moment(time).format("HH:mm");
    const hrsMinsArray = timeToHrsMins.split(":");
    const hrsToMins = parseInt(hrsMinsArray[0], 10) * 60;
    const duration_minutes =
      parseInt(hrsToMins, 10) + parseInt(hrsMinsArray[1], 10);
    const payload = {
      origin,
      destination,
      transport_notes,
      duration_minutes,
      transport_mode
    };
    this.props.handleSubmit(payload, !!this.props.transportData);
  };

  render() {
    const {
      wordLimit,
      transport_notes,
      time,
      destination,
      origin,
      transport_mode,
      formCompleted
    } = this.state;
    const { open, handleCloseModal } = this.props;
    return (
      <Modal
        open={open}
        text1={getTranslation("addTravel")}
        submitText={getTranslation("addToItinerary")}
        handleCloseModal={handleCloseModal}
        handleSubmit={this.handleSubmit}
        dialogStyle={{ width: 664, maxWidth: 664 }}
        loading={!formCompleted}
      >
        <Wrapper>
          <TransportSelection
            transportMode={transport_mode}
            handleTransportSelect={this.handleTransportSelect}
          />
          <div className="inputsWrapper">
            <div className="input">
              <ItemTitle title={getTranslation("from")} />
              <Input
                label={getTranslation("station")}
                value={origin}
                name="origin"
                onChange={this.handleChange}
              />
            </div>
            <div className="input">
              <ItemTitle title={getTranslation("to")} />
              <Input
                label={getTranslation("station")}
                name="destination"
                value={destination}
                onChange={this.handleChange}
              />
            </div>
            <div className="input">
              <ItemTitle title={getTranslation("duration")} />
              <div style={{ marginTop: 16 }}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <TimePicker
                    clearable
                    ampm={false}
                    label="hh:mm"
                    value={time}
                    inputVariant="filled"
                    onChange={this.handleTimeChange}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>
          </div>
          <div className="textareaWrapper">
            <ItemTitle title={getTranslation("addNotes")} />
            <TextArea
              rows="10"
              name="transport_notes"
              value={transport_notes}
              onChange={this.handleChange}
            />
            <span>{wordLimit} left</span>
          </div>
        </Wrapper>
      </Modal>
    );
  }
}
