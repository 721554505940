import React from "react";
import { getParameterByName, getItem, setItem } from "utils/utils";

export default class extends React.PureComponent {
  constructor(props) {
    super(props);
    const oauth_token = getParameterByName("oauth_token");
    const oauth_verifier = getParameterByName("oauth_verifier");
    const route = getItem("twitterRoute");
    setItem("twitter_oauth_token", oauth_token);
    setItem("twitter_oauth_verifier", oauth_verifier);
    if (route === "step") {
      this.props.history.replace("influencer/setup/step2");
    }
    if (route === "dashboard") {
      this.props.history.replace("influencer/dashboard/connect");
    }
  }
  render() {
    return <h1>Twitter success</h1>;
  }
}
