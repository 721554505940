import React from 'react';

export default class extends React.Component {
  state = {
    firstError: false,
  };
  handleImgError = (e) => {
    if (!this.state.firstError) {
      e.target.src = `/static/airlines/${this.props.airlineIc}.png`;
    } else {
      e.target.src = require('assets/missing_image.svg');
      e.target.style.filter = 'grayscale(100%)';
    }
    this.setState({ firstError: true });
  }

  handleFilterModal = () => {
    if (this.props.handleFilterModal) {
      this.props.handleFilterModal('airline');
    }
  }
  render() {
    const { airlineIc, style = {}, handleFilterModal } = this.props;
    return (
      <img
        style={{ ...style, cursor: handleFilterModal ? 'pointer' : 'default' }}
        src={`/static/airlines/${airlineIc}.svg`}
        onError={this.handleImgError}
        alt=""
        onClick={this.handleFilterModal}

      />
    );
  }
}
