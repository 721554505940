import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { uniqBy } from "lodash";
import { setConnectPosts } from "redux-store/actions";
import { Wrapper } from "containers/Influencer/Setup/Styles";
import RecurrenceCard from "./RecurrenceCard";
import getTranslation from "utils/getTranslation";

const Container = styled.div`
  p {
    font-size: 14px;
    color: #777777;
  }
  .recurrences {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

class Recurrences extends React.PureComponent {
  state = {
    recurrences: [],
    loading: true
  };
  handleRemoved = recurrenceId => {
    const { connectPosts } = this.props;
    const filtered = connectPosts.filter(
      post =>
        post.recurpattern_id !== recurrenceId && post.lastshared_at === null
    );
    this.props.setConnectPosts(filtered);
  };
  render() {
    const { connectPosts } = this.props;
    const posts = uniqBy(connectPosts, "recurpattern_id");
    const filteredPosts = posts.filter(item => item.recurpattern_id !== null);
    return (
      <Container>
        <Wrapper>
          {filteredPosts.length === 0 && (
            <>
              <h2>{getTranslation("noRecurrence")}</h2>
              <p>{getTranslation("createRecurrenceInfo")}</p>
            </>
          )}
          <div className="recurrences">
            {filteredPosts.map(post => (
              <RecurrenceCard
                key={post.id}
                connectPost={post}
                handleRemoved={this.handleRemoved}
              />
            ))}
          </div>
        </Wrapper>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  influencerDetails: state.influencer.influencerDetails,
  connectPosts: state.influencer.connectPosts
});

export default withApollo(
  connect(mapStateToProps, { setConnectPosts })(Recurrences)
);
