import React from "react";
import styled from "styled-components";
import {
  BackBigIcon,
  DurationIcon,
  EditIcon
} from "components/common/SvgIcons";
import { Button } from "components/common";
import { getTransportDetails, fancyTimeFormat } from "utils/utils";
import deleteIcon from "assets/tip/Delete.svg";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  .details {
    display: flex;
    flex-flow: column;
    span {
      color: #222222;
      font-size: 16px;
    }
    .transport {
      display: flex;
      background-color: rgba(63, 140, 82, 0.1);
      padding: 8px 30px 8px 10px;
      border-top-right-radius: 10em;
      border-bottom-right-radius: 10em;
      color: #3f8c52;
      margin-bottom: 10px;
      .meta {
        margin-left: 15px;
        display: flex;
        flex-flow: column;
        font-size: 16px;
        .duration {
          display: flex;
          align-items: center;
          span {
            font-size: 14px;
            color: #444444;
            margin-left: 8px;
          }
        }
      }
    }
  }
  .note {
    width: 536px;
    color: #777777;
    font-size: 15px;
    line-height: 140%;
    font-style: italic;
  }
  .btns {
    width: 56px;
    button {
      height: 32px;
      &:first-child {
        margin-bottom: 12px;
      }
    }
  }
`;

export default class extends React.PureComponent {
  handleEdit = () => {
    this.props.handleEdit(this.props.data, this.props.index);
  };
  handleRemove = () => {
    this.props.handleRemoveTransport(this.props.index);
  };
  render() {
    const { data, noEdit } = this.props;
    const transportDetails = getTransportDetails(data.transport_mode);
    const timeArray = fancyTimeFormat(data.duration_minutes * 60).split(":");
    return (
      <Wrapper>
        <div className="details">
          <div className="transport">
            <transportDetails.icon stroke="#3F8C52" />
            <div className="meta">
              <div>{data.transport_mode}</div>
              <div className="duration">
                <DurationIcon style={{ height: 12 }} stroke="#3F8C52" />
                <span>
                  {timeArray[0]}h {timeArray[1]}m
                </span>
              </div>
            </div>
          </div>
          <span style={{ marginLeft: 10 }}>{data.origin}</span>
          <BackBigIcon
            style={{ height: 8, width: 8, transform: "rotate(-90deg)" }}
            stroke="#3F8C52"
          />
          <span style={{ marginLeft: 10 }}>{data.destination}</span>
        </div>
        <div
          className="note"
          style={
            noEdit
              ? { borderTop: "1px solid rgba(0, 0, 0, 0.16)", paddingTop: 10 }
              : {}
          }
        >
          {data.transport_notes}
        </div>
        {!noEdit && (
          <div className="btns">
            <Button color="#D0F3FC" onClick={this.handleEdit}>
              <EditIcon stroke="#007AC7" />
            </Button>
            <Button color="#F0F0F0" onClick={this.handleRemove}>
              <img src={deleteIcon} alt="" />
            </Button>
          </div>
        )}
      </Wrapper>
    );
  }
}
