import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";
import { graphql, compose, ApolloProvider, withApollo } from "react-apollo";
import hotelClient from "hotelClient";
import { isMobile } from "react-device-detect";
import { ApolloConsumer } from "react-apollo";
import { connect } from "react-redux";
import ContinueBooking from "./childs/ContinueBooking";
import PassengerForm from "./passengersFormComponent";
import HotelResults from "../Hotel/HotelResults";
import AddedPassengers from "./AddedPassengers";
import PaymentGateway from "./PaymentGateway";
import {
  Toast,
  WarningModal,
  BaggageDiffModal,
  ExtraCheckErrorModal
} from "components/common";
import { togglePayment, handleSetupBookingComplete } from "redux-store/actions";
import { getItem, addDecimal } from "utils/utils";
import { updAdpasiQuery } from "./PaymentQuery";
import { setupBookingRequestGql } from "../Hotel/HotelQuery";

import "./Payment.css";
import passengers from "../../redux-store/reducers/passengers";
import PaypalGateway from "./PaypalGateway";

const Overlay = styled.div`
  position: absolute;
  z-index: 99;
  width: 100%;
  min-height: -webkit-fill-available;
  left: 0;
  bottom: 0;
  top: ${props => (props.onSearch ? "0" : "-50px")};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
`;

class SetupHotelBooking extends Component {
  constructor(props) {
    super(props);
  };
  async componentDidMount() {
    console.log("it's here in SetupHotelBooking compDidMount");
    // handleSetupHotelBooking = async () => {
    let { selectedHotels, hopRequests } = this.props;
    let bookables = selectedHotels.filter(sh => !sh.skipped && sh.finalised);
    await bookables.map(async (bookable, i) => {
      // within bookable, we have occupancies.adults/children/paxes
      // within passengers, we have lastname, firstname and birthdate
      // FIXME currently we only calculate CH and AD using birthdate and current date
      // console.log("pass " + JSON.stringify(hopRequests));
      let children = hopRequests[0].passengers.filter(p => moment().diff(p.birthdate, 'years') < 18);
      let adults = hopRequests[0].passengers.filter(p => moment().diff(p.birthdate, 'years') >= 18);
      // console.log("adults " + JSON.stringify(adults) + " children " + JSON.stringify(children));
      // console.log('bookable ' + JSON.stringify(bookable));
      let ratekeyPaxes = bookable.occupancies.map((occ, i) => {
        let inputpaxes = [];
        inputpaxes.push([...Array(occ.adults).keys()].map(j => {
          console.log('j is ' + j + " i is " + i);
          return { type: "AD", name: adults[(i * occ.adults) + j].firstName, surname: adults[(i * occ.adults) + j].lastName }
        }));
        inputpaxes.push([...Array(occ.children).keys()].map(j => {
          return { type: "CH", name: children[(i * occ.children) + j].firstName, surname: adults[(i * occ.children) + j].lastName }
        }));
        inputpaxes = inputpaxes.flat();
        return { rateKey: bookable.rateKeys[i], paxes: inputpaxes };
      }
      );
      let holderPax = { name: ratekeyPaxes[0].paxes[0].name, surname: ratekeyPaxes[0].paxes[0].surname };
      console.log("ratekeyPaxes " + JSON.stringify(ratekeyPaxes));
      // console.log("this props " + JSON.stringify(this.props));
      // console.log("this client " + JSON.stringify(this.props.client));
      const setupBookingReq = await this.props.client.mutate({
        mutation: setupBookingRequestGql,
        variables: {
          bookingInput: {
            availabilityKey: bookable.availabilityKey,
            tripId: getItem("tripId"),
            destinationCode: bookable.hotelDestinationCode,
            selectedHotelCode: bookable.hotelCode,
            holder: holderPax,
            rooms: ratekeyPaxes
          }
        }
      });
      console.log('gqlout ' + JSON.stringify(setupBookingReq.data.setupBookingRequest));
      let { __typename, ...booking } = setupBookingReq.data.setupBookingRequest;
      this.props.handleSetupBookingComplete(booking);
      if (i == (bookables.length - 1)) {
        this.props.handleFinishHotelBookingSetup();
      }
    });
  }
  render() {
    return null;
  }
};


class PaymentComponent extends Component {
  constructor(props) {
    super(props);
    const totalCost = this.getTotalCost(props.costItems);
    this.state = {
      realCost: totalCost || "00.00",
      paymentError: false,
      errorMessage: "",
      loadingMessage: "Connecting...",
      paymentLoading: false,
      isFormCompleted: false,
      card: {
        cardNumber: getItem("cardNumber") || "",
        cvvNumber: getItem("cvvNumber") || "",
        expDate: getItem("expDate") ? moment(getItem("expDate")).toDate() : ""
      },
      openWarning: false,
      opneExtraCheckWarning: false,
      openBaggageWarning: false,
      hotelsFinalised: false,
      finishedHotelBookingSetup: false
    };
  }

  componentWillReceiveProps({ costItems }) {
    const sumStr = this.getTotalCost(costItems);
    this.setState({ realCost: sumStr });
  }

  /* componentDidUpdate() {
    const { card } = this.state;
    const isFormCompleted = card.cardNumber && card.cvvNumber && card.expDate;
    if (this.state.isFormCompleted !== isFormCompleted)
      this.setState({ isFormCompleted });
  } */

  getTotalCost = costItems => {
    let sumStr = "";
    if (Array.isArray(costItems) && costItems.length > 0) {
      const sum = costItems.reduce(
        (accumulator, currentVal) => accumulator + currentVal.cost,
        0
      );
      sumStr = addDecimal(sum, costItems[0].costDecimalPlaces);
      if (sum < 10) sumStr = `0${sum.toFixed(2).toString()}`;
      return sumStr;
    }
    return sumStr;
  };

  handlePassengersDone = () => {
    // this.setState({ doFocus: true });
  };

  handleDisabledClick = e => {
    if (this.props.disabled) {
      e.stopPropagation();
      e.preventDefault();
    }
  };

  handleBaggageCheck = () => {
    let noWarning = true;
    if (this.props.hopRequests.length > 1) {
      this.props.hopRequests[0].passengers.forEach(
        (passenger, passengerIndex) => {
          let totalWeightFirst = null;
          let totalWeightOther = null;
          this.props.hopRequests.forEach(hopRequest => {
            const { baggagePieceCount } = hopRequest.hopSegmentControls[0];
            const standardWeight =
              hopRequest.hopSegmentControls[0].baggagePieceMaxWeight *
              (baggagePieceCount === 0 ? 1 : baggagePieceCount);
            const currentPassenger = hopRequest.passengers[passengerIndex];
            let currentPassengerBagCount = 0;
            let currentPassengerBagWeight = 0;
            if (currentPassenger.extraBaggages.length > 0) {
              currentPassengerBagCount =
                currentPassenger.extraBaggages[0].baggagePieceCount;
              currentPassengerBagWeight =
                currentPassenger.extraBaggages[0].baggagePieceMaxWeight;
            }
            if (totalWeightFirst === null) {
              totalWeightFirst =
                standardWeight +
                currentPassengerBagWeight *
                (currentPassengerBagCount === 0
                  ? 1
                  : currentPassengerBagCount);
            } else {
              totalWeightOther =
                standardWeight +
                currentPassengerBagWeight *
                (currentPassengerBagCount === 0
                  ? 1
                  : currentPassengerBagCount);
              if (totalWeightFirst !== totalWeightOther) {
                noWarning = false;
              }
            }
          });
        }
      );
    }
    return noWarning;
  };



  handleShowPayment = async value => {
    const canBook =
      getItem("mobile_number_obf") &&
      (getItem("tokenDetails") || getItem("guestLogin"));
    if (!this.props.disabled && !this.state.showIframe && value && canBook && !this.state.showPaymentGateway) {
      if (
        this.props.hasUniqueFsCode &&
        !this.state.fsCodeWarningAccept &&
        !getItem("showMultiBookingIcon")
      ) {
        this.setState({
          openWarning: true
        });
        return;
      }
      const extraBaggageCheck = this.handleBaggageCheck();
      if (!extraBaggageCheck && !this.state.baggageDiffWarningAccept) {
        this.setState({
          openBaggageWarning: true
        });
        return;
      }
      this.props.togglePayment(true);
      if (isMobile) {
        this.props.handleClosePassenger();
      }
      // this.handleSetupHotelBooking();
      this.setState({ showPaymentGateway: true });
    }
    if (!this.props.disabled && !value && this.state.showIframe) {
      this.setState({
        showIframe: false,
        startIframeLoading: false,
        startPolling: false,
        showPaymentGateway: false
      });
    }
  };

  handleShowExtraCheckError = () => {
    this.setState({
      opneExtraCheckWarning: true
    });
  };

  handleCloseWarning = () => {
    this.setState({
      openWarning: false
    });
  };

  handleCloseBaggageDiffWarning = () => {
    this.setState({
      openBaggageWarning: false
    });
  };

  handleCloseExtraCheckError = () => {
    console.log("handleCloseExtraCheckError");
    this.props.history.push("/new-trip");
    this.setState({
      opneExtraCheckWarning: false
    });
  };

  handleWarningDeny = () => {
    this.handleCloseWarning();
    this.props.handleMutliBookingWarningDeny();
  };

  handleBaggageDiffDeny = () => {
    this.handleCloseBaggageDiffWarning();
    this.props.history.push("/trip");
  };

  handleWarningAccept = () => {
    this.setState(
      {
        fsCodeWarningAccept: true,
        openWarning: false
      },
      () => {
        this.handleShowPayment(true);
      }
    );
  };

  handleBaggageDiffAccept = () => {
    this.setState(
      {
        baggageDiffWarningAccept: true,
        openBaggageWarning: false
      },
      () => {
        this.handleShowPayment(true);
      }
    );
  };

  handleHotelsCompleted = () => {
    this.setState({ hotelsFinalised: true });
  }
  handleFinishHotelBookingSetup = () => {
    console.log("about to finish");
    this.setState({ finishedHotelBookingSetup: true });
  }
  render() {
    const {
      openWarning,
      openBaggageWarning,
      opneExtraCheckWarning,
      showPaymentGateway,
      hotelsFinalised,
      finishedHotelBookingSetup
    } = this.state;
    const {
      disabled,
      allPassengersAdded,
      hopRequests,
      searchPage,
      costItems,
      passportDetails,
      handleClosePassenger,
      includeHotel,
      selectedHotels,
      availabilityKey,
      occupancies
    } = this.props;
    const passengers = hopRequests.length > 0 ? hopRequests[0].passengers : [];
    // console.log("include hotel " + includeHotel + "allPassAdded " + allPassengersAdded);
    return (
      <ApolloConsumer>
        {client => (
          <React.Fragment>
            <WarningModal
              open={openWarning}
              handleCloseModal={this.handleCloseWarning}
              handleDeny={this.handleWarningDeny}
              handleAccept={this.handleWarningAccept}
            />
            <BaggageDiffModal
              open={openBaggageWarning}
              handleDeny={this.handleBaggageDiffDeny}
              handleAccept={this.handleBaggageDiffAccept}
            />
            <ExtraCheckErrorModal
              open={opneExtraCheckWarning}
              hopRequests={hopRequests}
              costItems={costItems}
              handleCloseModal={this.handleCloseExtraCheckError}
            />
            <div
              className="__payment-container"
              style={isMobile ? { position: "relative" } : {}}
              onClick={this.handleDisabledClick}
            >
              {disabled && <Overlay onSearch={searchPage} />}
              <AddedPassengers passengers={passengers} />
              {(hotelsFinalised || !includeHotel) ? (<PassengerForm
                hopRequestId={this.props.hopRequestId}
                disabled={disabled}
                adpasi={this.props.adpasi}
                passportDetails={passportDetails}
                adpasiFound={this.props.adpasiFound}
                handlePassengersDone={this.handlePassengersDone}
                handleShowPayment={this.handleShowPayment}
                handleShowExtraCheckError={this.handleShowExtraCheckError}
                handleClosePassenger={handleClosePassenger}
              />) : (<ApolloProvider client={hotelClient}>
                <HotelResults handleHotelsCompleted={this.handleHotelsCompleted} decodedData={this.props.decodedData} />
              </ApolloProvider>)}
              {allPassengersAdded && (
                // <ApolloProvider >
                <SetupHotelBooking client={hotelClient}
                  handleFinishHotelBookingSetup={this.handleFinishHotelBookingSetup}
                  handleSetupBookingComplete={this.props.handleSetupBookingComplete}
                  selectedHotels={selectedHotels}
                  hopRequests={hopRequests}
                />
                // </ApolloProvider>
              )}
              {finishedHotelBookingSetup && allPassengersAdded && !disabled && (
                <ContinueBooking handleShowPayment={this.handleShowPayment} />
              )}
              {finishedHotelBookingSetup && allPassengersAdded && showPaymentGateway && !isMobile && (
                <PaypalGateway client={client} />
              )}
              <Toast
                open={this.state.paymentError}
                message={this.state.errorMessage}
                handleClose={this.handleHidePaymentError}
              />
            </div>
          </React.Fragment>
        )
        }
      </ApolloConsumer>
    );
  }
}

const mapStateToProps = state => ({
  pnrDone: state.reducers.payment.pnrDone,
  costItems: state.flightResults.tripCostItems,
  hopRequests: state.flightResults.hopRequests,
  hasUniqueFsCode: state.reducers.hasUniqueFsCode,
  includeHotel: state.hotelResults.includeHotel,
  allPassengersAdded: state.flightResults.allPassengersAdded,
  availabilityKey: state.hotelResults.availabilityKey,
  selectedHotels: state.hotelResults.selectedHotels,
  occupancies: state.hotelResults.occupancies
});

const mapDispatchToProps = {
  handleSetupBookingComplete,
  togglePayment
};

export default withRouter(
  compose(
    graphql(updAdpasiQuery, { name: "updAdpasi" }),
    // graphql(setupBookingRequestGql, { name: "setupBookingRequest" }),
    connect(mapStateToProps, mapDispatchToProps)
  )(PaymentComponent)
);

withApollo(SetupHotelBooking);
// withApollo(graphql(setupBookingRequestGql, { name: "setupBookingRequest", ownProps: { hotelClient } })(SetupHotelBooking));