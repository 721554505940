import React, { useState } from "react";
import styled from "styled-components";
import Fade from "react-reveal/Fade";
import BenefitCard from "./BenefitCard";
import getTranslation from "utils/getTranslation";
import Benefits1Data from "assets/ae-animations/Benefit-1/data";
import Benefits2Data from "assets/ae-animations/Benefit-2/data";
import Benefits3Data from "assets/ae-animations/Benefit-3/data";

const Wrapper = styled.div`
  width: 100%;
  margin: auto;
  margin-top: 100px;
  text-align: center;
  h1 {
    margin-bottom: 70px;
  }
  @media (max-width: 800px) {
    margin-top: 50px;
    h1 {
      margin-bottom: 40px;
    }
  }
  .Benefits {
    display: flex;
    justify-content: space-between;
    @media (max-width: 800px) {
      flex-flow: column;
    }
  }
`;

const items = [
  {
    id: 1,
    text: getTranslation("Benefits1"),
    data: Benefits1Data
  },
  {
    id: 2,
    data: Benefits3Data,
    text: getTranslation("Benefits2")
  },
  {
    id: 3,
    data: Benefits2Data,
    text: getTranslation("Benefits3")
  }
];

export default () => {
  const [revealed, setRevealed] = useState(false);
  function handleReveal() {
    setRevealed(true);
  }
  return (
    <Wrapper>
      <Fade bottom>
        <h1>{getTranslation("BenefitsFlyStrait")}</h1>
      </Fade>
      <Fade bottom onReveal={handleReveal}>
        <div className="Benefits">
          {items.map(item => (
            <BenefitCard revealed={revealed} key={item.id} data={item} />
          ))}
        </div>
      </Fade>
    </Wrapper>
  );
};
