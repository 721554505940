export default `
# Сроки и условия страница: 4436 слов

Эти общие положения и условия (далее «Условия») применяются между Вами (далее «Вы» или «Клиент») и Fly Strait OÜ (далее «Мы» или «Нас»), оператором портала бронирования flystrait.com (далее «Портал»). Перед использованием Портала необходимо внимательно прочитать эти Условия. Используя портал, Вы соглашаетесь с этими Условиями. Если Вы не принимаете эти Условия, мы просим Вас воздержаться от использования Портала и покинуть веб-сайт.

Вы должны быть не моложе 18 лет и действовать в роли частного потребителя, чтобы совершить бронирование на Портале. Частным потребителем, в отличие от бизнес потребителя, является физическое лицо, которое вступает в юридическую сделку для целей, которые являются преимущественно вне его/ее коммерческой и/или самостоятельной предпринимательской деятельности.

Оператором Портала и Вашим партнером по контракту для использования Портала является:

Fly Strait OÜ (Fly Strait)

E-mail: support@flystrait.com

Ниже изложены Условия:

#
# 1. Объём услуг

### 1.1 Посредничество в Туристических услугах

1.1.1. Услуги по бронированию авиабилетов, предлагаемые на Портале (именуемые «Туристические услуги»), Мы выполняем исключительно в качестве посредника. С этой целью наша роль и обязательства ограничиваются посредничеством в предоставлении Туристических услуг, которые будут предоставляться третьими лицами, такими как авиакомпании (далее в каждом случае «Поставщик услуг»).

1.1.2 Следовательно, соглашение о фактическом предоставлении туристических услуг вступает в силу непосредственно между Вами и соответствующим Поставщиком услуг. Мы не являемся совместителем Поставщика туристических услуг, и Мы не являемся стороной договорных отношений между Вами и Поставщиком услуг.

1.1.3. Используя Портал для покупки услуг для путешествий, Вы разрешаете Нам выступать в качестве посредника с соответствующими Поставщиками услуг от Вашего имени, включая посредничество в оплате этих Туристических услуг, с целью обеспечения безопасности сделки между Вами и Поставщиком(-ами) услуг. Итоговая Цена за фактические Туристические услуги, может включать комиссию за услуги, которые мы оказываем для посредничества между Вами и соответствующим Поставщиком услуг.

1.1.4. Ответственность за фактическое исполнение Туристических услуг, опосредованных через Портал, осуществляется исключительно соответствующим Поставщиком услуг. В нашей роли посредника Мы не берем на себя никакой ответственности за предоставленные Туристические услуги Поставщиками услуг, и Мы не делаем никаких заявлений или гарантий (ни выраженных, ни подразумеваемых) в отношении пригодности или качества услуг, предоставляемых на Портале. За какие-либо претензии, которые могут возникнуть в связи с выполнением или невыполнением туристических услуг, несет ответственность Поставщик услуг.

1.1.5 У Вас есть возможность запросить особые пожелания (например, специальное питание, удобства для инвалидов или детские сиденья) при бронировании Туристических услуг, Мы передадим Ваш запрос соответствующему Поставщику услуг. Однако мы не можем нести ответственность за то, сможет ли Поставщик услуг действительно выполнить такие пожелания.

### 1.2 Применимые договорные условия

1.2.1 Настоящие Условия применяются к посредничеству в Туристических услугах в соответствии с разделом 1.1, а также для наших собственных услуг в соответствии с разделом 1.2.

1.2.2. Для соглашения между Вами и соответствующим Поставщиком услуг (раздел 1.1.2) применяются общие положения и условия соответствующего Поставщика услуг (такие как условия перевозки, условия передачи, условия страхования или тому подобное), как указано Поставщиком услуг. Вы будете уведомлены о таких общих положениях и условиях соответствующего Поставщика услуг в ходе процесса бронирования. Поскольку положения и условия Поставщика услуг могут включать положения, касающиеся ответственности, отмены, изменений в бронировании и возврата денежных средств (если таковые имеются) и других ограничений, рекомендуется внимательно прочитать эти условия.

#
# 2. Процесс бронирования и контактная информация

2.1 Во время процесса бронирования Вам предоставляются технические средства, необходимые для обнаружения ошибок в регистрационных бланках и их исправления до подачи заявки на бронирование. Перед оформлением запроса на бронирование Вам необходимо проверить все данные на предмет точности. Последующие запросы на изменение могут привести к значительным дополнительным расходам.

2.2 Возможно, нам необходимо будет связаться с Вами, например, в случае последующих изменений в приобретенных Туристических услугах. Вы должны предоставить точную контактную информацию, такую ​​как номер телефона и адрес электронной почты. Вы также должны постоянно проверять, получили ли Вы сообщение от Нас.

Вы также несете ответственность за то, чтобы Вы получили наши сообщения. Мы не несем ответственности, если Вы не получили от нас сообщения из-за обстоятельств, не находящихся под нашем контролем, включая, помимо прочего, следующее: (i) вы указали нам неправильный адрес электронной почты; (ii) Ваши настройки электронной почты не позволят нашей электронной почте связаться с Вами; или (iii) Ваши настройки электронной почты обрабатывают нашу электронную почту как спам.

#
# 3. Посредничество в полетах

### 3.1 Заключение договора и изменение цены

3.1.1 После того, как Вы отправили запрос на бронирование, Мы подтвердим получение вашего запроса по электронной почте. В этот момент между Нами и Вами заключается договор о посредничестве. Если Вы не получите такое подтверждение в течение одного часа после завершения запроса на бронирование, и ни один из наших операторов не свяжется с Вами по электронной почте или телефону, чтобы указать на какие-либо проблемы, свяжитесь с нами по телефону для подтверждения.

3.1.2. После оформления запрошенных билетов Вы получите подтверждение по электронной почте с номером билета. В этот момент между Вами и соответствующей авиакомпанией заключается обязательный договор.

3.1.3. Цены на рейс, а также доступность мест, непосредственно вставляются соответствующей авиакомпанией. В случае изменения цен на авиабилеты вне нашего контроля, возникших после подачи заявки на бронирование, но до того, как договор с авиакомпанией стал обязательным (как указано выше в разделе 3.1.2), договор не вступит в силу, и Ваш платеж будет возвращен в полном объеме. Мы можем связаться с Вами и предложить вам возможность принять измененную цену в обычные часы работы, но не позднее, чем через 24 часа после того, как мы узнали о изменении цены или в первый будний день, следующий за таким 24-часовым периодом.

### 3.2 Информация о бронировании и условиях перевозки

3.2.1 Что касается рейсов, предлагаемых на Портале, Мы исключительно действуем в качестве посредника. Соглашение о выполнении рейса вступает в силу непосредственно между Вами и соответствующей авиакомпанией, и Мы не несем договорную ответственность, связанную с выполнением или невыполнением Вашего рейса. Действующая авиакомпания несет исключительную ответственность за выполнение / невыполнение Вашего полета.

3.2.2 Прежде чем завершить бронирование, Вам будут предоставлены условия и положения соответствующих авиакомпаний.

3.2.3 Ниже, в качестве общего обзора, Мы предоставляем информацию об условиях, связанных с бронированием и перевозкой, которые обычно применяются авиакомпаниями таким или, по крайней мере, аналогичным образом. Тем не менее, любые отклоняющиеся положения соответствующей авиакомпании имеют преимущественную силу над общей информацией, представленной в данном разделе 3.2.3. Поэтому в каждом случае, пожалуйста, ознакомьтесь с действующими положениями и условиями соответствующей авиакомпании до Вашего бронирования.

#### а. Время полета / регистрация

Время всех рейсов указано местное. Прибытие на следующий день обозначается знаком &quot;+1&quot; в расписании. Указанное время полета является предварительным и может быть изменено в короткие сроки после выдачи билета; например, из-за ограничений, связанных с управлением полетом, погодными условиями или функциональными ограничениями авиакомпании. Пожалуйста, информируйте себя о текущем времени заблаговременно до вылета.

Пожалуйста, соблюдайте время регистрации, указанное авиакомпанией. Авиакомпании имеют право отказать в посадке, если Вы опоздали на регистрацию. Пожалуйста, обратите внимание, что некоторые авиакомпании поощряют регистрацию через свой веб-сайт, поскольку они могут взимать плату за регистрацию при ручной регистрации в аэропорту.

#### б. Комбинация индивидуальных билетов

Комбинация двух отдельных билетов в одну сторону вместо билета туда-обратно четко обозначена как таковая во время процедуры бронирования. Билеты рассматриваются независимо друг от друга в случае отмены, изменения, нарушения воздушного движения, таких как забастовки и изменения в расписании рейсов. Будут применяться собственные правила каждой авиакомпании.

Авиабилеты с разными номерами бронирования всегда рассматриваются как путешествия, независимые друг от друга.

#### в. Авиабилеты с несколькими сегментами / порядок использования

Ваш билет туда-обратно или билет в одну сторону может состоять из нескольких сегментов. В соответствии с условиями большинства авиакомпаний такие полетные сегменты должны использоваться последовательно. Если нет, то многие авиакомпании откажутся от перевозки на последующих сегментах полета (например, неиспользование одного сегмента поездки может привести к аннулированию остальной части билета). Для билетов туда и обратно неявка на Ваш рейс может привести к последующей отмене обратного рейса авиакомпанией

#### г. Беременность

Некоторые авиакомпании отказываются перевозить женщин, которые прошли 28-ю неделю беременности на момент вылета или обратного рейса. Если Вы беременны, Вы должны уточнить у авиакомпании и Вашего врача, можете ли Вы начать путешествие.

#### д. Младенцы и детские билеты

Пожалуйста, свяжитесь с авиакомпанией, чтобы получить условия для путешествия с ребенком, который не имеет отдельного места. Как правило, детям старше 2 лет требуется отдельное место, в то время как дети в возрасте от 0 до 2 лет путешествуют как младенцы и не получают отдельного места. Если младенцу исполняется 2 года до окончания поездки, необходимо забронировать детский билет на всю поездку. Детские билеты не могут быть забронированы до рождения, так как правильное имя и дата рождения должны совпадать с заявленными в паспорте. Мы не будем возмещать никакие расходы, возникающие, если с самого начала забронирован неправильный тип билета.

#### е. Дети без сопровождения

Мы не выступаем посредником при бронировании билетов для несовершеннолетних без сопровождения. Дети в возрасте до 18 лет должны быть забронированы для поездки в сопровождении взрослого. Некоторые страны и авиакомпании отказывают во въезде детям до 18 лет без сопровождения законного опекуна. Обратите внимание, что некоторые авиакомпании требуют, чтобы дети в возрасте до 18 лет имели при себе свидетельство о рождении.

#### ж. Потерянный / поврежденный багаж

В качестве посредника Мы не несем ответственности за утерю или повреждение багажа. О любых проблемах следует немедленно сообщать представителю авиакомпании в аэропорту.

#### з. Транзит и ночлег

Как правило, наземный транспорт и/или ночлег во время Вашей поездки не включены в стоимость авиабилета. Вы несете личную ответственность за проверку расписания и цен на наземный транспорт.

#### и. Время пересадки между рейсами

Стандартные билеты, забронированные на Портале, имеют подтвержденное время пересадки. Время, необходимое для пересадки между рейсами, рассчитывается авиакомпаниями. Если сегмент полета задерживается и приводит к опозданию на пересадку, авиакомпании обязаны помочь Вам достичь конечного пункта назначения (см. Раздел 9.1).

При бронировании отдельных билетов авиакомпании не несут ответственности за пропущенные пересадочные рейсы, возникшие в результате задержек. Поэтому Вы несете ответственность за то, чтобы время пересадки было достаточным в соответствии с авиакомпаниями и аэропортами. Любые дополнительные расходы, понесенные из-за пропущенных пересадочных рейсов, не будут возмещены.

#### к. Двойное бронирование

Двойное бронирование означает, что два или более бронирования с одним и тем же именем пассажира были сделаны одной и той же авиакомпанией. Если у Вас есть двойной заказ, авиакомпания может отменить поездку(-и). Это также может произойти, если бронирование было сделано в разных туристических агентствах. Мы не несем ответственности за отмены, сделанные авиакомпаниями, ни за отказ авиакомпании в возврате денежных средств, в случаях, когда они подозревают двойное бронирование.

### 3.3 Авиакомпании, которым запрещено работать в Европейском Союзе (черный список)

Обратите внимание, что некоторым авиакомпаниям запрещено работать на территории Европейского Союза (ЕС) в соответствии с решением, принятым Европейской комиссией в тесной консультации с национальными органами воздушного транспорта. Такие авиакомпании запрещены, поскольку они считаются небезопасными или не подлежат надлежащему контролю со стороны властей их страны.

Вы можете проверить, какие авиакомпании затронуты запретом на эксплуатацию по следующей ссылке: [черный список (список авиакомпаний, которым не разрешено работать в ЕС)](https://ec.europa.eu/transport/language-selector/site-language_en?destination=node/2840)

### 3.4 Изменение и отмена расписания авиакомпаниями

3.4.1. Ваше соглашение с соответствующей авиакомпанией может позволить им отменить или изменить Ваше бронирование. Мы уведомим Вас о любых изменениях, как только авиакомпания проинформирует нас о них.

3.4.2 Время полета, указанное в подтверждении бронирования, может меняться в период между датой бронирования и датой фактической поездки. В случае изменения расписания рейсов Мы уведомим Вас об этом, как только авиакомпания проинформирует нас об этом. Тем не менее, Мы настоятельно рекомендуем Вам связаться с Машей авиакомпанией по крайней мере за 72 часа до запланированного вылета каждого рейса, чтобы убедиться, что рейс(-ы) (и любые пересадочные рейсы) работают по расписанию. Мы не контролируем изменения в расписании авиакомпаний и не несем ответственности за расходы, которые могут возникнуть в результате таких изменений.

### 3.5 Изменения и отмены, запрошенные Вами

Для бюджетных авиакомпаний см. раздел 3.7.

3.5.1 Условия для изменения бронирования рейса (включая изменение имени пассажира, пункта назначения и даты поездки), а также для предоставления возврата за отмену устанавливаются соответствующей авиакомпанией, которая является Вашим договорным партнером по предоставлению рейса. Мы, как посредник, не имеем никакого влияния на такие условия.

3.5.2 Если Вы хотите внести изменения в свое бронирование или запросить возврат средств за отмену, в качестве дополнительной собственной услуги, Мы предлагаем обработать запрос от Вашего имени, при условии, что правила авиакомпании допускают такое изменение или возврат средств за отмену. В процессе бронирования таких дополнительных услуг Мы сообщим Вам о любых дальнейших условиях предоставления таких услуг. Кроме того, Вы можете, конечно, непосредственно обратиться к соответствующей авиакомпании.

3.5.3 Для того, чтобы Мы могли обрабатывать изменения, запрошенные Вами, необходимо, чтобы Мы получили Ваши запросы на изменение не позднее, чем за 24 часа до начала путешествия (только по телефону). Если Вы приобрели нашу услугу «Гибкий билет», см. раздел 10.

Для запросов на изменение в более короткие сроки, мы рекомендуем Вам связаться с соответствующей авиакомпанией напрямую.

### 3.6 Неявки или неявки на рейс

Настоящим Вы уполномочиваете нас отменить неиспользованный рейс от Вашего имени в случае неявки или неявки на рейс и запросить от Вашего имени возможные возмещения от авиакомпании. Мы имеем право, но не обязаны делать это, и Ваше право требовать возмещения непосредственно от авиакомпании остается неизменным.

### 3.7 Бюджетные авиакомпании

3.7.1 Если соответствующий рейс выполняется бюджетной авиакомпанией, это будет четко отображаться Вам во время процедуры бронирования. На рейсы бюджетных авиакомпаний распространяются следующие особые положения

3.7.2 При посредничестве в туристических услугах, оказываемых бюджетными авиакомпаниями, Мы можем действовать от Вашего имени при заключении договора между Вами и бюджетной авиакомпанией.

3.7.3 Мы не имеем доступа к системам бронирования бюджетных авиакомпаний. Поэтому, пожалуйста, свяжитесь с соответствующей бюджетной авиакомпанией напрямую по вопросам бронирования, изменения или отмены бронирования. Если Вы приобрели нашу услугу «Гибкий билет» (см. Раздел 10), все запросы на перебронирование должны быть сделаны через нашу службу поддержки клиентов, как указано в разделе 10.1.4.

3.7.4 Пожалуйста, обратите внимание, что некоторые бюджетные авиакомпании взимают плату за ручную регистрацию в аэропорту. Для того, чтобы избежать подобных сборов, следует воспользоваться возможностью онлайн-регистрации через сайт соответствующей бюджетной авиакомпании. Пожалуйста, заблаговременно до Вашего отъезда информируйте себя о возможностях регистрации и условиях Вашей авиакомпании.

3.7.5. Подтверждения бронирования бюджетных рейсов отправляются сразу после подтверждения бронирования соответствующей бюджетной авиакомпанией. Вы можете получить два подтверждения бронирования – одно от нас и одно от соответствующей бюджетной авиакомпании. Если Вы получили два подтверждения бронирования, пожалуйста, используйте для регистрации подтверждение бронирования от бюджетной авиакомпании.

#
# 4. Специальные положения о посредничестве при бронировании нескольких услуг

Портал предлагает возможность смешивать и сочетать несколько отдельных услуг, по Вашему желанию (например, перелет + отель). В этом случае Вы поручаете нам выступать в качестве посредника при бронировании различных туристических услуг от различных поставщиков. Соответствующие поставщики будут четко представлены Вам во время процедуры бронирования до завершения бронирования. В случае, если Вы объединяете отдельные услуги, между Вами и нами не заключается договор о поездке, вместо этого Вы заключаете несколько договоров о предоставлении отдельных услуг с каждым отдельным поставщиком. В этом случае мы действуем исключительно в качестве посредника в отношении каждой отдельной туристической услуги.

#
# 5. Информация о паспортных, визовых и медицинских положениях

5.1. Требования к паспорту, визе и/или здоровью могут измениться, и поэтому Вы должны проконсультироваться с соответствующим органом (посольством, консульством и т.п.) задолго до поездки. Вы несете ответственность за наличие действительного паспорта и, в случае необходимости, визы. Важно не забыть указать все транзитные пункты в Вашем путешествии, которые также могут потребовать получения визы. Получение визы часто может занять некоторое время, поэтому рекомендуется подавать документы заблаговременно. Мы не несем ответственности за клиентов, которые не имеют правильных документов.

5.2 Каждое направление имеет свои собственные требования как въездных формальностей, вакцинации и т.д. что также может варьироваться в зависимости от гражданства пассажира. Вы несете ответственность за сбор этой информации. Мы не несем ответственности за любые инциденты, связанные с несоблюдением таких официальных правил. Поэтому мы призываем Вас всегда проверять различные формальности выбранных стран назначения или транзита, а также время, необходимое для принятия всех соответствующих мер.

#
# 6. Сборы и оплата

### 6.1 Оплата

6.1.1 Оплата туристических услуг обрабатывается либо нами (в сотрудничестве с нашими поставщиками платежных услуг, которые предоставляют функции обработки платежей), либо поставщиком услуг. Обратите внимание, что для обработки платежа нам может потребоваться предоставить поставщику(-ам) платежных услуг Вашу платежную информацию, например информацию о кредитной или дебетовой карте. Вся платежная информация, принадлежащая нашим клиентам, шифруется на защищенном сервере при передаче ее поставщику(-ам) платежных услуг.

6.1.2 Для того, чтобы мы могли обработать Ваш платеж, Вам необходимо убедиться, что имеете достаточно средств. В случае возникновения каких-либо проблем, связанных с обработкой Вашего платежа, мы повторим обработку Вашего платежа в сотрудничестве с нашим поставщиком платежных услуг. Если это не приведет к списанию средств с Вашего счета, мы свяжемся с Вами как можно скорее для получения инструкций по другим способам оплаты. Если мы не получим Ваш платеж после таких дальнейших инструкций, Ваш непогашенный платеж будет передан компании по взысканию задолженности.

### 6.2 Мошенничество с оплатой

Если у нас есть основания подозревать, что имеет место мошенничество, Мы оставляем за собой право отказать в обработке такого платежа. В случае подозрения в совершении преступления может потребоваться легитимизация платежа. Обо всех видах мошенничества с платежами будет сообщено в полицию и передано в компанию по взысканию задолженности.

#
# 7. Жалобы

### 7.1 Претензии, связанные с оказанием Туристических услуг

Любые проблемы, замечания или претензии, связанные с фактическим выполнением Туристических услуг, должны быть адресованы непосредственно соответствующему поставщику услуг, с которым у Вас заключен договор на соответствующие Туристические услуги. См. также раздел 9, где изложены Ваши права в соответствии с правилами ЕС.

### 7.2 Жалобы на наши собственные услуги

Жалобы, касающиеся наших услуг осуществляется исключительно в письменной форме и должны быть представлены в течение двух месяцев после даты окончания вашей поездки по электронной почте.

Адрес электронной почты: [complaints@flystrait.com](mailto:complaints@flystrait.com)

#
# 8. Права клиента в соответствии с правилами ЕС

### 8.1 Отмененные или задержанные рейсы

Если Вы путешествуете в или из ЕС, или на перевозчике ЕС, Вы можете иметь право требовать возмещения расходов, которые Вы можете предъявить непосредственно к соответствующей авиакомпании в случае, если Ваш рейс отменен, задержан или Вам было отказано в посадке. [Для получения дополнительной информации об правилах ЕС 261/2004, пожалуйста, нажмите здесь.](http://eur-lex.europa.eu/legal-content/EN/ALL/?uri=CELEX%3A32004R0261)

### 8.2 Ответственность перевозчика

Правила ЕС (889/2002) об ответственности авиаперевозчика в случае авиационных происшествий. [С правилами можно ознакомиться здесь.](http://eur-lex.europa.eu/legal-content/EN/TXT/?uri=celex%3A32002R0889)

#
# 9. Ответственность

9.1. Вы соглашаетесь с тем, что Мы выступаем в качестве посредника между Вами и Поставщиком услуг. Мы ни при каких обстоятельствах не несем ответственности в отношении туристических услуг, которые Вы заказали у одного или нескольких поставщиков услуг, и Мы не несем никакой ответственности за любые неточности в информации, отображаемой на портале, которые были предоставлены соответствующим Поставщиком услуг.

9.2 Если Поставщик услуг не может предоставить туристическую услугу по какой-либо причине, в том числе если поставщик услуг объявляет о банкротстве, Мы можем действовать только в качестве посредника и возвращать платежи, если Мы уже получили их от соответствующего Поставщика услуг.

9.3. Что касается наших собственных услуг, Мы несем ответственность за ущерб в соответствии с ограничениями, изложенными в настоящих Условиях и в пределах, разрешенных законом. Мы несем ответственность только за прямой ущерб, действительно причиненный, оплаченный или понесенный Вами в связи с неисполнением наших обязательств в отношении наших собственных услуг, вплоть до общей суммы стоимости Вашего бронирования (будь то для одного события или серии связанных событий).

9.4. Ограничение ответственности, изложенное в Разделе 10.3, также применяется к нарушениям обязанностей лицами, по вине которых Мы несем ответственность в соответствии с положениями закона.

#
# 10. Основные права и разрешение споров

### 10.1. Основные права

Эстонское право, за исключением основного единообразного закона, в частности закона ООН о международной купле-продаже товаров, применяется исключительно к настоящим Условиям и договору между Вами и нами.

Как потребитель, Вы получите выгоду от любых обязательных постановлений законодательства страны, в которой Вы проживаете. Ничто в этих Условиях, включая этот раздел 11.1, не влияет на Ваше право потребителя полагаться на такие обязательные положения местного законодательства.

### 10.2 Разрешение споров в Интернете

Европейская комиссия предоставляет платформу для разрешения споров в режиме онлайн: (http://ec.europa.eu/consumers/odr/)

#
# 11. Защита данных

Мы серьезно относимся к защите Ваших персональных данных. Вы можете найти подробную информацию о сборе, обработке и использовании Ваших персональных данных в нашей [Политике конфиденциальности.](https://flystrait.com/privacy)
`;
