import React from "react";
import styled from "styled-components";

const Mask = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 82px;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(208, 244, 252, 0.4) 0%,
    rgba(208, 243, 252, 0) 100%
  );
`;

export default (style = {}) => <Mask style={style} />;
