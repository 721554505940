export default `
# Allgemeine Geschäftsbedingungen

Diese Allgemeinen Geschäftsbedingungen (&quot;AGB&quot;) gelten zwischen Ihnen (&quot;Sie&quot; oder dem &quot;Kunden&quot;) und Fly Strait OÜ (&quot;Wir&quot; oder &quot;Uns&quot;), dem Betreiber des Buchungsportals flystrait.com (nachfolgend &quot;Portal&quot; genannt). Wir bitten Sie, diese AGB vor der Nutzung des Portals sorgfältig zu lesen. Mit der Nutzung des Portals erklären Sie sich mit diesen AGBs einverstanden. Wenn Sie diese AGB nicht akzeptieren, bitten wir Sie, die Nutzung des Portals zu unterlassen und die Website zu verlassen.

Sie müssen mindestens 18 Jahre alt sein und in Ihrer Rolle als privater Verbraucher handeln, um eine Buchung auf dem Portal vorzunehmen. Ein privater Verbraucher ist im Gegensatz zu einem Unternehmen eine natürliche Person, die ein Rechtsgeschäft zu Zwecken abschließt, die überwiegend außerhalb ihrer gewerblichen und/oder selbständigen Tätigkeit liegen.

Der Betreiber des Portals und Ihr Vertragspartner für die Nutzung des Portals ist:

Fly Strait OÜ (Fly Strait)

E-Mail: support@flystrait.com

Die AGB sind wie folgt aufgebaut:

#
# 1. Leistungsumfang

### 1.1 Vermittlung von Reisedienstleistungen

1.1.1 Für die auf dem Portal angebotenen Flugbuchungsdienstleistungen (nachfolgend &quot;Reisedienstleistungen&quot; genannt) handeln wir ausschließlich im Rahmen unserer Vermittlungstätigkeit. Zu diesem Zweck beschränken sich unsere Aufgaben und Pflichten auf die Vermittlung von Reisedienstleistungen, die von Dritten wie z.B. Fluggesellschaften (nachfolgend jeweils &quot;Dienstleister&quot;) erbracht werden.

1.1.2 Der Vertrag über die tatsächliche Erbringung von Reisedienstleistungen kommt somit direkt zwischen Ihnen und dem jeweiligen Leistungsträger zustande. Wir sind kein Miteigentümer der Reisedienstleistungen und wir sind nicht Partei des Vertragsverhältnisses zwischen Ihnen und dem Dienstleister.

1.1.3 Durch die Nutzung des Portals zum Kauf von Reisedienstleistungen ermächtigen Sie uns, in Ihrem Namen mit dem/den entsprechenden Dienstleistern zu vermitteln, einschließlich der Vermittlung der Zahlung für diese Reisedienstleistungen, um sicherzustellen, dass die Transaktion zwischen Ihnen und dem/den Dienstleister(n) durchgeführt wird. Der Preis für die eigentlichen Reisedienstleistungen kann eine Provision für die von uns erbrachten Leistungen zur Vermittlung der Vereinbarung zwischen Ihnen und dem jeweiligen Dienstleister beinhalten.

1.1.4 Die Verantwortung für die tatsächliche Erbringung der über das Portal vermittelten Reisedienstleistungen liegt ausschließlich bei dem jeweiligen Dienstleister. In unserer Rolle als Vermittler übernehmen wir keine Verantwortung für die von den Dienstleistern zu erbringenden Reisedienstleistungen und geben keine Zusicherungen oder Garantien (weder ausdrücklich noch stillschweigend) hinsichtlich der Eignung oder Qualität der auf dem Portal vermittelten Reisedienstleistungen. Für alle Ansprüche, die Sie im Zusammenhang mit der Erbringung oder Nichterbringung der Reiseleistung haben, ist der Dienstleister der zuständige Adressat.

1.1.5 Sollten Sie bei der Buchung von Reisedienstleistungen die Möglichkeit haben, Sonderwünsche (z.B. spezielle Mahlzeiten, Behinderteneinrichtungen oder Kindersitze) anzufordern, werden wir Ihre Anfrage an den jeweiligen Dienstleister weiterleiten. Wir können jedoch keine Verantwortung dafür übernehmen, ob der Dienstleister diese Wünsche tatsächlich erfüllen kann.

### 1.2 Geltende Vertragsbedingungen

1.2.1 Diese AGB gelten für die Vermittlung von Reisedienstleistungen gemäß Ziffer 1.1 sowie für unsere eigenen Leistungen gemäß Ziffer 1.2.

1.2.2 Für die Vereinbarung zwischen Ihnen und dem jeweiligen Dienstleister (Abschnitt 1.1.2) gelten die Allgemeinen Geschäftsbedingungen des jeweiligen Dienstleisters (wie Beförderungsbedingungen, Transferbedingungen, Versicherungsbedingungen oder dergleichen), wie sie vom Dienstleister herausgegeben werden. Diese Allgemeinen Geschäftsbedingungen des jeweiligen Dienstleisters werden Ihnen im Laufe des Buchungsprozesses mitgeteilt. Da die Allgemeinen Geschäftsbedingungen des Dienstanbieters Bestimmungen über Haftung, Stornierung, Änderungen von Buchungen und Rückerstattungen (falls verfügbar) und andere Einschränkungen enthalten können, wird Ihnen empfohlen, diese Bedingungen sorgfältig zu lesen.

#
# 2. Buchungsprozess und Kontaktdaten

2.1 Während des Buchungsprozesses stehen Ihnen die technischen Mittel zur Verfügung, um Fehler in den Anmeldeformularen zu erkennen und zu beheben, bevor Sie Ihre Buchungsanfrage stellen. Sie werden gebeten, alle Daten vor Abschluss Ihrer Buchungsanfrage auf ihre Richtigkeit zu überprüfen. Nachträgliche Änderungswünsche können zu erheblichen Mehrkosten führen.

2.2 Möglicherweise müssen wir Sie kontaktieren, z.B. bei nachträglichen Änderungen der erworbenen Reisedienstleistungen. Sie müssen genaue Kontaktinformationen wie Ihre Telefonnummer und E-Mail-Adresse angeben. Sie müssen auch ständig überprüfen, ob Sie eine Nachricht von uns erhalten haben.

Es liegt auch in Ihrer Verantwortung, sicherzustellen, dass Sie unsere Nachrichten empfangen können. Wir sind nicht verantwortlich, wenn Sie aufgrund von Umständen, die vernünftigerweise außerhalb unserer Kontrolle liegen, keine Nachricht von uns erhalten, einschließlich, aber nicht beschränkt auf das Folgende: (i) Sie haben uns eine falsche E-Mail-Adresse angegeben; (ii) Ihre E-Mail-Einstellungen erlauben es nicht, dass unsere E-Mail Sie erreicht; oder (iii) Ihre E-Mail-Einstellungen behandeln unsere E-Mail als Spam.

#
# 3. Vermittlung von Flügen

### 3.1 Vertragsabschluss und Preisänderungen

3.1.1 Nachdem Sie Ihre Buchungsanfrage abgeschickt haben, werden wir den Eingang Ihrer Anfrage per E-Mail bestätigen. Dies ist der Moment, in dem der Vermittlungsvertrag zwischen Ihnen und Uns zustande kommt. Wenn Sie diese Bestätigung nicht innerhalb einer Stunde nach Abschluss Ihrer Buchungsanfrage erhalten und keiner unserer Mitarbeiter Sie per E-Mail oder Telefon kontaktiert hat, um Probleme anzuzeigen, kontaktieren Sie uns bitte telefonisch zur Überprüfung.

3.1.2 Sobald Ihre gewünschten Tickets ausgestellt sind, erhalten Sie eine Bestätigungs-E-Mail mit einer Ticketnummer. Dies ist der Zeitpunkt, an dem ein verbindlicher Vertrag zwischen Ihnen und der/den betreffenden Fluggesellschaft(en) abgeschlossen wurde.

3.1.3 Die Preise für den Flug sowie die Verfügbarkeit der Plätze werden von der jeweiligen Fluggesellschaft direkt eingegeben. Für Preisänderungen von Fluggesellschaften, die außerhalb unserer Kontrolle liegen und nach Einreichung der Buchungsanfrage, aber vor der Bindung des Vertrages mit der Fluggesellschaft (wie in Abschnitt 3.1.2 oben beschrieben) eintreten, tritt der Vertrag nicht in Kraft und Ihre Zahlung wird vollständig zurückerstattet. Wir können uns mit Ihnen in Verbindung setzen und Ihnen die Möglichkeit anbieten, den geänderten Preis während der normalen Öffnungszeiten zu akzeptieren, spätestens jedoch 24 Stunden nach Kenntnisnahme der Preisänderung oder am ersten Wochentag nach Ablauf dieser 24 Stunden.

### 3.2 Informationen zu Buchungs- und Transportbedingungen

3.2.1 Bei den auf dem Portal angebotenen Flügen handeln wir ausschließlich im Rahmen unserer Vermittlungstätigkeit. Die Vereinbarung über die Durchführung des Fluges tritt direkt zwischen Ihnen und der jeweiligen Fluggesellschaft in Kraft, und wir übernehmen keine vertragliche Verantwortung für die Durchführung oder Nichtdurchführung Ihres Fluges. Die ausführende Fluggesellschaft ist allein verantwortlich für die Durchführung / Nichterfüllung Ihres Fluges.

3.2.2 Bevor Sie die Buchung abschließen, werden Ihnen die Allgemeinen Geschäftsbedingungen der jeweiligen Fluggesellschaft(en) zur Verfügung gestellt.

3.2.3 Im Folgenden geben wir Ihnen einen allgemeinen Überblick über die Buchungs- und Beförderungsbedingungen, die typischerweise von den Fluggesellschaften in dieser oder zumindest ähnlicher Weise angewandt werden. Abweichende Bestimmungen der jeweiligen Fluggesellschaft gehen jedoch aus den allgemeinen Informationen in diesem Abschnitt 3.2.3 vor. Bitte überprüfen Sie daher in jedem Fall vor Ihrer Buchung die geltenden Allgemeinen Geschäftsbedingungen der jeweiligen Fluggesellschaft.

#### a. Flugzeiten/Check-in

Alle angegebenen Flugzeiten sind lokal. Ankünfte am nächsten Tag werden mit einem &quot;+1&quot; auf dem Fahrplan angezeigt. Die angegebenen Flugzeiten sind vorläufig und können sich nach Ausstellung des Tickets kurzfristig ändern, z.B. aufgrund von Einschränkungen durch die Flugsicherung, das Wetter oder funktionale Einschränkungen durch die Fluggesellschaft. Bitte informieren Sie sich rechtzeitig vor Ihrem Flug über die aktuellen Zeiten.

Bitte beachten Sie die von der Fluggesellschaft angegebenen Check-in-Zeiten. Die Fluggesellschaften sind berechtigt, die Beförderung zu verweigern, wenn Sie zu spät zum Check-in kommen. Bitte beachten Sie, dass einige Fluggesellschaften den Check-in über ihre eigene Website empfehlen, da sie beim manuellen Check-in am Flughafen eine Check-in-Gebühr erheben können.

#### b. Kombination von Einzeltickets

Eine Kombination aus zwei getrennten Einzelfahrkarten anstelle eines Hin- und Rückfahrtickets wird während des Buchungsvorgangs deutlich als solche gekennzeichnet. Die Tickets werden unabhängig voneinander bei Annullierung, Änderung, Unterbrechung des Flugverkehrs wie Streiks und Änderung von Flugplänen behandelt. Es gelten die Bestimmungen der jeweiligen Fluggesellschaft.

Flugtickets mit unterschiedlichen Buchungsnummern werden immer als voneinander unabhängige Reisen betrachtet.

#### c. Flugtickets mit mehreren Segmenten/Nutzungsreihenfolge

Ihr Hin- und Rückflugticket oder One-Way-Ticket kann aus mehreren Segmenten bestehen. Gemäß den Bedingungen der meisten Fluggesellschaften sind solche Flugsegmente nacheinander zu nutzen. Andernfalls lehnen viele Fluggesellschaften die Beförderung auf nachfolgenden Flugsegmenten ab (z.B. könnte die Nichtverwendung eines Flugsegments den Rest des Tickets ungültig machen). Bei Hin- und Rückflügen kann ein Nichterscheinen auf Ihrem Hinflug dazu führen, dass Ihr Hinflug von der Fluggesellschaft storniert wird.

#### d. Schwangerschaft

Einige Fluggesellschaften weigern sich, Frauen zu befördern, die zum Zeitpunkt des Hin- oder Rückfluges die 28. Schwangerschaftswoche überschritten haben. Wenn Sie schwanger sind, müssen Sie mit der Fluggesellschaft und Ihrem Arzt klären, ob Sie die Reise antreten können oder nicht.

#### e. Kleinkinder und Kindertickets

Bitte wenden Sie sich an die Fluggesellschaft, um die Bedingungen für die Reise mit einem Kind ohne eigenen Sitzplatz zu erfahren. In der Regel benötigen Kinder über 2 Jahren einen separaten Sitzplatz, während Kinder zwischen 0 und 2 Jahren als Säuglinge reisen und keinen eigenen Platz erhalten. Erreicht das Kind vor Reiseende das Alter von 2 Jahren, muss ein Kinderticket für die gesamte Reise gebucht werden. Kindertickets können nicht vor der Geburt gebucht werden, da der richtige Name und das richtige Geburtsdatum mit den Angaben im Reisepass übereinstimmen müssen. Auslagen, die wegen Buchung der falschen Ticketart von vornherein entstehen, werden nicht erstattet.

#### Unbegleitete Minderjährige

Wir vermitteln keine Buchung von Tickets für unbegleitete Minderjährige. Tickets für Kinder unter 18 Jahren können nur gebucht werden, wenn diese in Begleitung eines Erwachsenen reisen. Einige Länder und Fluggesellschaften verweigern Kindern unter 18 Jahren den Zutritt, wenn sie nicht von einem gesetzlichen Vormund begleitet werden. Bitte beachten Sie, dass einige Fluggesellschaften verlangen, dass Kinder unter 18 Jahren eine Geburtsurkunde mitbringen.

#### g. Verlorenes/beschädigtes Gepäck

Im Rahmen unserer Vermittlungstätigkeit übernehmen wir keine Haftung für verloren gegangenes oder beschädigtes Gepäck. Etwaige Probleme sind unverzüglich dem Vertreter der Fluggesellschaft am Flughafen zu melden.

#### h. Transit und Übernachtung

In der Regel sind Bodentransport und/oder Übernachtung während der Reise nicht im Flugticketpreis enthalten. Sie sind selbst für die Überprüfung der Fahrpläne und Preise der Bodenverkehrsmittel verantwortlich.

#### i. Verbindungszeiten zwischen den Flügen

Standard-Tickets, die über das Portal gebucht werden, haben genehmigte Verbindungszeiten. Die benötigten Reisezeiten zwischen den Flügen werden von den Fluggesellschaften berechnet. Wenn sich ein Flugsegment verspätet und zu einer verpassten Verbindung führt, sind die Fluggesellschaften verpflichtet, Sie bei der Erreichung Ihres Endziels zu unterstützen (siehe Abschnitt 9.1).

Bei der Buchung separater Tickets übernehmen die Fluggesellschaften keine Verantwortung für verpasste Verbindungen aufgrund von Verspätungen. Daher liegt es in Ihrer Verantwortung, dafür zu sorgen, dass die Verbindungszeit nach Ansicht der Fluggesellschaften und Flughäfen ausreichend ist. Mehrkosten, die durch verpasste Anschlüsse entstehen, werden nicht erstattet.

#### j. Doppelbuchung

Eine Doppelbuchung bedeutet, dass zwei oder mehr Buchungen mit dem gleichen Passagiernamen bei derselben Fluggesellschaft vorgenommen wurden. Wenn Sie eine Doppelbuchung haben, kann die Fluggesellschaft die Reise(n) stornieren. Dies kann auch der Fall sein, wenn die Buchungen bei verschiedenen Reisebüros vorgenommen wurden. Wir sind nicht verantwortlich für Stornierungen durch die Fluggesellschaften oder für abgelehnte Erstattungen von Fluggesellschaften, wenn sie den Verdacht einer Doppelbuchung haben.

### 3.3 Fluggesellschaften, denen der Flugbetrieb in der EU verboten ist (schwarze Liste)

Bitte beachten Sie, dass es bestimmten Luftfahrtunternehmen - gemäss einer Entscheidung der Europäischen Kommission in enger Absprache mit den nationalen Luftverkehrsbehörden - untersagt ist, innerhalb der EU zu operieren, . Solche Fluggesellschaften sind verboten, weil sie als unsicher gelten oder keiner angemessenen Kontrolle durch die Behörden ihres Landes unterliegen.

Welche Fluggesellschaften von einem Betriebsverbot betroffen sind, können Sie über den folgenden Link überprüfen: [Schwarze Liste (Liste der Luftfahrtunternehmen, die nicht in der EU tätig sein dürfen)](https://ec.europa.eu/transport/language-selector/site-language_en?destination=node/2840)

### 3.4 Fahrplanänderungen und Stornierungen durch die Fluggesellschaften

3.4.1 Ihre Vereinbarung mit der jeweiligen Fluggesellschaft kann es ihr ermöglichen, Ihre Buchungen zu stornieren oder zu ändern. Wir werden Sie über alle Änderungen informieren, sobald wir von der Fluggesellschaft darüber informiert werden.

3.4.2 Die in Ihrer Buchungsbestätigung angegebenen Flugzeiten können sich zwischen dem Datum Ihrer Buchung und dem Datum Ihrer tatsächlichen Reise ändern. Im Falle von Flugplanänderungen werden wir Sie benachrichtigen, sobald wir von der Fluggesellschaft darüber informiert werden. Wir empfehlen Ihnen jedoch dringend, sich mindestens 72 Stunden vor dem geplanten Abflug jedes Fluges mit Ihrer Fluggesellschaft in Verbindung zu setzen, um sicherzustellen, dass die Flüge (und alle Anschlussflüge) planmäßig durchgeführt werden. Wir haben keine Kontrolle über die Flugplanänderungen der Fluggesellschaft und übernehmen keine Verantwortung für Kosten, die durch solche Änderungen entstehen können.

### 3.5 Von Ihnen gewünschte Änderungen und Stornierungen

Informationen zu Billigfluggesellschaften finden Sie in Abschnitt 3.7.

3.5.1 Die Bedingungen für die Änderung von Flugbuchungen (einschließlich der Änderung von Passagiername, Reiseziel und Reisedatum) und für die Erstattung von Stornierungen werden von der jeweiligen Fluggesellschaft festgelegt, die Ihr Vertragspartner für die Bereitstellung des Fluges ist. Wir als Vermittler haben keinen Einfluss auf solche Bedingungen.

3.5.2 Wenn Sie eine Änderung Ihrer Buchung vornehmen oder eine Stornierungsrückerstattung als zusätzlichen eigenen Service beantragen möchten, bieten wir Ihnen an, die Anfrage in Ihrem Namen zu bearbeiten, vorausgesetzt, die Bedingungen der Fluggesellschaft erlauben eine solche Änderung oder Stornierungsrückerstattung. Während des Prozesses der Buchung solcher Zusatzleistungen werden wir Sie über alle weiteren Bedingungen für diese Leistungen informieren. Alternativ können Sie sich natürlich auch direkt an die jeweilige Fluggesellschaft wenden.

3.5.3 Damit wir die von Ihnen gewünschten Änderungen bearbeiten können, ist es notwendig, dass wir Ihre Änderungswünsche spätestens 24 Stunden vor Reiseantritt (nur telefonisch) erhalten. Wenn Sie unseren flexiblen Ticket-Service erworben haben, siehe Abschnitt 10.

Für kurzfristige Änderungswünsche empfehlen wir Ihnen, sich direkt an die jeweilige Fluggesellschaft zu wenden.

### 3.6 Nichterscheinen oder Nicht-Durchführung des Fluges

Sie ermächtigen uns hiermit, den nicht genutzten Flug in Ihrem Namen bei Nichterscheinen oder Nicht-Durchführung des Fluges zu stornieren und in Ihrem Namen eventuelle Rückerstattungen von der Fluggesellschaft zu verlangen. Wir sind dazu berechtigt, aber nicht verpflichtet, und Ihr Recht, Rückerstattungen direkt von der Fluggesellschaft zu verlangen, bleibt unberührt.

### 3.7 Billigfluggesellschaften

3.7.1 Es wird Ihnen während des Buchungsvorgangs deutlich angezeigt, wenn der betreffende Flug von einer Billigfluggesellschaft durchgeführt wird. Für Flüge von Billigfluggesellschaften gelten folgende Sonderbestimmungen

3.7.2 Bei der Vermittlung von Reisedienstleistungen von Billigfluggesellschaften können wir in Ihrem Namen den Vertrag zwischen Ihnen und der Billigfluggesellschaft abschließen.

3.7.3 Wir haben keinen Zugang zu den Buchungssystemen von Billigfluggesellschaften. Für Fragen zu Buchungen, Änderungen oder Stornierungen Ihrer Buchung wenden Sie sich daher bitte direkt an die jeweilige Billigfluggesellschaft. Wenn Sie unseren flexiblen Ticket-Service (siehe Abschnitt 10) erworben haben, müssen alle Umbuchungsanträge über unseren Kundenservice erfolgen (siehe Abschnitt 10.1.4).

3.7.4 Bitte beachten Sie auch, dass einige Billigfluggesellschaften Gebühren für das manuelle Check-in am Flughafen erheben. Um solche Gebühren zu vermeiden, sollten Sie die Möglichkeit des Online-Check-in über die Website der jeweiligen Billigfluggesellschaft nutzen. Bitte informieren Sie sich rechtzeitig vor Ihrer Abreise über die Check-in-Möglichkeiten und Konditionen Ihrer Fluggesellschaft.

3.7.5 Buchungsbestätigungen für Billigflüge werden verschickt, sobald die Reservierung von der jeweiligen Billigfluggesellschaft bestätigt wurde. Sie können zwei Buchungsbestätigungen erhalten - eine von uns und eine von der jeweiligen Billigfluggesellschaft. Wenn Sie zwei Buchungsbestätigungen erhalten haben, verwenden Sie bitte die Buchungsbestätigung der Billigfluggesellschaft zum Einchecken.

#
# 4. Besondere Bestimmungen für die Vermittlung von Buchungen für mehrere Dienstleistungen

Das Portal bietet die Möglichkeit, mehrere Einzelleistungen nach Ihren Wünschen zu kombinieren (z.B. Flug + Hotel). In diesem Fall beauftragen Sie uns, als Vermittler für die Buchung verschiedener Reiseleistungen verschiedener Anbieter zu fungieren. Die relevanten Anbieter werden Ihnen während des Buchungsprozesses vor Abschluss der Buchung klar und deutlich vorgestellt. Für den Fall, dass Sie einzelne Leistungen kombinieren, kommt kein Reisevertrag zwischen Ihnen und uns zustande, sondern Sie schließen mehrere Verträge über die Erbringung einzelner Leistungen mit jedem einzelnen Anbieter. In diesem Fall agieren wir ausschließlich im Rahmen unserer Vermittlertätigkeit in Bezug auf jede einzelne Reisedienstleistung.

#
# 5. Informationen über Pass-, Visa- und Gesundheitsvorschriften

5.1 Pass-, Visa- und/oder Gesundheitsanforderungen können sich ändern, weshalb Sie sich rechtzeitig vor der Reise mit der zuständigen Behörde (Botschaft, Konsulat usw.) in Verbindung setzen sollten. Es liegt in Ihrer Verantwortung, im Besitz eines gültigen Reisepasses und gegebenenfalls eines Visums zu sein. Es ist wichtig, daran zu denken, alle Transitpunkte in Ihre Reise einzubeziehen, die auch die Beschaffung eines Visums erfordern können. Es kann oft einige Zeit dauern, bis Sie ein Visum erhalten, daher wird Ihnen empfohlen, sich rechtzeitig darum zu kümmern. Wir übernehmen keine Verantwortung für Kunden, die nicht über die richtigen Dokumente verfügen.

5.2 Jedes Reiseziel hat seine eigenen Anforderungen an Einreiseformalitäten, Impfungen usw., die auch je nach Nationalität des Fahrgastes variieren können. Es liegt in Ihrer Verantwortung, diese Informationen zu sammeln. Wir sind für keine Vorfälle, die sich aus der Nichteinhaltung solcher behördlichen Vorschriften ergeben, verantwortlich. Wir bitten Sie daher, die unterschiedlichen Formalitäten der gewählten Ziel- oder Transitländer sowie den Zeitaufwand für alle damit verbundenen Schritte stets zu überprüfen.

#
# 6 GEBÜHREN UND BEZAHLUNG

### 6.1 Zahlung

6.1.1 Die Zahlung für Reisedienstleistungen wird entweder von uns (in Zusammenarbeit mit unseren Zahlungsdienstleistern, die die Zahlungsabwicklung anbieten) oder dem Dienstleister abgewickelt. Bitte beachten Sie, dass wir Ihre Zahlungsinformationen, wie z.B. Kredit- oder Debitkarteninformationen, möglicherweise an unsere Zahlungsdienstleister weitergeben müssen, um die Zahlung bearbeiten zu können. Alle Zahlungsinformationen unserer Kunden werden auf einem sicheren Server verschlüsselt, wenn sie mit unseren Zahlungsdienstleistern geteilt werden.

6.1.2 Damit wir Ihre Zahlung bearbeiten können, müssen Sie sicherstellen, dass genügend Geld vorhanden ist. Im Falle von Problemen im Zusammenhang mit der Verarbeitung Ihrer Zahlung werden wir die Verarbeitung Ihrer Zahlung in Zusammenarbeit mit unseren Zahlungsdienstleistern erneut versuchen. Sollte dies nicht zu einer erfüllten Auszahlung von Geldern von Ihnen führen, werden wir Sie so schnell wie möglich kontaktieren, um Anweisungen zu anderen Zahlungsmitteln zu erhalten. Sollten wir Ihre Zahlung nach diesen weiteren Anweisungen nicht erhalten, wird Ihre ausstehende Zahlung an ein Inkassounternehmen weitergeleitet.

### 6.2 Zahlungsbetrug

Wenn wir Grund zu der Annahme haben, dass ein Betrug vorliegt, behalten wir uns das Recht vor, die Verarbeitung dieser Zahlung zu verweigern. Bei Verdacht auf eine Straftat kann eine Legitimation der Zahlung verlangt werden. Alle Arten von Zahlungsbetrug werden der Polizei gemeldet und an ein Inkassounternehmen weitergeleitet.

#
# 7. Beschwerden

### 7.1 Ansprüche im Zusammenhang mit der Erbringung von Reiseleistungen

Sämtliche Probleme, Bemerkungen oder Ansprüche im Zusammenhang mit der tatsächlichen Leistung der Reisedienstleistungen sind direkt an den jeweiligen Dienstleister zu richten, mit dem Sie den Vertrag für die jeweilige Reisedienstleistung abgeschlossen haben. Ihre Rechte nach der EU-Verordnung finden Sie auch in Abschnitt 9.

### 7.2 Reklamationen im Zusammenhang mit unseren eigenen Dienstleistungen

Reklamationen im Zusammenhang mit unseren eigenen Dienstleistungen werden ausschließlich schriftlich bearbeitet und sind innerhalb von zwei Monaten nach Beendigung Ihrer Reise per E-Mail einzureichen.

E-Mail-Adresse: [complaints@flystrait.com](mailto:complaints@flystrait.com)

#
# 8. Kundenrechte nach EU-Verordnung

### 8.1 Annullierte oder verspätete Flüge

Wenn Sie in die oder aus der EU oder mit einer EU-Fluggesellschaft reisen, haben Sie möglicherweise das Recht, eine Erstattung der Kosten zu verlangen, die Sie direkt gegen die betreffende Fluggesellschaft geltend machen können, wenn Ihr Flug gestrichen, verspätet oder Ihnen die Beförderung verweigert wird. [Für weitere Informationen zur EG-Verordnung 261/2004 klicken Sie bitte hier.](http://eur-lex.europa.eu/legal-content/EN/ALL/?uri=CELEX%3A32004R0261)

### 8.2 Haftung des Betreibers

Die EG-Verordnung (889/2002) über die Haftung von Luftfahrtunternehmen bei Unfällen. [Die Verordnung finden Sie hier.](http://eur-lex.europa.eu/legal-content/EN/TXT/?uri=celex%3A32002R0889)

#
# 9. Haftung

9.1 Sie akzeptieren, dass wir als Vermittler zwischen Ihnen und dem Dienstanbieter fungieren. Wir haften unter keinen Umständen für Reisedienstleistungen, die Sie bei einem oder mehreren Dienstleistern gebucht haben, und wir übernehmen keine Haftung für Ungenauigkeiten in den auf dem Portal angezeigten Informationen, die vom jeweiligen Dienstleister bereitgestellt wurden.

9.2 Sollte ein Dienstleister aus irgendeinem Grund nicht in der Lage sein, die Reiseleistung zu erbringen, auch wenn ein Dienstleister Konkurs anmeldet, können wir nur dann als Vermittler fungieren und Zahlungen erstatten, wenn wir diese bereits vom jeweiligen Dienstleister erhalten haben.

9.3 Im Zusammenhang mit unseren eigenen Dienstleistungen haften wir für Schäden gemäss der in diesen AGBs genannten Einschränkungen und im Rahmen der gesetzlichen Möglichkeiten. Wir haften nur für direkte Schäden, die Ihnen aufgrund eines zurechenbaren Mangels an unseren Verpflichtungen in Bezug auf unsere eigenen Dienstleistungen tatsächlich entstanden sind, und zwar bis zu einem Gesamtbetrag der Kosten Ihrer Buchung (sei es für eine Veranstaltung oder eine Reihe von verbundenen Veranstaltungen).

9.4 Die in Ziffer 10.3 genannte Haftungsbeschränkung gilt auch für Pflichtverletzungen von Personen, die wir nach den gesetzlichen Bestimmungen zu vertreten haben.

#
# 10. Geltendes Recht und Streitbeilegung

### 10.1 Anwendbares Recht

Für diese AGB und den Vertrag zwischen Ihnen und uns gilt ausschließlich estnisches Recht unter Ausschluss des materiellen Einheitsrechts, insbesondere das UN-Kaufrecht über den internationalen Warenkauf.

Als Verbraucher profitieren Sie von allen zwingenden Bestimmungen des Rechts des Landes, in dem Sie ansässig sind. Nichts in diesen AGB, einschließlich dieses Abschnitts 11.1, beeinträchtigt Ihre Rechte als Verbraucher, sich auf solche zwingenden Bestimmungen des lokalen Rechts zu verlassen.

### 10.2 Online-Streitbeilegung

Die Europäische Kommission bietet eine Plattform für die Online-Streitbeilegung (ODS) unter: (http://ec.europa.eu/consumers/odr/)

#
# 11. Datenschutz

Der Schutz personenbezogener Daten ist uns wichtig. Detaillierte Informationen über die Erhebung, Verarbeitung und Nutzung Ihrer personenbezogenen Daten finden Sie in unserer [Datenschutzerklärung.](https://flystrait.com/privacy)
`;
