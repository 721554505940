import React from "react";
import styled from "styled-components";
import { resizedImage } from "utils/utils";
import checked from "assets/tip/Checked-circle.svg";

const Container = styled.div`
  position: relative;
  .selectedCheck {
    z-index: 2;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: 48px;
    height: 44px;
    background-color: rgba(255, 255, 255, 0.7);
    border-bottom-left-radius: 10em;
    border-bottom-right-radius: 10em;
    img {
      height: 100% !important;
      width: 100% !important;
    }
  }
`;

const Wrapper = styled.div`
  width: 296px;
  cursor: pointer;
  margin-bottom: 20px;
  @media (max-width: 800px) {
    width: 165px;
  }
  &.images {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    img {
      width: 100%;
      object-fit: cover;
    }
    &.layout1 {
      img {
        height: 296px;
        @media (max-width: 800px) {
          height: 124px;
        }
      }
    }
    &.layout2 {
      flex-flow: column;
      img {
        height: 111px;
        @media (max-width: 800px) {
          height: 62px;
        }
        &:last-child {
          margin-top: 1px;
        }
      }
    }
    &.layout21 {
      flex-flow: row;
      img {
        height: 222px;
        width: 148px;
        @media (max-width: 800px) {
          height: 124px;
          width: 82.5px;
        }
        &:last-child {
          mar-left: 1px;
        }
      }
    }
    &.layout3 {
      justify-content: space-between;
      img {
        &:first-child {
          margin-bottom: 1px;
          width: 100%;
        }
        height: 111px;
        width: calc(50% - 0.5px);
        @media (max-width: 800px) {
          height: 62px;
        }
      }
    }
    &.layout31 {
      justify-content: space-between;
      img {
        height: 111px;
        width: calc(50% - 0.5px);
        @media (max-width: 800px) {
          height: 62px;
        }
        &:last-child {
          margin-top: 1px;
          width: 100%;
        }
      }
    }
    &.layout4 {
      justify-content: space-between;
      img {
        &:first-child {
          margin-bottom: 1px;
        }
        height: 111px;
        width: calc(50% - 0.5px);
        @media (max-width: 800px) {
          height: 62px;
        }
      }
    }
  }
`;

const layouts = {
  "1": "layout1",
  "=": "layout2",
  "||": "layout21",
  L: "layout3",
  "7": "layout31",
  "+": "layout4"
};

export default class extends React.PureComponent {
  handleLayoutChange = () => {
    const { layout, handleLayoutChange } = this.props;
    if (handleLayoutChange) {
      handleLayoutChange(layout);
    }
  };
  render() {
    const { blogData, layout, selectedLayout } = this.props;
    const layoutClass = layouts[layout];
    return (
      <Container>
        {selectedLayout === layout && (
          <div className="selectedCheck">
            <img src={checked} alt="" />
          </div>
        )}

        <Wrapper
          className={`images ${layoutClass}`}
          onClick={this.handleLayoutChange}
        >
          {layout === "1" && (
            <img src={resizedImage(blogData.coverimage1, 296)} alt="" />
          )}
          {(layout === "=" || layout === "||") && (
            <>
              <img src={resizedImage(blogData.coverimage1, 296)} alt="" />
              <img src={resizedImage(blogData.coverimage2, 296)} alt="" />
            </>
          )}
          {(layout === "L" || layout === "7") && (
            <>
              <img src={resizedImage(blogData.coverimage1, 296)} alt="" />
              <img src={resizedImage(blogData.coverimage2, 296)} alt="" />
              <img src={resizedImage(blogData.coverimage3, 296)} alt="" />
            </>
          )}
          {layout === "+" && (
            <>
              <img src={resizedImage(blogData.coverimage1, 296)} alt="" />
              <img src={resizedImage(blogData.coverimage2, 296)} alt="" />
              <img src={resizedImage(blogData.coverimage3, 296)} alt="" />
              <img src={resizedImage(blogData.coverimage4, 296)} alt="" />
            </>
          )}
        </Wrapper>
      </Container>
    );
  }
}
