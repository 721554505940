import React from 'react';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import {
  addDecimal,
  getItem,
  numberToLocaleString,
  underscoreToSpace,
} from 'utils/utils';
import HopSegButton from './HopSegButton';

const dateChangePenaltiesByHoprequest = gql`
  query dateChangePenaltiesByHoprequest($hoprequestId: Int!) {
    dateChangePenaltiesByHoprequest(hoprequestId: $hoprequestId)
  }
`;

export class DateChangeButton extends React.PureComponent {
  state = {
    airportData: [],
    selectedIndex: 0,
  };

  componentWillReceiveProps(newProps) {
    if (
      newProps.dateChangePolicy &&
      !newProps.dateChangePolicy.loading &&
      !this.state.airportData.length &&
      this.state.selectedIndex !== -1
    ) {
      const airportData =
        newProps.dateChangePolicy.dateChangePenaltiesByHoprequest;
      this.props.updateFirstChoice('dateChangePenalty', airportData[0]);
      const selectedIndex = airportData.findIndex(
        item => item === newProps.dateChangePenalty,
      );
      this.setState({
        airportData,
        selectedIndex,
      });
    }

    if (newProps.dateChangePenalty !== this.props.dateChangePenalty) {
      const selectedIndex = this.state.airportData.findIndex(
        item => item === newProps.dateChangePenalty,
      );
      this.setState({
        selectedIndex,
      });
    }
  }

  handleClick = () => {
    if (this.state.airportData.length) {
      const upcomingIndex = this.state.selectedIndex + 1;
      this.setState(
        {
          selectedIndex:
            this.state.airportData.length > upcomingIndex ? upcomingIndex : 0,
        },
        () =>
          this.props.onClick &&
          this.props.onClick({
            type: 'dateChangePenalty',
            value: this.state.airportData[this.state.selectedIndex],
          }),
      );
    }
  };

  render() {
    const { airportData, selectedIndex } = this.state;
    const {
      dateChangePenalty,
      userTrips,
      transferValue,
      costDecimalPlaces,
    } = this.props;
    const penalty = underscoreToSpace(dateChangePenalty);
    return (
      <HopSegButton
        onClick={this.handleClick}
        disabled={this.props.disabled || airportData.length === 1}
        label="Date Changes"
        icon={
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <b className="__release-text">
              {userTrips
                ? penalty
                : !selectedIndex && !airportData[selectedIndex]
                ? `${this.props.loadingText}...`
                : underscoreToSpace(airportData[selectedIndex])}
            </b>
            <p>Date Changes</p>
            <div className="pipe" />
            <p style={{ textAlign: 'center' }}>
              {dateChangePenalty === 'STRICT'
                ? 'No Refund'
                : 'Transfer Value upto'}
            </p>
            {dateChangePenalty !== 'STRICT' && (
              <p>
                {numberToLocaleString(
                  addDecimal(transferValue, costDecimalPlaces),
                  {
                    selectedCountry: getItem('selectedLanguage'),
                    selectedCurrency: getItem('selectedCurrency'),
                  },
                )}
              </p>
            )}
          </div>
        }
      />
    );
  }
}

export default compose(
  graphql(dateChangePenaltiesByHoprequest, {
    name: 'dateChangePolicy',
    options: ownProps => ({
      variables: {
        hoprequestId: ownProps.hopRequestId,
      },
    }),
  }),
)(DateChangeButton);
