import React from "react";
import styled from "styled-components";
import { Button } from "components/common";
import close from "assets/tip/Delete.svg";

const Wrapper = styled.div`
  width: 176px;
  height: 176px;
  margin-right: 20px;
  margin-bottom: 20px;
  position: relative;
  .close {
    z-index: 2;
    position: absolute;
    left: 12px;
    top: 12px;
    cursor: pointer;
    width: 32px;
    height: 32px;
  }
  .file {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }
`;

export default class extends React.PureComponent {
  handleRemove = () => {
    this.props.handleRemove(this.props.index);
  };
  render() {
    const { src } = this.props;
    return (
      <Wrapper>
        <div className="close">
          <Button
            style={{
              height: 32,
              minWidth: 32
            }}
            onClick={this.handleRemove}
            roundButton
            color="rgba(255, 255, 255, 0.8)"
          >
            <img src={close} alt="" />
          </Button>
        </div>
        <img className="file" src={src} alt="" />
      </Wrapper>
    );
  }
}
