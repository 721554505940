import moment from "moment";
const items = [
  {
    name: "day",
    label: "Day",
    short: "d"
  }, {
    name: "week",
    label: "Week",
    short: "w"
  }, {
    name: "month",
    label: "Month",
    short: "M"
  }
];

export const handleFiltering = posts => {
  let filteredPosts = [];
  posts.forEach(post => {
    if (!post.recurpattern_id) {
      filteredPosts = [
        ...filteredPosts,
        post
      ];
    } else {
      const exists = filteredPosts.find(item => item.recurpattern_id === post.recurpattern_id);
      if (!exists) {
        filteredPosts = [
          ...filteredPosts,
          post
        ];
      }
    }
  });
  return filteredPosts;
};

export const handleGrouping = posts => {
  let groupedPosts = [];
  posts.forEach(post => {
    const postIndex = groupedPosts.findIndex(item => item.shareschedule_at === moment(post.shareschedule_at).format("MMM DD, YYYY HH:mm"));
    if (postIndex > -1) {
      groupedPosts[postIndex].posts = [
        ...groupedPosts[postIndex].posts,
        post
      ];
    } else {
      groupedPosts = [
        ...groupedPosts, {
          shareschedule_at: moment(post.shareschedule_at).format("MMM DD, YYYY HH:mm"),
          posts: [post]
        }
      ];
    }
  });
  return groupedPosts;
};

export const getRecurrences = (connectPost, connectPosts) => {
  const {recurpattern} = connectPost;
  if (!recurpattern) {
    return null;
  }
  const filteredPosts = connectPosts.filter(item => item.recurpattern_id === connectPost.recurpattern_id);
  const dates = filteredPosts.map(item => (moment(item.shareschedule_at).format()));
  const {first_recur_at, recur_count, recur_frequency, recur_type} = recurpattern;
  let recurrences = moment(first_recur_at).isAfter(moment()) && dates.indexOf(first_recur_at) > -1
    ? [moment(first_recur_at)]
    : [];
  const repeatItem = items.find(item => item.name === recur_type.toLowerCase());
  for (var i = 1; i < recur_count; i++) {
    const date = moment(first_recur_at).add(recur_frequency * i, repeatItem.short);
    if (date.isAfter(moment()) && dates.indexOf(moment(date).format()) > -1) {
      recurrences = [
        ...recurrences,
        date
      ];
    }
  }
  return recurrences;
};
