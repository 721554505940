import { combineReducers } from "redux";

import reducers from "redux-store/reducers/reducers";
import flightResults from "./flightResults";
import hotelResults from "./hotelResults";
import passengers from "./passengers";
import user from "./user";
import filters from "./filters";
import translations from "./translations";
import influencer from "./influencer";

export default combineReducers({
  reducers,
  flightResults,
  hotelResults,
  passengers,
  user,
  filters,
  translations,
  influencer
});
