import { setItem } from 'utils/utils';

export const HANDLE_INCLUDE_HOTEL = 'HANDLE_INCLUDE_HOTEL';
export const SET_NIGHT_COUNT = 'SET_NIGHT_COUNT';
export const HANDLE_HOTELS_UNAVAILABLE = 'HANDLE_HOTELS_UNAVAILABLE';
export const HANDLE_UPDATE_HOTEL_FILTERED_RESULTS = 'HANDLE_UPDATE_HOTEL_FILTERED_RESULTS';
export const CLEAR_HOTEL_FILTERED_RESULTS = 'CLEAR_HOTEL_FILTERED_RESULTS';
export const HANDLE_SELECTED_HOTEL = 'HANDLE_SELECTED_HOTEL';
export const HANDLE_DESELECT_HOTEL = 'HANDLE_DESELECT_HOTEL';
export const HANDLE_FINALISE_HOTEL = 'HANDLE_FINALISE_HOTEL';
export const HANDLE_SETUPBOOKING_COMPLETE = 'HANDLE_SETUPBOOKING_COMPLETE';


export function handleIncludeHotel(includeHotel) {
    return {
        type: HANDLE_INCLUDE_HOTEL,
        payload: { includeHotel },
    };
}

export function setNightCount(nightCount) {
    return {
        type: SET_NIGHT_COUNT,
        payload: { nightCount },
    };
}

export function handleHotelsUnavailable() {
    return {
        type: HANDLE_INCLUDE_HOTEL
    };
}

export function handleUpdateHotelFilteredResults(prevDestinationCode, prevSkipped, destinationCode, hotelDestinationCode, hotels, availabilityKey, occupancies) {
    return {
        type: HANDLE_UPDATE_HOTEL_FILTERED_RESULTS,
        payload: { prevDestinationCode, prevSkipped, destinationCode, hotelDestinationCode, hotels, availabilityKey, occupancies },
    };
}

export function clearHotelFilteredResults() {
    return {
        type: CLEAR_HOTEL_FILTERED_RESULTS,
    };
}

export function handleSelectedHotel(selectedHotelData) {
    return {
        type: HANDLE_SELECTED_HOTEL,
        payload: { selectedHotelData }
    };
}

export function handleDeselectHotel(hotelCode) {
    return {
        type: HANDLE_DESELECT_HOTEL,
        payload: { hotelCode }
    };
}

export function handleFinaliseHotel(destinationCode) {
    return {
        type: HANDLE_FINALISE_HOTEL,
        payload: { destinationCode }
    };
}

export function handleSetupBookingComplete(booking) {
    return {
        type: HANDLE_SETUPBOOKING_COMPLETE,
        payload: { booking }
    }
}